import { autoinject, bindable } from "aurelia-framework";
import { EnumsService } from "../../../../services/enums-service";
import { EnumMap } from "../../../../common/utilities/enum-map";
import { NoteDataManager } from "../../note-data-manager";
import { EventAggregator } from "aurelia-event-aggregator";
import { WoundBodyStore } from "./wound-body-store";
import { IGetWoundGroupBehavior } from "../../../../interfaces/form-builder/i-behavior";
import { bindingMode } from "aurelia-binding";
import { IWoundBodyLocation } from "../../../../interfaces/i-wound-body";

@autoinject
export class WoundBody {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public behavior: IGetWoundGroupBehavior = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public listGroupName: string = "";
    private readonly _enumsService: EnumsService;
    private readonly _noteDataManager: NoteDataManager;
    private readonly _ea: EventAggregator;
    public svgViewMap: Map<string, HTMLDivElement> = new Map();
    public svgViewContentMap: Map<string, string> = new Map();
    public woundBodyStore: WoundBodyStore;
    public woundSideEnumMap: EnumMap = new EnumMap([]);
    public sides = ["right", "left", "front", "back", "feet"];

    public constructor(enumsService: EnumsService, noteDataManager: NoteDataManager, ea: EventAggregator) {
        this._enumsService = enumsService;
        this._noteDataManager = noteDataManager;
        this._ea = ea;
    }

    public async attached() {
        let woundSideEnum = await this._enumsService.getWoundSide();
        this.woundSideEnumMap = new EnumMap(woundSideEnum);
        this.initializeObjects();
        this.woundBodyStore = new WoundBodyStore(
            this._enumsService,
            this._noteDataManager,
            this._ea,
            this.behavior,
            this.listGroupName,
            this.addWoundMarkers.bind(this)
        );
        this.woundBodyStore.initialize();
    }

    public initializeObjects() {
        this.sides.forEach((side) => {
            this.svgViewMap.set(side, document.getElementById(`${side}ViewSVG`) as HTMLDivElement);
        });
        this.sides.forEach((side) => {
            this.svgViewContentMap.set(side, document.getElementById(`${side}View`).innerHTML);
        });
    }

    public addWoundMarkers(wounds: IWoundBodyLocation[]) {
        wounds = wounds.filter(
            (wound) =>
                wound.woundSide &&
                wound.yCoordinate &&
                wound.xCoordinate &&
                wound.woundNumber &&
                !wound.woundLocationSearch
        );
        this.sides.forEach((side) => {
            let woundMap: Map<string, IWoundBodyLocation[]> = new Map();
            let markers = "";
            // create arrays for wounds at same locations
            wounds.forEach((location) => {
                if (location.woundSide == this.woundSideEnumMap.getEnumValue(side)) {
                    if (!woundMap.has(`${location.xCoordinate}${location.xCoordinate}`)) {
                        woundMap.set(`${location.xCoordinate}${location.xCoordinate}`, []);
                    }
                    woundMap.get(`${location.xCoordinate}${location.xCoordinate}`).push(location);
                }
            });
            woundMap.forEach((value) => {
                markers += this.getMarkerHTML(value);
            });
            let svgView: HTMLDivElement = this.svgViewMap.get(side);
            let svgViewContent: string = this.svgViewContentMap.get(side);
            this.addMarkersToBody(svgView, side, markers, svgViewContent);
        });
    }

    public addMarkersToBody(svg: HTMLDivElement, side: string, markers: string, innerHTML: string) {
        if (!markers) {
            switch (side) {
                case "left":
                case "right":
                case "feet":
                    svg.classList.add("d-none");
                    return;
            }
        }
        svg.classList.remove("d-none");
        let sideView = document.getElementById(`${side}View`);
        sideView.innerHTML = innerHTML + markers;
    }

    public getMarkerHTML(locations: IWoundBodyLocation[]) {
        if (!locations?.length) {
            return "";
        }
        return `<text x="${parseFloat(locations[0].xCoordinate) * 320}" y="${
            parseFloat(locations[0].yCoordinate) * 460
        }" stroke="red" font-size="1.5em" fill="red">
                    ${locations
                        .sort(
                            (location1, location2) =>
                                parseInt(location2.woundNumber, 10) - parseInt(location1.woundNumber, 10)
                        )
                        .map((location) => location.woundNumber)
                        .join(",")}
                </text>`;
    }

    public detached() {
        this.woundBodyStore.detached();
    }
}
