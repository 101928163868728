import "./form-state-handler.scss";
import { bindingMode, bindable } from "aurelia-framework";
import { IFormSchema } from "../../interfaces/form-builder/i-form-schema";
export class FormStateHandler {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public formSchema: IFormSchema;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isSaveInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isCheckErrorInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPrefillInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public prefillLoadingText: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public tryAgain: () => any;
}
