import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    IGetInterimPayorIdResult,
    IGetMedicare,
    IGetMedicareIds,
    IGetPayorByNameQuery,
    IGetPayorLeanResult,
    IGetPayorQuery,
    IGetPayorResult,
    IGetPayorSearchByNameResult,
    IGetPayorSlimResult,
    IGetPayorWithAuthorization,
    IGetTaxonomies,
    IGetTaxonomiesResult,
    IUpdateMedicare
} from "../interfaces/i-get-payor";
import { IPaginationNew, IPaginationRequest } from "../interfaces/i-pagination";
import {
    ICreateLocRateResult,
    ICreateVisitRateResult,
    IGetRatesResult,
    ILocRate,
    IRateDetails,
    IVisitRate,
    ISequestrationRate, ICreateSequestrationResult, IGetSequestrationResult
} from "../interfaces/i-rates";
import { IProviderValueSource } from "../interfaces/payor/i-provider-value-source";
import { Payor } from "../models/payor";
import { PhysicianCode } from "../pages/create/create-payor/fee-schedule/model/physician-code";
import {
    IEditPhysicianFeeSchedule,
    IGetPhysicianRatesQuery,
    IGetSimilarInsurance,
    IGetSimilarInsurancesQuery,
    IPhysicianFeeSchedule,
    IPhysicianFeeScheduleCodes,
    IPostPhysicianCodesResponse
} from "./i-payor-service";

@autoinject()
export class PayorService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/payors";
    private _lookupURL: string = "/api/v1/lookup";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createPayor(newPayor: Payor) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("POST").useBody(newPayor).fetch();
    }

    public async getTaxonomies(query: IGetTaxonomies) {
        return await this._httpClient
            .build(`${this._lookupURL}/taxonomies`)
            .useQueryString(query)
            .fetch<IGetTaxonomiesResult[]>();
    }

    public async getInterimPayor() {
        return await this._httpClient.build(`${this._baseUrl}/interim/id`).fetch<IGetInterimPayorIdResult>();
    }

    public async getPayorByPage(query: IGetPayorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetPayorResult>();
    }

    public async getPayorsByName(query: IGetPayorByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/searchByName`)
            .useQueryString(query)
            .fetch<IGetPayorSearchByNameResult[]>();
    }

    public async getPayorsLean(includeCharityPayor?: boolean) {
        return await this._httpClient
            .build(`${this._baseUrl}/lean`)
            .useQueryString({ includeCharityPayor })
            .fetch<IGetPayorLeanResult[]>();
    }

    public async getPayorById(payorId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${payorId}`).fetch<Payor>();
    }

    public async getMedicareIds(providerIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/medicare/ids`)
            .useQueryString({ providerIds })
            .fetch<IGetMedicareIds[]>();
    }

    public async getMedicareById(payorId: string) {
        return await this._httpClient.build(`${this._baseUrl}/medicare/${payorId}`).fetch<IGetMedicare>();
    }

    public async getPayersAvailable(search: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/available`)
            .useQueryString({ search })
            .fetch<IGetPayorSlimResult>();
    }

    // POST of Medicare exists only for Production Usage

    public async editPayor(payorId: string, payor: Payor) {
        return await this._httpClient.build(`${this._baseUrl}/${payorId}`).useMethod("PUT").useBody(payor).fetch();
    }

    public async editMedicarePayor(payorId: string, medicarePayor: IUpdateMedicare) {
        return await this._httpClient
            .build(`${this._baseUrl}/medicare/${payorId}`)
            .useMethod("PUT")
            .useBody(medicarePayor)
            .fetchNoContent();
    }

    public async deletePayor(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}`).useMethod("DELETE").fetchNoContent();
    }

    public async createVisitInformation(payorId: string, newVisit: IVisitRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/rates/visit`)
            .useMethod("POST")
            .useBody(newVisit)
            .fetch<ICreateVisitRateResult>();
    }

    public async createLevelOfCareInformation(payorId: string, newLevel: ILocRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/rates/levelofcare`)
            .useMethod("POST")
            .useBody(newLevel)
            .fetch<ICreateLocRateResult>();
    }

    public async getRates(payorId: string, query: IPaginationRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/rates`)
            .useQueryString(query)
            .fetch<IGetRatesResult>();
    }

    public async getArchive(payorId: string, query: IPaginationRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/rates/previous`)
            .useQueryString(query)
            .fetch<IGetRatesResult>();
    }

    public async getRatesChronology(payorId: string, rateId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${payorId}/rates/${rateId}`).fetch<any[]>();
    }

    public async getArchiveRatesChronology(payorId: string, rateId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${payorId}/rates/${rateId}`).fetch<any[]>();
    }

    public async editVisitInformation(visitId: string, visit: IRateDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/rates/visit/${visitId}`)
            .useMethod("PUT")
            .useBody(visit)
            .fetchNoContent();
    }

    public async editLocInformation(locId: string, loc: IRateDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/rates/levelofcare/${locId}`)
            .useMethod("PUT")
            .useBody(loc)
            .fetchNoContent();
    }

    public async deleteVisitRate(rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/rates/visit/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async deleteLocRate(rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/rates/levelofcare/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getProviderSources(billType: number) {
        return await this._httpClient
            .build(`${this._baseUrl}/provider-sources`)
            .useQueryString({ billType })
            .fetch<IProviderValueSource[]>();
    }

    public async createPhysicianCode(payorId: string, newCode: IPhysicianFeeScheduleCodes) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/rates`)
            .useMethod("POST")
            .useBody(newCode)
            .fetch<IPostPhysicianCodesResponse>();
    }

    public async createPhysicianFeeSchedule(payorId: string, newCode: IPhysicianFeeSchedule) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician`)
            .useMethod("POST")
            .useBody(newCode)
            .fetch<IPostPhysicianCodesResponse>();
    }

    public async getPhysicianFeeSchedule(payorId: string, query: IPaginationRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician`)
            .useQueryString(query)
            .fetch<IPaginationNew<IPhysicianFeeSchedule>>();
    }

    public async getPhysicianRates(payorId: string, query: IGetPhysicianRatesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/rates`)
            .useQueryString(query)
            .fetch<IPhysicianFeeScheduleCodes[]>();
    }

    public async deletePhysicianRates(physicianRateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/physician/rates/${physicianRateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async deletePhysicianFeeSchedule(feeScheduleId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/physician/${feeScheduleId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async editPhysicianFeeSchedule(
        feeScheduleId: string,
        payorId: string,
        feeSchedule: IEditPhysicianFeeSchedule
    ) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/${feeScheduleId}`)
            .useMethod("PUT")
            .useBody(feeSchedule)
            .fetchNoContent();
    }

    public async editPhysicianFeeCode(payorId: string, codeId: string, feeCode: PhysicianCode) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/rates/${codeId}`)
            .useMethod("PUT")
            .useBody(feeCode)
            .fetchNoContent();
    }

    public async getSimilarInsurances(payorId: string, query: IGetSimilarInsurancesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/similar`)
            .useQueryString(query)
            .fetch<IGetSimilarInsurance[]>();
    }

    public async getSimilarPhysicianInsurances(payorId: string, query: IGetSimilarInsurancesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/similar`)
            .useQueryString(query)
            .fetch<IGetSimilarInsurance[]>();
    }

    public async copyPhysicianFeeSchedule(payorId: string, sourceInsuranceId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/copy`)
            .useMethod("PUT")
            .useBody({ sourceInsuranceId })
            .fetchNoContent();
    }

    public async copyInsuranceRates(payorId: string, sourceInsuranceId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/rates/copy`)
            .useMethod("PUT")
            .useBody({ sourceInsuranceId })
            .fetchNoContent();
    }

    public async deleteAllPhysicianRates(payorId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/physician/delete-all`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async deleteAllRates(payorId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/rates/delete-all`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getPayorsWithAuthorization() {
        return await this._httpClient
            .build(`${this._baseUrl}/with-authorization`)
            .fetch<IGetPayorWithAuthorization[]>();
    }

    public async getActiveSequestration(payorId: string, query: IPaginationRequest) {
        return await this._httpClient.build(`${this._baseUrl}/${payorId}/sequestration/active`).useQueryString(query).fetch<IGetSequestrationResult>();
    }

    public async getSequestrationHistory(payorId: string, query: IPaginationRequest) {
        return await this._httpClient.build(`${this._baseUrl}/${payorId}/sequestration/history`).useQueryString(query).fetch<IGetSequestrationResult>();
    }

    public async createSequestration(payorId: string, newSequestration: ISequestrationRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/sequestration`)
            .useMethod("POST")
            .useBody(newSequestration)
            .fetch<ICreateSequestrationResult>();
    }

    public async updateSequestration(payorId: string, sequestrationId: string, updatedSequestration: ISequestrationRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/sequestration/${sequestrationId}`)
            .useMethod("PUT")
            .useBody(updatedSequestration)
            .fetchNoContent();
    }

    public async deleteSequestration(payorId: string, sequestrationId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${payorId}/sequestration/${sequestrationId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }
}
