import { computedFrom } from "aurelia-binding";
import { ValidationRules } from "aurelia-validation";
import { moment } from "fullcalendar";
import nameof from "../common/nameof";

export class Medication {
    public isCovered: boolean;
    public isCoveredOther: boolean;
    public patientId: string;
    public id: string;
    public lexiDrugId: string;
    public synonymId: string;
    public customMedicationId: string;
    public startDate: string;
    public medicationName: string;
    public frequency: string;
    public dosage: string;
    public route: string;
    public classification: string;
    public discontinueDate: string;
    public discontinuedPhysicianFirstName: string;
    public discontinuedPhysicianLastName: string;
    public discontinuedPhysicianId: string;
    public initialPhysicianFirstName: string;
    public initialPhysicianLastName: string;
    public initialPhysicianId: string;
    public discontinuePhysicianId: string;
    public indication: string;
    public associatedTaskId: string;
    public discontinueOrderId: string;
    // This initialization is necessary for collectionObserver to work
    public administeredBy: number[] = [];
    public createdBy: IUserInfo;
    public discontinuedBy: IUserInfo;
    public sendToPatient: boolean = false;
    public daysSupply: string;
    public quantity: string;
    public dispenseCount: number = 0;
    public initialOrderId: string = "";
    public isFromOrderSet: boolean = false;
    public isPrn: boolean = false;
    public medicationFrequencyId: string = "";
    public instructions: string = "";
    public discontinueTime?: string = "";
    public isBowelRegimenInPlace: boolean = false;
    public days: string[] = [];
    public times: string[] = [];
    public nonHospiceProvider: string = "";
    public notCoveredReason: string = "";
    public minimumMedicationFrequency: number = 0;
    public maximumTimesPerDay: number = 0;
    public medicationFrequencyType: number = 0;
    public frequencyRepetitions: number = 0;
    public frequencyNotation: string = "";
    public hasUnlimitedTimesPerDay: boolean = false;
    public isHighRisk: boolean = false;
    public mediSpanDrugDescriptorId: number;
    public canRestart: boolean = false;

    @computedFrom(nameof<Medication>("discontinueDate"))
    public get isActive() {
        let today = moment();
        return !this.discontinueDate || moment(this.discontinueDate).diff(today) > 0;
    }

    public constructor(data?: Medication) {
        if (data) {
            if (!data.administeredBy) {
                data.administeredBy = [];
            }
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((x: Medication) => x.medicationName)
            .required()
            .withMessage("Medication Name is required.")
            .ensure((x: Medication) => x.startDate)
            .required()
            .withMessage("Start Date is required.")
            .ensure((x: Medication) => x.indication)
            .required()
            .withMessage("Indication is required.")
            .ensure((x: Medication) => x.dosage)
            .required()
            .withMessage("Dosage is required.")
            .ensure((x: Medication) => x.route)
            .required()
            .withMessage("Route is required.")
            .ensure((x: Medication) => x.frequency)
            .required()
            .withMessage("Frequency is required.")
            .ensure((x: Medication) => x.administeredBy)
            .required()
            .minItems(1)
            .withMessage("Administered by is required.")
            .ensure((x: Medication) => x.classification)
            .required()
            .when((medication: Medication) => medication.medicationName?.length > 0 && !medication.customMedicationId)
            .withMessage("Classification is required.")
            .ensure((x: Medication) => x.isCovered)
            .required()
            .withMessage("Covered By is required.")
            .on(this);
    }
}

interface IUserInfo {
    id: string;
    firstName: string;
    lastName: string;
}
