import { DialogCancellableOpenResult, DialogOpenPromise, DialogService, DialogSettings } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

import { HttpStatusCodeEnum } from "../../enums/http-status-code-enum";
import { IDialogOptions } from "../../interfaces/i-dialog";
import { IResponseHandler } from "../../interfaces/i-handlers";
import { ServerErrorDialog } from "../../resources/dialogs/server-error/server-error";

@autoinject()
export class ServerErrorHandler implements IResponseHandler {

    private _dialogService: DialogService;

    public constructor (dialogService: DialogService) {
        this._dialogService = dialogService;
    }

    private static dialog: DialogOpenPromise<DialogCancellableOpenResult> = null;

    public async handle(response: Response): Promise<void> {
        if (response.status >= HttpStatusCodeEnum.InternalServerError) {
            let model: IDialogOptions = {
                title: `Server Error`,
                description: `Server responded with the following message "${response.status} ${response.statusText}".`
            };
            let options: DialogSettings = {
                viewModel: ServerErrorDialog,
                model: model
            };
            if (!ServerErrorHandler.dialog) {
                // TODO: Redo the wording and link to get it back up
                /* ServerErrorHandler.dialog = this._dialogService.open(options);
                await ServerErrorHandler.dialog.whenClosed();
                ServerErrorHandler.dialog = null; */
            }
        }
    }
}
