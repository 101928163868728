import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";

import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { NonPatientActivity } from "../../../pages/list/non-patient-activities/models/non-patient-activity-model";

@autoinject
@customElement("non-patient-activity-form")
export class NonPatientActivityForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerOptions: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public nonPatientActivity: NonPatientActivity = new NonPatientActivity();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isEdit: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    public providerValidation: IValidateCustomElement = {
        required: true,
        message: "Associated Agency Provider is required"
    };
}
