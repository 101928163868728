import {valueConverter} from "aurelia-binding";

@valueConverter("formatDecimals")
export class FormatDecimals {
    public toView(value: number | string, numberOfDigits: number = 2): string {
        if (!value) {
            return (0).toFixed(numberOfDigits);
        }
        return Number(value).toFixed(numberOfDigits);
    }
}
