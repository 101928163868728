import { valueConverter } from "aurelia-framework";
import showdown from "showdown";

@valueConverter("markdownToHtml")
export class MarkdownToHtml {
    public toView(markdown: string) {
        if (markdown?.length > 0) {
            // Checking for script tag to avoid regex bug
            let message = this.sanitizeScriptTags(markdown);
            showdown.extension("targetlink", this.targetLinkExtension);
            let showdownConverter = new showdown.Converter({
                extensions: ["targetlink"]
            });
            return showdownConverter.makeHtml(message);
        }
        return "";
    }

    private sanitizeScriptTags(markdown: string): string {
        let message = markdown;
        let scriptStartIndex = markdown.indexOf("<script");
        if (scriptStartIndex < 0) {
            return message;
        }
        // 8 accounts for the length of '/script>'
        let scriptEndIndex = markdown.indexOf("/script>") + 8;
        message = markdown.substring(0, scriptStartIndex) + markdown.substring(scriptEndIndex);
        return this.sanitizeScriptTags(message);
    }

    private targetLinkExtension() {
        return [
            {
                type: "lang",
                regex: /\[((?:\[[^\]]*]|[^\[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)\{\:target=(["'])(.*)\6}/g,
                replace: (
                    wholematch: string,
                    linkText: string,
                    url: string,
                    a: string,
                    b: string,
                    title: string,
                    c: string,
                    target: string
                ) => {
                    let result = '<a href="' + url + '"';

                    if (typeof title != "undefined" && title !== "" && title !== null) {
                        title = title.replace(/"/g, "&quot;");
                        title = (showdown.helper as any).escapeCharacters(title, "*_", false);
                        result += ' title="' + title + '"';
                    }

                    if (typeof target != "undefined" && target !== "" && target !== null) {
                        result += ' target="' + target + '"';
                    }

                    result += ">" + linkText + "</a>";
                    return result;
                }
            }
        ];
    }
}
