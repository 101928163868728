import { ValidationRules } from "aurelia-validation";

export class Template {
    public id: string;
    public name: string;
    public description: string;
    public createdBy: string;
    public createdByFirstName: string;
    public createdByLastName: string;
    public lastModifiedBy: string;
    public lastModifiedByFirstName: string;
    public lastModifiedByLastName: string;
    public createdOn: string;
    public modifiedOn: string;

    public constructor(data?: Template) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    private initValidation() {
        ValidationRules
            .ensure((x: Template) => x.name).required().withMessage("Template Name is required.")
            .on(this);
    }
}
