import { autoinject } from "aurelia-framework";

import { formatName } from "../../../common/utilities/format-name";
import { IGetBehavior } from "../../../interfaces/form-builder/i-behavior";
import { PatientsService } from "../../../services/patient-service";
import { PhysiciansService } from "../../../services/physician-service";
import { ProvidersService } from "../../../services/providers-service";
import { UsersService } from "../../../services/users-service";
import { DocumentTypeService } from "./../../../services/document-types-service";
import { AccountsService } from "../../../services/accounts-service";
import { BereavementPOCService } from "../../../services/bereavement-poc-service";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { EnumsService } from "../../../services/enums-service";
import { EnumMap } from "../../../common/utilities/enum-map";
import { TeamsService } from "../../../services/teams-service";
import { IGetTeamLean } from "../../../interfaces/i-team";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { LookupService } from "../../../services/lookup-service";

export enum GetBehaviorComponent {
    PecosCertifiedPhysicians = "pecoscertifiedphysicians",
    Physicians = "physicians",
    Employees = "employees",
    Patients = "patients",
    DocumentTypes = "documentTypes",
    BereavedContacts = "bereavedcontacts",
    IncludeInactivePhysician = "includeInactivePhysician",
    CareTeam = "CareTeam",
    CaseManager = "CaseManager",
    Wound = "wound"
}

@autoinject
export class GetBehaviorHandler {
    private readonly _physicianService: PhysiciansService;
    private readonly _usersService: UsersService;
    private readonly _patientsService: PatientsService;
    private readonly _documentTypeService: DocumentTypeService;
    private readonly _providersService: ProvidersService;
    private readonly _accountsService: AccountsService;
    private readonly _bereavementPOCService: BereavementPOCService;
    private readonly _enumsService: EnumsService;
    private readonly _teamsService: TeamsService;
    private readonly _lookupService: LookupService;
    private _lineOfService: number = null;
    public teams: IGetTeamLean[] = null;
    public agencyRolesEnum: IEnumResponse[] = null;

    public constructor(
        bereavementPOCService: BereavementPOCService,
        physicianService: PhysiciansService,
        documentTypeService: DocumentTypeService,
        usersService: UsersService,
        patientsService: PatientsService,
        providersService: ProvidersService,
        accountsService: AccountsService,
        enumsService: EnumsService,
        teamsService: TeamsService,
        lookupService: LookupService
    ) {
        this._documentTypeService = documentTypeService;
        this._physicianService = physicianService;
        this._usersService = usersService;
        this._patientsService = patientsService;
        this._providersService = providersService;
        this._bereavementPOCService = bereavementPOCService;
        this._accountsService = accountsService;
        this._enumsService = enumsService;
        this._teamsService = teamsService;
        this._lookupService = lookupService;
    }

    public async getList(
        behavior: IGetBehavior,
        filter: string,
        limit: number,
        providerIds: string[],
        patientId?: string
    ): Promise<ITypeaheadOptions[]> {
        switch (behavior.component) {
            case GetBehaviorComponent.DocumentTypes:
                return await this.getDocumentTypes(filter, limit, patientId);
            case GetBehaviorComponent.Physicians:
                return await this.getPhysiciansList(filter, limit, providerIds, false);
            case GetBehaviorComponent.IncludeInactivePhysician:
                return await this.getPhysiciansList(filter, limit, providerIds, true);
            case GetBehaviorComponent.PecosCertifiedPhysicians:
                return await this.getPecosCertifiedPhysiciansList(filter, limit, providerIds, false);
            case GetBehaviorComponent.Employees:
                return await this.getUsersList(filter, limit);
            case GetBehaviorComponent.Patients:
                return await this.getPatientsList(filter, limit);
            case GetBehaviorComponent.BereavedContacts:
                return await this.getBereavedContactsList(filter, patientId);
            case GetBehaviorComponent.CaseManager:
                return await this.getCaseManagerList(filter, providerIds);
            case GetBehaviorComponent.CareTeam:
                return await this.getCareTeamList(filter, providerIds);
            case GetBehaviorComponent.Wound:
                return await this.getAnatomicLocations(filter);
            default:
                return [];
        }
    }

    private async getDocumentTypes(filter: string, limit: number, patientId: string): Promise<ITypeaheadOptions[]> {
        if (!patientId) {
            return [];
        }
        // Currently, we only have document type field in task details that is for patient
        // If we add a field for employee that would call this endpoint, we would need to accommodate for it
        let patientInfo = await this._patientsService.getPatientSlim(patientId);
        let providers = await this._providersService.getProvider();
        let patientProvider = providers.find((provider) =>
            provider.branches.some((branch) => branch.id === patientInfo.branchId)
        );
        let searchedTypes = await this._documentTypeService.getDocumentTypeByName({
            term: filter,
            providerIds: [patientProvider.id]
        });
        if (!searchedTypes) {
            return [];
        }
        return searchedTypes.map((documentType) => ({
            value: documentType.id,
            name: documentType.type,
            dataObject: JSON.stringify(documentType)
        }));
    }

    private async getPhysiciansList(
        filter: string,
        limit: number,
        providerIds: string[],
        includeInactive: boolean
    ): Promise<ITypeaheadOptions[]> {
        let physicians = await this._physicianService.getPhysiciansByName({
            name: filter,
            page: 1,
            pageLength: limit,
            providerIds,
            includeInactive
        });
        return (
            physicians?.items?.map((physician) => ({
                name: physician.name,
                value: physician.id,
                dataObject: JSON.stringify(physician),
                status: physician?.status
            })) ?? []
        );
    }

    private async getPecosCertifiedPhysiciansList(
        filter: string,
        limit: number,
        providerIds: string[],
        includeInactive: boolean
    ): Promise<ITypeaheadOptions[]> {
        let physicians = await this._physicianService.getPhysiciansByName({
            name: filter,
            page: 1,
            pageLength: limit,
            providerIds,
            includeInactive
        });
        return (
            physicians?.items?.map((physician) => {
                let pecosStatus = physician.isPecosVerified ? "is PECOS verified" : "is not PECOS verified";
                let nameWithNpiPecos = `${this.formatPhyName(physician.name)} - NPI: ${physician.npi} ${pecosStatus}`;
                return {
                    name: nameWithNpiPecos,
                    npi: physician.npi,
                    value: physician.id,
                    dataObject: JSON.stringify(physician),
                    status: physician?.status
                };
            }) ?? []
        );
    }

    private formatPhyName(fullName: string) {
        let nameSplitIndex = fullName.indexOf(" ");
        let firstName = fullName.substring(0, nameSplitIndex);
        let lastName = fullName.substring(nameSplitIndex + 1);
        return `${lastName.toLocaleUpperCase()}, ${firstName.toLocaleUpperCase()}`;
    }

    private async getUsersList(filter: string, limit: number): Promise<ITypeaheadOptions[]> {
        let users = await this._usersService.getUsersLean({
            term: filter,
            page: 1,
            pageLength: 10
        });
        return (
            users?.items?.map((user) => ({
                name: formatName(user.firstName, user.lastName),
                value: user.id,
                dataObject: JSON.stringify(user),
                status: user?.status
            })) ?? []
        );
    }

    private async getPatientsList(filter: string, limit: number): Promise<ITypeaheadOptions[]> {
        await this.getLineOfService();
        let patients = await this._patientsService.getPatientsLean({
            term: filter,
            page: 1,
            pageLength: 10,
            lineOfService: this._lineOfService
        });
        return (
            patients?.items?.map((user) => ({
                name: formatName(user.firstName, user.lastName),
                value: user.id,
                dataObject: JSON.stringify(user)
            })) ?? []
        );
    }

    private async getLineOfService() {
        if (!this._lineOfService) {
            this._lineOfService = await this._accountsService.getLineOfService();
        }
    }

    private async getBereavedContactsList(filter: string, patientId: string): Promise<ITypeaheadOptions[]> {
        let contacts = await this._bereavementPOCService.getContact({ patientId: patientId });
        return (
            contacts?.contacts?.map((contact: any) => ({
                name: formatName(contact.firstName, contact.lastName),
                value: contact.id,
                dataObject: JSON.stringify(contact)
            })) ?? []
        );
    }

    private async getCaseManagerList(filter: string, providerIds: string[]): Promise<ITypeaheadOptions[]> {
        if (!this.agencyRolesEnum) {
            this.agencyRolesEnum = await this._enumsService.getAgencyRoles();
        }
        let enumMap = new EnumMap(this.agencyRolesEnum);
        let caseManagerRole = enumMap.getEnumValue("Case Manager");
        let users = await this._usersService.getUsersByRole({
            term: filter,
            role: [caseManagerRole] as number[],
            include: true,
            providerIds
        });
        return (
            users?.map((user) => ({
                name: formatName(user.firstName, user.lastName),
                value: user.id,
                dataObject: JSON.stringify(user)
            })) ?? []
        );
    }

    private async getCareTeamList(filter: string, providerIds: string[]): Promise<ITypeaheadOptions[]> {
        if (!this.teams) {
            this.teams = await this._teamsService.getTeamsLean({ providerIds });
        }
        return this.teams
            ?.map((team) => ({
                name: team.name,
                value: team.id,
                dataObject: JSON.stringify(team)
            }))
            .filter((team) => !filter || team.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
    }

    private async getAnatomicLocations(filter: string): Promise<ITypeaheadOptions[]> {
        let anatomicLocations = await this._lookupService.getAnatomicLocation(filter);
        return (
            anatomicLocations?.map((anatomicLocation) => ({
                name: anatomicLocation.name,
                value: anatomicLocation.id,
                dataObject: JSON.stringify(anatomicLocation)
            })) ?? []
        );
    }
}
