import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

import { IDeleteItemBehavior } from "../../../interfaces/form-builder/i-behavior";
import { FormButtonEvent } from "../form-input/form-button/form-button";

@autoinject
export class DeleteItemBehaviorHandler {
    private _ea: EventAggregator;

    public constructor(ea: EventAggregator) {
        this._ea = ea;
    }

    public handle(buttonBehavior: IDeleteItemBehavior, elementName: string) {
        buttonBehavior.itemName = elementName;
        this._ea.publish(FormButtonEvent.ListDeleteItem, buttonBehavior);
    }
}
