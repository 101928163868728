import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";
import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import Infrastructure from "../infrastructure";
import { OidcAuthorizeStep } from "./oidc-authorize-step";
import { OidcEventHandler } from "./oidc-event-handler";

// import { getLogger } from "../logging";
// https://github.com/IdentityModel/oidc-client-js/wiki
@autoinject
export class OidcConfiguration {
    // private readonly _logger = getLogger("oidc-client");
    private _oidcAuthorizeStep: OidcAuthorizeStep;
    private _ea: EventAggregator;
    private _oidcEventHandler: OidcEventHandler;
    private _userManager: UserManager;

    public constructor(oidcAuthorizeStep: OidcAuthorizeStep, ea: EventAggregator, oidcEventHandler: OidcEventHandler) {
        this._oidcAuthorizeStep = oidcAuthorizeStep;
        this._ea = ea;
        this._oidcEventHandler = oidcEventHandler;

        this._userManager = new UserManager(this.configureOidc());
    }

    public createUserManager() {
        this._oidcEventHandler.attach(this._userManager);
        return this._userManager;
    }

    private configureOidc(): UserManagerSettings {
        let authority = Infrastructure.authorityUrl;
        return {
            // popup_redirect_uri: this.sigInUrl,
            redirect_uri: this.getUrlFromLocation("/oidc/sign-in-redirect"),
            silent_redirect_uri: this.getUrlFromLocation("/oidc/sign-in-silent"),
            automaticSilentRenew: true,
            authority: `${authority}/.well-known/openid-configuration`,
            client_id: "Axxess-Hospice-Frontend",
            response_type: "id_token token",
            scope: "openid profile email",
            filterProtocolClaims: false,
            monitorSession: Infrastructure.isProdEnvironment,
            userStore: new WebStorageStateStore({ store: localStorage })
        };
    }

    // public configureOidcLogging(): Logger {
    //     return {
    //         error: this._logger.error,
    //         info: (x, y) => {
    //             if ((x + "").indexOf("UserManager.getUser: user loaded") != -1) {
    //                 return;
    //             }
    //             this._logger.info(x, y);
    //         },
    //         debug: this._logger.debug,
    //         warn: this._logger.warn
    //     };
    // }

    public configureRouter(config: RouterConfiguration, router: Router) {
        config.addAuthorizeStep(this._oidcAuthorizeStep);
        this._oidcEventHandler.attachToRouter(this._userManager, router);
    }

    private getUrlFromLocation(subPath: string) {
        return window.location.protocol + "//" + window.location.host + subPath;
    }
}
