import "./form-image.scss";

import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";

import nameof from "../../common/nameof";
import { IImage } from "../../interfaces/form-builder/i-element";
import {getColumnClasses} from "../../common/utilities/column-classes";

@autoinject
export class FormImage {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public element: IImage;

    @computedFrom(nameof<FormImage>("element"))
    public get columnsClasses(): string {
        let columns = this.element.columns;
        return getColumnClasses(columns);
    }

    public async activate(params: IImage) {
        Object.assign(this, params);
    }
}
