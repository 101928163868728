import "./dashboard-grid-new.scss";

import {bindingMode} from "aurelia-binding";
import {bindable, computedFrom, containerless} from "aurelia-framework";

import nameof from "../../../common/nameof";
import {IDashboardGridItems, IDashBoardGridNavParams} from "../../../interfaces/custom-elements/i-dashboard-grid";

@containerless
export class DashboardGridNew {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public itemColumnSize: number = 6;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public dashboardGridHeight: number = 50;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public gridItems: IDashboardGridItems<any>[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public loadingCount: number = 4;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public navigationCallback: (params: IDashBoardGridNavParams) => void;

    @computedFrom(nameof<DashboardGridNew>("itemColumnSize"))
    public get itemsPerRow() {
        return Math.floor(12 / this.itemColumnSize);
    }

    public bind() {
        if (this.gridItems?.length === 1) {
            this.itemColumnSize = 12;
        }
    }

    public getBorderClass(index: number) {
        if (this.gridItems?.length === 1) {
            return "";
        }
        index = index + 1;
        let classes = "";
        if (index > this.itemsPerRow) {
            classes = "top-line";
        }

        if (index % this.itemsPerRow !== 0) {
            classes += " right-line";
        }

        return classes;
    }

    public navigate(item: IDashboardGridItems<any>) {
        this.navigationCallback({ data: item.metaData });
    }
}
