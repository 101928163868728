import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    IGetConversationChunkQuery,
    IGetConversationQuery,
    IGetConversationResult,
    IGetMessageQuery,
    IGetMessageResult,
    IGetSharedAssetQuery,
    IGetSharedAssetResult,
    IMessageItem,
    INewMessage,
    ISendMessageQuery,
    ISendMessageResult,
    IDeleteMessages
} from "../interfaces/i-message";

@autoinject()
export class MessageService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/conversations";
    private _baseUrlV2: string = "/api/v2/conversations";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getConversations(query: IGetConversationQuery) {
        return await this._httpClient.build(`${this._baseUrl}`).useQueryString(query).fetch<IGetConversationResult>();
    }

    public async getUnreadConversations(query: IGetConversationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/unread`)
            .useQueryString(query)
            .fetch<IGetConversationResult>();
    }

    public async getMessages(conversationId: string, query: IGetMessageQuery) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/${conversationId}/messages`)
            .useQueryString(query)
            .fetch<IGetMessageResult>();
    }

    public async getUnReadMessages(conversationId: string) {
        return await this._httpClient.build(`${this._baseUrlV2}/${conversationId}/unread`).fetch<IMessageItem[]>();
    }

    public async getConversationChunk(conversationId: string, query: IGetConversationChunkQuery) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/${conversationId}/chunk`)
            .useQueryString(query)
            .fetch<IMessageItem[]>();
    }

    public async newConversation(newMessage: INewMessage) {
        return await this._httpClient.build(`${this._baseUrlV2}`).useMethod("POST").useBody(newMessage).fetch<any>();
    }

    public async sendMessage(conversationId: string, query: ISendMessageQuery) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/${conversationId}/message`)
            .useMethod("PUT")
            .useBody(query)
            .fetch<ISendMessageResult>();
    }

    public async getSharedAssets(conversationId: string, query: IGetSharedAssetQuery) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/${conversationId}/assets`)
            .useQueryString(query)
            .fetch<IGetSharedAssetResult[]>();
    }

    public async deleteMessages(conversationId: string, data: IDeleteMessages) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/${conversationId}/messages`)
            .useMethod("DELETE")
            .useBody(data)
            .fetchNoContent();
    }

    public async getDeletedMessages(conversationId: string, query: IGetMessageQuery) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/${conversationId}/deleted-messages`)
            .useQueryString(query)
            .fetch<IGetMessageResult>();
    }
}
