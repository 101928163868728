import "./allergy-form.scss";

import { observable } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable, bindingMode, computedFrom, customElement } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import moment from "moment";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IAllergy } from "../../../interfaces/allergy/i-allergy";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IGetDrug } from "../../../interfaces/i-medication";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { MedicationService } from "../../../services/medication-service";
import { ToastrService } from "../../../services/toastr-service";

@autoinject
@customElement("allergy-form")
export class AllergyForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public allergy: IAllergy;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public controller: ValidationController;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public isEndDateRequired: (args: { startDate: string }) => string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public title: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public allergyTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public allergySeverityEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public allergyTypeEnumMap: EnumMap;
    private readonly _medicationService: MedicationService;
    private readonly _toastrService: ToastrService;
    @observable({
        changeHandler: nameof<AllergyForm>("selectedTypeChanged")
    })
    public selectedType: number = null;
    @observable({
        changeHandler: nameof<AllergyForm>("startDateChanged")
    })
    public startDate: string;
    @observable({
        changeHandler: nameof<AllergyForm>("endDateChanged")
    })
    public endDate: string;
    @observable({
        changeHandler: nameof<AllergyForm>("selectedMedicationChanged")
    })
    public selectedMedication: ITypeaheadOptions;
    public showTypeDescription: boolean = false;
    public showEndDateValidation: boolean = false;
    public isMedication: boolean = false;
    public isAddCustomMedRequested: boolean = false;
    public medicationList: IGetDrug[] = [];
    public newCustomMedication: string = "";
    public isAddCustomMedInProgress: boolean = false;
    public medicationValidation: IValidateCustomElement = {
        required: true,
        displayName: "Allergy Name"
    };

    public startDatePickerOptions: DatepickerOptions = {
        endDate: moment().format("MM/DD/YYYY")
    };
    public isMedispan: boolean = false;

    @computedFrom(`${nameof<AllergyForm>("allergy")}.${nameof<IAllergy>("startDate")}`)
    public get datePickerOptions(): DatepickerOptions {
        let baseDatePickerOptions = {
            autoclose: true
        };
        if (this.allergy?.startDate) {
            return Object.assign(baseDatePickerOptions, {
                startDate: moment(this.allergy.startDate).format("MM/DD/YYYY"),
                endDate: moment().format("MM/DD/YYYY")
            });
        }
        return baseDatePickerOptions;
    }

    public constructor(medicationService: MedicationService, toastrService: ToastrService) {
        this._medicationService = medicationService;
        this._toastrService = toastrService;
    }

    public attached() {
        this.initValidation();
        if (!!this.allergy) {
            this.startDate = this.allergy.startDate;
            this.endDate = this.allergy.endDate;
            this.selectedType = this.allergy.type;
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: AllergyForm) => x.selectedType)
            .required()
            .withMessage("Type is required")
            .ensure((x: AllergyForm) => x.startDate)
            .required()
            .withMessage("Start Date is required.")
            .on(this);
    }

    public async fetchMedications(term: string) {
        try {
            if (term?.length <= 0) {
                return [];
            }
            this.medicationList = await this._medicationService.getDrugs({
                term,
                providerIds: [this.providerId]
            });
            let medispanMedication = this.medicationList.find((med) => med?.isMedispan);
            this.isMedispan = medispanMedication?.isMedispan;
            let medicationOptions = this.medicationList.map((med: IGetDrug) => ({
                name: med.medicationName,
                value: !med.customMedicationId ? med.mediSpanDrugDescriptorId.toString() : med.customMedicationId
            }));
            return medicationOptions;
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Error",
                message: "There was a problem while fetching the medication list. Please try again."
            });
            throw e;
        }
    }

    public startDateChanged(newValue: string, oldValue: string) {
        if (!!newValue && newValue !== oldValue) {
            this.allergy.startDate = this.startDate;
            let dateToCompare = this.isEndDateRequired({ startDate: newValue });
            if (!!dateToCompare) {
                this.showEndDateValidation = true;
                this.initEndDateValidation(dateToCompare);
            } else {
                this.showEndDateValidation = false;
            }
        }
    }

    private initEndDateValidation(compareDate: string) {
        ValidationRules.ensure((x: AllergyForm) => x.endDate)
            .satisfies((val, x: AllergyForm) => moment(val).isBefore(moment(compareDate)))
            .when((x: AllergyForm) => x.showEndDateValidation)
            .withMessage("End Date should be earlier than No Known Allergy Start Date")
            .on(this);
    }

    public endDateChanged(newValue: string, oldValue: string) {
        if (!!newValue && newValue !== oldValue) {
            this.allergy.endDate = this.endDate;
        }
    }

    public async selectedMedicationChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            let selectedMedication: IGetDrug;
            if (this.isMedispan) {
                selectedMedication = this.medicationList.find((medication) => {
                    let medId = !medication.customMedicationId
                        ? medication.mediSpanDrugDescriptorId.toString()
                        : medication.customMedicationId;
                    return medication.medicationName === newValue.name && medId === newValue.value;
                });
            } else {
                selectedMedication = this.medicationList.find((medication) => {
                    return (
                        medication.medicationName === newValue.name &&
                        (medication.lexiDrugId === newValue.value || medication.customMedicationId === newValue.value)
                    );
                });
            }
            if (selectedMedication) {
                this.allergy.allergy = selectedMedication.medicationName;
                this.allergy.lexiDrugId = selectedMedication.lexiDrugId;
                this.allergy.synonymId = selectedMedication.synonymId;
                this.allergy.customMedicationId = selectedMedication.customMedicationId;
                this.allergy.classification = selectedMedication.classification;
                this.allergy.mediSpanDrugDescriptorId = selectedMedication.mediSpanDrugDescriptorId;
            }
        }
    }

    public async addCustomMedication(term: string) {
        this.isAddCustomMedRequested = true;
        this.newCustomMedication = term;
    }

    public selectedTypeChanged(newValue: number, oldValue: number) {
        if (!!newValue && newValue !== oldValue) {
            this.controller?.reset();
            this.allergy.type = newValue;
            this.isMedication = this.allergyTypeEnumMap.checkEnumValue("Medication", newValue);
            if (this.isMedication) {
                this.selectedMedication = {
                    name: this.allergy.allergy,
                    value: this.allergy.lexiDrugId ? this.allergy.lexiDrugId : this.allergy.customMedicationId
                };
            }
            this.showTypeDescription = this.allergyTypeEnumMap.checkEnumValue("other", newValue);
            if (!this.showTypeDescription) {
                this.allergy.otherTypeDescription = null;
            }
        }
    }

    public async createCustomMedication() {
        try {
            this.isAddCustomMedInProgress = true;
            let createCustomMedicationResponse = await this._medicationService.createCustomMedication({
                medicationName: this.newCustomMedication
            });
            this.selectedMedication = {
                name: this.newCustomMedication,
                value: createCustomMedicationResponse.id
            };
            this.allergy.allergy = this.newCustomMedication;
            this.allergy.customMedicationId = createCustomMedicationResponse.id;
            this.isAddCustomMedRequested = false;
            this._toastrService.success({
                title: "Custom Medication Created",
                message: `Custom medication <b>${this.newCustomMedication}</b> has been successfully created.`
            });
        } catch (err) {
            let message = "";
            console.error(err);
            if (err.statusMessage === "Conflict") {
                message = `Custom medication <b>${this.newCustomMedication}</b> is already present.`;
            } else {
                message = `There was a problem while creating custom medication, <b>${this.newCustomMedication}</b>. Please try again.`;
            }
            this._toastrService.error({
                title: "Error",
                message: message
            });
        } finally {
            this.isAddCustomMedInProgress = false;
        }
    }

    public isCustomMed(medicationValue: string) {
        return !!this.medicationList.find((medication) => medication.customMedicationId == medicationValue);
    }

    public cancelUpdateMedication() {
        this.isAddCustomMedRequested = false;
    }

    public refreshSelectedData() {
        this.selectedType = null;
        this.startDate = null;
        this.endDate = null;
        this.showTypeDescription = false;
        this.selectedMedication = null;
    }

    public detached() {
        this.refreshSelectedData();
    }
}
