import {ValidationRules} from "aurelia-validation";

import {ICreateTask} from "../interfaces/i-schedule";

export class NewTask implements ICreateTask {
    public taskId: string = null;
    public metaId: string = null;
    public patientId: string = null;
    public userId: string = null;
    public startDates: string[] = [];
    public endDates: string[] = [];
    public comments: string = null;
    public onCall: boolean = false;
    public patientPayerId: string = null;

    public constructor(data?: ICreateTask) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((task: ICreateTask) => task.patientPayerId)
            .required()
            .withMessage("Payer is required.")
            .on(this);
    }
}
