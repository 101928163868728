import "./date-picker.scss";
import "bootstrap-datepicker";
import "custom-event-polyfill";

import { autoinject, bindable, bindingMode, customElement, inject } from "aurelia-framework";

// custom-event-polyfill is used for custom events to work in IE9/10/11.
// As of 03/23/2018, Axxess products support IE11
// https://www.npmjs.com/package/custom-event-polyfill

@customElement("date-picker")
@inject(Element)
@autoinject
export class DatePicker {
    // INFO: @children and slots do not work well together.
    // @children("input, div.input-group.date, div.input-group.input-daterange")
    // public pickerFields: HTMLElement[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public options: DatepickerOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    public $el: JQuery<HTMLElement>;
    private _datepicker: JQuery;
    private _self: HTMLElement;

    public constructor(element: Element) {
        this._self = element as HTMLElement;
    }

    public attached() {
        this.$el = jQuery(this._self).children("input, div.input-group.date, div.input-group.input-daterange");
        this.initDatePicker();
    }

    public optionsChanged() {
        if (this._datepicker && this.$el) {
            this._datepicker.datepicker("destroy");
            this._datepicker = undefined;
            this.initDatePicker();
        }
    }

    private initDatePicker() {
        if (!this._datepicker && this.$el && !this.disabled && this.$el.toArray().length === 1) {
            if (this.options != null) {
                this._datepicker = this.$el.datepicker(this.options);
            } else {
                this._datepicker = this.$el.datepicker({ autoclose: true });
            }

            this._datepicker.on("changeDate", (event: DatepickerEventObject) => {
                let changeEvent = new CustomEvent("change", {
                    detail: {},
                    bubbles: true
                });

                // Target = input(s), input(s) was modified outside of dom events, need to notify the binder.
                // Unfortunately, we must find the inputs here since the target is not always the input.
                let inputs = jQuery("input", event.target.parentElement);
                for (let input of inputs.toArray()) {
                    input.dispatchEvent(changeEvent);
                }
                // Anyone listening on the custom element.
                this._self.dispatchEvent(changeEvent);
            });
        } else {
            if (!this.$el || this.$el.toArray().length !== 1) {
                console.warn(`The date-picker custom element must contain one child of type input[type=text] or div.input-group.date or div.input-group.input-daterange.
                             When using ranges, the input-group must follow standard bootstrap formats.
                             See https://uxsolutions.github.io/bootstrap-datepicker/.`);
            }
        }

    }

    /**
     * Note that this does not update the fields, only the internal dates used by the date picker.
     * @param dates
     */
    // public update(...dates: (string | Date)[]) {
    //     if (dates.length < 1 || dates.length > 2) {
    //         throw new Error("Invalid number of dates given, should be either 1 or 2 (for date range).");
    //     }

    //     if (dates.length != 1) {
    //         this._datepicker.datepicker("setStartDate", dates[0]);
    //         this._datepicker.datepicker("setEndDate", dates[1]);
    //     } else {
    //         this._datepicker.datepicker("setDate", dates[0]);
    //     }
    // }

    public disabledChanged(newValue: boolean) {
        if (!this._datepicker && typeof newValue == "boolean") {
            if (!newValue) {
                this.initDatePicker();
            }
        } else if (this._datepicker && typeof newValue == "boolean") {
            this._datepicker.datepicker("disabled", newValue);
        }
    }

    public updateDates(dates: string[] | Date[]) {
        if (dates.length < 1 || dates.length > 2) {
            throw new Error("Invalid number of dates given, should be either 1 or 2 (for date range).");
        }
        if (dates.length !== 1) {
            this._datepicker.datepicker("update", [dates[0], dates[1]]);
        } else {
            this._datepicker.datepicker("update", dates[0]);
        }
    }

    public detached() {
        if (this._datepicker) {
            this._datepicker.datepicker("destroy");
            this._datepicker = undefined;
        }
    }
}
