import { bindable, bindingMode, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";

import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import nameof from "./../../../common/nameof";

export class UploadDocument {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public newFile: File;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public acceptableFileType: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public documentName: string;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public buttonText: string = "Choose File";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<UploadDocument>("clearFileChanged")
    })
    public clearFile: boolean = false;
    @observable({
        changeHandler: nameof<UploadDocument>("fileChanged")
    })
    public uploadFile: File;
    public changeFileInput: HTMLInputElement;

    public attached() {
        if (this.validation) {
            let displayName = this.validation.displayName;
            let message = this.validation.message;
            ValidationRules.ensure((x: UploadDocument) => x.documentName)
                .displayName(displayName)
                .required()
                .when((uploadDocument: UploadDocument) => uploadDocument.validation.required)
                .withMessage(message ? message : `${displayName} is required.`)
                .on(this);
        }
        if (this.clearFile) {
            this.clearInput();
        }
    }

    public openFileWindow() {
        $(this.changeFileInput).trigger("click");
    }

    public fileChanged(newValue: FileList) {
        if (newValue && newValue.length > 0) {
            let files: File[] = Array.from(newValue);
            this.newFile = files[0];
            this.documentName = files[0].name;
        }
    }

    public clearFileChanged(newVal: boolean) {
        if (newVal && !!this.changeFileInput?.value) {
            this.clearInput();
        }
    }

    public clearInput() {
        this.uploadFile = null;
        this.documentName = "";
        this.newFile = null;
        $(this.changeFileInput).val(null);
        this.clearFile = false;
    }
}
