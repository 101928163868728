import "./../../../../node_modules/jquery-timepicker/jquery.timepicker.js";
import "./time-picker.scss";
import "custom-event-polyfill";

import { autoinject, bindable, bindingMode, children, customElement, inject } from "aurelia-framework";

import { ITimePickerOptions } from "../../../interfaces/i-time-picker";

// NOTE: Inject should be before autoinject
@inject(Element)
@autoinject
@customElement("time-picker")
export class TimePicker {
    // TimePicker
    @children("input")
    public pickerFields: HTMLInputElement[] = [];
    public $el: JQuery<HTMLElement>;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public options: ITimePickerOptions = {
        timeFormat: "hh:mm p",
        interval: 15,
        dynamic: true,
        dropdown: true,
        scrollbar: true
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideClockIcon: boolean = false;
    private _timePicker: JQuery;
    private _self: HTMLElement;
    private updatedValue: Date = new Date();

    public constructor(element: Element) {
        this._self = element as HTMLElement;
    }

    public attached() {
        this.$el = jQuery(this._self).find("input");
        this.initTimePicker();
    }

    private initTimePicker() {
        if (!this._timePicker && this.$el && this.$el.toArray().length === 1) {
            let timePickerOptions = Object.assign(this.options, {
                change: (newTime: Date) => {
                    if (newTime) {
                        this.updatedValue = newTime;
                        let changeEvent = new CustomEvent("change", {
                            detail: {},
                            bubbles: true
                        });
                        // Target = input(s), input(s) was modified outside of dom events, need to notify the binder.
                        let inputs = jQuery("input", this._self);
                        for (let input of inputs.toArray()) {
                            input.dispatchEvent(changeEvent);
                        }
                        // Anyone listening on the custom element.
                        this._self.dispatchEvent(changeEvent);
                    }
                }
            });
            this._timePicker = (jQuery(this.$el) as any).timepicker(timePickerOptions);
            this.$el.on("focus", this.focusHandler);
        } else {
            if (!this.$el || this.$el.toArray().length !== 1) {
                console.warn(`The time-picker custom element must contain one child of type input[type=text].
                             When using ranges, the input-group must follow standard bootstrap formats.`);
            }
        }
    }

    public focusHandler() {
        let $window = jQuery(window);
        let $timepicker = jQuery(".ui-timepicker-standard");
        let timepickerHeight = $timepicker.outerHeight();
        let timepickerBottom = $timepicker.offset().top + timepickerHeight;
        // Check if the timepicker dropdown is overflowing the bottom of the screen.
        if (timepickerBottom >= $window.innerHeight()) {
            let inputHeight = jQuery(this).outerHeight();
            let newOffsetTop = $timepicker.offset().top - timepickerHeight - inputHeight;
            $timepicker.css("top", newOffsetTop);
        }
    }

    public detached() {
        if (this._timePicker) {
            this.$el.off("focus", this.focusHandler);
            (jQuery(this._timePicker) as any).timepicker("destroy");
            this._timePicker = undefined;
        }
    }
}
