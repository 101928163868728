import { bindable, bindingMode, computedFrom, containerless } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { formatName } from "../../../common/utilities/format-name";

@containerless
export class FormatName {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public firstName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public lastName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public format: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public middleInitial: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public suffix: string;

    @computedFrom(
        `${nameof<FormatName>("firstName")}`,
        `${nameof<FormatName>("lastName")}`,
        `${nameof<FormatName>("format")}`,
        `${nameof<FormatName>("middleInitial")}`,
        `${nameof<FormatName>("suffix")}`,
    )
    public get fullName() {
        return formatName(this.firstName, this.lastName, this.format, null, this.middleInitial, this.suffix);
    }
}
