import { autoinject } from "aurelia-framework";

import { EnumMap } from "../../../common/utilities/enum-map";
import { IGetBehavior, IGetPatientDiagBehavior } from "../../../interfaces/form-builder/i-behavior";
import { BranchesService } from "../../../services/branches-service";
import { EnumsService } from "../../../services/enums-service";
import { LookupService } from "../../../services/lookup-service";
import { PatientsService } from "../../../services/patient-service";
import { UsersService } from "../../../services/users-service";
import { FormatDateTime } from "../../value-converters/date-and-time";
import { DocumentTypeService } from "./../../../services/document-types-service";
import { ProvidersService } from "./../../../services/providers-service";
import { PatientDiagnosisBehaviorEnum } from "./patient-diag-behavior-enum";

enum FetchDataBehaviorComponent {
    AmericanStates = "americanstates",
    BenefitPeriod = "benefitperiod",
    DocumentTypes = "documentType",
    Enum = "enum",
    PatientPayers = "patientPayers",
    PatientDiagnosis = "patientDiagnoses",
    HospiceBranches = "hospicelocations",
    UserLocations = "userLocation"
}

@autoinject
export class FetchDataBehaviorHandler {
    private readonly _branchesService: BranchesService;
    private _usersService: UsersService;
    private _patientsService: PatientsService;
    private _enumsService: EnumsService;
    private _lookupService: LookupService;
    private _documentTypeService: DocumentTypeService;
    private readonly _providersService: ProvidersService;
    public dateFormatter: FormatDateTime = new FormatDateTime();

    public constructor(
        branchesService: BranchesService,
        usersService: UsersService,
        enumsService: EnumsService,
        patientsService: PatientsService,
        lookupService: LookupService,
        documentTypeService: DocumentTypeService,
        providersService: ProvidersService
    ) {
        this._lookupService = lookupService;
        this._documentTypeService = documentTypeService;
        this._patientsService = patientsService;
        this._enumsService = enumsService;
        this._providersService = providersService;
        this._branchesService = branchesService;
        this._usersService = usersService;
    }

    public async getList(behavior: IGetBehavior, patientId?: string, taskId?: string) {
        switch (behavior.component) {
            case FetchDataBehaviorComponent.UserLocations:
                return await this.getUserLocations();
            case FetchDataBehaviorComponent.AmericanStates:
                return await this.getAmericanStates();
            case FetchDataBehaviorComponent.DocumentTypes:
                return await this.getDocumentTypes(patientId);
            case FetchDataBehaviorComponent.BenefitPeriod:
                if (!patientId || !taskId) {
                    return [];
                }
                return await this.getBenefitPeriodData(patientId, taskId);
            case FetchDataBehaviorComponent.PatientPayers:
                if (!taskId) {
                    return [];
                }
                return await this.getPatientPayers(patientId, taskId);
            case FetchDataBehaviorComponent.PatientDiagnosis:
                if (!!behavior?.subComponent) {
                    return await this.getPatientDiagnosis(behavior as IGetPatientDiagBehavior, patientId);
                }
                return [];
            case FetchDataBehaviorComponent.HospiceBranches:
                return await this.getHospiceBranches();
            default:
                if (!behavior.action) {
                    return [];
                }
                return await this.getEnumValues(behavior.action);
        }
    }

    public async getUserLocations() {
        let locations = await this._usersService.getUserLocations();
        if (!locations?.length) {
            return [];
        }
        return locations.map((branch) => ({
            value: branch.id,
            name: branch.name
        }));
    }

    private async getEnumValues(action: string) {
        let enumValues = await this._enumsService.getEnum(action);
        enumValues.forEach((enumValue) => (enumValue.value = "" + enumValue.value));
        return enumValues;
    }

    private async getBenefitPeriodData(patientId: string, taskId: string) {
        let data = await this._patientsService.getBenefitPeriodsForRecert(patientId, taskId);
        if (!data.benefitPeriods) {
            return [];
        }
        return data.benefitPeriods.map((value) => ({
            name: `${this.dateFormatter.toView(
                value.isTransfer ? value.transferStartDate : value.startDate,
                "MM/DD/YYYY"
            )} -
                            ${this.dateFormatter.toView(value.endDate, "MM/DD/YYYY")}`,
            value: value.id
        }));
    }

    private async getDocumentTypes(patientId: string) {
        if (!patientId) {
            return [];
        }
        let patientInfo = await this._patientsService.getPatientSlim(patientId);
        let providers = await this._providersService.getProvider();
        let patientProvider = providers.find((provider) =>
            provider.branches.some((branch) => branch.id === patientInfo.branchId)
        );
        // this only accounts for patient document types as we only have this field for patient.
        let categoryTypeEnum = await this._enumsService.getDocumentTypeCategory();
        let categoryTypeEnumMap = new EnumMap(categoryTypeEnum);
        let category = Number(categoryTypeEnumMap.getEnumValue("patient"));
        let searchedTypes = await this._documentTypeService.getDocumentTypesList({
            providerIds: [patientProvider.id],
            category
        });
        if (!searchedTypes) {
            return [];
        }
        return searchedTypes.map((documentType) => ({
            value: documentType.id,
            name: documentType.type
        }));
    }

    private async getAmericanStates() {
        let searchedCodes = await this._lookupService.getAmericanStateCodes();
        if (!searchedCodes) {
            return [];
        }
        return searchedCodes.map((stateCode) => ({
            value: stateCode.value,
            name: stateCode.value
        }));
    }

    private async getPatientPayers(patientId: string, taskId: string) {
        let patientPayers = await this._patientsService.getPatientPayersByTask(patientId, taskId);
        if (!patientPayers) {
            return [];
        }
        return patientPayers.map((payer) => ({
            value: payer.id,
            name: payer.name
        }));
    }

    private async getPatientDiagnosis(behavior: IGetPatientDiagBehavior, patientId: string) {
        let showOnlyActive = false;
        if (behavior.subComponent === PatientDiagnosisBehaviorEnum.All) {
            showOnlyActive = false;
        } else {
            showOnlyActive = true;
        }
        let patientDiagCodes = await this._patientsService.getPatientDiagnosisCodes(patientId, showOnlyActive);
        if (!patientDiagCodes) {
            return [];
        }
        return patientDiagCodes.map((diagCode) => ({
            value: diagCode.code,
            name: diagCode.displayName
        }));
    }

    public async getHospiceBranches() {
        let lineOfServiceEnum = await this._enumsService.getLineOfService();
        let lineOfServiceEnumMap = new EnumMap(lineOfServiceEnum);
        let branches = await this._branchesService.getAllAgencyBranches();
        let hospiceBranches = branches.filter(
            (branch) => branch.lineOfService === lineOfServiceEnumMap.getEnumValue("Hospice")
        );
        return hospiceBranches.map((branch) => ({
            value: branch.id,
            name: branch.name
        }));
    }
}
