import { autoinject } from "aurelia-framework";

import { config } from "../common/config";
import { HospiceClient } from "../common/hospice-client";
import { IEnumResponse } from "../interfaces/i-enum";

@autoinject()
export class EnumsService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/enums";
    private _enumMap: Map<string, IEnumResponse[]> = new Map();
    public enumCacheKey = config.enumCacheKey;

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getEnum(resource: string): Promise<IEnumResponse[]> {
        // Local First
        let enumFromMap = this.getEnumFromMap(resource);
        if (!!enumFromMap) {
            return enumFromMap;
        }
        // LocalStorage next
        let cachedData = JSON.parse(localStorage.getItem(this.enumCacheKey));
        if (cachedData) {
            this._enumMap = new Map(cachedData);
            enumFromMap = this.getEnumFromMap(resource);
            if (!!enumFromMap) {
                return enumFromMap;
            }
        }
        // Server Last
        let enumData = await this._httpClient.build(`${this._baseUrl}/${resource}`).fetch<IEnumResponse[]>();
        this._enumMap.set(resource, enumData);
        // https://stackoverflow.com/questions/28918232/how-do-i-persist-a-es6-map-in-localstorage-or-elsewhere
        let dataToCache = JSON.stringify(Array.from(this._enumMap.entries()));
        localStorage.setItem(this.enumCacheKey, dataToCache);
        return enumData;
    }

    public getEnumFromMap(resource: string) {
        if (this._enumMap.size > 0 && this._enumMap.has(resource)) {
            return this._enumMap.get(resource);
        }
        return null;
    }

    public clearCachedEnums() {
        localStorage.removeItem(this.enumCacheKey);
    }

    public async getAllEnums() {
        this.clearCachedEnums();
        let enums = await this._httpClient.build(this._baseUrl).fetch<string[]>();
        enums.forEach((item: string) => {
            this.getEnum(item);
        });
    }

    public async getCustomTasksRequestType() {
        return await this.getEnum("RequestType");
    }

    public async getAdministeredBy() {
        return await this.getEnum("AdministeredBy");
    }

    public async getMedicationType() {
        return await this.getEnum("MedicationType");
    }

    public async getScheduleStatus() {
        return await this.getEnum("ScheduleStatus");
    }

    public async getVolunteerTaskType() {
        return await this.getEnum("VolunteerTaskType");
    }

    public async getPayrollReportScheduleStatus() {
        return await this.getEnum("PayrollReportScheduleStatus");
    }

    public async getScheduleStatusForMissedVisit() {
        return await this.getEnum("ScheduleStatusForMissedVisit");
    }

    public async getScheduleStatusForVolunteerActivity() {
        return await this.getEnum("ScheduleStatusForVolunteerActivity");
    }

    public async getServiceLocations() {
        return await this.getEnum("ServiceLocations");
    }

    public async getPaymentSources() {
        return await this.getEnum("PaymentSources");
    }

    public async getClaimHoldSigns() {
        return await this.getEnum("ClaimHoldSigns");
    }

    public async getCcHoursSign() {
        return await this.getEnum("ContinuousCareNursingHoursSign");
    }

    public async getReferralType() {
        return await this.getEnum("ReferralType");
    }

    public async getReferralStatus() {
        return await this.getEnum("ElectronicReferralStatus");
    }

    public async getElectronicReferralPatientStatus() {
        return await this.getEnum("ElectronicReferralPatientStatus");
    }

    public async getRejectReferralReasons() {
        return await this.getEnum("ElectronicReferralRejectionReason");
    }

    public async getAdmittedFromLocations() {
        return await this.getEnum("AdmittedFromLocations");
    }

    public async getGender() {
        return await this.getEnum("Gender");
    }

    public async getGenderPronoun() {
        return await this.getEnum("GenderPronoun");
    }

    public async getMaritalStatuses() {
        return await this.getEnum("MaritalStatus");
    }

    public async getEthnicities() {
        return await this.getEnum("Ethnicities");
    }

    public async getAgencyRoles() {
        return await this.getEnum("AgencyRoles");
    }

    public async getLegalRepresentativeTypes() {
        return await this.getEnum("LegalRepresentativeTypes");
    }

    public async getServicesCurrentlyReceiving() {
        return await this.getEnum("ServicesCurrentlyReceiving");
    }

    public async getAdvancedDirectives() {
        return await this.getEnum("AdvancedDirectives");
    }

    public async getEmergencyPriorities() {
        return await this.getEnum("EmergencyPreparednessPriorities");
    }

    public async getEmergencyPreparednessInformation() {
        return await this.getEnum("EmergencyPreparednessInformation");
    }

    public async getEvacuationZone() {
        return await this.getEnum("EvacuationZone");
    }

    public async getVendorTypes() {
        return await this.getEnum("VendorType");
    }

    public async getClaimFormType() {
        return await this.getEnum("ClaimFormType");
    }

    public async getClearingHouses() {
        return await this.getEnum("ClearingHouses");
    }

    public async getSourceOfPaymentsPayors() {
        return await this.getEnum("SourceOfPayments");
    }

    public async getInterChangeQualifiers() {
        return await this.getEnum("InterChangeQualifiers");
    }

    public async getRelationshipToInsured() {
        return await this.getEnum("RelationshipToInsured");
    }

    public async getPatientStatus() {
        return await this.getEnum("PatientStatus");
    }

    public async getBillHoldsFilter() {
        return await this.getEnum("BillHoldFilter");
    }

    public async getBillHoldsReportFilter() {
        return await this.getEnum("BillHoldReportFilter");
    }

    public async getLevelOfCare() {
        return await this.getEnum("LevelOfCare");
    }

    public async getLevelOfCareFilter() {
        return await this.getEnum("LevelOfCareFilter");
    }

    public async getSourceOfReferral() {
        return await this.getEnum("SourceOfReferral");
    }

    public async getLineOfService() {
        return await this.getEnum("LineOfService");
    }

    public async getReferralSourceRelationship() {
        return await this.getEnum("ReferralSourceRelationship");
    }

    public async getInterpreters() {
        return await this.getEnum("Interpreter");
    }

    public async advancedCarePlanDirectives() {
        return await this.getEnum("AdvancedCarePlanDirectives");
    }

    public async codeStatus() {
        return await this.getEnum("CodeStatus");
    }

    public async advancedDirectiveType() {
        return await this.getEnum("AdvancedDirectiveType");
    }

    public async getAdvanceDirectivesForReport() {
        return await this.getEnum("advanceddirectivefilterforreport");
    }

    public async getDischargeReasons() {
        return await this.getEnum("DischargeReason");
    }

    public async getHospiceDischargeReasons() {
        return await this.getEnum("HospiceDischargeReason");
    }

    public async getPalliativeDischargeReasons() {
        return this.getEnum("PalliativeCareDischargeReason");
    }

    public async getDischargeReasonsForReport() {
        return await this.getEnum("DischargeReasonForReport");
    }

    public async getDisciplines() {
        return await this.getEnum("Discipline");
    }

    public async getDisciplineForReport() {
        return await this.getEnum("DisciplineForReport");
    }

    public async getTaskStatusForCompDueReport() {
        return await this.getEnum("TaskStatusForComprehensiveAssessmentDue");
    }

    public async getInterventionDisciplines() {
        return await this.getEnum("InterventionDisciplines");
    }

    public async getClaimNoteType() {
        return await this.getEnum("ClaimNoteType");
    }

    public async getAdmissionSource() {
        return await this.getEnum("AdmissionSource");
    }

    public async getClaimPatientStatus() {
        return await this.getEnum("ClaimPatientStatus");
    }

    public async getAdmissionType() {
        return await this.getEnum("AdmissionType");
    }

    public async getCredentials() {
        return await this.getEnum("Credentials");
    }

    public async getUserTitles() {
        return await this.getEnum("UserTitles");
    }

    public async getUserTypes() {
        return await this.getEnum("UserType");
    }

    public async getClaimStatus() {
        return await this.getEnum("ClaimStatus");
    }

    public async getClaimStatusWithBalance() {
        return await this.getEnum("ClaimStatusWithBalance");
    }

    public async getLinkableClaimStatus() {
        return await this.getEnum("LinkableClaimStatus");
    }

    public async getMedicationGroup() {
        return await this.getEnum("MedicationGroup");
    }

    public async getAllergySeverity() {
        return await this.getEnum("AllergySeverity");
    }

    public async getAllergyType() {
        return await this.getEnum("AllergyType");
    }

    public async getClaimStatusForClaimSummary() {
        return await this.getEnum("ClaimStatusForClaimSummaryReport");
    }

    public async getTaxIdType() {
        return await this.getEnum("TaxIdType");
    }

    public async getTimeZones() {
        return await this.getEnum("TimeZones");
    }

    public async getQaTaskStatuses() {
        return await this.getEnum("QaTaskStatus");
    }

    public async getParentPermission() {
        return await this.getEnum("ParentPermission");
    }

    public async getPermissionActions() {
        return await this.getEnum("PermissionActions");
    }

    public async getPermissionGroup() {
        return await this.getEnum("PermissionGroup");
    }

    public async getPermissionValue() {
        return await this.getEnum("PermissionValue");
    }

    public async getUserStatus() {
        return await this.getEnum("UserStatus");
    }

    public async getGoalsAction() {
        return await this.getEnum("GoalsAction");
    }

    public async getTeamType() {
        return await this.getEnum("TeamType");
    }

    public async getNoeType() {
        return await this.getEnum("NoeType");
    }

    public async getBillRateType() {
        return await this.getEnum("BillRateType");
    }

    public async getBillUnitType() {
        return await this.getEnum("BillUnitType");
    }

    public async getHisType() {
        return await this.getEnum("HisType");
    }

    public async getMissedVisitReason() {
        return await this.getEnum("MissedVisitReason");
    }

    public async getHisStatus() {
        return await this.getEnum("HisStatus");
    }

    public async getUpdateHisStatus() {
        return await this.getEnum("HisUserUpdateStatus");
    }

    public async getHospiceNonAdmitReason() {
        return await this.getEnum("HospiceNonAdmitReason");
    }

    public async getPalliativeNonadmitReason() {
        return await this.getEnum("PalliativeNonadmitReason");
    }

    public async getHospiceMeetingRecurrence() {
        return await this.getEnum("HospiceMeetingRecurrence");
    }

    public async getPalliativeMeetingRecurrence() {
        return await this.getEnum("PalliativeMeetingRecurrence");
    }

    public async getRepeatOnDay() {
        return await this.getEnum("RepeatOnDay");
    }

    public async getRecertCategory() {
        return await this.getEnum("RecertCategory");
    }

    public async getRecertTask() {
        return await this.getEnum("RecertificationTask");
    }

    public async getMeetingPatientCategory() {
        return await this.getEnum("MeetingPatientCategory");
    }

    public async getGoalCompletedByDate() {
        return await this.getEnum("GoalCompletedByDate");
    }

    public async getOrderType() {
        return await this.getEnum("OrderType");
    }

    public async getFrequencyDiscipline() {
        return await this.getEnum("FrequencyDiscipline");
    }

    public async getOrderAge() {
        return await this.getEnum("OrderAge");
    }

    public async getMedicareWeek() {
        return await this.getEnum("MedicareWeek");
    }

    public async getPatientStatusForReports() {
        return await this.getEnum("PatientStatusForReports");
    }

    public async getOrderTaskType() {
        return await this.getEnum("OrderTaskType");
    }

    public async getContactRelationship() {
        return await this.getEnum("ContactRelationship");
    }

    public async getContactRole() {
        return await this.getEnum("ContactRole");
    }

    public async getVisitAlertType() {
        return await this.getEnum("VisitAlertType");
    }

    public async getTransactionType() {
        return await this.getEnum("TransactionType");
    }

    public async getPayType() {
        return await this.getEnum("PayRateType");
    }

    public async getRateType() {
        return await this.getEnum("RateType");
    }

    public async getReferralReadiness() {
        return await this.getEnum("ReferralReadiness");
    }

    public async getVerbalCertification() {
        return await this.getEnum("VerbalCertification");
    }

    public async getClaimTypeForReport() {
        return await this.getEnum("ClaimTypeForReport");
    }

    public async getClaimReadyBillingStatus() {
        return await this.getEnum("ClaimReadyBillingStatus");
    }

    public async getPayrollFrequency() {
        return await this.getEnum("PayrollFrequency");
    }

    public async getWeekDays() {
        return await this.getEnum("dayOfWeek");
    }

    public async getFacilityQualifier() {
        return await this.getEnum("FacilityQualifier");
    }

    public async getPayorType() {
        return await this.getEnum("PayorType");
    }

    public async getPreSubmissionClaimStatus() {
        return await this.getEnum("PreSubmissionClaimStatus");
    }

    public async getReimbursementType() {
        return await this.getEnum("ReimbursementType");
    }

    public async getClaimSection() {
        return await this.getEnum("ClaimSection");
    }

    public async getEntitiesForDataLoad() {
        return await this.getEnum("DataLoadType");
    }

    public async getDataLoadStatus() {
        return await this.getEnum("DataLoadStatus");
    }

    public async getPayrollType() {
        return await this.getEnum("PayrollType");
    }

    public async getReasonsPreventingBilling() {
        return await this.getEnum("ReasonsPreventingBilling");
    }

    public async getRiskLevel() {
        return await this.getEnum("RiskLevel");
    }

    public async getScreeningType() {
        return await this.getEnum("screeningType");
    }

    public async getInfectionType() {
        return await this.getEnum("InfectionTypeFlags");
    }

    public async getInfectionStatus() {
        return await this.getEnum("InfectionStatus");
    }

    public async getMedicareType() {
        return await this.getEnum("MedicareType");
    }

    public async getOrdersPendingQaCategory() {
        return await this.getEnum("OrdersPendingQaCategory");
    }

    public async getQaInfectionReportStatus() {
        return await this.getEnum("QaInfectionReportStatus");
    }

    public async getCertificationTasks() {
        return await this.getEnum("CertificationTasks");
    }

    public async getRoomAndBoardVendorType() {
        return await this.getEnum("RoomAndBoardVendorType");
    }

    public async getVeteranOptions() {
        return await this.getEnum("veteran");
    }

    public async getPayerSequenceCode() {
        return await this.getEnum("payerSequenceCode");
    }

    public async getClaimAdjustmentGroupCode() {
        return await this.getEnum("ClaimAdjustmentGroupCode");
    }

    public async getClaimAdjustmentReasonCode() {
        return await this.getEnum("ClaimAdjustmentReasonCode");
    }

    public async getPhysicianStatus() {
        return await this.getEnum("PhysicianStatus");
    }

    public async getPersonScreened() {
        return await this.getEnum("ScreeningReceiverType");
    }

    public async getGenerateExportStatus() {
        return await this.getEnum("GenerateExportStatus");
    }

    public async getReportExportHistoryType() {
        return await this.getEnum("ReportExportHistoryType");
    }

    public async getPatientFaceSheetSsn() {
        return await this.getEnum("PatientFaceSheetSsn");
    }

    public async getElectionAddendumRequestedType() {
        return await this.getEnum("ElectionAddendumRequestedType");
    }

    public async getElectionAddendumStatus() {
        return await this.getEnum("ElectionAddendumStatus");
    }

    public async getProviderValueSource() {
        return await this.getEnum("ProviderValueSource");
    }

    public async getProviderType() {
        return await this.getEnum("ProviderType");
    }

    public async getPlaceOfService() {
        return await this.getEnum("PlaceOfService");
    }

    public async getProviderEntityType() {
        return await this.getEnum("ProviderEntityType");
    }

    public async getInterventionOptions() {
        return await this.getEnum("InterventionOption");
    }

    public async getGoalOptions() {
        return await this.getEnum("GoalOption");
    }

    public async getServiceOptions() {
        return await this.getEnum("ServiceOption");
    }

    public async getResolutionReason() {
        return await this.getEnum("ResolutionReason");
    }

    public async getAuthorizationType() {
        return await this.getEnum("AuthorizationType");
    }

    public async getAuthorizationStatus() {
        return await this.getEnum("AuthorizationStatus");
    }

    public async getAuthorizationUnitType() {
        return await this.getEnum("AuthorizationUnitType");
    }

    public async getReportType() {
        return await this.getEnum("ReportType");
    }

    public async getDisciplineSubCategories() {
        return await this.getEnum("DisciplineSubCategory");
    }

    public async getDisciplineCategories() {
        return await this.getEnum("DisciplineCategories");
    }

    public async getCahpsColumnDetails() {
        return await this.getEnum("CahpsColumnDetails");
    }

    public async getMonthName() {
        return await this.getEnum("Months");
    }

    public async getRecurrences() {
        return await this.getEnum("Recurrences");
    }

    public async getBereavementRiskLevel() {
        return await this.getEnum("BereavementRiskLevel");
    }

    public async getMedicationActionType() {
        return await this.getEnum("MedicationActionType");
    }

    public async getClinicianLevelOfCare() {
        return await this.getEnum("ClinicianLevelOfCare");
    }

    public async getServiceRequested() {
        return await this.getEnum("ServiceRequested");
    }

    public async getDeliveryMethod() {
        return await this.getEnum("PreferredDeliveryMethod");
    }

    public async getReimbursementDisplayedType() {
        return await this.getEnum("reimbursementFilter");
    }

    public async getTaskType() {
        return await this.getEnum("TaskType");
    }

    public async getProviderServiceType() {
        return await this.getEnum("ProviderServiceType");
    }

    public async getPatientConsentStatus() {
        return await this.getEnum("PatientConsentStatus");
    }

    public async getConsentSource() {
        return await this.getEnum("ConsentSource");
    }

    public async getFastScore() {
        return await this.getEnum("FastScore");
    }

    public async getTemperatureUnit() {
        return await this.getEnum("TemperatureUnit");
    }

    public async getTemperatureRoute() {
        return await this.getEnum("TemperatureRoute");
    }

    public async getPulseLocation() {
        return await this.getEnum("PulseLocation");
    }

    public async getOxygenSaturationMethod() {
        return await this.getEnum("OxygenSaturationMethod");
    }

    public async getBloodPressurePosition() {
        return await this.getEnum("BloodPressurePosition");
    }

    public async getHeightScale() {
        return await this.getEnum("HeightScale");
    }

    public async getWeightScale() {
        return await this.getEnum("WeightScale");
    }

    public async getMidArmCircumferenceArm() {
        return await this.getEnum("MidArmCircumferenceArm");
    }

    public async getNyhaScore() {
        return await this.getEnum("NYHAScore");
    }

    public async getPercentileScale() {
        return await this.getEnum("PercentileScale");
    }

    public async getFastScoreShortened() {
        return await this.getEnum("FastScoreShortened");
    }

    public async getNyhaScoreShortened() {
        return await this.getEnum("NYHAScoreShortened");
    }

    public async getInternalReferralSource() {
        return await this.getEnum("InternalReferralSource");
    }

    public async getPalliativeReferralReadiness() {
        return await this.getEnum("PalliativeReferralReadiness");
    }

    public async getPalliativeCareOrderOptions() {
        return await this.getEnum("PalliativeCareOrderOptions");
    }

    public async getPayrollCenterStatus() {
        return await this.getEnum("PayrollCenterStatus");
    }

    public async getAdditionalColumns() {
        return await this.getEnum("PayrollCenterAdditionalColumns");
    }

    public async getPayrollVendors() {
        return await this.getEnum("payroll-vendors");
    }

    public async getPhysicianBillingType() {
        return await this.getEnum("PhysicianBillingType");
    }

    public async getFlaggedServiceLocation() {
        return await this.getEnum("FlaggedServiceLocation");
    }

    public async getFeeScheduleType() {
        return await this.getEnum("FeeScheduleType");
    }

    public async getPhoneTypes() {
        return await this.getEnum("PhoneType");
    }

    public async getClaimSignatureDates() {
        return await this.getEnum("claimSignatureDate");
    }

    public async getPayRateType() {
        return await this.getEnum("PayRateType");
    }

    public async getConsentDateOption() {
        return await this.getEnum("ConsentDateOption");
    }

    public async getEarningCodeUsage() {
        return await this.getEnum("EarningCodeUsage");
    }

    public async getAllPatientStatus() {
        return await this.getEnum("AllPatientStatus");
    }

    public async getHospitalizationReasons() {
        return await this.getEnum("HospitalizationReason");
    }

    public async getMrnCharacterType() {
        return await this.getEnum("MrnCharacterType");
    }

    public async getMrnGenerationType() {
        return await this.getEnum("MrnGenerationType");
    }

    public async getDocumentTypeCategory() {
        return await this.getEnum("DocumentTypeCategory");
    }

    public async getDocumentType() {
        return await this.getEnum("DocumentType");
    }

    public async getAdmittedPatientStatus() {
        return await this.getEnum("AdmittedPatientStatus");
    }

    public async getSignatureStatusEnum() {
        return await this.getEnum("SignatureStatus");
    }

    public async getPatientPendingReason() {
        return await this.getEnum("PatientPendingReason");
    }

    public async getNearDeathReportCredentials() {
        return await this.getEnum("NearDeathReportCredentials");
    }

    public async getReportExportFormat() {
        return await this.getEnum("ReportExportFormat");
    }

    public async getProviderServiceLineValueSource() {
        return await this.getEnum("ProviderServiceLineValueSource");
    }

    public async getLatePayrollItemStatus() {
        return await this.getEnum("LatePayrollItemStatus");
    }

    public async getCahpsSurveyContactType() {
        return await this.getEnum("CahpsSurveyContactType");
    }

    public async getDeliveryVendorEnum() {
        return await this.getEnum("ElectronicDeliveryVendor");
    }

    public async getVendorLevelOfCare() {
        return await this.getEnum("VendorLevelOfCare");
    }

    public async getVendorLOCType() {
        return await this.getEnum("LOCVendorType");
    }

    public async getEmploymentStatus() {
        return await this.getEnum("EmploymentStatus");
    }

    public async getExemptStatus() {
        return await this.getEnum("ExemptStatus");
    }

    public async getReferralReportPatientStatus() {
        return await this.getEnum("ReferralReportPatientStatus");
    }

    public async getFrequencyStatus() {
        return await this.getEnum("FrequencyStatus");
    }

    public async getExcludeTasks() {
        return await this.getEnum("excludeTasks");
    }

    public async getPatientCareTeamReportStatus() {
        return await this.getEnum("PatientCareTeamReportPatientStatus");
    }

    public async getRoomAndBoardTypeOfFacility() {
        return await this.getEnum("TypeOfBillFacilityInstitutional");
    }

    public async getRoomAndBoardTypeOfCare() {
        return await this.getEnum("TypeOfBillCareType");
    }

    public async getBereavementStatus() {
        return await this.getEnum("BereavementStatus");
    }

    public async getFeatures() {
        return await this.getEnum("Feature");
    }

    public async getBillingHoldScheduleStatus() {
        return await this.getEnum("BillingHoldScheduleStatus");
    }

    public async getServiceUserApplications() {
        return await this.getEnum("ServiceUserApplication");
    }

    public async getServiceUserStatus() {
        return await this.getEnum("ServiceUserStatus");
    }

    public async getWoundType() {
        return await this.getEnum("WoundType");
    }

    public async getPainIntensityLevel() {
        return await this.getEnum("PainIntensityLevel");
    }

    public async getPreferredMethodOfContact() {
        return await this.getEnum("PreferredMethodOfContact");
    }

    public async getInstitutionalProviderValueSource() {
        return await this.getEnum("institutionalProviderValueSource");
    }

    public async getQASettingDisciplines() {
        return await this.getEnum("QaSettingsDiscipline");
    }

    public async getWoundSide() {
        return await this.getEnum("WoundSide");
    }

    public async getAnatomicLocationGroup() {
        return await this.getEnum("AnatomicLocationGroup");
    }

    public async getProviderLogoDocumentType() {
        return await this.getEnum("ProviderLogoDocumentType");
    }

    public async getIncidentTypes() {
        return await this.getEnum("IncidentType");
    }

    public async getRoomAndBoardHCPCSSource() {
        return await this.getEnum("RoomAndBoardHCPCSSource");
    }

    public async getAgedArType() {
        return await this.getEnum("AgedArType");
    }

    public async getClosedAccountingFrequency() {
        return await this.getEnum("ClosedAccountingFrequency");
    }

    public async getAccountingPeriodStatus() {
        return await this.getEnum("AccountingPeriodStatus");
    }

    public async getUpdateAccountingPeriodStatus() {
        return await this.getEnum("UpdateAccountingPeriodStatus");
    }

    public async getUnbilledReportAgedArType() {
        return await this.getEnum("UnbilledReportAgedArType");
    }

    public async getInvoicePaymentTerms() {
        return await this.getEnum("InvoicePaymentTerms");
    }

    public async getDownloadType() {
        return await this.getEnum("DownloadType");
    }

    public async getActivityLogCategories() {
        return await this.getEnum("ActivityDomainType");
    }

    public async getActivityLogSubCategories() {
        return await this.getEnum("ActivityEntityType");
    }

    public async getBereavementServiceStaus() {
        return await this.getEnum("BereavementServicesStatusFilter");
    }

    public async getIntegratedService() {
        return await this.getEnum("IntegratedService");
    }
}
