import { autoinject } from "aurelia-dependency-injection";

import { HospiceClient } from "../common/hospice-client";
import Infrastructure, { ReleaseEnvironments } from "../common/infrastructure";
import {
    IGetUserNonPatientActivity,
    IGetUserNonPatientActivityQuery
} from "../interfaces/employee-schedule/i-non-patient-activity";
import { IPaginationNew, IPaginationRequest } from "../interfaces/i-pagination";
import {
    ICopyUserQuery,
    ICreatePayRate,
    IGetIsUserSalary,
    IGetPayRatesByPageQuery,
    IGetPayRatesByPageResult,
    IUpdatePayRate
} from "../interfaces/i-pay-rate";
import {
    ICreateReimbursementRate,
    IGetReimbursementRate,
    IGetReimbursementRatesByPageQuery,
    IUpdateReimbursementRate
} from "../interfaces/i-reimbursement-rate";
import {
    ICreateInterimUserResult,
    ICreateUserInviteCommand,
    ICreateUserInviteResult,
    IGetActiveUsersQuery,
    IGetRateCopyableUsersLeanQuery,
    IGetUserFlags,
    IGetUserPhotoResult,
    IGetUsersByRoleQuery,
    IGetUserSchedule,
    IGetUserScheduleQuery,
    IGetUsersLean,
    IGetUsersLeanQuery,
    IGetUsersLeanResult,
    IGetUserSlimInfoResult,
    IGetUsersQuery,
    IGetUsersResult,
    ISaveUserPermissionsQuery,
    IUpdateUserStatusQuery,
    IUserResult
} from "../interfaces/i-user";
import {
    ICreatePayType,
    IGetCurrentPayType,
    IGetPayType,
    IGetUserPayrollSettingsCheck
} from "../interfaces/i-user-payroll-settings";
import { IGetUserInfectionReport, IGetUserInfectionReportsQuery } from "../interfaces/users/i-infection-reports";
import { Permission } from "../models/permission";
import { User } from "../models/user";
import { UserPayrollSettings } from "../models/user-payroll-settings";
import {
    ICreateNpaPayRate,
    IGetCurrentPayPeriod,
    IGetNpaPayRate,
    IGetNpaPayRatesByPageQuery
} from "./i-providers-service";
import {
    IGetUserDocumentsQuery,
    IUpdateUserDocument,
    IUploadUserDocumentQuery,
    IUploadUserDocumentResult,
    IUserDocument,
    IUserProvider,
    IGetUserLocations,
    IGetUserCurrentPayPeriod,
    IGetServicePartners,
    IServicePartner,
    IUpdateServicePartnerLocations,
    IGetLocationsByTaskIdsQuery
} from "./i-users-services";
import {
    IGetUserVolunteerActivity,
    IGetVolunteerActivityQuery
} from "../interfaces/employee-schedule/i-volunteer-acitivty";
import { IBranchResponse } from "../interfaces/i-branch";

@autoinject
export class UsersService {
    private _user: IGetUserSlimInfoResult;
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/users";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createInterimUser() {
        return await this._httpClient
            .build(`${this._baseUrl}/interim`)
            .useMethod("POST")
            .fetch<ICreateInterimUserResult>();
    }

    public async postUserInvite(command: ICreateUserInviteCommand) {
        return await this._httpClient
            .build(`${this._baseUrl}/invite`)
            .useMethod("POST")
            .useBody(command)
            .fetch<ICreateUserInviteResult>();
    }

    public async createNewUser(userId: string, user: User) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/invite`)
            .useMethod("POST")
            .useBody(user)
            .fetch<ICreateUserInviteResult>();
    }

    public async isTemporaryUser() {
        if (Infrastructure.releaseEnvironment === ReleaseEnvironments.Local.toString()) {
            return true;
        }
        let currentUser = await this.getCurrentUser();
        return currentUser.isSupportUser;
    }

    public async getCurrentUser() {
        return await this._httpClient.build(`${this._baseUrl}/current`).fetch<IGetUserSlimInfoResult>();
    }

    public async getUsersLeanByIds(ids: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/leanbyids`)
            .useQueryString({ ids })
            .fetch<IGetUsersLean[]>();
    }

    public async getUsersByPage(query: IGetUsersQuery) {
        return await this._httpClient.build(`${this._baseUrl}`).useQueryString(query).fetch<IGetUsersResult>();
    }

    public async getUsersByRole(query: IGetUsersByRoleQuery) {
        return await this._httpClient.build(`${this._baseUrl}/role`).useQueryString(query).fetch<IUserResult[]>();
    }

    public async getUsersLean(query: IGetUsersLeanQuery) {
        return await this._httpClient.build(`${this._baseUrl}/lean`).useQueryString(query).fetch<IGetUsersLeanResult>();
    }

    public async getUsersProviders(userId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${userId}/providers`).fetch<IUserProvider[]>();
    }

    public async getActiveUsers(query: IGetActiveUsersQuery) {
        return await this._httpClient.build(`${this._baseUrl}/active`).useMethod("POST").useBody(query).fetch<any[]>();
    }

    public async resendUserInvite(userId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/resendinvite`)
            .useMethod("POST")
            .fetchNoContent();
    }

    public async getUserSlimInfo(userId: string) {
        this._user = await this._httpClient.build(`${this._baseUrl}/${userId}/slim`).fetch<IGetUserSlimInfoResult>();
        return Promise.resolve(this._user);
    }

    public async getUserFlags(userId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${userId}/flags`).fetch<IGetUserFlags>();
    }

    public async getUserSchedule(userId: string, query: IGetUserScheduleQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/schedule`)
            .useQueryString(query)
            .fetch<IGetUserSchedule[]>();
    }

    public async getUserNonPatientActivity(userId: string, query: IGetUserNonPatientActivityQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/non-patient-activities`)
            .useQueryString(query)
            .fetch<IGetUserNonPatientActivity[]>();
    }

    public async getUserVolunteerActivity(userId: string, query: IGetVolunteerActivityQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/volunteer-activities`)
            .useQueryString(query)
            .fetch<IGetUserVolunteerActivity[]>();
    }

    public async getUserInfectionReports(userId: string, query: IGetUserInfectionReportsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/infectionreports`)
            .useQueryString(query)
            .fetch<IGetUserInfectionReport[]>();
    }

    public async getUser(userId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${userId}`).fetch<User>();
    }

    public async editUser(user: User, userAffiliationId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userAffiliationId}`)
            .useMethod("PUT")
            .useBody(user)
            .fetchNoContent();
    }

    public async getUserPhoto(userId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${userId}/photo`).fetch<IGetUserPhotoResult>();
    }

    public async deleteUserPhoto(userId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${userId}/photo`).useMethod("DELETE").fetchNoContent();
    }

    public async uploadUserPhoto(userId: string, assetId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/photo`)
            .useMethod("PUT")
            .useBody(assetId)
            .fetchNoContent();
    }

    public async getUserPermissions(userId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${userId}/permissions`).fetch<Permission[]>();
    }

    public async editUserPermissions(query: ISaveUserPermissionsQuery, userId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/permissions`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async updateUserStatus(query: IUpdateUserStatusQuery, userId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/status`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async revokeUserInvite(userAffiliationId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/revokeInvite`)
            .useMethod("DELETE")
            .useBody({ userAffiliationId })
            .fetchNoContent();
    }

    public async getNpaPayRates(providerId: string, query: IGetNpaPayRatesByPageQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrates`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetNpaPayRate>>();
    }

    public async getUserPayRates(userId: string, query: IGetPayRatesByPageQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrates`)
            .useQueryString(query)
            .fetch<IGetPayRatesByPageResult>();
    }

    public async createPayRate(userId: string, payRate: ICreatePayRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrate`)
            .useMethod("POST")
            .useBody(payRate)
            .fetchNoContent();
    }

    public async copyPayRate(userId: string, copyUser: ICopyUserQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrates/copy`)
            .useMethod("PUT")
            .useBody(copyUser)
            .fetchNoContent();
    }

    public async editPayRate(userId: string, rateId: string, rate: IUpdatePayRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrate/${rateId}`)
            .useMethod("PUT")
            .useBody(rate)
            .fetchNoContent();
    }

    public async deletePayRate(userId: string, rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrate/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async createNpaPayRate(userId: string, payRate: ICreateNpaPayRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/non-patient-task-pay-rate`)
            .useMethod("POST")
            .useBody(payRate)
            .fetchNoContent();
    }

    public async GetRateCopyableUsersLean(userId: string, query: IGetRateCopyableUsersLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrates/copyable`)
            .useQueryString(query)
            .fetch<IGetUsersLeanResult>();
    }

    public async getIsUserSalary(userId: string, date: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrate/issalary?startDate=${date}`)
            .fetch<IGetIsUserSalary>();
    }

    public async getCurrentUserPermissions() {
        return await this._httpClient.build(`${this._baseUrl}/permissions`).fetch<Permission[]>();
    }

    // User Payroll Settings

    public async getCurrentUserPayrollSettings(userId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/currentpayrollsettings`)
            .fetch<IGetCurrentPayType>();
    }

    public async getUserPayrollSettings(userId: string, query: IPaginationRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollsettings`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetPayType>>();
    }

    public async getUserPayrollSettingsCheck(userId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollsettings-check`)
            .fetch<IGetUserPayrollSettingsCheck>();
    }

    public async editUserPayrollSettings(userId: string, rateId: string, query: UserPayrollSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollsettings/${rateId}`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async deleteUserPayrollSettings(userId: string, rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollsettings/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async createUserPayrollSettings(userId: string, payType: ICreatePayType) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollsettings`)
            .useMethod("POST")
            .useBody(payType)
            .fetchNoContent();
    }

    // Reimbursement Rates

    public async getReimbursementRates(userId: string, query: IGetReimbursementRatesByPageQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollreimbursement`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetReimbursementRate>>();
    }

    public async editReimbursementRate(userId: string, rateId: string, query: IUpdateReimbursementRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollreimbursement/${rateId}`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async deleteReimbursementRate(userId: string, rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollreimbursement/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async createReimbursementRate(userId: string, rate: ICreateReimbursementRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/payrollreimbursement`)
            .useMethod("POST")
            .useBody(rate)
            .fetchNoContent();
    }

    public async getUserDocuments(userId: string, query: IGetUserDocumentsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/documents`)
            .useQueryString(query)
            .fetch<IPaginationNew<IUserDocument>>();
    }

    public async uploadDocument(userId: string, query: IUploadUserDocumentQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/documents`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IUploadUserDocumentResult>();
    }

    public async updateUserDocument(userId: string, documentId: string, query: IUpdateUserDocument) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/documents/${documentId}`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async deleteDocument(userId: string, documentId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/documents/${documentId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async updateUserDocumentStatus(userId: string, documentId: string, isActive: boolean) {
        return await this._httpClient
            .build(`${this._baseUrl}/${userId}/toggle-documents/${documentId}`)
            .useMethod("PUT")
            .useBody({ isActive })
            .fetchNoContent();
    }

    public async getUserLocations() {
        return await this._httpClient.build(`${this._baseUrl}/locations`).fetch<IGetUserLocations[]>();
    }

    public async getUserCurrentPayPeriod(query: IGetUserCurrentPayPeriod) {
        return await this._httpClient
            .build(`${this._baseUrl}/current-pay-periods`)
            .useQueryString(query)
            .fetch<IGetCurrentPayPeriod[]>();
    }

    public async getServicePartners(query: IGetServicePartners) {
        return await this._httpClient
            .build(`${this._baseUrl}/service-partners`)
            .useQueryString(query)
            .fetch<IPaginationNew<IServicePartner>>();
    }

    public async updateServicePartners(id: string, query: IUpdateServicePartnerLocations) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/service-partners`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getLocationsByTaskIds(query: IGetLocationsByTaskIdsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/locations-by-task`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IBranchResponse[]>();
    }
}
