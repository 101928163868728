import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import {
    billHoldsTooltip,
    claimActivityReport,
    claimCountSummaryTooltip,
    claimsReadyBillingTooltip,
    costReportTooltip,
    depositReportTooltip,
    earnedRevenueReportCTMTooltip,
    earnedRevenueReportTooltip,
    eftTooltip,
    eligibilityIssuesTooltip,
    generalLedgerMultiLocationTooltip,
    generalLedgerReportTooltip,
    monthEndArReportTooltip,
    monthEndRollForwardTooltip,
    paymentAndAdjustmentActivityTooltip,
    payrollReportTooltip,
    reasonsPreventingTooltip,
    roomAndBoardContractTooltip,
    siaReportTooltip,
    unbilledArReportTooltip
} from "./billing-and-financial-tooltips";

export const billingFinancialPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.BillingFinancial,
        action: PermissionActionEnum.View
    }
];

export const payrollPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.Payroll,
        action: PermissionActionEnum.View
    }
];

export const billingAndFinancialRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "month-end-ar-report"
    },
    {
        name: "general-ledger-report",
        route: "general-ledger-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./general-ledger-report/general-ledger-report", "general-ledger-report"),
        title: "General Ledger Report",
        href: "reports/billing-and-financial-reports/general-ledger-report",
        settings: {
            menuName: "General Ledger Report",
            tooltip: generalLedgerReportTooltip,
            permission: billingFinancialPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "general-ledger-multi-select-report",
        route: "general-ledger-multi-select-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./general-ledger-multi-report/general-ledger-multi-report",
            "general-ledger-multi-report"
        ),
        title: "General Ledger Report - Multi Select",
        href: "reports/billing-and-financial-reports/general-ledger-multi-select-report",
        settings: {
            menuName: "General Ledger Report - Multi Select",
            tooltip: generalLedgerMultiLocationTooltip,
            permission: billingFinancialPermission,
            isPalliativeCare: false,
            releaseDate: "5/17/2023"
        }
    },
    {
        name: "deposit-report",
        route: "deposit-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./deposit-report/deposit-report", "deposit-report"),
        title: "Deposit Report",
        href: "reports/billing-and-financial-reports/deposit-report",
        settings: {
            menuName: "Deposit Report",
            permission: billingFinancialPermission,
            isPalliativeCare: false,
            releaseDate: "06/28/2023",
            tooltip: depositReportTooltip
        }
    },
    {
        name: "month-end-ar-report",
        route: "month-end-ar-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./month-end-ar-report/month-end-ar-report", "month-end-ar-report"),
        title: "Month-End AR Aging Report",
        href: "reports/billing-and-financial-reports/month-end-ar-report",
        settings: {
            menuName: "Month End AR Report",
            tooltip: monthEndArReportTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "eft-report",
        route: "eft-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./eft-report/eft-report", "eft-report"),
        title: "Electronic Fund Transfers (Deposits)",
        href: "reports/billing-and-financial-reports/eft-report",
        settings: {
            tooltip: eftTooltip,
            menuName: "Electronic Fund Transfers(Deposits)",
            permission: billingFinancialPermission
        }
    },
    {
        name: "reasons-preventing-billing",
        route: "reasons-preventing-billing",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./reasons-preventing-billing/reasons-preventing-billing",
            "reasons-preventing-billing"
        ),
        title: "Reasons Preventing Billing",
        href: "reports/billing-and-financial-reports/reasons-preventing-billing",
        settings: {
            tooltip: reasonsPreventingTooltip,
            menuName: "Reasons Preventing Billing",
            isPalliativeCare: false,
            permission: billingFinancialPermission
        }
    },
    {
        name: "bill-holds",
        route: "bill-holds",
        nav: true,
        moduleId: PLATFORM.moduleName("./bill-holds/bill-holds", "bill-holds"),
        title: "Bill Holds",
        href: "reports/billing-and-financial-reports/bill-holds",
        settings: {
            tooltip: billHoldsTooltip,
            menuName: "Bill Holds",
            permission: billingFinancialPermission
        }
    },
    {
        name: "payment-and-adjustment-activity",
        route: "payment-and-adjustment-activity",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./payment-and-adjustment-activity/payment-and-adjustment-activity",
            "payment-and-adjustment-activity"
        ),
        title: "Payment and Adjustment Activity",
        href: "reports/billing-and-financial-reports/payment-and-adjustment-activity",
        settings: {
            menuName: "Payment and Adjustment Activity",
            tooltip: paymentAndAdjustmentActivityTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "month-end-roll-forward",
        route: "month-end-roll-forward",
        nav: true,
        moduleId: PLATFORM.moduleName("./month-end-roll-forward/month-end-roll-forward", "month-end-roll-forward"),
        title: "Month-End AR Roll Forward",
        href: "reports/billing-and-financial-reports/month-end-roll-forward",
        settings: {
            menuName: "Month-End AR Roll Forward",
            tooltip: monthEndRollForwardTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "claims-ready-billing",
        route: "claims-ready-billing",
        nav: true,
        moduleId: PLATFORM.moduleName("./claims-ready-billing/claims-ready-billing", "claims-ready-billing"),
        title: "Claims Ready for Billing",
        href: "reports/billing-and-financial-reports/claims-ready-billing",
        settings: {
            menuName: "Claims Ready for Billing",
            isPalliativeCare: false,
            tooltip: claimsReadyBillingTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "claim-count-summary",
        route: "claim-count-summary",
        nav: true,
        moduleId: PLATFORM.moduleName("./claim-count-summary/claim-count-summary", "claim-count-summary"),
        title: "Claim Count Summary",
        href: "reports/billing-and-financial-reports/claim-count-summary",
        settings: {
            menuName: "Claim Count Summary",
            tooltip: claimCountSummaryTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "payroll-report",
        route: "payroll-report",
        moduleId: PLATFORM.moduleName("./payroll-report/payroll-report", "payroll-report"),
        title: "Payroll Report",
        settings: {
            menuName: "Payroll Report",
            tooltip: payrollReportTooltip,
            permission: payrollPermission
        }
    },
    {
        name: "claim-activity-report",
        route: "claim-activity-report",
        moduleId: PLATFORM.moduleName("./claim-activity-report/claim-activity-report", "claim-activity-report"),
        title: "Claim Activity Report",
        settings: {
            menuName: "Claim Activity Report",
            tooltip: claimActivityReport,
            permission: billingFinancialPermission
        }
    },
    {
        name: "eligibility-issues",
        route: "eligibility-issues",
        moduleId: PLATFORM.moduleName("./eligibility-issues/eligibility-issues", "eligibility-issues"),
        title: "Eligibility Issues",
        settings: {
            menuName: "Eligibility Issues",
            tooltip: eligibilityIssuesTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "earned-revenue-ctm-report",
        route: "earned-revenue-ctm-report",
        moduleId: PLATFORM.moduleName(
            "./earned-revenue-ctm-report/earned-revenue-ctm-report",
            "earned-revenue-ctm-report"
        ),
        title: "Earned Revenue (Completed Task Method)",
        settings: {
            isPalliativeCare: true,
            menuName: "Earned Revenue Report",
            tooltip: earnedRevenueReportCTMTooltip,
            permission: billingFinancialPermission,
            newRoute: true
        }
    },
    {
        name: "earned-revenue-report",
        route: "earned-revenue-report",
        moduleId: PLATFORM.moduleName("./earned-revenue-report/earned-revenue-report", "earned-revenue-report"),
        title: "Earned Revenue (Completed Service Day Method)",
        settings: {
            isPalliativeCare: false,
            menuName: "Earned Revenue Report",
            tooltip: earnedRevenueReportTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "room-and-board-contract",
        route: "room-and-board-contract",
        moduleId: PLATFORM.moduleName("./room-and-board-contract/room-and-board-contract", "room-and-board-contract"),
        title: "Hospice Room & Board Contract Report",
        settings: {
            menuName: "Hospice Room & Board Contract Report",
            tooltip: roomAndBoardContractTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "unbilled-account-receivable",
        route: "unbilled-account-receivable",
        moduleId: PLATFORM.moduleName(
            "./unbilled-account-receivable/unbilled-account-receivable",
            "unbilled-account-receivable"
        ),
        title: "Unbilled Account Receivable (Accrual Basis)",
        settings: {
            menuName: "Unbilled Account Receivable (Accrual Basis)",
            tooltip: unbilledArReportTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "sia-report",
        route: "sia-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./sia-report/sia-report", "sia-report"),
        title: "Service Intensity Add On Report",
        href: "reports/billing-and-financial-reports/sia-report",
        settings: {
            menuName: "Service Intensity Add On Report",
            tooltip: siaReportTooltip,
            permission: billingFinancialPermission
        }
    },
    {
        name: "cost-report",
        route: "cost-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./cost-report/cost-report", "cost-report"),
        title: "Cost Report - Part II Statistical Data",
        href: "reports/billing-and-financial-reports/cost-report",
        settings: {
            menuName: "Cost Report - Part II Statistical Data",
            tooltip: costReportTooltip,
            permission: billingFinancialPermission,
            releaseDate: "11/30/2022",
            isPalliativeCare: false
        }
    }
];
