import { HttpClient } from "aurelia-fetch-client";
import { autoinject, buildQueryString } from "aurelia-framework";

import { HttpStatusCodeEnum } from "../enums/http-status-code-enum";
import { IBlobResponse } from "../interfaces/i-hospice-client";

@autoinject
export class HospiceClient {

    private _client: HttpClient;

    public constructor (client: HttpClient) {
        this._client = client;
    }

    public build<TResponse>(path: string): HospiceClientChain {
        return new HospiceClientChain(path, this._client);
    }
}

export class HospiceClientChain {
    private _path: string;
    private _init: RequestInit;
    private _client: HttpClient;

    public constructor (path: string, client: HttpClient) {
        this._path = path;
        this._init = {
            method: "GET"
        };
        this._client = client;
    }

    public useQueryString<TParam>(param: TParam): HospiceClientChain {
        if (param == null) {
            return this;
        }

        let concat = this._path.indexOf("?") != -1;
        let anchorChar = concat ? "&" : "?";

        let queryString = buildQueryString(param);
        this._path += anchorChar + queryString;
        return this;
    }

    public useMethod(method: string): HospiceClientChain {
        this._init.method = method;
        return this;
    }

    public useMode(mode: RequestMode): HospiceClientChain {
        this._init.mode = mode;
        return this;
    }

    public useCredentials(credentials: RequestCredentials): HospiceClientChain {
        this._init.credentials = credentials;
        return this;
    }

    public useBody<T>(body: T): HospiceClientChain {
        let json = JSON.stringify(body);
        this._init.body = json;
        return this;
    }

    public useAuthorization(token: string): HospiceClientChain {
        this._init.headers = { Authorization: token };
        return this;
    }

    public useFormData(body: FormData): HospiceClientChain {
        this._init.body = body;
        return this;
    }

    public async fetch<TResponse>(): Promise<TResponse> {
        let response = await this._client.fetch(this._path, this._init);
        if (!response.ok) {
            throw new HospiceClientHttpError(response.status, response.statusText, response);
        }

        let json = await response.json();
        return json as TResponse;
    }

    public async fetchBlob(): Promise<IBlobResponse> {
        let response: Response = await this._client.fetch(this._path, this._init);
        if (!response.ok) {
            throw new HospiceClientHttpError(response.status, response.statusText, response);
        }
        let blob = await response.blob();
        return {
            headers: response.headers,
            body: blob
        };
    }

    public async fetchNoContent(): Promise<void> {
        let response = await this._client.fetch(this._path, this._init);
        if (response.status !== HttpStatusCodeEnum.NoContent) {
            throw new HospiceClientHttpError(response.status, `Expected '204 : No Content' but received '${response.status} : ${response.statusText}' instead.`, response);
        }
    }
}

export interface IHospiceClientOptions {
    path: string;
    method?: string;
    body?: any;
    query?: any;
}

export class HospiceClientHttpError {
    public statusMessage: string;
    public status: number;
    public response: Response;

    public constructor (status: number, statusMessage: string, response: Response) {
        this.status = status;
        this.statusMessage = statusMessage;
        this.response = response;
    }
}
