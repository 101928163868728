import { bindingMode, computedFrom } from "aurelia-binding";
import { autoinject, bindable, containerless, customElement } from "aurelia-framework";
import { Router } from "aurelia-router";
import { IScrollSideNavOptions } from "../../../interfaces/i-side-nav-options";

@customElement("scroll-layout")
@containerless
@autoinject
export class ScrollLayout {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public hasSideNav: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public hasButtons: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public router: Router;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasTitle: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public pageTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public scrollNavElements: IScrollSideNavOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public scrollWrapper: HTMLElement;
    public activeNavItem: string = null;

    public attached() {
        if (!this.scrollWrapper || !this.scrollNavElements || this.scrollNavElements?.length == 0) {
            console.warn(
                "The scroll Layout component must contain the nav elements and the scroll wrapper to handle scroll events."
            );
        }
        this.activeNavItem = this.scrollNavElements[0].menuName;
        this.scrollWrapper.addEventListener("scroll", this.throttle(this.scrollHandler, 100), { passive: true });
        this.scrollWrapper.addEventListener("scrollend", this.throttle(this.scrollHandler, 100));
    }

    public scrollWrapperChanged() {
        this.scrollWrapper?.addEventListener("scroll", this.throttle(this.scrollHandler, 100));
        this.scrollWrapper?.addEventListener("scrollend", this.throttle(this.scrollHandler, 100));
    }

    public scrollHandler() {
        const normalizedScrollPercentage = Math.min(
            1,
            Math.max(
                0,
                this.scrollWrapper.scrollTop / (this.scrollWrapper.scrollHeight - this.scrollWrapper.clientHeight)
            )
        );

        /**
         * The `selector` is the line that goes from the top of the scrollable view to the bottom
         * according to the scrolled distance
         */
        const selectorDistance = normalizedScrollPercentage * this.scrollWrapper.clientHeight;

        /**
         * We now find the section that the `selector` is currently on top of and mark that section as active
         */
        this.activeNavItem = this.scrollNavElements.find((navElement) => {
            const distanceFromParentTopLowerBound =
                navElement.element.getBoundingClientRect().y - this.scrollWrapper.getBoundingClientRect().y;
            const distanceFromParentTopUpperBound =
                distanceFromParentTopLowerBound + navElement.element.getBoundingClientRect().height;
            return distanceFromParentTopUpperBound > selectorDistance;
        }).menuName;
    }

    public scrollToSection(navElement: IScrollSideNavOptions) {
        const scrollableDistance = this.scrollWrapper.scrollHeight - this.scrollWrapper.clientHeight;
        const navElementIndex = this.scrollNavElements.findIndex((element) => navElement === element);

        /**
         * If the element selected is either 1st or last one
         * just scroll straight to top or bottom respectively
         */
        if (navElementIndex === 0) {
            this.scrollWrapper.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
        if (navElementIndex === this.scrollNavElements.length - 1) {
            this.scrollWrapper.scrollTo({ top: scrollableDistance, behavior: "smooth" });
            return;
        }

        /**
         * relativeScrollRatio is the ratio of the speed of the Selector to the actual speed of scroll
         *
         * you can imagine that if the scrollableDistance is 5000px and the height of the scrollWrapper itself is just 1000px
         * then the Selector would have to move 5 times slower than the scrollWrapper scrolls
         */
        const relativeScrollRatio = scrollableDistance / this.scrollWrapper.clientHeight;

        /**
         * Logical next step is to calculate what scroll position the selector will meet the selected section
         *
         * It's a little counter intuitive but, imagine the scrollableDIstance is 2000px and the height of the scroll wrapper is just 1000px
         * this means that the total height of the `scrollable space` is actually (2000 + 1000)px
         *
         */
        this.scrollWrapper.scrollTo({
            top:
                navElement.element.offsetTop -
                (1 / (relativeScrollRatio + 1)) * navElement.element.offsetTop +
                navElement.element.clientHeight / 4,
            behavior: "smooth"
        });
    }

    public reset() {
        // selects active item to first item
        if (this.scrollNavElements[0].menuName !== this.activeNavItem) {
            this.activeNavItem = this.scrollNavElements[0].menuName;
        }
    }

    private throttle(fn: () => void, wait: number) {
        let time = Date.now();
        return () => {
            if (time + wait - Date.now() < 0) {
                fn.apply(this);
                time = Date.now();
            }
        };
    }
}
