// import "bootstrap/js/src/tooltip.js";
import { bindable, customAttribute, inject, observable } from "aurelia-framework";
import "popper.js";
import nameof from "../../../common/nameof";
import "./tooltip.scss";

@customAttribute("tooltip")
@inject(Element)
export class Tooltip {
    private _element: any;
    @bindable
    public animation: boolean = true;
    @bindable
    public container: string = "body";
    @bindable
    public delay: number = 0;
    @bindable
    public html: boolean = true;
    @bindable
    public placement: string = "auto";
    @bindable
    @observable({ changeHandler: nameof<Tooltip>("titleChanged") })
    public title: string = "";
    @bindable
    public trigger: string = "hover focus";
    @bindable
    public hideWait: number = 0;
    @bindable
    public showOnChange: boolean = false;
    @bindable
    public className: string;

    public constructor(el: Element) {
        this._element = $(el);
    }

    public attached(): void {
        this.initTooltip();
    }

    public initTooltip() {
        if (!this.title || this.title.length <= 0) {
            return;
        }
        let options: any = {
            animation: this.animation,
            container: this.container,
            delay: this.delay,
            html: this.html,
            placement: this.placement,
            title: this.title,
            trigger: this.trigger
        };

        if (this.className && this.className.length) {
            options.template = `<div class="tooltip ${this.className}" role="tooltip">
                                    <div class="tooltip-arrow"></div>
                                    <div class="tooltip-inner"></div>
                                </div>`;
        }
        this.destroyTooltip();

        if (options.trigger === "manual") {
            this._element
                .tooltip(options)
                .tooltip("hide")
                .on("mouseenter", () => {
                    this._element.tooltip("show");
                    let hideWait: number = this.hideWait;
                    // If hidewait is set, hide after hidewait seconds
                    // even if the mouse is hovering over the content
                    if (hideWait) {
                        let hideWaitTimer = setTimeout(() => {
                            this._element.tooltip("hide");
                            clearTimeout(hideWaitTimer);
                        }, hideWait);
                    }
                })
                .on("mouseleave", () => {
                    // this._element.tooltip("hide");
                });
        } else {
            this._element.tooltip(options);
        }
    }

    public detached(): void {
        this.destroyTooltip();
    }

    private destroyTooltip(): void {
        this._element.tooltip("dispose").off("mouseenter").off("mouseleave");
    }

    /*
     * Dynamic content change
     */
    public titleChanged(): void {
        this.destroyTooltip();
        this.initTooltip();
        if (this.showOnChange.toString() === "true") {
            this._element.tooltip("show");
        }
    }
}
