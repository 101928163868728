import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import { censusReportTooltip, deceasedPatientsTooltip, dischargedPatientsByReasonTooltip, infectiousDiseaseScreening, patientsByLocationTooltip, vendorContractListTooltip } from "./survey-reports-tooltips";

export const surveyPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.Survey,
        action: PermissionActionEnum.View
    }
];

export const surveyReportsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "census-report"
    },
    {
        name: "census-report",
        route: "census-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./census-report/census-report", "census-report"),
        title: "Census Report",
        href: "reports/survey-reports/census-report",
        settings: {
            tooltip: censusReportTooltip,
            menuName: "Census Report",
            permission: surveyPermission
        }
    },
    {
        name: "deceased-patients-report",
        route: "deceased-patients-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./deceased-patients-report/deceased-patients-report", "deceased-patients-report"),
        title: "Deceased Patients Report",
        href: "reports/survey-reports/deceased-patients-report",
        settings: {
            tooltip: deceasedPatientsTooltip,
            menuName: "Deceased Patients Report",
            permission: surveyPermission
        }
    },
    {
        name: "patients-by-location",
        route: "patients-by-location",
        nav: true,
        moduleId: PLATFORM.moduleName("./patients-by-location/patients-by-location", "patients-by-location"),
        title: "Patients by Service Location",
        href: "reports/survey-reports/patients-by-location",
        settings: {
            tooltip: patientsByLocationTooltip,
            menuName: "Patients by Service Location",
            permission: surveyPermission
        }
    },
    {
        name: "vendor-contract-list",
        route: "vendor-contract-list",
        nav: true,
        moduleId: PLATFORM.moduleName("./vendor-contract-list/vendor-contract-list", "vendor-contract-list"),
        title: "Vendor Contract List",
        href: "reports/survey-reports/vendor-contract-list",
        settings: {
            tooltip: vendorContractListTooltip,
            menuName: "Vendor Contract List",
            permission: surveyPermission
        }
    },
    {
        name: "discharged-patients-by-reason",
        route: "discharged-patients-by-reason",
        nav: true,
        moduleId: PLATFORM.moduleName("./discharged-patients-by-reason/discharged-patients-by-reason", "discharged-patients-by-reason"),
        title: "Discharged Patients by Reason",
        href: "reports/survey-reports/discharged-patients-by-reason",
        settings: {
            tooltip: dischargedPatientsByReasonTooltip,
            menuName: "Discharged Patients by Reason",
            permission: surveyPermission
        }
    },
    {
        name: "infectious-disease-screening",
        route: "infectious-disease-screening",
        nav: true,
        moduleId: PLATFORM.moduleName("./infectious-disease-screening/infectious-disease-screening", "infectious-disease-screening"),
        title: "Infectious Disease Screening",
        href: "reports/survey-reports/infectious-disease-screening",
        settings: {
            tooltip: infectiousDiseaseScreening,
            menuName: "Infectious Disease Screening",
            permission: surveyPermission
        }
    }
];
