import "./quick-help.scss";

import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, customElement } from "aurelia-framework";
import * as moment from "moment";
import { Key } from "ts-keycode-enum";

import Infrastructure from "../../../common/infrastructure";
import { KeyPressPublish } from "../../../common/utilities/key-listener";
import { IKeyEventPayload } from "../../../interfaces/i-keyevent-payload";
import { NluService } from "../../../services/nlu-service";

@autoinject
@customElement("quick-help")
export class QuickHelp {
    private readonly _ea: EventAggregator;
    private readonly _nluService: NluService;
    public isMinimized: boolean = false;
    public isHidden: boolean = true;
    public messages: any[] = [];
    public message: string = "";
    public messagesWrapper: HTMLElement;

    public constructor (ea: EventAggregator, nluService: NluService) {
        this._ea = ea;
        this._nluService = nluService;
    }

    public attached() {
        this._ea.subscribe(KeyPressPublish, this.keyListenHandler.bind(this));
    }

    public hideQuickHelp() {
        this.isHidden = true;
    }

    public minimize() {
        this.isMinimized = true;
    }

    public expand() {
        this.isMinimized = false;
    }

    public async sendMessage() {
        try {
            this.messages.push({
                createdOn: moment(),
                text: this.message,
                isFromSelf: true
            });
            let userMessage = this.message;
            this.message = "";
            this.messages.push({
                createdOn: moment(),
                isLoading: true,
                isFromSelf: false
            });
            let response = await this._nluService.sendMessage(userMessage);
            let loadingMessage = this.messages.findIndex((message) => message.isLoading);
            this.messages[loadingMessage].isLoading = false;
            this.messages[loadingMessage].text = response.responseMessage;
            this.scrollToBottom();
        } catch (e) {
            console.log(e);
        }
    }

    public scrollToBottom() {
        // The delay is set for rendering the messages in the UI
        // Attempts and info:
        // Aurelia does not have any life cycle hook to know when a view updated
        // ref's don't update in repeat.for. Ref's binding is one-time
        // scroll bindings aurelia have on an element does not have scrollHeight
        setTimeout(() => {
            this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight;
        }, 0);
    }

    private keyListenHandler(payload: IKeyEventPayload) {
        let event = payload.KeyboardEvent;
        let isCtrl = event.ctrlKey;
        let isShift = event.shiftKey;
        let isQuery = event.keyCode === Key.ForwardSlash;
        if (isCtrl && isShift && isQuery && !Infrastructure.isProdEnvironment) {
            this.isHidden = false;
        }
    }
}
