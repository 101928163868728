import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";
import { IAsset, IMessageItem } from "../../../interfaces/i-message";
import nameof from "../../../common/nameof";

@customElement("others-message")
@autoinject
export class OthersMessage {
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public updateSelectedIds: (event: { isSelected: boolean; messageId: string }) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isViewDeletedMessagesRequested: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public allMessagesSelected: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isBulkDeleteRequested: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedMessageIds: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<OthersMessage>("selectAllChanged") })
    public selectAll: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public message: IMessageItem = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public renderAsset: (asset: IAsset) => void;
    @observable({ changeHandler: nameof<OthersMessage>("isSelectedChanged") })
    public isSelected: boolean = false;
    public axxessUserName = "Axxess";

    public selectAllChanged(newValue: boolean, oldValue: boolean) {
        if (this.selectAll) {
            this.isSelected = true;
        } else if (this.allMessagesSelected) {
            this.isSelected = false;
        }
    }

    public isSelectedChanged(newValue: boolean) {
        if (!this?.message?.id) {
            return;
        }
        this.updateSelectedIds({ isSelected: newValue, messageId: this.message.id });
        if (!newValue) {
            this.selectAll = false;
        }
    }
}
