import { valueConverter } from "aurelia-binding";

@valueConverter("currencyFormat")
export class CurrencyFormatter {
    private _defaultLocale: string = "en-US";
    private _defaultOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        useGrouping: true,
        currency: "USD",
        currencyDisplay: "symbol"
    };

    public toView(value: number, options?: Intl.NumberFormatOptions) {
        let mergedOptions = Object.assign({}, this._defaultOptions, options);
        if (!this.isInt(value) && !this.isFloat(value)) {
            value = 0.0;
        }
        if (options && options.style === "decimal" && value < 0) {
            let currencyString = Number(Math.abs(value)).toLocaleString(this._defaultLocale, mergedOptions);
            return `(${currencyString})`;
        }
        return Number(value).toLocaleString(this._defaultLocale, mergedOptions);
    }

    private isInt(n: number) {
        return Number(n) === n && n % 1 === 0;
    }

    private isFloat(n: number) {
        return Number(n) === n && n % 1 !== 0;
    }
}

@valueConverter("formatCurrencyInput")
export class FormatCurrencyInput {
    public fromView(value: string) {
        let newVal = ("" + value).replace(/\,|\$/g, "");
        let number = newVal ? parseFloat(newVal) : null;
        return !!newVal && !isNaN(number) ? number : null;
    }
}

@valueConverter("formatCurrencyTwoWay")
export class FormatCurrencyInputToView {
    private _defaultLocale: string = "en-US";
    private _defaultOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        useGrouping: true,
        currency: "USD",
        currencyDisplay: "symbol"
    };

    public toView(value: number, options?: Intl.NumberFormatOptions) {
        let mergedOptions = Object.assign({}, this._defaultOptions, options);
        if (isNaN(value)) {
            value = 0.0;
        }
        return Number(value).toLocaleString(this._defaultLocale, mergedOptions);
    }

    public fromView(value: string, options?: Intl.NumberFormatOptions) {
        let mergedOptions = Object.assign({}, this._defaultOptions, options);
        let newVal = ("" + value).replace(/\,|\$/g, "");
        let number = newVal ? parseFloat(newVal) : null;
        if (!newVal || isNaN(number)) {
            return null;
        }
        let decimalPlace = mergedOptions.maximumFractionDigits;
        return !!decimalPlace ? number.toFixed(decimalPlace) : number;
    }
}
