export enum ClinicalPermissionEnum {
    Schedule = "Schedule",
    PatientChart = "Patient Charts",
    Orders = "Orders",
    Visits = "Visits",
    Tasks = "Tasks",
    Shifts = "Shifts",
    MedicationProfile = "Medication Profile",
    Allergies = "Allergies",
    AbilityToCoSignNotes = "Ability To Co-Sign Notes",
    AbilityToCoSignOrders = "Ability To Co-Sign Orders",
    RequireCoSignatureForNotes = "Require Co-Signature For Notes",
    RequireCoSignatureForOrders = "Require Co-Signature For Orders",
    LoadPreviousNotes = "Load Previous Notes",
    LoadOtherUsersPreviousNotes = "Load Other Users Previous Notes",
    Frequency = "Frequency",
    ManageBenefitPeriod = "Manage Benefit Period",
    DocumentAddendum = "Document Addendum",
    eMAR = "eMAR",
    DownloadPatientChart = "Download Patient Charts",
    Bereavement = "Bereavement",
    ManageDocuments = "Manage Documents",
    InactiveUserDocumentAddendum = "Inactive User Document Addendum"
}
