import "./problem-statements-renderer.scss";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";

import { IEnumResponse } from "../../../interfaces/i-enum";
import { IPlanOfCare, IProblemStatement, IResolveGoal, IUpdateItemsUpdated } from "../../../interfaces/i-plan-of-care";
import { EnumsService } from "../../../services/enums-service";

@autoinject
@customElement("problem-statements-renderer")
export class ProblemStatementsRenderer {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isError: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasData: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public planOfCare: IPlanOfCare[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public noteId: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public pageReset: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isUpdateEnabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isValidOrderLinked: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateItemsUpdated: (count: IUpdateItemsUpdated) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAction: boolean = false;
    private _enumsService: EnumsService;
    public goalActions: IEnumResponse[] = [];
    public columns: number = 5;
    public showProblemAreas: string[] = [];
    public resolvedGoals: IResolveGoal[] = [];
    public expandStatements: boolean;
    public discontinuedInterventions: string[] = [];
    public discontinuedProblemStatements: string[] = [];
    public goalCompletedByDateEnum: IEnumResponse[] = [];
    public interventionDisciplinesEnum: IEnumResponse[] = [];
    public discontinuedAreaIds: string[] = [];

    public constructor(enumsService: EnumsService) {
        this._enumsService = enumsService;
    }

    public async attached() {
        this.goalActions = await this._enumsService.getGoalsAction();
        this.goalCompletedByDateEnum = await this._enumsService.getGoalCompletedByDate();
        this.interventionDisciplinesEnum = await this._enumsService.getInterventionDisciplines();
    }

    public toggleProblemArea(problemArea: IPlanOfCare) {
        if (this.showProblemAreas.includes(problemArea.id)) {
            this.showProblemAreas = this.showProblemAreas.filter((areaId) => areaId != problemArea.id);
            if (this.expandStatements) {
                this.expandStatements = false;
            }
        } else {
            this.showProblemAreas = this.showProblemAreas.concat(problemArea.id);
        }
    }

    // Update Marked Interventions
    public toggleInterventionCallback(id: string, addIntervention: boolean, statement: IProblemStatement) {
        let doesExists = this.discontinuedInterventions.includes(id);
        if (doesExists && !addIntervention) {
            this.discontinuedInterventions = this.discontinuedInterventions.filter(
                (interventionId) => interventionId !== id
            );
            this.discontinuedAreaIds = this.discontinuedAreaIds.filter((areaId) => areaId !== statement.areaId);
        }
        if (!doesExists && addIntervention) {
            this.discontinuedInterventions = this.discontinuedInterventions.concat(id);
            this.discontinuedAreaIds = this.discontinuedAreaIds.concat(statement.areaId);
        }
        this.updateCount();
    }

    public toggleAllInterventions(statement: IProblemStatement, addInterventions: boolean) {
        let statementInterventionIds = statement.interventions.map((intervention) => intervention.id);
        if (addInterventions) {
            statementInterventionIds.forEach((intervention) => {
                if (!this.discontinuedInterventions.includes(intervention)) {
                    this.discontinuedInterventions = this.discontinuedInterventions.concat(intervention);
                }
            });
        } else {
            this.discontinuedInterventions = this.discontinuedInterventions.filter(
                (intervention) => !statementInterventionIds.includes(intervention)
            );
        }
    }

    // Update Marked Statements
    public toggleProblemStatementCallback(statement: IProblemStatement, addStatement: boolean) {
        let doesExists = this.discontinuedProblemStatements.includes(statement.id);
        if (doesExists && !addStatement) {
            this.discontinuedProblemStatements = this.discontinuedProblemStatements.filter(
                (interventionId) => interventionId !== statement.id
            );
            this.discontinuedAreaIds = this.discontinuedAreaIds.filter((areaId) => areaId !== statement.areaId);
            this.toggleAllInterventions(statement, false);
        }
        if (!doesExists && addStatement) {
            this.discontinuedProblemStatements = this.discontinuedProblemStatements.concat(statement.id);
            this.discontinuedAreaIds = this.discontinuedAreaIds.concat(statement.areaId);
            this.toggleAllInterventions(statement, true);
        }
        this.updateCount();
    }

    // Update Marked Goals Array
    public resolveGoalCallback(resolveGoal: IResolveGoal, statement: IProblemStatement) {
        if (this.resolvedGoals.map((goal) => goal.id).includes(resolveGoal.id)) {
            // Goal present
            if (resolveGoal.action !== null) {
                // Action Changed
                let index = this.resolvedGoals.map((goal) => goal.id).indexOf(resolveGoal.id);
                this.resolvedGoals = this.resolvedGoals
                    .splice(0, index)
                    .concat(resolveGoal)
                    .concat(this.resolvedGoals.splice(index + 1));
            } else {
                // Action removed
                this.resolvedGoals = this.resolvedGoals.filter((goal) => goal.id !== resolveGoal.id);
            }
        } else {
            // New Goal Action
            if (resolveGoal.action !== null) {
                this.resolvedGoals = this.resolvedGoals.concat(resolveGoal);
            }
        }
        if (!this.discontinuedAreaIds.includes(statement?.areaId)) {
            this.discontinuedAreaIds = this.discontinuedAreaIds.concat(statement?.areaId);
        }
        this.updateCount();
    }

    public isUpdateEnabledChanged(newValue: boolean) {
        if (newValue) {
            this.updatePlanOfCare();
        } else {
            this.cancelUpdate();
        }
    }

    public isActionChanged(newValue: boolean) {
        if (newValue) {
            this.columns = 5;
        } else {
            this.columns = 4;
        }
    }

    public updatePlanOfCare() {
        this.columns = 4;
        if (this.showProblemAreas.length == 0) {
            this.expandAll();
        }
    }

    public expandAll() {
        this.showProblemAreas = this.planOfCare.map((area) => area.id);
        this.expandStatements = true;
    }

    public collapseAll() {
        this.showProblemAreas = [];
        this.expandStatements = false;
    }

    public cancelUpdate() {
        this.columns = 4;
        this.resetSelections();
    }

    public updateCount() {
        if (this.updateItemsUpdated) {
            this.updateItemsUpdated({
                count:
                    this.discontinuedInterventions.length +
                    this.resolvedGoals.length +
                    this.discontinuedProblemStatements.length
            });
        }
    }

    public resetSelections() {
        this.discontinuedAreaIds = [];
        this.discontinuedInterventions = [];
        this.resolvedGoals = [];
        this.discontinuedProblemStatements = [];
        this.updateCount();
    }
}
