import { autoinject, bindable, bindingMode, observable } from "aurelia-framework";

import { IOpenOrder } from "../../../interfaces/i-plan-of-care";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { PhysiciansService } from "../../../services/physician-service";
import { TaskService } from "../../../services/task-service";
import { ToastrService } from "../../../services/toastr-service";
import nameof from "../../../common/nameof";

@autoinject
export class PhysicianOrderCard {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<PhysicianOrderCard>("orderChanged") })
    public order: IOpenOrder;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public selectedOrder: IOpenOrder;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public getPhysicians: (params: { filter: string; limit: number }) => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public openOrder: () => void;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public selectOrder: () => void;
    private _taskService: TaskService;
    private _toastrService: ToastrService;
    @observable({
        changeHandler: nameof<PhysicianOrderCard>("selectedPhysicianChanged")
    })
    public selectedPhysician: ITypeaheadOptions = null;
    public showEditPhysician: boolean = false;
    public isUpdateInProgress: boolean = false;

    public constructor(physicianService: PhysiciansService, taskService: TaskService, toastrService: ToastrService) {
        this._taskService = taskService;
        this._toastrService = toastrService;
    }

    public orderChanged() {
        if (!!this.order) {
            if (!!this.order.physicianFirstName) {
                this.order.physicianFirstName = this.order.physicianFirstName.toLocaleLowerCase();
            }
            if (!!this.order.physicianLastName) {
                this.order.physicianLastName = this.order.physicianLastName.toLocaleLowerCase();
            }
        }
    }

    public openAddPhysicianToOrder() {
        this.showEditPhysician = true;
    }

    public async getPhysicianList(filter: string, limit: number) {
        return await this.getPhysicians({
            filter,
            limit
        });
    }

    public async addPhysicianToOrder() {
        try {
            this.closeAddPhysicianToOrder();
            this.isUpdateInProgress = true;
            await this._taskService.updateOrder(this.order.orderId, {
                physicianId: this.selectedPhysician.value,
                physicianName: this.selectedPhysician.name
            });
            this.order.orderStatus = "Saved";
            this.order.physicianFirstName = this.selectedPhysician.name.split(" ")[1];
            this.order.physicianLastName = this.selectedPhysician.name.split(" ")[0];
            this.selectedPhysician = null;
            this.isUpdateInProgress = false;
        } catch (e) {
            this.isUpdateInProgress = false;
            this.openAddPhysicianToOrder();
            console.error(e);
            this._toastrService.error({
                title: "Error - Add Physician",
                message: `There was a problem while adding the physician to the order. Please try again.`
            });
        }
    }

    public async selectedPhysicianChanged(newValue: ITypeaheadOptions) {
        let npiIndex = newValue?.name?.indexOf(" - NPI");
        if (npiIndex > 0 && newValue?.name) {
            let name = newValue?.name?.slice(0, npiIndex + 1);
            this.selectedPhysician.name = name;
        }
    }

    public closeAddPhysicianToOrder() {
        this.showEditPhysician = false;
    }
}
