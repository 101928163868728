import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { IDialogOptions } from "../../../interfaces/i-dialog";
import "./verify.scss";

@inject(DialogController)
export class Verify {
    public controller: DialogController;
    public options: IDialogOptions = {
        title: "Verify",
        description: "",
        type: "verify",
        icon: "fas fa-tasks"
    };

    public constructor(controller: DialogController) {
        this.controller = controller;
    }

    public activate(options: any) {
        Object.assign(this.options, options);
    }
}
