import { IClearTargetBehavior } from "../../../interfaces/form-builder/i-behavior";
import { NoteDataManager } from "../note-data-manager";

export class ClearTargetBehaviorHandler {
    public handle(behavior: IClearTargetBehavior, noteDataManager: NoteDataManager) {
        if (!!behavior) {
            let targetName = behavior.targetName;
            // TODO: Need to know the element type to clear secondary data and handle array elements
            noteDataManager.updatePropInModifiedData(targetName, "", false);
            noteDataManager.refreshValueInView(targetName);
        }
    }
}
