import { bindingMode, observable } from "aurelia-binding";
import { bindable, customElement } from "aurelia-framework";
import nameof from "../../common/nameof";
import { IFormBuilderNav } from "../../interfaces/form-builder/i-form-builder-nav";
import { ITab } from "../../interfaces/form-builder/i-tab";
import { NoteDataManager } from "./note-data-manager";

@customElement("tabs")
export class Tabs {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<Tabs>("tabsChanged") })
    public tabs: ITab[] = [];
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public id: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public currentTab: string = "";
    public noteDataManager: NoteDataManager;
    public navigation: IFormBuilderNav[] = [];

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public handleMenuSelection(route: IFormBuilderNav) {
        this.navigation.forEach(nav => {
            nav.isActive = false;
            return nav;
        });

        route.isActive = true;
        this.currentTab = route.id;
    }

    public tabsChanged() {
        if (this.tabs && this.tabs.length > 0) {
            this.navigation = this.tabs.map(tab => {
                let codes = tab.codes[0] ? tab.codes[0] : "";
                let numOfCodes = tab.codes.length;
                let isActive = false;

                if (numOfCodes > 1) {
                    codes = `${codes} - ${tab.codes[numOfCodes - 1]}`;
                }

                if (tab.name === this.currentTab) {
                    isActive = true;
                }

                return {
                    name: tab.label,
                    id: tab.name,
                    isActive,
                    codes: codes
                };
            });

            this.currentTabChanged(this.currentTab);
        }
    }

    public currentTabChanged(newTab: string) {
        if (this.navigation.length > 0) {
            this.navigation.forEach(nav => {
                nav.isActive = false;
                return nav;
            });

            if (!!newTab) {
                let selectedNav = this.navigation.find((nav) => nav.id == newTab);

                if (!!selectedNav) {
                    selectedNav.isActive = true;
                } else {
                    this.navigation[0].isActive = true;
                }
            }
        }
    }
}
