import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { PhoneTypeEnum } from "../../../enums/phone-type-enum";
import { Pharmacy } from "../../../models/pharmacy";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

@autoinject
@customElement("pharmacy-form")
export class PharmacyForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerOptions: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public pharmacy: Pharmacy = new Pharmacy();
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    public phoneNumberTypeOptionsConfig: PhoneTypeEnum[] = [PhoneTypeEnum.Mobile, PhoneTypeEnum.Office];
    public providerValidation: IValidateCustomElement = {
        required: true,
        message: "Associated Agency Provider is required"
    };
}
