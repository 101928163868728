import {
    autoinject,
    bindable,
    bindingMode,
    customElement,
    observable,
    Disposable,
    BindingEngine
} from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import * as moment from "moment";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IGetCustomNonPatient } from "../../../interfaces/employee-schedule/i-non-patient-activity";
import { IBranchResponse } from "../../../interfaces/i-branch";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { NonPatientActivity } from "../../../models/employee-schedule/non-patient-activity";
import { AgencyTasksService } from "../../../services/agency-task-service";
import { BranchesService } from "../../../services/branches-service";
import { IEnumResponse } from "./../../../interfaces/i-enum";
import { EnumsService } from "./../../../services/enums-service";

@autoinject
@customElement("add-non-patient-form")
export class AddNonPatientForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<AddNonPatientForm>("nonPatientActivityChanged") })
    public nonPatientActivity: NonPatientActivity = new NonPatientActivity();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validationController: ValidationController;
    private readonly _agencyTasksService: AgencyTasksService;
    private readonly _enumsService: EnumsService;
    private readonly _branchesService: BranchesService;
    private _locationSubscription: Disposable;
    private _bindingEngine: BindingEngine;
    @observable({ changeHandler: nameof<AddNonPatientForm>("newTaskRepeatChanged") })
    public newTaskRepeat: number = 0;
    @observable({ changeHandler: nameof<AddNonPatientForm>("newTaskSingleDateChanged") })
    public newTaskSingleDate: string = "";
    @observable({ changeHandler: nameof<AddNonPatientForm>("newTaskDateRangeChanged") })
    public newTaskDateRange: string = "";
    @observable({ changeHandler: nameof<AddNonPatientForm>("newTaskFlexDatesChanged") })
    public newTaskFlexDates: string = "";
    @observable({ changeHandler: nameof<AddNonPatientForm>("shiftStartTimeChanged") })
    public shiftStartTime: string;
    @observable({ changeHandler: nameof<AddNonPatientForm>("selectedNonPatientActivityChanged") })
    public selectedNonPatientActivity: IGetCustomNonPatient;
    @observable({
        changeHandler: nameof<AddNonPatientForm>("selectedShiftLengthChanged")
    })
    public shiftLength: number = 0;
    public shiftEndTime: string;
    public nonPatientActivityLists: IGetCustomNonPatient[] = [];
    public recurrencesEnum: IEnumResponse[] = [];
    public recurrencesEnumMap: EnumMap;
    public showSingleDateValidation: boolean = true;
    public showDateRangeValidation: boolean = false;
    public branches: IBranchResponse[];
    public activityValidation: IValidateCustomElement = {
        required: true,
        displayName: "Activity",
        matches: true
    };
    public dateValidation: IValidateCustomElement = {
        required: false,
        displayName: "Date",
        matches: true
    };

    public constructor(
        bindingEngine: BindingEngine,
        agencyTasksService: AgencyTasksService,
        enumsService: EnumsService,
        branchesService: BranchesService
    ) {
        this._bindingEngine = bindingEngine;
        this._agencyTasksService = agencyTasksService;
        this._enumsService = enumsService;
        this._branchesService = branchesService;
    }

    public async attached() {
        this.initValidation();
        this.initSubscriptions();
        try {
            this.branches = await this._branchesService.getAllBranches();
            this.recurrencesEnum = await this._enumsService.getRecurrences();
            this.recurrencesEnumMap = new EnumMap(this.recurrencesEnum);
            if (!!this.nonPatientActivity?.taskId) {
                let selectedNonPatientActivity = this.nonPatientActivityLists.find((activity) => {
                    return activity.taskId === this.nonPatientActivity.taskId;
                });
                if (!!selectedNonPatientActivity) {
                    this.selectedNonPatientActivity = Object.assign({}, selectedNonPatientActivity);
                }
            }
        } catch (e) {
            console.error("Unable to Fetch Agency Tasks", e);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: AddNonPatientForm) => x.newTaskSingleDate)
            .required()
            .when((x: AddNonPatientForm) => !!x.showSingleDateValidation)
            .withMessage("Date is required.")
            .ensure((x: AddNonPatientForm) => x.newTaskDateRange)
            .required()
            .when((x: AddNonPatientForm) => !!x.showDateRangeValidation)
            .withMessage("Date is required.")
            .on(this);
    }

    public initSubscriptions() {
        this._locationSubscription = this._bindingEngine
            .propertyObserver(this.nonPatientActivity, nameof<NonPatientActivity>("locationId"))
            .subscribe(() => {
                this.getNonPatientActivities();
            });
    }

    public async getNonPatientActivities() {
        try {
            this.nonPatientActivityLists = await this._agencyTasksService.getCustomNonPatientActivities({
                locationIds: [this.nonPatientActivity?.locationId],
                effectiveDate: this.nonPatientActivity?.startDates[0]
            });
        } catch (error) {
            console.error(error);
        }
    }

    public nonPatientActivityChanged() {
        // Here every value is reset since newNonPatientActivity only changes when the form is closed
        this.selectedNonPatientActivity = null;
        this.newTaskRepeat = 0;
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
        this.shiftLength = 0;
        this.shiftStartTime = "";
    }

    public newTaskRepeatChanged(newValue: number, oldValue: number) {
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
        this.nonPatientActivity.startDates = [];
        this.nonPatientActivity.endDates = [];
        this.validationController?.reset();
        this.changeDateValidationStatus();
    }

    public changeDateValidationStatus() {
        if (!!this.dateValidation && !!this.recurrencesEnumMap) {
            let recurrenceName = this.recurrencesEnumMap.getEnumName(this.newTaskRepeat) as string;
            if (recurrenceName.toLowerCase() === "flexible") {
                this.showDateRangeValidation = false;
                this.showSingleDateValidation = false;
                this.dateValidation.required = true;
            } else if (recurrenceName.toLowerCase() === "does not repeat") {
                this.showDateRangeValidation = false;
                this.showSingleDateValidation = true;
                this.dateValidation.required = false;
            } else {
                this.showDateRangeValidation = true;
                this.showSingleDateValidation = false;
                this.dateValidation.required = false;
            }
        }
    }

    public async selectedNonPatientActivityChanged(selectedCustomTask: IGetCustomNonPatient) {
        if (selectedCustomTask) {
            this.nonPatientActivity.taskId = selectedCustomTask.taskId;
        }
    }

    public newTaskSingleDateChanged(newValue: string) {
        if (newValue) {
            this.nonPatientActivity.startDates = [];
            this.nonPatientActivity.startDates.push(newValue);
            this.updateShiftStartAndEndTime();
            this.getNonPatientActivities();
        }
    }

    public newTaskDateRangeChanged(newValue: string) {
        this.nonPatientActivity.startDates = [];
        let newTaskDates = newValue.split(" - ");
        let startDate = moment(newTaskDates[0]);
        let endDate = moment(newTaskDates[1]);
        let dateToAdd = startDate;
        while (dateToAdd <= endDate) {
            this.nonPatientActivity.startDates.push(dateToAdd.format("MM/DD/YYYY"));
            if (this.newTaskRepeat == 4) {
                dateToAdd = dateToAdd.add(1, "month");
            } else {
                dateToAdd = dateToAdd.add(this.newTaskRepeat, "week");
            }
        }
        this.updateShiftStartAndEndTime();
    }

    public newTaskFlexDatesChanged(newValue: string) {
        if (newValue) {
            this.nonPatientActivity.startDates = newValue.split(", ");
            this.updateShiftStartAndEndTime();
            this.getNonPatientActivities();
        }
    }

    public shiftStartTimeChanged() {
        if (this.shiftLength > 0 && this.nonPatientActivity?.startDates?.length > 0) {
            this.updateShiftStartAndEndTime();
        }
    }

    public selectedShiftLengthChanged(newValue: string, oldValue: string) {
        if (!newValue || newValue === "0") {
            this.shiftStartTime = null;
            this.shiftEndTime = null;
        }
        if (
            !!newValue &&
            newValue !== oldValue &&
            !!this.shiftStartTime &&
            this.nonPatientActivity?.startDates?.length > 0
        ) {
            this.updateShiftStartAndEndTime();
        }
    }

    public updateShiftStartAndEndTime() {
        let startDates = this.nonPatientActivity.startDates;
        this.nonPatientActivity.startDates = startDates.map((item) => {
            let startDate = moment(item).format("MM/DD/YYYY");
            let updatedStartDate;
            if (!!this.shiftStartTime) {
                updatedStartDate = new Date(`${startDate} ${this.shiftStartTime}`).toISOString();
                return moment(updatedStartDate).format("MM/DD/YYYY hh:mm A");
            }
            return startDate;
        });
        this.nonPatientActivity.endDates = [];
        this.shiftEndTime = moment(this.shiftStartTime, "hh:mm A").add(this.shiftLength, "hours").format("hh:mm A");
        this.nonPatientActivity.startDates.forEach((item) => {
            let endDate;
            if (!!this.shiftStartTime) {
                endDate = moment(item).add(this.shiftLength, "hours").format("MM/DD/YYYY hh:mm A");
            } else {
                endDate = moment(item).format("MM/DD/YYYY");
            }
            this.nonPatientActivity.endDates.push(endDate);
        });
    }

    public detached() {
        this._locationSubscription?.dispose();
    }
}
