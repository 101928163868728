export enum InputTypesEnum {
    Text = "text",
    Decimal = "decimal",
    Integer = "integer",
    Date = "date",
    Time = "time",
    Textarea = "textarea",
    SingleSelect = "single-select",
    SingleSelectScale = "single-select-scale",
    SingleSelectDropdown = "single-select-dropdown",
    SingleSelectGetDropdown = "single-select-get-dropdown",
    SingleSelectGetAll = "single-select-get-all",
    SingleSelectGetAllDropdown = "single-select-get-all-dropdown",
    MultiSelect = "multi-select",
    MultiSelectDropdown = "multi-select-dropdown",
    MultiSelectGetAllDropdown = "multi-select-get-all-dropdown",
    ScoreTableInput = "score-table-input",
    Diagnosis = "diagnosis",
    Template = "template",
    Button = "button",
    Link = "link",
    ButtonLink = "button-link",
    ListGroup = "list-group",
    File = "file",
    Hidden = "hidden",
    SingleSelectOptionDropdown = "single-select-get-option-dropdown",
    ExternalComponent = "external-component",
    PhoneNumber = "phone-number"
}
