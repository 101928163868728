export function getColumnClasses(columns: string) {
    if (!columns) {
        return "";
    }
    let numCols = Number(columns);
    let mdCols = numCols == 4 ? 6 : numCols * 2;
    let xl = `col-xl-${columns}`;
    let lg = `col-lg-${mdCols > 12 ? "12" : mdCols}`;
    return `col-12 ${lg} ${xl}`;
}
