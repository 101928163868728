import { bindable, bindingMode, computedFrom, customElement, autoinject } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";

import nameof from "../../../../common/nameof";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IMaxLengthValidation, IRegexValidation } from "../../../../interfaces/form-builder/i-validation";
import { NoteDataManager } from "../../note-data-manager";
import { InputTypesEnum } from "../input-types-enum";

@customElement("text-input")
@autoinject
export class TextInput {
    // Input fields
    @bindable({ defaultBindingMode: bindingMode.toView })
    public inputType: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasHighlight: string = "";
    // INFO: Do not make this field private since it is used in the test file
    public noteDataManager: NoteDataManager;
    public inputTypesEnum: typeof InputTypesEnum = InputTypesEnum;
    public maxLength: number = 65535;

    @computedFrom(nameof<TextInput>("hasHighlight"))
    public get highlightClass() {
        switch (this.hasHighlight) {
            case "errors":
                return "is-invalid";
            case "warnings":
                return "is-warning";
            case "inconsistencies":
                return "is-inconsistency";
        }
        return "";
    }

    @computedFrom(nameof<TextInput>("hasHighlight"))
    public get highlightFocus() {
        return this.hasHighlight.length > 0;
    }

    public set highlightFocus(focus: boolean) {
        // focus would try to set property
    }

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public bind() {
        this.initValidation();
    }

    public initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        let validationRules = ValidationRules.ensure((x: any) => x.result);
        validation?.forEach((rule: IMaxLengthValidation | IRegexValidation) => {
            if (rule.validationType === FormValidationEnum.Required) {
                validationRules.required().withMessage(rule.message).on(this);
            } else if (rule.validationType === FormValidationEnum.MaxLength) {
                this.maxLength = (rule as IMaxLengthValidation).maxLength;
            } else if (rule.validationType === FormValidationEnum.Regex) {
                validationRules
                    .matches(new RegExp((rule as IRegexValidation).pattern))
                    .withMessage(rule.message)
                    .on(this);
            }
        });
    }
}
