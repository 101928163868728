import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const censusReportTooltip: IReportTooltip = {
    description:
        "Displays a list of patients by date range with status, level of care, diagnosis and demographic information. This report simplifies internal administrative operations and is used during state and regulatory surveys.",
    parameters: "Branch, Team, Patient Status, Gender, Race/Ethnicity, Level of Care, Date Range, Unduplicated",
    reportIncludes:
        "Branch, Team, Patient Name, MRN,  Patient Status, Admission Date, Discharge Date, Days on Service, Admission Source, Terminal Diagnosis, Level of Care, Primary Payer, Gender, Race/Ethnicity, Date of Birth, Primary Phone, Address Line 1, Address Line 2, City, State, Zip"
};

export const deceasedPatientsTooltip: IReportTooltip = {
    description:
        "Displays a list of deceased patients by branch and date range. This report is critical for bereavement purposes and is used during state and regulatory surveys.",
    parameters: "Branch, Date Range",
    reportIncludes: "Name, Admission Date, Discharge Date, Service Location, Address"
};

export const patientsByLocationTooltip: IReportTooltip = {
    description:
        "Displays a list of patients by the location where they are receiving hospice services along with the date they started receiving care at that location. This report is critical for internal administrative purposes and is used during state and regulatory surveys.",
    parameters:
        "Branch, Team, Status, Date Range, Service Location. Selecting different parameters will effect the results displayed in the report.",
    reportIncludes: "Name, Service Location, Date of Admission, Service Location Start Date"
};

export const vendorContractListTooltip: IReportTooltip = {
    description:
        "Displays a list of contracted vendors within your agency. Vendors will only populate on this report if the contract field in their vendor profile is marked as yes. This report is critical for internal administrative purposes and is used during state and regulatory surveys.",
    parameters: "Vendor Type. Selecting different parameters will effect the results displayed in the report.",
    reportIncludes: "Vendor Name, Vendor Type, Address, Phone, Contact"
};

export const dischargedPatientsByReasonTooltip: IReportTooltip = {
    description:
        "Displays a list of discharged patients by reason for discharge. This report is critical for internal administrative purposes and is used during state and regulatory surveys.",
    parameters: "Branch, Team, Discharge Reason, Date Range, Unduplicated ",
    reportIncludes:
        "Branch, Team, Patient Name, MRN, Reason for Discharge, Admission Date, Date of Discharge, Days on Service"
};

export const infectiousDiseaseScreening: IReportTooltip = {
    description:
        "Displays a list of patients, household members, non-agency caregivers and agency staff who have been screened for infectious diseases and the results of each screening. This report is critical to infection control and emergency preparedness planning and implementation.",
    parameters: "Date Range, Branch, Team, Person Screened, Type of Screening.",
    reportIncludes:
        "Branch, Team, Person Screened, Name, Type of Screening, Date of Screening, Time of Screening Entered By, Title, Screening Results, Risk Level."
};
