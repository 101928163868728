import { valueConverter } from "aurelia-binding";

@valueConverter("percentage")
export class Percentage {
    public toView(value: number, total: number): string {
        if (total > 0) {
            let percentage = Math.round((value / total) * 100);
            return `${percentage}%`;
        } else {
            return "";
        }
    }
}
