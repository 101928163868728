import {EventAggregator, Subscription} from "aurelia-event-aggregator";
import {autoinject, bindable, bindingMode, customElement, Disposable} from "aurelia-framework";
import {NavigationInstruction, PipelineResult, RouteConfig, RouterEvent} from "aurelia-router";
import {isRouteAllowed} from "../../../common/utilities/is-route-allowed";
import {CreateRoutes} from "../../../pages/create/create-routes";
import {ListRoutes} from "../../../pages/list/list-routes";
import {PermissionEvent, PermissionManager} from "../../../common/utilities/permission-manager";
import "./page-actions.scss";
import {AccountsService} from "../../../services/accounts-service";
import {AccountEvent} from "../../../services/auth-service";
import {UserLoaded} from "../../../common/oidc/oidc-event-handler";

@autoinject()
@customElement("page-actions")
export class PageActions {
    @bindable({defaultBindingMode: bindingMode.fromView})
    public isSearchExpanded: boolean;
    private readonly _accountsService: AccountsService;
    private _ea: EventAggregator;
    private _routeSubscription: Disposable;
    private _permissionManager: PermissionManager;
    private _subscriptions: Subscription[] = [];
    public isListPageActive: boolean = false;
    public isCreatePageActive: boolean = false;
    public createRoutes: RouteConfig[] = [];
    public listRoutes: RouteConfig[] = [];
    public hasOneOrMoreAddPermissions: boolean = true;
    public isPalliativeCare: boolean = false;

    public constructor(
        ea: EventAggregator,
        permissionManager: PermissionManager,
        accountService: AccountsService
    ) {
        this._ea = ea;
        this._permissionManager = permissionManager;
        this._accountsService = accountService;
    }

    private initSubscriptions() {
        // handle provider change and permission refresh
        [AccountEvent.ProvidersSelected, PermissionEvent.UserPermissionLoaded].forEach(event => {
            this._subscriptions.push(
                this._ea.subscribe(event, async () => {
                    await this.initializeLists();
                })
            );
        });
    }

    private async initializeLists() {
        this.isPalliativeCare = await this._accountsService.getIsPalliativeAccount();
        this.createRoutes = CreateRoutes.filter((route) => this.isLinkAllowed(route) && this.hasPermission(route));
        if (!this.createRoutes.length) {
            this.hasOneOrMoreAddPermissions = false;
        }
        this.listRoutes = ListRoutes.filter((route) => this.isLinkAllowed(route) && this.hasPermission(route));
    }

    public async attached() {
        await this.initializeLists();
        this._routeSubscription = this._ea
            .subscribe(RouterEvent.Complete, (event: { instruction: NavigationInstruction; result: PipelineResult }) => {
                let currentPageUrl = event.instruction.fragment.toLocaleLowerCase();
                this.isListPageActive = !!currentPageUrl && currentPageUrl.includes("/list/");
                this.isCreatePageActive = !!currentPageUrl && currentPageUrl.includes("/create/");
            });
        this.initSubscriptions();
    }

    public isLinkAllowed(routeConfig: RouteConfig) {
        let isPalliativeRoute = routeConfig?.settings?.isPalliativeCare;
        // checks for isPalliativeCare flag and adds/removes navigation buttons accordingly
        if (
            (isPalliativeRoute === false && this.isPalliativeCare) ||
            (isPalliativeRoute === true && !this.isPalliativeCare)
        ) {
            return false;
        }
        if (routeConfig) {
            return isRouteAllowed(routeConfig);
        } else {
            return false;
        }
    }

    public hasPermission(route: RouteConfig) {
        return route?.settings?.permission ? this._permissionManager.checkRoutePermission(route) : true;
    }

    public detached() {
        this._routeSubscription?.dispose();
        if (this._subscriptions.length > 0) {
            this._subscriptions.forEach((sub) => sub.dispose());
        }
    }
}
