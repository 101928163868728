import { ValidationRules } from "aurelia-validation";

export class ElectronicReferral {
    public id?: string = "";
    public receivingProviderId: string = "";
    public serviceUserId: string = "";
    public nonAdmit: boolean = false;
    public nonAdmitReasonId: string = "";
    public patientStatus?: number = null;
    public patientId?: string = "";
    public note?: string = "";

    public constructor(data?: ElectronicReferral) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: ElectronicReferral) => x.nonAdmitReasonId)
            .required()
            .when((x: ElectronicReferral) => x.nonAdmit)
            .withMessage("Non-Admit Reason  is required")
            .on(this);
    }
}
