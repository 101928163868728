import { bindable, customElement, inject, child, computedFrom } from "aurelia-framework";
import nameof from "../../../common/nameof";

// Libraries
@customElement("scroll-pagination")
@inject(Element)
export class ScrollPagination {
    @child(".scroll-paginate-outer")
    public scrollElement: Element;
    @bindable public pageNumber: number = 1;
    @bindable public nextPageOffset: number = 50;
    @bindable public isLoading: boolean = false;
    @bindable public allPagesLoaded: boolean = false;
    private readonly el: Element;

    public constructor(Element: Element) {
        this.el = Element;
    }

    @computedFrom(nameof<ScrollPagination>("scrollElement"), nameof<ScrollPagination>("pageNumber"))
    public get scrollableElement() {
        let $scrollableElement: JQuery<Element>;
        if (this.scrollElement) {
            $scrollableElement = $(this.scrollElement);
        } else {
            $scrollableElement = $(this.el);
            if (!$scrollableElement.hasClass("scroll-paginate-outer")) {
                let scrollPagination = $scrollableElement
                    .children()
                    .toArray()
                    .find((element) => $(element).hasClass("scroll-paginate-outer"));
                if (!!scrollPagination) {
                    this.scrollElement = scrollPagination;
                    $scrollableElement = $(this.scrollElement);
                }
            }
        }
        if (!$scrollableElement.hasClass("scroll-paginate")) {
            let scrollPagination = $scrollableElement
                .children()
                .toArray()
                .find((element) => $(element).hasClass("scroll-paginate"));
            if (!!scrollPagination) {
                this.scrollElement = scrollPagination;
            }
        }
        return this.scrollElement;
    }

    public attached(): void {
        this.handleScrolling();
    }

    public pageNumberChanged(newValue: number, oldValue: number) {
        // console.log("Scroll pagination: ", { newValue, oldValue }, this.allPagesLoaded);
        // If newValue is less than old value and if the newValue is 1 then that means the page has been refreshed
        if (newValue < oldValue && newValue === 1) {
            this.handleScrolling();
            $(this.scrollableElement).scrollTop(0);
        }
    }

    public handleScrolling() {
        let scrollTimer: NodeJS.Timer = null;
        let $scrollableElement: JQuery<Element> = $(this.scrollableElement);
        let self: this = this;
        $scrollableElement.off("scroll").on("scroll", (e: any) => {
            if (scrollTimer) {
                clearTimeout(scrollTimer); // clear any previous pending timer
            }
            scrollTimer = setTimeout(() => {
                // console.log("Scroll Pagination: Page Scrolled", scrollTimer);
                let st: number = $scrollableElement.scrollTop();
                let sh: number = $scrollableElement[0].scrollHeight;
                let ht: number = $scrollableElement.height();
                let nextCallHt: number = sh - self.nextPageOffset;
                if (st + ht >= nextCallHt && !self.isLoading && !self.allPagesLoaded) {
                    // console.log("Scroll Pagination: Load next page", ((st + ht) >= nextCallHt), !self.isLoading, !self.allPagesLoaded);
                    self.pageNumber++;
                }
            }, 100); // set new timer
        });
    }

    public detached() {
        $(this.scrollableElement).off("scroll");
    }
}
