import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

@autoinject
export class PalliativePatientWarning {
    public dialogController: DialogController;

    public constructor(dialogController: DialogController) {
        this.dialogController = dialogController;
    }
}
