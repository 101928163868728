import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { IFormSchema } from "../interfaces/form-builder/i-form-schema";
import { IFormModel, IModelSchema } from "../interfaces/form-builder/i-model-schema";
import { IPlanOfCare, IPOCTask, IProblemAreas, IUpdatePOCData } from "../interfaces/i-plan-of-care";

@autoinject()
export class PlanOfCareService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/poc";
    private _formUrl: string = "/api/v1/form";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getPlanOfCare(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}`)
            .fetch<IPlanOfCare[]>();
    }

    public async updatePlanOfCare(patientId: string, data: IUpdatePOCData) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async getHistory(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/history`)
            .fetch<IPOCTask[]>();
    }

    public async getProblemAreas(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/areas`)
            .fetch<IProblemAreas[]>();
    }

    public async getComprehensiveFormSchema() {
        return await this._httpClient
            .build(`${this._formUrl}/comprehensivepoc/formschema`)
            .fetch<IFormSchema>();
    }

    public async getComprehensiveModelSchema() {
        return await this._httpClient
            .build(`${this._formUrl}/comprehensivepoc/modelschema`)
            .fetch<IModelSchema>();
    }

    public async getComprehensiveModel(patientId: string) {
        return await this._httpClient
            .build(`${this._formUrl}/comprehensivepoc/${patientId}/model`)
            .fetch<IFormModel>();
    }

    public async printComprehensivePoc(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/export-comprehensive-plan-of-care`)
            .fetchBlob();
    }

    // Palliative Care Endpoints

    public async getPalliativeComprehensiveFormSchema() {
        return await this._httpClient
            .build(`${this._formUrl}/palliative-care/comprehensive-poc/form-schema`)
            .fetch<IFormSchema>();
    }

    public async getPalliativeComprehensiveModelSchema() {
        return await this._httpClient
            .build(`${this._formUrl}/palliative-care/comprehensive-poc/model-schema`)
            .fetch<IModelSchema>();
    }

    public async getPalliativeComprehensiveModel(patientId: string) {
        return await this._httpClient
            .build(`${this._formUrl}/palliative-care/comprehensive-poc/${patientId}/model`)
            .fetch<IFormModel>();
    }

    public async printPalliativeComprehensivePoc(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care/${patientId}/export-comprehensive-plan-of-care`)
            .fetchBlob();
    }
}
