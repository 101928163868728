import { ValidationRules } from "aurelia-validation";

export class DME {
    public id?: string;
    public name: string;
    public isInfusionPump: boolean;
    public isRelated: boolean;
    public notRelatedComments: string = "";
    public perDiem: number = null;
    public rental: number = null;
    public purchase: number = null;
    public associatedAgencyProviderIds: string[] = [];

    public constructor(data?: DME) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }
    private initValidation() {
        ValidationRules.ensure((x: DME) => x.name)
            .required()
            .withMessage("DME Name is required.")
            .on(this);
    }
}
