import {IBranchResponse} from "../../interfaces/i-branch";
import {IEnumResponse} from "../../interfaces/i-enum";
import {IGetTeamLean} from "../../interfaces/i-team";
import {ITypeaheadOptions} from "../../interfaces/i-typeahead";

export function getEnumFilterOptions(filter: string, filterOptions: IEnumResponse[]) {
    return filterOptions.map(filterOption => ({
        name: filterOption.name,
        value: filterOption.value as string
    })).filter(
        x => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
}

export function getFilterOptions(filter: string, filterOptions: (IBranchResponse | IGetTeamLean)[]) {
    return filterOptions.map(filterOption => ({
        name: filterOption.name,
        value: filterOption.id
    })).filter(
        x => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
}

export function getSelectedEnumFilter(selectedFilters: (number | string)[], filterEnums: IEnumResponse[]) {
    if (selectedFilters) {
        return filterEnums
            .filter(filterEnum => {
                return selectedFilters.includes(filterEnum.value.toString());
            }) as ITypeaheadOptions[];
    } else {
        return [];
    }
}

export function getSelectedFilter(selectedFilters: string[], filterOptions: (IBranchResponse | IGetTeamLean)[]) {
    if (selectedFilters) {
        return filterOptions
            .filter(opt => selectedFilters.includes(opt.id))
            .map(opt => ({
                value: opt.id,
                name: opt.name
            }));
    } else {
        return [];
    }
}
