import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    ICreateVendorResult,
    IGetInterimVendorIdResult,
    IGetVendorByTermQuery,
    IGetVendorQuery,
    IGetVendorResult,
    IGetVendorSlim
} from "../interfaces/i-get-vendor";
import {
    ICreateVendorContractQuery,
    IGetVendorContractResult,
    IUpdateVendorContract
} from "../interfaces/i-vendor-contract";
import {
    IGetVendorDocumentResult,
    IUploadVendorDocumentQuery,
    IUploadVendorDocumentResult
} from "../interfaces/i-vendor-document";
import { IVendor } from "../interfaces/vendor/i-vendor";
import { IPaginationNew, IPaginationRequest } from "../interfaces/i-pagination";
import { IGetRugRateQuery, IRugRate, ICreateRugRateResult } from "../interfaces/vendor/i-rug-rates";
import { IVendorLevelOfCareRate, IGetLevelOfCareRates, ICreateLOC } from "./i-vendor-service";

@autoinject
export class VendorsService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/vendors";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createVendor(newVendor: IVendor) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(newVendor)
            .fetch<ICreateVendorResult>();
    }

    public async getVendorsByPage(query: IGetVendorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetVendorResult>();
    }

    public async getVendorById(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}`).fetch<IVendor>();
    }

    public async getVendorByTerm(query: IGetVendorByTermQuery) {
        return await this._httpClient.build(`${this._baseUrl}/slim`).useQueryString(query).fetch<IGetVendorSlim[]>();
    }

    public async editVendor(id: string, vendor: IVendor) {
        return await this._httpClient.build(`${this._baseUrl}/${id}`).useMethod("PUT").useBody(vendor).fetch();
    }

    public async deleteVendor(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}`).useMethod("DELETE").fetchNoContent();
    }

    public async getVendorDocuments(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}/documents`).fetch<IGetVendorDocumentResult[]>();
    }

    public async createVendorContract(id: string, vendorContract: ICreateVendorContractQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/contracts`)
            .useMethod("POST")
            .useBody(vendorContract)
            .fetchNoContent();
    }

    public async deleteVendorDocument(id: string, documentId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/documents/${documentId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async uploadDocument(id: string, query: IUploadVendorDocumentQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/documents`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IUploadVendorDocumentResult>();
    }

    public async getVendorContracts(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}/contracts`).fetch<IGetVendorContractResult[]>();
    }

    public async deleteVendorContract(vendorId: string, contractId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/contracts/${contractId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async editVendorContract(vendorId: string, contractId: string, query: IUpdateVendorContract) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/contracts/${contractId}`)
            .useBody(query)
            .useMethod("PUT")
            .fetchNoContent();
    }

    public async getInterimVendor() {
        return await this._httpClient
            .build(`${this._baseUrl}/interim/id`)
            .useMethod("POST")
            .fetch<IGetInterimVendorIdResult>();
    }

    public async getRugLevels(vendorId: string, effectiveDate: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/rugrates/levels`)
            .useQueryString({ effectiveDate })
            .fetch<string[]>();
    }

    public async getRugRatesByPage(vendorId: string, query: IGetRugRateQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/rugrates`)
            .useQueryString(query)
            .fetch<IPaginationNew<IRugRate>>();
    }

    public async createRugRate(vendorId: string, rugRate: IRugRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/rugrates`)
            .useMethod("POST")
            .useBody(rugRate)
            .fetch<ICreateRugRateResult>();
    }

    public async updateRugRate(vendorId: string, rateId: string, rugRate: IRugRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/rugrates/${rateId}`)
            .useMethod("PUT")
            .useBody(rugRate)
            .fetchNoContent();
    }

    public async deleteRugRate(vendorId: string, rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/rugrates/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getVendorLevelOfCareRates(vendorId: string, query: IGetLevelOfCareRates) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/loc-rates`)
            .useQueryString(query)
            .fetch<IPaginationNew<IVendorLevelOfCareRate>>();
    }

    public async createVendorLevelOfCareRate(vendorId: string, rate: IVendorLevelOfCareRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/loc-rates`)
            .useMethod("POST")
            .useBody(rate)
            .fetch<ICreateLOC>();
    }

    public async updateVendorLevelOfCareRate(vendorId: string, rateId: string, rate: IVendorLevelOfCareRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/loc-rates/${rateId}`)
            .useMethod("PUT")
            .useBody(rate)
            .fetchNoContent();
    }

    public async deactivateVendorLevelOfCareRate(vendorId: string, rateId: string, date: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/loc-rates/${rateId}/deactivate`)
            .useBody({ deactivationDate: date })
            .useMethod("PUT")
            .fetchNoContent();
    }

    public async deleteVendorLevelOfCareRate(vendorId: string, rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${vendorId}/loc-rates/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async exportVendorExcel(query: IGetVendorQuery) {
        return await this._httpClient.build(`${this._baseUrl}/xlsx`).useMethod("POST").useBody(query).fetchBlob();
    }
}
