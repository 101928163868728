import { IPermissionCheck } from "../../common/utilities/permission-manager";
import { AdministrativePermissionEnum } from "../../enums/administrative-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";

export const viewPhysicianPermission: IPermissionCheck[] = [
    {
        action: PermissionActionEnum.View,
        resource: AdministrativePermissionEnum.Physicians
    }
];

export const viewDocumentTypePermission: IPermissionCheck[] = [
    {
        action: PermissionActionEnum.View,
        resource: AdministrativePermissionEnum.DocumentTypes
    }
];

export const viewTemplatePermission: IPermissionCheck[] = [
    {
        action: PermissionActionEnum.View,
        resource: AdministrativePermissionEnum.Templates
    }
];

export const viewPharmacyPermission: IPermissionCheck[] = [
    {
        action: PermissionActionEnum.View,
        resource: AdministrativePermissionEnum.Pharmacies
    }
];

export const viewDmePermission: IPermissionCheck[] = [
    {
        action: PermissionActionEnum.View,
        resource: AdministrativePermissionEnum.Dme
    }
];

export const viewSupplyPermission: IPermissionCheck[] = [
    {
        action: PermissionActionEnum.View,
        resource: AdministrativePermissionEnum.Supplies
    }
];

export const viewInquiryPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Inquiries,
        action: PermissionActionEnum.View
    }
];

export const viewReferralPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Referrals,
        action: PermissionActionEnum.View
    }
];

export const viewInsurancePayorsPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.InsurancePayers,
        action: PermissionActionEnum.View
    }
];

export const viewVendorPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Vendors,
        action: PermissionActionEnum.View
    }
];

export const viewInfectionLogPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.InfectionLog,
        action: PermissionActionEnum.View
    }
];

export const viewEmployeePermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Users,
        action: PermissionActionEnum.View
    }
];

export const editVendorPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Vendors,
        action: PermissionActionEnum.Edit
    }
];

export const viewCustomMedicationPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.CustomMedications,
        action: PermissionActionEnum.View
    }
];

export const viewNonAdmitReasons: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.NonAdmitReasons,
        action: PermissionActionEnum.View
    }
];

export const viewVolunteerActivitiesPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.VolunteerActivities,
        action: PermissionActionEnum.View
    }
];

export const viewNonPatientActivities: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.NonPatientActivities,
        action: PermissionActionEnum.View
    }
];
