import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

import { IAddItemBehavior } from "../../../interfaces/form-builder/i-behavior";
import { FormButtonEvent } from "../form-input/form-button/form-button";

@autoinject
export class AddItemBehaviorHandler {
    private _ea: EventAggregator;

    public constructor(ea: EventAggregator) {
        this._ea = ea;
    }

    public handle(buttonBehavior: IAddItemBehavior) {
        this._ea.publish(FormButtonEvent.ListAddItem, buttonBehavior);
    }
}
