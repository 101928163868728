import { ValidationRules } from "aurelia-validation";
import { ICreateInfectionReport } from "../../interfaces/infection-log/i-infection-log";

export class CreateInfectionReport implements ICreateInfectionReport {
    public userId?: string;
    public patientId: string = "";
    public createdDate: string = "";
    public infectionDate: string = "";

    public constructor(data?: ICreateInfectionReport) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules
            .ensure((x: CreateInfectionReport) => x.patientId).required().withMessage("Patient is required.")
            .ensure((x: CreateInfectionReport) => x.createdDate).required().withMessage("Created Date is required.")
            .ensure((x: CreateInfectionReport) => x.infectionDate).required().withMessage("Infection Date is required.")
            .on(this);
    }
}
