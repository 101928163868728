import { bindingMode } from "aurelia-binding";
import { bindable, containerless } from "aurelia-framework";
import { RouteConfig } from "aurelia-router";

@containerless
export class PageActionsList {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public baseRoute: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public routes: RouteConfig[];

    public generateRouteHref(href: string) {
        return `/${href}`;
    }
}
