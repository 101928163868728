import "./message-users.scss";

import { autoinject, bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { IMessageUser, INewMessage } from "../../../interfaces/i-message";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IGetUsersLeanResult, IGetUserSlimInfoResult } from "../../../interfaces/i-user";
import { MessageService } from "../../../services/message-service";
import { ToastrService } from "../../../services/toastr-service";
import { UsersService } from "../../../services/users-service";
import { AssetsService } from "./../../../services/assets-service";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { AdministrativePermissionEnum } from "../../../enums/administrative-permission-enum";
import { PermissionManager } from "../../../common/utilities/permission-manager";

@autoinject
@customElement("message-users")
export class MessageUsers {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showCancel: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public newMessage: INewMessage = {
        title: "",
        message: "",
        userIds: [],
        isImportant: false
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public users: IMessageUser[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showInput: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public closeSendMessage: () => void;
    @observable({ changeHandler: nameof<MessageUsers>("tagInputUsersChanged") })
    public tagInputUsers: ITypeaheadOptions[] = [];
    public files: File[] = [];
    private _permissionManager: PermissionManager;
    private _toastrService: ToastrService;
    private _messageService: MessageService;
    private _usersService: UsersService;
    private _assetsService: AssetsService;
    public isSendingMessage: boolean = false;
    public currentUser: IGetUserSlimInfoResult;

    @computedFrom(`${nameof<MessageUsers>("newMessage")}.${nameof<INewMessage>("message")}`)
    public get initMessage() {
        return this.newMessage.message.length > 0 ? this.newMessage.message : "";
    }

    public constructor(
        assetsService: AssetsService,
        toastrService: ToastrService,
        messageService: MessageService,
        usersService: UsersService,
        permissionManager: PermissionManager
    ) {
        this._assetsService = assetsService;
        this._toastrService = toastrService;
        this._messageService = messageService;
        this._usersService = usersService;
        this._permissionManager = permissionManager;
    }

    public bind() {
        this.checkPermission();
    }

    public async attached() {
        // this.canShow = !!this.users && this.users.length > 0;
        this.currentUser = await this._usersService.getCurrentUser();
    }

    private checkPermission() {
        let hasMessageCenterPermission = this._permissionManager.checkPermission([
            {
                resource: AdministrativePermissionEnum.MessageCenter,
                action: PermissionActionEnum.View
            }
        ]);
        if (!hasMessageCenterPermission) {
            this._toastrService.error({
                title: `Error`,
                message: `You do not have permission to view this data. Contact an administrator at your agency to request access.`
            });

            this.closeSendMessage();
        }
    }

    public async getUsersList(filter: string, limit: number) {
        return this.getDisplayUsers(await this._usersService.getUsersLean({ term: filter, page: 1, pageLength: 10 }));
    }

    public getDisplayUsers(data: IGetUsersLeanResult) {
        if (data) {
            let filteredItems = data.items.filter((user) => user.id !== this.currentUser.id);
            return filteredItems.map((user) => ({
                name: `${user.lastName ? user.lastName + ", " : ""}${user.firstName}`,
                value: user.id,
                firstName: user.firstName,
                lastName: user.lastName
            }));
        }
        return [];
    }

    public tagInputUsersChanged() {
        if (this.newMessage) {
            this.newMessage.userIds = this.tagInputUsers.map((user) => user.value);
        }
    }

    public async sendMessage(message: string) {
        if (this.newMessage) {
            try {
                this.isSendingMessage = true;
                this.newMessage.message = message;
                if (this.files?.length > 25) {
                    this.files.splice(25);
                }
                this.newMessage.assetIds = await this._assetsService.uploadMessageFiles(this.files);
                if (!this.showInput) {
                    this.newMessage.userIds = this.users.map((user) => user.userId);
                }
                await this._messageService.newConversation(this.newMessage);
                this.closeSendMessage();
                this._toastrService.success({
                    title: "Message Sent",
                    message: `Your message has been successfully sent.`
                });
            } catch (e) {
                console.error(e);
                this._toastrService.error({
                    title: "Message Send Error",
                    message: `There was a problem while sending the message. Please try again.`
                });
            } finally {
                this.isSendingMessage = false;
                this.files = [];
            }
        }
    }

    public navigateToUser(user: IMessageUser) {
        let win = window.open(`/employees/employee-center/${user.userId}`, "_blank");
        win.focus();
    }

    public toggleImportant() {
        this.newMessage.isImportant = !this.newMessage.isImportant;
    }
}
