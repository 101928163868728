import "./add-document-row.scss";

import { bindable, bindingMode, customElement, observable } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { IPatientFileDetails } from "../../../../interfaces/i-get-patient";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";

@customElement("add-document-row")
export class AddDocumentRow {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public fileDetail: IPatientFileDetails;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public uploading: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public searchedTypes: ITypeaheadOptions[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public uploadDocuments: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public removeUploadedFile: () => void;
    @observable({
        changeHandler: nameof<AddDocumentRow>("uploadTypeChanged")
    })
    public selectedUploadType: ITypeaheadOptions;
    public typeValidation: IValidateCustomElement = {
        required: true,
        displayName: "Type"
    };

    public uploadTypeChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            this.fileDetail.type = newValue;
        }
    }
}
