import { computedFrom, customElement, inlineView } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { NumberAnimator } from "./number-animator";

@inlineView("<template>${value | currencyFormat}</template>")
@customElement("currency-animator")
export class CurrencyAnimator extends NumberAnimator {
    public constructor () {
        super();
        this.desired = 2;
    }

    @computedFrom(nameof<CurrencyAnimator>("text"))
    public get value() {
        return this.text ? parseFloat(this.text) : null;
    }
}
