import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const recertificationsDueTooltip: IReportTooltip = {
    description:
        "Displays a list of patients with upcoming, at risk and past due recertifications. Patients who are 15 to 30 days away from recertification are listed under upcoming recertifications. Patients who are 1 to 14 days away from recertification are classified as at risk. Patients with past due recertifications are listed under past due. This report assists with state and regulatory surveys and facilitates compliance with recertification requirements.",
    parameters: "Recertification Status, Task, Team, Branch, Tags",
    reportIncludes:
        "Patient Name, MRN, Branch, Team, Current Benefit Period, Recertification Date, Recertification Status, Days Remaining, Incomplete Tasks, Tags"
};

export const compAssessmentDueTooltip: IReportTooltip = {
    description:
        " Displays a list of patients with unscheduled or incomplete comprehensive assessments and the number of days remaining before they must be completed. This report facilitates timely completion of comprehensive assessments for regulatory compliance.",
    parameters: "Branch, Team, Discipline",
    reportIncludes:
        "Patient Name, Patient Status, Branch, Team, Discipline, Task, Task Status, Admission Date, Days Remaining"
};

export const frequencyWatchTooltip: IReportTooltip = {
    description:
        "Displays a list of patients who have visits scheduled over or under the ordered frequency for each active discipline for the patient. This report enables users to prevent survey deficiencies by ensuring visits are scheduled according to ordered frequencies.",
    parameters: "Branch, Team, Discipline",
    reportIncludes:
        "Patient Name, Branch, Team, Discipline, Frequency, Frequency Status, Start Date, End Date, Visits Scheduled, Visits Ordered, Scheduled Variance, Tags, Actions"
};

export const visitAlertsTooltip: IReportTooltip = {
    description: "Generates a list of all visits by status.",
    parameters:
        "Branch, Assigned To, Status, Date Range. Selecting different parameters will affect the results displayed in the report.",
    reportIncludes: "Branch, Patient, Assigned To, Visit Type, Visit Date, Status."
};

export const scheduledTasksTooltip: IReportTooltip = {
    description:
        "Displays a list of tasks that have been scheduled for patients and employees in a given date range. The date range defaults to the current month but can be filtered to display scheduled tasks within any time frame. This report supports seamless operations and schedule management.",
    parameters: "Branch, Team, Task, Status, Patient, Employee, Title, Date Range",
    reportIncludes: "Task, Status, Patient, MRN, Employee, Title, Branch, Team, Schedule Start, Schedule End"
};

export const missedVisitUserTooltip: IReportTooltip = {
    description:
        "Generates a list of missed visits. This report is helpful for identifying any common trends that may affect frequency compliance.",
    parameters: "Branch, Assigned To, Patient, Task Status, Date Range",
    reportIncludes:
        "Branch, Assigned To, User Status, Patient, Task, Task Status, Task Date, Reason, Physician Notified, RN Case Manager Notified."
};

export const visitHistoryDateRangeTooltip: IReportTooltip = {
    description:
        "Displays a list of visits and tasks scheduled within the selected date range. This report is useful for monitoring staff activity and performance and assigning new work to employees.",
    parameters: "Branch, Team, Title, Employee, Tags, Task, Task Status, Date Range",
    reportIncludes:
        "Employee Name, Title, Patient Name, Branch, Team, Task, Task Date, Task Status, Visit Start Time, Visit End Time, Total Visit Time, Documentation Time, Travel Start Time, Travel End Time, Total Travel Time, Tags"
};

export const certificationWatchReportTooltip: IReportTooltip = {
    description:
        "Displays a list of patients with unscheduled or incomplete Certification of Terminal Illness or Face-to-Face documentation for certification and recertification. This report facilitates timely completion of documentation for regulatory compliance.",
    parameters: "Patient Status, Branch, Team, Task",
    reportIncludes:
        " Patient Name, Patient Status, Branch, Team, Task, Task Status, Current Benefit Period Number, Upcoming Benefit Period Number, Due Date, Days Remaining"
};

export const bereavementServicesReportTooltip: IReportTooltip = {
    description: "Displays a list of bereavement services scheduled in the selected date range.",
    parameters: "Branch, Team, Service, Service Status, Patient, Employee/Volunteer, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Employee/Volunteer, Service, Service Status, Schedule Date, Bereaved Contact"
};
