import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { config } from "../common/config";
import { IGetAccountsModel, IGetAccountsQuery, IGetAccountsResult } from "../interfaces/i-get-accounts";
import { NoAccountException } from "../common/exceptions/no-account-exception";
import { NotAuthenticatedException } from "../common/exceptions/not-authenticated-exception";

@autoinject
export class MushuService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = `${config.mushuApiUrl}/api/v1`;
    private _accounts: IGetAccountsModel[] = [];
    private _accountsLoaded: boolean = false;

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getAccounts() {
        try {
            if (!this._accountsLoaded) {
                let response = await this._httpClient
                    .build(`${this._baseUrl}/accounts`)
                    .useQueryString<IGetAccountsQuery>({
                        page: 1,
                        pageLength: 25
                    })
                    .fetch<IGetAccountsResult>();
                this._accounts = response.items;
                this._accountsLoaded = true;
            }
            return this._accounts;
        } catch (e) {
            console.error(e);
            let allowedFailedStatus = [401, 403, 404];
            if (!!e.status && allowedFailedStatus.includes(e.status)) {
                this._accountsLoaded = true;
            }
            throw e;
        }
    }
}
