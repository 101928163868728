import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { UserManager } from "oidc-client";

export const UserSessionChanged = "oidc:user-session-changed";
export const SilentRenewError = "oidc:silent-renew-error";
export const UserLoaded = "oidc:user-loaded";
export const UserUnloaded = "oidc:user-unloaded";
export const UserSignedOut = "oidc:user-signed-out";
export const AccessTokenExpiring = "oidc:access-token-expiring";
export const AccessTokenExpired = "oidc:access-token-expired";
export const OidcSignOut = "oidc:sign-out";
export const OidcSilentRenewStart = "oidc:silent-renew:start";
export const OidcSilentRenewCallback = "oidc:silent-renew:callback";
export const OidcSignInRedirectStart = "oidc:sign-in:redirect:start";
export const OidcSignInRedirectCallback = "oidc:sign-in:redirect:callback";

@autoinject
export class OidcEventHandler {
    private readonly _ea: EventAggregator;
    private readonly _router: Router;

    public constructor (ea: EventAggregator) {
        this._ea = ea;
    }

    public attach(userManager: UserManager) {
        userManager.events.addUserSessionChanged(() => this.publishAndLog(UserSessionChanged, null));
        userManager.events.addSilentRenewError((e) => this.publishAndLog(SilentRenewError, e));
        userManager.events.addUserLoaded((e) => this.publishAndLog(UserLoaded, e));
        userManager.events.addUserUnloaded(() => this.publishAndLog(UserUnloaded, null));
        userManager.events.addUserSignedOut(() => this.publishAndLog(UserSignedOut, null));
        userManager.events.addAccessTokenExpiring((e) => this.publishAndLog(AccessTokenExpiring, e));
        userManager.events.addAccessTokenExpired((e) => this.publishAndLog(AccessTokenExpired, e));

        this._ea.subscribe(OidcSignOut, async () => {
            console.log("Handling sign-out redirect request.");
            await userManager.signoutRedirect();
        });
        this._ea.subscribe(OidcSilentRenewStart, () => {
            console.log("Handling silent renewal request.");
            userManager.startSilentRenew();
        });
        this._ea.subscribe(OidcSignInRedirectStart, async () => {
            console.log("Handling redirect sign-in request.");
            await userManager.signinRedirect();
        });
        this._ea.subscribe(OidcSignInRedirectCallback, (location: string) => {
            console.log("Handling redirect sign-in response.", location);
            userManager.signinRedirectCallback(location);
        });
        this._ea.subscribe(OidcSilentRenewCallback, async (location: string) => {
            console.log("Handling silent sign-in response.", location);
            await userManager.signinSilentCallback(location);
        });
        this._ea.subscribe(UserSignedOut, async (location: string) => {
            console.log("Handling sign-out request.", location);
            await userManager.removeUser();
            window.location.reload();
        });
    }

    public attachToRouter(userManager: UserManager, router: Router) {
        //
    }

    private publishAndLog(channel: string, data: any) {
        console.log(`Event occurred on channel ${channel}.`, data);
        this._ea.publish(channel, data);
    }
}
