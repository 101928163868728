import "./patient-documents.scss";

import { autoinject } from "aurelia-dependency-injection";
import { DialogOpenResult, DialogService, DialogSettings } from "aurelia-dialog";
import { bindable, bindingMode, customElement, observable } from "aurelia-framework";
import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";

import nameof from "../../../../common/nameof";
import { ClinicalPermissionEnum } from "../../../../enums/clinical-permission-enum";
import { PermissionActionEnum } from "../../../../enums/permission-action-enum";
import {
    IPatientDialogOptions,
    IPatientDocument,
    IPatientFileDetails,
    IUploadPatientDocumentQuery,
    IUploadPatientDocumentResult
} from "../../../../interfaces/i-get-patient";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IPromptOptions, Prompt } from "../../../../resources/dialogs/prompt/prompt";
import { AssetsService } from "../../../../services/assets-service";
import { DocumentTypeService } from "../../../../services/document-types-service";
import {
    IReferralDocument,
    IUploadReferralDocumentQuery,
    IUploadReferralDocumentResult
} from "../../../../services/i-referral-services";
import { ToastrService } from "../../../../services/toastr-service";
import { EnumMap } from "./../../../../common/utilities/enum-map";
import { PermissionManager } from "./../../../../common/utilities/permission-manager";
import { EnumsService } from "./../../../../services/enums-service";
import { PatientsService } from "./../../../../services/patient-service";
import { ProvidersService } from "./../../../../services/providers-service";
import { ReferralService } from "./../../../../services/referral-service";

@autoinject
@customElement("patient-documents")
export class PatientDocuments {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPalliative: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isReferralPage: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientVersionId: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public getDocuments: (args: { id: string }) => Promise<IPatientDocument[] | IReferralDocument[]>;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public deleteDocument: (args: { id: string; documentId: string }) => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public uploadDocument: (args: {
        id: string;
        newDocument: IUploadReferralDocumentQuery | IUploadPatientDocumentQuery;
    }) => Promise<IUploadReferralDocumentResult | IUploadPatientDocumentResult>;
    private readonly _documentTypeService: DocumentTypeService;
    private readonly _assetsService: AssetsService;
    private readonly _toastrService: ToastrService;
    private readonly _permissionManager: PermissionManager;
    private _dialogService: DialogService;
    private readonly _patientsService: PatientsService;
    private readonly _providersService: ProvidersService;
    private readonly _referralService: ReferralService;
    private readonly _enumsService: EnumsService;
    @observable({
        changeHandler: nameof<PatientDocuments>("uploadFileChanged")
    })
    public uploadFile: FileList;
    public patientDocuments: IPatientDocument[] = [];
    public loadingCount: number = 5;
    public loadingDocuments: boolean = true;
    public validationController: ValidationController;
    public addDocument: boolean = false;
    public searchedTypes: ITypeaheadOptions[];
    public selectedUploadType: ITypeaheadOptions[] = [];
    public fileDetails: IPatientFileDetails[] = [];
    public fileInput: HTMLInputElement;
    public uploading: boolean = false;
    public categoryTypeEnumMap: EnumMap = new EnumMap([]);
    public addPatientDocumentsPermission: boolean = false;
    public deletePatientDocumentsPermission: boolean = false;

    public constructor(
        toastrService: ToastrService,
        documentTypeService: DocumentTypeService,
        assetsService: AssetsService,
        dialogService: DialogService,
        validationControlFactory: ValidationControllerFactory,
        patientsService: PatientsService,
        providersService: ProvidersService,
        enumsService: EnumsService,
        permissionManager: PermissionManager,
        referralService: ReferralService
    ) {
        this.validationController = validationControlFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
        this._documentTypeService = documentTypeService;
        this._assetsService = assetsService;
        this._toastrService = toastrService;
        this._dialogService = dialogService;
        this._patientsService = patientsService;
        this._providersService = providersService;
        this._enumsService = enumsService;
        this._referralService = referralService;
        this._permissionManager = permissionManager;
    }

    public async attached(options: IPatientDialogOptions) {
        await this.loadSearchedTypes();
        this.addPatientDocumentsPermission = this._permissionManager.checkPermission([
            {
                resource: ClinicalPermissionEnum.ManageDocuments,
                action: PermissionActionEnum.Add
            }
        ]);
        this.deletePatientDocumentsPermission = this._permissionManager.checkPermission([
            {
                resource: ClinicalPermissionEnum.ManageDocuments,
                action: PermissionActionEnum.Delete
            }
        ]);
        this.loadFilesList();
    }

    public async loadSearchedTypes() {
        if (!this.patientId) {
            this.searchedTypes = [];
        }
        let categoryTypeEnum = await this._enumsService.getDocumentTypeCategory();
        this.categoryTypeEnumMap = new EnumMap(categoryTypeEnum);
        let category = Number(this.categoryTypeEnumMap.getEnumValue("patient"));
        let searchedTypes = await this._documentTypeService.getDocumentTypesList({
            providerIds: [await this.getProviderId()],
            category
        });
        if (searchedTypes) {
            this.searchedTypes = searchedTypes.map((documentType) => ({
                value: documentType.id,
                name: documentType.type
            }));
        } else {
            this.searchedTypes = [];
        }
    }

    private async getProviderId(): Promise<string> {
        let branchId: string;
        let providerId: string;
        if (this.isReferralPage) {
            if (this.isPalliative) {
                let patientInfo = await this._referralService.getPalliativeReferral(this.patientId);
                branchId = patientInfo.locationId;
                providerId = patientInfo.providerId;
            } else {
                let patientInfo = await this._referralService.getHospiceReferral(this.patientId);
                branchId = patientInfo.locationId;
                providerId = patientInfo.providerId;
            }
        } else {
            let patientInfo = await this._patientsService.getPatientSlim(this.patientId);
            branchId = patientInfo.branchId;
        }
        if (!!providerId) {
            return providerId;
        } else {
            let providers = await this._providersService.getProvider();
            let patientProvider = providers.find((provider) =>
                provider.branches.some((branch) => branch.id === branchId)
            );
            return patientProvider.id;
        }
    }

    public async loadFilesList() {
        if (this.patientId) {
            this.loadingDocuments = true;
            this.patientDocuments = await this.getDocuments({ id: this.patientId });
            this.loadingDocuments = false;
        }
    }

    public removeUploadedFile() {
        // https://github.com/aurelia/binding/issues/314
        this.fileDetails = [];
        // this.fileInput.value = null;
    }

    public uploadFileChanged(newValue: File[], oldValue: File[]) {
        let files: File[] = Array.from(newValue);
        this.fileDetails = files.map((file: File) => ({ name: file.name, type: null, file: file }));
    }

    public async uploadDocuments() {
        let res = await this.validationController.validate();
        if (!res.valid) {
            return;
        }
        if (this.patientId) {
            try {
                this.uploading = true;
                await this.asyncForEach(this.fileDetails, async (fileDetail, index) => {
                    if (fileDetail.type) {
                        let assetId = await this._assetsService.uploadFile(fileDetail.file);
                        await this.uploadDocument({
                            id: this.patientId,
                            newDocument: {
                                assetId: assetId,
                                documentName: fileDetail.name,
                                uploadType: fileDetail.type.value,
                                patientVersionId: this.patientVersionId
                            }
                        });
                    } else {
                        throw new Error("No Type");
                    }
                });
                this.removeUploadedFile();
                this.loadFilesList();
                this._toastrService.success({
                    title: `Upload Successful`,
                    message: `The files have been successfully uploaded`
                });
            } catch (error) {
                await this.showUploadError();
            }
            this.uploading = false;
        } else {
            await this.showUploadError();
        }
    }

    public async showUploadError() {
        this._toastrService.error({
            title: `Error`,
            message: `There was a problem while uploading files. Please try again.`
        });
    }

    public async deleteSelectedDocument(documentId: string) {
        try {
            let promptOptions: IPromptOptions = {
                message: "Are you sure you want to delete?",
                okText: "Yes",
                cancelText: "No"
            };
            let dialogOptions: DialogSettings = {
                viewModel: Prompt,
                model: promptOptions
            };
            let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
            let closeResult = await dialog.closeResult;

            if (!closeResult.wasCancelled) {
                await this.deleteDocument({ id: this.patientId, documentId });
                this.loadFilesList();
                this._toastrService.success({
                    message: `The document has been successfully deleted.`,
                    title: `Document Deleted`
                });
            }
        } catch (error) {
            this._toastrService.error({
                title: `Error - Document`,
                message: `There was a problem while deleting the document. Please try again.`
            });
        }
    }

    private async asyncForEach(
        array: IPatientFileDetails[],
        callback: (item: IPatientFileDetails, index: number, array: IPatientFileDetails[]) => void
    ) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
}
