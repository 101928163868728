import Infrastructure from "./infrastructure";
import * as Sentry from "@sentry/browser";
import { LogManager } from "aurelia-framework";
import { SentryLogger } from "./sentry-logger";

export class SentryClient {

    public start() {
        if (Infrastructure.sentryDsn) {
            Sentry.init({
                dsn: Infrastructure.sentryDsn,
                release: Infrastructure.releaseVersion,
                debug: true // TODO: Check what extra information is captured
            });
            // https://github.com/getsentry/raven-js/issues/424
            window.addEventListener("unhandledrejection", (errRaw) => {
                let err = errRaw as any;
                if (err != null && err.reason != null) {
                    let reason = err.reason;
                    Sentry.captureException(reason);
                }
            });
        } else {
            console.warn(`Sentry not loaded. Consider adding a Dsn for '${Infrastructure.releaseEnvironment}'.`);
        }
    }

    public captureLogging() {
        LogManager.addAppender(new SentryLogger());
    }
}
