const localGlobal = window as any;
const _releaseVersion: string = localGlobal._releaseVersion == null ? "" : localGlobal._releaseVersion; // Will be replaced by Octopus on deployment.
const _releaseEnvironment: string = localGlobal._releaseEnvironment == null ? "" : localGlobal._releaseEnvironment; // Will be replaced by Octopus on deployment.
const _releaseTimestamp: string = localGlobal._releaseTimestamp == null ? "" : localGlobal._releaseTimestamp; // Will be replaced by Octopus on deployment.
const _buildVersion: string = localGlobal._buildVersion == null ? "" : localGlobal._buildVersion; // Will be replaced by TeamCity on build.
const _assetServerUrl: string = localGlobal._assetServerUrl == null ? "" : localGlobal._assetServerUrl; // Will be replaced by Octopus on deployment.
const identityUrl = "https://identity.test.axxess.tech";
const _authorityUrl: string =
    localGlobal._authorityUrl?.startsWith("#") || localGlobal._authorityUrl == null
        ? identityUrl
        : localGlobal._authorityUrl; // Will be replaced by Octopus on deployment.
const _dsnMappings: { [key: string]: string } = {
    Production: "https://10f3af03da694fc89c95d7b56ae047f7@sentry.axxessweb.com/16",
    Staging: null,
    Development: null,
    Local: null
};

export enum ReleaseEnvironments {
    Production = "Production" as any,
    Staging = "Staging" as any,
    Development = "Development" as any,
    Local = "Local" as any,
    Testing = "Testing" as any
}

export default class Infrastructure {
    public static get releaseVersion(): string {
        return _releaseVersion.startsWith("#") ? "0.0.0.1" : _releaseVersion;
    }
    public static get releaseEnvironment(): string {
        return _releaseEnvironment.startsWith("#") ? "Local" : _releaseEnvironment;
    }
    public static get isProdEnvironment(): boolean {
        return _releaseEnvironment === ReleaseEnvironments.Production.toString();
    }
    public static get buildVersion(): string {
        return _buildVersion.startsWith("#") ? "0.0.0.2" : _buildVersion;
    }
    public static get releaseTimestamp(): string {
        return _releaseTimestamp;
    }
    public static get sentryDsn(): string {
        return _dsnMappings[this.releaseEnvironment];
    }
    public static get assetServerUrl(): string {
        return _assetServerUrl;
    }

    public static get authorityUrl(): string {
        return _releaseEnvironment === ReleaseEnvironments.Local.toString() ? identityUrl : _authorityUrl;
    }
}
