import { BindingEngine, bindingMode, computedFrom, Disposable, observable } from "aurelia-binding";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable } from "aurelia-framework";
import nameof from "../../../common/nameof";
import {
    IAddItemBehavior,
    IAutofillBehavior,
    IClearTargetBehavior,
    ICountListGroupBehavior,
    IDeleteItemBehavior,
    IGetBehavior,
    ILoadNarrativeBehavior,
    INavigationBehavior,
    IPermissionBehavior,
    IPrefillBehavior,
    IReadOnlyIfBehavior
} from "../../../interfaces/form-builder/i-behavior";
import { FormulaBehaviorHandler } from "./../behavior-handlers/formula-behavior-handler";
import { IComputeFormulaBehavior } from "./../../../interfaces/form-builder/i-behavior";
import { IButtonOptions } from "../../../interfaces/form-builder/i-button-options";
import { IDisplayDependency } from "../../../interfaces/form-builder/i-element";
import { IInput, IListInput, IOptionsInput, IScoreTableInput } from "../../../interfaces/form-builder/i-input";
import { IInputOptions } from "../../../interfaces/form-builder/i-input-options";
import { FormModelType } from "../../../interfaces/form-builder/i-model-schema";
import { IScrubberResult } from "../../../interfaces/form-builder/i-scrubber";
import { BehaviorTypesEnum } from "../behavior-handlers/behavior-types-enum";
import { ClearTargetBehaviorHandler } from "../behavior-handlers/clear-target-behavior-handler";
import { DependencyTypeEnum } from "../behavior-handlers/display-type-enum";
import { PermissionBehaviorHandler } from "../behavior-handlers/permission-behavior-handler";
import { PrefillBehaviorHandler, PrefillBehaviorResourceEnum } from "../behavior-handlers/prefill-behavior-handler";
import { ReadOnlyIfBehaviorHandler } from "../behavior-handlers/read-only-if-behavior-handler";
import { ScrubberResultPublishKey } from "../form-navigator/form-navigator";
import {
    FormBuilderEvent,
    NoteDataManager,
    ReadOnlyChangePublishKey,
    RefreshViewPublishKey
} from "../note-data-manager";
import { DefaultValueHandler } from "./default-value-handler";
import { FormButtonEvent } from "./form-button/form-button";
import "./form-input.scss";
import { InputTypesEnum } from "./input-types-enum";
import { IAutofillData } from "../../../interfaces/form-builder/i-autofill-data";
import { FormValidationEnum } from "../../../enums/form-validation-enum";
import { IConditionalValidation } from "../../../interfaces/form-builder/i-validation";
import { FormConditionValidationEnum } from "../../../enums/form-condition-validation-enum";
import { RequiredIfValidationHandler } from "../validation-handlers/required-if-validation-handler";
import { getColumnClasses } from "../../../common/utilities/column-classes";

@autoinject
export class FormInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<FormInput>("elementChanged")
    })
    public element: IInput | IScoreTableInput | IListInput | IOptionsInput;
    private _noteDataManager: NoteDataManager;
    private _ea: EventAggregator;
    private _bindingEngine: BindingEngine;
    private _prefillBehaviorHandler: PrefillBehaviorHandler;
    private _clearTargetBehaviorHandler: ClearTargetBehaviorHandler;
    private _readOnlyIfBehaviorHandler: ReadOnlyIfBehaviorHandler;
    private _permissionBehaviorHandler: PermissionBehaviorHandler;
    private _observerSubscriptions: Disposable[] = [];
    private _eaSubscriptions: Subscription[] = [];
    private _isPropFromListInput: boolean;
    private _clearTargetBehaviorInfo: IClearTargetBehavior;
    private _readOnlyIfBehaviorInfo: IReadOnlyIfBehavior;
    private _permissionBehaviorInfo: IPermissionBehavior;
    private _getMultiTarget: IAutofillBehavior;
    private _getBehaviorInfo: IGetBehavior;
    private _countListGroupBehavior: ICountListGroupBehavior;
    private _loadNarrativeBehavior: ILoadNarrativeBehavior;
    private _defaultValueHandler: DefaultValueHandler;
    private _computeFormulaBehavior: IComputeFormulaBehavior;
    private _formulaBehaviorHandler: FormulaBehaviorHandler;
    @observable({
        changeHandler: nameof<FormInput>("resultChanged")
    })
    public result: string;
    @observable({
        changeHandler: nameof<FormInput>("resultArrayChanged")
    })
    public resultArray: string[] = [];
    @observable({
        changeHandler: nameof<FormInput>("secondaryDataChanged")
    })
    public secondaryData: string;
    @observable({
        changeHandler: nameof<FormInput>("secondaryDataArrayChanged")
    })
    public secondaryDataArray: string[] = [];
    @observable({
        changeHandler: nameof<FormInput>("dataObjectChanged")
    })
    public dataObject: string;
    public tooltip: string;
    public hasError: boolean = false;
    public hasWarning: boolean = false;
    public hasInconsistency: boolean = false;
    public hasHighlight: string = "";
    public isRequired: boolean = false;
    public isRequiredIf: boolean = false;
    public isReadOnly: boolean = false;
    public columnsClasses: string = "";
    public secondaryDataProp: string;
    public isButton: boolean = false;
    public inputTypesEnum: typeof InputTypesEnum = InputTypesEnum;
    public readonly requiredIfValidationHandler: RequiredIfValidationHandler;
    public patientId: string = "";
    public visitDate: string = "";
    public formulaTargetNameList: string[] = [];

    @computedFrom(nameof<FormInput>("element"), nameof<FormInput>("isReadOnly"), nameof<FormInput>("showIfActive"))
    public get inputElementOptions(): IInputOptions {
        if (this.element?.options) {
            let displayDependencies = this.element?.valueDependencies;
            if (displayDependencies?.length > 0) {
                let noteModel = this._noteDataManager.getNoteModel();
                displayDependencies?.forEach((dependency) => {
                    let targetName = this._noteDataManager.getModelKeyFromTargetName(dependency.targetName);
                    let dependencyValue = noteModel[targetName] || false;
                    switch (dependency.dependencyType) {
                        case DependencyTypeEnum.LimitOptions:
                            this.element.options = this.element.options.filter((option) => {
                                return !!Array.isArray(dependencyValue) && dependencyValue.includes(option.value);
                            });
                            break;
                    }
                });
            }
        }
        return {
            behaviors: this.element.behaviors,
            isReadOnly: this.isReadOnly,
            placeholderText: this.element.placeholderText ? this.element.placeholderText : "",
            defaultValue: this.element.defaultValue,
            name: this.element.name,
            options: this.element.options,
            questions: this.element.questions,
            isTextOnly: this.element.isTextOnly,
            showActiveOptions: this.element.showActiveOptions
        };
    }

    public set inputElementOptions(data) {
        // console.log("set inputElementOptions: ", data);
    }

    @computedFrom(nameof<FormInput>("element"), nameof<FormInput>("isReadOnly"))
    public get buttonElementOptions(): IButtonOptions {
        let modelTarget: IDisplayDependency;
        let neverDisable = false;
        if (this.element.valueDependencies?.length > 0) {
            this.element.valueDependencies.forEach((dependency) => {
                if (dependency.neverDisable) {
                    neverDisable = dependency.neverDisable;
                }
                if (dependency.dependencyType === DependencyTypeEnum.modelTarget) {
                    modelTarget = dependency;
                }
            });
        }
        return {
            elementType: this.element.elementType,
            url: this.element.url,
            behaviors: this.element.behaviors as INavigationBehavior[],
            isReadOnly: this.isReadOnly,
            placeholderText: this.element.placeholderText ? this.element.placeholderText : "",
            defaultValue: this.element.defaultValue,
            name: this.element.name,
            label: this.element.label,
            modelTarget: modelTarget,
            neverDisable
        };
    }

    public set buttonElementOptions(data) {
        // console.log("set buttonElementOptions: ", data);
    }

    @computedFrom(
        nameof<FormInput>("result"),
        nameof<FormInput>("resultArray"),
        `${nameof<FormInput>("element")}.${nameof<IInput>("inputType")}`,
        `${nameof<FormInput>("element")}.${nameof<IInput>("showActiveOptions")}`
    )
    public get showIfActive() {
        let hasValue = false;
        let result = true;

        if (!!this.element) {
            let allowedInputTypes = [InputTypesEnum.SingleSelect, InputTypesEnum.SingleSelectDropdown];

            if (allowedInputTypes.includes(this.element.inputType as InputTypesEnum)) {
                hasValue = !!this.result;
            } else if (this.element.inputType === InputTypesEnum.MultiSelect) {
                hasValue = this.resultArray?.length > 0;
            }

            if (this.element.showActiveOptions) {
                result = hasValue;
            }
        }

        return result;
    }

    @computedFrom(nameof<FormInput>("isRequired"), nameof<FormInput>("isRequiredIf"))
    public get showIfRequired() {
        return this.isRequiredIf || this.isRequired;
    }

    public constructor(
        ea: EventAggregator,
        bindingEngine: BindingEngine,
        prefillBehavior: PrefillBehaviorHandler,
        clearTargetBehavior: ClearTargetBehaviorHandler,
        readOnlyIfBehaviorHandler: ReadOnlyIfBehaviorHandler,
        permissionBehaviorHandler: PermissionBehaviorHandler,
        noteDataManager: NoteDataManager,
        defaultValueHandler: DefaultValueHandler,
        requiredIfValidationHandler: RequiredIfValidationHandler,
        formulaBehaviorHandler: FormulaBehaviorHandler
    ) {
        this._ea = ea;
        this._bindingEngine = bindingEngine;
        this._prefillBehaviorHandler = prefillBehavior;
        this._clearTargetBehaviorHandler = clearTargetBehavior;
        this._readOnlyIfBehaviorHandler = readOnlyIfBehaviorHandler;
        this._permissionBehaviorHandler = permissionBehaviorHandler;
        this._defaultValueHandler = defaultValueHandler;
        this._noteDataManager = noteDataManager;
        this.requiredIfValidationHandler = requiredIfValidationHandler;
        this._formulaBehaviorHandler = formulaBehaviorHandler;
    }

    public elementChanged() {
        // TODO: Handle valueDependencies-model-target behavior for two-way bound element result.
        if (!!this.element) {
            this.checkIfElementIsButton();
            this.handleReadOnlyChange();
            this.extractBehaviors();
            this.calculateElementWidth();
        }
    }

    private checkIfElementIsButton() {
        let buttonTypes = [InputTypesEnum.Button, InputTypesEnum.ButtonLink, InputTypesEnum.ButtonLink];
        let inputType = this.element?.inputType;
        this.isButton = buttonTypes.includes(inputType as InputTypesEnum);
    }

    private handleReadOnlyChange() {
        if (!!this.element) {
            let isFormReadOnly = this._noteDataManager?.getIsReadOnly();

            if (this.element.isReadOnly || isFormReadOnly) {
                this.isReadOnly = true;
            } else {
                this.isReadOnly = false;
            }
        }
    }

    private extractBehaviors() {
        if (this.element?.behaviors?.length > 0) {
            this._countListGroupBehavior = this.element.behaviors.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.CountListGroupItems;
            }) as ICountListGroupBehavior;
            if (!!this._countListGroupBehavior) {
                this.initCountBehaviorSubscriptions();
            }

            this._computeFormulaBehavior = this.element.behaviors.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.Formula;
            }) as IComputeFormulaBehavior;

            if (!!this._computeFormulaBehavior) {
                let elementNameSplit = this.element.name.split(".");
                this._computeFormulaBehavior.targets.forEach((target) => {
                    if (elementNameSplit?.length > 2) {
                        target.targetName = `${elementNameSplit[0]}.${elementNameSplit[1]}.${target.targetName}`;
                    }
                    this.formulaTargetNameList.push(target.targetName);

                    if (!!target.targetUnitName) {
                        if (elementNameSplit?.length > 2) {
                            target.targetUnitName = `${elementNameSplit[0]}.${elementNameSplit[1]}.${target.targetUnitName}`;
                        }
                        this.formulaTargetNameList.push(target.targetUnitName);
                    }
                });
                this.initFormulaBehaviorSubscriptions();
            }

            this._clearTargetBehaviorInfo = this.element.behaviors.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.ClearTargetOnValueChange;
            }) as IClearTargetBehavior;

            this._permissionBehaviorInfo = (this.element as IInput).behaviors.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.Permission;
            }) as IPermissionBehavior;
            // Has Permission is set to false if there is a permission associated and the user doesn't have it
            let hasPermission = true;

            if (!!this._permissionBehaviorInfo && !this._permissionBehaviorInfo.isShowHide) {
                hasPermission = this._permissionBehaviorHandler.handle(this._permissionBehaviorInfo);
                if (!hasPermission) {
                    this.element.isReadOnly = true;
                }
            }

            if (hasPermission) {
                // Read only behavior works if the user has permission to edit field, else its always read only
                this._readOnlyIfBehaviorInfo = this.element.behaviors.find((behavior) => {
                    return behavior.behaviorType === BehaviorTypesEnum.ReadOnlyIf;
                }) as IReadOnlyIfBehavior;
                if (!!this._readOnlyIfBehaviorInfo) {
                    this.initReadOnlyIfSubscription();
                }
            }

            this._getMultiTarget = this.element.behaviors.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.GetMultiTarget;
            }) as IAutofillBehavior;
            if (!!this._getMultiTarget) {
                this.secondaryDataProp = this._getMultiTarget.secondaryData;
            }

            this._getBehaviorInfo = this.element.behaviors.find(
                (ele) => ele.behaviorType == BehaviorTypesEnum.Get
            ) as IGetBehavior;
            if (!!this._getBehaviorInfo) {
                this.secondaryDataProp = this._getBehaviorInfo.secondaryData;
            }
        }
    }

    private initCountBehaviorSubscriptions() {
        // Adding subscriptions to have real time count of list group items when adding or deleting.
        this._eaSubscriptions.push(
            this._ea.subscribe(FormButtonEvent.ListAddItem, (buttonBehavior: IAddItemBehavior) => {
                if (
                    this._countListGroupBehavior.targetName.toLocaleLowerCase() ===
                    buttonBehavior.listName.toLocaleLowerCase()
                ) {
                    this.result = `${Number(this.result) + 1}`;
                }
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(FormButtonEvent.ListDeleteItem, (buttonBehavior: IDeleteItemBehavior) => {
                if (
                    this._countListGroupBehavior.targetName.toLocaleLowerCase() ===
                    buttonBehavior.listName.toLocaleLowerCase()
                ) {
                    this.result = `${Number(this.result) - 1}`;
                }
            })
        );
    }

    public initFormulaBehaviorSubscriptions() {
        this._eaSubscriptions.push(
            this._ea.subscribe("resultChanged", (targetName: string) => {
                if (this.formulaTargetNameList.includes(targetName)) {
                    this.result = this._formulaBehaviorHandler.handle(
                        this._computeFormulaBehavior,
                        this._noteDataManager
                    );
                }
            })
        );
    }

    private async initReadOnlyIfSubscription() {
        if (!this._readOnlyIfBehaviorInfo || this.isReadOnly) {
            return;
        }
        this.isReadOnly = await this._readOnlyIfBehaviorHandler.handle(
            this._readOnlyIfBehaviorInfo,
            this._noteDataManager
        );
        if (!!this._readOnlyIfBehaviorInfo.component) {
            // Dynamic Read only changes is only available when the target is a field in the form
            return;
        }
        this._eaSubscriptions.push(
            this._ea.subscribe(FormBuilderEvent.ModifiedDataUpdated, async (prop: string) => {
                if (prop === this._readOnlyIfBehaviorInfo?.targetName) {
                    this.isReadOnly = await this._readOnlyIfBehaviorHandler.handle(
                        this._readOnlyIfBehaviorInfo,
                        this._noteDataManager
                    );
                }
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(
                FormButtonEvent.ToggleDisplay,
                async (dependencyInfo: { elementName: string; value: FormModelType }) => {
                    if (dependencyInfo.elementName === this._readOnlyIfBehaviorInfo.targetName) {
                        this.isReadOnly = await this._readOnlyIfBehaviorHandler.handle(
                            this._readOnlyIfBehaviorInfo,
                            this._noteDataManager,
                            dependencyInfo
                        );
                    }
                }
            )
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(FormBuilderEvent.ModifiedDataPropDeleted, async (prop: string) => {
                if (prop === this._readOnlyIfBehaviorInfo?.targetName) {
                    this.isReadOnly = await this._readOnlyIfBehaviorHandler.handle(
                        this._readOnlyIfBehaviorInfo,
                        this._noteDataManager
                    );
                }
            })
        );
    }

    private calculateElementWidth() {
        let columns = this.element?.columns;
        this.columnsClasses = getColumnClasses(columns);
    }

    public async attached() {
        this.patientId = this._noteDataManager.getPatientId();
        this.visitDate = this._noteDataManager.getValueFromModel("visitDate") as string;
        this.handleReadOnlyChange();
        this._isPropFromListInput = this._noteDataManager.isPropFromListInput(this.element.name);
        // console.log(this.element.name, this._isPropFromListInput);
        // Here we should also check if modified data has value for the same field
        // in that case, should use modifiedData's value not noteModel's value
        this.tooltip = await this._noteDataManager.getTooltip(this.element.name);
        await this.initializeResult();
        this.restoreFromCache();
        this.initSubscriptions();
        this.initRequiredIndicatorSubscriptions();
        if (!!this.element.code) {
            this.getScrubberResults();
        }
    }

    private async initializeResult() {
        let finalValue = this._noteDataManager.getValueFromModel(this.element.name);
        let finalSecondaryData = null;
        let hasValueInModifiedData = this._noteDataManager.hasElementModified(this.element.name);
        let hasSecondaryDataInModifiedData = null;
        let arrayInputTypes = [
            InputTypesEnum.MultiSelect,
            InputTypesEnum.MultiSelectDropdown,
            InputTypesEnum.MultiSelectGetAllDropdown
        ];
        // console.log("Form Input: Init result, modifiedData: ", this._noteDataManager.getModifiedData());
        if (this.secondaryDataProp) {
            finalSecondaryData = this._noteDataManager.getValueFromModel(this.secondaryDataProp);
            hasSecondaryDataInModifiedData = this._noteDataManager.hasElementModified(this.secondaryDataProp);
        }
        if (hasValueInModifiedData) {
            finalValue = this._noteDataManager.getValueFromModifiedData(this.element.name);
            // console.log(`Form input, modified data had value ${valueInModifiedData}. Updating final value with it.`, this.element.name, finalValue);
        }
        if (!!hasSecondaryDataInModifiedData) {
            finalSecondaryData = this._noteDataManager.getValueFromModifiedData(this.secondaryDataProp);
        }
        if (!!this.element.behaviors && this.element.behaviors.length > 0) {
            let prefillBehavior = this.element.behaviors.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.Prefill;
            }) as IPrefillBehavior;

            if (prefillBehavior?.resource === PrefillBehaviorResourceEnum.ListIndex) {
                // to make sure we don't pick value from cache
                finalValue = null;
                this._noteDataManager.updatePropInModifiedData(this.element.name, null, true);
                this._noteDataManager.deletePropFromCache(this.element.name);
            }
            if (!finalValue && !!prefillBehavior) {
                finalValue = await this._prefillBehaviorHandler.handle(prefillBehavior, this.inputElementOptions.name);
            }
        }
        if (!finalValue && !!this.element.defaultValue) {
            finalValue = await this._defaultValueHandler.handle(this.element.defaultValue);
        }
        if (arrayInputTypes.includes(this.element.inputType as InputTypesEnum)) {
            if (!!finalValue) {
                this.resultArray = finalValue as string[];
            }
        } else {
            // console.log("Form input, result set", this.element.name, finalValue);
            this.result = finalValue as string;
            if (!!this._countListGroupBehavior) {
                this.result = `${this._noteDataManager.getListCount(this._countListGroupBehavior.targetName)}`;
            }
            if (!this.result) {
                return;
            }
            this.secondaryData = finalSecondaryData as string;
            if (this.secondaryDataProp) {
                console.log(this.secondaryDataProp, "initializeResult: ", this.secondaryData);
            }
        }
    }

    private restoreFromCache() {
        let inputName = this.element.name;
        let value = this._noteDataManager.getValueFromCache(inputName);

        if (!!value) {
            // List input values are never cached
            this._noteDataManager.updatePropInModifiedData(inputName, value, false);

            if (Array.isArray(value)) {
                this.resultArray = value as string[];
            } else {
                this.result = value as string;
            }

            this._noteDataManager.deletePropFromCache(inputName);
        }
    }

    private initSubscriptions() {
        this._observerSubscriptions.push(
            this._bindingEngine.collectionObserver(this.resultArray).subscribe(() => {
                this.resultArrayChanged(this.resultArray);
            })
        );
        this._observerSubscriptions.push(
            this._bindingEngine.collectionObserver(this.secondaryDataArray).subscribe(() => {
                this.secondaryDataArrayChanged(this.secondaryDataArray);
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(ReadOnlyChangePublishKey, () => {
                this.handleReadOnlyChange();
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(FormBuilderEvent.AutofillData, (autofillData: IAutofillData) => {
                if (autofillData.component.toLowerCase() === this.element.name.toLowerCase()) {
                    this.result = autofillData.data;
                    this.secondaryData = autofillData.data;
                }
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(FormBuilderEvent.AppendData, (autofillData: IAutofillData) => {
                // check if input is part of a list group
                let elementNameSplit = autofillData?.elementName.split(".");
                let component = autofillData?.component;
                if (elementNameSplit?.length > 2) {
                    component = `${elementNameSplit[0]}.${elementNameSplit[1]}.${autofillData?.component}`;
                }
                if (component.toLowerCase() === this.element.name.toLowerCase()) {
                    if (!this.result) {
                        this.result = "";
                    }
                    this.result = this.result + (!!this.result ? "\n\n" : "") + autofillData.data;
                }
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(RefreshViewPublishKey, (elementName: string) => {
                if (elementName === this.element?.name) {
                    let newVal = this._noteDataManager.getValueFromModifiedData(elementName);
                    this.result = Array.isArray(newVal) ? "" : (newVal as string);
                    this.resultArray = Array.isArray(newVal) ? (newVal as string[]) : [];
                }
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(FormButtonEvent.LoadData, (prop: { target: string; data: string }) => {
                if (prop.target.toLocaleLowerCase() === this.element?.name.toLocaleLowerCase()) {
                    this.result = !this.result ? prop.data : `${this.result} \n${prop.data}`;
                }
            })
        );
        if (this.element.code) {
            this._eaSubscriptions.push(this._ea.subscribe(ScrubberResultPublishKey, () => this.getScrubberResults()));
            this._eaSubscriptions.push(
                this._ea.subscribe("Highlight Form Input", (question: { code: string; type: string }) => {
                    if (question.code === this.element.code) {
                        this.hasHighlight = question.type;
                    }
                })
            );
            this._eaSubscriptions.push(
                this._ea.subscribe("Reset :: Highlight Form Input", () => {
                    this.hasHighlight = "";
                })
            );
        }
    }

    private initRequiredIndicatorSubscriptions() {
        let validations = this._noteDataManager.getValidationFromProp(this.element.name);
        validations?.forEach((rule: any) => {
            if (rule.validationType === FormValidationEnum.Conditional) {
                let conditionalValidation = rule as IConditionalValidation;
                let conditionMethod: () => boolean = null;
                if (conditionalValidation.conditionType === FormConditionValidationEnum.IsNotEmpty) {
                    conditionMethod = () => !!this._noteDataManager.getValue(conditionalValidation.targetName);
                } else if (conditionalValidation.conditionType === FormConditionValidationEnum.IsEmpty) {
                    conditionMethod = () => !this._noteDataManager.getValue(conditionalValidation.targetName);
                }
                if (!!conditionMethod) {
                    this.checkIfRequired(conditionalValidation.conditionValidation, conditionMethod);
                    this._eaSubscriptions.push(
                        this._ea.subscribe(FormBuilderEvent.PropDataUpdated, async (prop: string) => {
                            if (prop === conditionalValidation.targetName) {
                                this.checkIfRequired(conditionalValidation.conditionValidation, conditionMethod);
                            }
                        })
                    );
                }
            } else {
                this.checkIfRequired(rule);
                if (!!rule.targetName) {
                    this._eaSubscriptions.push(
                        this._ea.subscribe(FormBuilderEvent.PropDataUpdated, async (prop: string) => {
                            if (prop === rule.targetName) {
                                this.checkIfRequired(rule);
                            }
                        })
                    );
                }
            }
        });
    }

    public checkIfRequired(rule: any, condition: () => boolean = () => true) {
        if (
            rule.validationType === FormValidationEnum.Required ||
            rule.validationType === FormValidationEnum.RequiredArray ||
            rule.validationType === FormValidationEnum.RequiredAll
        ) {
            this.isRequired = condition();
        } else if (rule.validationType === FormValidationEnum.RequiredIf) {
            this.isRequiredIf = this.requiredIfValidationHandler.handle(rule, this._noteDataManager) && condition();
        }
    }

    private getScrubberResults() {
        let scrubberResults = this._noteDataManager.getScrubberResults();
        let scrubberKeys = Object.keys(scrubberResults);

        if (scrubberKeys?.length > 0) {
            let hasResults = scrubberKeys.some((key) => scrubberResults[key].length > 0);

            if (hasResults) {
                this.hasError = this.hasProblem(scrubberResults.errors);
                this.hasWarning = this.hasProblem(scrubberResults.warnings);
                this.hasInconsistency = this.hasProblem(scrubberResults.inconsistencies);
            }
        }
    }

    private hasProblem(result: IScrubberResult[]) {
        return result.some((item) => item.questions.indexOf(this.element.code) > -1);
    }

    public resultChanged(newValue: string) {
        this.updatePropWithNewResult(newValue);
        this._ea.publish("resultChanged", this.element.name);
    }

    public resultArrayChanged(newValue: string[]) {
        this.updatePropWithNewResult(newValue);
    }

    private updatePropWithNewResult(result: string | string[]) {
        if (!!this.element) {
            let inputName = this.element.name;
            // When a field is updated regardless of it belonging to a list input, we should update the field
            // Last parameter (isPropFromListInput) in updatePropInModifiedData is set to false
            // because list-group manages deletion of a list-item's prop
            let isModifiedDataUpdated = this._noteDataManager.updatePropInModifiedData(inputName, result, false);

            if (isModifiedDataUpdated) {
                this._clearTargetBehaviorHandler.handle(this._clearTargetBehaviorInfo, this._noteDataManager);
            }
        }
    }

    public secondaryDataChanged(newValue: string) {
        this.updateSecondaryPropWithNewResult(newValue);
    }

    public secondaryDataArrayChanged(newValue: string[]) {
        this.updateSecondaryPropWithNewResult(newValue);
    }

    public dataObjectChanged(newValue: string) {
        if (!this._getMultiTarget) {
            return;
        }
        let propSplit = this.inputElementOptions.name.split(".");
        let prependName: string = "";
        // check if input is part of a list group
        if (propSplit.length > 2) {
            prependName = propSplit[0] + "." + propSplit[1] + ".";
        }
        if (!!newValue) {
            try {
                let dataObject = JSON.parse(newValue);
                for (let key of Object.keys(this._getMultiTarget.mapping)) {
                    if (!!this._getMultiTarget.mapping[key]) {
                        let value = null;
                        for (let innerKey of key.split(".")) {
                            value = !!value
                                ? value[innerKey[0].toLowerCase() + innerKey.slice(1)]
                                : dataObject[innerKey];
                        }
                        this._ea.publish(FormBuilderEvent.AutofillData, {
                            data: value,
                            component: prependName + this._getMultiTarget.mapping[key]
                        } as IAutofillData);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        } else if (this._getMultiTarget.shouldClearOnEmpty) {
            for (let key of Object.keys(this._getMultiTarget.mapping)) {
                if (!!this._getMultiTarget.mapping[key]) {
                    this._ea.publish(FormBuilderEvent.AutofillData, {
                        data: "",
                        component: prependName + this._getMultiTarget.mapping[key]
                    } as IAutofillData);
                }
            }
        }
    }

    private updateSecondaryPropWithNewResult(result: string | string[]) {
        if (!!this.element) {
            // When a field is updated regardless of it belonging to a list input, we should update the field
            if (!!this.secondaryDataProp) {
                this._noteDataManager.updatePropInModifiedData(this.secondaryDataProp, result, false);
            }
        }
    }

    public detached() {
        this._noteDataManager.cacheProp(this.element.name, this._isPropFromListInput);
        this._noteDataManager.deletePropFromModifiedData(this.element.name, this._isPropFromListInput);

        this._eaSubscriptions?.forEach((sub) => sub?.dispose());
        this._observerSubscriptions?.forEach((sub) => sub?.dispose());
    }
}
