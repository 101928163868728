import { DialogController } from "aurelia-dialog";
import { inject } from "aurelia-framework";

@inject(DialogController)
export class Prompt {
    public controller: DialogController;
    public options: IPromptOptions = {
        title: "",
        message: "Are you sure?",
        additionalMessage: "",
        okText: "Ok",
        cancelText: "Cancel"
    };

    public constructor(controller: DialogController) {
        this.controller = controller;
    }

    public activate(options: IPromptOptions) {
        Object.assign(this.options, options);
    }
}

export interface IPromptOptions {
    title?: string;
    message: string;
    additionalMessage?: string;
    okText?: string;
    cancelText?: string;
}
