import { autoinject, bindable, bindingMode, containerless, customElement, observable } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { IAgencyTaskLeanResult } from "../../../interfaces/i-get-agency-task";
import { IGetPatientsLeanResult, IGetPatientsSlimInfoResult } from "../../../interfaces/i-get-patient";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { NewTask } from "../../../models/new-task";
import { AccountsService } from "../../../services/accounts-service";
import { AgencyTasksService } from "../../../services/agency-task-service";
import { IGetAvailableUser } from "../../../services/i-agency-task-service";
import { PatientsService } from "../../../services/patient-service";

@autoinject
@containerless
@customElement("add-task-entity")
export class AddTaskEntity {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public patient: IGetPatientsSlimInfoResult;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<AddTaskEntity>("newTaskChanged") })
    public newTask: NewTask = new NewTask();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public employeeForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public currentLineOfService: number = 0;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isOutsideFrequency: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    @observable({ changeHandler: nameof<AddTaskEntity>("selectedCustomTaskChanged") })
    public selectedCustomTask: IAgencyTaskLeanResult = null;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public selectedPatientChanged: () => void;
    private readonly _patientsService: PatientsService;
    private readonly _agencyTasksService: AgencyTasksService;
    private readonly _accountsService: AccountsService;
    @observable({ changeHandler: nameof<AddTaskEntity>("addTaskEmployeeChanged") })
    public addTaskEmployee: ITypeaheadOptions;
    @observable({ changeHandler: nameof<AddTaskEntity>("addTaskPatientChanged") })
    public addTaskPatient: ITypeaheadOptions;
    public agencyTasksList: IAgencyTaskLeanResult[] = [];
    public isDateSelected: boolean = false;
    public isTaskSelected: boolean = false;
    public isPatientSelected: boolean = false;
    public taskValidation: IValidateCustomElement = {
        required: true,
        displayName: "Task",
        matches: true
    };
    public patientValidation: IValidateCustomElement = {
        required: true,
        displayName: "Patient",
        matches: true
    };
    public lineOfService: number = null;

    public constructor(
        accountsService: AccountsService,
        patientsService: PatientsService,
        agencyTasksService: AgencyTasksService
    ) {
        this._accountsService = accountsService;
        this._agencyTasksService = agencyTasksService;
        this._patientsService = patientsService;
    }

    public async attached() {
        try {
            this.lineOfService = await this._accountsService.getLineOfService();
            if (!this.employeeForm) {
                this.isPatientSelected = true;
            }
            this.taskDateChanged();
        } catch (e) {
            console.error(e);
        }
    }

    private async fetchTasks() {
        let patientId = this.newTask?.patientId ?? this.patient?.id;
        if (!patientId) {
            return;
        }
        this.agencyTasksList = await this._patientsService?.getAvailableTasks(patientId, {
            startDates: this.newTask.startDates,
            userId: this.newTask.userId
        });
        this.updateAgencyTask();
    }

    public async taskDateChanged() {
        this.newTask.taskId = "";
        this.selectedCustomTask = null;
        this.addTaskEmployee = null;
        this.addTaskPatient = null;
        if (this.employeeForm) {
            this.newTask.patientId = "";
        } else {
            this.newTask.userId = "";
            await this.fetchTasks();
        }
        this.isDateSelected = this.newTask.startDates?.length > 0;
    }

    public async getUsersList(filter: string) {
        let usersList = await this._agencyTasksService.getAvailableUsers(this.newTask.taskId, {
            term: filter
        });
        return this.getDisplayUsers(usersList);
    }

    public async getPatientsList(filter: string) {
        let patientsList = await this._patientsService.getPatientsLean({
            lineOfService: this.lineOfService,
            term: filter,
            page: 1,
            pageLength: 10
        });
        return this.getDisplayPatients(patientsList);
    }

    public getDisplayUsers(data: IGetAvailableUser[]): ITypeaheadOptions[] {
        if (!data) {
            return [];
        }
        return data.map((item) => ({
            name: `${item.lastName ? item.lastName + ", " : ""}${item.firstName ? item.firstName : ""}`,
            value: item.id
        }));
    }

    public getDisplayPatients(data: IGetPatientsLeanResult): ITypeaheadOptions[] {
        if (!data) {
            return [];
        }
        return data.items.map((item) => ({
            name: `${item.lastName ? item.lastName + ", " : ""}${item.firstName ? item.firstName : ""}`,
            value: item.id
        }));
    }

    public async selectedCustomTaskChanged(selectedCustomTask: IAgencyTaskLeanResult) {
        if (!!selectedCustomTask) {
            this.newTask.taskId = selectedCustomTask.id;
            this.isOutsideFrequency = false;
        }
        this.isTaskSelected = !!selectedCustomTask;
    }

    public addTaskEmployeeChanged(newValue: ITypeaheadOptions) {
        if (!newValue) {
            return;
        }
        this.newTask.userId = newValue.value;
    }

    public async addTaskPatientChanged(newValue: ITypeaheadOptions) {
        if (!!newValue) {
            this.newTask.patientId = newValue.value;
            this.patient = await this._patientsService.getPatientSlim(newValue.value);
        }
        if (this.employeeForm) {
            this.isPatientSelected = !!newValue;
            this.selectedCustomTask = null;
            await this.fetchTasks();
        }
        this.selectedPatientChanged();
    }

    public newTaskChanged(newTask: NewTask, oldTask: NewTask) {
        if (newTask.taskId === oldTask.taskId) {
            return;
        }
        this.selectedCustomTask = null;
        this.updateAgencyTask();
        this.addTaskEmployee = null;
        this.addTaskPatient = null;
    }

    public updateAgencyTask() {
        if (!this.newTask.taskId && !this.newTask.metaId) {
            return;
        }
        let selectedCustomTask = this.agencyTasksList.find((task) => {
            return task.id === this.newTask.taskId || task.metaId === this.newTask.metaId;
        });
        if (!!selectedCustomTask) {
            this.selectedCustomTask = Object.assign({}, selectedCustomTask);
        }
    }
}
