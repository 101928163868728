import "./popover.scss";

import { bindable, customElement, inject } from "aurelia-framework";
import Popper from "popper.js";

@customElement("popover")
@inject(Element)
export class Popover {

    private _element: HTMLElement;

    @bindable
    public title: string;

    @bindable
    public content: string;

    @bindable
    public parentId: string;

    @bindable
    public placement: Popper.Placement = "auto";

    public showPopover: boolean = false;
    public mouseOutTimeOut: NodeJS.Timer;

    public constructor(element: Element) {
        this._element = element as HTMLElement;
    }

    public async attached() {
        // if no parent id was provided, then one must be elected
        /* if (!this.parentId) {
            let refObj = this._element.parentElement;

            if (refObj.id == "" || !refObj.id) {
                refObj.id = UUID.v4();
            }

            this.parentId = refObj.id;
        } */

        // let parent = document.getElementById(this.parentId);
        let parent = this._element.parentElement;

        if (!parent) {
            // throw Error(`Could not find parent element with id '${this.parentId}'`);
            throw Error("Could not find parent element");
        }

        let options: Popper.PopperOptions = {
            placement: this.placement
        };

        let popper = new Popper(parent, this._element, options);

        parent.onmouseover = () => this.mouseOver(popper);
        parent.onmouseout = () => this.mouseOut(popper);
    }

    public mouseOver(popper: Popper) {
        if (this.mouseOutTimeOut) {
            clearTimeout(this.mouseOutTimeOut);
        }
        this.showPopover = true;
        popper.scheduleUpdate();
    }

    public mouseOut(popper: Popper) {
        this.mouseOutTimeOut = setTimeout(() => {
            this.showPopover = false;
        }, 150);
    }
}
