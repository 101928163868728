import { Parser } from "expr-eval";
import { IComputeFormulaBehavior, IVal } from "../../../interfaces/form-builder/i-behavior";
import { NoteDataManager } from "./../note-data-manager";

// Formula behavior is to calculate value for a field based on a formula/expression
// For example: BMI field should be auto-populated based on these fields: height, heightScale, weight and weightScale
// These fields are entered by user and BE sends an expression based on which we would need to calculate the value for BMI

export class FormulaBehaviorHandler {
    public handle(behavior: IComputeFormulaBehavior, noteDataManager: NoteDataManager) {
        if (!behavior) {
            return "";
        }

        let variableWithVals: IVal = {};
        let allHasVal = true;

        behavior.targets?.forEach((target) => {
            // target values could either be in modified data or model
            // so get from modified data to get latest value, if value not found then get from model

            let targetVal = noteDataManager.getValueFromModifiedData(target.targetName);

            if (targetVal === undefined) {
                targetVal = noteDataManager.getValueFromModel(target.targetName);
            }

            // if any of the targets do not have a value, then return as we would not need to calculate

            if (!targetVal) {
                allHasVal = false;
                return;
            }

            if (!!behavior.unitConversions && Object.keys(behavior.unitConversions).length > 0) {
                let unitScale = noteDataManager.getValueFromModifiedData(target.targetUnitName);
                if (unitScale === undefined) {
                    unitScale = noteDataManager.getValueFromModel(target.targetUnitName);
                }

                if (!unitScale) {
                    allHasVal = false;
                    return;
                }

                // Removing prefix Name if input Scale is part of List group
                let elementUnitNameSplit = target.targetUnitName.split(".");
                let targetUnitName = elementUnitNameSplit?.length > 2 ? elementUnitNameSplit[2] : target.targetUnitName;
                if (behavior.unitConversions[targetUnitName]) {
                    let unitScaleObj = behavior.unitConversions[targetUnitName].find(
                        (item: { value: string; formula: string }) => item.value === unitScale
                    );

                    // Parser converts the string expression and calculates
                    if (!!unitScaleObj) {
                        let unitScaleParser = Parser.parse(unitScaleObj.formula);
                        targetVal = unitScaleParser.evaluate({ x: targetVal as string });
                    }
                }
            }

            variableWithVals[target.variableName] = targetVal as string;
        });

        if (!allHasVal) {
            return "";
        }

        let formulaParser = Parser.parse(behavior.equation);
        let res = formulaParser.evaluate(variableWithVals);
        let decimalPlaces = !Number.isNaN(behavior.decimalPlaces) ? behavior.decimalPlaces : 2;

        return Number(res).toFixed(decimalPlaces).toString();
    }
}
