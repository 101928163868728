import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const monthEndArReportTooltip: IReportTooltip = {
    description:
        "Displays open accounts receivable with the length of time each claim’s net receivable balance has been outstanding. The net receivable balance includes the original claim billed amount, net adjustments and net cash posted through the accounts receivable date selected by the user.",
    parameters:
        "Branch, Payer, Thru Closing Date. The Thru Closing Date is the accounts receivable date through which open balances are calculated in the report.",
    reportIncludes:
        "Payer Type, Payer, Branch, MRN, Patient Name, Patient Status, Subscriber ID, Date of Birth, Claim Start Date, Claim End Date, Bill Type, Billed Charges Amount, Claim Status, Billed Date, Expected Reimbursement, Net Adjustment, Adjusted Revenue, Cash Received as of (Date), Net Receivable, 1-30, 31-60, 61-90, 91-120, 121-150, 151-180, 181-210, 211-240, 241-270, 271-300, 301-330, 331-360, >361, Days Since Billed Date, Collection Notes"
};

export const eftTooltip: IReportTooltip = {
    description: "Displays a list of Remittance Advice with total payment amounts based on the date range selected.",
    parameters: "Branch, Date Range. The Date Range may be set to view EFTs for a specific time period.",
    reportIncludes: "EFT Number, Effective Date, Payer, Total Payment Amount, Remaining Balance, Claim Count"
};

export const reasonsPreventingTooltip: IReportTooltip = {
    description:
        "Displays a list of tasks or claims that may prevent billing. Tasks shown in this report are Physician Order, Hospice Physician Certification of Terminal Illness, or Hospice Physician Face-to-Face Visit. All tasks and claims display in chronological order from oldest to newest. This report is vital to monitor clinical and financial operations and ensure timely claim submissions.",
    parameters: "Orders Preventing Billing, Branch, Team",
    reportIncludes: "Patient Name, MRN, Branch, Team, Task, Status"
};

export const billHoldsTooltip: IReportTooltip = {
    description:
        "Displays a list of tasks or claims that may delay billing. Tasks shown in this report are incomplete and have a scheduled date from the previous month or prior. Claims in this report have not yet been submitted and have a From date that is earlier than the first day of the prior month. All tasks and claims display in chronological order from oldest to newest. This report is vital to monitor clinical and financial operations and ensure timely claim submissions.",
    parameters: "Bill Hold. Select at least one task or select Sequential Billing to view claims.",
    reportIncludes:
        "For Tasks: Task, Status, Patient, Employee, Schedule Start, Schedule End. For Claims: Bill Type, Status, Patient, Claim Date Range, Prior Claim Status"
};

export const paymentAndAdjustmentActivityTooltip: IReportTooltip = {
    description:
        "Displays payment and adjustment financial activity for a selected time period.  The report will display activity based either on the payment/adjustment post date (financial date of record) or transaction date (when the item was entered into the system by a user).",
    parameters: "Branch, Payer Type, Payer,Transaction Date,Transaction Type, Posting Date",
    reportIncludes:
        "Payer Type, Payer, Branch, MRN, Last Name,First Name, Patient Status, Claim Start Date, Claim End Date, Bill Type, Claim Status, Claim Billed Date, Claim Amount ($), Balance Before START DATE SELECTED ($), Tran Code, Adjustment Reason, Transaction Date, Post Date, Check Number, Payment, Adjustment, Current Balance ($)"
};

export const monthEndRollForwardTooltip: IReportTooltip = {
    description:
        "Displays accounts receivable beginning balances, financial activity within a date range and new ending accounts receivable balances. The financial activity within a date range will include new charges from submitted claims, net adjustments posted and cash posted.",
    parameters: "Branch, Payer, Start Date and End Date",
    reportIncludes:
        "Payer Type, Payer, Branch, MRN, Patient Name, Patient Status, Benefit Period Start Date, Benefit Period End Date, Claim Start Date, Claim End Date, Bill Type, Claim Status, Billed Date, Beginning AR Balance, Charges, Net Adjustments, Cash Posted, Ending AR Balance"
};

export const claimsReadyBillingTooltip: IReportTooltip = {
    description: "Generates a list of all claims in created or verified status.",
    parameters:
        "Type, Status, Patient, Date Range. Selecting different parameters will affect the results displayed in the report.",
    reportIncludes: "Type, Status, Patient, Claim Date Range, Prior Claim Status"
};

export const claimCountSummaryTooltip: IReportTooltip = {
    description: "Generates a list of all claims by status.",
    parameters:
        "Branch, Type of Bill, Payer, Payer Type, Status, Claim Date Range, Billed Date Range. Selecting different parameters will affect the results displayed in the report.",
    reportIncludes:
        "Branch, Type of Bill, Patient, Payer, Payer Type, Status, Claim Date Range, Billed Date Range, Balance"
};

export const payrollReportTooltip: IReportTooltip = {
    description:
        "Displays employee payroll information. This report is critical for processing payroll and completing internal administrative tasks. At the top of the report, Payroll Summary displays total values for the entire report. At the bottom of the report, Subtotal displays total values for the individual page.",
    parameters: "Date Range, Branch, Employee Name, Visit Status, Show/Hide Payroll Summary",
    reportIncludes:
        "Payer, Branch, Teams, Employee Name, Employee ID, Employee Payroll ID, Employee Credentials, Employment Type, Pay Type, Visit Pay Rate ($), Patient Name, MRN, Level of Care, Visit Date, Visit/Task Type, On-Call, Visit Status, Visit Time In, Visit Time Out, Visit Hours, Visit Amount ($), Travel Time In, Travel Time Out, Travel Hours, Travel Amount ($), Associated Mileage, Mileage Amount ($), Surcharge Amount ($), Additional Pay ($), Total Visit Pay ($)"
};

export const claimActivityReport: IReportTooltip = {
    description:
        "Displays submitted claims and claims with adjustment financial activity occurring in a selected transaction time period. At the top of the report, Claim Activity Summary displays total values for the entire report. At the bottom of the report, Subtotal displays total values for the individual payer type selected. By default, this report will show data for the current month to date.",
    parameters: "Branch, Payer Type, Date Range, Summary/Detailed, Include Claim Amounts",
    reportIncludes:
        "Branch, Claim Type, Claim Status, Claim Billed Date, Payer Type, Payer, MRN, Patient, Patient Status, Claim Start Date, Claim End Date, Units, Claim Amount($), Charges($), Net Adjustments($), Total($)"
};

export const eligibilityIssuesTooltip: IReportTooltip = {
    description: "Generates a list of any eligibility issues for all active patients.",
    parameters:
        "Branch, Patient, Payer. Selecting different parameters will affect the results displayed in the report.",
    reportIncludes:
        "Branch, Patient, Payer, Subscriber ID, MSP Periods (Medicare as a Secondary Payer), Overlapping Home Health NPI, Overlapping Home Health Episode, Other Hospice NPI, Other Hospice Benefit Period"
};

export const earnedRevenueReportTooltip: IReportTooltip = {
    description:
        "Displays earned revenue and fee schedule amounts for completed service days in a specified date range. This report enables agencies to monitor financial operations and predict anticipated revenue based on historical earnings. A worksheet added to the Earned revenue (Completed Service Day Method) that shows the current information in the report in a more summarized and compact form. ",
    parameters:
        "Branch, Payer, Service Day Date Range, Billed & Cash Received Through Date, Include Adjustments, Repeat Row Labels",
    reportIncludes:
        "Payer, Payer Type, Branch, MRN, Patient, Patient Status, Claim Start Date, Claim End Date, Bill Type, Authorization Number, Service Date, Level of Care, Claim Created Date, Claim Submitted Date, Claim Paid Date, Revenue Code Description, Service Units, Expected Daily Reimbursement, Claim Status, Claim Amount, Net Adjustments, Adjusted Revenue, Total Service Units, Cash Received, Billed Amount, Earned Revenue Based on Day of Service, Adjustments for Non-Reimbursed Services, Days"
};

export const earnedRevenueReportCTMTooltip: IReportTooltip = {
    description:
        "Displays earned revenue and fee schedule amounts for completed task in a specific date range. This report enables agencies to monitor financial operations and predict anticipated revenue based on historical earnings. A worksheet added to the Earned Revenue (Completed Task Method) that shows the current information in the report in a more summarized and compact form. ",
    parameters:
        "Branch, Payer, Scheduled Visit from Date, Scheduled Visit to Date, Billed and Cash Received Through Date, Include Billed Charges, Include Adjustments, Repeat Row Labels",
    reportIncludes:
        " Payor, Payer Type, Branch, MRN, Patient, Patient Status, Claim Start Date, Claim End Date, Bill Type, Visit Scheduled Date, Visit Completed Date, Service Units, Claim Status, Claim Created Date, Claim Submitted Date, Claim Paid Date, Expected Reimbursement, Net Adjustments as of Date, Adjusted Revenue, Total Service Units, Cash Received as of Date, Billed Amount as of Date."
};

export const roomAndBoardContractTooltip: IReportTooltip = {
    description:
        "Displays room and board rates and units for a specified time period. Report allows for the calculation of room and board contracted charges at a specified facility. The report will allow a user the ability to select a room and board date range in which to pull in room and board activity for a patient.",
    parameters: "Branch, Room & Board Date Range, Payers",
    reportIncludes:
        "Facility, Branch, Payer, Payer Type, MRN, Patient Last Name, Patient First Name & Middle Initial, Patient Status, Level of Care Type, Room & Board Start Date, Room & Board End Date, Number of Room & Board Service Days, Rate, Total Amount, Date of Death, Date of Discharge, Location Change Facility From, Location Change Date/Time From, Location Change Facility To, Location Change Date/Time To"
};

export const unbilledArReportTooltip: IReportTooltip = {
    description:
        "Displays earned revenue compared to billing activity for a specified time period. Report allows for the calculation of unbilled accounts receivable by comparting what has been earned (accrual method) versus what has been billed to a payer. The report will allow a user the ability to select an unbilled AR date in which to pull all prior earned revenue as well as all prior billing activity for patients.",
    parameters: "Branch, Unbilled AR Date",
    reportIncludes:
        "Branch, Payer, Payer Type, MRN, Patient Last Name, Patient First Name & Middle Initial, Claim Start Date, Claim End Date, Bill Type, Service Date, Level of Care, Service Units, Expected Daily Reimbursement, Claim Status, Claim Created Date, Claim Submitted Date, Earned Revenue Amount as of MM/DD/YYYY, Billed Amount as of MM/DD/YYYY, Unbilled AR as of MM/DD/YYYY"
};

export const siaReportTooltip: IReportTooltip = {
    description:
        "Displays service intensity add on (SIA) rates and units for a specified time period. Report allows for the calculation of service intensity add on (SIA) revenue estimates. The report will allow a user the ability to select a service intensity add on (SIA) date range in which to pull in activity for patients.",
    parameters: "Branch, SIA Date Range, Payers",
    reportIncludes:
        "Branch, Payer, Payer Type, MRN, Patient Last Name, Patient First Name & Middle Initial, Patient Status, Date of Death, Date of Discharge, Visit Date, In-Home Start Time, In-Home Complete Time, Visit Hours, Discipline, Clinician Last Name, Clinician First Name, SIA Category, Billable Units, Rate Per Unit, Revenue Estimate"
};

export const generalLedgerReportTooltip: IReportTooltip = {
    description:
        "Generates an Accounting General Ledger for Agency. Includes a journal entry template as well as all supporting reports to allow for a more efficient monthly close process.",
    parameters: "Branch(es), Date Range",
    reportIncludes:
        "Month-End AR Roll Forward, Payment and Adjustment Activity Report, Month End AR Report, Earned Revenue (Completed Service Day Method)"
};

export const costReportTooltip: IReportTooltip = {
    description:
        "Displays patient days for Medicare, Medicaid, and “Other” payers for a specific date range. This report enables agencies to gather information needed for the Medicare Cost Report Part II Statistical Data section. It also provides patient level detail as support.",
    parameters: "Branch, Service Date From, Service Date To",
    reportIncludes:
        "Payer Type, Title, Level of Care, Branch, Last Name, First Name, Level of Care Start Date, Level of Care End Date, Days of Service."
};

export const generalLedgerMultiLocationTooltip: IReportTooltip = {
    description: "Displays GL Export Tab by Summary or Detail for a range of selected branches.",
    parameters: "Branch, Memo, Report Type, Report Format",
    reportIncludes:
        "Ref, Export File Date, Account, Account Name, Debit, Credit, Memo, Agency ID, Report Source, Data Location in Report, Activity Driving Entry."
};

export const depositReportTooltip: IReportTooltip = {
    description:
        "Displays all deposit activity within the selected date range. This report is helpful for reconciling bank deposits.",
    parameters: "Provider, Payer, Date Range",
    reportIncludes:
        "Provider, Check (EFT) Number, Created By, Payment Date, Deposit Date, Payer, Payment Amount, Remaining Balance, Posted Status"
};
