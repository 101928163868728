import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { IGetCustomNonPatient } from "../interfaces/employee-schedule/i-non-patient-activity";
import { IAgencyTaskLeanQuery, IAgencyTaskLeanResult } from "../interfaces/i-get-agency-task";
import {
    IGetAvailableUser,
    IGetAvailableUsersQuery,
    IGetCustomNonPatientActivities,
    IGetTasksByDiscipline
} from "./i-agency-task-service";

@autoinject()
export class AgencyTasksService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/customtasks";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getTasksLean(query: IAgencyTaskLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/lean`)
            .useQueryString(query)
            .fetch<IAgencyTaskLeanResult[]>();
    }

    public async getCustomNonPatientActivities(query?: IGetCustomNonPatientActivities) {
        return await this._httpClient
            .build(`${this._baseUrl}/non-patient-activities`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetCustomNonPatient[]>();
    }

    public async getAvailableUsers(taskId: string, query: IGetAvailableUsersQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/available-users`)
            .useQueryString(query)
            .fetch<IGetAvailableUser[]>();
    }

    public async getTasksByDiscipline() {
        return await this._httpClient.build(`${this._baseUrl}/by-discipline`).fetch<IGetTasksByDiscipline[]>();
    }
}
