import { autoinject } from "aurelia-framework";

import { IRequestHandler } from "../../interfaces/i-handlers";
import { AuthService } from "../../services/auth-service";

@autoinject()
export class AccountHeaderHandler implements IRequestHandler {
    private _authService: AuthService;

    public constructor(authService: AuthService) {
        this._authService = authService;
    }

    public async handle(request: Request): Promise<void> {
        let accountId = await this._authService.getAccountId();
        if (accountId) {
            request.headers.append("X-Account-Id", accountId);
        } else {
            console.info("No Active account, no account was set.");
        }
    }
}
