import { autoinject, computedFrom } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { config } from "../../common/config";
import { NotAuthenticatedException } from "../../common/exceptions/not-authenticated-exception";
import nameof from "../../common/nameof";
import { BillingPermissionEnum } from "../../enums/billing-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";
import { MushuService } from "../../services/mushu-service";
import { NalaService } from "../../services/nala-service";
import { DashboardPermissionEnum } from "../../enums/dashboard-permission-enum";

const billingKeyWords = [
    "claim",
    "response",
    "receivable",
    "status",
    "tob",
    "bill",
    "adjustment",
    "remit",
    "accept",
    "verified"
];

@autoinject
export class BillingRoutes {
    private _billingRoutes: RouteConfig[] = [
        {
            name: "root",
            route: "",
            redirect: "billing-dashboard"
        },
        {
            name: "billing-dashboard",
            route: "dashboard",
            moduleId: PLATFORM.moduleName("./billing-dashboard/billing-dashboard", "billing-dashboard"),
            nav: true,
            title: "Billing Dashboard",
            settings: {
                menuName: "Billing Dashboard",
                permission: [
                    {
                        resource: DashboardPermissionEnum.BillingDashboard,
                        action: PermissionActionEnum.View
                    }
                ]
            }
        },
        {
            name: "noe",
            route: "noe",
            moduleId: PLATFORM.moduleName("./noe/noe", "noe"),
            nav: true,
            title: "Notice of Election (NOE)",
            settings: {
                menuName: "Notice of Election (NOE)",
                isPalliativeCare: false,
                keywords: billingKeyWords
            }
        },
        {
            name: "notr",
            route: "notr",
            moduleId: PLATFORM.moduleName("./notr/notr", "notr"),
            nav: true,
            title: "Notice of Termination/Revocation (NOTR)",
            settings: {
                menuName: "Notice of Termination/Revocation (NOTR)",
                isPalliativeCare: false,
                keywords: billingKeyWords
            }
        },
        {
            name: "claims",
            route: "claims",
            moduleId: PLATFORM.moduleName("./claims/claims", "claims"),
            nav: true,
            title: "Claims",
            settings: {
                menuName: "Claims",
                keywords: [
                    "paid",
                    "suspense",
                    "reject",
                    "denied",
                    "rtp",
                    "processing",
                    "account",
                    "receivable",
                    "tob",
                    "bill",
                    "verified"
                ]
            }
        },
        {
            name: "remittance-advice",
            route: "remittance-advice",
            moduleId: PLATFORM.moduleName("./remittances-list/remittances-list", "remittances-list"),
            nav: true,
            title: "Remittance Advice",
            settings: {
                menuName: "Remittance Advice",
                permission: [
                    {
                        resource: BillingPermissionEnum.Remittance,
                        action: PermissionActionEnum.View
                    }
                ]
            }
        },
        {
            name: "collections",
            route: "collections",
            moduleId: PLATFORM.moduleName("./collections/collections", "collections"),
            nav: true,
            title: "Collections",
            settings: {
                menuName: "Collections",
                permission: [
                    {
                        resource: BillingPermissionEnum.Collections,
                        action: PermissionActionEnum.View
                    }
                ]
            }
        },
        {
            name: "history",
            route: "history/:id?",
            moduleId: PLATFORM.moduleName("./history/history", "history"),
            href: "billing/history/load",
            nav: true,
            title: "Claims History",
            settings: {
                menuName: "Claims History",
                keywords: [
                    "response",
                    "submission",
                    "submit",
                    "claim",
                    "noe",
                    "notr",
                    "tob",
                    "bill",
                    "paid",
                    "suspense",
                    "reject",
                    "denied",
                    "rtp",
                    "processing",
                    "verified",
                    "verify"
                ]
            }
        },
        {
            name: "remittance",
            route: "remittance/:id?",
            moduleId: PLATFORM.moduleName("./remittances-details/remittances-details", "remittances-details"),
            href: "billing/remittance/load",
            title: "Remittance",
            settings: {
                permission: [
                    {
                        resource: BillingPermissionEnum.Remittance,
                        action: PermissionActionEnum.View
                    }
                ]
            }
        },
        {
            name: "verify-claim",
            route: "verify-claim/:id?",
            moduleId: PLATFORM.moduleName(
                "./institutional-claim-form/institutional-claim-form",
                "institutional-claim-form"
            ),
            href: "billing/verify-claim/load",
            title: "Verify Claim",
            settings: {
                permission: [
                    {
                        resource: BillingPermissionEnum.Claims,
                        action: PermissionActionEnum.Edit
                    }
                ]
            }
        },
        {
            name: "verify-professional-claim",
            route: "verify-professional-claim/:id?",
            moduleId: PLATFORM.moduleName(
                "./professional-claim-form/professional-claim-form",
                "professional-claim-form"
            ),
            href: "billing/verify-professional-claim/load",
            title: "Verify Professional Claim",
            settings: {
                permission: [
                    {
                        resource: BillingPermissionEnum.Claims,
                        action: PermissionActionEnum.Edit
                    }
                ]
            }
        },
        {
            name: "verify-noe",
            route: "verify-noe/:id?",
            moduleId: PLATFORM.moduleName("./noe-verify-page/noe-verify-page", "noe-verify-page"),
            href: "billing/verify-noe/load",
            title: "Verify Noe",
            settings: {
                isPalliativeCare: false,
                permission: [
                    {
                        resource: BillingPermissionEnum.NOE,
                        action: PermissionActionEnum.Edit
                    }
                ]
            }
        },
        {
            name: "verify-notr",
            route: "verify-notr/:id?",
            moduleId: PLATFORM.moduleName("./notr-verify-page/notr-verify-page", "notr-verify-page"),
            href: "billing/verify-noe/load",
            title: "Verify Notr",
            settings: {
                isPalliativeCare: false,
                permission: [
                    {
                        resource: BillingPermissionEnum.NOTR,
                        action: PermissionActionEnum.Edit
                    }
                ]
            }
        },
        {
            name: "verify-invoice",
            route: "verify-invoice/:id?",
            moduleId: PLATFORM.moduleName(
                "./invoice-form/invoice-form",
                "invoice-form"
            ),
            href: "billing/verify-invoice/load",
            title: "Verify Invoice",
            settings: {
                permission: [
                    {
                        resource: BillingPermissionEnum.Claims,
                        action: PermissionActionEnum.Edit
                    }
                ]
            }
        }
    ];
    private _mushuService: MushuService;
    private _nalaService: NalaService;
    public mushuLoaded: boolean = false;
    public nalaLoaded: boolean = false;

    @computedFrom(`${nameof<BillingRoutes>("mushuLoaded")}`, `${nameof<BillingRoutes>("nalaLoaded")}`)
    public get allRoutesLoaded(): boolean {
        return this.mushuLoaded && this.nalaLoaded;
    }

    public constructor(mushuService: MushuService, nalaService: NalaService) {
        this._mushuService = mushuService;
        this._nalaService = nalaService;
    }

    public async getRoutes() {
        try {
            if (!this.allRoutesLoaded) {
                await this.addNalaRoute();
                await this.addMushuRoute();
            }

            return this._billingRoutes;
        } catch (e) {
            console.error(e);
            // Regardless of getting any error, we should load the hard coded routes.
            return this._billingRoutes;
        }
    }

    private async addNalaRoute() {
        let isNotAuthException = false;
        try {
            let nalaAccounts = await this._nalaService.getAccounts();
            if (nalaAccounts?.length > 0) {
                this._billingRoutes.push({
                    name: "axxessdde",
                    route: "axxessdde",
                    href: config.nalaApiUrl,
                    nav: true,
                    // INFO: When there is no moduleId specified then
                    // We must specify a "redirect:", "navigationStrategy:", or "viewPorts:"
                    navigationStrategy: () => undefined,
                    settings: {
                        menuName: "Axxess DDE",
                        target: "_blank"
                    }
                });
            }
        } catch (e) {
            console.error(e);
            isNotAuthException = e instanceof NotAuthenticatedException;
            // Don't want to block when an exception is encountered
            return;
        } finally {
            if (!isNotAuthException) {
                this.nalaLoaded = true;
            }
        }
    }

    private async addMushuRoute() {
        let isNotAuthException = false;

        try {
            let mushuAccounts = await this._mushuService.getAccounts();

            if (mushuAccounts?.length > 0) {
                this._billingRoutes.push({
                    name: "axxessrcm",
                    route: "axxessrcm",
                    href: config.mushuApiUrl,
                    nav: true,
                    navigationStrategy: () => undefined,
                    settings: {
                        menuName: "Axxess RCM",
                        target: "_blank"
                    }
                });
            }
        } catch (e) {
            console.error(e);
            isNotAuthException = e instanceof NotAuthenticatedException;
            // Don't want to block when an exception is encountered
            return;
        } finally {
            if (!isNotAuthException) {
                this.mushuLoaded = true;
            }
        }
    }
}
