import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { Supply } from "../../../models/supply";

@autoinject
export class SupplyForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerOptions: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public supply: Supply;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    public providerValidation: IValidateCustomElement = {
        required: true,
        message: "Associated Agency Provider is required"
    };

    public setFormulary(value: boolean) {
        this.supply.isFormulary = value;
    }
}
