import { autoinject, bindable, bindingMode, containerless, customElement } from "aurelia-framework";

import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { LookupService } from "../../../services/lookup-service";

@autoinject
@containerless
@customElement("state-typeahead")
export class StateTypeahead {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedState: ITypeaheadOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validation: IValidateCustomElement;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public prefillState: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public disabled: boolean = false;
    private readonly _lookupService: LookupService;
    public stateList: ITypeaheadOptions[] = [];

    public constructor(lookupService: LookupService) {
        this._lookupService = lookupService;
    }

    public async attached() {
        await this.getStateList();
        if (this.prefillState) {
            this.selectedState = this.stateList.find((state) => state.value === this.prefillState) as ITypeaheadOptions;
        }
    }

    public async getStateList() {
        try {
            let stateList = await this._lookupService.getAmericanStateCodes();
            this.stateList = stateList.map((state) => ({ value: state.value, name: state.value }));
        } catch (e) {
            console.error(e);
        }
    }

    public selectedStateChanged(newValue: ITypeaheadOptions) {
        if (newValue && this.prefillState !== newValue.value) {
            this.prefillState = newValue.value;
        }
    }

    public prefillStateChanged(newValue: string) {
        this.selectedState = { value: newValue, name: newValue };
    }
}
