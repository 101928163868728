import { NoteDataManager } from "../note-data-manager";
import { IRequiredIfValidation } from "../../../interfaces/form-builder/i-validation";

export class RequiredIfValidationHandler {
    public handle(validation: IRequiredIfValidation, noteDataManager: NoteDataManager) {
        if (!validation || !validation?.targetName) {
            return false;
        }
        let isRequired = false;
        let value = noteDataManager.getValue(validation.targetName);
        if (!(value === null || value === undefined)) {
            isRequired = validation.values.includes(value.toString());
        }
        return isRequired;
    }
}
