import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    ICreateDocumentType,
    IGetDocumentTypeByName,
    IGetDocumentTypeByNameQuery,
    IGetDocumentTypeLean,
    IGetDocumentTypeLeanQuery,
    IGetDocumentTypeQuery,
    IGetDocumentTypeResult
} from "../interfaces/i-document-type";
import { ICreateResponse } from "../interfaces/i-response";
import { DocumentType } from "../models/document-type";

@autoinject()
export class DocumentTypeService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/document-types";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }
    public async createDocumentType(newDocumentType: DocumentType) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("POST").useBody(newDocumentType).fetch();
    }

    public async getDocumentTypes(query: IGetDocumentTypeQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetDocumentTypeResult>();
    }

    public async editDocumentType(id: string, params: ICreateDocumentType) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}`)
            .useMethod("PUT")
            .useBody(params)
            .fetch<ICreateResponse>();
    }

    public async deleteDocumentType(documentTypeId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${documentTypeId}`).useMethod("DELETE").fetchNoContent();
    }

    public async getDocumentTypeByName(query: IGetDocumentTypeByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/searchByName`)
            .useQueryString(query)
            .fetch<IGetDocumentTypeByName[]>();
    }

    public async getDocumentTypesList(query: IGetDocumentTypeLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetDocumentTypeLean[]>();
    }
}
