import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { FluentRules, ValidationRules } from "aurelia-validation";
import { cloneDeep } from "lodash";
import { FormValidationEnum } from "../../enums/form-validation-enum";
import { IElement, IOption } from "../../interfaces/form-builder/i-element";
import { IFormSchema } from "../../interfaces/form-builder/i-form-schema";
import { IInput } from "../../interfaces/form-builder/i-input";
import { FormModelType, IFormModel, IModelSchema, IProperty } from "../../interfaces/form-builder/i-model-schema";
import { IScrubberResults } from "../../interfaces/form-builder/i-scrubber";
import { ITooltip } from "../../interfaces/form-builder/i-tooltips";
import { IConditionalValidation, IValidation } from "../../interfaces/form-builder/i-validation";
import { InputTypesEnum } from "./form-input/input-types-enum";
import { RequiredIfValidationHandler } from "./validation-handlers/required-if-validation-handler";
import { FormConditionValidationEnum } from "../../enums/form-condition-validation-enum";
import { RequiredAllValidationHandler } from "./validation-handlers/required-all-validation-handler";

export const ReadOnlyChangePublishKey = "read:only:changed";
export const RefreshViewPublishKey = "view:refresh";

export const enum FormBuilderEvent {
    ModifiedDataUpdated = "modifiedData:update",
    ModifiedDataPropDeleted = "modifiedData:prop:deleted",
    AutofillData = "AutofillData",
    AppendData = "AppendData",
    PropDataUpdated = "prop:update"
}

export interface ICountedListItem {
    name: string;
    value: number;
}

@autoinject
export class NoteDataManager {
    private _noteId: string;
    private _ea: EventAggregator;
    private _patientId: string;
    private _userId: string;
    private _isReadOnly: boolean = false;
    private _isAssessment: boolean = false;
    private _formSchema: IFormSchema;
    private _noteModel: IFormModel = {};
    private _modifiedData: IFormModel = {};
    private _cache: IFormModel = {};
    private _modelSchema: IModelSchema = {};
    private _tooltips: ITooltip[] = [];
    private _validations: Map<string, IValidation[]> = new Map();
    private _optionTypes: Map<string, string[]> = new Map();
    private _scrubberResults: IScrubberResults = {
        errors: [],
        inconsistencies: [],
        warnings: [],
        validations: []
    };
    private _listInputs: Set<string> = new Set();
    private _taskId: string;
    private _countedListGroupItems: ICountedListItem[] = [];
    private _providerIds: string[] = [];
    public readonly requiredIfValidationHandler: RequiredIfValidationHandler;
    public readonly requiredAllValidationHandler: RequiredAllValidationHandler;

    public constructor(
        ea: EventAggregator,
        requiredIfValidationHandler: RequiredIfValidationHandler,
        requiredAllValidationHandler: RequiredAllValidationHandler
    ) {
        this._ea = ea;
        this.requiredIfValidationHandler = requiredIfValidationHandler;
        this.requiredAllValidationHandler = requiredAllValidationHandler;
    }

    public setProviderIds(ids: string[]) {
        this._providerIds = ids;
    }

    public getProviderIds() {
        return this._providerIds;
    }

    public getListCount(targetName: string) {
        let countedListGroups = this._countedListGroupItems;
        let listGroup = countedListGroups.find((item) => item.name === targetName);
        return listGroup.value;
    }

    public setCountedListGroup() {
        let listGroupWithCount = [];
        // _listInputs currently does not include inner list groups
        for (let list of this._listInputs) {
            listGroupWithCount.push({
                name: list,
                value: this.getListGroupCount(list)
            });
        }
        this._countedListGroupItems = listGroupWithCount;
    }

    public getListGroupCount(name: string) {
        let noteModel = this.getNoteModel();
        let noteModelKeys = Object.keys(noteModel);
        let listNoteModelKeys = noteModelKeys.filter((key) => key.includes(`${name}.`));
        if (listNoteModelKeys.length === 0) {
            return 0;
        }
        let indexArray: number[] = [];
        listNoteModelKeys.forEach((key) => {
            let listNameSplit = key.split(".");
            // Checking to make sure value is not boolean to avoid counting toggle display values.
            if (listNameSplit[1] && !!noteModel[key] && typeof noteModel[key] !== "boolean") {
                indexArray.push(Number(listNameSplit[1]));
            }
        });
        if (indexArray.length === 0) {
            return 0;
        }
        return Math.max(...indexArray) + 1;
    }

    public setToolTips(tooltips: ITooltip[]) {
        if (tooltips?.length > 0) {
            this._tooltips = Array.from(tooltips);
        } else {
            // Need this until we move Note data manager as transient
            // 07/31/2020 - No need for transient, wrap note with <form-container>
            // Which will create a note data manager for the current DOM scope
            this._tooltips = [];
        }
    }

    public getTooltip(elementName: string) {
        let tooltip: ITooltip = this._tooltips.find((tip) => tip.name === elementName);
        if (tooltip) {
            return tooltip.tooltip;
        }
        return null;
    }

    public getNoteId() {
        return this._noteId;
    }

    public setNoteId(id: string) {
        this._noteId = id;
        console.log("Note Id Set: ", { id, noteId: this._noteId });
    }

    public getPatientId() {
        return this._patientId;
    }

    public setPatientId(id: string) {
        this._patientId = id;
    }

    public getUserId() {
        return this._userId;
    }

    public setUserId(id: string) {
        this._userId = id;
    }

    public getIsReadOnly() {
        return this._isReadOnly;
    }

    public setIsReadOnly(value: boolean) {
        this._isReadOnly = value;
        this._ea.publish(ReadOnlyChangePublishKey, value);
    }

    public getIsAssessment() {
        return this._isAssessment;
    }

    public setIsAssessment(value: boolean) {
        this._isAssessment = value;
    }

    // TODO: Need to move this no INoteMeta
    public getTaskId() {
        return this._taskId;
    }

    public setTaskId(id: string) {
        this._taskId = id;
    }

    public setFormSchema(schema: IFormSchema) {
        if (schema) {
            this._formSchema = cloneDeep(schema);
            this.getListInputNames();
            console.log("List Inputs: ", this._listInputs);
        }
    }

    public getTabsWithCodes() {
        let tabs = this._formSchema.elements;
        return tabs.map((tab) => ({
            name: tab.name,
            codes: tab.codes
        }));
    }

    public getNoteModel() {
        return Object.assign({}, this._noteModel);
    }

    public setNoteModel(model: IFormModel) {
        if (model) {
            this._noteModel = Object.assign({}, model);
            this.setCountedListGroup();
        }
    }

    public updateNoteModel(model: IFormModel) {
        if (model) {
            Object.assign(this._noteModel, model);
            this.setCountedListGroup();
        }
    }

    public getValueFromModel(prop: string) {
        return this.getValueByProp(prop, this._noteModel);
    }

    public initModifiedData(data: IFormModel) {
        this._modifiedData = Object.assign({}, data);
    }

    public getModifiedData() {
        console.log("Note Data Manager: ", this._noteId);
        return Object.assign({}, this._modifiedData);
    }

    public getValueFromModifiedData(prop: string) {
        return this.getValueByProp(prop, this._modifiedData);
    }

    public getValue(prop: string) {
        let valueFromModifiedData = this.getValueFromModifiedData(prop);
        if (valueFromModifiedData != undefined) {
            return valueFromModifiedData;
        }
        return this.getValueFromModel(prop);
    }

    public hasElementModified(elementName: string) {
        return Object.keys(this._modifiedData).some((key) => key === elementName);
    }

    public resetModifiedData(modifiedDataBeforeReset: IFormModel = {}) {
        // console.log("Reset modified data called");
        let modelKeys = Object.keys(modifiedDataBeforeReset);
        if (modelKeys.length > 0) {
            modelKeys.forEach((key) => {
                let savedValue = modifiedDataBeforeReset[key];
                let latestModifiedValue = this._modifiedData[key];
                let savedValueString = "";
                let latestModifiedValueString = "";

                // INFO: DO NOT use !! in if(). Since !! will return false for "" and false
                if (savedValue !== null && savedValue !== undefined) {
                    savedValueString = savedValue.toString();
                }

                if (latestModifiedValue !== null && latestModifiedValue !== undefined) {
                    latestModifiedValueString = latestModifiedValue.toString();
                }

                if (savedValueString === latestModifiedValueString) {
                    // console.log("Resetting, no modifications found, deleting from modified data");
                    delete this._modifiedData[key];
                }
            });
        } else {
            // console.log("Reset: No data in modified data before reset, setting to {}");
            this._modifiedData = {};
        }
    }

    public updatePropInModifiedData(
        prop: string,
        incomingValue: FormModelType | FormModelType[],
        isPropFromListInput: boolean
    ) {
        let valueInModel = this.getValueFromModel(prop);
        let canUpdateData = false;
        let finalValue;
        // console.log("UpdatePropInModifiedData has been called: ", { prop, incomingValue, valueInModel });
        // TODO: See if we can use toString() and avoid the array check
        if (!!incomingValue && Array.isArray(incomingValue)) {
            let valueInModelString = "";
            if (!!valueInModel) {
                valueInModelString = (valueInModel as FormModelType[]).join("");
            }
            let incomingValueString = incomingValue.join("");
            canUpdateData = valueInModelString !== incomingValueString;
            if (canUpdateData) {
                finalValue = Array.from(incomingValue);
            }
        } else {
            canUpdateData = valueInModel !== incomingValue;
            if (canUpdateData) {
                finalValue = incomingValue;
            }
        }
        let result: boolean;
        if (canUpdateData) {
            // console.log("Updating Prop in Modified Data: ", { prop, valueInModel, incomingValue, finalValue });
            this._modifiedData[prop] = finalValue;
            this._ea.publish(FormBuilderEvent.ModifiedDataUpdated, prop);
            result = true;
        } else {
            // We are deleting because
            // If the user modifies a field-with-value to a new value
            // and then modified it back to the old value in the model
            // console.log("Value same in modified data and model. Delete", { prop, valueInModel, incomingValue, isPropFromListInput });
            this.deletePropFromModifiedData(prop, isPropFromListInput);
            result = false;
        }
        this._ea.publish(FormBuilderEvent.PropDataUpdated, prop);
        return result;
    }

    public refreshValueInView(elementName: string) {
        // TODO: Handle listener of this event aggregator in all the form input elements
        this._ea.publish(RefreshViewPublishKey, elementName);
    }

    public deletePropFromModifiedData(prop: string, isPropFromListInput: boolean) {
        if (this.hasElementModified(prop) && !isPropFromListInput) {
            // console.log("Going to delete prop in modified data", this.hasElementModified(prop), this._modifiedData[prop], { prop, isPropFromListInput });
            delete this._modifiedData[prop];
            this._ea.publish(FormBuilderEvent.ModifiedDataPropDeleted, prop);
        }
    }

    public getValueFromCache(prop: string) {
        return this.getValueByProp(prop, this._cache);
    }

    public resetCache() {
        this._cache = {};
    }

    public cacheProp(prop: string, isPropFromListInput: boolean) {
        if (isPropFromListInput) {
            let value = this.getValueFromModifiedData(prop);
            if (!!value) {
                if (Array.isArray(this._modifiedData[prop])) {
                    value = Array.from(this._modifiedData[prop] as FormModelType[]);
                }
                this._cache[prop] = value;
            }
        }
    }

    public deletePropFromCache(prop: string) {
        if (!!this._cache[prop]) {
            delete this._cache[prop];
        }
    }

    public getModelSchema() {
        return Object.assign({}, this._modelSchema);
    }

    public setModelSchema(schema: IModelSchema) {
        if (schema) {
            this._modelSchema = Object.assign({}, schema);
            this.setValidations();
            this.setOptionTypes();
        }
    }
    public getValidations() {
        return Object.assign({}, this._validations);
    }

    private addValidations(properties: IProperty[]) {
        if (properties?.length === 0) {
            return;
        }
        properties.forEach((property) => {
            if (!property.validations || property.validations?.length === 0) {
                return;
            }
            if (property.dataType === "optionlist") {
                property.validations?.forEach((validation) => {
                    if (validation.validationType === FormValidationEnum.Required) {
                        validation.validationType = FormValidationEnum.RequiredArray;
                    }
                });
            }
            this._validations.set(property.name, property.validations);
        });
    }

    private setOptionTypes() {
        if (!this._modelSchema?.optionTypes || !Array.isArray(this._modelSchema.optionTypes)) {
            return;
        }
        this._modelSchema?.optionTypes.forEach(({ name, values }) => {
            if (values?.length === 0) {
                return;
            }
            this._optionTypes.set(name, values);
        });
    }

    private setValidations() {
        if (this._modelSchema?.properties && Array.isArray(this._modelSchema.properties)) {
            this.addValidations(this._modelSchema.properties);
        }
        if (this._modelSchema?.referenceTypes && Array.isArray(this._modelSchema.referenceTypes)) {
            this._modelSchema.referenceTypes.forEach((reference) => {
                if (reference.properties?.length > 0) {
                    this.addValidations(reference.properties);
                }
            });
        }
    }

    public updateListGroupValidations(listItem: string[], listGroupName: string, index: number) {
        listItem.forEach((item: string) => {
            if (index === 0 && this._validations.has(item)) {
                let value = this._validations.get(item);
                let key = `${listGroupName}.${index}.${item}`;
                this._validations.delete(item);
                this._validations.set(key, value);
            } else {
                let firstValidation = `${listGroupName}.${0}.${item}`;
                if (!this._validations.has(firstValidation)) {
                    return;
                }
                let newValue = this._validations.get(firstValidation);
                let newKey = `${listGroupName}.${index}.${item}`;
                this._validations.set(newKey, newValue);
            }
        });
    }

    public getValidationFromProp(prop: string) {
        return this._validations.get(prop);
    }

    public getOptionTypeFromProp(prop: string) {
        return this._optionTypes.get(prop);
    }

    public isPropRequired(prop: string) {
        let isRequired = false;
        let validation = this.getValidationFromProp(prop);
        validation?.forEach((rule: any) => {
            if (
                rule.validationType === FormValidationEnum.Required ||
                rule.validationType === FormValidationEnum.RequiredArray ||
                rule.validationType === FormValidationEnum.RequiredAll
            ) {
                isRequired = true;
            }
        });
        return isRequired;
    }

    public assignValidationRules(prop: string, noteDataManager: NoteDataManager) {
        // Always needs .call() to retain the context of this
        let validation = noteDataManager.getValidationFromProp(prop);
        let validationRules = ValidationRules.ensure((x: any) => x.result);
        validation?.forEach((rule: any) => {
            if (rule.validationType === FormValidationEnum.Conditional) {
                let conditionalValidation = rule as IConditionalValidation;
                if (conditionalValidation.conditionType === FormConditionValidationEnum.IsNotEmpty) {
                    noteDataManager.assignValidationRule.call(
                        this,
                        conditionalValidation.conditionValidation,
                        validationRules,
                        noteDataManager,
                        () => !!noteDataManager.getValue(conditionalValidation.targetName)
                    );
                } else if (conditionalValidation.conditionType === FormConditionValidationEnum.IsEmpty) {
                    noteDataManager.assignValidationRule.call(
                        this,
                        conditionalValidation.conditionValidation,
                        validationRules,
                        noteDataManager,
                        () => !noteDataManager.getValue(conditionalValidation.targetName)
                    );
                }
            } else {
                noteDataManager.assignValidationRule.call(this, rule, validationRules, noteDataManager);
            }
        });
    }

    public assignValidationRule(
        rule: any,
        validationRules: FluentRules<any, any>,
        noteDataManager: NoteDataManager,
        condition: () => boolean = () => true
    ) {
        // Always needs .call() to retain the context of this
        if (rule.validationType === FormValidationEnum.Regex) {
            validationRules.matches(new RegExp(rule.pattern)).when(condition).withMessage(rule.message).on(this);
        } else if (rule.validationType === FormValidationEnum.RequiredArray) {
            validationRules.minItems(1).when(condition).withMessage(rule.message).on(this);
        } else if (rule.validationType === FormValidationEnum.Required) {
            validationRules.required().when(condition).withMessage(rule.message).on(this);
            // } else if (rule.validationType === FormValidationEnum.MaxLength) {
            //     validationRules
            //         .maxLength(rule.maxLength)
            //         .when(condition)
            //         .withMessage(rule.message)
            //         .on(this);
        } else if (rule.validationType === FormValidationEnum.RequiredIf) {
            validationRules
                .required()
                .when(() => noteDataManager.requiredIfValidationHandler.handle(rule, noteDataManager) && condition())
                .withMessage(rule.message)
                .on(this);
        } else if (rule.validationType === FormValidationEnum.RequiredAll) {
            noteDataManager.requiredAllValidationHandler.handle.call(
                this,
                validationRules,
                rule,
                noteDataManager,
                condition
            );
        }
    }

    // TODO: Make Scrubber generic
    public getScrubberResults(): IScrubberResults {
        return cloneDeep(this._scrubberResults);
    }

    public setScrubberResults(result: IScrubberResults) {
        if (result) {
            let deepCopiedResult = cloneDeep(result);
            this._scrubberResults = Object.assign({}, deepCopiedResult);
        }
    }

    public resetScrubberResults() {
        this._scrubberResults = Object.assign(
            {},
            {
                errors: [],
                warnings: [],
                inconsistencies: [],
                validations: []
            }
        );
    }

    public isPropFromListInput(prop: string) {
        let propSplit = prop.split(".");
        let result = false;
        if (propSplit.length > 2) {
            let listInputNames = Array.from(this._listInputs);
            result = listInputNames.some((inputName) => prop.includes(inputName));
            // console.log("isProp from ListInput(): ", { prop, result });
        }
        return result;
    }

    private getValueByProp(prop: string, model: IFormModel) {
        let value = model[prop];
        if (!!value && Array.isArray(value)) {
            return Array.from(value);
        } else {
            return value;
        }
    }

    private getListInputNames(elements: IElement[] = this._formSchema.elements) {
        for (let element of elements) {
            // console.log(element.elementType, element.elements, (element as IInput).inputType);
            if (!!element.elements) {
                this.getListInputNames(element.elements);
            } else if (!!(element as IInput).options) {
                this.unwrapOptions((element as IInput).options);
            }
            if ((element as IInput).inputType === InputTypesEnum.ListGroup) {
                // console.log(element.name);
                this._listInputs.add(element.name);
            }
        }
    }

    private unwrapOptions(options: IOption[]) {
        for (let option of options) {
            const subElement = option.subElement;
            if (!!subElement) {
                // Handling options within options
                if (!!(subElement as IInput).options) {
                    this.unwrapOptions((subElement as IInput).options);
                } else if (!!subElement.elements) {
                    this.getListInputNames(subElement.elements);
                } else if ((subElement as IInput).inputType === InputTypesEnum.ListGroup) {
                    this._listInputs.add(subElement.name);
                }
            }
        }
    }

    public getModelKeyFromTargetName(targetName: string) {
        let result;
        if (!!this._noteModel[targetName]) {
            return targetName;
        }
        let targetNameSplit = targetName.split(".");
        let key = targetNameSplit[targetNameSplit.length - 1];
        if (!!this._noteModel[key]) {
            result = key;
        }
        return result;
    }
}
