import {ValidationRules} from "aurelia-validation";

import {ITypeaheadOptions} from "../../../interfaces/i-typeahead";

export class NpaPayRate {
    public activities: ITypeaheadOptions[] = [];
    public branches: ITypeaheadOptions[] = [];
    public rate: number = null;
    public rateType: number = null;
    public effectiveDate: string = "";

    public constructor(data?: NpaPayRate) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules
            .ensure((x: NpaPayRate) => x.rate).required().withMessage("Rate is required.")
            .ensure((x: NpaPayRate) => x.rateType).required().withMessage("Rate Type is required.")
            .ensure((x: NpaPayRate) => x.effectiveDate).required().withMessage("Effective Date is required.")
            .on(this);
    }
}
