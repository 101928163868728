import "./search-page.scss";

import {EventAggregator, Subscription} from "aurelia-event-aggregator";
import {autoinject, bindable, bindingMode, computedFrom, observable} from "aurelia-framework";
import {RouteConfig, Router} from "aurelia-router";
import {Key} from "ts-keycode-enum";

import nameof from "../../../common/nameof";
import {isRouteAllowed} from "../../../common/utilities/is-route-allowed";
import {KeyPressPublish} from "../../../common/utilities/key-listener";
import {PermissionManager} from "../../../common/utilities/permission-manager";
import {IKeyEventPayload} from "../../../interfaces/i-keyevent-payload";
import {ISearchRoute} from "../../../interfaces/i-search-route";
import {AppRoutes} from "../../../pages/app/app-routes";
import {AccountsService} from "./../../../services/accounts-service";

const SearchPlaceHolderText = "Search Page.. Ex: Chart";

@autoinject
export class SearchPage {
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public showMenu: boolean = false;
    private _router: Router;
    private _ea: EventAggregator;
    private _appRoutes: AppRoutes;
    private _subscription: Subscription[] = [];
    private _linkSet: Set<string> = new Set();
    private _permissionManager: PermissionManager;
    private readonly _accountsService: AccountsService;
    @observable({ changeHandler: nameof<SearchPage>("selectFirstRoute") })
    public searchKey: string = SearchPlaceHolderText;
    public searchRoutes: ISearchRoute[] = [];
    public selectedRoute: ISearchRoute;
    public dropdownRef: Element;
    public searchInputRef: HTMLElement;
    public selectedRouteRefs: Element[] = [];
    public searchInputFocus: boolean = false;
    public isPalliativeCareView: boolean = false;

    @computedFrom(nameof<SearchPage>("searchKey"), `${nameof<SearchPage>("searchRoutes")}.length`)
    public get searchResult() {
        if (
            this.searchKey.length > 0 &&
            this.searchRoutes &&
            this.searchRoutes.length > 0 &&
            !this.hasPlaceholderText
        ) {
            return this.searchRoutes.filter((route) => {
                let searchKey = this.searchKey.toLowerCase();
                if (route.title.toLowerCase().includes(searchKey)) {
                    return true;
                } else if (!!route.parent && route.parent.toLowerCase().includes(searchKey)) {
                    return true;
                } else if (route.keywords.length > 0) {
                    let hasKeywordMatch = route.keywords.some((keyword) => {
                        keyword = keyword.toLowerCase();
                        return searchKey.includes(keyword) || keyword.includes(searchKey);
                    });
                    if (hasKeywordMatch) {
                        return true;
                    }
                }
                return false;
            });
        } else if (this.searchKey.length > 0) {
            return [];
        } else {
            return this.searchRoutes || [];
        }
    }

    @computedFrom(nameof<SearchPage>("searchKey"))
    public get hasPlaceholderText() {
        return this.searchKey === SearchPlaceHolderText;
    }

    public constructor(
        router: Router,
        ea: EventAggregator,
        appRoutes: AppRoutes,
        permissionManager: PermissionManager,
        accountsService: AccountsService
    ) {
        this._router = router;
        this._ea = ea;
        this._appRoutes = appRoutes;
        this._permissionManager = permissionManager;
        this._accountsService = accountsService;
    }

    public startSearch(event: KeyboardEvent) {
        if (event.altKey && event.keyCode === Key.P) {
            this.searchInputRef.focus();
        }
    }

    public async attached() {
        this.isPalliativeCareView = await this._accountsService.getIsPalliativeAccount();
        await this.getRoutes();
        this.registerDocumentClick();
        this._subscription.push(
            this._ea.subscribe(KeyPressPublish, (payload: IKeyEventPayload) => {
                this.startSearch(payload.KeyboardEvent);
            })
        );
    }

    public async getRoutes() {
        let parentRoutes = await this._appRoutes.getRoutes();
        this.processRoutes(parentRoutes);
        // Do not delete
        console.log("Total:", this.searchRoutes.length);
    }

    private processRoutes(routes: RouteConfig[], parentRoute?: RouteConfig) {
        routes.forEach((route: RouteConfig) => {
            let searchRoute = this.generateSearchRoute(route, parentRoute);
            if (!!searchRoute) {
                this.searchRoutes.push(searchRoute);
            }
            let subRoutes = route?.settings?.subRoutes;
            if (!!subRoutes) {
                this.processRoutes(subRoutes, route);
            }
        });
    }

    private generateSearchRoute(route: RouteConfig, parentRoute?: RouteConfig): ISearchRoute {
        if (this.isSearchableRoute(route) && this.hasPermission(route)) {
            let href: string = "/";
            let currentHref = Array.isArray(route.route) ? route.route.find((item) => item.length > 0) : route.route;
            let parentName: string = "";
            let title: string = "";
            let keywords: string[] = [];
            let target: string = "";

            if (!!route.href) {
                if (route.href.includes("http://") || route.href.includes("https://")) {
                    href = route.href;
                } else {
                    href += route.href;
                }
            } else if (!!parentRoute) {
                href += `${parentRoute.route}/${currentHref}`;
            } else {
                href += currentHref;
            }

            if (parentRoute?.settings) {
                parentName = parentRoute.settings.menuName;
            }

            if (!!route.settings) {
                if (!!route.settings.menuName) {
                    title = route.settings.menuName;
                }

                if (!!route.settings.keywords && route.settings.keywords.length > 0) {
                    keywords = route.settings.keywords;
                }

                if (!!route.settings.target) {
                    target = route.settings.target;
                }
            }

            if (!this.doesRouteExist(href)) {
                return {
                    href,
                    parent: parentName,
                    title,
                    keywords,
                    target
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private doesRouteExist(link: string) {
        if (this._linkSet.has(link)) {
            return true;
        } else {
            this._linkSet.add(link);
            return false;
        }
    }

    private isSearchableRoute(route: RouteConfig) {
        return (
            route?.name === "message-center" ||
            (route?.name !== "root" &&
                isRouteAllowed(route) &&
                !route?.settings?.subRoutes &&
                this.isRouteAllowedByLineOfService(route))
        );
    }

    private isRouteAllowedByLineOfService(route: RouteConfig) {
        if (
            (this.isPalliativeCareView && route?.settings?.isPalliativeCare === false) ||
            (route?.settings?.isPalliativeCare === true && !this.isPalliativeCareView)
        ) {
            return false;
        }
        return true;
    }

    private registerDocumentClick() {
        document.addEventListener("click", (evt: MouseEvent) => {
            if (!this.showMenu) {
                return;
            }

            setTimeout(() => {
                if (!!this.dropdownRef && !this.dropdownRef.contains(evt.target as Element)) {
                    this.handleMenuSelection();
                }
            }, 150);
        });
    }

    public expandSearch() {
        this.showMenu = !this.showMenu;
        this.searchInputFocus = true;
        if (this.hasPlaceholderText) {
            this.searchKey = "";
            this.selectedRoute = this.searchResult[0];
        }
    }

    public handleInputKeydown(event: KeyboardEvent) {
        setTimeout(() => {
            if (event.keyCode === Key.UpArrow) {
                this.handleUpArrowPress();
            } else if (event.keyCode === Key.DownArrow) {
                this.handleDownArrowPress();
            } else if (event.keyCode === Key.Enter) {
                this.handleEnterPress();
            } else if (event.keyCode === Key.Escape) {
                this.handleMenuSelection();
            }
        }, 150);
        // https://kabaehr.de/blog/Aurelia-keyboard-event-binding/
        return true;
    }

    public selectFirstRoute() {
        if (this.searchResult && this.searchResult.length > 0 && !this.hasPlaceholderText) {
            this.selectedRoute = this.searchResult[0];
        }
    }

    private handleUpArrowPress() {
        let selectedIndex = 0;
        if (!!this.selectedRoute) {
            selectedIndex = this.searchResult.findIndex((item) => item.href === this.selectedRoute.href);
            if (selectedIndex <= 0) {
                selectedIndex = this.searchResult.length - 1;
            } else {
                selectedIndex--;
            }
            this.handleKeyPressNavigation(selectedIndex);
        }
    }

    private handleDownArrowPress() {
        let selectedIndex = 0;
        if (!!this.selectedRoute) {
            selectedIndex = this.searchResult.findIndex((item) => item.href === this.selectedRoute.href);
            if (selectedIndex >= this.searchResult.length - 1) {
                selectedIndex = 0;
            } else {
                selectedIndex++;
            }
        }
        this.handleKeyPressNavigation(selectedIndex);
    }

    private handleKeyPressNavigation(selectedIndex: number) {
        this.selectedRoute = this.searchResult[selectedIndex];
        let selectedRouteEl = this.selectedRouteRefs[selectedIndex];
        if (selectedRouteEl) {
            selectedRouteEl.scrollIntoView();
        }
    }

    private handleEnterPress() {
        if (!!this.selectedRoute) {
            if (!!this.selectedRoute.target && this.selectedRoute.target === "_blank") {
                window.open(this.selectedRoute.href);
            } else {
                this._router.navigate(this.selectedRoute.href);
            }
            this.handleMenuSelection();
        }
    }

    public handleMenuSelection() {
        this.showMenu = false;
        this.searchKey = SearchPlaceHolderText;
        this.searchInputRef.blur();
    }

    public detached() {
        if (this._subscription.length > 0) {
            this._subscription.forEach((sub) => sub.dispose());
        }
    }

    private hasPermission(route: RouteConfig) {
        if (route) {
            if (route.settings && route.settings.permission && route.settings.permission.length) {
                return this._permissionManager.checkRoutePermission(route);
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}
