import { containerless, bindable, customElement, bindingMode } from "aurelia-framework";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { ValidationRules } from "aurelia-validation";

@containerless
@customElement("qa-return-reason")
export class QaReturnReason {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isReturnInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public returnReason: string = "";

    public attached() {
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((x: QaReturnReason) => x.returnReason)
            .required()
            .withMessage("Return Reason is required.")
            .on(this);
    }
}
