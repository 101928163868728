import "./multiselect-time.scss";
import { autoinject, bindable, bindingMode, containerless, customElement, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

@autoinject
@containerless
@customElement("multiselect-time")
export class MultiselectTime {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public times: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public classes: string = "";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public placeholder: string = "Start Typing...";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public maxTimesPerDay: number = Number.MAX_VALUE;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasTimesLimit: boolean = false;
    public timesLength: number = 0;
    public multiselectTimeInput: Element;
    public value: string = "";

    public attached() {
        this.valueChanged = this.valueChanged.bind(this);
        this.timesLength = this.times?.length;
        this.multiselectTimeInput?.addEventListener("change", this.valueChanged);
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((x: MultiselectTime) => x.times)
            .minItems(1)
            .when(() => this.validation?.required)
            .withMessage(`${this.validation.message}`)
            .ensure((x: MultiselectTime) => x.timesLength)
            .min(1)
            .when(() => this.validation?.required)
            .withMessage(`${this.validation.message}`)
            .on(this);
    }

    public valueChanged() {
        if (!!this.value) {
            this.addTime(this.value);
        }
        this.value = "";
    }

    private addTime(tag: string) {
        if (this.hasTimesLimit && this.timesLength >= this.maxTimesPerDay) {
            return;
        }
        if (!this.times.find((item: string) => item === tag)) {
            this.times = this.times.concat([this.value]);
            this.timesLength = this.times.length;
        }
    }

    public removeTime(tag: string) {
        this.times = this.times.filter((item: string) => item !== tag);
        this.timesLength = this.times.length;
    }

    public detached() {
        this.multiselectTimeInput?.removeEventListener("change", this.valueChanged);
    }
}
