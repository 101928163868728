import { bindable, bindingMode } from "aurelia-framework";

import { IElement } from "../../../interfaces/form-builder/i-element";
import { GroupingTypesEnum } from "./grouping-types-enum";

export class FormGrouping {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public element: IElement;
    public groupingTypesEnum: typeof GroupingTypesEnum = GroupingTypesEnum;
}
