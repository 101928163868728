import {autoinject, bindable, bindingMode, computedFrom, customElement} from "aurelia-framework";

import nameof from "../../../../common/nameof";
import {IOption} from "../../../../interfaces/form-builder/i-element";
import {IFetchDataInputOptions} from "../../../../interfaces/form-builder/i-input-options";
import {IEnumResponse} from "../../../../interfaces/i-enum";
import {FormatDateTime} from "../../../value-converters/date-and-time";
import { BehaviorTypesEnum } from "../../behavior-handlers/behavior-types-enum";
import {FetchDataBehaviorHandler} from "../../behavior-handlers/fetch-data-behavior-handler";
import {NoteDataManager} from "../../note-data-manager";

@autoinject
@customElement("single-select-get-all")
export class SingleSelectGetAll {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryData: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IFetchDataInputOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasHighlight: string = "";
    private _fetchDataBehaviorHandler: FetchDataBehaviorHandler;
    private _taskId: string = "";
    private _patientId: string = "";
    public optionValues: IEnumResponse[] = [];
    public isLoading: boolean = true;
    public dateFormatter: FormatDateTime = new FormatDateTime();
    public noteDataManager: NoteDataManager;

    @computedFrom(nameof<SingleSelectGetAll>("viewOptions"), nameof<SingleSelectGetAll>("optionValues"))
    public get options(): IOption[] {
        return this.optionValues?.map((e, i) => ({
            elementType: "option",
            name: `${this.viewOptions.name}.${i}`,
            label: e.name,
            value: e.value as string,
            groupingType: "",
            elements: [],
            isReadOnly: this.viewOptions.isReadOnly
        }));
    }

    @computedFrom(nameof<SingleSelectGetAll>("hasHighlight"))
    public get highlightClass() {
        switch (this.hasHighlight) {
            case "errors":
                return "is-invalid";
            case "warnings":
                return "is-warning";
            case "inconsistencies":
                return "is-inconsistency";
        }
        return "";
    }

    @computedFrom(nameof<SingleSelectGetAll>("hasHighlight"))
    public get highlightFocus() {
        return this.hasHighlight.length > 0;
    }

    public set highlightFocus(focus: boolean) {
        // focus would try to set property
    }

    @computedFrom(nameof<SingleSelectGetAll>("viewOptions"), nameof<SingleSelectGetAll>("result"))
    public get showSubInput() {
        let result = this.result;
        return this.options.map((opt) => result === opt.value);
    }

    public constructor(fetchDataBehavior: FetchDataBehaviorHandler, noteDataManager: NoteDataManager) {
        this._fetchDataBehaviorHandler = fetchDataBehavior;
        this.noteDataManager = noteDataManager;
    }

    public async attached() {
        this._patientId = this.noteDataManager.getPatientId();
        this._taskId = this.noteDataManager.getTaskId();
        await this.getList();
        this.noteDataManager.assignValidationRules.call(this, this.viewOptions.name, this.noteDataManager);
    }

    public optionClick(value: string) {
        if (this.result == value) {
            this.result = null;
        }
        return true;
    }

    public resultChanged(newValue: string) {
        if (this.optionValues?.length > 0) {
            if (newValue) {
                let selectedEnum = this.optionValues.filter((e) => e.value == newValue);
                this.secondaryData = selectedEnum[0] ? selectedEnum[0].name : "";
            } else {
                this.secondaryData = "";
            }
        }
    }

    public async getList() {
        try {
            this.isLoading = true;
            let getBehavior = this.viewOptions.behaviors.find((behavior) => behavior.behaviorType === BehaviorTypesEnum.Get);
            if (!getBehavior) {
                console.error("Get Behavior not found for single-select-get-all-dropdown");
                return;
            }
            let optionValues = await this._fetchDataBehaviorHandler.getList(getBehavior, this._patientId, this._taskId);
            this.optionValues = optionValues as IEnumResponse[];
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }
}
