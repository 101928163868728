import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { IDialogOptions } from "../../../interfaces/i-dialog";

@inject(DialogController)
export class Success {
    public controller: DialogController;
    public options: IDialogOptions = {
        title: "Success",
        description: "",
        type: "success",
        icon: "far fa-check-circle",
        actions: []
    };

    public constructor(controller: DialogController) {
        this.controller = controller;
    }

    public activate(options: any) {
        Object.assign(this.options, options);
    }

    public handleCallBack(callBack: () => void) {
        callBack();
        this.controller.ok();
    }
}
