import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    IDiscontinueFrequencyParams,
    IGetActiveFrequenciesQuery,
    IGetDiscontinuedFrequenciesQuery,
    IGetDiscontinuedFrequenciesResult,
    IGetFrequency,
    IGetFrequencyTasksQuery,
    IGetFrequencyTasksResult,
    IGetInactiveFrequenciesQuery,
    IGetInactiveFrequenciesResult,
    IGetTasksToBeDeprecatedParams,
    IGetTasksToBeDeprecatedResult,
    IPrintFrequencyProfileQuery
} from "../interfaces/i-frequency";
import { IGetTaskFrequenciesQuery, IGetTaskFrequenciesResult } from "../interfaces/patients/i-get-task-frequencies";
import { Frequency } from "../models/frequency";
import { IGetFrequencyCount } from "./../interfaces/i-frequency";

@autoinject()
export class FrequencyService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/frequency";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createFrequency(frequency: Frequency) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("POST").useBody(frequency).fetchNoContent();
    }

    public async discontinueFrequency(frequencyId: string, discontinueParams: IDiscontinueFrequencyParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/${frequencyId}/discontinue`)
            .useMethod("PUT")
            .useBody(discontinueParams)
            .fetchNoContent();
    }

    public async deleteFrequency(frequencyId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${frequencyId}`)
            .useMethod("DELETE")
            .useBody(frequencyId)
            .fetchNoContent();
    }

    public async getActiveFrequencies(query: IGetActiveFrequenciesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/active`)
            .useQueryString(query)
            .fetch<IGetFrequency[]>();
    }

    public async getInactiveFrequencies(query: IGetInactiveFrequenciesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/inactive`)
            .useQueryString(query)
            .fetch<IGetInactiveFrequenciesResult>();
    }

    public async getOrderedDisciplines(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/ordereddisciplines`)
            .useQueryString({ patientId })
            .fetch<string[]>();
    }

    public async getDiscontinuedFrequencies(query: IGetDiscontinuedFrequenciesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/discontinued`)
            .useQueryString(query)
            .fetch<IGetDiscontinuedFrequenciesResult>();
    }

    public async getTasksToBeDeprecated(frequencyId: string, query: IGetTasksToBeDeprecatedParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/${frequencyId}/taskstobedeprecated`)
            .useQueryString(query)
            .fetch<IGetTasksToBeDeprecatedResult>();
    }

    public async getFrequencyTasks(frequencyId: string, query: IGetFrequencyTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${frequencyId}/tasks`)
            .useQueryString(query)
            .fetch<IGetFrequencyTasksResult>();
    }

    public async getTaskFrequency(query: IGetTaskFrequenciesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/frequenciesofatask`)
            .useQueryString(query)
            .fetch<IGetTaskFrequenciesResult[]>();
    }

    public async getFrequencyWatchCount(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/watchcounts`)
            .useQueryString({ locationIds })
            .fetch<IGetFrequencyCount>();
    }

    public async printFrequencyProfile(id: string, query: IPrintFrequencyProfileQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/print-frequency-profile`)
            .useQueryString(query)
            .fetchBlob();
    }
}
