import { EnumMap } from "./../../../common/utilities/enum-map";
import { EnumsService } from "./../../../services/enums-service";
import "./pay-rate-form.scss";

import { observable, autoinject, bindable, bindingMode, customElement } from "aurelia-framework";

import { IBranchResponse } from "../../../interfaces/i-branch";
import { IAgencyTaskLeanResult } from "../../../interfaces/i-get-agency-task";
import { PayRate } from "../../../models/pay-rate";
import { AgencyTasksService } from "../../../services/agency-task-service";
import { PayorService } from "../../../services/payor-service";
import { IEnumResponse } from "./../../../interfaces/i-enum";
import { AccountsService } from "../../../services/accounts-service";
import nameof from "../../../common/nameof";

@autoinject
@customElement("pay-rate-form")
export class PayRateForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<PayRateForm>("providerIdChanged")
    })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disableSave: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public warningMessage: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public payRate: PayRate = new PayRate();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public branchesList: IBranchResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isEdit: boolean = false;
    private readonly _payorService: PayorService;
    private readonly _accountsService: AccountsService;
    private readonly _agencyTasksService: AgencyTasksService;
    private readonly _enumService: EnumsService;
    private _customTasksEnum: IEnumResponse[] = [];
    public agencyTasksList: IAgencyTaskLeanResult[] = [];
    public displayRateTypes: IEnumResponse[] = [];

    public constructor(
        payorService: PayorService,
        accountsService: AccountsService,
        agencyTasksService: AgencyTasksService,
        enumsService: EnumsService
    ) {
        this._payorService = payorService;
        this._accountsService = accountsService;
        this._agencyTasksService = agencyTasksService;
        this._enumService = enumsService;
    }

    public async attached() {
        let currentLineOfService = await this._accountsService.getLineOfService();
        this._customTasksEnum = await this._enumService.getCustomTasksRequestType();
        let customTasksEnumMap = new EnumMap(this._customTasksEnum);
        let requestTypes = [
            customTasksEnumMap.getEnumValue("isPayable") as number
        ];
        this.agencyTasksList = await this._agencyTasksService.getTasksLean({
            lineOfService: currentLineOfService,
            requestType: requestTypes
        });
    }

    public tasksFetch(filter: string) {
        return this.agencyTasksList
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((task) => ({
                name: task.name,
                value: task.id
            }));
    }

    public async providerIdChanged() {
        if (this.providerId) {
            this.payRate.payers = [];
            this.payRate.branches = [];
        }
    }

    public getProviderIds() {
        if (this.payRate?.branches?.length > 0) {
            let selectedBranchIds = this.payRate.branches.map((branch) => branch.value);
            let selectedProviders = this.branchesList.filter((branch) => selectedBranchIds.includes(branch.id));
            return [...new Set(selectedProviders.map((branch) => branch.providerId))];
        }
        return [...new Set(this.branchesList.map((branch) => branch.providerId))];
    }

    public async payersFetch(filter: string) {
        let response = await this._payorService.getPayorsByName({
            name: filter,
            includeCharity: true,
            providerIds: this.providerId ? [this.providerId] : this.getProviderIds()
        });
        if (response) {
            return response.map((payor) => ({ name: payor.name, value: payor.id }));
        } else {
            console.error("Unable to Fetch");
            return [];
        }
    }

    public branchesFetch(filter: string) {
        return this.branchesList
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((branch) => ({
                name: branch.name,
                value: branch.id
            }));
    }
}
