import { inlineView, bindable, computedFrom, customElement } from "aurelia-framework";
import nameof from "../../../common/nameof";

@inlineView("<template>${text}</template>")
@customElement("number-animator")
export class NumberAnimator {

    @bindable
    public desired: number;

    @bindable
    public current: number = 0;

    @bindable
    public animationTime: number = 300;

    @bindable
    public animationStepTime: number = 10;

    @bindable
    public fixed: number;

    @computedFrom(nameof<NumberAnimator>("current"))
    public get text() {
        return this.current.toFixed(this.fixed || 0);
    }

    public async desiredChanged() {
        let desired = this.desired || 0;
        let distance = desired - this.current;
        let steps = Math.round(this.animationTime / this.animationStepTime);
        let stepSize = distance / steps;

        for (let i = 0; i < steps; i++) {
            this.current += stepSize;
            await this.timeout(this.animationStepTime);
        }

        this.current = desired;
    }

    public async timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}
