import { valueConverter } from "aurelia-binding";

import { ICoreMemberCriteria, IMeetingAttendee } from "../../interfaces/i-meeting";
import { IEnumResponse } from "../../interfaces/i-enum";

export const registeredNurseCriteria: ICoreMemberCriteria = {
    titles: ["Director of Nursing", "Alternate Director of Nursing", "Registered Nurse"],
    credentials: ["RN"]
};

export const hospicePhysicianCriteria: ICoreMemberCriteria = {
    titles: ["Hospice Medical Director", "Hospice Physician"],
    credentials: []
};

export const socialWorkerCriteria: ICoreMemberCriteria = {
    titles: ["Social Worker"],
    credentials: ["LCSW", "MSW", "LMSW", "Marriage Family Therapist", "Mental Health Counselor"]
};

export const counselorCriteria: ICoreMemberCriteria = {
    titles: ["Spiritual Counselor", "Bereavement Coordinator", "Dietician"],
    credentials: ["BCC", "MDiv", "LPC", "LCPC", "LMHC", "LPCC", "RD/LD", "Ordained Minister"]
};

export interface ICoreMemberCheckEnums {
    userTitlesEnum: IEnumResponse[];
    credentialsEnum: IEnumResponse[];
}

@valueConverter("isCoreMember")
export class CoreMemberRevealer {
    public userTitlesEnum: IEnumResponse[] = [];
    public credentialsEnum: IEnumResponse[] = [];

    public toView(attendee: IMeetingAttendee, enums: ICoreMemberCheckEnums): boolean {
        if (!!attendee) {
            this.setEnums(enums);
            return this.isAttendeeCoreMember(attendee);
        } else {
            return false;
        }
    }

    public setEnums(enums: ICoreMemberCheckEnums) {
        if (!!enums) {
            this.userTitlesEnum = Array.from(enums.userTitlesEnum);
            this.credentialsEnum = Array.from(enums.credentialsEnum);
        } else {
            console.warn("enums parameter is null or undefined.");
        }
    }

    public isAttendeeCoreMember(attendee: IMeetingAttendee) {
        return (
            this.isRegisteredNurse(attendee) ||
            this.isHospicePhysician(attendee) ||
            this.isSocialWorker(attendee) ||
            this.isSpiritualCounselor(attendee)
        );
    }

    public isRegisteredNurse(attendee: IMeetingAttendee) {
        return this.criteriaCheck(attendee, registeredNurseCriteria);
    }

    public isHospicePhysician(attendee: IMeetingAttendee) {
        return this.criteriaCheck(attendee, hospicePhysicianCriteria);
    }

    public isSocialWorker(attendee: IMeetingAttendee) {
        return this.criteriaCheck(attendee, socialWorkerCriteria);
    }

    public isSpiritualCounselor(attendee: IMeetingAttendee) {
        return this.criteriaCheck(attendee, counselorCriteria);
    }

    private criteriaCheck(user: IMeetingAttendee, criteria: ICoreMemberCriteria) {
        if (!!user && this.userTitlesEnum.length > 0 && this.credentialsEnum.length > 0) {
            return (
                criteria.titles.map((t) => t.toLocaleLowerCase()).includes(this.getTitleLabel(user.title)) ||
                criteria.credentials
                    .map((t) => t.toLocaleLowerCase())
                    .filter((c) => this.getCredentialsLabels(user.credentials).includes(c)).length > 0
            );
        } else {
            if (!user) {
                console.warn("User is missing.");
            }
            if (this.userTitlesEnum.length <= 0 || this.credentialsEnum.length <= 0) {
                console.warn("Set enums first before checking by criteria.");
            }
            return false;
        }
    }

    private getTitleLabel(title: number) {
        if (this.userTitlesEnum && this.userTitlesEnum.find((e) => e.value == title)) {
            return this.userTitlesEnum.find((e) => e.value == title).name.toLocaleLowerCase();
        } else {
            return "";
        }
    }

    private getCredentialsLabels(credentials: number[]) {
        if (this.credentialsEnum && credentials) {
            return credentials.map((cred) => {
                return this.credentialsEnum.find((credEnum) => credEnum.value === cred).name.toLocaleLowerCase();
            });
        } else {
            return "";
        }
    }
}
