import { customElement, containerless, bindable, bindingMode } from "aurelia-framework";

@customElement("empty-text-handler")
@containerless
export class EmptyTextHandler {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public text: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public exceptionMessage: string = "Not Available";
}
