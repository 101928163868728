import { valueConverter } from "aurelia-binding";

@valueConverter("drugSeverityColor")
export class DrugSeverityColor {
    public toView(severity: string) {
        severity = severity.toLowerCase();
        let result = "success";
        if (severity.includes("major")) {
            result = "danger";
        } else if (severity.includes("moderate")) {
            result = "safety-orange";
        } else if (severity.includes("minor")) {
            result = "warning";
        }
        return result;
    }
}
