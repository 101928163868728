import { bindable, bindingMode, computedFrom, containerless, autoinject } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { IGrouping } from "../../../interfaces/form-builder/i-element";
import { NoteDataManager } from "../note-data-manager";
import { getColumnClasses } from "../../../common/utilities/column-classes";

// INFO: Do not name it section since there is section tag and
// Aurelia will override that section tag
// The section tag was redirected to this component
// since all components are custom elements in Aurelia
@containerless
@autoinject
export class SectionGroup {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public element: IGrouping;
    private _noteDataManager: NoteDataManager;
    public tooltip: string;

    @computedFrom(nameof<SectionGroup>("element"))
    public get columnsClasses(): string {
        let columns = this.element.columns;
        return getColumnClasses(columns);
    }

    @computedFrom(nameof<SectionGroup>("element"))
    public get sectionTypeClass(): string {
        if (this.element.sectionType) {
            return `${this.element.sectionType.toLowerCase()}-section`;
        } else {
            return "";
        }
    }

    public constructor(noteDataManager: NoteDataManager) {
        this._noteDataManager = noteDataManager;
    }

    public async attached() {
        this.tooltip = await this._noteDataManager.getTooltip(this.element.name);
    }
}
