import { autoinject } from "aurelia-framework";
import { HospiceClient } from "../common/hospice-client";
import {
    ICreateContactQuery,
    ICreateGoalQuery,
    ICreateInterventionQuery,
    ICreateServiceQuery,
    IDischargeBereavementRequest,
    IGetBereavedAuthorizedContactsLeanQuery,
    IGetBereavedAuthorizedLeanResult,
    IGetBereavedContactsLeanQuery,
    IGetBereavedLeanResult,
    IGetCheckUpdateResponse,
    IGetContact,
    IGetContactPrefill,
    IGetContactQuery,
    IGetGoal,
    IGetGoalQuery,
    IGetHistoryQuery,
    IGetIntervention,
    IGetInterventionsQuery,
    IGetPatientInformationResult,
    IGetService,
    IGetServiceQuery,
    IHistory,
    IUpdateContactQuery,
    IUpdateGoalQuery,
    IUpdateInterventionQuery,
    IUpdatePrintVersionRequest,
    IUpdateServiceQuery,
    IDischargeBereavementContact
} from "../interfaces/i-bereavement-poc";
import { IPaginationNew } from "../interfaces/i-pagination";
import { ICreateResponse } from "../interfaces/i-response";
import { IBereavementServices, IGetBereavementServices } from "../interfaces/employee-schedule/i-bereavement-services";
import { IUpdatePatientContact } from "../interfaces/i-patient-contact";

@autoinject()
export class BereavementPOCService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/bereavement";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getPatientInformation(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/details`)
            .useQueryString({ patientId })
            .fetch<IGetPatientInformationResult>();
    }

    public async getInterventions(query: IGetInterventionsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/intervention`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetIntervention>>();
    }

    public async getBereavedContactsLean(query: IGetBereavedContactsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/contacts/lean`)
            .useQueryString(query)
            .fetch<IGetBereavedLeanResult[]>();
    }

    public async getBereavedAuthorizedContactLean(query: IGetBereavedAuthorizedContactsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/contacts/authorized-lean`)
            .useQueryString(query)
            .fetch<IGetBereavedAuthorizedLeanResult[]>();
    }

    public async createIntervention(query: ICreateInterventionQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/intervention`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateResponse>();
    }

    public async updateIntervention(interventionId: string, updatedIntervention: IUpdateInterventionQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/intervention/${interventionId}`)
            .useMethod("PUT")
            .useBody(updatedIntervention)
            .fetchNoContent();
    }

    public async deleteIntervention(bereavementId: string, patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/intervention/${bereavementId}`)
            .useMethod("DELETE")
            .useQueryString({ patientId })
            .fetchNoContent();
    }

    public async createGoal(newGoal: ICreateGoalQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/goal`)
            .useMethod("POST")
            .useBody(newGoal)
            .fetch<ICreateResponse>();
    }

    public async updateGoal(goalId: string, updatedGoal: IUpdateGoalQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/goal/${goalId}`)
            .useMethod("PUT")
            .useBody(updatedGoal)
            .fetchNoContent();
    }

    public async getGoals(query: IGetGoalQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/goal`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetGoal>>();
    }

    public async deleteGoal(goalId: string, patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/goal/${goalId}`)
            .useMethod("DELETE")
            .useQueryString({ patientId })
            .fetchNoContent();
    }

    public async createService(newService: ICreateServiceQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/service`)
            .useMethod("POST")
            .useBody(newService)
            .fetchNoContent();
    }

    public async updateService(serviceId: string, updatedService: IUpdateServiceQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/service/${serviceId}`)
            .useMethod("PUT")
            .useBody(updatedService)
            .fetchNoContent();
    }

    public async getServices(query: IGetServiceQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/service`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetService>>();
    }

    public async deleteService(serviceId: string, patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/service/${serviceId}`)
            .useMethod("DELETE")
            .useQueryString({ patientId })
            .fetchNoContent();
    }

    public async createContact(newService: ICreateContactQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/contact`)
            .useMethod("POST")
            .useBody(newService)
            .fetch<ICreateResponse>();
    }

    public async updateContact(contactId: string, updatedService: IUpdateContactQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/contact/${contactId}`)
            .useMethod("PUT")
            .useBody(updatedService)
            .fetch<IUpdatePatientContact>();
    }

    public async getContactPrefill(query: IGetContactQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/contacts/prefill`)
            .useQueryString(query)
            .fetch<IGetContactPrefill>();
    }

    public async getContact(query: IGetContactQuery) {
        return await this._httpClient.build(`${this._baseUrl}/contacts`).useQueryString(query).fetch<IGetContact>();
    }

    public async deleteContact(contactId: string, patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/contact/${contactId}`)
            .useMethod("DELETE")
            .useQueryString({ patientId })
            .fetchNoContent();
    }

    public async getBereavementHistory(query: IGetHistoryQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/versions`)
            .useQueryString(query)
            .fetch<IPaginationNew<IHistory>>();
    }

    public async updateDischargeDate(params: IDischargeBereavementRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/discharge`)
            .useMethod("POST")
            .useBody(params)
            .fetchNoContent();
    }

    public async resumeBeravement(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/resume`)
            .useMethod("POST")
            .useBody({ patientId })
            .fetchNoContent();
    }

    public async printVersion(versionId: string) {
        return await this._httpClient.build(`${this._baseUrl}/version/${versionId}/print`).fetchBlob();
    }

    public async updatePrintVersion(params: IUpdatePrintVersionRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/version/create-print`)
            .useMethod("PUT")
            .useQueryString(params)
            .fetchNoContent();
    }

    public async checkForUpdate(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/version/check-for-update`)
            .useQueryString({ patientId })
            .fetch<IGetCheckUpdateResponse>();
    }

    public async printBereavement(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/print`).useQueryString({ patientId }).fetchBlob();
    }

    public async getUserBereavementServices(query: IGetBereavementServices) {
        return await this._httpClient
            .build(`${this._baseUrl}/service/employee`)
            .useQueryString(query)
            .fetch<IPaginationNew<IBereavementServices>>();
    }

    public async dischargeBereavementContact(data: IDischargeBereavementContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/discharge-contact`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }
}
