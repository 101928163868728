import "./messages-preview.scss";

import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, customElement } from "aurelia-framework";
import { Router } from "aurelia-router";

import { IConversationNavOptions } from "../../../interfaces/i-message";
import { MessageService } from "../../../services/message-service";
import { SignalrEvent } from "../../../signalr/hubs-container";
import { MessageListEvent } from "../../../pages/message-center/conversation/messages-renderer";
import { AccountEvent } from "../../../services/auth-service";

@customElement("messages-preview")
@autoinject
export class MessagesPreview {
    private _ea: EventAggregator;
    private pageNumber: number = 1;
    private pageLength: number = 5;
    private _router: Router;
    private _messageService: MessageService;
    private _eaSubscriptions: Subscription[] = [];
    public conversations: IConversationNavOptions[] = [];
    public unreadConversations: number = 0;
    public shakeMessageIcon: boolean = false;
    public titleChangeInterval: NodeJS.Timer;
    public isLoading: boolean = true;

    public constructor(ea: EventAggregator, messageService: MessageService, router: Router) {
        this._ea = ea;
        this._router = router;
        this._messageService = messageService;
    }

    public async attached() {
        this.getUnreadConversations();
        this._eaSubscriptions.push(
            this._ea.subscribe(AccountEvent.Selected, async () => {
                await this.getUnreadConversations();
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(SignalrEvent.NewMessage, async () => {
                await this.getUnreadConversations();
            })
        );
        this._eaSubscriptions.push(
            this._ea.subscribe(MessageListEvent.HasUnreadMessage, async () => {
                await this.getUnreadConversations();
            })
        );
    }

    public detached() {
        if (this._eaSubscriptions.length > 0) {
            this._eaSubscriptions.forEach((sub) => sub.dispose());
        }
    }

    public async getUnreadConversations() {
        this.isLoading = true;
        let result = await this._messageService.getUnreadConversations({
            page: this.pageNumber,
            pageLength: this.pageLength
        });
        this.isLoading = false;
        this.conversations = result.items ? result.items : [];
        this.unreadConversations = result.itemCount;
        if (this.unreadConversations > 0) {
            this.shakeMessageIcon = true;
            let showMessage = true;
            /* this.titleChangeInterval = setInterval(() => {
                document.title = showMessage ? `(${this.unreadConversations}) New Messages!` : this.getCurrentTitle();
                showMessage = !showMessage;
            }, 1000); */
        } else {
            if (this.titleChangeInterval) {
                // clearInterval(this.titleChangeInterval);
            }
        }
        setTimeout(() => (this.shakeMessageIcon = false), 1000);
    }

    public getCurrentTitle() {
        let router = this._router;
        let title = "";
        if (router.currentInstruction.config.settings.menuName) {
            title = router.currentInstruction.config.settings.menuName + " | ";
        }
        return title + (router as any).title;
    }
}
