import { EventAggregator } from "aurelia-event-aggregator";
import { PLATFORM, useView } from "aurelia-framework";
import { Router } from "aurelia-router";

import { config } from "../../common/config";
import { OidcSignInRedirectCallback, UserLoaded } from "../../common/oidc/oidc-event-handler";
import { ILocationSave } from "../../interfaces/i-location-save";
import { AuthService } from "../../services/auth-service";

@useView(PLATFORM.moduleName("./sign-in-view.html"))
export class SignInRedirectPage {
    public _ea: EventAggregator;
    private _router: Router;
    private _authService: AuthService;

    public constructor(ea: EventAggregator, router: Router, authService: AuthService) {
        this._ea = ea;
        this._router = router;
        this._authService = authService;

        this._ea.subscribeOnce(UserLoaded, () => this.userLoaded());
    }

    public async activate() {
        let location = window.location.toString();
        console.log(`Publishing redirect sign-in using location:`, location);
        this._ea.publish(OidcSignInRedirectCallback, location);
    }

    private async userLoaded() {
        await this._authService.loadAccountAndProviders();
        let saveJson = window.sessionStorage.getItem(config.locationSaveKey);
        let save: ILocationSave = JSON.parse(saveJson) || {};
        console.log(`Restoring saved location:`, save);
        this._router.navigate(save.fragment + "?" + save.query);
    }
}
