import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const hospitalizedPatientsTooltip: IReportTooltip = {
    description:
        "Generates a list of patients that have been marked as hospitalized. This report can be a useful tool to identify total number of patients who have been hospitalized for the organization.",
    parameters: "Branch, Team, Date Range",
    reportIncludes:
        "Days Hospitalized, Branch, Team, MRN, Patient Name, Payment Source, SOC Date, Primary Diagnosis, Hospitalization Date, Date of Last Visit, Facility Name, Hospitalization Reason, Clinical Manager, Case Manager"
};

export const patientDiagReportTooltip: IReportTooltip = {
    description:
        "Displays a list of diagnoses for each patient. Denotes the terminal diagnosis, co-morbidities, and relatedness to the terminal prognosis for hospice patients only.",
    parameters: "Service Line, Provider/Branch, Team, Status, ICD 10 Code, Description",
    reportIncludes:
        "Provider/Branch, Team, Patient Name, MRN, Admission Date, Status, Diagnosis Type (for hospice only), ICD 10 Code, Description, Relatedness (for hospice only), Start Date, Resolved Date"
};

export const onCallStatsTooltip: IReportTooltip = {
    description: "Displays a list of tasks that have been marked as on-call within the selected date range.",
    parameters: "Date Range, Tasks, Team, Branch",
    reportIncludes: "Patient Name, MRN, Branch, Team, Task, Status, Employee, Schedule Start, Schedule End"
};

export const ordersPendingTooltip: IReportTooltip = {
    description:
        "Displays a list of orders that are pending QA approval. This report enables users to identify and address pending orders to ensure compliance.",
    parameters: "Orders Preventing Billing, Branch, and Team",
    reportIncludes: "Patient Name, MRN, Branch, Team, Order Type"
};

export const outstandingOrdersTooltip: IReportTooltip = {
    description:
        "Displays a list of orders that are waiting to be sent to the physician or pending the physician's signature. Users can sort and filter by age to categorize orders based on the length of time each order has spent in outstanding status. This report is crucial for tracking physician orders to maintain compliance and streamline billing processes.",
    parameters: "Age, Branch, Team",
    reportIncludes: "Patient Name, MRN, Branch, Team, Age, Status, Order Type"
};

export const outstandingOrdersByTypeTooltip: IReportTooltip = {
    description: "Displays a list of outstanding orders sorted by To Be Sent or Sent to Physician status.",
    parameters: " Branch, Order Type, Status, Date Range",
    reportIncludes: "Patient Name, Branch, Physician, Order Type, Order Date, Status, Approval Date, Sent Date"
};

export const overlappingFrequencyTooltip: IReportTooltip = {
    description:
        "Displays a list of visit frequencies that overlap with other visit frequencies of the same discipline.",
    parameters: "Branch, Discipline, Effective Date.",
    reportIncludes: "Branch, Team, Last Name, First Name, MRN, Discipline, Frequency, Start Date, End Date, Actions."
};

export const topOutstandingOrdersTooltip: IReportTooltip = {
    description: "Displays a list of orders in Sent to Physician status.",
    parameters: "Branch, Physician, Order Type, Date Range",
    reportIncludes: "Branch, Physician, Patient, Order Type, Order Date, Sent Date"
};

export const supervisoryVisitTooltip: IReportTooltip = {
    description:
        "Displays a list of supervisory visits sorted by discipline and shows the number of days since the last supervisory visit was completed. This report is critical for internal administrative purposes and maintaining compliance.",
    parameters: "Branch, Team, Employee Name, Task Type, Date",
    reportIncludes:
        "Branch, Team, Patient Name, Employee Name, Task Type, Status, Schedule Date, Days Since Last Supervision"
};

export const skilledNursingCareVisitTooltip: IReportTooltip = {
    description:
        "Displays a list of skilled nursing visits and time for patients in the agency within a given date range. This report is useful in monitoring nursing time spent with patients and visits occurring on the weekend to help track trends for the Hospice Care Index.",
    parameters: "Branch, Team, Level of Care, Day of Week, Date Range",
    reportIncludes:
        "Employee Name, Patient Name, Branch, Team, Level of Care, Day of Week, Task, Task Date, Task Status, Visit Time In, Visit Time Out, Total Time"
};

export const skilledNursingCareWeekendsTooltip: IReportTooltip = {
    description:
        "Displays a list of skilled nursing visits performed on weekends with the total number of minutes spent on each visit. This report is useful in monitoring Indicator 9 of the Hospice Care Index.",
    parameters: " Branch, Team, Task, Task Status, Day, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, Employee Name, Task, Task Status, Day, Visit Start Time, Visit End Time, Total Time"
};

export const levelOfCareTooltip: IReportTooltip = {
    description: "Displays a list of patients and their level of care histories.",
    parameters: "Branch, Team, Patient Status, Payer, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Status, Payer, Level of Care, Start Date, End Date, Benefit Period Number, Tags"
};

export const benefitPeriodHistoryTooltip: IReportTooltip = {
    description: "Displays a list of patients and their benefit period histories.",
    parameters: "Provider/Branch, Team, Patient Status, Payer, Date Range",
    reportIncludes:
        "Provider/Branch, Team, Last Name, First Name, MRN, Patient Status, Payer, Benefit Period, Start Date, End Date, Tags"
};

export const incidentLogTooltip: IReportTooltip = {
    description: "Displays a list of patients and their documented incidents/accidents.",
    parameters: "Provider/Branch, Team, Status, Type, Sentinel Event, Date Range",
    reportIncludes:
        "Provider/Branch, Team, Patient Name, Status, Patient Location, MRN, Task, Date Reported, Date of Incident, Time of Incident, Type of Incident, Witnessed, Sentinel Event, Interventions, Physician Notified, Required Emergent Care, Tags"
};

export const nonCoveredItemsTooltip: IReportTooltip = {
    description:
        "Displays a list of all addenda generated for non-covered items in the last 30 days. This report enables users to easily monitor addendum and signature statuses for regulatory compliance.",
    parameters: "Provider/Branch, Patient Status, Date Range, Addendum Status, Signature Status, Tags",
    reportIncludes:
        "Provider/Branch, Patient Name, MRN, Patient Status, Requested Date, Requested By, Requester Name, Addendum Status, Signature Date, Signature Status, Tags"
};
