import {PLATFORM} from "aurelia-pal";
import {RouteConfig} from "aurelia-router";

import {AdministrativePermissionEnum} from "../../enums/administrative-permission-enum";
import {PermissionActionEnum} from "../../enums/permission-action-enum";

export const HomeRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "my-dashboard"
    },
    {
        name: "my-dashboard",
        route: "my-dashboard",
        moduleId: PLATFORM.moduleName("./my-dashboard/my-dashboard", "my-dashboard"),
        nav: true,
        title: "My Dashboard",
        settings: {
            menuName: "My Dashboard",
            keywords: ["dashboard", "landing"]
        }
    },
    // {
    //     name: "message-center",
    //     route: "message-center",
    //     moduleId: PLATFORM.moduleName("../message-center/message-center", "message-center"),
    //     nav: true,
    //     title: "Message Center",
    //     settings: {
    //         menuName: "Message Center",
    //         keywords: ["message", "send", "receive", "mc"]
    //     }
    // },
    {
        name: "pending-co-signature",
        route: "pending-co-signature",
        moduleId: PLATFORM.moduleName("./pending-co-signature/pending-co-signature", "pending-co-signature"),
        nav: true,
        title: "Pending Co-Signature",
        settings: {
            menuName: "Pending Co-Signature",
            keywords: [
                "review",
                "approve",
                "return",
                "task",
                "visit",
                "document",
                "order",
                "audit",
                "pre-qa",
                "co signature",
                "signature"
            ]
        }
    },
    {
        name: "qa-center",
        route: "qa-center",
        moduleId: PLATFORM.moduleName("./qa-center/qa-center", "qa-center"),
        nav: true,
        title: "Quality Assurance (QA) Center",
        settings: {
            menuName: "Quality Assurance (QA) Center",
            keywords: ["review", "approve", "return", "task", "visit", "document", "order", "audit"],
            permission: [
                {
                    resource: AdministrativePermissionEnum.QaCenter,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "his-export",
        route: "his-export",
        moduleId: PLATFORM.moduleName("./his-export/his-export", "his-export"),
        nav: true,
        title: "HIS Export Manager",
        settings: {
            menuName: "HIS Export Manager",
            isPalliativeCare: false,
            keywords: ["review", "approve", "return", "task", "visit", "document", "order", "audit", "export", "his"]
        }
    },
    {
        name: "my-payroll-summary",
        route: "my-payroll-summary",
        moduleId: PLATFORM.moduleName("./my-payroll-summary/my-payroll-summary", "my-payroll-summary"),
        nav: true,
        title: "My Payroll Summary",
        settings: {
            menuName: "My Payroll Summary"
        }
    }
];
