export enum NavigationComponentEnum {
    Patient = "patient",
    Wound = "wound",
    Infection = "infection",
    Incident = "incident",
    Medication = "medication",
    Frequency = "frequency-profile",
    Allergy = "allergy",
    PlanOfCareProfile = "plan-of-care-profile",
    InfectiousDiseaseScreening = "infectious-disease-profile",
    BereavementPlanOfCareProfile = "bereavement-plan-of-care-profile",
    ConsentTracking = "consent-tracking",
    SymptomRatings = "symptom-ratings",
    VitalSigns = "vital-signs",
    EMar = "emar",
    HospiceAidePlanOfCare = "hospice-aide-plan-of-care",
    HomemakerPlanOfCare = "homemaker-plan-of-care",
    AddContact = "add-contact"
}
