import "./diagnosis.scss";

import {autoinject, bindable, bindingMode, containerless, customElement, observable} from "aurelia-framework";
import {ValidationRules} from "aurelia-validation";

import nameof from "../../../common/nameof";
import {IDiagnosis, IDiagnosisChangeParameter, IDiagnosisDetails} from "../../../interfaces/i-diagnosis";
import {IValidateCustomElement} from "../../../interfaces/i-validate-custom-element";
import {DiagnosisQueryTypeEnum, LookupService} from "../../../services/lookup-service";

@customElement("diagnosis")
@containerless
@autoinject
export class Diagnosis {
    @observable({
        changeHandler: nameof<Diagnosis>("codeFilterChanged")
    })
    public codeFilter: string = "";
    @observable({
        changeHandler: nameof<Diagnosis>("descFilterChanged")
    })
    public descFilter: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedDiagnosis: IDiagnosis = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    private debounce: number = 200;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public noResultsText: string = "No matches found";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public loadingText: string = "Loading...";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public classes: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable
    public diagnosisChanged: (newDiagnosis: IDiagnosisChangeParameter) => void;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public codeValidation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public descValidation: IValidateCustomElement = {
        required: false
    };
    private currentDiagnosisLevel: number = 0;
    private parentDiagnosis: IDiagnosisDetails[] = [];
    private ignoreCodeFilterUpdate: boolean = false;
    private ignoreDescFilterUpdate: boolean = false;
    private ignoreSelectedDiagnosisUpdate: boolean = false;
    private readonly _lookUpService: LookupService;
    private _outsideClickListener: (evt: MouseEvent) => void;
    public currentDiagnosisList: IDiagnosisDetails[] = [];
    public diagnosisList: IDiagnosisDetails[] = [];
    public loading: boolean = false;
    public diagnosisListComponent: HTMLElement;

    private getImmediateParentDiag() {
        if (this.parentDiagnosis.length > 0) {
            return this.parentDiagnosis[this.parentDiagnosis.length - 1];
        }
        return null;
    }

    public constructor(lookUpService: LookupService) {
        this._lookUpService = lookUpService;
        this._outsideClickListener = (evt: MouseEvent) => this.handleBlur(evt);
    }

    public attached() {
        document.addEventListener("click", this._outsideClickListener);
        if (this.selectedDiagnosis) {
            this.ignoreCodeFilterUpdate = true;
            this.codeFilter = this.selectedDiagnosis.code;
            this.ignoreDescFilterUpdate = true;
            this.descFilter = this.selectedDiagnosis.description;
        }
        if (this.codeValidation && this.descValidation) {
            let codeDisplayName = this.codeValidation.displayName;
            let codeMessage = this.codeValidation.message;
            let descDisplayName = this.descValidation.displayName;
            let descMessage = this.descValidation.message;
            ValidationRules
                .ensure((diagnosis: Diagnosis) => diagnosis.codeFilter)
                .displayName(codeDisplayName)
                .required().when((diagnosis: Diagnosis) => diagnosis.codeValidation.required)
                .withMessage(codeMessage ? codeMessage : `${codeDisplayName} is required.`)
                .ensure((diagnosis: Diagnosis) => diagnosis.descFilter)
                .displayName(descDisplayName)
                .required().when((diagnosis: Diagnosis) => diagnosis.descValidation.required)
                .withMessage(descMessage ? descMessage : `${descDisplayName} is required.`)
                .on(this);
        }
    }

    public detached() {
        document.removeEventListener("click", this._outsideClickListener);
    }

    private updateDiagnosisList(diagnosisResultList: IDiagnosisDetails[]) {
        this.diagnosisList = diagnosisResultList;
        this.parentDiagnosis = [];
        if (diagnosisResultList && diagnosisResultList.length > 0) {
            this.currentDiagnosisLevel = Math.min.apply(null, this.diagnosisList.map((diagnosis: IDiagnosisDetails) => diagnosis.levels));
        } else {
            this.currentDiagnosisLevel = 0;
        }
        this.renderDiagnosis();
    }

    private renderDiagnosis() {
        if (this.diagnosisList && this.diagnosisList.length > 0) {
            this.currentDiagnosisList = this.diagnosisList.filter((diagnosis: IDiagnosisDetails) => {
                let diagnosisLevel = diagnosis.levels == this.currentDiagnosisLevel;
                let diagnosisParent = this.getImmediateParentDiag() ? diagnosis.upperLevel == this.getImmediateParentDiag().code : true;
                return diagnosisLevel && diagnosisParent;
            });
        } else {
            this.currentDiagnosisList = [];
        }
    }

    public selectedDiagnosisChanged(newValue: IDiagnosis) {
        if (this.ignoreSelectedDiagnosisUpdate) {
            this.ignoreSelectedDiagnosisUpdate = false;
            return;
        }
        if (newValue) {
            this.ignoreCodeFilterUpdate = true;
            this.codeFilter = newValue.code;
            this.ignoreDescFilterUpdate = true;
            this.descFilter = newValue.description;
        }

    }

    public codeFilterChanged(newValue: string, oldValue: string) {
        if (this.ignoreCodeFilterUpdate) {
            this.ignoreCodeFilterUpdate = false;
            return;
        }
        if (newValue == "") {
            this.resetDiagnosis();
        }
        if (newValue && newValue.length > 2) {
            setTimeout(async () => {
                this.loading = true;
                let diagnosisResult = await this._lookUpService.getIcd10Codes({
                    term: newValue,
                    type: DiagnosisQueryTypeEnum.code
                });
                this.ignoreDescFilterUpdate = true;
                this.descFilter = "";
                this.loading = false;
                this.updateDiagnosisList(diagnosisResult);
            }, this.debounce);
        }
    }

    public descFilterChanged(newValue: string, oldValue: string) {
        if (this.ignoreDescFilterUpdate) {
            this.ignoreDescFilterUpdate = false;
            return;
        }
        if (newValue == "") {
            this.resetDiagnosis();
        }
        if (newValue && newValue.length > 2) {
            setTimeout(async () => {
                this.loading = true;
                let diagnosisResult = await this._lookUpService.getIcd10Codes({
                    term: newValue,
                    type: DiagnosisQueryTypeEnum.desc
                });
                this.ignoreCodeFilterUpdate = true;
                this.codeFilter = "";
                this.loading = false;
                this.updateDiagnosisList(diagnosisResult);
            }, this.debounce);
        }
    }

    public selectDiagnosis(diagnosis: IDiagnosisDetails) {
        if (!diagnosis.billable) {
            this.currentDiagnosisLevel = diagnosis.levels + 1;
            this.parentDiagnosis.push(diagnosis);
            this.renderDiagnosis();
        } else {
            this.ignoreCodeFilterUpdate = true;
            this.codeFilter = diagnosis.formatCode;
            this.ignoreDescFilterUpdate = true;
            this.descFilter = diagnosis.shortDescription;
            this.ignoreSelectedDiagnosisUpdate = true;
            this.selectedDiagnosis = { code: diagnosis.formatCode, description: diagnosis.shortDescription, billable: diagnosis.billable };
            if (typeof this.diagnosisChanged === "function") {
                this.diagnosisChanged({
                    newDiagnosis: { code: diagnosis.formatCode, description: diagnosis.shortDescription, billable: diagnosis.billable }
                });
            }
            this.currentDiagnosisList = [];
        }
    }

    private handleBlur(evt: Event) {
        let source = evt.target as Element;
        if ($(this.diagnosisListComponent).find(source).length != 0 || this.currentDiagnosisList.length != 0) {
            return;
        }
        this.updateDiagnosisList([]);
    }

    public goInitState() {
        this.updateDiagnosisList(this.diagnosisList);
    }

    public goToDiagList(index: number, diagnosis: IDiagnosisDetails) {
        this.parentDiagnosis.splice(index, this.parentDiagnosis.length);
        this.selectDiagnosis(diagnosis);
    }

    private resetDiagnosis() {
        this.codeFilter = "";
        this.descFilter = "";
        this.ignoreSelectedDiagnosisUpdate = true;
        this.selectedDiagnosis = null;
        this.updateDiagnosisList([]);
        if (this.diagnosisChanged) {
            this.diagnosisChanged(null);
        }
    }
}
