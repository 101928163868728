import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, containerless, customElement, observable } from "aurelia-framework";
import nameof from "../../common/nameof";
import { IPermissionBehavior } from "../../interfaces/form-builder/i-behavior";
import { IDisplayDependency, IElement } from "../../interfaces/form-builder/i-element";
import { IInput } from "../../interfaces/form-builder/i-input";
import { FormModelType } from "../../interfaces/form-builder/i-model-schema";
import { BehaviorTypesEnum } from "./behavior-handlers/behavior-types-enum";
import { DependencyTypeEnum } from "./behavior-handlers/display-type-enum";
import { PermissionBehaviorHandler } from "./behavior-handlers/permission-behavior-handler";
import { ElementTypesEnum } from "./element-types-enum";
import { FormButtonEvent } from "./form-input/form-button/form-button";
import { FormBuilderEvent, NoteDataManager } from "./note-data-manager";

@containerless
@autoinject
@customElement("form-element")
export class FormElement {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<FormElement>("elementChanged") })
    public element: IElement;
    // For Addendum, new bindable prop called skeletonElement
    // If skeletonElement exists, then get value from metaSchema and store it in this.element
    private _ea: EventAggregator;
    private _noteDataManager: NoteDataManager;
    private _permissionBehaviorHandler: PermissionBehaviorHandler;
    private _dependencyModifiedSubscription: Subscription[] = [];
    private _hasDependencies: boolean = false;
    private _hasDisplayDependencies: boolean = false;
    private _permissionBehaviorInfo: IPermissionBehavior;
    public showElement: boolean = true;
    public elementTypesEnum: typeof ElementTypesEnum = ElementTypesEnum;

    public constructor(
        ea: EventAggregator,
        noteDataManager: NoteDataManager,
        permissionBehaviorHandler: PermissionBehaviorHandler
    ) {
        this._ea = ea;
        this._noteDataManager = noteDataManager;
        this._permissionBehaviorHandler = permissionBehaviorHandler;
    }

    public elementChanged() {
        if (!!this.element) {
            this._hasDependencies = this.element.dependencies?.length > 0;

            this._permissionBehaviorInfo = (this.element as IInput).behaviors?.find((behavior) => {
                return behavior.behaviorType === BehaviorTypesEnum.Permission;
            }) as IPermissionBehavior;

            this._hasDisplayDependencies = this.element.valueDependencies?.length > 0;
            if (this._hasDependencies || this._hasDisplayDependencies || !!this._permissionBehaviorInfo?.isShowHide) {
                this.setShowElement();
            }
            this.disposeSubscription();
            this._dependencyModifiedSubscription.push(
                this._ea.subscribe(
                    FormButtonEvent.ToggleDisplay,
                    (dependencyInfo: { elementName: string; value: FormModelType }) => {
                        if (this._hasDependencies || this._hasDisplayDependencies) {
                            let hasMyDisplayDependency = false;
                            let hasMyDependency = false;
                            if (this._hasDependencies) {
                                hasMyDependency = this.element.dependencies.some(
                                    (dependency) => dependency === dependencyInfo.elementName
                                );
                            }
                            if (this._hasDisplayDependencies) {
                                hasMyDisplayDependency = this.element.valueDependencies.some(
                                    (dependency) => dependency.targetName === dependencyInfo.elementName
                                );
                            }
                            if (hasMyDependency || hasMyDisplayDependency) {
                                this.setShowElement(dependencyInfo);
                            }
                        }
                    }
                )
            );

            this._dependencyModifiedSubscription.push(
                this._ea.subscribe(FormBuilderEvent.ModifiedDataUpdated, (prop: string) => {
                    if (this._hasDependencies || this._hasDisplayDependencies) {
                        let hasDependency = this.modifiedDataUpdated(prop);
                        if (hasDependency) {
                            let modifiedData = this._noteDataManager.getModifiedData();
                            let dependencyInfo = {
                                elementName: prop,
                                value: modifiedData[prop] as number
                            };
                            this.setShowElement(dependencyInfo);
                        }
                    }
                })
            );

            this._dependencyModifiedSubscription.push(
                this._ea.subscribe(FormBuilderEvent.ModifiedDataPropDeleted, (prop: string) => {
                    if (this._hasDependencies || this._hasDisplayDependencies) {
                        let hasDependency = this.modifiedDataUpdated(prop);
                        if (hasDependency) {
                            this.setShowElement();
                        }
                    }
                })
            );
        }
    }

    private modifiedDataUpdated(prop: string) {
        let hasMyDisplayDependency = false;
        let hasMyDependency = false;
        if (this._hasDependencies) {
            hasMyDependency = this.element.dependencies.some((dependency) => dependency === prop);
        }
        if (this._hasDisplayDependencies) {
            hasMyDisplayDependency = this.element.valueDependencies.some(
                (dependency) => dependency.targetName === prop
            );
        }
        return hasMyDependency || hasMyDisplayDependency;
    }

    private setShowElement(dependencyInfo?: { elementName: string; value: FormModelType }) {
        if (this._permissionBehaviorInfo?.isShowHide) {
            let hasPermission = this._permissionBehaviorHandler.handle(this._permissionBehaviorInfo);
            if (!hasPermission) {
                this.showElement = false;
                return;
            }
        }
        let showElement = true;
        if (this._hasDisplayDependencies) {
            showElement = showElement && this.handleDisplayDependencies(dependencyInfo);
        } else if (this._hasDependencies) {
            showElement = showElement && this.handleDependencies(dependencyInfo);
        }
        this.showElement = showElement;
    }

    private handleDisplayDependencies(dependencyInfo?: { elementName: string; value: FormModelType }) {
        let showElement = true;
        let displayDependencies = this.element?.valueDependencies;
        if (displayDependencies?.length > 0) {
            let noteModel = this._noteDataManager.getNoteModel();
            showElement = displayDependencies.reduce((result: boolean, dependency: IDisplayDependency) => {
                // TODO: Handle array type
                let targetName = this._noteDataManager.getModelKeyFromTargetName(dependency.targetName);
                let dependencyValue = noteModel[targetName] || false;
                // INFO: Unable to get it from modifiedData due to race condition.
                // EventAggregator is faster than data binding updates.
                if (!!dependencyInfo) {
                    dependencyValue = dependencyInfo.value;
                }
                switch (dependency.dependencyType) {
                    case DependencyTypeEnum.HasValue:
                        let affectingValues = dependency.values;
                        affectingValues = affectingValues.map((value) => value.toString());
                        if (affectingValues?.length > 0) {
                            return (
                                result &&
                                affectingValues.includes(dependencyValue.toString() ? dependencyValue.toString() : "")
                            );
                        }
                        break;
                    case DependencyTypeEnum.WithInRange:
                        return (
                            result && dependencyValue >= dependency.minValue && dependencyValue <= dependency.maxValue
                        );
                    case DependencyTypeEnum.IsNotEmpty:
                        return (
                            result &&
                            (!!dependencyValue || (Array.isArray(dependencyValue) && dependencyValue.length > 0))
                        );
                    case DependencyTypeEnum.DoesNotHaveValue:
                        let values = dependency.values;
                        values = values.map((value) => value.toString());
                        if (values?.length > 0) {
                            return result && values.indexOf(dependencyValue ? dependencyValue.toString() : "") === -1;
                        }
                        break;
                    case DependencyTypeEnum.LimitOptions:
                        let element = this.element as IInput;
                        if (element.elementType === ElementTypesEnum.Input && element?.options) {
                            return !!Array.isArray(dependencyValue) && !!dependencyValue?.length;
                        }
                        break;
                }
                return result && true;
            }, true);
        }
        return showElement;
    }

    private handleDependencies(dependencyInfo?: { elementName: string; value: FormModelType }) {
        let showElement = true;

        let dependencies = this.element?.dependencies;
        if (dependencies?.length > 0) {
            let noteModel = this._noteDataManager.getNoteModel();
            showElement = dependencies.reduce((result: boolean, dependency: string) => {
                let dependencyValue = !!noteModel[dependency];
                // INFO: Unable to get it from modifiedData due to race condition.
                // EventAggregator is faster than data binding updates.
                if (!!dependencyInfo) {
                    dependencyValue = dependencyInfo.value as boolean;
                }
                return result && dependencyValue;
            }, true);
        }
        return showElement;
    }

    private disposeSubscription() {
        this._dependencyModifiedSubscription?.forEach((subscription) => {
            subscription?.dispose();
        });
    }

    public detached() {
        this.disposeSubscription();
    }
}
