import "./form-note.scss";

import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    observable
} from "aurelia-framework";
import { Router } from "aurelia-router";
import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";

import nameof from "../../../common/nameof";
import { IElement } from "../../../interfaces/form-builder/i-element";
import { IFormSchema } from "../../../interfaces/form-builder/i-form-schema";
import { IFormModel, IModelSchema } from "../../../interfaces/form-builder/i-model-schema";
import { ITooltip } from "../../../interfaces/form-builder/i-tooltips";
import { NoteDataManager } from "../note-data-manager";
import { ElementTypesEnum } from "../element-types-enum";
import { GroupingTypesEnum } from "../form-grouping/grouping-types-enum";

@customElement("form-note")
@autoinject
@containerless
export class FormNote {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isNonPatientActivity: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isVolunteerActivity: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public formSchema: IFormSchema = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public noteModel: IFormModel = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public modelSchema: IModelSchema = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public tooltips: ITooltip[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public userId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showTitle: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showHeader: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public previousUrl: string = "/";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public prefillLoadingText: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPrefillInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isSaveInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showDefaultStateLoader: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<FormNote>("readOnlyFormChanged") })
    public readOnlyForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public reloadForm: () => void;
    private readonly _noteDataManager: NoteDataManager;
    private readonly _router: Router;
    @observable({ changeHandler: nameof<FormNote>("currentTabChanged") })
    public currentTab: string = "";
    public navId: string = "top_nav";
    public elements: IElement[];
    public tabs: IElement[];
    public controller: ValidationController;
    public selectedTabContent: IElement;
    public isCheckErrorInProgress: boolean = false;

    @computedFrom(nameof<FormNote>("tabs"))
    public get hasTabs(): boolean {
        return !!this.tabs && this.tabs.length > 1;
    }

    @computedFrom(
        nameof<FormNote>("hideForm"),
        nameof<FormNote>("isLoading"),
        nameof<FormNote>("isError"),
        nameof<FormNote>("isSaveInProgress"),
        nameof<FormNote>("isCheckErrorInProgress"),
        nameof<FormNote>("selectedTabContent")
    )
    public get showForm(): boolean {
        return (
            !this.hideForm &&
            !this.isError &&
            !this.isLoading &&
            !this.isSaveInProgress &&
            !this.isCheckErrorInProgress &&
            !this.isPrefillInProgress &&
            !!this.selectedTabContent
        );
    }

    @computedFrom(`${nameof<FormNote>("tabs")}.${nameof<IElement>("label")}`, `${nameof<FormNote>("currentTab")}`)
    public get activeRouteName() {
        if (this.tabs && this.currentTab) {
            return this.tabs.filter((nav) => nav.name == this.currentTab)[0].label;
        } else {
            return "Menu";
        }
    }

    public constructor(
        noteDataManager: NoteDataManager,
        router: Router,
        controllerFactory: ValidationControllerFactory
    ) {
        this._router = router;
        this._noteDataManager = noteDataManager;
        this._noteDataManager.setIsReadOnly(false);
        this.controller = controllerFactory.createForCurrentScope();
        this.controller.validateTrigger = validateTrigger.changeOrBlur;
    }

    public attached() {
        this.initNote();
    }

    public isLoadingChanged() {
        if (!this.isLoading && !!this.formSchema) {
            this.initNote();
        }
    }

    public readOnlyFormChanged() {
        this._noteDataManager.setIsReadOnly(this.readOnlyForm);
    }

    public currentTabChanged(newTab: string, oldTab?: string) {
        this.getContent();
    }

    private initNote() {
        if (this.isLoading || !this.formSchema || !this.modelSchema || !this.noteModel) {
            return;
        }

        this.initMetaData();
        this.generateForm();

        if (!this.currentTab && this.hasTabs) {
            this.currentTab = this.tabs[0].name;
            console.log("No current tab found but has tabs, set current tab to", this.currentTab);
        } else {
            this.currentTabChanged(this.currentTab);
        }
    }

    private initMetaData() {
        this._noteDataManager.setIsReadOnly(this.readOnlyForm);
        if (!this.patientId && !this.userId && !this.isNonPatientActivity && !this.isVolunteerActivity) {
            this._router.navigate(this.previousUrl);
            return;
        } else if (this.patientId) {
            this._noteDataManager.setPatientId(this.patientId);
        } else if (this.userId) {
            this._noteDataManager.setUserId(this.userId);
        }
    }

    private generateForm() {
        this.isError = false;
        this._noteDataManager.setFormSchema(this.formSchema);
        this._noteDataManager.setNoteModel(this.noteModel);
        this._noteDataManager.setModelSchema(this.modelSchema);
        this._noteDataManager.setToolTips(this.tooltips);
        this.elements = this.formSchema.elements;
        this.getTabs();
    }

    private getTabs() {
        this.tabs = this.elements.filter(
            (elem) =>
                elem.elementType === ElementTypesEnum.Grouping &&
                elem.groupingType === GroupingTypesEnum.Tab &&
                elem.elements &&
                elem.elements.length > 0
        );
    }

    private getContent() {
        if (this.hasTabs && !!this.currentTab) {
            console.log("Setting content: ", this.currentTab, this.hasTabs);
            this.selectedTabContent = this.tabs.find((elem) => elem.name === this.currentTab);
        } else if (!!this.formSchema) {
            console.log("Set content: Has no tab", this.hasTabs);
            this.selectedTabContent = this.formSchema.elements[0];
        } else {
            console.log("No Tabs loaded & No Current Tab & No Form Schema; That means nothing to see yet.");
        }
    }

    public async validate() {
        return await this.controller.validate();
    }

    public detached() {
        this.tabs = [];
    }
}
