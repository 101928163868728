import {DialogController} from "aurelia-dialog";
import {autoinject} from "aurelia-framework";
import {validateTrigger, ValidationController, ValidationControllerFactory, ValidationRules} from "aurelia-validation";
import moment from "moment";

import {IDialogOptions} from "../../../interfaces/i-dialog";

@autoinject
export class SignSubmit {
    public controller: DialogController;
    public validationController: ValidationController;
    public options: ISignSubmitOptions = {
        title: "Information Verified",
        description: "All the information has been verified. Please sign to complete the document.",
        type: "sign-submit",
        icon: "fas fa-clipboard-check",
        isDocument: true
    };
    public signature: boolean = false;
    public dateSignedOn: string = "";
    public timeSignedOn: string = "";
    public datePickerOptions: DatepickerOptions = {
        endDate: moment().format("MM/DD/YYYY")
    };

    public constructor(controller: DialogController, controllerFactory: ValidationControllerFactory) {
        this.controller = controller;
        this.validationController = controllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
    }

    public activate(options: IDialogOptions) {
        if (options) {
            Object.assign(this.options, options);
        }
    }

    public attached() {
        this.initValidation();
    }

    private initValidation() {
        ValidationRules
            .ensure((x: SignSubmit) => x.dateSignedOn)
            .required()
            .when((x: SignSubmit) => x.options?.showDateSettings)
            .withMessage("Signature Date is required.")
            .ensure((x: SignSubmit) => x.timeSignedOn)
            .required()
            .when((x: SignSubmit) => x.options?.showTimeSettings)
            .withMessage("Signature Time is required.")
            .on(this);
    }

    public async completeDocument() {
        let res = await this.validationController.validate();
        if (res.valid) {
            let resultOutput = { signature: this.signature, signatureDate: this.dateSignedOn, signatureTime: this.timeSignedOn };
            this.controller.ok(resultOutput);
        }
    }
}

export interface ISignSubmitOptions extends IDialogOptions {
    inconsistencies?: number;
    warnings?: number;
    isDocument: boolean;
    showDateSettings?: boolean;
    showTimeSettings?: boolean;
}
