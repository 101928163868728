import { bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";
import { config } from "../../../common/config";
import nameof from "../../../common/nameof";
import { IPageCallbackResult, IPageChangedEvent } from "../../../interfaces/i-page-container";
import "./pagination.scss";

export const PAGE_FAIL_RESPONSE: IPageCallbackResult = {
    totalPageCount: 0,
    success: false,
    totalCount: 0
};

@customElement("pagination")
export class Pagination {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public numberOfPagesToShow: number = 9;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<Pagination>("pageSizeChanged") })
    public pageSize: number = config.pageSize;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public pageChanged: (event: IPageChangedEvent) => IPageCallbackResult;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showPageSizeOptions: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageSizeOptions: number[] = [10, 25];
    private _remakeGetCall: boolean = false;
    public currentPage: number = 1;
    public totalPageCount: number = 0;
    public totalDataCount: number = 0;
    public noData: boolean = false;
    public currentNavPages: number[] = [];
    public lastPageNumberLoaded: number = -1;

    @computedFrom("currentPage")
    public get disablePrevious(): boolean {
        return this.currentPage === 1;
    }

    @computedFrom("currentPage", "totalPageCount")
    public get disableNext(): boolean {
        return this.currentPage === this.totalPageCount;
    }

    public async attached() {
        await this.changePage();
    }

    public async changePage(pageNum: number = -1): Promise<void> {
        if (pageNum !== -1) {
            this.currentPage = pageNum;
        }
        await this.getData();
    }

    private async getData(): Promise<void> {
        if (this.isLoading) {
            // if there is already a get request, make another request after current one is resolved
            this._remakeGetCall = true;
        }
        if (!this.isLoading /* && this.lastPageNumberLoaded !== this.currentPage */ && this.pageChanged) {
            this.isLoading = true;
            let data = await this.pageChanged({
                pageNumber: this.currentPage,
                pageSize: this.pageSize
            });
            /* if (data.success) {
                this.lastPageNumberLoaded = this.currentPage;
            } */
            this.isLoading = false;
            this.noData = !data.success || data.totalPageCount == 0;
            this.totalPageCount = data.totalPageCount;
            this.totalDataCount = data.totalCount;
            this.generateNavPages();
            if (this._remakeGetCall) {
                this._remakeGetCall = false;
                await this.getData();
            }
            if (this.currentPage > this.totalPageCount && this.totalPageCount != 0) {
                this.currentPage = 1;
                await this.getData();
            }
        }
    }

    private generateNavPages() {
        let total = this.totalPageCount;
        let show = this.numberOfPagesToShow;
        let current = this.currentPage;
        let navPages = [];
        let startPage = current - Math.floor(show / 2) <= 1 ? 1 : current - Math.floor(show / 2);
        let endPage = current + Math.floor(show / 2) >= total ? total : current + Math.floor(show / 2);

        if (total <= show) {
            for (let i = 1; i <= total; i++) {
                navPages.push(i);
            }
            this.currentNavPages = navPages;
            return;
        }

        if (startPage === 1) {
            for (let i = 1; i <= show; i++) {
                navPages.push(i);
            }
        } else if (endPage === total) {
            for (let i = 0; i < show; i++) {
                navPages.unshift(total - i);
            }
        } else {
            for (let i = startPage; i <= endPage; i++) {
                navPages.push(i);
            }
        }
        this.currentNavPages = navPages;
    }

    public async incrementPageNumber(): Promise<void> {
        if (this.currentPage !== this.totalPageCount) {
            this.currentPage++;
            await this.changePage();
        }
    }

    public async decrementPageNumber(): Promise<void> {
        if (this.currentPage > 1) {
            this.currentPage--;
            await this.changePage();
        }
    }

    public async pageSizeChanged(newValue: number, oldValue: number) {
        if (newValue && newValue.toString() !== oldValue.toString()) {
            await this.reset();
        }
    }

    public async refresh() {
        await this.changePage(this.currentPage);
    }

    public async reset() {
        await this.changePage(1);
    }
}
