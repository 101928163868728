import { autoinject, bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { formatName } from "../../../common/utilities/format-name";
import { getEnumValue } from "../../../common/utilities/get-enum-value";
import { IEnumResponse } from "../../../interfaces/i-enum";
import {
    IDeceasedPatientQuery,
    IDischargePatientQuery,
    IGetPatientsSlimInfoResult,
    INonAdmitPatientQuery
} from "../../../interfaces/i-get-patient";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IGetUsersByRoleQuery, IUserResult } from "../../../interfaces/i-user";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { HospitalizedPatient } from "../../../models/patient/hospitalized-patient";
import { BranchesService } from "../../../services/branches-service";
import { EnumsService } from "../../../services/enums-service";
import { IGetAllAgencyBranches } from "../../../services/i-branches-service";
import { UsersService } from "../../../services/users-service";
import { VendorsService } from "../../../services/vendor-service";
import { AccountsService } from "../../../services/accounts-service";
import { NonAdmitReasonsService } from "../../../services/non-admit-reasons-service";

@autoinject
@customElement("status-update-form")
export class StatusUpdateForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patient: IGetPatientsSlimInfoResult;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public nonAdmitPatient: INonAdmitPatientQuery;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public dischargePatient: IDischargePatientQuery;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public hospitalizedPatient: HospitalizedPatient;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public deceasedPatient: IDeceasedPatientQuery;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPalliativeCareView: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedStatus: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientStatusEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public hospitalDischargeDate: string = "";
    private _enumsService: EnumsService;
    private _vendorsService: VendorsService;
    private _usersService: UsersService;
    private _nonAdmitReasonsService: NonAdmitReasonsService;
    private readonly _accountsService: AccountsService;
    @observable({
        changeHandler: nameof<StatusUpdateForm>("hospitalizedCommunityLiaisonChanged")
    })
    public hospitalizedCommunityLiaison: ITypeaheadOptions;
    @observable({
        changeHandler: nameof<StatusUpdateForm>("hospitalizedFacilityChanged")
    })
    public hospitalizedFacility: ITypeaheadOptions;
    private _branchesService: BranchesService;
    @observable({
        changeHandler: nameof<StatusUpdateForm>("createHospiceReferralChanged")
    })
    public createHospiceReferral: boolean = false;
    @observable({
        changeHandler: nameof<StatusUpdateForm>("selectedBranchChanged")
    })
    public selectedBranch: string = "";
    @observable({
        changeHandler: nameof<StatusUpdateForm>("selectedNonAdmitReasonChanged")
    })
    public selectedNonAdmitReason: ITypeaheadOptions;
    public dischargeReasonEnum: IEnumResponse[];
    public hospitalizationReasonEnum: IEnumResponse[];
    public nonAdmitReasons: ITypeaheadOptions[];
    public roles: IEnumResponse[] = [];
    public patientStatusEnumMap: EnumMap;
    public lineOfServiceEnumMap: EnumMap = new EnumMap([]);
    public branchValidation: IValidateCustomElement = null;
    public hospiceBranches: IGetAllAgencyBranches[] = [];
    public isPalliativeCare: boolean = false;
    public nonAdmitReasonValidation: IValidateCustomElement = {
        required: true,
        displayName: "Non-Admit Reason"
    };

    @computedFrom(`${nameof<StatusUpdateForm>("selectedStatus")}`, `${nameof<StatusUpdateForm>("patientStatusEnum")}`)
    public get getPatientStatusDesc() {
        if (!!this.selectedStatus && !!this.patientStatusEnum) {
            let description = this.patientStatusEnum.find((status) => status.value === this.selectedStatus);
            return description?.name;
        } else {
            return "";
        }
    }

    @computedFrom(
        `${nameof<StatusUpdateForm>("selectedStatus")}`,
        `${nameof<StatusUpdateForm>("patientStatusEnumMap")}`
    )
    public get isHospitalizedSelected() {
        return this.selectedStatus === this.patientStatusEnumMap.getEnumValue("Hospitalized");
    }

    @computedFrom(
        `${nameof<StatusUpdateForm>("selectedStatus")}`,
        `${nameof<StatusUpdateForm>("patientStatusEnumMap")}`
    )
    public get isActiveSelected() {
        return this.selectedStatus === this.patientStatusEnumMap.getEnumValue("Active");
    }

    @computedFrom(
        `${nameof<StatusUpdateForm>("patient")}.${nameof<IGetPatientsSlimInfoResult>("status")}`,
        `${nameof<StatusUpdateForm>("patientStatusEnum")}`
    )
    public get patientStatusOptions() {
        this.patientStatusEnumMap = new EnumMap(this.patientStatusEnum);
        if (!!this.patientStatusEnumMap && this.patient.status) {
            let patientStatus = this.patientStatusEnumMap.getEnumName(this.patient.status);
            switch (patientStatus) {
                case "Pending":
                    return [this.patientStatusEnumMap.getEnumObjectByName("Non-Admitted")];
                case "Active":
                    let patientStatuses = [];
                    patientStatuses.push(this.patientStatusEnumMap.getEnumObjectByName("Deceased"));
                    patientStatuses.push(this.patientStatusEnumMap.getEnumObjectByName("Discharged"));
                    this.patient.isHospitalized
                        ? patientStatuses.unshift(this.patientStatusEnumMap.getEnumObjectByName("Active"))
                        : patientStatuses.push(this.patientStatusEnumMap.getEnumObjectByName("Hospitalized"));
                    return patientStatuses;
            }
        }
        return "";
    }

    public constructor(
        enumsService: EnumsService,
        vendorsService: VendorsService,
        usersService: UsersService,
        branchesService: BranchesService,
        accountsService: AccountsService,
        nonAdmitReasonsService: NonAdmitReasonsService
    ) {
        this._enumsService = enumsService;
        this._vendorsService = vendorsService;
        this._usersService = usersService;
        this._branchesService = branchesService;
        this._accountsService = accountsService;
        this._nonAdmitReasonsService = nonAdmitReasonsService;
    }

    public async attached() {
        this.clearPatientStatusFields();
        this.initValidation();
        this.dischargeReasonEnum = await this.getDischargeReasonOptions();
        this.roles = await this._enumsService.getAgencyRoles();
        this.hospitalizationReasonEnum = await this._enumsService.getHospitalizationReasons();
        let lineOfServiceEnum = await this._enumsService.getLineOfService();
        this.lineOfServiceEnumMap = new EnumMap(lineOfServiceEnum);
        this.hospiceBranches = await this.getHospiceBranches();
        this.isPalliativeCare = await this._accountsService.getIsPalliativeAccount();
        let nonAdmitReasons = await this._nonAdmitReasonsService.getNonAdmitReasonsSlim([this.providerId]);
        this.nonAdmitReasons = nonAdmitReasons?.map((reason) => ({ value: reason.id, name: reason.reason }));
    }

    public clearPatientStatusFields() {
        this.selectedStatus = 0;
        this.dischargePatient = { dischargeComments: "", dischargeReason: null, dischargeDate: "" };
        this.nonAdmitPatient = { nonAdmitComments: "", nonAdmitDate: "", nonAdmitReasonId: "" };
        this.deceasedPatient = { dateOfDeath: "", timeOfDeath: "", timeOfLastBreath: "" };
        this.hospitalizedPatient = new HospitalizedPatient();
        this.hospitalizedCommunityLiaison = null;
        this.hospitalizedFacility = null;
        this.hospitalDischargeDate = null;
    }

    public selectedStatusChanged(newValue: number, oldValue: number) {
        if (this.patientStatusEnum.length > 0) {
            switch (oldValue) {
                case getEnumValue("discharged", this.patientStatusEnum):
                    Object.assign(this.dischargePatient, {
                        dischargeComments: "",
                        dischargeReason: null,
                        dischargeDate: ""
                    });
                    break;
                case getEnumValue("non-admitted", this.patientStatusEnum):
                    Object.assign(this.nonAdmitPatient, {
                        nonAdmitComments: "",
                        nonAdmitDate: "",
                        nonAdmitReasonId: ""
                    });
                    break;
                case getEnumValue("deceased", this.patientStatusEnum):
                    Object.assign(this.deceasedPatient, { dateOfDeath: "", timeOfDeath: "", timeOfLastBreath: "" });
                    break;
            }
        }
    }

    public async hospitalizedFacilityChanged(facility: ITypeaheadOptions) {
        if (!facility) {
            return;
        }
        this.hospitalizedPatient.facilitySourceId = facility.value;
        if (!facility.value) {
            return;
        }
        let selectedFacility = await this._vendorsService.getVendorById(facility.value);
        this.hospitalizedCommunityLiaison = {
            name: selectedFacility.communityLiaisonName,
            value: selectedFacility.communityLiaisonId
        };
    }

    public hospitalizedCommunityLiaisonChanged(communityL: IUserResult): void {
        if (communityL) {
            this.hospitalizedPatient.communityLiaisonId = communityL.value as string;
        } else {
            this.hospitalizedPatient.communityLiaisonId = null;
        }
    }

    private initValidation() {
        ValidationRules.ensure((dischargePatient: IDischargePatientQuery) => dischargePatient.dischargeDate)
            .required()
            .withMessage("Discharge Date is required.")
            .ensure((dischargePatient: IDischargePatientQuery) => dischargePatient.dischargeReason)
            .required()
            .withMessage("Discharge Reason is required.")
            .on(this.dischargePatient);

        ValidationRules.ensure((deceasedPatient: IDeceasedPatientQuery) => deceasedPatient.dateOfDeath)
            .required()
            .withMessage("Date of Death is required.")
            .ensure((deceasedPatient: IDeceasedPatientQuery) => deceasedPatient.timeOfDeath)
            .required()
            .withMessage("Time of Death is required.")
            .on(this.deceasedPatient);

        ValidationRules.ensure((nonAdmitPatient: INonAdmitPatientQuery) => nonAdmitPatient.nonAdmitDate)
            .required()
            .withMessage("Non-Admit Date is required.")
            .on(this.nonAdmitPatient);
    }

    public async getDischargeReasonOptions() {
        if (this.isPalliativeCareView) {
            return await this._enumsService.getPalliativeDischargeReasons();
        }
        return await this._enumsService.getHospiceDischargeReasons();
    }

    public async getVendorList(filter: string, limit: number) {
        let facilityList = await this._vendorsService.getVendorByTerm({ name: filter, providerIds: [this.providerId] });
        return facilityList.map((facility) => ({
            name: facility.name,
            value: facility.id
        }));
    }

    public selectedNonAdmitReasonChanged(reason: ITypeaheadOptions) {
        if (!!reason) {
            this.nonAdmitPatient.nonAdmitReasonId = reason.value;
        } else {
            this.nonAdmitPatient.nonAdmitReasonId = null;
        }
    }

    public async getUsersByRole(filter: string, limit: number) {
        let users = await this._usersService.getUsersByRole(
            this.getUserQuery("Community Liaison Officer", true, filter)
        );
        return users.map((user) => ({
            name: `${formatName(user.firstName, user.lastName, "FN LN")}`,
            value: user.id
        }));
    }

    private getUserQuery(userRole: string, include: boolean, filter: string): IGetUsersByRoleQuery {
        let roleCode = this.roles.filter((role) => role.name === userRole)[0].value;
        return {
            role: [roleCode] as number[],
            include: include,
            term: filter,
            providerIds: [this.providerId]
        };
    }

    public async getHospiceBranches() {
        let branches = await this._branchesService.getAllAgencyBranches();
        let hospiceBranches = branches.filter(
            (branch: IGetAllAgencyBranches) =>
                branch.lineOfService === this.lineOfServiceEnumMap.getEnumValue("Hospice")
        );
        return hospiceBranches;
    }

    public createHospiceReferralChanged() {
        if (this.createHospiceReferral) {
            this.branchValidation = {
                required: true,
                displayName: "Branch"
            };
        } else {
            this.branchValidation = null;
            if (this.dischargePatient) {
                this.dischargePatient.referralLocationId = null;
            }
        }
    }

    public selectedBranchChanged(branch: string) {
        if (!branch) {
            return;
        }
        if (this.dischargePatient) {
            this.dischargePatient.referralLocationId = branch;
        }
    }

    public detached() {
        this.clearPatientStatusFields();
    }
}
