import { DefaultValueEnum } from "./default-value-enum";
import moment from "moment";

export class DefaultValueHandler {
    public handle(defaultValue: string) {
        switch (defaultValue) {
            case DefaultValueEnum.CurrentDate:
                return moment().format("MM/DD/YYYY");
            case DefaultValueEnum.CurrentTime:
                return moment().format("LT");
            default:
                return defaultValue;
        }
    }
}