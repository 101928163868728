import { valueConverter } from "aurelia-binding";
import { RouteConfig } from "aurelia-router";

@valueConverter("subRouteLink")
export class SubRouteLink {
    public toView(subRoute: RouteConfig, routeHref: string): string {
        const subRouteLink = subRoute.href;
        if (!!subRouteLink
            && (subRouteLink.includes("http://") || subRouteLink.includes("https://"))) {
            return subRouteLink;
        }
        if (!!subRouteLink) {
            return `/${subRouteLink}`;
        }
        return `${routeHref}/${subRoute.route}`;
    }
}
