import { BindingEngine, bindingMode, Disposable, observable, computedFrom } from "aurelia-binding";
import { autoinject, bindable } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { IAutoFillCheckParams, IGetPhysicianOigRequest, INpiPhysician } from "../../../interfaces/i-get-physician";
import { IPhoneNumber } from "../../../interfaces/i-phone-number";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { PhoneNumber } from "../../../models/phone-number";
import { Physician } from "../../../models/physician";
import { PhysiciansService } from "../../../services/physician-service";
import { LookupService } from "../../../services/lookup-service";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { EnumMap } from "../../../common/utilities/enum-map";
import { ProvidersService } from "../../../services/providers-service";
import { EnumsService } from "../../../services/enums-service";
import * as moment from "moment";
import { Moment } from "moment";
import { IGetIntegrationStatus } from "../../../services/i-providers-service";
import { IAmericanStates } from "../../../interfaces/lookup/i-american-states";
import { IGetUsersByRoleQuery, IUserResult } from "../../../interfaces/i-user";
import { UsersService } from "../../../services/users-service";

@autoinject
export class PhysicianForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public deliveryVendorEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerOptions: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public deliveryMethodEnumMap: EnumMap;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public deliveryMethodEnum: IEnumResponse[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<PhysicianForm>("selectedPhysicianChanged")
    })
    public selectedPhysician: ITypeaheadOptions;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public physician: Physician = new Physician();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    private _bindingEngine: BindingEngine;
    private _lookupService: LookupService;
    private _physicianService: PhysiciansService;
    private _observerSubscription: Disposable[] = [];
    private _providersService: ProvidersService;
    private _enumsService: EnumsService;
    private readonly _usersService: UsersService;
    @observable({
        changeHandler: nameof<PhysicianForm>("communityLiaisonChanged")
    })
    public communityLiaison: ITypeaheadOptions;
    public npiPhysicians: INpiPhysician[] = [];
    public usCountryCode: string = "";
    public statesList: IAmericanStates[] = [];
    public isCheckingOig: boolean = false;
    public isVerifyingPecos: boolean = false;
    public integrationStatus: IGetIntegrationStatus;
    public isDifferentFromAutoFill: IAutoFillCheckParams = {
        npiNumber: false,
        firstName: false,
        lastName: false
    };
    public preferredPhoneValidation: IValidateCustomElement = {
        required: true,
        displayName: "Phone Number",
        matches: true
    };
    public providerValidation: IValidateCustomElement = {
        required: true,
        message: "Associated Agency Provider is required"
    };
    public integrationTypeEnumMap: EnumMap = new EnumMap([]);
    public deliveryMethods: IEnumResponse[] = [];
    public deliveryVendors: IEnumResponse[] = [];
    public deliveryVendorEnumMap: EnumMap;
    public today: Moment = moment();
    public datePickerOptions: DatepickerOptions = {
        startDate: this.today.format("MM/DD/YYYY"),
        autoclose: true
    };
    public lastCheckOigQuery: IGetPhysicianOigRequest = null;
    public roles: IEnumResponse[] = [];

    public constructor(
        physicianService: PhysiciansService,
        lookupService: LookupService,
        bindingEngine: BindingEngine,
        providersService: ProvidersService,
        enumsService: EnumsService,
        usersService: UsersService
    ) {
        this._physicianService = physicianService;
        this._lookupService = lookupService;
        this._bindingEngine = bindingEngine;
        this._providersService = providersService;
        this._enumsService = enumsService;
        this._usersService = usersService;
    }

    public async attached() {
        this.deliveryMethods = this.deliveryMethodEnum;
        this.deliveryVendors = this.deliveryVendorEnum;
        this.deliveryVendorEnumMap = new EnumMap(this.deliveryVendorEnum);
        this.usCountryCode = await this._lookupService.getUnitedStatesCode();
        this.statesList = await this._lookupService.getAmericanStates({
            includeExcludedStates: true
        });
        this.initObservers();
        let integrationTypeEnum = await this._enumsService.getIntegratedService();
        this.integrationTypeEnumMap = new EnumMap(integrationTypeEnum);
        await this.providersChanged();
        await this.updateDeliveryMethods(this.physician?.npiNumber);
        this.roles = await this._enumsService.getAgencyRoles();
        if (!!this.physician.id) {
            let physicianInfo = await this._physicianService.getPhysicianById(this.physician.id);
            if (!!physicianInfo?.communityLiaisonId && !!physicianInfo?.communityLiaisonName) {
                this.communityLiaison = {
                    name: physicianInfo?.communityLiaisonName,
                    value: physicianInfo?.communityLiaisonId
                };
            }
        }
        // Initialize physician's primary and alternate phone numbers.
        this.initPhoneNumbers();
    }

    private initPhoneNumbers() {
        if (this.physician.phoneNumbers.length == 0) {
            let primaryPhoneNumber = new PhoneNumber();
            let alternatePhoneNumber = new PhoneNumber();
            primaryPhoneNumber.isPrimary = true;
            this.physician.phoneNumbers.push(primaryPhoneNumber);
            this.physician.phoneNumbers.push(alternatePhoneNumber);
        }
    }

    private initObservers() {
        this._observerSubscription.push(
            this._bindingEngine
                .propertyObserver(this.physician, nameof<Physician>("npiNumber"))
                .subscribe((newValue: string, oldValue: string) => {
                    if (newValue.length >= 10) {
                        this.updateDeliveryMethods(this.physician?.npiNumber);
                    }
                    if (this.selectedPhysician) {
                        if (this.selectedPhysician.value !== newValue) {
                            this.isDifferentFromAutoFill.npiNumber = true;
                        } else {
                            this.isDifferentFromAutoFill.npiNumber = false;
                        }
                    }
                    if (newValue && newValue.length >= 10 && newValue !== oldValue) {
                        this.verifyPecos();
                    }
                })
        );
        this._observerSubscription.push(
            this._bindingEngine
                .propertyObserver(this.physician, nameof<Physician>("firstName"))
                .subscribe((newValue: string, oldValue: string) => {
                    if (this.selectedPhysician) {
                        let physicianName = this.selectedPhysician.name.split(" - ")[1];
                        let names = physicianName.split(" ");
                        let firstName = names[0];
                        if (this.physician.firstName !== firstName) {
                            this.isDifferentFromAutoFill.firstName = true;
                        } else {
                            this.isDifferentFromAutoFill.firstName = false;
                        }
                    }
                    if (this.physician.firstName && this.physician.lastName && newValue !== oldValue) {
                        this.checkOig();
                    }
                })
        );
        this._observerSubscription.push(
            this._bindingEngine
                .propertyObserver(this.physician, nameof<Physician>("lastName"))
                .subscribe((newValue: string, oldValue: string) => {
                    if (this.selectedPhysician) {
                        let physicianName = this.selectedPhysician.name.split(" - ")[1];
                        let names = physicianName.split(" ");
                        let lastName = names[1];
                        if (names.length > 2) {
                            lastName = names[2];
                        }

                        if (this.physician.lastName !== lastName) {
                            this.isDifferentFromAutoFill.lastName = true;
                        } else {
                            this.isDifferentFromAutoFill.lastName = false;
                        }
                    }
                    if (this.physician.firstName && this.physician.lastName && newValue !== oldValue) {
                        this.checkOig();
                    }
                })
        );
        this._observerSubscription.push(
            this._bindingEngine.propertyObserver(this.physician, nameof<Physician>("deliveryMethod")).subscribe(() => {
                if (!this.physician?.deliveryMethod) {
                    return;
                }
                if (!this.deliveryMethodEnumMap.checkEnumValue("Electronic", this.physician?.deliveryMethod)) {
                    this.physician.deliveryVendor = null;
                }
            })
        );
        this._observerSubscription.push(
            this._bindingEngine.collectionObserver(this.physician?.associatedAgencyProviderIds).subscribe(() => {
                this.providersChanged();
            })
        );
    }

    public async providersChanged() {
        if (this.physician?.associatedAgencyProviderIds.length === 0) {
            return;
        }
        let forcura = this.integrationTypeEnumMap.getEnumValue("forcura");
        let integrationStatus = await this._providersService.getIntegrationStatus({
            providerIds: this.physician?.associatedAgencyProviderIds,
            integratedService: Number(forcura)
        });
        this.integrationStatus = integrationStatus;
        this.updateDeliveryMethods(this.physician?.npiNumber);
    }

    public async getPhysicianList(filter: string) {
        try {
            // NPI cannot be over 10 characters but allowing 2 more characters for exceptional cases
            if (filter.length < 12 && (!this.selectedPhysician || filter !== this.selectedPhysician.value)) {
                let response = await this._physicianService.getPhysiciansByNPI({
                    term: filter,
                    page: 1,
                    pageLength: 10
                });
                if (response.isSuccessful) {
                    this.npiPhysicians = response.data.items;
                    if (this.npiPhysicians) {
                        return this.npiPhysicians.map(
                            (value: INpiPhysician): ITypeaheadOptions => {
                                let physicianName = `${value.id} - ${value.providerFirstName}`;
                                if (value.providerMiddleName) {
                                    physicianName += ` ${value.providerMiddleName}`;
                                }
                                if (value.providerLastName) {
                                    physicianName += ` ${value.providerLastName}`;
                                }
                                return {
                                    name: physicianName,
                                    value: value.id
                                };
                            }
                        );
                    }
                }
            }
            return [];
        } catch (e) {
            console.error("Unable to Fetch");
            throw e;
        }
    }

    public selectedPhysicianChanged(item: ITypeaheadOptions, prevItem: ITypeaheadOptions) {
        // console.log("Selected Physician: ", { item, prevItem });
        if (item && this.npiPhysicians && this.npiPhysicians.length > 0) {
            let selectedNPI = this.npiPhysicians.filter((value: INpiPhysician) => value.id == item.value)[0];
            if (selectedNPI) {
                this.physician.firstName = selectedNPI.providerFirstName;
                this.physician.lastName = selectedNPI.providerLastName;
                this.physician.middleInitial = selectedNPI.providerMiddleName.charAt(0);
                this.physician.credentials = selectedNPI.providerCredentialText;
                this.physician.npiNumber = selectedNPI.id;
                this.physician.isPecosVerified = selectedNPI.isPecosVerified;
                this.physician.address.addressLine1 = selectedNPI.providerFirstLineBusinessPracticeLocationAddress;
                this.physician.address.addressLine2 = selectedNPI.providerSecondLineBusinessPracticeLocationAddress;
                this.physician.address.city = selectedNPI.providerBusinessPracticeLocationAddressCityName;
                this.physician.address.state = selectedNPI.providerBusinessPracticeLocationAddressStateName;
                this.physician.address.zipCode = selectedNPI.providerBusinessPracticeLocationAddressPostalCode;
                this.physician.address.country = this.usCountryCode;
                this.physician.phoneNumbers = [
                    {
                        number: selectedNPI.providerBusinessPracticeLocationAddressTelephoneNumber,
                        extension: "",
                        type: 1,
                        isPrimary: true
                    },
                    {
                        number: "",
                        extension: "",
                        type: 0,
                        isPrimary: false
                    }
                ];
                this.physician.faxNumber = selectedNPI.providerBusinessPracticeLocationAddressFaxNumber;
                // Using splice to empty associatedAgencyProviderIds array to keep observer subscription working
                this.physician.associatedAgencyProviderIds.splice(
                    0,
                    this.physician.associatedAgencyProviderIds.length - 1
                );
                this.checkOig();
            }
        }
    }

    public primaryNumberIndex(phoneNumbers: IPhoneNumber[]) {
        if (phoneNumbers) {
            return phoneNumbers.findIndex((e) => e.isPrimary);
        }
        return 0;
    }

    public alternateNumberIndex(phoneNumbers: IPhoneNumber[]) {
        if (phoneNumbers) {
            return phoneNumbers.findIndex((e) => !e.isPrimary);
        }
        return 0;
    }

    public async checkOig() {
        // to prevent multiple REST calls
        if (
            this.lastCheckOigQuery?.lastName === this.physician.lastName &&
            this.lastCheckOigQuery?.firstName === this.physician.firstName
        ) {
            return;
        }
        try {
            this.isCheckingOig = true;
            this.lastCheckOigQuery = {
                firstName: this.physician.firstName,
                lastName: this.physician.lastName
            };
            let result = await this._physicianService.checkPhysicianOig(this.lastCheckOigQuery);
            this.physician.isNotInExclusionList = result.data.isNotInExclusionList;
            this.isCheckingOig = false;
        } catch (e) {
            this.isCheckingOig = false;
        }
    }

    public async verifyPecos() {
        try {
            this.isVerifyingPecos = true;
            this.physician.isPecosVerified = await this._physicianService.verifyPecos(this.physician.npiNumber);
            this.isVerifyingPecos = false;
        } catch (e) {
            this.isVerifyingPecos = false;
        }
    }

    public async updateDeliveryMethods(npi: string) {
        this.deliveryMethods = this.deliveryMethodEnum;
        this.deliveryVendors = this.deliveryVendorEnum;
        if (!this.integrationStatus || !this.integrationStatus.isActive) {
            this.deliveryMethods = this.deliveryMethods.filter(
                (method) => method.value != this.deliveryMethodEnumMap.getEnumValue("Electronic")
            );
        }
        if (!this.integrationStatus || !this.integrationStatus.isSutureSignSupported) {
            this.deliveryVendors = this.deliveryVendors.filter(
                (v) => v.value != this.deliveryVendorEnumMap.getEnumValue("SutureSign")
            );
        }
        let physicianUser = await this._physicianService.getIsPhysicianUser(npi);
        let isPhysicianUser = physicianUser?.isPhysicianUser;
        if (!npi || !isPhysicianUser || !physicianUser?.credentials) {
            this.deliveryMethods = this.deliveryMethods.filter(
                (method) => method.value != this.deliveryMethodEnumMap.getEnumValue("IDG")
            );
            return;
        }
        let credentialsEnum = await this._enumsService.getCredentials();
        let credentialsEnumMap = new EnumMap(credentialsEnum);
        let validIDGCredentials = [
            credentialsEnumMap.getEnumValue("DO"),
            credentialsEnumMap.getEnumValue("NP"),
            credentialsEnumMap.getEnumValue("MD"),
            credentialsEnumMap.getEnumValue("PA")
        ];
        let canPhysicianUseIDG = physicianUser?.credentials.some((cred) => validIDGCredentials.includes(cred));
        if (!canPhysicianUseIDG) {
            this.deliveryMethods = this.deliveryMethods.filter(
                (method) => method.value != this.deliveryMethodEnumMap.getEnumValue("IDG")
            );
        }
    }

    private userToTypeahead(users: IUserResult[]): ITypeaheadOptions[] {
        return users.map((user) => ({
            name: `${user.firstName} ${user.lastName}`,
            value: user.id
        }));
    }

    private getUserQuery(userRole: string, include: boolean, filter: string): IGetUsersByRoleQuery {
        let roleCode = this.roles.filter((role) => role.name === userRole)[0].value;
        return {
            role: [roleCode] as number[],
            include: include,
            term: filter
        };
    }

    public async getUsersByRole(filter: string, limit: number) {
        return this.userToTypeahead(
            await this._usersService.getUsersByRole(this.getUserQuery("Community Liaison Officer", true, filter))
        );
    }

    public communityLiaisonChanged(communityL: IUserResult): void {
        if (communityL) {
            this.physician.communityLiaisonId = communityL.value as string;
            this.physician.communityLiaisonName = communityL.name as string;
        } else {
            this.physician.communityLiaisonId = null;
            this.physician.communityLiaisonName = null;
        }
    }

    public detached() {
        if (this._observerSubscription.length > 0) {
            this._observerSubscription.forEach((subscription: Disposable) => {
                subscription.dispose();
            });
        }
    }
}
