import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, computedFrom, customElement } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { IScoreMessageBehavior } from "../../../../interfaces/form-builder/i-behavior";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { FormBuilderEvent, NoteDataManager } from "../../note-data-manager";

@autoinject
@customElement("score-table-input")
export class ScoreTableInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    private _ea: EventAggregator;
    private _answerObservers: Subscription[] = [];
    public score: number = -1;
    public noteDataManager: NoteDataManager;

    @computedFrom(
        nameof<ScoreTableInput>("viewOptions"),
        nameof<ScoreTableInput>("score"),
    )
    public get scoreMessage(): string {
        let score = this.score;
        let matchingBehaviors = this.viewOptions?.behaviors
            .filter((behavior: IScoreMessageBehavior) =>
                behavior.minimum <= score && behavior.maximum >= score
            ) as IScoreMessageBehavior[];

        return matchingBehaviors.length > 0 ? matchingBehaviors[0].message : "";
    }

    public constructor(ea: EventAggregator, noteDataManager: NoteDataManager) {
        this._ea = ea;
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.updateScore();
        this._answerObservers.push(
            this._ea.subscribe(
                FormBuilderEvent.ModifiedDataUpdated,
                (modifiedProp: string) => {
                    this.handleModifiedDataChange(modifiedProp);
                }
            )
        );
        this._answerObservers.push(
            this._ea.subscribe(
                FormBuilderEvent.ModifiedDataPropDeleted,
                (modifiedProp: string) => {
                    this.handleModifiedDataChange(modifiedProp);
                }
            )
        );
    }

    private handleModifiedDataChange(modifiedProp: string) {
        let isMyQuestionModified = this.viewOptions.questions
            .some(question => question.name === modifiedProp);
        if (isMyQuestionModified) {
            this.updateScore();
        }
    }

    private updateScore() {
        this.score = -1;
        this.viewOptions.questions.forEach((question) => {
            let selectedScore = null;
            let questionName = question.name;
            let answer = this.noteDataManager.getValueFromModifiedData(questionName);

            if (!answer) {
                answer = this.noteDataManager.getValueFromModel(questionName);
            }

            if (Array.isArray(answer)) {
                selectedScore = this.findMultiScore(questionName, answer as string[]);
            } else {
                selectedScore = this.findScore(questionName, answer as string);
            }

            if (selectedScore !== null) {
                if (this.score == -1) {
                    this.score = selectedScore;
                } else {
                    this.score += selectedScore;
                }
            }
        });
        this.result = this.score > 0 ? String(this.score) : "0";
    }

    private findScore(questionName: string, value: string): number {
        let selectedScore: number = null;
        let selectedQuestion = this.viewOptions.questions
            .find((question) => question.name == questionName);
        selectedQuestion?.options
            .forEach((option) => {
                if (option.value == value) {
                    selectedScore = option.score;
                }
            });
        return selectedScore;
    }

    private findMultiScore(questionName: string, value: string[]): number {
        let selectedScore: number = null;
        let selectedQuestion = this.viewOptions.questions
            .find((question) => question.name == questionName);

        if (!!selectedQuestion) {
            value.forEach((selectedValue) => {
                selectedQuestion.options
                    .forEach((option) => {
                        if (option.value == selectedValue) {
                            selectedScore += option.score;
                        }
                    });
            });
        }
        return selectedScore;
    }

    public detached() {
        this._answerObservers?.forEach((answerObserver) => {
            answerObserver?.dispose();
        });
    }
}
