import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { IDrugInteractionResult } from "../interfaces/i-drug-interaction";
import { IBenefitPeriodForRecertResult } from "../interfaces/i-get-benefit-period";
import {
    IContactsOrder,
    IDeceasedPatientQuery,
    IDiagnosisSnapshotQuery,
    IDiagnosisSnapshotResult,
    IDischargePatientQuery,
    IExportPatientPayor,
    IGetAvailablePatientPayer,
    IGetPatientAdmissionDates,
    IGetPatientAdmissionInfo,
    IGetPatientAdmissionPeriods,
    IGetPatientBenefitPeriods,
    IGetPatientByPayorResult,
    IGetPatientDisciplineCategories,
    IGetPatientLastUsedMRN,
    IGetPatientPayerByName,
    IGetPatientPayerByNameQuery,
    IGetPatientPhotoResult,
    IGetPatientsByPayorItem,
    IGetPatientsByPayorQuery,
    IGetPatientsByPrimaryPayorQuery,
    IGetPatientSchedule,
    IGetPatientScheduleQuery,
    IGetPatientsLean,
    IGetPatientsLeanBilling,
    IGetPatientsLeanBillingQuery,
    IGetPatientsLeanBillingResult,
    IGetPatientsLeanBranchQuery,
    IGetPatientsLeanQuery,
    IGetPatientsLeanResult,
    IGetPatientsQuery,
    IGetPatientsResult,
    IGetPatientsSlimInfoResult,
    IHospitalDischargeQuery,
    IHospitalizedPatientQuery,
    ILevelOfCareCensus,
    INonAdmitPatientQuery,
    IPatientDaysOnService,
    IPatientDocument,
    IPatientLocation,
    IPatientPayorCount,
    IPatientsAdvanceDirectives,
    IPendingPatientReasonQuery,
    IRecertificationDueCount,
    IStatusCensus,
    IUpdatePatientAdmissionInfo,
    IUpdatePatientReferralDate,
    IUploadPatientDocumentQuery,
    IUploadPatientDocumentResult
} from "../interfaces/i-get-patient";
import {
    IAvailablePayorTypesByDateQuery,
    ICreatePatientPayerResult,
    IGetCurrentPatientPayerQuery,
    IGetPatientPayerQuery,
    IGetPatientPayerResult
} from "../interfaces/i-patient-payor";
import { IOpenOrder } from "../interfaces/i-plan-of-care";
import { ICreateResponse } from "../interfaces/i-response";
import {
    IGetServiceLocationQuery,
    IGetServiceLocations,
    IPatientServiceLocation
} from "../interfaces/i-service-locations";
import { IGetRugLevelsQuery, IRugLevel } from "../interfaces/patients/i-rug-level";
import { ICreateOrderParams } from "../interfaces/task/i-create-order";
import { AuthorizedContact } from "../models/authorized-contact";
import { Patient } from "../models/patient";
import { HospicePatient } from "../pages/create/create-patient/models/hospice-patient";
import { IPaginationNew } from "./../interfaces/i-pagination";
import {
    ICreatePatientContact,
    IGetPatientContactQuery,
    IUpdatePatientContact
} from "./../interfaces/i-patient-contact";
import { IAdmitPatientQuery } from "./../interfaces/patients/i-admit-patient";
import { IGetNarrative } from "../interfaces/form-builder/i-load-narrative";
import { PatientPayor } from "./../models/patient-payor";
import { PalliativePatient } from "./../pages/create/create-palliative-patient/models/palliative-patient";
import {
    IGetAvailableTaskResult,
    IGetAvailableTasksQuery,
    IGetLevelCensusQuery,
    IGetPatientDiagnosisCodes,
    IGetPatientDocumentsQuery,
    IGetPatientPayerByTask,
    IGetPatientsAdvancedDirectivesQuery,
    IGetPatientsByNameOrMrn,
    IGetPatientsLocationQuery,
    IGetPatientDaysOnServiceQuery,
    IUpdatePatientPayorResponse,
    IGetLevelOfCareHistory,
    IAdmissionCountByBenefitPeriod,
    IWoundLog,
    IWoundLogGetQuery,
    IGetAdmissionsByBenefitPeriodQuery,
    IGetDeletedTasksQuery,
    IDeletedTasksList,
    IBenefitPeriodDashboardCount
} from "./i-patient-service";

@autoinject()
export class PatientsService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/patients";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createPatient(newPatient: Patient) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("POST").useBody(newPatient).fetch();
    }

    public async getPatient(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}`).fetch<HospicePatient>();
    }

    public async getPalliativePatient(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/palliative-care/${patientId}`).fetch<PalliativePatient>();
    }

    public async updateHospicePatient(patient: Patient) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("PUT").useBody(patient).fetch();
    }

    public async updatePalliativePatient(patient: PalliativePatient) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care`)
            .useMethod("PUT")
            .useBody(patient)
            .fetch();
    }

    public async loadHospiceReferral(patientId: string, referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/hospice/${patientId}/load-referral`)
            .useMethod("PUT")
            .useBody({
                referralId
            })
            .fetchNoContent();
    }

    public async loadPalliativeReferral(patientId: string, referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care/${patientId}/load-referral`)
            .useMethod("PUT")
            .useBody({
                referralId
            })
            .fetchNoContent();
    }

    public async admitPatient(patientId: string, query: IAdmitPatientQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/admit`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getPatientByPage(query: IGetPatientsQuery) {
        return await this._httpClient.build(`${this._baseUrl}`).useQueryString(query).fetch<IGetPatientsResult>();
    }

    public async getPatientsLean(query: IGetPatientsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim`)
            .useQueryString(query)
            .fetch<IGetPatientsLeanResult>();
    }

    public async getPatientsLeanForClaimsHistory(query: IGetPatientsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim/claims-history`)
            .useQueryString(query)
            .fetch<IGetPatientsLeanResult>();
    }

    public async getPatientsLeanBilling(query: IGetPatientsLeanBillingQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim/billing`)
            .useQueryString(query)
            .fetch<IGetPatientsLeanBillingResult>();
    }

    public async getPatientsLeanByLocation(query: IGetPatientsLeanBranchQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/leanbynamewithbranches`)
            .useQueryString(query)
            .fetch<IGetPatientsLean[]>();
    }

    public async getPatientSlim(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/slim`).fetch<IGetPatientsSlimInfoResult>();
    }

    public async getPatientsByNameOrMrn(term: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/patient-by-name-mrn`)
            .useQueryString({ term })
            .fetch<IGetPatientsByNameOrMrn[]>();
    }

    public async getPatientSlimBilling(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/slim/billing`)
            .fetch<IGetPatientsLeanBilling>();
    }

    public async getPatientSchedule(patientId: string, query: IGetPatientScheduleQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/schedule`)
            .useQueryString(query)
            .fetch<IGetPatientSchedule[]>();
    }

    public async getPatientDocuments(patientId: string, query: IGetPatientDocumentsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/documents`)
            .useQueryString(query)
            .fetch<IPatientDocument[]>();
    }

    public async uploadDocument(patientId: string, query: IUploadPatientDocumentQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/documents`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IUploadPatientDocumentResult>();
    }

    public async deleteDocument(patientId: string, documentId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/documents/${documentId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getPatientBenefitPeriods(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/benefitperiods`)
            .fetch<IGetPatientBenefitPeriods[]>();
    }

    public async getPatientAdmissionDates(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/admissiondates`)
            .fetch<IGetPatientAdmissionDates>();
    }

    public async getPatientsDrugInteractionReport(patientId: string, medicationGroup: number = 0) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/checkinteractions/`)
            .useQueryString({
                medicationGroup
            })
            .fetch<IDrugInteractionResult[]>();
    }

    public async getPatientsDrugInteractionDetailedReport(patientId: string, medicationGroup: number = 0) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/checkinteractions/detailed`)
            .useQueryString({
                medicationGroup
            })
            .fetch<IDrugInteractionResult[]>();
    }

    public async uploadPatientPhoto(patientId: string, assetId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/photo`)
            .useMethod("PUT")
            .useBody(assetId)
            .fetchNoContent();
    }

    public async getPatientPhoto(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/photo`).fetch<IGetPatientPhotoResult>();
    }

    public async deletePatientPhoto(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/photo`).useMethod("DELETE").fetchNoContent();
    }

    public async getLevelOfCareCensus(query: IGetLevelCensusQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/dashboard/levelofcare`)
            .useQueryString(query)
            .fetch<ILevelOfCareCensus[]>();
    }

    public async getStatusCensus(locationIds?: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/dashboard/status`)
            .useQueryString({ locationIds })
            .fetch<IStatusCensus[]>();
    }

    public async getPatientsLocation(query: IGetPatientsLocationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/dashboard/locations`)
            .useQueryString(query)
            .fetch<IPatientLocation[]>();
    }

    public async getPatientDaysOnServiceCount(query: IGetPatientDaysOnServiceQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/patient-days-on-service-count`)
            .useQueryString(query)
            .fetch<IPatientDaysOnService>();
    }

    public async getPatientsAdvancedDirectives(query: IGetPatientsAdvancedDirectivesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/dashboard/advancedirectives`)
            .useQueryString(query)
            .fetch<IPatientsAdvanceDirectives[]>();
    }

    public async getPatientsLeanByIds(ids: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/leanbyids`)
            .useQueryString({ ids })
            .fetch<IGetPatientsLean[]>();
    }

    public async getRecertificationsDueCount(locationIds?: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/recertificationsdue`)
            .useQueryString({ locationIds })
            .fetch<IRecertificationDueCount[]>();
    }

    public async printDrugInteractionReport(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/printinteractions`).fetchBlob();
    }

    public async getPatientPayorCount(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/primaryPayorCount`)
            .useQueryString({ locationIds })
            .fetch<IPatientPayorCount[]>();
    }

    public async getServiceLocationsByPage(patientId: string, query: IGetServiceLocationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/servicelocations`)
            .useQueryString(query)
            .fetch<IGetServiceLocations>();
    }

    public async createServiceLocation(patientId: string, location: IPatientServiceLocation) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/servicelocations`)
            .useMethod("POST")
            .useBody(location)
            .fetchNoContent();
        // .fetch<ICreatePatientLocationResult>();
    }

    public async updateServiceLocation(patientId: string, locationId: string, location: IPatientServiceLocation) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/servicelocations/${locationId}`)
            .useMethod("PUT")
            .useBody(location)
            .fetch<ICreateResponse>();
    }

    public async deleteServiceLocation(patientId: string, locationId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/servicelocations/${locationId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async updatePatientStatusToDeceased(patientId: string, query: IDeceasedPatientQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/status/deceased`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async updatePatientStatusToNonAdmit(patientId: string, query: INonAdmitPatientQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/status/nonAdmit`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async updatePatientStatusToDischarge(patientId: string, query: IDischargePatientQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/status/discharge`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async updatePatientStatusToHospitalized(patientId: string, query: IHospitalizedPatientQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/status/hospitalize`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async updateHospitalizedPatientToActive(patientId: string, query: IHospitalDischargeQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/status/discharge-hospitalized`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getPatientsByPrimaryPayor(query: IGetPatientsByPrimaryPayorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/by/primaryPayor`)
            .useQueryString(query)
            .fetch<IGetPatientByPayorResult>();
    }

    public async exportPatientByPayorExcel(query: IExportPatientPayor) {
        return await this._httpClient.build(`${this._baseUrl}/by/primaryPayor/xlsx`).useQueryString(query).fetchBlob();
    }

    public async getPatientDiagnosisSnapshots(patientId: string, query: IDiagnosisSnapshotQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/DiagnosesSnapshot`)
            .useQueryString(query)
            .fetch<IDiagnosisSnapshotResult>();
    }

    public async printDiagnosisSnapshot(patientId: string, snapshotId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/snapshot/${snapshotId}/print`).fetchBlob();
    }

    public async getOpenOrders(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/openorders`).fetch<IOpenOrder[]>();
    }

    public async createPhysicianOrder(patientId: string, query: ICreateOrderParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/order`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateResponse>();
    }

    public async getPatientByPayer(payerId: string, query?: IGetPatientsByPayorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/by/payor/${payerId}`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetPatientsByPayorItem>>();
    }

    public async getBenefitPeriodsForRecert(patientId: string, taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/benefitperiods/${taskId}/forrecert`)
            .fetch<IBenefitPeriodForRecertResult>();
    }

    public async printFacesheet(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/print/facesheet`).fetchBlob();
    }

    public async getPatientPayors(patientId: string, query: IGetPatientPayerQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/payers`)
            .useQueryString(query)
            .fetch<IGetPatientPayerResult>();
    }

    public async getCurrentPatientPayors(patientId: string, query: IGetCurrentPatientPayerQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/currentpayers`)
            .useQueryString(query)
            .fetch<IGetPatientPayerResult>();
    }

    public async getAvailablePayorTypesByDate(patientId: string, query: IAvailablePayorTypesByDateQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/payers/by-date`)
            .useQueryString(query)
            .fetch<IGetAvailablePatientPayer[]>();
    }

    public async createPatientPayors(patientId: string, patientPayer: PatientPayor) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/payers`)
            .useMethod("POST")
            .useBody(patientPayer)
            .fetch<ICreatePatientPayerResult>();
    }

    public async updatePatientPayors(payerId: string, patientPayer: PatientPayor) {
        return await this._httpClient
            .build(`${this._baseUrl}/payers/${payerId}`)
            .useMethod("PUT")
            .useBody(patientPayer)
            .fetch<IUpdatePatientPayorResponse>();
    }

    public async deletePatientPayors(payerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/payers/${payerId}`).useMethod("DELETE").fetchNoContent();
    }

    public async getRugLevelsByPage(patientId: string, query: IGetRugLevelsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/ruglevels`)
            .useQueryString(query)
            .fetch<IPaginationNew<IRugLevel>>();
    }

    public async createRugLevel(patientId: string, rugLevel: IRugLevel) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/ruglevels`)
            .useMethod("POST")
            .useBody(rugLevel)
            .fetch<ICreatePatientPayerResult>();
    }

    public async updateRugLevel(patientId: string, rugLevelId: string, rugLevel: IRugLevel) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/ruglevel/${rugLevelId}`)
            .useMethod("PUT")
            .useBody(rugLevel)
            .fetchNoContent();
    }

    public async deleteRugLevel(patientId: string, rugLevelId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/ruglevel/${rugLevelId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async validateRoomAndBoard(patientId: string, effectiveDate: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/has-room-and-board`)
            .useQueryString({ effectiveDate })
            .fetch<boolean>();
    }

    public async getPatientAdmissionPeriods(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/admissionperiods`)
            .fetch<IGetPatientAdmissionPeriods[]>();
    }

    public async getPatientAdmissionPeriodsNoPermission(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/admissionperiods-lookup`)
            .fetch<IGetPatientAdmissionPeriods[]>();
    }

    public async getPatientLastUsedMrn() {
        return await this._httpClient.build(`${this._baseUrl}/last-patient-mrn`).fetch<IGetPatientLastUsedMRN>();
    }

    public async getPatientDisciplineCategories() {
        return await this._httpClient
            .build(`${this._baseUrl}/patient-discipline-categories`)
            .fetch<IGetPatientDisciplineCategories>();
    }

    public async getPatientPayerByName(patientId: string, query: IGetPatientPayerByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/payers/by-name`)
            .useQueryString(query)
            .fetch<IGetPatientPayerByName[]>();
    }

    // Contact Endpoints
    public async getContacts(id: string, query?: IGetPatientContactQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/contacts-fe`)
            .useQueryString(query)
            .fetch<AuthorizedContact[]>();
    }

    public async addContact(patientId: string, contact: AuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/authorized-contact`)
            .useMethod("POST")
            .useBody(contact)
            .fetch<ICreatePatientContact>();
    }

    public async updateContact(contactInformation: AuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${contactInformation.id}`)
            .useMethod("PUT")
            .useBody(contactInformation)
            .fetch<IUpdatePatientContact>();
    }

    public async deleteContact(contactId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${contactId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async reorderContacts(contactsOrder: IContactsOrder[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/order-contacts`)
            .useMethod("PUT")
            .useBody({ contactsOrder: contactsOrder })
            .fetch<{}>();
    }

    public async getAvailableTasks(patientId: string, query: IGetAvailableTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/available-tasks`)
            .useQueryString(query)
            .fetch<IGetAvailableTaskResult[]>();
    }

    public async getPatientPayersByTask(patientId: string, taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/payers/by-visit`)
            .useQueryString({ taskId })
            .fetch<IGetPatientPayerByTask[]>();
    }

    public async getPatientDiagnosisCodes(patientId: string, showOnlyActive: boolean) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/diagnosis-codes`)
            .useQueryString({ showOnlyActive })
            .fetch<IGetPatientDiagnosisCodes[]>();
    }

    public async updatePendingPatientReason(patientId: string, request: IPendingPatientReasonQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/pending-reason`)
            .useBody(request)
            .useMethod("PUT")
            .fetchNoContent();
    }

    public async getRoomAndBoardAuthorization(patientId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${patientId}/has-payer-authorization`).fetch<boolean>();
    }

    public async getLevelOfCareHistory(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/admissions/level-of-care`)
            .fetch<IGetLevelOfCareHistory[]>();
    }

    public async getAdmissionCountByBenefitPeriod(query: IGetAdmissionsByBenefitPeriodQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/admission-count`)
            .useQueryString(query)
            .fetch<IAdmissionCountByBenefitPeriod[]>();
    }

    // for patient status == active, deceased or discharged
    public async updateReferralDate(patientId: string, query: IUpdatePatientReferralDate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/referral-date`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getWounds(query: IWoundLogGetQuery): Promise<IPaginationNew<IWoundLog>> {
        return await this._httpClient
            .build(`${this._baseUrl}/wound-info`)
            .useQueryString(query)
            .fetch<IPaginationNew<IWoundLog>>();
    }

    public async getDeletedTasks(patientId: string, query: IGetDeletedTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/deleted-tasks/list`)
            .useQueryString(query)
            .fetch<IPaginationNew<IDeletedTasksList>>();
    }

    public async exportWoundsExcel(query: IWoundLogGetQuery) {
        return await this._httpClient.build(`${this._baseUrl}/wound-info/xlsx`).useQueryString(query).fetchBlob();
    }

    public async getBenefitPeriodCounts(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/dashboard/benefitPeriodCount`)
            .useQueryString({ locationIds })
            .fetch<IBenefitPeriodDashboardCount[]>();
    }

    public async loadNarrativeByTask(taskId: string) {
        return await this._httpClient.build(`${this._baseUrl}/narrative-by-task/${taskId}`).fetch<IGetNarrative>();
    }

    public async updatePatientAdmissionInfo(patientId: string, query: IUpdatePatientAdmissionInfo) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/prior-hospice`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getAdmissionInfo(patientId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/admission-info`)
            .fetch<IGetPatientAdmissionInfo>();
    }
}
