import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IGetBehavior } from "../../../../interfaces/form-builder/i-behavior";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IValidation } from "../../../../interfaces/form-builder/i-validation";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { FetchDataBehaviorHandler } from "../../behavior-handlers/fetch-data-behavior-handler";
import { NoteDataManager } from "../../note-data-manager";
import { BehaviorTypesEnum } from "../../behavior-handlers/behavior-types-enum";

@customElement("multi-select-get-all-dropdown")
@autoinject
export class MultiSelectGetAllDropdown {
    // Input fields
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryData: any[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    private _ignoreTagsChange: boolean = false;
    private _ignoreResultChange: boolean = false;
    private _fetchDataBehaviorHandler: FetchDataBehaviorHandler;
    private _patientId: string = "";
    @observable({
        changeHandler: nameof<MultiSelectGetAllDropdown>("tagsResultChanged")
    })
    public tagsResult: ITypeaheadOptions[] = [];
    public isLoading: boolean = true;
    public tagsOptions: ITypeaheadOptions[] = [];
    public validation: IValidateCustomElement;
    public noteDataManager: NoteDataManager;

    public constructor(fetchDataBehavior: FetchDataBehaviorHandler, noteDataManager: NoteDataManager) {
        this._fetchDataBehaviorHandler = fetchDataBehavior;
        this.noteDataManager = noteDataManager;
    }

    public bind() {
        this._patientId = this.noteDataManager.getPatientId();
    }

    public async attached() {
        await this.getList();
        this.initValidation();
    }

    public resultChanged(newValue: string[], oldValue: string[]) {
        if (oldValue !== undefined && newValue && newValue.length > 0) {
            this.initTypeAheadResult();
        }
    }

    public secondaryDataChanged(newValue: string[], oldValue: string[]) {
        if (oldValue !== undefined && newValue && newValue.length > 0) {
            this.initTypeAheadResult();
        }
    }

    private initTypeAheadResult() {
        if (this._ignoreResultChange) {
            this._ignoreResultChange = false;
            return;
        }
        if (this.viewOptions) {
            this._ignoreTagsChange = true;
            this.tagsResult = this.tagsOptions.filter(
                (option) => this.result.findIndex((result) => option.value == result) > -1
            );
        }
    }

    public tagsResultChanged(newValue: ITypeaheadOptions[]) {
        if (this._ignoreTagsChange) {
            this._ignoreTagsChange = false;
            return;
        }
        if (!!newValue) {
            this._ignoreResultChange = true;
            this.result = newValue.map((val) => val.value);
            this.secondaryData = newValue.map((val) => val.name);
        }
    }

    private async getList() {
        try {
            this.isLoading = true;
            let getBehavior = this.viewOptions.behaviors.find(
                (behavior) => behavior.behaviorType === BehaviorTypesEnum.Get
            );
            if (!getBehavior) {
                console.error("Get Behavior not found for multi-select-get-all-dropdown");
                return;
            }
            let optionValues = await this._fetchDataBehaviorHandler.getList(
                getBehavior as IGetBehavior,
                this._patientId
            );
            this.tagsOptions = optionValues as ITypeaheadOptions[];
            this.initTypeAheadResult();
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    private initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IValidation) => {
            if (rule.validationType === FormValidationEnum.RequiredArray) {
                this.validation = {
                    required: true,
                    displayName: this.viewOptions.name,
                    message: rule.message
                };
            }
        });
    }
}
