import { autoinject } from "aurelia-framework";

import { IPermissionBehavior } from "../../../interfaces/form-builder/i-behavior";
import { PermissionManager } from "./../../../common/utilities/permission-manager";

@autoinject
export class PermissionBehaviorHandler {
    private _permissionManager: PermissionManager;

    public constructor(permissionManager: PermissionManager) {
        this._permissionManager = permissionManager;
    }

    public handle(behavior: IPermissionBehavior) {
        if (!behavior || !this._permissionManager) {
            return true;
        }
        return this._permissionManager.checkPermission(behavior.permissions, behavior.checkAll);
    }
}
