import "./patient-quick-links.scss";

import { autoinject, bindable, bindingMode, containerless } from "aurelia-framework";
import { Router } from "aurelia-router";

import { PermissionManager } from "../../../common/utilities/permission-manager";
import { ClinicalPermissionEnum } from "../../../enums/clinical-permission-enum";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { viewInsurancePayorsPermission } from "../../../pages/list/list-route-permission";
import { AccountsService } from "../../../services/accounts-service";

@containerless
@autoinject
export class PatientQuickLinks {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string;
    private readonly _permissionManager: PermissionManager;
    private readonly _accountsService: AccountsService;
    private _router: Router;
    public isPalliativeCareView: boolean = false;
    public hasViewAllergyPermission: boolean = false;
    public hasViewEmarPermission: boolean = false;
    public hasViewInsurancePayerPermission: boolean = false;
    public hasViewBereavementPermission: boolean = false;

    public constructor(permissionManager: PermissionManager, accountsService: AccountsService, router: Router) {
        this._permissionManager = permissionManager;
        this._accountsService = accountsService;
        this._router = router;
    }

    public async attached() {
        this.isPalliativeCareView = await this._accountsService.getIsPalliativeAccount();
        this.hasViewAllergyPermission = this._permissionManager
            .checkPermission([{
                resource: ClinicalPermissionEnum.Allergies,
                action: PermissionActionEnum.View
            }]);
        this.hasViewEmarPermission = this._permissionManager
            .checkPermission([{
                resource: ClinicalPermissionEnum.eMAR,
                action: PermissionActionEnum.View
            }]);
        this.hasViewInsurancePayerPermission = this._permissionManager.checkPermission(viewInsurancePayorsPermission);
        this.hasViewBereavementPermission = this._permissionManager
            .checkPermission([{
                resource: ClinicalPermissionEnum.Bereavement,
                action: PermissionActionEnum.View
            }]);
    }
}
