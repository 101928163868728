import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import {
    CustomMedicationKeyWords,
    DmeKeyWords,
    PayorKeyWords,
    PharmacyKeyWords,
    PhysicianKeyWords,
    SuppliesKeyWords,
    VendorKeyWords
} from "../create/create-routes";
import { InquiryKeywords } from "../patients/patients-routes";
import {
    editVendorPermission,
    viewCustomMedicationPermission,
    viewDmePermission,
    viewDocumentTypePermission,
    viewEmployeePermission,
    viewInfectionLogPermission,
    viewInquiryPermission,
    viewInsurancePayorsPermission,
    viewPharmacyPermission,
    viewPhysicianPermission,
    viewReferralPermission,
    viewSupplyPermission,
    viewTemplatePermission,
    viewVendorPermission,
    viewNonAdmitReasons,
    viewVolunteerActivitiesPermission,
    viewNonPatientActivities
} from "./list-route-permission";

const CommonKeywords = ["roster", "formulary", "create", "add"];

export const ListRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "physician-list"
    },
    {
        name: "inquiry-list",
        route: "inquiry-list",
        href: "list/inquiry-list",
        moduleId: PLATFORM.moduleName("./inquiry-list/inquiry-list", "inquiry-list"),
        nav: true,
        title: "Inquiries",
        settings: {
            menuName: "Inquiries",
            keywords: CommonKeywords.concat(InquiryKeywords),
            permission: viewInquiryPermission
        }
    },
    {
        name: "referral-list",
        route: "referral-list",
        href: "list/referral-list",
        moduleId: PLATFORM.moduleName("./referral-list/referrals", "referrals"),
        nav: true,
        title: "Referrals",
        settings: {
            menuName: "Referrals",
            keywords: CommonKeywords,
            permission: viewReferralPermission
        }
    },
    {
        name: "physician-list",
        route: "physician-list",
        href: "list/physician-list",
        moduleId: PLATFORM.moduleName("./physician-list/physician-list", "physician-list"),
        nav: true,
        title: "Physicians",
        settings: {
            menuName: "Physicians",
            keywords: CommonKeywords.concat(PhysicianKeyWords),
            permission: viewPhysicianPermission
        }
    },
    {
        name: "payer-list",
        route: "payer-list",
        href: "list/payer-list",
        title: "Insurance/Payers",
        moduleId: PLATFORM.moduleName("./payor-list/payor-list", "payor-list"),
        nav: true,
        settings: {
            menuName: "Insurance/Payers",
            keywords: CommonKeywords.concat(PayorKeyWords),
            permission: viewInsurancePayorsPermission
        }
    },
    {
        name: "users-list",
        route: "user-list",
        href: "list/user-list",
        moduleId: PLATFORM.moduleName("../employees/user-list/user-list", "user-list"),
        nav: true,
        title: "Users",
        settings: {
            menuName: "Users",
            keywords: ["roster", "employee", "user"],
            permission: viewEmployeePermission
        }
    },
    {
        name: "pharmacy-list",
        route: "pharmacy-list",
        href: "list/pharmacy-list",
        title: "Pharmacies",
        moduleId: PLATFORM.moduleName("./pharmacy-list/pharmacy-list", "pharmacy-list"),
        nav: true,
        settings: {
            menuName: "Pharmacies",
            keywords: CommonKeywords.concat(PharmacyKeyWords),
            permission: viewPharmacyPermission
        }
    },
    {
        name: "custom-medications",
        route: "custom-medications",
        href: "list/custom-medications",
        title: "Custom Medications",
        moduleId: PLATFORM.moduleName("./custom-medications/custom-medications", "custom-medications"),
        nav: true,
        settings: {
            menuName: "Custom Medications",
            keywords: CommonKeywords.concat(CustomMedicationKeyWords),
            permission: viewCustomMedicationPermission
        }
    },
    {
        name: "dme-list",
        route: "dme-list",
        href: "list/dme-list",
        moduleId: PLATFORM.moduleName("./dme-list/dme-list", "dme-list"),
        nav: true,
        title: "DME",
        settings: {
            menuName: "DME",
            keywords: CommonKeywords.concat(DmeKeyWords),
            permission: viewDmePermission
        }
    },
    {
        name: "vendor-list",
        route: "vendor-list",
        href: "list/vendor-list",
        moduleId: PLATFORM.moduleName("./vendor-list/vendor-list", "vendor-list"),
        nav: true,
        title: "Vendors",
        settings: {
            menuName: "Vendors",
            keywords: CommonKeywords.concat(VendorKeyWords),
            permission: viewVendorPermission
        }
    },
    {
        name: "supplies-list",
        route: "supplies-list",
        href: "list/supplies-list",
        moduleId: PLATFORM.moduleName("./supplies-list/supplies-list", "supplies-list"),
        nav: true,
        title: "Supplies",
        settings: {
            menuName: "Supplies",
            keywords: CommonKeywords.concat(SuppliesKeyWords),
            permission: viewSupplyPermission
        }
    },
    {
        name: "document-types",
        route: "document-types",
        href: "list/document-types",
        moduleId: PLATFORM.moduleName("./document-types/document-types", "document-types"),
        nav: true,
        title: "Document Types",
        settings: {
            menuName: "Document Types",
            keywords: CommonKeywords.concat(["form", "document", "scan", "upload"]),
            permission: viewDocumentTypePermission
        }
    },
    {
        name: "infection-log",
        route: "infection-log",
        href: "list/infection-log",
        moduleId: PLATFORM.moduleName("./infection-log/infection-log", "infection-log"),
        nav: true,
        title: "Infection Log",
        settings: {
            menuName: "Infection Log",
            keywords: CommonKeywords.concat(["infection", "log", "report", "form"]),
            permission: viewInfectionLogPermission
        }
    },
    {
        name: "adjustments-list",
        route: "adjustments-list",
        href: "list/adjustments-list",
        moduleId: PLATFORM.moduleName("./adjustments-list/adjustments-list", "adjustments-list"),
        nav: true,
        title: "Adjustments",
        settings: {
            menuName: "Adjustments"
        }
    },
    {
        name: "template-list",
        route: "template-list",
        href: "list/template-list",
        moduleId: PLATFORM.moduleName("./template-list/template-list", "template-list"),
        nav: true,
        title: "Templates",
        settings: {
            menuName: "Templates",
            permission: viewTemplatePermission
        }
    },
    {
        name: "teams-list",
        route: "teams-list",
        href: "list/teams-list",
        moduleId: PLATFORM.moduleName("./teams-list/teams-list", "teams-list"),
        nav: true,
        title: "Teams",
        settings: {
            menuName: "Teams"
        }
    },
    {
        name: "non-patient-activities",
        route: "non-patient-activities",
        title: "Non-Patient Activities",
        href: "list/non-patient-activities",
        nav: true,
        moduleId: PLATFORM.moduleName("./non-patient-activities/non-patient-activities", "non-patient-activities"),
        settings: {
            menuName: "Non-Patient Activities",
            permission: viewNonPatientActivities
        }
    },
    {
        name: "volunteer-activities-list",
        route: "volunteer-activities-list",
        href: "list/volunteer-activities-list",
        moduleId: PLATFORM.moduleName(
            "./volunteer-activities-list/volunteer-activities-list",
            "volunteer-activities-list"
        ),
        nav: true,
        title: "Volunteer Activities",
        settings: {
            menuName: "Volunteer Activities",
            permission: viewVolunteerActivitiesPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "vendor-edit",
        route: "vendor-edit/:id?",
        moduleId: PLATFORM.moduleName("../create/create-vendor/create-vendor", "create-vendor"),
        href: "list/vendor-edit/load",
        title: "Edit Vendor",
        settings: {
            permission: editVendorPermission
        }
    },
    {
        name: "payer-edit",
        route: "payer-edit/:id?",
        moduleId: PLATFORM.moduleName("../create/create-payor/create-payor", "create-payor"),
        href: "list/payer-edit/load",
        title: "Edit Insurance/Payer"
    },
    {
        name: "medicare-edit",
        route: "medicare-edit/:id?",
        moduleId: PLATFORM.moduleName("./payor-list/medicare-edit", "medicare-edit"),
        href: "list/medicare-edit/load",
        title: "Edit Medicare"
    },
    {
        name: "invite-user",
        route: "invite-user",
        title: "Invite User",
        moduleId: PLATFORM.moduleName("./invite-user/invite-user", "invite-user"),
        settings: {
            menuName: "Invite User"
        }
    },
    {
        name: "non-admit-reasons",
        route: "non-admit-reasons",
        title: "Non Admit Reasons",
        href: "list/non-admit-reasons",
        nav: true,
        moduleId: PLATFORM.moduleName("./non-admit-reasons/non-admit-reasons", "non-admit-reasons"),
        settings: {
            menuName: "Non-Admit Reasons",
            permission: viewNonAdmitReasons
        }
    }
];
