import { valueConverter } from "aurelia-framework";

@valueConverter("formatSsn")
export class FormatSsn {
    public toView(value: string): string {
        let input: string = value;
        if (value) {
            input = input.replace(/^(\d{3})\s?\-?\s?(\d+)$/, "$1-$2");
            input = input.replace(/^(\d{3})\s?\-?\s?(\d{2})\s?\-?\s?(\d+)$/, "$1-$2-$3");
        }
        return input;
    }

    public fromView(value: string) {
        return value.replace(/^(\d{3})\-(\d{2})\-(\d{4})$/, "$1$2$3");
    }
}
