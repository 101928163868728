import { autoinject, bindable, bindingMode, containerless, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";

import nameof from "../../../common/nameof";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { Template } from "../../../models/template";
import { viewTemplatePermission } from "../../../pages/list/list-route-permission";
import { TemplateService } from "../../../services/template-service";

@autoinject
@containerless
export class CommentInput {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public maxLength: number = 65535;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public inputName: string = "comments";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public customValidation: IValidateCustomElement;
    private _permissionManager: PermissionManager;
    @observable({ changeHandler: nameof<CommentInput>("selectedTemplateChanged") })
    public selectedTemplate: ITypeaheadOptions;
    public isError: boolean = false;
    public isLoadingTemplate: boolean = false;
    private _templateService: TemplateService;
    public showTemplateTypeahead: boolean = false;
    public maxCharLimit: number = 65535;

    public constructor(templatesService: TemplateService, permissionManager: PermissionManager) {
        this._templateService = templatesService;
        this._permissionManager = permissionManager;
    }

    public attached() {
        this.showTemplateTypeahead = this._permissionManager.checkPermission(viewTemplatePermission);
        this.initValidation();
    }

    private initValidation() {
        if (this.customValidation?.required) {
            ValidationRules.ensure((x: CommentInput) => x.result)
                .satisfies((value, object) => {
                    return !/\*+/.test(value);
                })
                .withMessage("Replace asterisks with individualized patient information.")
                .required()
                .withMessage(this.customValidation.message)
                .on(this);
        } else {
            ValidationRules.ensure((x: CommentInput) => x.result)
                .satisfies((value, object) => {
                    return !/\*+/.test(value);
                })
                .withMessage("Replace asterisks with individualized patient information.")
                .on(this);
        }
        if (!(this.validation?.length > 0)) {
            return;
        }
        // INFO: Yes you need that string interpolation! You remove it you break the application
        // Aurelia validation expects a string for message (not a variable)
        this.validation.forEach((rule: any) => {
            if (rule.required) {
                ValidationRules.ensure((x: CommentInput) => x.result)
                    .required()
                    .withMessage(`${rule.message}`)
                    .on(this);
            }
            if (rule.matches) {
                ValidationRules.ensure((x: CommentInput) => x.result)
                    .matches(new RegExp(rule.satisfies))
                    .withMessage(`${rule.message}`)
                    .on(this);
            }
        });
    }

    public async getTemplateList(term: string, limit: number = 10) {
        try {
            let templates = await this._templateService.getTemplateByPage({
                term: term,
                page: 1,
                pageLength: limit
            });
            return templates.items;
        } catch (e) {
            throw e;
        }
    }

    public async selectedTemplateChanged(newValue: Template) {
        let selectedTemplate = newValue;
        if (selectedTemplate?.id) {
            try {
                this.isError = false;
                this.isLoadingTemplate = true;
                let template = await this._templateService.getTemplateById(selectedTemplate.id);
                if (this.result?.length > 0) {
                    this.result += `\n\n${template.description}`;
                } else {
                    this.result = template.description;
                }
                this.selectedTemplate = undefined;
            } catch (e) {
                this.isError = true;
            } finally {
                this.isLoadingTemplate = false;
            }
        }
    }
}
