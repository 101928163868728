import { autoinject, bindable, bindingMode, customElement, BindingEngine, Disposable } from "aurelia-framework";
import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";
import * as moment from "moment";

import { PermissionManager } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { PayRate } from "../../../models/pay-rate";
import { IGetCurrentPayType } from "../../../interfaces/i-user-payroll-settings";
import { ToastrService } from "../../../services/toastr-service";
import nameof from "../../../common/nameof";
import { EnumMapType } from "../../../common/utilities/enum-map-type";
import { PayrollMenuEnum } from "../../../enums/payroll-menu-enum";
import { IDeletePayRateParams, IGetPayRate, IUpdatePayRateParams } from "./../../../interfaces/i-pay-rate";
import {ActionButtonTooltipEnum} from "../../../enums/action-button-tooltip-enum";

@autoinject
@customElement("pay-rate-row")
export class PayRateRow {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnumMap: EnumMapType;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public activePayrollTab: PayrollMenuEnum = PayrollMenuEnum.PayRates;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public payType: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public userSettings: IGetCurrentPayType;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public payRate: IGetPayRate;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public permission: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public resetRates: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateRate: (rate: IUpdatePayRateParams) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public deleteRate: (id: IDeletePayRateParams) => void;
    private _toastrService: ToastrService;
    private _permissionManager: PermissionManager;
    private _bindingEngine: BindingEngine;
    private _effectiveDateSubscription: Disposable;
    public actionButtonTooltipEnum = ActionButtonTooltipEnum;
    public cachedData: PayRate;
    public isEditRequested: boolean = false;
    public isDeleteRequested: boolean = false;
    public isEditInProgress: boolean = false;
    public isDeleteInProgress: boolean = false;
    public hasEditPermission: boolean = false;
    public hasDeletePermission: boolean = false;
    public validationController: ValidationController;
    public isSaveDisabled: boolean = false;
    public warningMessage: string = "";

    public constructor(controllerFactory: ValidationControllerFactory, toastrService: ToastrService,
        permissionManager: PermissionManager, bindingEngine: BindingEngine) {
        this._toastrService = toastrService;
        this._permissionManager = permissionManager;
        this.validationController = controllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
        this._bindingEngine = bindingEngine;
    }

    public attached() {
        this.hasEditPermission = this._permissionManager.checkPermission([{
            resource: this.permission,
            action: PermissionActionEnum.Edit
        }]);
        this.hasDeletePermission = this._permissionManager.checkPermission([{
            resource: this.permission,
            action: PermissionActionEnum.Delete
        }]);
    }

    private initSubscription() {
        if (this.cachedData) {
            this._effectiveDateSubscription = (this._bindingEngine
                .propertyObserver(this.cachedData, `${nameof<PayRate>("effectiveDate")}`)
                .subscribe((newValue: string) => this.currentSettingsChanged()));
        }
    }

    public currentSettingsChanged() {
        this.isSaveDisabled = false;
        this.warningMessage = "";
        if (this.userSettings?.startDate && this.payType === "Salary" && this.activePayrollTab === PayrollMenuEnum.PayRates) {
            const isAfterCurrentStartDate = moment(this.cachedData.effectiveDate).isAfter(this.userSettings.startDate);
            const isSameDate = moment(this.cachedData.effectiveDate).isSame(this.userSettings.startDate);
            const isSameOrAfterCurrentStartDate = isAfterCurrentStartDate || isSameDate;
            if (this.userSettings?.endDate) {
                let isBeforeCurrentEndDate = moment(this.cachedData.effectiveDate).isBefore(this.userSettings.endDate);
                let isAfterCurrentEndDate = moment(this.cachedData.effectiveDate).isAfter(this.userSettings.endDate);
                if ((isSameOrAfterCurrentStartDate && isBeforeCurrentEndDate) || isAfterCurrentEndDate) {
                    this.isSaveDisabled = true;
                    this.warningMessage = "Pay rates cannot be set for salaried employees. Update the effective date or user’s pay type to save this rate.";
                }
            } else {
                this.isSaveDisabled = !!isSameOrAfterCurrentStartDate;
                this.warningMessage = !!isSameOrAfterCurrentStartDate ? "Pay rates cannot be set for salaried employees. Update the effective date or user’s pay type to save this rate." : "";
            }
        }
    }

    public openEditPayRate() {
        if (!this.hasEditPermission) {
            return;
        }
        this.isEditRequested = true;
        this.cachedData = new PayRate();
        this.cachedData.rate = this.payRate.rate;

        this.cachedData.tasks = [{
            name: this.payRate.customTaskName,
            value: this.payRate.customTaskId
        }];
        this.cachedData.payers = [{
            name: this.payRate.payorName,
            value: this.payRate.payorId
        }];
        this.cachedData.branches = [{
            name: this.payRate.locationName,
            value: this.payRate.locationId
        }];
        this.cachedData.effectiveDate = this.payRate.effectiveDate;
        this.cachedData.rateType = this.payRate.rateType;
        if (this.userSettings) {
            this.initSubscription();
        }
    }

    public async updatePayRate() {
        if (this.cachedData.rate == this.payRate.rate
            && this.cachedData.rateType == this.payRate.rateType
            && this.cachedData.effectiveDate == this.payRate.effectiveDate) {
            this._toastrService.info({
                title: `The pay rate is up to date`,
                message: `There were no changes made in the pay rate.`
            });
        } else {
            this.isEditInProgress = true;
            let res = await this.validationController.validate();
            if (res.valid) {
                await this.updateRate({ id: this.payRate.payRateId, updatedPayRate: this.cachedData });
                this.closeEditPayRate();
            }
            this.isEditInProgress = false;
        }
    }

    public closeEditPayRate() {
        this.isEditRequested = false;
        this.validationController.reset();
        this.cachedData = undefined;
        this.isSaveDisabled = false;
        this.warningMessage = "";
    }

    public confirmDeletePayRate() {
        if (!this.hasDeletePermission) {
            return;
        }
        this.isDeleteRequested = true;
    }

    public async deletePayRate() {
        this.isDeleteInProgress = true;
        await this.deleteRate({ id: this.payRate.payRateId });
        this.isDeleteInProgress = false;
    }

    public cancelDeletePayRate() {
        this.isDeleteRequested = false;
    }

    public getRateTypeLabel(type: number) {
        return this.rateTypeEnumMap?.get(type);
    }

    public detached() {
        this._effectiveDateSubscription?.dispose();
    }

}
