import { autoinject, bindable, bindingMode, computedFrom, containerless, customElement } from "aurelia-framework";
import { IBranchResponse } from "../../../../../interfaces/i-branch";
import { BranchesService } from "../../../../../services/branches-service";
import nameof from "../../../../../common/nameof";
import { IGetPatientsSlimInfoResult } from "../../../../../interfaces/i-get-patient";
import { PatientsService } from "../../../../../services/patient-service";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { FormBuilderEvent } from "../../../note-data-manager";
import { IAutofillData } from "../../../../../interfaces/form-builder/i-autofill-data";
import { ILookupAndCopyBehavior } from "../../../../../interfaces/form-builder/i-behavior";
import "./document-poc-problem-statement.scss";
import { ProblemStatementEventEnum } from "../../../../../enums/problem-statement-event-enum";

@customElement("document-poc-problem-statement")
@containerless
@autoinject
export class DocumentPocProblemStatement {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public elementName: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public visitDate: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public behavior: ILookupAndCopyBehavior;
    private readonly _branchesService: BranchesService;
    private readonly _patientsService: PatientsService;
    private readonly _eventAggregator: EventAggregator;
    private _subscriptions: Subscription[] = [];
    public branches: IBranchResponse[] = [];
    public patient: IGetPatientsSlimInfoResult;

    @computedFrom(
        `${nameof<DocumentPocProblemStatement>("patient")}.${nameof<IGetPatientsSlimInfoResult>("branchId")}`,
        nameof<DocumentPocProblemStatement>("branches")
    )
    public get providerId() {
        let patientBranch = this.branches?.find((branch) => branch.id === this.patient?.branchId);
        return patientBranch?.providerId ?? "";
    }

    public constructor(
        branchesService: BranchesService,
        patientsService: PatientsService,
        eventAggregator: EventAggregator
    ) {
        this._branchesService = branchesService;
        this._patientsService = patientsService;
        this._eventAggregator = eventAggregator;
    }

    public async bind() {
        this._subscriptions.push(
            this._eventAggregator.subscribe(ProblemStatementEventEnum.goal, (payload: string) => {
                this._eventAggregator.publish(FormBuilderEvent.AppendData, {
                    data: payload,
                    component: this.behavior.mapping.goalsInputName,
                    elementName: this.elementName
                } as IAutofillData);
            })
        );
        this._subscriptions.push(
            this._eventAggregator.subscribe(ProblemStatementEventEnum.intervention, (payload: string) => {
                this._eventAggregator.publish(FormBuilderEvent.AppendData, {
                    data: payload,
                    component: this.behavior.mapping.interventionInputName,
                    elementName: this.elementName
                } as IAutofillData);
            })
        );
        this.patient = await this._patientsService.getPatientSlim(this.patientId);
        this.branches = await this._branchesService.getAllBranches();
    }

    public detached() {
        if (this._subscriptions.length > 0) {
            this._subscriptions.forEach((sub) => sub.dispose());
        }
    }
}
