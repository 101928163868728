export const config = {
    libertyAccountId: "39ff9787-bc7d-30c0-e3db-178f4f4e8bb5",
    devServerURL: "http://hospice.dev.axxess.tech/", // master
    betaServerURL: "http://hospice-feature.dev.axxess.tech/", // beta - feature-environment
    omegaServerURL: "http://hospice-api-feature-2.service.axxess.consul:8385/", // omega - feature-environment
    localServerURL: "http://dev-hospice.axxess.net", // local backend
    testServerURL: "http://hospice.test.axxess.tech/", // Testing
    assetServerURL: "http://hospice-asset-storage-api.service.axxess.consul:9085/",
    supportUrl: "https://www.axxess.com/support/",
    ticketUrl: "https://help.axxessweb.com/openid/login/1",
    helpUrl: "https://www.axxess.com/help/axxesshospice/",
    softwareUpdateUrl: "https://www.axxess.com/help/axxesshospice/software-updates/",
    trainingWebinarUrl: "https://www.axxess.com/help/axxesshospice/training-webinars/",
    nalaApiUrl: "https://dde.axxessweb.com",
    mushuApiUrl: "https://revenue.axxessweb.com",
    axxessCentralUrl: "https://central.axxessweb.com",
    pageSize: 10,
    enumCacheKey: "AxxessHospiceEnumCacheKey",
    versionCacheKey: "AxxessHospiceVersionCacheKey",
    locationCacheKey: "AxxessHospiceUserLocation",
    weatherCacheKey: "AxxessHospiceUserWeather",
    versionChangedPublishKey: "app:version:changed",
    userAuthPublishKey: "user:auth:verified",
    locationSaveKey: "hospice-oidc-redirect",
    stateListCacheKey: "AxxessHospiceStateListCacheKey",
    countryListCacheKey: "AxxessHospiceCountryListCacheKey",
    countryPhoneCodeListCacheKey: "AxxessHospiceCountryPhoneCodeListCacheKey",
    userCommunityUrl: "https://community.axxess.com/",
    followAxxessUrl: "https://www.linkedin.com/company/axxess-consult"
};
