import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import {
    certificationWatchReportTooltip,
    compAssessmentDueTooltip,
    frequencyWatchTooltip,
    missedVisitUserTooltip,
    recertificationsDueTooltip,
    scheduledTasksTooltip,
    visitAlertsTooltip,
    visitHistoryDateRangeTooltip,
    bereavementServicesReportTooltip
} from "./schedule-reports-tooltips";

export const schedulePermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.Schedule,
        action: PermissionActionEnum.View
    }
];

export const scheduleReportsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "recertifications-due"
    },
    {
        name: "recertifications-due",
        route: "recertifications-due",
        nav: true,
        moduleId: PLATFORM.moduleName("./recertifications-due/recertifications-due", "recertifications-due"),
        title: "Recertifications Due",
        href: "reports/schedule-reports/recertifications-due",
        settings: {
            tooltip: recertificationsDueTooltip,
            menuName: "Recertifications Due",
            isPalliativeCare: false,
            permission: schedulePermission
        }
    },
    {
        name: "comprehensive-assessment-due",
        route: "comprehensive-assessment-due",
        moduleId: PLATFORM.moduleName(
            "./comprehensive-assessment-due/comprehensive-assessment-due",
            "comprehensive-assessment-due"
        ),
        title: "Comprehensive Assessment Due",
        href: "reports/schedule-reports/comprehensive-assessment-due",
        settings: {
            tooltip: compAssessmentDueTooltip,
            menuName: "Comprehensive Assessment Due",
            isPalliativeCare: false,
            permission: schedulePermission
        }
    },
    {
        name: "visit-alerts",
        route: "visit-alerts",
        nav: true,
        moduleId: PLATFORM.moduleName("./visit-alerts/visit-alerts", "visit-alerts"),
        title: "Visit Alerts",
        href: "reports/schedule-reports/visit-alerts",
        settings: {
            tooltip: visitAlertsTooltip,
            menuName: "Visit Alerts",
            permission: schedulePermission
        }
    },
    {
        name: "frequency-watch-report",
        route: "frequency-watch-report",
        moduleId: PLATFORM.moduleName("./frequency-watch/frequency-watch", "frequency-watch"),
        nav: true,
        title: "Frequency Watch Report",
        href: "reports/schedule-reports/frequency-watch",
        settings: {
            tooltip: frequencyWatchTooltip,
            menuName: "Frequency Watch Report",
            isPalliativeCare: false,
            permission: schedulePermission
        }
    },
    {
        name: "scheduled-tasks",
        route: "scheduled-tasks",
        nav: true,
        moduleId: PLATFORM.moduleName("./scheduled-tasks/scheduled-tasks", "scheduled-tasks"),
        title: "Scheduled Tasks",
        href: "reports/schedule-reports/scheduled-tasks",
        settings: {
            tooltip: scheduledTasksTooltip,
            menuName: "Scheduled Tasks",
            permission: schedulePermission
        }
    },
    {
        name: "bereavement-services-report",
        route: "bereavement-services-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./bereavement-services-report/bereavement-services-report",
            "bereavement-services-report"
        ),
        title: "Bereavement Services Report",
        href: "reports/schedule-reports/bereavement-services-report",
        settings: {
            tooltip: bereavementServicesReportTooltip,
            permission: schedulePermission,
            menuName: "Bereavement Services Report",
            releaseDate: "03/20/2024"
        }
    },
    {
        name: "missed-visit-user",
        route: "missed-visit-user",
        moduleId: PLATFORM.moduleName("./missed-visit-user/missed-visit-user", "missed-visit-user"),
        title: "Missed Visits by User",
        href: "reports/schedule-reports/missed-visit-user",
        settings: {
            tooltip: missedVisitUserTooltip,
            menuName: "Missed Visits by User",
            permission: schedulePermission
        }
    },
    {
        name: "visit-history-date-range",
        route: "visit-history-date-range",
        moduleId: PLATFORM.moduleName(
            "./visit-history-date-range/visit-history-date-range",
            "visit-history-date-range"
        ),
        title: "Visit History by Date Range Report",
        href: "reports/schedule-reports/visit-history-date-range",
        settings: {
            tooltip: visitHistoryDateRangeTooltip,
            menuName: "Visit History by Date Range",
            permission: schedulePermission
        }
    },
    {
        name: "certification-watch-report",
        route: "certification-watch-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./certification-watch-report/certification-watch-report",
            "certification-watch-report"
        ),
        title: "Certification Watch Report",
        href: "reports/schedule-reports/certification-watch-report",
        settings: {
            inProgressPage: true,
            tooltip: certificationWatchReportTooltip,
            permission: schedulePermission,
            menuName: "Certification Watch Report",
            isPalliativeCare: false
        }
    }
];
