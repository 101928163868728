import { ValidationRules } from "aurelia-validation";
import { IHospitalizedPatientQuery } from "../../interfaces/i-get-patient";

export class HospitalizedPatient implements IHospitalizedPatientQuery {
    public hospitalizationDate: string = "";
    public hospitalizationReason: number = null;
    public facilitySourceId: string = "";
    public facilityContactName: string = "";
    public communityLiaisonId: string = "";
    public comments: string = "";

    public constructor(data?: IHospitalizedPatientQuery) {
        if (!!data) {
            Object.assign(this, data);
        }
        this.initValidations();
    }

    public initValidations() {
        ValidationRules.ensure((patient: IHospitalizedPatientQuery) => patient.hospitalizationDate)
            .required()
            .withMessage("Hospitalized Date is required.")
            .ensure((patient: IHospitalizedPatientQuery) => patient.hospitalizationReason)
            .required()
            .withMessage("Hospitalization Reason is required.")
            .on(this);
    }
}
