import { IDisplayNavigatorState, INavigatorGroup, INavigatorGroupInfo, INavigatorState, INavigatorStateReference } from "../../../interfaces/form-builder/i-navigator";

export class NavigatorStateManager {
    private _activeState: INavigatorState = null;
    private _previousStateReference: INavigatorStateReference = null;
    private _nextStateReference: INavigatorStateReference = null;
    private _navigatorData: Map<string, INavigatorGroup> = null;
    private _navigatorGroups: string[] = [];

    public constructor(navigatorData: Map<string, INavigatorGroup>) {
        this._navigatorData = navigatorData;
        if (navigatorData) {
            this._navigatorGroups = Array.from(navigatorData.keys());
        } else {
            this._navigatorGroups = [];
            return;
        }
        this.resetState();
    }

    public getGroups(): INavigatorGroupInfo[] {
        if (this._navigatorGroups.length == 0) {
            return [];
        }
        let groupNames = Array.from(this._navigatorData.keys());
        let groupsData = Array.from(this._navigatorData.values());
        return groupsData.map((group, index) => ({
            ...group.settings,
            groupName: groupNames[index],
            questionsCount: group.navigatorItems.length
        }));
    }

    public setGroup(groupName: string, navigatorItemIndex: number = 0) {
        let groupData = this._navigatorData.get(groupName);
        if (!groupData) {
            throw new Error("Error Setting Group: Unknown Group");
        }
        this.setState({
            groupName: groupName,
            navigatorItemIndex
        });
        return this.getDisplayNavigatorState();
    }

    public resetState(): IDisplayNavigatorState {
        if (this._navigatorGroups.length === 0) {
            throw new Error("Cannot Reset Navigator Manager: No Data Available");
        }
        let activeGroupName = this._navigatorGroups[0];
        this.setState({
            groupName: activeGroupName,
            navigatorItemIndex: 0
        });
        return this.getDisplayNavigatorState();
    }

    public previousState(): IDisplayNavigatorState {
        if (!this._previousStateReference) {
            throw new Error("Cannot Reset Navigator Manager: No Previous State Available");
        }
        this.setState(this._previousStateReference);
        return this.getDisplayNavigatorState();
    }

    public nextState(): IDisplayNavigatorState {
        if (!this._nextStateReference) {
            throw new Error("Cannot Reset Navigator Manager: No Next State Available");
        }
        this.setState(this._nextStateReference);
        return this.getDisplayNavigatorState();
    }

    public getDisplayNavigatorState() {
        return {
            activeState: this._activeState,
            hasPreviousState: !!this._previousStateReference,
            hasNextState: !!this._nextStateReference
        };
    }

    private setState(stateReference: INavigatorStateReference) {
        let activeGroup = this._navigatorData.get(stateReference.groupName);
        let activeNavigatorItem = activeGroup.navigatorItems[stateReference.navigatorItemIndex];
        if (!activeNavigatorItem) {
            throw new Error("Cannot Navigate Navigator Manager: No Question Found");
        }
        this._activeState = {
            groupName: stateReference.groupName,
            theme: activeGroup.settings.theme,
            navigatorItem: activeNavigatorItem
        };
        this.setNextStateReference(stateReference);
        this.setPreviousStateReference(stateReference);
    }

    private setPreviousStateReference(stateReference: INavigatorStateReference) {
        let activeGroupIndex = this._navigatorGroups.indexOf(stateReference.groupName);
        if (stateReference.navigatorItemIndex > 0) {
            this._previousStateReference = {
                groupName: stateReference.groupName,
                navigatorItemIndex: stateReference.navigatorItemIndex - 1
            };
            return;
        }
        // Active Question is the first in group
        if (activeGroupIndex === 0) {
            this._previousStateReference = null;
        } else {
            // Set reference to previous groups last question
            let previousGroupName = this._navigatorGroups[activeGroupIndex - 1];
            let previousGroupData = this._navigatorData.get(previousGroupName);
            this._previousStateReference = {
                groupName: previousGroupName,
                navigatorItemIndex: previousGroupData.navigatorItems.length - 1
            };
        }
    }

    private setNextStateReference(stateReference: INavigatorStateReference) {
        let activeGroupIndex = this._navigatorGroups.indexOf(stateReference.groupName);
        let activeGroupData = this._navigatorData.get(stateReference.groupName);
        let activeGroupQuestionLength = activeGroupData.navigatorItems.length - 1;
        if (stateReference.navigatorItemIndex !== activeGroupQuestionLength) {
            this._nextStateReference = {
                groupName: stateReference.groupName,
                navigatorItemIndex: stateReference.navigatorItemIndex + 1
            };
            return;
        }
        // Active question is the last question of the group
        if (activeGroupIndex === this._navigatorGroups.length - 1) {
            this._nextStateReference = null;
        } else {
            // Set reference to first question of the next group
            let nextGroupName = this._navigatorGroups[activeGroupIndex + 1];
            this._nextStateReference = {
                groupName: nextGroupName,
                navigatorItemIndex: 0
            };
        }
    }
}
