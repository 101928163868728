import { bindingMode, observable, computedFrom } from "aurelia-binding";
import { autoinject, bindable, customElement } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IActionCallBack, IGoal } from "../../../interfaces/i-plan-of-care";
import { EnumMap } from "../../../common/utilities/enum-map";
import { EventAggregator } from "aurelia-event-aggregator";
import { ProblemStatementEventEnum } from "../../../enums/problem-statement-event-enum";

@autoinject
@customElement("goal-row")
export class GoalRow {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public goal: IGoal;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isUpdateEnabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public resolveGoal: (actionObj: IActionCallBack) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public goalActions: IEnumResponse[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public goalCompletedByDateEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAction: boolean = false;
    private readonly _eventAggregator: EventAggregator;
    @observable({
        changeHandler: nameof<GoalRow>("resolveReasonChanged")
    })
    public resolveReason: string;

    @computedFrom(nameof<GoalRow>("goalCompletedByDateEnum"), nameof<GoalRow>("goal"))
    public get completedByLabel() {
        if (!!this.goalCompletedByDateEnum && !!this.goal) {
            let goalCompletedByDateEnumMap = new EnumMap(this.goalCompletedByDateEnum);
            return (goalCompletedByDateEnumMap.getEnumName(this.goal.completedBy) as string) ?? "";
        }

        return "";
    }

    public constructor(eventAggregator: EventAggregator) {
        this._eventAggregator = eventAggregator;
    }

    public resolveReasonChanged(newValue: string, oldValue: string) {
        if (oldValue === undefined) {
            return;
        }

        if (!!this.resolveGoal) {
            this.resolveGoal({ action: newValue });
        }
    }

    public isUpdateEnabledChanged(newValue: string) {
        if (!newValue) {
            this.resolveReason = null;
        }
    }

    public document() {
        this._eventAggregator.publish(ProblemStatementEventEnum.goal, this.goal.goal);
    }
}
