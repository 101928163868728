import { valueConverter } from "aurelia-binding";

@valueConverter("subStringHighlight")
export class SubStringHighlight {
    public toView(value: string, filter: string) {
        if (!filter) {
            return value;
        }
        let result: string = "";
        let position: number = 0;
        while (true) {
            let index: number = value.toLowerCase().indexOf(filter.toLowerCase(), position);
            if (index == -1) {
                result += value.substring(position);
                return result;
            }
            result += value.substring(position, index) + `<strong>${value.substr(index, filter.length)}</strong>`;

            position = index + filter.length;

            if (position >= value.length) {
                return result;
            }
        }
    }
}
