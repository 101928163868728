// Button is used in a lot of places so leaving it on top
// import "bootstrap/js/src/button.js";
// Collapse is used by app navbar and page layout might as well be on the top
// import "bootstrap/js/src/collapse.js";
import "bootstrap";
import "whatwg-fetch";

import { DialogConfiguration } from "aurelia-dialog";
import { Aurelia, PLATFORM } from "aurelia-framework";
import { UserManager } from "oidc-client";

import { BootstrapFormRenderer } from "./common/bootstrap-form-renderer";
import Infrastructure from "./common/infrastructure";
import { ConfigureAppLogging } from "./common/logging";
import { OidcConfiguration } from "./common/oidc/oidc-configuration";
import { SentryClient } from "./common/sentry-client";

export async function configure(aurelia: Aurelia) {
    let sentryClient = new SentryClient();
    sentryClient.start();

    aurelia.use
        .standardConfiguration()
        .developmentLogging()
        .feature(PLATFORM.moduleName("resources/index"))
        .plugin(PLATFORM.moduleName("aurelia-validation"))
        .plugin(PLATFORM.moduleName("aurelia-dialog"), (config: DialogConfiguration) => {
            config.useDefaults();
            config.settings.lock = true;
            config.settings.centerHorizontalOnly = false;
            config.settings.startingZIndex = 9999;
        });

    sentryClient.captureLogging();

    let oidcConfig: OidcConfiguration = aurelia.container.get(OidcConfiguration);
    let userManager = oidcConfig.createUserManager();
    aurelia.container.registerInstance(UserManager, userManager);
    aurelia.container.registerHandler("bootstrap-form-renderer", x => x.get(BootstrapFormRenderer));

    ConfigureAppLogging();

    await aurelia.start();
    await aurelia.setRoot(PLATFORM.moduleName("pages/app/app"));

    logVersion();
}

function logVersion(): void {
    console.log(`Running release version '${Infrastructure.releaseVersion}'.`);
    console.log(`Running release environment '${Infrastructure.releaseEnvironment}'.`);
    console.log(`Running build version '${Infrastructure.buildVersion}'.`);
}
