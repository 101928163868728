import "./grouped-multi-select.scss";

import { bindingMode, computedFrom, observable } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import * as $ from "jquery";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IMultiSelectDropDownOption } from "../../../interfaces/i-grouped-multi-select";

@customElement("grouped-multi-select")
export class GroupedMultiSelect {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public singularLabelText: string = "";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public placeholderText: string = "";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public searchText: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public checkAllByDefault: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public groupNameEnumMap: EnumMap;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public optionEnumMap: EnumMap;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public labelText: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isDisabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public defaultBranchName: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<GroupedMultiSelect>("dropDownOptionsChanged")
    })
    public dropDownOptions: IMultiSelectDropDownOption;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedOptions: any[] = [];
    public filteredOptions: IMultiSelectDropDownOption;
    @observable({ changeHandler: "searchChanged" })
    public search: string;
    public dropDownMenu: HTMLElement;
    public dropDownElement: HTMLElement;
    public searchInput: HTMLElement;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showSelectAll: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public maxSelectedLimit: number = 0;

    @computedFrom(nameof<GroupedMultiSelect>("labelText"))
    public get searchPlaceholder(): string {
        return this.searchText ? `Search for ${this.searchText}` : `Search for ${this.labelText}`;
    }

    @computedFrom(nameof<GroupedMultiSelect>("dropDownOptions"))
    public get dropDownOptionsLength() {
        if (!this.dropDownOptions) {
            return 0;
        }
        return Object.values(this.dropDownOptions).reduce((len, option) => option.length + len, 0);
    }

    @computedFrom(
        `${nameof<GroupedMultiSelect>("selectedOptions")}.length`,
        nameof<GroupedMultiSelect>("dropDownOptionsLength")
    )
    public get buttonText(): string {
        if (this.selectedOptions?.length === this.dropDownOptionsLength) {
            if (this.defaultBranchName) {
                return `${this.labelText}: All`;
            } else {
                return `All ${this.labelText}`;
            }
        } else if (this.selectedOptions?.length === 0) {
            return this.placeholderText ? this.placeholderText : `All ${this.labelText}`;
        } else if (this.selectedOptions?.length === 1) {
            return `1 ${this.singularLabelText ? this.singularLabelText : this.labelText} selected`;
        } else {
            return `${this.selectedOptions?.length} ${this.labelText} selected`;
        }
    }

    public attached() {
        this.filteredOptions = { ...this.dropDownOptions };
        $(this.dropDownMenu).on("click", (e) => {
            e.stopPropagation();
        });
        $(this.dropDownElement).on("shown.bs.dropdown", (e) => {
            this.searchInput.focus();
        });
        if (this.checkAllByDefault) {
            this.toggleAllSelected();
        }
    }

    public toggleAllSelected() {
        let shouldAddAll = this.dropDownOptionsLength !== this.selectedOptions?.length;
        // emptying array
        this.selectedOptions?.splice(0, this.selectedOptions?.length);
        if (shouldAddAll) {
            for (let groupName of Object.keys(this.dropDownOptions)) {
                this.selectAllGroupOptions(groupName);
            }
        }
    }

    public isLimitReached(index: number): boolean {
        return (
            this.maxSelectedLimit !== 0 &&
            this.selectedOptions.length >= this.maxSelectedLimit &&
            !(this.selectedOptions.indexOf(index) > -1)
        );
    }

    public closeDropDown() {
        $(this.dropDownElement).dropdown("toggle");
    }

    public searchChanged() {
        if (!!this.search) {
            for (let groupName of Object.keys(this.dropDownOptions)) {
                let options: any = this.dropDownOptions[groupName];
                this.filteredOptions[groupName] = options.filter((option: string | number) => {
                    let displayText = this.optionEnumMap.getEnumName(option) as string;
                    return displayText.toLowerCase().includes(this.search.toLowerCase());
                });
            }
        } else {
            this.filteredOptions = { ...this.dropDownOptions };
        }
    }

    public toggleAllGroupOptions(groupName: string) {
        let options = this.dropDownOptions[groupName];
        let shouldSelectAll = false;
        for (let option of options) {
            let optionIndex = this.selectedOptions.indexOf(option);
            if (
                optionIndex === -1 &&
                (this.maxSelectedLimit === 0 ||
                    (this.maxSelectedLimit !== 0 &&
                        this.selectedOptions.length + this.dropDownOptions[groupName].length <= this.maxSelectedLimit))
            ) {
                shouldSelectAll = true;
            } else {
                this.selectedOptions.splice(optionIndex, 1);
            }
        }
        if (shouldSelectAll) {
            this.selectAllGroupOptions(groupName);
        }
    }

    public dropDownOptionsChanged() {
        this.filteredOptions = { ...this.dropDownOptions };
    }

    private selectAllGroupOptions(groupName: string) {
        for (let option of this.dropDownOptions[groupName]) {
            this.selectedOptions.push(option);
        }
    }

    public clearSelection() {
        while (this.selectedOptions.length > 0) {
            this.selectedOptions.pop();
        }
    }
}
