import {bindable, bindingMode, containerless, customElement} from "aurelia-framework";

@customElement("error-message-handler")
@containerless
export class ErrorMessageHandler {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public tryAgain: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showTryAgain: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public action: string = "loading";
}
