import {autoinject, bindable, bindingMode, containerless, customElement} from "aurelia-framework";

import {ITypeaheadOptions} from "../../../interfaces/i-typeahead";
import {IValidateCustomElement} from "../../../interfaces/i-validate-custom-element";
import {IGetCountryCode} from "../../../interfaces/lookup/i-country-code";
import {LookupService} from "../../../services/lookup-service";

@autoinject
@containerless
@customElement("country-typeahead")
export class CountryTypeahead {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedCountry: IGetCountryCode;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validation: IValidateCustomElement;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public prefillCountry: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public prefillCountryName: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public disabled: boolean = false;
    private readonly _lookupService: LookupService;
    public countryList: IGetCountryCode[] = [];

    public constructor(lookupService: LookupService) {
        this._lookupService = lookupService;
    }

    public async attached() {
        await this.getCountryList();
        if (this.prefillCountry) {
            this.selectedCountry = this.countryList.find(
                (country) => country.value === this.prefillCountry
            ) as IGetCountryCode;
        } else {
            let unitedStatesCode = await this._lookupService.getUnitedStatesCode();
            this.selectedCountry = this.countryList.find((country) => country.value === unitedStatesCode);
        }
    }

    public async getCountryList() {
        try {
            this.countryList = await this._lookupService.getCountryCodes();
        } catch (e) {
            console.error(e);
        }
    }

    public selectedCountryChanged(newValue: ITypeaheadOptions) {
        if (newValue && this.prefillCountry !== newValue?.value) {
            this.prefillCountry = newValue.value;
            this.prefillCountryName = newValue.value;
        }
    }

    public async prefillCountryChanged(newValue: string) {
        if (this.countryList?.length === 0) {
            await this.getCountryList();
        }
        this.selectedCountry = this.countryList.find((country) => country.twoLetterCode === newValue);
        if (!this.selectedCountry) {
            this.selectedCountry = this.countryList.find((country) => country.value === newValue);
        }
    }

    public async prefillCountryNameChanged(newValue: string) {
        if (this.countryList?.length === 0) {
            await this.getCountryList();
        }
        if (newValue?.toLowerCase() === "us") {
            newValue = "US";
        }
        this.selectedCountry = this.countryList.find((country) => country.twoLetterCode === newValue);
    }
}
