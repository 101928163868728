import {lazy} from "aurelia-framework";
import {NavigationInstruction, Next, RedirectToRoute} from "aurelia-router";

import Infrastructure from "../../common/infrastructure";
import {PermissionManager} from "../../common/utilities/permission-manager";
import {HttpStatusCodeEnum} from "../../enums/http-status-code-enum";
import {AccountsService} from "../../services/accounts-service";
import {AuthService} from "../../services/auth-service";

export class AuthorizeStep {
    private readonly _authService: () => AuthService;
    private readonly _accountsService: () => AccountsService;
    private readonly _permissionManager: () => PermissionManager;
    private isPalliativeCareView: boolean = false;

    public constructor(
        @lazy(AuthService) authService: () => AuthService,
        @lazy(AccountsService) accountService: () => AccountsService,
        @lazy(PermissionManager) permissionManager: () => PermissionManager
    ) {
        this._authService = authService;
        this._accountsService = accountService;
        this._permissionManager = permissionManager;
    }
    public async run(navigationInstruction: NavigationInstruction, next: Next) {
        let instructions = navigationInstruction.getAllInstructions();
        let allowsAccountLess = instructions.some((nav) => nav.config?.settings?.accountLess);

        if (allowsAccountLess) {
            return next();
        }

        let isInProgressPage = instructions.some((nav) => (nav.config.settings || {}).inProgressPage);
        let routeToCheck = instructions.find((nav) => (nav.config.settings || {}).permission);
        // Routes that are not allowed to Palliative View
        let isNotAllowedForPal = instructions.find((nav) => nav?.config?.settings?.isPalliativeCare === false);

        if (isInProgressPage && Infrastructure.isProdEnvironment) {
            return next.cancel(new RedirectToRoute("home"));
        }

        let isUserLoggedIn = await this._authService().isLoggedIn();
        if (isUserLoggedIn) {
            let accountId = await this._authService().getAccountId();
            let isPermissionManagerReady = await this._permissionManager().isPermissionManagerReady();
            this.isPalliativeCareView = await this._accountsService().getIsPalliativeAccount();

            if (accountId && !isPermissionManagerReady) {
                console.log("Refreshing User's permissions");
                try {
                    await this._permissionManager().refreshUserPermissionData();
                } catch (e) {
                    console.error(e);
                    if (e.status === HttpStatusCodeEnum.Forbidden) {
                        // Potential wrong account in the local storage, so clear local storage
                        this._authService().clearAccountId();
                    }
                }
            }
        }

        if (routeToCheck) {
            if (!this.canAccessRoute(routeToCheck)) {
                return next.cancel(new RedirectToRoute("not-authorized"));
            }
        }

        if (isNotAllowedForPal && this.isPalliativeCareView) {
            return next.cancel(new RedirectToRoute("not-authorized"));
        }

        return next();
    }

    private canAccessRoute(route: NavigationInstruction) {
        let permission = route.config.settings.permission;
        let checkAll = route.config.settings.checkAll ? route.config.settings.checkAll : false;
        return this._permissionManager().checkPermission(permission, checkAll);
    }
}
