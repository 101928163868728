import { autoinject } from "aurelia-dependency-injection";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { bindable, bindingMode, computedFrom, containerless, customElement } from "aurelia-framework";
import { NavModel, Router, RouterEvent } from "aurelia-router";
import nameof from "../../../common/nameof";
import { isNavAllowed } from "../../../common/utilities/is-route-allowed";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { RouteName } from "../../../pages/app/app-routes";
import { AccountsService } from "../../../services/accounts-service";
import "./level-two.scss";

@autoinject
@customElement("level-two")
@containerless
export class LevelTwo {
    private _permissionManager: PermissionManager;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public router: Router;
    private _ea: EventAggregator;
    private _accountsService: AccountsService;
    private _navigationSubscription: Subscription;
    public accountLoaded: boolean = false;
    public isPalliativeCare: boolean = false;
    public levelTwoContainer: HTMLElement;
    public allowableRoutes: NavModel[];

    @computedFrom(`${nameof<LevelTwo>("router")}.${nameof<Router>("navigation")}`)
    public get routes(): NavModel[] {
        return this.router.navigation || [];
    }

    @computedFrom(nameof<LevelTwo>("routes"))
    public get activeRouteName(): string {
        let selectedRoute = this.routes.find((route) => route.isActive);
        if (!!selectedRoute && !!selectedRoute.settings) {
            return selectedRoute.settings.menuName;
        }
        return "";
    }

    @computedFrom(
        `${nameof<LevelTwo>("routes")}.length`,
        `${nameof<LevelTwo>("router")}.${nameof<Router>("currentInstruction")}`
    )
    public get activeRoute() {
        let activeRoute: any = this.routes
            .filter((route) => !!route.config && route.config.nav)
            .find((route) => route.isActive);
        return activeRoute;
    }

    @computedFrom(
        `${nameof<LevelTwo>("routes")}.length`,
        `${nameof<LevelTwo>("activeRoute")}`,
        `${nameof<LevelTwo>("activeRoute")}.navItem`,
        `${nameof<LevelTwo>("router")}.${nameof<Router>("currentInstruction")}`
    )
    public get activeItem() {
        if (this.activeRoute && this.activeRoute.navItem) {
            return this.activeRoute.navItem;
        } else {
            return null;
        }
    }

    public constructor(
        ea: EventAggregator,
        router: Router,
        authService: AccountsService,
        permissionManager: PermissionManager
    ) {
        this.router = router;
        this._accountsService = authService;
        this._permissionManager = permissionManager;
        this._ea = ea;
    }

    public async attached() {
        this.accountLoaded = false;
        this.isPalliativeCare = await this._accountsService.getIsPalliativeAccount();
        this.accountLoaded = true;
        this.constructPatientUrl();
        this._navigationSubscription = this._ea.subscribe(RouterEvent.Complete, async () => {
            // Replaces loads with patient id and adds query string for maintaining the same patient
            this.constructPatientUrl();
        });
        this.allowableRoutes = this.routes.filter((route) => this.hasPermission(route) && this.isLinkAllowed(route));
    }

    public constructPatientUrl() {
        if (this.router.baseUrl.includes(RouteName.Patients) && this.router.currentInstruction?.params?.id) {
            let routes = this.router.navigation.map((route) =>
                Object.assign(route, {
                    href:
                        route.href.replace("load", this.router.currentInstruction.params.id) +
                        (route.href.includes("load") ? `?${this.router.currentInstruction?.queryString}` : "")
                })
            );
            this.router.navigation = routes;
        }
    }

    public isLinkAllowed(navModel: NavModel) {
        let isPalliativeRoute = navModel?.config?.settings?.isPalliativeCare;
        if (
            (isPalliativeRoute === false && this.isPalliativeCare) ||
            (isPalliativeRoute === true && !this.isPalliativeCare)
        ) {
            return false;
        }
        if (!!navModel) {
            return isNavAllowed(navModel);
        }
        return false;
    }

    public hasPermission(navModel: NavModel) {
        if (!navModel) {
            return false;
        }
        if (navModel?.settings?.permission?.length > 0) {
            return this._permissionManager.checkPermission(navModel.settings.permission, navModel.settings.checkAll);
        } else {
            return true;
        }
    }

    public detached() {
        if (this._navigationSubscription) {
            this._navigationSubscription.dispose();
        }
    }
}
