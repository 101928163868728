import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { customElement } from "aurelia-templating";

import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { NoteDataManager } from "../../note-data-manager";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IMaxLengthValidation, IRegexValidation } from "./../../../../interfaces/form-builder/i-validation";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";

@autoinject
@customElement("phone-number-input")
export class PhoneNumberInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    public noteDataManager: NoteDataManager;
    public isValidationLoaded: boolean = false;
    public validation: IValidateCustomElement = {
        required: false,
        matches: true,
        displayName: "Phone Number"
    };

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public bind() {
        this.initValidation();
    }

    private initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions?.name);
        validation?.forEach((rule: IMaxLengthValidation | IRegexValidation) => {
            if (rule.validationType === FormValidationEnum.Required) {
                this.validation.required = true;
                this.validation.displayName = this.viewOptions.name;
            }
        });
        this.isValidationLoaded = true;
    }
}
