import { LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { ConsoleAppender } from "aurelia-logging-console";

export const appLogger: Logger = LogManager.getLogger("app");

export function ConfigureAppLogging() {
    LogManager.addAppender(new ConsoleAppender());
    LogManager.setLevel(LogManager.logLevel.debug);
}
