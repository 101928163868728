import { ValidationRules } from "aurelia-validation";
import { ICreateNonPatientActivity } from "../../../../services/i-non-patient-activities-service";

export class NonPatientActivity implements ICreateNonPatientActivity {
    public nonPatientActivityName: string = "";
    public providerIds: string[] = [];
    public effectiveDate: string;

    public constructor(data?: ICreateNonPatientActivity) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((x: NonPatientActivity) => x.nonPatientActivityName)
            .required()
            .withMessage("Name is required.")
            .ensure((x: NonPatientActivity) => x.effectiveDate)
            .required()
            .withMessage("Effective date is required.")
            .on(this);
    }
}
