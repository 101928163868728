/******************************************************************************
 * *
 * Feature Toggle
 * Usage: Wrapper enclosing the feature should have if.bind to isFeatureVisible
 * *
 ********************************************************************************/
import {autoinject, bindable, bindingMode, containerless, customElement, inlineView} from "aurelia-framework";

import Infrastructure from "../../../common/infrastructure";
import {AuthService} from "../../../services/auth-service";

export const PAbcId = "39ecb472-00b3-e723-3898-56770747a82d";

@customElement("feature-toggle")
@inlineView("<template><slot></slot></template>")
@containerless
@autoinject
export class FeatureToggle {
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public isFeatureVisible: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public feature: string = "";
    private readonly _authService: AuthService;
    private featureMap: Map<string, string[]> = new Map([["claim-submission", [PAbcId]]]);
    public element: Element;

    public constructor(authService: AuthService) {
        this._authService = authService;
    }

    public attached() {
        this.isFeatureVisible = this.checkFeatureVisibility();
    }

    private checkFeatureVisibility() {
        let isProd = Infrastructure.isProdEnvironment;
        if (!this.feature || !isProd) {
            return !isProd;
        }
        let accountsAllowed = this.featureMap.get(this.feature);
        if (!accountsAllowed) {
            return false;
        }
        let currentAccountId = this._authService.getAccountId();
        return !!accountsAllowed.find((accountId) => accountId === currentAccountId);
    }
}
