// import "bootstrap/js/src/popover.js";
import "./popover.scss";
import "popper.js";

import { bindable, customAttribute, inject, observable } from "aurelia-framework";

import nameof from "../../../common/nameof";

@customAttribute("custom-popover")
@inject(Element)
export class Popover {
    private _element: any;
    @bindable
    public animation: boolean = true;
    @bindable
    public container: string = "body";
    @bindable
    public delay: number = 0;
    @bindable
    public html: boolean = true;
    @bindable
    public placement: string = "auto";
    @bindable
    public title: string = "";
    @bindable
    @observable({ changeHandler: nameof<Popover>("contentChanged") })
    public content: string = "";
    @bindable
    public trigger: string = "click";
    @bindable
    public hideWait: number = 0;
    @bindable
    public showOnChange: boolean = false;
    @bindable
    public className: string;
    private _defaultClassName: string = "popover";

    public constructor(el: Element) {
        this._element = $(el);
    }

    public attached(): void {
        if (!this.content || this.content.length <= 0) {
            return;
        }
        let options: any = {
            animation: this.animation,
            container: this.container,
            delay: this.delay,
            html: this.html,
            placement: this.placement,
            title: this.title,
            content: this.content,
            trigger: this.trigger
        };

        if (this.className && this.className.length) {
            options.template = `<div class="${this._defaultClassName} ${this.className}" role="tooltip">
                                    <div class="arrow"></div>
                                    <h3 class="popover-header"></h3>
                                    <div class="popover-body"></div>
                                </div>`;
        }
        this.destroyPopover();

        if (options.trigger === "manual") {
            this._element
                .popover(options)
                .popover("hide")
                .on("mouseenter", () => {
                    this._element.popover("show");
                    let hideWait: number = this.hideWait;
                    // If hidewait is set, hide after hidewait seconds
                    // even if the mouse is hovering over the content
                    if (hideWait) {
                        let hideWaitTimer = setTimeout(() => {
                            this._element.popover("hide");
                            clearTimeout(hideWaitTimer);
                        }, hideWait);
                    }
                })
                .on("mouseleave", () => {
                    // this._element.popover("hide");
                });
        } else {
            this._element.popover(options);
        }
        this.registerDocumentClick();
    }

    public detached(): void {
        this.destroyPopover();

    }

    private destroyPopover(): void {
        this._element
            .popover("dispose")
            .off("mouseenter")
            .off("mouseleave");
    }

    public registerDocumentClick() {
        document.addEventListener("click", (evt: MouseEvent) => {
            setTimeout(() => {
                let isNotPopOver = $(evt.target as Element).parents(this._defaultClassName).length == 0;
                let isNotElement = this._element.find(evt.target).length == 0;
                if (isNotPopOver && isNotElement) {
                    this._element
                        .popover("hide");
                }
            }, this.hideWait);
        });
    }

    /*
    * Dynamic content change
    */
    public contentChanged(): void {
        this._element.popover("hide");
        this._element.attr("data-original-title", this.title);

        if (this.showOnChange.toString() === "true") {
            this._element.popover("show");
        }
    }

}
