import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { VolunteerActivity } from "../../../models/volunteer-activity";
import { Moment } from "moment";
import * as moment from "moment";

@autoinject
@customElement("volunteer-activity-form")
export class VolunteerActivityForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public volunteerActivity: VolunteerActivity;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerOptions: ITypeaheadOptions[] = [];
    public decimalFormat: number = 2;
    public providerValidation: IValidateCustomElement = {
        required: true,
        message: "Associated Agency Provider is required"
    };
    public today: Moment = moment();
    public datePickerOptions: DatepickerOptions = {
        autoclose: true
    };
}
