import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IGetBehavior } from "../../../../interfaces/form-builder/i-behavior";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IValidation } from "../../../../interfaces/form-builder/i-validation";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { GetBehaviorComponent, GetBehaviorHandler } from "../../behavior-handlers/get-behavior-handler";
import { NoteDataManager } from "../../note-data-manager";
import { BehaviorTypesEnum } from "../../behavior-handlers/behavior-types-enum";
import { EnumsService } from "../../../../services/enums-service";
import { EnumMap } from "../../../../common/utilities/enum-map";
import { PhysiciansService } from "../../../../services/physician-service";

@autoinject
@customElement("single-select-get-dropdown")
export class SingleSelectGetDropDown {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryData: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public dataObject: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<SingleSelectGetDropDown>("viewOptionsChanged") })
    public viewOptions: IInputOptions;
    @observable({
        changeHandler: nameof<SingleSelectGetDropDown>("typeaheadResultChanged")
    })
    public typeaheadResult: ITypeaheadOptions;
    private _getBehaviorInfo: IGetBehavior;
    private _getBehaviorHandler: GetBehaviorHandler;
    private readonly _enumsService: EnumsService;
    private readonly _physicianService: PhysiciansService;
    public isValidationLoading: boolean = true;
    public validation: IValidateCustomElement;
    public showStatus: boolean = false;
    public noteDataManager: NoteDataManager;
    public inactiveStatus: number = null;

    public constructor(
        getBehaviorHandler: GetBehaviorHandler,
        noteDataManager: NoteDataManager,
        enumsService: EnumsService,
        physicianService: PhysiciansService
    ) {
        this._getBehaviorHandler = getBehaviorHandler;
        this.noteDataManager = noteDataManager;
        this._enumsService = enumsService;
        this._physicianService = physicianService;
    }

    public viewOptionsChanged() {
        if (!!this.viewOptions) {
            this._getBehaviorInfo = this.viewOptions.behaviors.find((behavior) => {
                return (
                    behavior.behaviorType === BehaviorTypesEnum.Get ||
                    behavior.behaviorType === BehaviorTypesEnum.GetMultiTarget
                );
            }) as IGetBehavior;
            this.getStatus();
        }
    }

    public async getStatus() {
        if (!this._getBehaviorInfo?.component) {
            return;
        }
        try {
            switch (this._getBehaviorInfo.component) {
                case GetBehaviorComponent.Employees:
                    let userStatusEnumMap = new EnumMap(await this._enumsService.getUserStatus());
                    this.inactiveStatus = Number(userStatusEnumMap.getEnumValue("inactive"));
                    this.showStatus = true;
                    break;
                case GetBehaviorComponent.IncludeInactivePhysician:
                    let physicianStatusEnumMap = new EnumMap(await this._enumsService.getPhysicianStatus());
                    this.inactiveStatus = Number(physicianStatusEnumMap.getEnumValue("deleted"));
                    this.showStatus = true;
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    public getAppend(item: ITypeaheadOptions) {
        if (item?.status && this.inactiveStatus == Number(item?.status)) {
            return ` <span class="badge badge-pill badge-danger">Inactive</span>`;
        }
        return "";
    }

    public resultChanged(newValue: string, oldValue: string) {
        if (oldValue === undefined && newValue) {
            this.initTypeAheadResult();
        }
    }

    public secondaryDataChanged(newValue: string, oldValue: string) {
        if (oldValue === undefined && newValue) {
            this.initTypeAheadResult();
        }
    }

    private async initTypeAheadResult() {
        if (this._getBehaviorInfo.component === GetBehaviorComponent.PecosCertifiedPhysicians) {
            if (this.secondaryData === undefined && !!this.result) {
                await this.getPhysiciansDetails(this.result);
            }
        }
        this.typeaheadResult = {
            name: this.secondaryData,
            value: this.result,
            dataObject: this.dataObject
        };
    }

    public async getPhysiciansDetails(id: string) {
        let physician = await this._physicianService.getPhysicianById(id);
        let pecosVerifyStatus = physician.isPecosVerified ? "is PECOS verified" : "is not PECOS verified";
        let formatName = `${physician?.lastName?.toLocaleUpperCase()}, ${physician?.firstName?.toLocaleUpperCase()}`;
        this.secondaryData = `${formatName} - NPI: ${physician.npiNumber} ${pecosVerifyStatus}`;
    }

    public typeaheadResultChanged(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions) {
        if (newValue || oldValue) {
            if (newValue) {
                this.result = newValue.value;
                this.secondaryData = newValue.name;
                this.dataObject = newValue.dataObject;
            } else {
                this.result = "";
                this.secondaryData = "";
                this.dataObject = null;
            }
        }
    }

    public attached() {
        this.initValidation();
    }

    public initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IValidation) => {
            if (rule.validationType == FormValidationEnum.Required) {
                this.validation = {
                    required: true,
                    displayName: this.viewOptions.name,
                    message: rule.message
                };
            }
        });
        this.isValidationLoading = false;
    }

    public async getList(filter: string, limit: number) {
        let providerIds = this.noteDataManager.getProviderIds();
        return await this._getBehaviorHandler.getList(
            this._getBehaviorInfo,
            filter,
            limit,
            providerIds,
            this.noteDataManager.getPatientId()
        );
    }
}
