import "./loading-indicator.scss";

import { bindable, bindingMode } from "aurelia-framework";

export class LoadingIndicator {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public loading: boolean = false;

    public activate() {
        console.log("Loading Indicator :: Activated");
    }
}
