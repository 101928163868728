import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { IPermissionCheck } from "../../common/utilities/permission-manager";
import { AdministrativePermissionEnum } from "../../enums/administrative-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";

const idgKeyWords = [
    "idg",
    "team",
    "group",
    "interdisciplinary",
    "meeting",
    "summary",
    "agenda",
    "schedule",
    "conference"
];

export const bereavementCenterPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.BereavementCenter,
        action: PermissionActionEnum.View
    }
];

export const CentersRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "bereavement-center"
    },
    {
        name: "bereavement-center",
        route: "bereavement-center/:id?",
        moduleId: PLATFORM.moduleName("./bereavement-center/bereavement-center", "bereavement-center"),
        href: "centers/bereavement-center/load",
        nav: true,
        title: "Bereavement Center",
        settings: {
            menuName: "Bereavement Center",
            keywords: idgKeyWords,
            isPalliativeCare: false,
            permission: bereavementCenterPermission
        }
    },
    {
        name: "idg-center",
        route: "idg-center/:id?",
        moduleId: PLATFORM.moduleName("./idg-center/idg-center", "idg-center"),
        href: "centers/idg-center/load",
        nav: true,
        title: "Interdisciplinary Group (IDG)",
        settings: {
            menuName: "Interdisciplinary Group (IDG) Center",
            keywords: idgKeyWords
        }
    },
    /* {
        name: "idg",
        route: "idg",
        moduleId: PLATFORM.moduleName("./idg-dashboard/idg-dashboard", "idg-dashboard"),
        nav: true,
        title: "Interdisciplinary Group (IDG)",
        settings: {
            menuName: "Interdisciplinary Group (IDG)",
            keywords: ["idg", "team", "group", "interdisciplinary", "meeting", "summary", "agenda", "schedule", "conference"],
            inProgressPage: true
        }
    }, */
    {
        name: "idg-meeting",
        route: "idg-meeting/:id",
        moduleId: PLATFORM.moduleName("./idg-meeting/idg-meeting", "idg-meeting")
    },
    {
        name: "idg-active-meeting",
        route: "idg-active-meeting/:id",
        moduleId: PLATFORM.moduleName("./idg-active-meeting/idg-active-meeting", "idg-active-meeting")
    }
];
