export enum BehaviorTypesEnum {
    Navigation = "navigation",
    ToggleDisplay = "toggledisplay",
    CreateDocument = "createdocument",
    CreateInfectionReport = "createinfectiondocument",
    AddItem = "additem",
    DeleteItem = "deleteitem",
    Prefill = "prefill",
    ClearTargetOnValueChange = "cleartargetonvaluechange",
    Permission = "permission",
    ReadOnlyIf = "readonlyif",
    CountListGroupItems = "countlistgroupitems",
    Get = "get",
    GetMultiTarget = "get-multi-target",
    GetWoundGroup = "getwoundgroup",
    LoadNarrative = "load-narrative",
    Formula = "formula"
}
