import { valueConverter } from "aurelia-binding";

@valueConverter("isReadOnlyClaimById")
export class IsReadOnlyClaimById {
    public toView(status: number) {
        switch (status) {
            case 2: // Submitted
            case 9: // Paid
            case 13: // Processing
                return true;
            default:
                return false;
        }
    }
}
