import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { AdministrativePermissionEnum } from "../../enums/administrative-permission-enum";
import { BillingPermissionEnum } from "../../enums/billing-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";
import { DashboardPermissionEnum } from "../../enums/dashboard-permission-enum";

export const AdminRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "company-setup"
    },
    {
        name: "admin-dashboard",
        route: "dashboard",
        moduleId: PLATFORM.moduleName("./admin-dashboard/admin-dashboard", "admin-dashboard"),
        nav: true,
        title: "Admin Dashboard",
        settings: {
            menuName: "Admin Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.AdminDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "accelerated-payment-dashboard",
        route: "accelerated-payment-dashboard",
        moduleId: PLATFORM.moduleName(
            "./accelerated-payment-dashboard/accelerated-payment-dashboard",
            "accelerated-payment-dashboard"
        ),
        nav: true,
        title: "Accelerated Payment Dashboard",
        settings: {
            menuName: "Accelerated Payment Dashboard",
            permission: [
                {
                    resource: AdministrativePermissionEnum.CompanySetup,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "company-setup",
        route: "company-setup",
        moduleId: PLATFORM.moduleName("./company-setup/company-setup", "company-setup"),
        nav: true,
        title: "Company Setup",
        settings: {
            menuName: "Company Setup",
            keywords: [
                "setup",
                "setting",
                "agency",
                "account",
                "tax",
                "payer",
                "branch",
                "location",
                "team",
                "group",
                "financial",
                "billing",
                "intake",
                "company"
            ],
            permission: [
                {
                    resource: AdministrativePermissionEnum.CompanySetup,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "partner-connections",
        route: "partner-connections",
        moduleId: PLATFORM.moduleName("./partner-connections/partner-connections", "partner-connections"),
        nav: true,
        title: "Partner Connections",
        settings: {
            menuName: "Partner Connections",
            permission: [
                {
                    resource: AdministrativePermissionEnum.PartnerConnections,
                    action: PermissionActionEnum.View
                }
            ],
            isPalliativeCare: false
        }
    },
    {
        name: "data-load-details",
        route: "data-load-details/:id",
        moduleId: PLATFORM.moduleName("../data-load/data-load-details", "data-load-details")
    },
    {
        name: "data-load",
        route: "data-load",
        moduleId: PLATFORM.moduleName("../data-load/data-load", "data-load")
    },
    {
        name: "closing-of-accounts",
        route: "closing-of-accounts",
        moduleId: PLATFORM.moduleName("./closing-of-accounts/closing-of-accounts", "closing-of-accounts"),
        settings: {
            permission: [
                {
                    resource: BillingPermissionEnum.OverrideClosedAccounting,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "chart-of-accounts",
        route: "chart-of-accounts",
        moduleId: PLATFORM.moduleName("./chart-of-accounts/chart-of-accounts", "chart-of-accounts")
    },
    {
        name: "activity-log",
        route: "activity-log",
        moduleId: PLATFORM.moduleName("./activity-log/activity-log", "activity-log"),
        nav: true,
        title: "Activity Log",
        settings: {
            menuName: "Activity Log",
            permission: [
                {
                    resource: AdministrativePermissionEnum.ActivityLog,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "quality-assurance-settings",
        route: "quality-assurance-settings",
        moduleId: PLATFORM.moduleName(
            "./quality-assurance-settings/quality-assurance-settings",
            "quality-assurance-settings"
        ),
        nav: true,
        title: "Quality Assurance (QA) Settings",
        settings: {
            menuName: "Quality Assurance (QA) Settings",
            permission: [
                {
                    resource: AdministrativePermissionEnum.CompanySetup,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    /* {
        name: "volunteer-center",
        route: "volunteer-center/:id?",
        moduleId: PLATFORM.moduleName("../employees/volunteer-center/volunteer-center", "volunteer-center"),
        href: "admin/volunteer-center/load",
        nav: true,
        title: "Volunteer Center",
        settings: {
            menuName: "Volunteer Center"
        }
    }, */
    {
        name: "prior-periods",
        route: "prior-periods",
        moduleId: PLATFORM.moduleName("./prior-periods/prior-periods", "prior-periods"),
        settings: {
            permission: [
                {
                    resource: BillingPermissionEnum.OverrideClosedAccounting,
                    action: PermissionActionEnum.View
                }
            ]
        }
    }
];
