import { FormBuilderEvent, NoteDataManager } from "../../note-data-manager";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { EnumsService } from "../../../../services/enums-service";
import { IGetWoundGroupBehavior } from "../../../../interfaces/form-builder/i-behavior";
import { IFormModel } from "../../../../interfaces/form-builder/i-model-schema";
import { IWoundBodyLocation } from "../../../../interfaces/i-wound-body";

export class WoundBodyStore {
    private readonly _enumsService: EnumsService;
    private readonly _noteDataManager: NoteDataManager;
    private readonly _ea: EventAggregator;
    private readonly _reset: (wounds: IWoundBodyLocation[]) => void;
    private readonly _listGroupName: string;
    private readonly _woundBodyBehavior: IGetWoundGroupBehavior;
    private readonly _subscription: Subscription[] = [];
    private readonly _wounds: IWoundBodyLocation[] = [];
    private _noteModel: IFormModel;

    public constructor(
        enumsService: EnumsService,
        noteDataManager: NoteDataManager,
        ea: EventAggregator,
        woundBodyBehavior: IGetWoundGroupBehavior,
        listGroupName: string,
        reset: (wounds: IWoundBodyLocation[]) => void
    ) {
        this._enumsService = enumsService;
        this._noteDataManager = noteDataManager;
        this._ea = ea;
        this._reset = reset;
        this._woundBodyBehavior = woundBodyBehavior;
        this._listGroupName = listGroupName;
    }

    public initialize() {
        this._noteModel = this._noteDataManager.getNoteModel();
        Object.keys(this._noteModel).forEach((key) => this.addData(key));
        this.initSubscriptions();
    }

    private initSubscriptions() {
        this._subscription.push(this._ea.subscribe(FormBuilderEvent.PropDataUpdated, this.addData.bind(this)));
        this._subscription.push(
            this._ea.subscribe(FormBuilderEvent.ModifiedDataPropDeleted, this.deleteData.bind(this))
        );
    }

    private addData(prop: string) {
        if (!prop) {
            return;
        }
        let modifiedData = this._noteDataManager.getModifiedData();
        let propSplit = prop.split(".");
        if (propSplit.length > 2 && propSplit[0] === this._listGroupName) {
            let index = parseInt(propSplit[1], 10);
            if (!this._wounds[index]) {
                this._wounds[index] = {};
            }
            let newValue = (modifiedData[prop] ?? this._noteModel[prop]) as string;
            newValue = newValue != null ? String(newValue) : newValue;
            if (!newValue && propSplit[2].toLowerCase() !== this._woundBodyBehavior.locationSearchType.toLowerCase()) {
                this.deleteData(prop);
                return;
            }
            switch (propSplit[2].toLowerCase()) {
                case this._woundBodyBehavior.woundNumber.toLowerCase():
                    this._wounds[index].woundNumber = newValue;
                    break;
                case this._woundBodyBehavior.xCoordinate.toLowerCase():
                    this._wounds[index].xCoordinate = newValue;
                    break;
                case this._woundBodyBehavior.yCoordinate.toLowerCase():
                    this._wounds[index].yCoordinate = newValue;
                    break;
                case this._woundBodyBehavior.woundSide.toLowerCase():
                    this._wounds[index].woundSide = newValue;
                    break;
                case this._woundBodyBehavior.locationSearchType.toLowerCase():
                    this._wounds[index].woundLocationSearch = newValue;
                    break;
            }
            if (
                this._wounds[index].woundSide &&
                this._wounds[index].yCoordinate &&
                this._wounds[index].xCoordinate &&
                this._wounds[index].woundNumber
            ) {
                this._reset(this._wounds);
            }
        }
    }

    private deleteData(prop: string) {
        if (!prop) {
            return;
        }
        let propSplit = prop.split(".");
        if (propSplit.length > 2 && propSplit[0] === this._listGroupName) {
            let index = parseInt(propSplit[1], 10);
            switch (propSplit[2].toLowerCase()) {
                case this._woundBodyBehavior.woundNumber.toLowerCase():
                    delete this._wounds[index].woundNumber;
                    break;
                case this._woundBodyBehavior.xCoordinate.toLowerCase():
                    delete this._wounds[index].xCoordinate;
                    break;
                case this._woundBodyBehavior.yCoordinate.toLowerCase():
                    delete this._wounds[index].yCoordinate;
                    break;
                case this._woundBodyBehavior.woundSide.toLowerCase():
                    delete this._wounds[index].woundSide;
                    break;
                case this._woundBodyBehavior.locationSearchType.toLowerCase():
                    delete this._wounds[index].woundLocationSearch;
                    break;
            }
            if (
                !this._wounds[index].woundSide ||
                !this._wounds[index].yCoordinate ||
                !this._wounds[index].xCoordinate ||
                !this._wounds[index].woundNumber
            ) {
                this._reset(this._wounds);
            }
        }
    }

    public detached() {
        if (this._subscription?.length > 0) {
            this._subscription.forEach((sub) => sub.dispose());
        }
    }
}
