import "./create-layout.scss";
import { bindable, bindingMode, containerless } from "aurelia-framework";

@containerless
export class CreateLayout {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
}
