import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";

@autoinject
export class DisplayToggleBehaviorHandler {
    private _ea: EventAggregator;

    public constructor(ea: EventAggregator) {
        this._ea = ea;
    }

    public handle(currentValue: boolean, elementName: string) {
        let result = !currentValue;
        this._ea.publish("dependency:modified", {
            elementName,
            value: result
        });
        return result;
    }
}
