import * as moment from "moment";
import { Moment } from "moment";

class MomentValueConverterClass {
    public dateOrStringToMoment(date: Date | string | Moment, format: string = "MM/DD/YYYY") {
        if (date) {
            if (moment.isMoment(date)) {
                return date;
            } else {
                return moment(date as any);
            }
        } else {
            return undefined;
        }
    }

    public dateOrStringToISOString(date: Date | string | Moment, format: string = "MM/DD/YYYY"): string {
        if (date) {
            if (moment.isMoment(date)) {
                return date.toISOString();
            }

            return moment(date as any, format).toISOString();
        } else {
            return undefined;
        }
    }

    public momentToDateString(momentValue: Moment): string {
        if (momentValue) {
            return moment(momentValue).format("MM/DD/YYYY");
        } else {
            return undefined;
        }
    }

    public momentToDataTimeString(momentValue: Moment): string {
        if (momentValue) {
            return moment(momentValue).format("MM/DD/YYYY");
        } else {
            return undefined;
        }
    }

    public momentToISOString(momentValue: Moment): string {
        if (momentValue) {
            return moment(momentValue).toISOString();
        } else {
            return undefined;
        }
    }
}

export const MomentValueConverter = new MomentValueConverterClass();
