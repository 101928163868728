import { Disposable } from "aurelia-binding";
import { autoinject, bindable, BindingEngine, bindingMode, customElement, observable } from "aurelia-framework";
import jquery from "jquery";
import nameof from "../../../common/nameof";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import "./multiselect-dropdown.scss";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { ValidationRules } from "aurelia-validation";

@customElement("multiselect-dropdown")
@autoinject
export class MultiselectDropdown {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    // Message shown when no option selected
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "Select Options";
    // String appended to message when multiple options selected
    @bindable({ defaultBindingMode: bindingMode.toView })
    public name: string = "Options";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasActions: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasCustomView: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public activeClasses: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<MultiselectDropdown>("optionsChanged")
    })
    public options: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<MultiselectDropdown>("selectedItemsChanged")
    })
    public selectedItems: string[] | number[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validation: IValidateCustomElement = {
        required: false
    };
    private _bindingEngine: BindingEngine;
    public dropdownElement: HTMLElement;
    public dropdown: HTMLElement;
    public selectedItemsSubscription: Disposable;
    public message: string = "";
    public isActive: boolean = false;

    public constructor(bindingEngine: BindingEngine) {
        this._bindingEngine = bindingEngine;
    }

    public async attached() {
        this.initSelectedItemsSubscription();
        jquery(this.dropdownElement).on("click.bs.dropdown", (e) => {
            if (e.target.nodeName != "BUTTON") {
                e.stopPropagation();
            }
        });
        jquery(this.dropdown).on("hide.bs.dropdown", (e) => {
            this.toggleActiveClasses(false);
        });

        if (this.validation?.required) {
            this.initValidation();
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: MultiselectDropdown) => x.selectedItems)
            .minItems(1)
            .withMessage(`${this.validation.message}`)
            .on(this);
    }

    private initSelectedItemsSubscription() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.dispose();
        }
        this.selectedItemsSubscription = this._bindingEngine
            .collectionObserver(this.selectedItems)
            .subscribe(() => this.updateMessage());
    }

    private updateMessage() {
        if (!this.selectedItems || this.selectedItems.length === 0) {
            this.message = this.placeholder;
        } else if (this.selectedItems.length === 1) {
            let selectedOption = this.options.find((option) => option.value === this.selectedItems[0]);
            this.message = selectedOption?.name;
        } else if (this.selectedItems.length > 1) {
            // since name is the unique id for the component, don't show in label
            this.message = `${this.selectedItems.length} Selected`;
        }
    }

    public toggleActiveClasses(value: boolean) {
        this.isActive = value;
    }

    public selectedItemsChanged() {
        // Subscription is lost after binding
        this.initSelectedItemsSubscription();
        this.updateMessage();
    }

    public optionsChanged() {
        this.updateMessage();
    }

    public selectAll() {
        this.selectedItems = this.options.map((option) => option.value);
    }

    public deselectAll() {
        this.selectedItems = [];
    }

    public detached() {
        if (this.selectedItemsSubscription) {
            this.selectedItemsSubscription.dispose();
        }
    }
}
