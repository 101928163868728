import { bindingMode } from "aurelia-binding";
import { autoinject, bindable, customElement } from "aurelia-framework";
import { Router } from "aurelia-router";

import { IPlanOfCare } from "../../../interfaces/i-plan-of-care";
import { ToastrService } from "../../../services/toastr-service";

@autoinject
@customElement("problem-area-group")
export class ProblemAreaGroup {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public problemArea: IPlanOfCare;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public noteId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showProblemArea: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isValidOrderLinked: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public toggleProblemArea: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isUpdateEnabled: boolean = false;
    private readonly _router: Router;
    private readonly _toastrService: ToastrService;

    public constructor(router: Router, toastrService: ToastrService) {
        this._router = router;
        this._toastrService = toastrService;
    }

    public editProblemArea() {
        let routeParams = this._router.currentInstruction.params;
        if (routeParams && routeParams.id && this.problemArea.id && this.noteId) {
            this._router.navigate(
                `/notes/problem-area/${this.problemArea.id}?patientId=${routeParams.id}&noteId=${this.noteId}`
            );
        } else {
            this._toastrService.error({
                title: `Error - Edit Problem`,
                message: `There was a problem while editing ${this.problemArea.name} problem. Please try again.`
            });
        }
    }
}
