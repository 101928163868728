import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { EnumMap } from "../common/utilities/enum-map";
import { IGetAccountsModel, IGetAccountsQuery, IGetAccountsResult } from "../interfaces/i-get-accounts";
import { Account } from "../models/account";
import { FinancialIntake } from "../models/financial-intake";
import { AuthService } from "./auth-service";
import { EnumsService } from "./enums-service";
import {
    IActivityLog,
    IActivityLogDetail,
    IGetActivityLogs,
    ICreateQaSetting,
    IGetQaSettings,
    IGetQaSettingsQuery,
    IGetActivityLogCategories
} from "./i-accounts-service";
import { IPaginationNew } from "../interfaces/i-pagination";
import { config } from "../common/config";
import Infrastructure from "../common/infrastructure";
import { UsersService } from "./users-service";

@autoinject()
export class AccountsService {
    private _httpClient: HospiceClient;
    private _enumService: EnumsService;
    private _usersService: UsersService;
    private _authService: AuthService;
    private _baseUrl: string = "/api/v1/accounts";
    private _palliativeCareValue: number = null;
    private _cacheAccountId: string = "";
    private _cacheLineOfService: number = null;
    private _cacheIsPalliativeCare: boolean = null;

    public constructor(
        httpClient: HospiceClient,
        authService: AuthService,
        enumService: EnumsService,
        usersService: UsersService
    ) {
        this._httpClient = httpClient;
        this._authService = authService;
        this._enumService = enumService;
        this._usersService = usersService;
    }

    public async getAccounts(query: IGetAccountsQuery) {
        return await this._httpClient
            .build(this._baseUrl)
            .useQueryString<IGetAccountsQuery>(query)
            .fetch<IGetAccountsResult>();
    }

    public async getAllAccounts() {
        // TODO: Cache accounts like current user service does
        let accounts: IGetAccountsModel[] = [];
        let needPages: boolean;
        let currentPage = 1;
        do {
            let page = await this.getAccounts({
                page: currentPage,
                pageLength: 25
            });

            accounts = accounts.concat(page.items);
            needPages = page.pageCount > currentPage;
            currentPage++;
        } while (needPages);

        return accounts;
    }

    public async getAccountInfo() {
        // NOTE: remove if condition if we no longer need to hide accounts for Liberty Agency
        let res = await this._httpClient.build(`${this._baseUrl}/meta`).fetch<Account>();
        // NOTE: giving access to split solution for only axxess users. if no longer needed, remove current user check below
        let currentUser = await this._usersService.getCurrentUser();
        if (currentUser?.email.endsWith("@axxess.com")) {
            return res;
        }
        let isProd = Infrastructure.isProdEnvironment;
        let libertyHidingStartDate = "2024-01-26T20:00:00";
        let todaysDate = new Date();
        let todaysDateEST = todaysDate.toLocaleString("en-US", { timeZone: "America/New_York" });
        if (
            config.libertyAccountId === this._cacheAccountId &&
            new Date(todaysDateEST) > new Date(libertyHidingStartDate) &&
            isProd
        ) {
            let lineOfServiceEnum = await this._enumService.getLineOfService();
            let lineOfServiceEnumMap = new EnumMap(lineOfServiceEnum);
            res.providers = res.providers.filter((provider) =>
                lineOfServiceEnumMap.checkEnumValue("Hospice", provider.lineOfService)
            );
        }
        return res;
    }

    public async editAccount(accountInfo: Account) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("PUT").useBody(accountInfo).fetchNoContent();
    }

    public async getAccountCompanyInfo(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/companyinformation`)
            .useQueryString({ providerId })
            .fetch<FinancialIntake>();
    }

    public async editAccountCompanyInfo(accountCompanyInfo: any) {
        return await this._httpClient
            .build(`${this._baseUrl}/companyinformation`)
            .useBody({ accountCompanyInfo })
            .fetchNoContent();
    }

    public async getIsPalliativeAccount() {
        let accountId = await this._authService.getAccountId();
        if (this._cacheIsPalliativeCare === null || accountId !== this._cacheAccountId) {
            await this.updateAccountCache();
        }
        return this._cacheIsPalliativeCare;
    }

    public async getLineOfService() {
        let accountId = await this._authService.getAccountId();
        if (this._cacheLineOfService === null || accountId !== this._cacheAccountId) {
            await this.updateAccountCache();
        }
        return this._cacheLineOfService;
    }

    public clearLineOfService() {
        this._cacheLineOfService = null;
        this._cacheIsPalliativeCare = null;
    }

    private async updateAccountCache() {
        let lineOfService = this._authService.getLineOfService();
        if (this._palliativeCareValue === null) {
            let lineOfServiceEnum = await this._enumService.getLineOfService();
            let lineOfServiceEnumMap = new EnumMap(lineOfServiceEnum);
            this._palliativeCareValue = lineOfServiceEnumMap.getEnumValue("Palliative Care") as number;
        }
        this._cacheIsPalliativeCare = lineOfService === this._palliativeCareValue;
        this._cacheLineOfService = lineOfService;
        this._cacheAccountId = await this._authService.getAccountId();
    }

    public async getQaSettings(query: IGetQaSettingsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa-settings/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IPaginationNew<IGetQaSettings>>();
    }

    public async createQaSetting(query: ICreateQaSetting) {
        return await this._httpClient.build(`${this._baseUrl}/qa-settings`).useMethod("POST").useBody(query).fetch();
    }

    public async editQaSetting(qaSettingId: string, query: ICreateQaSetting) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa-settings/${qaSettingId}`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async deleteQaSetting(qaSettingId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa-settings/${qaSettingId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getActivityLogs(query: IGetActivityLogs) {
        return await this._httpClient
            .build(`${this._baseUrl}/activity-logs`)
            .useQueryString(query)
            .fetch<IPaginationNew<IActivityLog>>();
    }

    public async getActivityLogDetails(activityLogId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/activity-log/${activityLogId}/details`)
            .fetch<IActivityLogDetail[]>();
    }

    public async getAccountActivityLogCategories() {
        return await this._httpClient
            .build(`${this._baseUrl}/activity-log-categories`)
            .fetch<IGetActivityLogCategories>();
    }
}
