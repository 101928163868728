import { autoinject } from "aurelia-framework";
import { config } from "../common/config";
import { HospiceClient } from "../common/hospice-client";
import { IGetAmericanStateCode } from "../interfaces/i-american-state-code";
import { IGetCahpsVendor } from "../interfaces/i-cahps-vendor";
import { IDiagnosisDetails, IGetDiagnosisQuery } from "../interfaces/i-diagnosis";
import { IGetInteractionResult } from "../interfaces/i-get-interactions";
import { IPermissionDependency } from "../interfaces/i-permission-dependency";
import { IPhoneNumberOption } from "../interfaces/i-phone-number-option";
import { IUserPermissionGroup } from "../interfaces/i-user";
import { IGetCountryCode } from "../interfaces/lookup/i-country-code";
import { IGetCountryPhoneCode } from "../interfaces/lookup/i-country-phone-code";
import { IAmericanStates, IGetAmericanState } from "../interfaces/lookup/i-american-states";
import { IAnatomicLocation } from "../interfaces/lookup/i-anatomic-location";
import { ILanguage } from "../interfaces/lookup/i-language";

export enum DiagnosisQueryTypeEnum {
    code = "icd10code" as any,
    desc = "icd10diagnosis" as any
}

@autoinject()
export class LookupService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/lookup";
    private _baseUrlV2: string = "/api/v2/lookup";
    public stateListCacheKey = config.stateListCacheKey;
    public countryListCacheKey = config.countryListCacheKey;
    public countryPhoneCodeListCacheKey = config.countryPhoneCodeListCacheKey;

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getIcd10Codes(query: IGetDiagnosisQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/icd10codes`)
            .useQueryString(query)
            .fetch<IDiagnosisDetails[]>();
    }

    public async drugInteractions(drugs: string[], isDetailed: boolean = true) {
        return await this._httpClient
            .build(`${this._baseUrl}/interactions`)
            .useMethod("POST")
            .useBody({
                drugsSelected: drugs,
                isDetailed
            })
            .fetch<IGetInteractionResult>();
    }

    public async getCahpsVendors() {
        return await this._httpClient.build(`${this._baseUrl}/cahpsvendors`).fetch<IGetCahpsVendor[]>();
    }

    public async getPermissionGroups() {
        return await this._httpClient.build(`${this._baseUrl}/permissiongroups`).fetch<IUserPermissionGroup[]>();
    }

    public async getAmericanStateCodes(): Promise<IGetAmericanStateCode[]> {
        let cachedData = JSON.parse(localStorage.getItem(this.stateListCacheKey));
        if (cachedData) {
            return cachedData;
        }

        let stateList = await this._httpClient
            .build(`${this._baseUrl}/americanstatecodes`)
            .fetch<IGetAmericanStateCode[]>();
        localStorage.setItem(config.stateListCacheKey, JSON.stringify(stateList));
        return stateList;
    }

    public async getAmericanStates(query: IGetAmericanState) {
        return await this._httpClient
            .build(`${this._baseUrl}/americanstates`)
            .useQueryString(query)
            .fetch<IAmericanStates[]>();
    }

    public async getPermissionDependencies() {
        return await this._httpClient.build(`${this._baseUrl}/permissiondependencies`).fetch<IPermissionDependency>();
    }

    public async getCountryCodes(): Promise<IGetCountryCode[]> {
        let cachedData = JSON.parse(localStorage.getItem(this.countryListCacheKey));
        if (cachedData) {
            return cachedData;
        }

        let countryList = await this._httpClient.build(`${this._baseUrl}/countries`).fetch<IGetCountryCode[]>();
        localStorage.setItem(config.countryListCacheKey, JSON.stringify(countryList));
        return countryList;
    }

    public async getUnitedStatesCode(): Promise<string> {
        let countryList = await this.getCountryCodes();
        let unitedStates = countryList.find((country) => country.name.toLowerCase() === "united states of america");
        return unitedStates?.value;
    }

    public async getPhoneTypeOptions() {
        return await this._httpClient.build(`${this._baseUrl}/phone-number-type`).fetch<IPhoneNumberOption[]>();
    }

    public async getCountryPhoneCodes(): Promise<IGetCountryPhoneCode[]> {
        let cachedData = JSON.parse(localStorage.getItem(this.countryPhoneCodeListCacheKey));
        if (cachedData) {
            return cachedData;
        }

        let countryPhoneCodeList = await this._httpClient
            .build(`${this._baseUrl}/country-phone-codes`)
            .fetch<IGetCountryPhoneCode[]>();
        localStorage.setItem(config.countryPhoneCodeListCacheKey, JSON.stringify(countryPhoneCodeList));
        return countryPhoneCodeList;
    }

    public async getAnatomicLocation(searchTerm: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/anatomic-locations`)
            .useQueryString({ searchTerm })
            .fetch<IAnatomicLocation[]>();
    }

    public async getLanguages(term: string) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/languages`)
            .useQueryString({ term })
            .fetch<ILanguage[]>();
    }

    public async getLanguageNames(codes: string[]) {
        return await this._httpClient
            .build(`${this._baseUrlV2}/languages/names`)
            .useQueryString({ codes })
            .fetch<ILanguage[]>();
    }
}
