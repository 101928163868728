import { bindable, bindingMode, containerless } from "aurelia-framework";
import "./list-layout.scss";

@containerless
export class ListLayout {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public formTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasTitle: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasHeader: boolean = true;
}
