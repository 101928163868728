import "./report-list-layout.scss";

import { autoinject, bindable, bindingMode, customElement, containerless } from "aurelia-framework";
import { Router } from "aurelia-router";

@autoinject
@containerless
@customElement("report-list-layout")
export class ReportListLayout {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public formTitle: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showHeader: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasTitle: boolean = true;
    public router: Router;

    public constructor(router: Router) {
        this.router = router;
    }

    public goToReportCenter() {
        this.router.navigate("/reports/report-center");
    }
}
