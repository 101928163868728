import { valueConverter } from "aurelia-framework";

@valueConverter("formatPhoneNumber")

export class FormatPhoneNumber {
    public toView(value: string) {
        let result: string = value;
        if (result) {
            result = value.replace(/^(\d{3})(\d+)+$/, "($1) $2");
            result = result.replace(/^\((\d{3})\) (\d{3})(\d+)+$/, "($1) $2-$3");
        }
        return result;
    }

    public fromView(value: string) {
        return value.replace(/^\((\d{3})\) (\d{3})-(\d{4})+$/, "$1$2$3");
    }
}
