import "./dialog-content.scss";

import { DialogController } from "aurelia-dialog";
import { bindable, bindingMode, containerless, customElement } from "aurelia-framework";

@customElement("dialog-content")
@containerless
export class DialogContent {
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public options: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public controller: DialogController;
}
