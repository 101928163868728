import "./app-navbar.scss";

import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { DialogOpenResult, DialogService, DialogSettings } from "aurelia-dialog";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { NavModel, RouteConfig, Router } from "aurelia-router";
import { customElement } from "aurelia-templating";
import { User, UserManager } from "oidc-client";

import { config } from "../../common/config";
import nameof from "../../common/nameof";
import { OidcSignOut, UserLoaded } from "../../common/oidc/oidc-event-handler";
import { isRouteAllowed } from "../../common/utilities/is-route-allowed";
import { PermissionManager, PermissionEvent } from "../../common/utilities/permission-manager";
import { Alert, IAlertOptions } from "../../resources/dialogs/alert/alert";
import { IPromptOptions, Prompt } from "../../resources/dialogs/prompt/prompt";
import { AccountsService } from "../../services/accounts-service";
import { AccountEvent, AuthService } from "../../services/auth-service";
import { ToastrService } from "../../services/toastr-service";
import { SignalrReactor } from "../../signalr/signalr-reactor";
import { Permission } from "../../models/permission";
import { ProvidersService } from "../../services/providers-service";

@autoinject()
@customElement("app-navbar")
export class AppNavbar {
    private _providersService: ProvidersService;
    private _toastrService: ToastrService;
    private _accountsService: AccountsService;
    private _authService: AuthService;
    private _signalR: SignalrReactor;
    private _ea: EventAggregator;
    private _dialogService: DialogService;
    private _userManager: UserManager;
    private _user: User;
    private _subscriptions: Subscription[] = [];
    private _permissionManager: PermissionManager;
    public router: Router;
    public userFirstName: string;
    public originUrl: string = window.location.origin;
    public originName: string = "AxxessHospice";
    public isLoggedIn: boolean = false;
    public isPalliativeCare: boolean = false;
    public accountName: string = "";
    public helpUrl: string = config.helpUrl;
    public softwareUpdateUrl: string = config.softwareUpdateUrl;
    public trainingWebinarUrl: string = config.trainingWebinarUrl;
    public hasMultipleAccounts: boolean = false;
    public isSearchExpanded: boolean = false;
    public accountId: string;
    public userPermissionLoaded: boolean = false;
    public providersLoaded: boolean = false;
    public axxessCentralUrl: string = config.axxessCentralUrl;
    public userCommunityUrl: string = config.userCommunityUrl;
    public followAxxessUrl: string = config.followAxxessUrl;
    public hasMultipleProviders: boolean = false;

    @computedFrom(`${nameof<AppNavbar>("router")}.${nameof<Router>("navigation")}`)
    public get activeRouteName(): string {
        if (this.router) {
            let activeRoute: NavModel = this.router.navigation.find((route) => route.isActive);
            return activeRoute ? activeRoute.settings.menuName : "";
        } else {
            return "";
        }
    }

    @computedFrom(nameof<AppNavbar>("router"))
    public get switchAccountsUrl() {
        let selectUrl = this.router.generate("oidc-select-accounts", {
            fragment: this.router.currentInstruction.fragment
        });
        return selectUrl;
    }

    public constructor(
        accountService: AccountsService,
        userManager: UserManager,
        signalR: SignalrReactor,
        authService: AuthService,
        ea: EventAggregator,
        router: Router,
        dialogService: DialogService,
        toastrService: ToastrService,
        permissionManager: PermissionManager,
        providersService: ProvidersService
    ) {
        this._toastrService = toastrService;
        this._accountsService = accountService;
        this._signalR = signalR;
        this._authService = authService;
        this._ea = ea;
        this._userManager = userManager;
        this.router = router;
        this._dialogService = dialogService;
        this._permissionManager = permissionManager;
        this._providersService = providersService;
        this.initSubscriptions();
    }

    private initSubscriptions() {
        this._subscriptions.push(this._ea.subscribe(UserLoaded, () => this.loadUser()));
        this._subscriptions.push(
            this._ea.subscribe(AccountEvent.Selected, async (accountId: string) => {
                await this.processSelectedAccount(accountId);
            })
        );
        this._subscriptions.push(
            this._ea.subscribe(AccountEvent.ProvidersSelected, async () => {
                await this.processSelectedProviders();
                // refresh permissions after selecting new provider
                await this._permissionManager.refreshUserPermissionData();
            })
        );
        this._subscriptions.push(
            this._ea.subscribe(PermissionEvent.UserPermissionLoaded, async (permissions: Permission[]) => {
                if (permissions?.length > 0) {
                    this.userPermissionLoaded = true;
                }
            })
        );
    }

    public async attached() {
        await this.loadUser();
        this.isPalliativeCare = await this._accountsService.getIsPalliativeAccount();
        let accountId = this._authService.getAccountId();
        if (!!accountId && this.isLoggedIn && this._user) {
            await this.processSelectedAccount(accountId);
            await this.processSelectedProviders();
        }
    }

    private async processSelectedAccount(accountId: string) {
        this.accountId = accountId;
        this.accountName = "";
        let hasCachedUserConflict = await this._authService.hasCachedUserConflict();
        if (!hasCachedUserConflict) {
            await this.loadAccount(accountId);
        }
    }

    private async processSelectedProviders() {
        this.providersLoaded = false;
        this.isPalliativeCare = await this._accountsService.getIsPalliativeAccount();
        this.providersLoaded = true;
    }

    private async loadUser() {
        this._user = await this._userManager.getUser();
        if (this._user) {
            this.isLoggedIn = await this._authService.isLoggedIn();
            this.userFirstName = this._user.profile.given_name;
        }
    }

    private async loadAccount(accountId: string) {
        try {
            let accounts = await this._accountsService.getAllAccounts();
            let account = accounts.find((x) => x.id == accountId);
            this.hasMultipleAccounts = accounts?.length > 1;
            if (account == null) {
                this.accountName = "Unknown Account";
            } else {
                this.accountName = account.name;
                let providerList = await this._providersService.getProvidersDefault();
                this.hasMultipleProviders = providerList?.length > 1;
            }
        } catch (error) {
            let isLoggedIn = await this._authService.isLoggedIn();
            if (isLoggedIn) {
                if (error.status === 401) {
                    let model: IAlertOptions = {
                        title: `Unauthorized User`,
                        message: `User with email <b> ${this._user.profile.email} </b> is not authorized or Invalid user credentials`
                    };
                    let options: DialogSettings = {
                        viewModel: Alert,
                        model: model
                    };
                    await this._dialogService.open(options).whenClosed(async () => {
                        await this.logOut();
                    });
                }
            }
        }
    }

    public async logOut() {
        let promptOptions: IPromptOptions = {
            title: "Log Out",
            message: "Are you sure you wish to log out?",
            okText: "Yes, Log Out",
            cancelText: "No"
        };

        let dialogOptions: DialogSettings = {
            viewModel: Prompt,
            model: promptOptions
        };

        let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
        let closeResult = await dialog.closeResult;

        if (!closeResult.wasCancelled) {
            await this._signalR.stop();
            this._ea.publish(OidcSignOut);
        }
    }

    public closeNotifications() {
        this._toastrService.clear();
    }

    public isLinkAllowed(routeConfig: RouteConfig) {
        let isPalliativeRoute = routeConfig?.settings?.isPalliativeCare;
        if (
            (isPalliativeRoute === false && this.isPalliativeCare) ||
            (isPalliativeRoute === true && !this.isPalliativeCare)
        ) {
            return false;
        }
        if (routeConfig) {
            return isRouteAllowed(routeConfig);
        } else {
            return false;
        }
    }

    public isMenuAllowed(route: NavModel) {
        if (route) {
            return this.isLinkAllowed(route.config);
        } else {
            return false;
        }
    }

    public hasPermission(routeConfig: RouteConfig) {
        if (!routeConfig) {
            return false;
        }
        if (!!routeConfig?.settings?.permission) {
            return this._permissionManager.checkRoutePermission(routeConfig);
        } else {
            return true;
        }
    }

    public navigateToAxxessCentral() {
        this.router.navigate(this.axxessCentralUrl);
    }

    public detached() {
        if (this._subscriptions.length > 0) {
            this._subscriptions.forEach((sub) => sub.dispose());
        }
    }
}
