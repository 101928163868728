import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const referralConversionRateTooltip: IReportTooltip = {
    description: "Displays the referrals, admissions, and conversion rate by admission source for a given time frame.",
    parameters: "Branch, Team, Admission Source, Date Range",
    reportIncludes: "Admission Source, Number of Referrals, Number of Admissions, Conversion Rate"
};

export const referralConversionRateByReferralSourceTooltip: IReportTooltip = {
    description: "Displays the referrals, admissions, and conversion rate by referral source for a given time frame.",
    parameters: "Provider/Branch, Team, Referral Source, Date Range",
    reportIncludes:
        " Provider/Branch, Team, Patient Name, Payer, Date of Birth, Referral Date, Status, Referral Source, Referral Source Name, Community Liaison"
};

export const percentageOfCareDaysTooltip: IReportTooltip = {
    description:
        "Displays the total number of days of care and percentage of days of care by service location for a specified date range.",
    parameters: "Branch, Team, Location of Care, Date Range",
    reportIncludes: "Service Location, Total Days of Care, Percent of Days of Care"
};

export const patientMixByDiagnosisTooltip: IReportTooltip = {
    description: "Displays the total number of admissions or re-admissions by diagnosis for a specified timeframe.",
    parameters: "Branch, Team, Date Range",
    reportIncludes:
        "Line No., Diagnosis, ICD-10-CM Codes, No. of New Admissions, Re-admissions Previously Seen by Another Hospice Program, Re-admissions Previously Seen by This Hospice Program, Total Admissions"
};

export const locationOfCareProvidedTooltip: IReportTooltip = {
    description:
        "Displays the total number of days of care for a specified date range. This report enables organizations to track the level of care days for each service location.",
    parameters: "Branch, Team, Location of Care, Date Range",
    reportIncludes:
        "Location of Care, Number of Patients Served, Days of Routine Home Care, Days of Inpatient Care, Days of Inpatient Respite Care, Days of Continuous Care, Total Patient Care Days"
};

export const averageDailyCensusTooltip: IReportTooltip = {
    description:
        " Displays the average daily census for a specified date range. This report enables organizations to evaluate census trends and identify what patients were on service during a specific time frame.",
    parameters: "Date, Branch, Team",
    reportIncludes: "Branch, Team, Last Name, First Name, Days, Average Daily Census Calculations"
};

export const censusByPrimaryPayerTooltip: IReportTooltip = {
    description: "Displays a list of patients that can be sorted and filtered by primary payer.",
    parameters: "Branch, Team, Patient Status, Payer",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Patient Status, Patient Admission Date, Primary Payer, Effective Date, Gender, Address, Mobile Number"
};

export const capStatisticsTooltip: IReportTooltip = {
    description:
        "Generates the cap statistics for the given cap year. This report will include most of the information needed to calculate the inpatient and aggregate cap amounts within the selected cap year.",
    parameters:
        "Branch, Reporting Year. Selecting different parameters will affect the results displayed in the report.",
    reportIncludes:
        "Levels of Care, Medicare Beneficiaries, Days, Charges, Electronic Payments, Other Payments, Net Medicare Payments, Net Adjustments, Total Medicare Inpatient Days, Maximum Inpatient Days Allowed, Days Under/Over Maximum Inpatient Days, Allowable Medicare Payments, Estimated Amount Under/Over Cap."
};

export const hisByStatusTooltip: IReportTooltip = {
    description: "Generates a list of all HIS sorted by status.",
    parameters: "Branch, HIS Type, Status, Date Range",
    reportIncludes: "Patient Name, Branch, HIS Type, Admit/Discharge Date, Status, Approved Date, Exported Date"
};

export const medianLengthOfStayTooltip: IReportTooltip = {
    description:
        "Displays the median length of stay for the selected date range and includes patient information for all patients who were active within that time frame.",
    parameters: "Branch, Payer, Patient Status, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Status, Payer, Terminal Diagnosis, ICD-10 Code, Admission Date, Discharge Date, Length of Stay"
};

export const averageLengthOfStayTooltip: IReportTooltip = {
    description:
        "Displays the average length of stay for the selected date range and includes patient information for all patients who were active within that time frame.",
    parameters: "Branch, Payer, Patient Status, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Status, Payer, Terminal Diagnosis, ICD-10 Code, Admission Date, Discharge Date, Length of Stay"
};

export const volunteerActivityTooltip: IReportTooltip = {
    description:
        "Displays a list of all activities provided by volunteers for a given date range. This report also includes a summary to show the total number of volunteer hours, total patient care hours, a percentage of volunteer hours, the cost savings rate and the number of volunteer hours over/under the 5% threshold. This report is vital to ensuring compliance with regulations and tracking volunteer activity.",
    parameters: "Provider, Team, Date Range, Task Type, Included in 5%, Task Status",
    reportIncludes:
        "Provider, Team, Patient Name, Task, Task Status, Date, Assigned To, Visit Time In, Visit Time Out, Total Visit Time, Cost Savings Rate, Travel Time In, Travel Time Out, Total Travel Time, Associated Mileage"
};

export const daysSinceLastVisitTooltip: IReportTooltip = {
    description:
        "Displays the number of days since the last visit for each discipline. This is helpful for tracking one of the Hospice Care Index Measures.",
    parameters: " Provider/Branch, Status, Team, Tags, Date Range",
    reportIncludes:
        "Provider/Branch, Patient Name, Status, Team, Start of Visit Gap, End of Visit Gap, Days Without a Visit, Tags"
};

export const visitsNearDeathTooltip: IReportTooltip = {
    description:
        "Displays patient visits in the last 3 days of life by LPN/LVN, RN, LMSW, BSW and MSW for a specific date range. This report is useful in monitoring Indicator 10 of the Hospice Care Index.",
    parameters: "Branch, Team, Date, Task, Task Status, Credentials",
    reportIncludes:
        "Branch, Team, Patient Name, Date of Death, Visit Date, Task, Task Status, Employee Name and Credentials"
};
