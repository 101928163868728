import { IPhoneNumber } from "../interfaces/i-phone-number";
import { ValidationRules } from "aurelia-validation";
import { IAddress } from "../interfaces/i-address";
import { Address } from "./common/address";

export class Pharmacy {
    public id: string = "";
    public name: string = "";
    public address: IAddress = new Address();
    public phoneNumbers: IPhoneNumber[] = [
        {
            number: "",
            extension: "",
            type: 0,
            country: "",
            isPrimary: true
        }
    ];
    public contactFirstName?: string = "";
    public contactLastName?: string = "";
    public email: string = "";
    public faxNumber: string = "";
    public comment: string = "";
    public lastUpdated: string;
    public associatedAgencyProviderIds: string[] = [];

    public constructor(data?: Pharmacy) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
            this.address = new Address(this.address);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: Pharmacy) => x.name)
            .required()
            .withMessage("Pharmacy Name is required.")
            .ensure((x: Pharmacy) => x.email)
            .email()
            .withMessage("Invalid Email Address")
            .ensure((x: Pharmacy) => x.faxNumber)
            .matches(/^\d{10}$/)
            .withMessage("Invalid Fax Number")
            .on(this);
    }
}
