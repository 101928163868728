import { bindable, bindingMode, containerless, customElement } from "aurelia-framework";

@containerless
@customElement("table-prompt")
export class TablePrompt {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public message: string; /* = "Are you sure want to perform this operation?" */
    @bindable({ defaultBindingMode: bindingMode.toView })
    public okText: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public name: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public operation: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public btnClass: string = "danger";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public cancelText: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public includeYes: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public okButtonDisabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public cancelButtonDisabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public ok: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public cancel: () => void;

    public performOperation() {
        this.ok();
    }

    public cancelOperation() {
        this.cancel();
    }
}
