import { valueConverter } from "aurelia-binding";

@valueConverter("claimStatusNameColor")
export class ClaimStatusNameColor {
    public toView(status: string) {
        switch (status?.toLowerCase()) {
            case "error":
            case "rejected":
                return "danger";
            case "processing":
                return "warning";
            case "submitted":
            case "paid":
                return "success";
            default:
                return "info";
        }
    }
}
