import { autoinject, customAttribute } from "aurelia-framework";
import { Key } from "ts-keycode-enum";

import { NumberOnly } from "./../number-only/number-only";

@customAttribute("date-format-only")
@autoinject
export class DateFormatOnly {
    private _element: JQuery;
    private _numberOnly: NumberOnly;

    public constructor(el: Element, numberOnly: NumberOnly) {
        this._element = $(el) as JQuery;
        this._numberOnly = numberOnly;
    }

    public attached(): void {
        this._element.on("keydown", (evt: JQueryEventObject) => {
            let check = this._numberOnly.checkIfEnteredKeyIsNumber(evt);
            if (!!check) {
                return true;
            } else {
                if (evt.keyCode === Key.ForwardSlash) {
                    return true;
                }
                return false;
            }
        });
    }
}
