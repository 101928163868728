import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    IBranchResponse,
    IGetBranchRevenueQuery,
    IGetBranchRevenueResult,
    IGetBranchSlimResult,
    IGetCurrentSubscription,
    ISubscriptionPlan,
    ISubscriptionRequest
} from "../interfaces/i-branch";
import { IPaginationNew } from "../interfaces/i-pagination";
import { AuthService } from "../services/auth-service";
import {
    ICreateIntegrationQuery,
    ICreatePharmacyIntegrationQuery,
    IGLLinkAdjustmentsRequest,
    IGLLinkAdjustmentsResult,
    IGetAllAgencyBranches,
    IGetGLAccountsQuery,
    IGetGroupedGLAccounts,
    IGetIntegrationsResult,
    IGlAccountHistory,
    IUpdateGLAccount,
    IUpdateIntegrationQuery
} from "./i-branches-service";

@autoinject()
export class BranchesService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/branches";
    private _authService: AuthService;
    private _branches: IBranchResponse[] = [];
    private _lineOfServiceBranches: IBranchResponse[] = [];

    public constructor(httpClient: HospiceClient, authService: AuthService) {
        this._httpClient = httpClient;
        this._authService = authService;
    }

    public async getAllBranches() {
        if (this._branches?.length === 0) {
            this._branches = await this.updateBranchCache();
        }
        return this._branches;
    }

    public async getBranchesByLineOfService(serviceLine?: number) {
        if (!!serviceLine) {
            return await this.updateBranchCache(serviceLine);
        }
        if (this._lineOfServiceBranches?.length === 0) {
            let lineOfService = await this._authService.getLineOfService();
            this._lineOfServiceBranches = await this.updateBranchCache(lineOfService);
        }
        return this._lineOfServiceBranches;
    }

    public async updateBranchCache(lineOfService?: number) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useQueryString({ lineOfService })
            .fetch<IBranchResponse[]>();
    }

    public clearBranchCache() {
        this._lineOfServiceBranches = [];
        this._branches = [];
    }

    public async getBranchLean(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}/slim`).fetch<IGetBranchSlimResult>();
    }

    public async getBranchRevenue(id: string, query: IGetBranchRevenueQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/revenue`)
            .useQueryString(query)
            .fetch<IGetBranchRevenueResult>();
    }

    public async getSubscriptionPlans() {
        return await this._httpClient.build(`${this._baseUrl}/subscription/available`).fetch<ISubscriptionPlan[]>();
    }

    public async getCurrentSubscription(id: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/subscription/current`)
            .fetch<IGetCurrentSubscription>();
    }

    public async getPendingRequest(id: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/subscription/request`)
            .fetch<ISubscriptionRequest>();
    }

    public async requestUpgrade(id: string, update: ISubscriptionRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/subscription/request`)
            .useMethod("POST")
            .useBody(update)
            .fetchNoContent();
    }

    public async getIntegrations(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}/integrations`).fetch<IGetIntegrationsResult>();
    }

    public async getNewIntegrations(id: string, activeTab: number) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/integrations-v2`)
            .useMethod("GET")
            .useQueryString({ integrationType: activeTab })
            .fetch<IGetIntegrationsResult>();
    }

    public async createIntegration(id: string, newIntegration: ICreateIntegrationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/integrations/orders-management`)
            .useMethod("POST")
            .useBody(newIntegration)
            .fetchNoContent();
    }

    public async updateIntegration(id: string, integrationId: string, data: IUpdateIntegrationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/integrations/orders-management/${integrationId}`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async createIntegrationBackFill(id: string, integrationId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/integrations/orders-management/${integrationId}/back-fill`)
            .useMethod("POST")
            .useBody({})
            .fetchNoContent();
    }

    public async getAllAgencyBranches() {
        return await this._httpClient.build(`${this._baseUrl}/all`).fetch<IGetAllAgencyBranches[]>();
    }

    public async updateGlAccount(glAccounts: IUpdateGLAccount[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/general-ledger-account`)
            .useMethod("PUT")
            .useBody({ glAccounts })
            .fetchNoContent();
    }

    public async getGlAccountHistory(accountId: string, locationId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/general-ledger-history/${accountId}`)
            .useQueryString({ locationId })
            .fetch<IGlAccountHistory[]>();
    }

    public async getGLAccounts(query: IGetGLAccountsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/general-ledger-account`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetGroupedGLAccounts>>();
    }

    public async linkAdjustmentsToGLAccounts(id: string, request: IGLLinkAdjustmentsRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/general-ledger-account/${id}/link-adjustments`)
            .useMethod("PUT")
            .useBody(request)
            .fetch<IGLLinkAdjustmentsResult>();
    }

    public async createPharmacyIntegration(id: string, newIntegration: ICreatePharmacyIntegrationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/integrations/pharmacy`)
            .useMethod("POST")
            .useBody(newIntegration)
            .fetchNoContent();
    }
}
