import { autoinject, bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IOption } from "../../../../interfaces/form-builder/i-element";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IValidation } from "../../../../interfaces/form-builder/i-validation";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { NoteDataManager } from "../../note-data-manager";

@customElement("multi-select-dropdown")
@autoinject
export class MultiSelectDropDown {
    // Input fields
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    private _ignoreTagsChange: boolean = false;
    private _ignoreResultChange: boolean = false;
    @observable({
        changeHandler: nameof<MultiSelectDropDown>("tagsResultChanged")
    })
    public tagsResult: ITypeaheadOptions[] = [];
    public noteDataManager: NoteDataManager;
    public validation: IValidateCustomElement;
    public isValidationLoading: boolean = true;

    @computedFrom(nameof<MultiSelectDropDown>("viewOptions"))
    public get tagsOptions(): ITypeaheadOptions[] {
        let result: ITypeaheadOptions[] = [];
        let options = this.viewOptions.options;
        result = options.map((option: IOption) => ({
            name: option.label,
            value: option.value
        }));
        return result;
    }

    public set tagsOptions(data) {
        // console.log("Tags options: ", data);
    }

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.initValidation();
    }

    public resultChanged() {
        if (this._ignoreResultChange) {
            this._ignoreResultChange = false;
            return;
        }
        if (this.viewOptions) {
            this._ignoreTagsChange = true;
            this.tagsResult = this.tagsOptions.filter(option => this.result.indexOf(option.value) > -1);
        }
    }

    public tagsResultChanged(newValue: ITypeaheadOptions[]) {
        if (this._ignoreTagsChange) {
            this._ignoreTagsChange = false;
            return;
        }
        if (newValue && newValue.length > 0) {
            this._ignoreResultChange = true;
            this.result = newValue.map((val) => val.value);
        } else {
            this.result = [];
        }
    }

    private initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IValidation) => {
            if (rule.validationType === FormValidationEnum.RequiredArray) {
                this.validation = {
                    required: true,
                    displayName: this.viewOptions.name,
                    message: rule.message
                };
            }
        });
        this.isValidationLoading = false;
    }
}
