import { EventAggregator } from "aurelia-event-aggregator";
import { Container, autoinject, bindable, bindingMode } from "aurelia-framework";

import { NoteDataManager } from "./note-data-manager";
import { RequiredAllValidationHandler } from "./validation-handlers/required-all-validation-handler";
import { RequiredIfValidationHandler } from "./validation-handlers/required-if-validation-handler";

@autoinject
export class FormContainer {
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public noteDataManager: NoteDataManager;
    private _currentScopeContainer: Container;

    public constructor(
        currentScopeContainer: Container,
        ea: EventAggregator,
        requiredIfValidationHandler: RequiredIfValidationHandler,
        requiredAllValidationHandler: RequiredAllValidationHandler
    ) {
        // Localize note data manager to the current DOM scope.
        // This enables multiple active forms in a single page
        this._currentScopeContainer = currentScopeContainer;
        this.noteDataManager = new NoteDataManager(ea, requiredIfValidationHandler, requiredAllValidationHandler);
        this._currentScopeContainer.registerInstance(NoteDataManager, this.noteDataManager);
    }
}
