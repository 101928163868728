import { autoinject } from "aurelia-framework";

import { ICreateDocumentBehavior } from "../../../interfaces/form-builder/i-behavior";
import { NoteDataManager } from "../note-data-manager";
import { ToastrService } from "../../../services/toastr-service";

@autoinject
export class CreateDocumentBehaviorHandler {
    private _toastrService: ToastrService;

    public constructor(toastrService: ToastrService) {
        this._toastrService = toastrService;
    }

    public handle(buttonBehavior: ICreateDocumentBehavior, noteDataManager: NoteDataManager) {
        try {
            let taskId = noteDataManager.getTaskId();

            if (!!taskId) {
                let taskMetaId = buttonBehavior.disciplineTaskId;
                window.open(`/create-note?taskId=${taskMetaId}&associatedTaskId=${taskId}`);
            } else {
                this.displayErrorMessage();
            }
        } catch (e) {
            console.error(e);
            this.displayErrorMessage();
        }
    }

    private displayErrorMessage() {
        this._toastrService.error({
            title: "Create Document - Error",
            message: "There was a problem while creating the document. Please try again or report it."
        });
    }
}
