import { IPermissionCheck } from "../../common/utilities/permission-manager";
import { AdministrativePermissionEnum } from "../../enums/administrative-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";
import { viewEmployeePermission } from "../list/list-route-permission";

export const addInquiryPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Inquiries,
        action: PermissionActionEnum.Add
    }
];

export const addReferralPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Referrals,
        action: PermissionActionEnum.Add
    }
];

export const addPhysicianPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Physicians,
        action: PermissionActionEnum.Add
    }
];

export const addInsurancePayorPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.InsurancePayers,
        action: PermissionActionEnum.Add
    }
];

export const addPharmacyPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Pharmacies,
        action: PermissionActionEnum.Add
    }
];

export const addDmePermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Dme,
        action: PermissionActionEnum.Add
    }
];

export const addVendorPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Vendors,
        action: PermissionActionEnum.Add
    }
];

export const addSupplyPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Supplies,
        action: PermissionActionEnum.Add
    }
];

export const addUserPermission: IPermissionCheck[] = [
    ...viewEmployeePermission,
    {
        resource: AdministrativePermissionEnum.Users,
        action: PermissionActionEnum.Add
    },
    {
        resource: AdministrativePermissionEnum.Users,
        action: PermissionActionEnum.Edit
    }
];

export const addTemplatePermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Templates,
        action: PermissionActionEnum.Add
    }
];

export const addVolunteerActivityPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.VolunteerActivities,
        action: PermissionActionEnum.Add
    }
];

export const addNonPatientActivityPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.NonPatientActivities,
        action: PermissionActionEnum.Add
    }
];

export const addTeamsPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.Teams,
        action: PermissionActionEnum.Add
    }
];