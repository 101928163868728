/**
 * Reference:
 * https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/hubs-api-guide-javascript-client
 */

import { autoinject } from "aurelia-framework";
import "ms-signalr-client";
import { UserManager } from "oidc-client";
import { HubsContainer } from "./hubs-container";

@autoinject()
export class SignalrReactor {
    private _hubsContainer: HubsContainer;
    private _signalrConnection: any;
    private _userManager: UserManager;

    public constructor(hubsContainer: HubsContainer, userManager: UserManager) {
        this._signalrConnection = (jQuery as any).hubConnection();
        this._hubsContainer = hubsContainer;
        this._userManager = userManager;
        this.captureSignalrEvents();
    }

    public captureSignalrEvents() {
        let signalR = ($ as any).signalR;
        this._signalrConnection.stateChanged((change: any) => {
            if (change.newState === signalR.connectionState.reconnecting) {
                console.log("SignalR: Reconnecting!");
            } else if (change.newState === signalR.connectionState.connected) {
                console.log("SignalR: Connected!");
            }
        }).disconnected(() => {
            console.log("SignalR: Disconnected!");
        });
    }

    public async start(accountId: string) {
        let connection = this._signalrConnection;
        let user = await this._userManager.getUser();

        if (user != null) {
            connection.qs = { Authorization: user.access_token, AccountId: accountId };
        } else {
            console.log("SignalR: User is not logged in, no Authorization set.");
        }

        this.registerAllHubs();

        try {
            await connection.start();
            console.log(`SignalR: Connection id '${connection.id}'`);
        } catch (error) {
            console.error(error);
        }

    }

    public async stop() {
        try {
            await this._signalrConnection.stop();
        } catch (error) {
            console.log(error);
        }
    }

    private registerAllHubs() {
        let hubsContainer = this._hubsContainer.hubs;

        // For each hub in the hubs container
        // tslint:disable-next-line:ban-types
        hubsContainer.forEach((hub: Map<string, Function>, hubName: string) => {
            // For each method in the hub
            // tslint:disable-next-line:ban-types
            hub.forEach((method: Function, methodName: string) => {
                this.registerMethodForHub(hubName, methodName, method);
            });
        });
    }

    // tslint:disable-next-line:ban-types
    public registerMethodForHub(hubName: string, methodName: string, method: Function) {
        let hubProxy = this._signalrConnection.createHubProxy(hubName);
        hubProxy.on(methodName, method);
    }
}
