import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import {
    billingAndFinancialRoutes,
    billingFinancialPermission,
    payrollPermission
} from "./billing-and-financial/billing-and-financial-routes";
import { clinicalPermission, clinicalReportsRoutes } from "./clinical-reports/clinical-reports-routes";
import { patientPermission, patientReportsRoutes } from "./patient-reports/patient-reports-routes";
import { peopleReportPermission, peopleReportsRoutes } from "./people-reports/people-reports-routes";
import { schedulePermission, scheduleReportsRoutes } from "./schedule-reports/schedule-reports-routes";
import { statisticalPermission, statisticalReportsRoutes } from "./statistical-reports/statistical-reports-routes";
import { surveyPermission, surveyReportsRoutes } from "./survey-reports/survey-reports-routes";
import { DashboardPermissionEnum } from "../../enums/dashboard-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";

export const ReportRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "patients-dashboard"
    },
    {
        name: "report-center",
        route: "report-center",
        moduleId: PLATFORM.moduleName("./report-center/report-center", "report-center"),
        nav: true,
        title: "Report Center",
        settings: {
            menuName: "Report Center",
            permission: [
                ...billingFinancialPermission,
                ...patientPermission,
                ...peopleReportPermission,
                ...clinicalPermission,
                ...surveyPermission,
                ...statisticalPermission,
                ...payrollPermission
            ]
        }
    },
    {
        name: "patients-dashboard",
        route: "patients-dashboard",
        moduleId: PLATFORM.moduleName("../patients/patients-dashboard/patients-dashboard", "patients-dashboard"),
        nav: true,
        title: "Patients Dashboard",
        settings: {
            menuName: "Patients Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.PatientDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "orders-dashboard",
        route: "orders-dashboard",
        moduleId: PLATFORM.moduleName("../patients/orders-dashboard/orders-dashboard", "orders-dashboard"),
        nav: true,
        title: "Orders Dashboard",
        settings: {
            menuName: "Orders Dashboard",
            isPalliativeCare: false,
            permission: [
                {
                    resource: DashboardPermissionEnum.OrdersDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "schedule-dashboard",
        route: "schedule-dashboard",
        moduleId: PLATFORM.moduleName("../schedule/schedule-dashboard/schedule-dashboard", "schedule-dashboard"),
        nav: true,
        title: "Schedule Dashboard",
        settings: {
            menuName: "Schedule Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.ScheduleDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "billing-dashboard",
        route: "billing-dashboard",
        moduleId: PLATFORM.moduleName("../billing/billing-dashboard/billing-dashboard", "billing-dashboard"),
        nav: true,
        title: "Billing Dashboard",
        settings: {
            menuName: "Billing Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.BillingDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "admin-dashboard",
        route: "admin-dashboard",
        moduleId: PLATFORM.moduleName("../admin/admin-dashboard/admin-dashboard", "admin-dashboard"),
        nav: true,
        title: "Admin Dashboard",
        settings: {
            menuName: "Admin Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.AdminDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "patient-reports",
        route: "patient-reports",
        moduleId: PLATFORM.moduleName("./patient-reports/patient-reports", "patient-reports"),
        title: "Patient Reports",
        href: "reports/patient-reports/load",
        settings: {
            isReport: true,
            subRoutes: patientReportsRoutes,
            menuName: "Patient Reports",
            permission: patientPermission
        }
    },
    {
        name: "clinical-reports",
        route: "clinical-reports",
        moduleId: PLATFORM.moduleName("./clinical-reports/clinical-reports", "clinical-reports"),
        title: "Clinical Reports",
        href: "reports/clinical-reports/load",
        settings: {
            isReport: true,
            menuName: "Clinical Reports",
            subRoutes: clinicalReportsRoutes,
            permission: clinicalPermission
        }
    },
    {
        name: "schedule-reports",
        route: "schedule-reports",
        moduleId: PLATFORM.moduleName("./schedule-reports/schedule-reports", "schedule-reports"),
        title: "Schedule Reports",
        href: "reports/schedule-reports/load",
        settings: {
            isReport: true,
            menuName: "Schedule Reports",
            subRoutes: scheduleReportsRoutes,
            permission: schedulePermission
        }
    },
    {
        name: "people-reports",
        route: "people-reports",
        moduleId: PLATFORM.moduleName("./people-reports/people-reports", "people-reports"),
        title: "People Reports",
        href: "reports/people-reports/load",
        settings: {
            isReport: true,
            menuName: "People Reports",
            subRoutes: peopleReportsRoutes,
            permission: [...peopleReportPermission, ...payrollPermission],
            checkAll: false
        }
    },
    {
        name: "statistical-reports",
        route: "statistical-reports",
        moduleId: PLATFORM.moduleName("./statistical-reports/statistical-reports", "statistical-reports"),
        title: "Statistical Reports",
        href: "reports/statistical-reports/load",
        settings: {
            isReport: true,
            subRoutes: statisticalReportsRoutes,
            menuName: "Statistical Reports",
            permission: statisticalPermission
        }
    },
    {
        name: "survey-reports",
        route: "survey-reports",
        moduleId: PLATFORM.moduleName("./survey-reports/survey-reports", "survey-reports"),
        title: "Survey Reports",
        href: "reports/survey-reports/load",
        settings: {
            isReport: true,
            subRoutes: surveyReportsRoutes,
            permission: surveyPermission,
            menuName: "Survey Reports"
        }
    },
    {
        name: "billing-and-financial-reports",
        route: "billing-and-financial-reports",
        moduleId: PLATFORM.moduleName("./billing-and-financial/billing-and-financial", "billing-and-financial"),
        title: "Billing and Financial Reports",
        href: "reports/billing-and-financial-reports/load",
        settings: {
            isReport: true,
            subRoutes: billingAndFinancialRoutes,
            menuName: "Billing and Financial Reports",
            permission: [...billingFinancialPermission, ...payrollPermission],
            checkAll: false
        }
    },
    {
        name: "patients-by-payer",
        route: "patients-by-payer/:id",
        moduleId: PLATFORM.moduleName("./patients-by-payor/patients-by-payor", "patients-by-payor"),
        title: "Census By Primary Payer"
    }
];
