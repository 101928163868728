import { DialogController } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

@autoinject
export class LoginPrompt {
    public controller: DialogController;
    public constructor(controller: DialogController) {
        this.controller = controller;
    }
}
