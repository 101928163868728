import "./textarea-input.scss";

import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";

import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { NoteDataManager } from "../../note-data-manager";

@customElement("textarea-input")
@autoinject
export class TextareaInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    public noteDataManager: NoteDataManager;

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.noteDataManager.assignValidationRules.call(
            this,
            this.viewOptions.name,
            this.noteDataManager
        );
    }
}
