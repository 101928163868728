import {autoinject} from "aurelia-framework";
import * as download from "downloadjs";

import {HospiceClient} from "../common/hospice-client";
import {ExportTypeEnum} from "../enums/export-type-enum";
import {IAssetBlobResponse} from "../interfaces/i-asset";
import {IBlobResponse} from "../interfaces/i-hospice-client";

@autoinject
export class DownloadService {
    private _httpClient: HospiceClient;

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public downloadFile(file: IBlobResponse, type: ExportTypeEnum | string = ExportTypeEnum.Pdf) {
        let fileName = this.extractFileNameFromResponseHeader(file);
        download(file.body, fileName, type);
    }

    public downloadFileFromUrl(url: string) {
        download(url);
    }

    public async downloadFileFromAssetBlob(assetFile: IAssetBlobResponse) {
        download(assetFile.assetBlob, assetFile.assetName, assetFile.type);
    }

    private extractFileNameFromResponseHeader(response: IBlobResponse): string {
        let responseHeader: string = response.headers.get("content-disposition");
        return responseHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
    }
}
