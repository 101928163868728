import { autoinject, bindable, bindingMode, computedFrom, containerless, customElement } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { INavigationBehavior } from "../../../../interfaces/form-builder/i-behavior";
import { IButtonOptions } from "../../../../interfaces/form-builder/i-button-options";
import { BehaviorTypesEnum } from "../../behavior-handlers/behavior-types-enum";
import { NavigationBehaviorHandler } from "../../behavior-handlers/navigation-behavior-handler";
import { NoteDataManager } from "../../note-data-manager";

@customElement("form-link")
@containerless
@autoinject
export class FormLink {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public viewOptions: IButtonOptions;
    private _navigationBehaviorHandler: NavigationBehaviorHandler;
    private _noteDataManager: NoteDataManager;

    @computedFrom(
        nameof<FormLink>("viewOptions")
    )
    public get isButton(): boolean {
        return this.viewOptions.elementType === "button-link";
    }

    public constructor(
        navigationBehavior: NavigationBehaviorHandler,
        noteDataManager: NoteDataManager) {
        this._navigationBehaviorHandler = navigationBehavior;
        this._noteDataManager = noteDataManager;
    }

    public onLinkClick() {
        let behaviors = this.viewOptions.behaviors;
        let numOfBehaviors = behaviors.length;

        for (let i = 0; i < numOfBehaviors; i++) {
            let buttonBehavior = behaviors[i];
            switch (buttonBehavior.behaviorType) {
                case BehaviorTypesEnum.Navigation:
                    // INFO: Reason for passing NoteDataManager instead of using autoinject in the behavior handler?
                    // Containers only work with classes that are custom elements or custom attributes
                    // Classes that are not either of the above will always get the instance from the root container
                    this._navigationBehaviorHandler.handle(
                        buttonBehavior as INavigationBehavior,
                        this._noteDataManager
                    );
                    break;
            }
        }
    }
}
