import { autoinject, bindable, bindingMode, computedFrom, observable } from "aurelia-framework";
import moment from "moment";
import nameof from "../../../common/nameof";
import { formatName } from "../../../common/utilities/format-name";
import { IGetPatientsSlimInfoResult } from "../../../interfaces/i-get-patient";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { CreateInfectionReport } from "../../../models/infection-report/create-infection-report";
import { PatientsService } from "../../../services/patient-service";

@autoinject
export class AddInfectionForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public infectionReport: CreateInfectionReport = new CreateInfectionReport();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public lineOfService: number = null;
    private _patientsService: PatientsService;
    @observable({ changeHandler: nameof<AddInfectionForm>("selectedPatientChanged") })
    public selectedPatient: ITypeaheadOptions;
    public patient: IGetPatientsSlimInfoResult;
    public isAddTaskRequested: boolean = false;
    public isPatientDisabled: boolean = false;

    @computedFrom(`${nameof<AddInfectionForm>("infectionReport")}.${nameof<CreateInfectionReport>("infectionDate")}`)
    public get createdDateOptions(): DatepickerOptions {
        let baseDatePickerOptions = {
            autoclose: true
        };
        if (this.infectionReport?.infectionDate) {
            return Object.assign(baseDatePickerOptions, {
                startDate: moment(this.infectionReport.infectionDate).format("MM/DD/YYYY")
            });
        }
        return baseDatePickerOptions;
    }

    @computedFrom(`${nameof<AddInfectionForm>("infectionReport")}.${nameof<CreateInfectionReport>("createdDate")}`)
    public get infectionDateOptions(): DatepickerOptions {
        let baseDatePickerOptions = {
            autoclose: true
        };
        if (this.infectionReport?.createdDate) {
            return Object.assign(baseDatePickerOptions, {
                endDate: moment(this.infectionReport.createdDate).format("MM/DD/YYYY")
            });
        }
        return baseDatePickerOptions;
    }

    public constructor(patientsService: PatientsService) {
        this._patientsService = patientsService;
    }

    public async attached() {
        if (this.infectionReport?.patientId) {
            await this.initSelectedPatient();
        }
    }

    public async initSelectedPatient() {
        try {
            let response = await this._patientsService.getPatientSlim(this.infectionReport.patientId);
            if (response) {
                this.selectedPatient = {
                    name: formatName(response.firstName, response.lastName),
                    value: response.id
                };
                this.isPatientDisabled = true;
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async patientListFetch(term: string) {
        let patientsList = await this._patientsService.getPatientsLean({
            term: term,
            page: 1,
            pageLength: 10,
            lineOfService: this.lineOfService
        });
        if (!!patientsList) {
            return patientsList.items.map((patient) => ({
                name: formatName(patient.firstName, patient.lastName),
                value: patient.id
            }));
        }
        return [];
    }

    public async selectedPatientChanged(selectedPatient: ITypeaheadOptions) {
        if (selectedPatient) {
            this.infectionReport.patientId = selectedPatient.value;
            this.patient = await this._patientsService.getPatientSlim(selectedPatient.value);
        }
    }

    public detached() {
        this.selectedPatient = null;
    }
}
