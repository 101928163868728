import { autoinject } from "aurelia-framework";
import { IRequestHandler } from "../../interfaces/i-handlers";

@autoinject()
export class ContentTypeHandler implements IRequestHandler {

    public async handle(request: Request): Promise<void> {
        if (!request.headers.get("Content-Type")) {
            request.headers.append("Content-Type", "application/json");
        }
    }
}
