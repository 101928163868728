import { FrameworkConfiguration } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";

enum ResourceChunkNameEnum {
    CustomElement = "custom-element",
    ValueConverters = "value-converters"
}

export function configure(config: FrameworkConfiguration, editorConfig: any) {
    config.globalResources([
        // Global @customElement
        PLATFORM.moduleName("./custom-elements/qa-return-reason/qa-return-reason"),
        PLATFORM.moduleName("./custom-elements/grouped-multi-select/grouped-multi-select"),
        PLATFORM.moduleName("./custom-elements/branch-default-filter/branch-default-filter"),
        PLATFORM.moduleName("./custom-elements/nav-menu-more-button/nav-menu-more-button"),
        PLATFORM.moduleName("./custom-elements/filter-menu-more-button/filter-menu-more-button"),
        PLATFORM.moduleName("./custom-elements/page-layout/page-layout"),
        PLATFORM.moduleName("./custom-elements/scroll-layout/scroll-layout"),
        PLATFORM.moduleName("./custom-elements/popover/popover"),
        PLATFORM.moduleName("./custom-elements/side-nav/side-nav"),
        PLATFORM.moduleName("./custom-elements/dashboard-card/dashboard-card"),
        PLATFORM.moduleName("./custom-elements/level-two/level-two"),
        PLATFORM.moduleName("./custom-elements/tabs-animated-header/tabs-animated-header"),
        PLATFORM.moduleName("./custom-elements/form-section/form-section"),
        PLATFORM.moduleName("./custom-elements/typeahead-input/typeahead-input"),
        PLATFORM.moduleName("./custom-elements/new-typeahead/new-typeahead"),
        PLATFORM.moduleName("./custom-elements/multi-select-filter/multi-select-filter"),
        PLATFORM.moduleName("./custom-elements/multiselect-time/multiselect-time"),
        PLATFORM.moduleName("./custom-elements/multiselect-dropdown/multiselect-dropdown"),
        PLATFORM.moduleName("./custom-elements/tags-input/tags-input"),
        PLATFORM.moduleName("./custom-elements/phone-number/phone-number"),
        PLATFORM.moduleName("./custom-elements/phone-numbers-component/phone-numbers-component"),
        PLATFORM.moduleName("./custom-elements/date-picker/date-picker"),
        PLATFORM.moduleName("./custom-elements/date-range-picker/date-range-picker"),
        PLATFORM.moduleName("./custom-elements/multi-date-picker/multi-date-picker"),
        PLATFORM.moduleName("./custom-elements/time-picker/time-picker"),
        PLATFORM.moduleName("./custom-elements/scroll-pagination/scroll-pagination"),
        PLATFORM.moduleName("./custom-elements/pagination/pagination"),
        PLATFORM.moduleName("./custom-elements/table-loading/table-loading"),
        PLATFORM.moduleName("./custom-elements/table-empty/table-empty"),
        PLATFORM.moduleName("./custom-elements/places-autocomplete/places-autocomplete"),
        PLATFORM.moduleName("./custom-elements/welcome-header/welcome-header"),
        PLATFORM.moduleName("./custom-elements/feature-toggle/feature-toggle"),
        PLATFORM.moduleName("./custom-elements/permission-toggle/permission-toggle"),
        PLATFORM.moduleName("./custom-elements/summer-editor/summer-editor"),
        PLATFORM.moduleName("./custom-elements/text-editor/text-editor"),
        PLATFORM.moduleName("./custom-elements/sortable-th/sortable-th"),
        PLATFORM.moduleName("./custom-elements/dialog-content/dialog-content"),
        PLATFORM.moduleName("./custom-elements/diagnosis/diagnosis"),
        PLATFORM.moduleName("./custom-elements/diagnosis-component/diagnosis-component"),
        PLATFORM.moduleName("./custom-elements/chart/chart"),
        PLATFORM.moduleName("./custom-elements/table-prompt/table-prompt"),
        PLATFORM.moduleName("./custom-elements/patient-info-header/patient-info-header"),
        PLATFORM.moduleName("./custom-elements/format-name/format-name"),
        PLATFORM.moduleName("./custom-elements/dashboard-grid/dashboard-grid-new"),
        PLATFORM.moduleName("./custom-elements/dashboard-ring/dashboard-ring"),
        PLATFORM.moduleName("./custom-elements/dashboard-row/dashboard-row"),
        PLATFORM.moduleName("./custom-elements/message-input-handler/message-input-handler"),
        PLATFORM.moduleName("./custom-elements/message-users/message-users"),
        PLATFORM.moduleName("./custom-elements/report-error-link/report-error-link"),
        PLATFORM.moduleName("./custom-elements/list-layout/list-layout"),
        PLATFORM.moduleName("./custom-elements/report-list-layout/report-list-layout"),
        PLATFORM.moduleName("./custom-elements/reports-layout/reports-layout"),
        PLATFORM.moduleName("./custom-elements/create-layout/create-layout"),
        PLATFORM.moduleName("./custom-elements/patient-quick-links/patient-quick-links"),
        PLATFORM.moduleName("./custom-elements/attach-physician-order/attach-physician-order"),
        PLATFORM.moduleName("./custom-elements/selected-assessment/selected-assessment"),
        PLATFORM.moduleName("./custom-elements/comment-input/comment-input"),
        PLATFORM.moduleName("./custom-elements/claim-errors/claim-errors"),
        PLATFORM.moduleName("./custom-elements/upload-document/upload-document"),
        PLATFORM.moduleName("./custom-elements/error-message-handler/error-message-handler"),
        PLATFORM.moduleName("./custom-elements/number-animators/number-animator"),
        PLATFORM.moduleName("./custom-elements/number-animators/currency-animator"),
        PLATFORM.moduleName("./custom-elements/empty-text-handler/empty-text-handler"),
        PLATFORM.moduleName("./custom-elements/currency-text-handler/currency-text-handler"),
        PLATFORM.moduleName("./custom-elements/eligibility-check/eligibility-check"),
        PLATFORM.moduleName("./custom-elements/state-typeahead/state-typeahead"),
        PLATFORM.moduleName("./custom-elements/country-typeahead/country-typeahead"),
        PLATFORM.moduleName("./custom-elements/address-form/address-form"),
        PLATFORM.moduleName("./custom-elements/pay-rates-table/pay-rates-table"),
        PLATFORM.moduleName("./custom-elements/npa-pay-rates-table/npa-pay-rates-table"),
        // Form builder
        PLATFORM.moduleName("./form-builder/form-note/form-note"),
        PLATFORM.moduleName("./form-builder/page-note/page-note"),
        PLATFORM.moduleName("./form-builder/form-container"),
        // Global @valueConverter
        PLATFORM.moduleName("./value-converters/object-converter"),
        PLATFORM.moduleName("./value-converters/sanitize-markup"),
        PLATFORM.moduleName("./value-converters/date-and-time"),
        PLATFORM.moduleName("./value-converters/format-ssn"),
        PLATFORM.moduleName("./value-converters/format-phone-number"),
        PLATFORM.moduleName("./value-converters/format-string-to-float"),
        PLATFORM.moduleName("./value-converters/currency"),
        PLATFORM.moduleName("./value-converters/percentage"),
        PLATFORM.moduleName("./value-converters/format-message"),
        PLATFORM.moduleName("./value-converters/format-string-to-pascal"),
        PLATFORM.moduleName("./value-converters/markdown-to-html"),
        PLATFORM.moduleName("./value-converters/task-status-color"),
        PLATFORM.moduleName("./value-converters/claim-status-color"),
        PLATFORM.moduleName("./value-converters/sub-route-link"),
        PLATFORM.moduleName("./value-converters/format-zip-code"),
        PLATFORM.moduleName("./value-converters/drug-severity-color"),
        PLATFORM.moduleName("./value-converters/read-only-tasks"),
        PLATFORM.moduleName("./value-converters/read-only-claims"),
        PLATFORM.moduleName("./value-converters/is-action-allowed"),
        PLATFORM.moduleName("./value-converters/is-core-member"),
        PLATFORM.moduleName("./value-converters/permission-action"),
        PLATFORM.moduleName("./value-converters/sub-string-highlight"),
        PLATFORM.moduleName("./value-converters/format-to-uppercase"),
        PLATFORM.moduleName("./value-converters/format-decimals"),
        // Global @customAttribute
        PLATFORM.moduleName("./attributes/height-auto/height-auto"),
        PLATFORM.moduleName("./attributes/tooltip/tooltip"),
        PLATFORM.moduleName("./attributes/popover/popover"),
        PLATFORM.moduleName("./attributes/number-only/number-only"),
        PLATFORM.moduleName("./attributes/currency-format/currency-format"),
        PLATFORM.moduleName("./attributes/date-format-only/date-format-only"),
        // Global Forms
        PLATFORM.moduleName("./forms/add-task-form/add-task-form"),
        PLATFORM.moduleName("./forms/pharmacy-form/pharmacy-form"),
        PLATFORM.moduleName("./forms/inquiry-form/inquiry-form"),
        PLATFORM.moduleName("./forms/volunteer-activity-form/volunteer-activity-form"),
        PLATFORM.moduleName("./forms/dme-form/dme-form"),
        PLATFORM.moduleName("./forms/template-form/template-form"),
        PLATFORM.moduleName("./forms/medication-form/medication-form"),
        PLATFORM.moduleName("./forms/physician-form/physician-form"),
        PLATFORM.moduleName("./forms/allergy-form/allergy-form"),
        PLATFORM.moduleName("./forms/infection-form/add-infection-form"),
        PLATFORM.moduleName("./forms/status-update-form/status-update-form"),
        PLATFORM.moduleName("./forms/supply-form/supply-form"),
        PLATFORM.moduleName("./forms/pay-rate-form/pay-rate-form"),
        PLATFORM.moduleName("./forms/add-non-patient-form/add-non-patient-form"),
        PLATFORM.moduleName("./forms/add-volunteer-activity-form/add-volunteer-activity-form"),
        PLATFORM.moduleName("./forms/npa-pay-rate-form/npa-pay-rate-form"),
        PLATFORM.moduleName("./forms/non-patient-activity-form/non-patient-activity-form"),
        PLATFORM.moduleName("./forms/send-referral-form/send-referral-form"),

        // TODO: Edit/Create dialogs common content
        // This code is part of Idg-dashboard. Idg-dashboard is not prod ready, so the below line is commented
        // PLATFORM.moduleName("../pages/idg/idg-dashboard/idg-home/idg-schedule/idg-schedule"),
        // https://stackoverflow.com/questions/46088319/getting-the-error-unable-find-module-with-id-with-aurelia-router
        // PLATFORM.moduleName("./dialogs/error/error"),
        PLATFORM.moduleName("./dialogs/success/success"),
        PLATFORM.moduleName("./dialogs/verify/verify"),
        PLATFORM.moduleName("./dialogs/sign-submit/sign-submit")
    ]);
}
