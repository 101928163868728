export class SearchTermValueConverter {
    public toView(value: string, term: string): string {

        if (value == null || term == null || term == "") {
            return value;
        }

        let regex = new RegExp(term, "gi");

        let attemptsLeft = 20;
        let result;

        let words: { start: number, end: number }[] = [];

        while ((result = regex.exec(value)) !== null) {
            attemptsLeft--;
            if (attemptsLeft < 0) {
                break;
            }
            words.push({
                start: result.index,
                end: regex.lastIndex
            });
        }

        // Start at the end.
        words = words.reverse();

        for (let word of words) {
            value = value.slice(0, word.end) + "</strong>" + value.slice(word.end);
            value = value.slice(0, word.start) + "<strong>" + value.slice(word.start);
        }

        console.log(value);

        return value;
    }
}
