import { computedFrom } from "aurelia-binding";
import { DialogController } from "aurelia-dialog";
import { autoinject, observable } from "aurelia-framework";
import { Router } from "aurelia-router";

import nameof from "../../../common/nameof";
import { IGetAccountsModel } from "../../../interfaces/i-get-accounts";
import { AuthService } from "../../../services/auth-service";
import { ProvidersService } from "../../../services/providers-service";
import { IGetProviderDefault } from "../../../services/i-providers-service";
import { config } from "../../../common/config";
import { EnumsService } from "../../../services/enums-service";
import { EnumMap } from "../../../common/utilities/enum-map";

@autoinject
export class SwitchAccountDialog {
    private readonly _dialogController: DialogController;
    private readonly _authService: AuthService;
    private _enumService: EnumsService;
    private _router: Router;
    private _providersService: ProvidersService;
    @observable({
        changeHandler: nameof<SwitchAccountDialog>("selectedAccountChanged")
    })
    public selectedAccount: string;
    public showHospice: number = null;
    public accounts: IGetAccountsModel[];
    public currentAccountId: string;
    public searchQuery: string;
    public providers: IGetProviderDefault[];
    public selectedProviders: IGetProviderDefault[];
    public rememberSelection: boolean = false;
    public isProvidersLoading: boolean = false;
    public lineOfServiceEnumMap: EnumMap = new EnumMap([]);
    public defaultProviders: string[] = [];

    @computedFrom(nameof<SwitchAccountDialog>("providers"))
    public get showLineOfServiceToggle() {
        let userHasPalliativeCareProviders = this.providers?.some(
            (provider) =>
                provider.lineOfService === (this.lineOfServiceEnumMap.getEnumValue("Palliative Care") as number)
        );
        let userHasHospiceProviders = this.providers?.some(
            (provider) => provider.lineOfService === (this.lineOfServiceEnumMap.getEnumValue("Hospice") as number)
        );
        return userHasPalliativeCareProviders && userHasHospiceProviders;
    }

    @computedFrom(
        `${nameof<SwitchAccountDialog>("selectedProviders")}.length`,
        `${nameof<SwitchAccountDialog>("defaultProviders")}.length`
    )
    public get allProvidersChecked() {
        return this.selectedProviders?.length === this.defaultProviders.length;
    }

    @computedFrom(nameof<SwitchAccountDialog>("showHospice"))
    public get isHospiceActive() {
        return this.showHospice === this.lineOfServiceEnumMap.getEnumValue("Hospice");
    }

    public constructor(
        dialogController: DialogController,
        authService: AuthService,
        providersService: ProvidersService,
        router: Router,
        enumService: EnumsService
    ) {
        this._dialogController = dialogController;
        this._authService = authService;
        this._providersService = providersService;
        this._router = router;
        this._enumService = enumService;
    }

    public activate(params: SwitchAccountDialogParams) {
        this.accounts = params.accounts;
        this.selectedAccount = params.currentAccountId;
    }

    public async attached() {
        let lineOfServiceEnum = await this._enumService.getLineOfService();
        this.lineOfServiceEnumMap = new EnumMap(lineOfServiceEnum);
    }

    public async selectedAccountChanged(accountId: string = null) {
        if (!accountId) {
            return;
        }
        await this._authService.setAccountId(accountId);
        this.isProvidersLoading = true;
        this.providers = await this._providersService.getProvidersDefault();
        if (this.providers?.length > 0) {
            let rememberedSelection = this.providers.find((provider) => provider.isDefault);
            if (!!rememberedSelection) {
                this.updateShowHospice(rememberedSelection.lineOfService);
            } else {
                this.updateShowHospice(this.providers[0].lineOfService);
            }
        }
        this.isProvidersLoading = false;
    }

    public updateShowHospice(value: number) {
        this.showHospice = value;
        this.updateSelectedProviders();
    }

    public updateSelectedProviders() {
        if (!this.providers || this.providers?.length === 0) {
            return;
        }
        this.defaultProviders?.splice(0, this.defaultProviders?.length);
        this.selectedProviders = this.providers.filter((provider) => provider.lineOfService === this.showHospice);
        this.selectedProviders.forEach((provider) => {
            if (provider.isDefault) {
                this.defaultProviders.push(provider.id);
            }
        });
    }

    public navigateToAxxessCentral() {
        this._router.navigate(config.axxessCentralUrl);
    }

    public closeDialog() {
        this.providers.forEach((provider) => {
            provider.isDefault = this.defaultProviders.includes(provider.id);
        });
        let selectedInfo = {
            providers: this.providers,
            rememberSelection: this.rememberSelection,
            lineOfService: this.selectedProviders?.[0].lineOfService
        };
        this._dialogController.ok(selectedInfo);
    }

    public toggleAllSelected() {
        let checkAll = !this.allProvidersChecked;
        this.defaultProviders?.splice(0, this.defaultProviders?.length);
        if (checkAll) {
            this.selectedProviders.forEach((provider) => {
                this.defaultProviders.push(provider.id);
            });
        }
    }
}

export class SwitchAccountDialogParams {
    public accounts: IGetAccountsModel[];
    public currentAccountId: string;
}
