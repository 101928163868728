import {autoinject, bindable, bindingMode, customElement, observable} from "aurelia-framework";

import nameof from "../../../../common/nameof";

@customElement("file-picker")
@autoinject
export class FilePicker {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<FilePicker>("fileChanged")
    })
    public file: File;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public acceptableFileType: string = "";
    public fileInput: HTMLInputElement;
    @observable({
        changeHandler: nameof<FilePicker>("selectedFilesChanged")
    })
    public selectedFiles: File;

    public openFileDialog() {
        $(this.fileInput).trigger("click");
    }

    public selectedFilesChanged(newValue: FileList) {
        if (!newValue) {
            return;
        }
        let files: File[] = Array.from(newValue);
        this.file = files[0];
    }

    public fileChanged(newValue: File) {
        if (!newValue) {
            this.selectedFiles = null;
            // https://css-tricks.com/snippets/jquery/clear-a-file-input/
            let inputElement = $(this.fileInput);
            inputElement.replaceWith(inputElement.val("").clone(true));
            return;
        }
        this.selectedFiles = newValue;
    }
}
