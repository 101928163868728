import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { ICreateNonPatientActivity } from "../interfaces/employee-schedule/i-non-patient-activity";
import { IComAssessmentDueCount } from "../interfaces/i-comp-assessment-due";
import {
    IGetPendingCoSignatureTaskResponse,
    IGetPendingCoSignatureTasksQuery
} from "../interfaces/i-pending-cosignature";
import { IGetQaStatusCounts, IGetQaTaskResponse, IGetQaTasksQuery } from "../interfaces/i-qa";
import { ICreateResponse } from "../interfaces/i-response";
import {
    IApproveCoSignatureDetails,
    ICertificationWatchResult,
    ICreateTask,
    ICreateTaskResponse,
    IGetCurrentUserTaskResponse,
    IGetDisciplineCounts,
    IGetPreviousTaskQuery,
    IGetPreviousTaskResult,
    IGetTaskCountQuery,
    IGetTaskCountResult,
    IGetTasksQuery,
    IGetTasksResult,
    IMissedVisitDetails,
    IModifiedTask,
    IReassignTask,
    IVisitAlertCountResult
} from "../interfaces/i-schedule";
import {
    ICreateAddendumParams,
    ICreateAddendumResponse,
    IGetAddendumVersionsResponse,
    IGetAddendumWarningResponse
} from "../interfaces/task/i-addendum";
import { ICreateOrderParams } from "../interfaces/task/i-create-order";
import { IUpdateOrderParams } from "../interfaces/task/i-update-order";
import { NonPatientActivity } from "../models/employee-schedule/non-patient-activity";
import { Task } from "../models/task";
import {
    IGetCptCodesResponse,
    IGetTaskDetailsAttachment,
    IPostTaskDetailsAttachmentRequest,
    IPostTaskDetailsAttachmentResponse,
    IGetCurrentUserTaskQuery,
    IGetDisciplineCount,
    IGetCptCodesRequest,
    IReturnTask,
    IValidateDateQuery,
    IValidateDateResponse,
    IGetScheduleWidgetTasksQuery,
    IScheduleWidgetTasks,
    IPatientVisitInfo,
    IUnlockPatientVisit
} from "./i-task-service";
import { ICreateVolunteerActivity } from "../interfaces/employee-schedule/i-volunteer-acitivty";
import { VolunteerActivityModel } from "../models/employee-schedule/volunteer-activity";
import { IPaginationNew } from "../interfaces/i-pagination";

@autoinject()
export class TaskService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/tasks";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createTask(newTask: ICreateTask) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(newTask)
            .fetch<ICreateTaskResponse>();
    }

    public async createPalliativeTask(newTask: ICreateTask) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care`)
            .useMethod("POST")
            .useBody(newTask)
            .fetch<ICreateTaskResponse>();
    }

    public async createNonPatientActivity(newTask: ICreateNonPatientActivity) {
        return await this._httpClient
            .build(`${this._baseUrl}/non-patient-activity`)
            .useMethod("POST")
            .useBody(newTask)
            .fetch<ICreateTaskResponse>();
    }

    public async createVolunteerActivity(newTask: ICreateVolunteerActivity) {
        return await this._httpClient
            .build(`${this._baseUrl}/volunteer-activity`)
            .useMethod("POST")
            .useBody(newTask)
            .fetch<ICreateTaskResponse>();
    }

    public async getTaskForCurrentUser(query: IGetCurrentUserTaskQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/currentUser`)
            .useQueryString(query)
            .fetch<IGetCurrentUserTaskResponse[]>();
    }

    public async getQaTasks(params: IGetQaTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa`)
            .useMethod("POST")
            .useBody(params)
            .fetch<IGetQaTaskResponse>();
    }

    public async getPendingCoSignatureTasks(params: IGetPendingCoSignatureTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/pendingcosignature`)
            .useMethod("POST")
            .useBody(params)
            .fetch<IGetPendingCoSignatureTaskResponse>();
    }

    public async getTaskById(taskId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${taskId}`).fetch<Task>();
    }

    public async getNonPatientActivityById(nonPatientActivityId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${nonPatientActivityId}`).fetch<NonPatientActivity>();
    }

    public async getVolunteerActivityById(volunteerActivityId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${volunteerActivityId}`).fetch<VolunteerActivityModel>();
    }

    public async getQaStatusCounts(params: IGetQaTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa/statuscounts`)
            .useMethod("POST")
            .useBody(params)
            .fetch<IGetQaStatusCounts>();
    }

    public async getQaDisciplineCounts(params: IGetQaTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa/disciplinecounts`)
            .useMethod("POST")
            .useBody(params)
            .fetch<IGetQaStatusCounts>();
    }

    public async reassign(taskId: string, query: IReassignTask) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/reassign`)
            .useMethod("PUT")
            .useBody(query)
            .fetch();
    }

    public async deleteTask(taskIds: string[]) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("DELETE").useBody(taskIds).fetchNoContent();
    }

    public async deleteNPATask(taskIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/non-patient-activity`)
            .useMethod("DELETE")
            .useBody(taskIds)
            .fetchNoContent();
    }

    public async approveTask(taskIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa/approve`)
            .useMethod("PUT")
            .useBody({
                taskIds
            })
            .fetchNoContent();
    }

    public async returnTask(data: IReturnTask) {
        return await this._httpClient
            .build(`${this._baseUrl}/qa/return`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async approveCoSignature(data: IApproveCoSignatureDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/cosignature/approve`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async returnCoSignature(data: IReturnTask) {
        return await this._httpClient
            .build(`${this._baseUrl}/cosignature/return`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async markTaskAsMissed(taskId: string, missedVisitDetails: IMissedVisitDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/markasmissed`)
            .useMethod("PUT")
            .useBody(missedVisitDetails)
            .fetchNoContent();
    }

    public async updateMissedTaskDetails(taskId: string, missedVisitDetails: IMissedVisitDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/missed-task-details`)
            .useMethod("PUT")
            .useBody(missedVisitDetails)
            .fetchNoContent();
    }

    public async resetMissedTask(taskId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${taskId}/resetmissed`).useMethod("PUT").fetchNoContent();
    }

    public async getMissedTaskDetails(taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/missedtaskdetails`)
            .fetch<IMissedVisitDetails>();
    }

    public async exportQaTasks(timeZone: string, params: IGetQaTasksQuery) {
        let excelParams = Object.assign(
            {
                timeZone
            },
            params
        );
        return await this._httpClient.build(`${this._baseUrl}/qa/xlsx`).useQueryString(excelParams).fetchBlob();
    }

    public async exportPendingCoSignatureTasks(timeZone: string, params: IGetPendingCoSignatureTasksQuery) {
        let excelParams = Object.assign(
            {
                timeZone
            },
            params
        );
        return await this._httpClient
            .build(`${this._baseUrl}/cosignature/xlsx`)
            .useQueryString(excelParams)
            .fetchBlob();
    }

    public async getTasks(params: IGetTasksQuery) {
        return await this._httpClient.build(`${this._baseUrl}`).useQueryString(params).fetch<IGetTasksResult[]>();
    }

    public async editTask(tasks: IModifiedTask[]) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("PUT").useBody(tasks).fetchNoContent();
    }

    public async getDashboardDisciplineCount(query: IGetDisciplineCount) {
        return await this._httpClient
            .build(`${this._baseUrl}/disciplinecount`)
            .useQueryString(query)
            .fetch<IGetDisciplineCounts[]>();
    }

    public async getTaskCount(params: IGetTaskCountQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/count`)
            .useQueryString(params)
            .fetch<IGetTaskCountResult[]>();
    }

    public async getPreviousTasks(taskId: string, params: IGetPreviousTaskQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/previousTasks`)
            .useQueryString(params)
            .fetch<IGetPreviousTaskResult[]>();
    }

    public async getCertificationWatch(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/certificationwatch`)
            .useQueryString({ locationIds })
            .fetch<ICertificationWatchResult>();
    }

    public async getCompAssessmentDueCount(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/comprehensiveassessmentduecount`)
            .useQueryString({ locationIds })
            .fetch<IComAssessmentDueCount[]>();
    }

    public async getVisitAlert(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/VisitAlerts`)
            .useQueryString({ locationIds })
            .fetch<IVisitAlertCountResult>();
    }

    public async createOrder(query: ICreateOrderParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/order`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateResponse>();
    }

    public async updateOrder(taskId: string, query: IUpdateOrderParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/order`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async createAddendum(taskId: string, query: ICreateAddendumParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/addendum`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateAddendumResponse>();
    }

    public async getAddendumWarnings(taskId: string, addendumId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/addendum/${addendumId}/warnings`)
            .fetch<IGetAddendumWarningResponse>();
    }

    public async getAddendumVersions(taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/addenda`)
            .fetch<IGetAddendumVersionsResponse[]>();
    }

    public async printAddendum(taskId: string, addendumId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${taskId}/addendum/${addendumId}/print`).fetchBlob();
    }

    public async getTaskDetailsAttachments(taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/task-details-attachments`)
            .fetch<IGetTaskDetailsAttachment[]>();
    }

    public async createTaskDetailsAttachment(taskId: string, attachmentRequest: IPostTaskDetailsAttachmentRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/task-details-attachments`)
            .useMethod("POST")
            .useBody(attachmentRequest)
            .fetch<IPostTaskDetailsAttachmentResponse>();
    }

    public async deleteTaskDetailsAttachment(taskId: string, attachmentId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/task-details-attachments/${attachmentId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async createPalliativeCareOrder(query: ICreateOrderParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care-order`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateResponse>();
    }

    public async getPhysicianCptCode(taskId: string, query: IGetCptCodesRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/physician-cpt-code`)
            .useQueryString(query)
            .fetch<IGetCptCodesResponse>();
    }

    public async validateDate(query: IValidateDateQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/validate-date`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IValidateDateResponse>();
    }

    public async restoreTask(taskIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/restore`)
            .useMethod("PUT")
            .useBody({ taskIds })
            .fetchNoContent();
    }

    public async getScheduleWidgetTasks(query: IGetScheduleWidgetTasksQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/schedule-widget`)
            .useQueryString(query)
            .fetch<IPaginationNew<IScheduleWidgetTasks>>();
    }

    public async getPatientVisitInformation(patientId: string, taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/patient-visit-information/${taskId}`)
            .useMethod("GET")
            .fetch<IPatientVisitInfo>();
    }

    public async unlockVisit(patientId: string, visitNote: IUnlockPatientVisit) {
        return await this._httpClient
            .build(`${this._baseUrl}/${patientId}/checkout-note`)
            .useMethod("POST")
            .useBody(visitNote)
            .fetch<IPatientVisitInfo>();
    }
}
