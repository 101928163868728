import { ValidationRules } from "aurelia-validation";

import { ITypeaheadOptions } from "../interfaces/i-typeahead";

export class PayRate {
    public tasks: ITypeaheadOptions[] = [];
    public payers: ITypeaheadOptions[] = [];
    public branches: ITypeaheadOptions[] = [];
    public rate: number = null;
    public rateType: number = null;
    public effectiveDate: string = "";

    public constructor (data?: PayRate) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    private initValidation() {
        ValidationRules
            .ensure((x: PayRate) => x.rate).required().withMessage("Rate is required.")
            .ensure((x: PayRate) => x.rateType).required().withMessage("Rate Type is required.")
            .ensure((x: PayRate) => x.effectiveDate).required().withMessage("Effective Date is required.")
            .on(this);
    }
}
