import "./send-referral-form.scss";

import { observable, BindingEngine, Disposable } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable, bindingMode, customElement } from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";

import nameof from "../../../common/nameof";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { ToastrService } from "../../../services/toastr-service";
import { ElectronicReferral } from "../../../models/electronic-referral";
import { formatName } from "../../../common/utilities/format-name";
import { ReferralService } from "../../../services/referral-service";
import { IGetReferralsLean } from "../../../interfaces/i-electronic-referral";
import { IPartnerProvider } from "../../../services/i-service-user-service";
import { EnumsService } from "../../../services/enums-service";
import { ServiceUserService } from "../../../services/service-user-service";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IBranchResponse } from "../../../interfaces/i-branch";
import { getEnumFilterOptions } from "../../../common/utilities/filter-manager";

@autoinject
@customElement("send-referral-form")
export class SendReferralForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public nonAdmitReasons: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public electronicReferral: ElectronicReferral = new ElectronicReferral();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public branches: IBranchResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public controller: ValidationController;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public title: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientStatusEnum: IEnumResponse[] = [];
    private readonly _toastrService: ToastrService;
    private readonly _referralService: ReferralService;
    private readonly _enumService: EnumsService;
    private _serviceUserService: ServiceUserService;
    private _bindingSubscriptions: Disposable[] = [];
    private _bindingEngine: BindingEngine;
    public selectedPatientStatuses: ITypeaheadOptions[] = [];
    @observable({
        changeHandler: nameof<SendReferralForm>("selectedPatientChanged")
    })
    public selectedPatient: ITypeaheadOptions;
    @observable({
        changeHandler: nameof<SendReferralForm>("selectedPartnerOrganizationChanged")
    })
    public selectedPartnerOrganization: string = null;
    public referralId: string = "";
    public providerId: string = "";
    public patientList: IGetReferralsLean[] = [];
    public partnerOrganizations: IPartnerProvider[] = [];
    public patientStatusEnumMap: EnumMap;
    public patientValidation: IValidateCustomElement = {
        required: true,
        displayName: "Patient Name"
    };

    public constructor(
        toastrService: ToastrService,
        referralService: ReferralService,
        enumService: EnumsService,
        serviceUserService: ServiceUserService,
        bindingEngine: BindingEngine
    ) {
        this._toastrService = toastrService;
        this._referralService = referralService;
        this._enumService = enumService;
        this._serviceUserService = serviceUserService;
        this._bindingEngine = bindingEngine;
    }

    public async attached() {
        this.initValidation();
        this.initSubscription();
        this.patientStatusEnum = await this._enumService.getElectronicReferralPatientStatus();
        this.patientStatusEnumMap = new EnumMap(this.patientStatusEnum);
        if (!!this.electronicReferral) {
            this.selectedPartnerOrganization = this.electronicReferral.receivingProviderId;
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: SendReferralForm) => x.selectedPartnerOrganization)
            .required()
            .withMessage("Refer To is required.")
            .on(this);
    }

    private initSubscription() {
        this._bindingSubscriptions.push(
            this._bindingEngine
                .propertyObserver(this.electronicReferral, `${nameof<ElectronicReferral>("nonAdmit")}`)
                .subscribe((newValue: string) => this.resetNonAdmitReason())
        );
        this._bindingSubscriptions.push(
            this._bindingEngine
                .collectionObserver(this.selectedPatientStatuses)
                .subscribe(() => this.patientStatusesChanged())
        );
    }

    public async getPartnerOrganizations(providerId: string) {
        this.partnerOrganizations = await this._serviceUserService.getPartnerProvidersByProvider(providerId);
    }

    public async fetchPatientList(filter: string) {
        try {
            let patients = await this._referralService.getReferralsLean({
                term: filter,
                page: 1,
                pageLength: 10,
                patientStatus: this.selectedPatientStatuses.map((patientStatus) => Number(patientStatus.value)),
                locationIds: [...this.branches.map((branch) => branch.id)]
            });
            this.patientList = patients.items;
            return (
                this.patientList.map((user) => ({
                    name: formatName(user.firstName, user.lastName),
                    value: user.id
                })) ?? []
            );
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Error",
                message: "There was a problem while fetching the patient list. Please try again."
            });
            return [];
        }
    }

    public patientStatusFetch(filter: string): ITypeaheadOptions[] {
        return getEnumFilterOptions(filter, this.patientStatusEnum);
    }

    public patientStatusesChanged() {
        this.selectedPatient = null;
    }

    public async selectedPatientChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            let selectedPatient = this.patientList.find((patient) => {
                return patient.id === newValue.value;
            });
            this.referralId = selectedPatient.id;
            this.providerId = selectedPatient.providerId;
            this.electronicReferral.patientStatus = selectedPatient.patientStatus;
            await this.getPartnerOrganizations(this.providerId);
        } else {
            this.selectedPartnerOrganization = null;
            this.partnerOrganizations = [];
            this.electronicReferral.nonAdmit = false;
            this.electronicReferral.patientStatus = null;
        }
    }

    public selectedPartnerOrganizationChanged(newValue: string) {
        if (newValue) {
            let selectedPartnerOrganization = this.partnerOrganizations.find((organization) => {
                return organization.providerId === newValue;
            });
            this.electronicReferral.receivingProviderId = selectedPartnerOrganization.providerId;
            this.electronicReferral.serviceUserId = selectedPartnerOrganization.serviceUserId;
        }
    }

    public refreshSelectedData() {
        this.selectedPatient = null;
        this.selectedPatientStatuses = [];
        this.selectedPartnerOrganization = null;
        this.partnerOrganizations = [];
        this.electronicReferral.nonAdmit = false;
    }

    public resetNonAdmitReason() {
        if (!this.electronicReferral?.nonAdmit) {
            this.electronicReferral.nonAdmitReasonId = null;
        }
    }

    public detached() {
        this.refreshSelectedData();
        this._bindingSubscriptions?.forEach((subscription) => subscription.dispose());
    }
}
