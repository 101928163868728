import { autoinject, lazy } from "aurelia-framework";
import { NavigationInstruction, Next, RedirectToRoute } from "aurelia-router";
import { UserManager } from "oidc-client";

import { AuthService, userIdKey } from "../../services/auth-service";
import { CurrentUserService } from "../../services/current-user-service";

@autoinject
export class OidcAuthorizeStep {

    private readonly _userManager: () => UserManager;
    private readonly _authService: () => AuthService;
    private readonly _currentUserService: () => CurrentUserService;

    public constructor(@lazy(UserManager) userManager: () => UserManager, @lazy(AuthService) authService: () => AuthService,
        @lazy(CurrentUserService) currentUserService: () => CurrentUserService) {
        this._userManager = userManager;
        this._authService = authService;
        this._currentUserService = currentUserService;
    }

    public async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {

        let instructions = navigationInstruction.getAllInstructions();

        console.log(`Handing a navigation request for ${navigationInstruction.fragment}.`, navigationInstruction.config.name, navigationInstruction.config.moduleId, navigationInstruction, instructions);

        let allowsAnonymous = instructions.some(x => (x.config.settings?.anonymous) || !x.config.name);
        let requiresAuth = !allowsAnonymous;

        console.log("This plan needs authentication:", requiresAuth);
        if (!requiresAuth) {
            console.log("No authentication needed, will unconditionally continue.");
            return await next();
        }

        let loginResult = await this.getLoginStatus();

        let isAuthenticated = loginResult.isAuthenticated;
        if (!isAuthenticated) {
            console.info("Requested plan needs authentication, but the user is not authenticated, will begin sign-in process.");
            return next.cancel(new RedirectToRoute("oidc-sign-in", {
                fragment: navigationInstruction.fragment,
                query: navigationInstruction.queryString,
                referrer: document.referrer
            }));
        } else {
            let currentUserId = loginResult.subjectId;
            // This is done to prevent user with multiple login ids belonging to different accounts
            // Scenario: User Account 1 -> Hospice Account 1 -> Hospice Account 1's id cached
            // User Account 2 -> Hospice Account 2 -> should clear caches account id before making API calls
            let hasCachedUserConflict = await this._authService().hasCachedUserConflict();
            // This is to clear account when the url has an account id.
            // Account id in url gets highest priority
            let hasAccountIdInUrl = navigationInstruction.queryString.includes("accountId");
            if (hasCachedUserConflict || hasAccountIdInUrl) {
                this._authService().clearAccountId();
                if (hasCachedUserConflict) {
                    localStorage.setItem(userIdKey, currentUserId);
                }
            }
        }

        let allowsAccountLess = instructions.some(x => (x.config.settings?.accountLess) || !x.config.name);
        console.log("This plan needs account scopes:", !allowsAccountLess);
        if (allowsAccountLess) {
            console.log("No account scopes are needed, will unconditionally continue.");
            return await next();
        }

        let hasAccountScope = this._authService().getAccountId() !== null;
        if (!hasAccountScope) {
            console.info("Requested plan needs account scopes, but the user has not selected an account, will begin account selection process.");
            return next.cancel(new RedirectToRoute("oidc-select-accounts", {
                fragment: navigationInstruction.fragment,
                query: navigationInstruction.queryString
            }));
        }

        // let currentUser = await this._currentUserService().getUser();
        // if (!currentUser) {
        return await next();
        // } else {
        // Need to handle invalid current user case
        // }
    }

    // TODO: move this service or mediator
    public async getLoginStatus() {

        let user = await this._userManager().getUser();

        let result: LoginStatusResult;

        if (user && !user.expired) {
            let profile = user.profile || {};
            result = {
                isAuthenticated: true,
                token: user.access_token,
                tokenSchema: user.token_type,
                subjectId: user.profile.sub,
                profile: profile
            };
        } else {
            result = {
                isAuthenticated: false,
                token: null,
                tokenSchema: null,
                subjectId: null,
                profile: null
            };
        }

        return result;
    }
}

class LoginStatusResult {
    public isAuthenticated: boolean;
    public token: string;
    public tokenSchema: string;
    public subjectId: string;
    public profile: any;
}
