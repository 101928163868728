import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { AdministrativePermissionEnum } from "../../../enums/administrative-permission-enum";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import { payrollPermission } from "../billing-and-financial/billing-and-financial-routes";
import { payrollReportTooltip } from "../billing-and-financial/billing-and-financial-tooltips";
import {
    employeeRosterTooltip,
    expiringDocumentsTooltip,
    expiringPhysiciansLicenseReportTooltip,
    payrollExportReportTooltip
} from "./people-reports-tooltips";

export const peopleReportPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.People,
        action: PermissionActionEnum.View
    }
];

export const payrollExportReportPermission: IPermissionCheck[] = [
    {
        resource: AdministrativePermissionEnum.GeneratePayrollExports,
        action: PermissionActionEnum.View
    },
    ...peopleReportPermission
];

export const peopleReportsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "expiring-documents-report"
    },
    {
        name: "expiring-physicians-license-report",
        route: "expiring-physicians-license-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./expiring-physicians-license-report/expiring-physicians-license-report",
            "expiring-physicians-license-report"
        ),
        title: "Expiring Physician Licenses Report",
        href: "reports/people-reports/expiring-physicians-license-report",
        settings: {
            tooltip: expiringPhysiciansLicenseReportTooltip,
            menuName: "Expiring Physician Licenses Report",
            permission: peopleReportPermission
        }
    },
    {
        name: "payroll-export-report",
        route: "payroll-export-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./payroll-export-report/payroll-export-report", "payroll-export-report"),
        title: "Payroll Export Report",
        href: "reports/people-reports/payroll-export-report",
        settings: {
            tooltip: payrollExportReportTooltip,
            menuName: "Payroll Export Report",
            permission: payrollExportReportPermission,
            checkAll: true
        }
    },
    {
        name: "expiring-documents-report",
        route: "expiring-documents-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./expiring-documents-report/expiring-documents-report",
            "expiring-documents-report"
        ),
        title: "Expiring Documents Report",
        href: "reports/people-reports/expiring-documents-report",
        settings: {
            tooltip: expiringDocumentsTooltip,
            menuName: "Expiring Documents Report",
            permission: peopleReportPermission
        }
    },
    {
        name: "employee-roster",
        route: "employee-roster",
        nav: true,
        moduleId: PLATFORM.moduleName("./employee-roster/employee-roster", "employee-roster"),
        title: "Employee/Volunteer Roster",
        href: "reports/people-reports/employee-roster",
        settings: {
            tooltip: employeeRosterTooltip,
            menuName: "Employee/Volunteer Roster",
            permission: peopleReportPermission
        }
    },
    {
        name: "payroll-report",
        route: "payroll-report",
        moduleId: PLATFORM.moduleName("../billing-and-financial/payroll-report/payroll-report", "payroll-report"),
        title: "Payroll Report",
        settings: {
            tooltip: payrollReportTooltip,
            permission: payrollPermission
        }
    }
];
