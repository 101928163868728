import { bindable, bindingMode, customElement, computedFrom, autoinject } from "aurelia-framework";

import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import nameof from "../../../../common/nameof";
import { NoteDataManager } from "../../note-data-manager";

@customElement("date-input")
@autoinject
export class DateInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasHighlight: string = "";
    public noteDataManager: NoteDataManager;

    @computedFrom(nameof<DateInput>("hasHighlight"))
    public get highlightClass() {
        switch (this.hasHighlight) {
            case ("errors"): return "is-invalid";
            case ("warnings"): return "is-warning";
            case ("inconsistencies"): return "is-inconsistency";
        }
        return "";
    }

    @computedFrom(nameof<DateInput>("hasHighlight"))
    public get highlightFocus() {
        return this.hasHighlight.length > 0;
    }

    public set highlightFocus(focus: boolean) {
        // focus would try to set property
    }

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.noteDataManager.assignValidationRules.call(
            this,
            this.viewOptions.name,
            this.noteDataManager
        );
    }
}
