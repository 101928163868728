import { autoinject, bindable, bindingMode, containerless, customElement } from "aurelia-framework";
import { Task } from "../../../models/task";
import { TaskService } from "../../../services/task-service";
import "./selected-assessment.scss";

@autoinject
@containerless
@customElement("selected-assessment")
export class SelectedAssessment {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public urlNoteId: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public saveInfo: () => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public returnButtonLabel: string = "Return to Assessment";
    public associatedTaskInfo: Task;
    private _taskService: TaskService;
    public isSaveInProgress: boolean = false;

    public constructor(taskService: TaskService) {
        this._taskService = taskService;
    }

    public async bind() {
        if (this.urlNoteId) {
            try {
                this.associatedTaskInfo = await this._taskService.getTaskById(this.urlNoteId);
            } catch (e) {
                console.error(e);
            }
        }
    }

    public async returnToAssessment() {
        if (!!this.saveInfo) {
            this.isSaveInProgress = true;
            await this.saveInfo();
            this.isSaveInProgress = false;
        }
        window.close();
    }
}