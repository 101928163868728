import { ValidationRules } from "aurelia-validation";

export class Supply {
    public name: string;
    public isFormulary: boolean = false;
    public unitCost: number;
    public associatedAgencyProviderIds: string[] = [];

    public constructor(data?: Supply) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((supply: Supply) => supply.name)
            .required()
            .withMessage("Name is required.")
            .on(this);
    }
}
