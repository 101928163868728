import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";

import { IMaxLengthValidation, IRegexValidation } from "./../../../../interfaces/form-builder/i-validation";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { NoteDataManager } from "../../note-data-manager";
import { IValidateCustomElement } from "./../../../../interfaces/i-validate-custom-element";

@customElement("template-input")
@autoinject
export class TemplateInput {
    // Input fields
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    public validation: IValidateCustomElement[] = [];
    public isValidationLoaded: boolean = false;
    public noteDataManager: NoteDataManager;
    public maxLength: number = 65535;

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public bind() {
        this.initValidation();
    }

    public initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IMaxLengthValidation | IRegexValidation) => {
            if (rule.validationType === FormValidationEnum.Required) {
                this.validation.push({
                    required: true,
                    message: rule.message
                });
            } else if (rule.validationType === FormValidationEnum.MaxLength) {
                this.maxLength = (rule as IMaxLengthValidation).maxLength;
            } else if (rule.validationType === FormValidationEnum.Regex) {
                this.validation.push({
                    matches: true,
                    message: rule.message,
                    satisfies: (rule as IRegexValidation).pattern
                });
            }
        });
        this.isValidationLoaded = true;
    }
}
