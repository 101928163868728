import { BindingEngine, bindingMode, Disposable, observable } from "aurelia-binding";
import { autoinject, bindable, customElement } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IBranchResponse } from "../../../interfaces/i-branch";
import { IMultiSelectDropDownOption } from "../../../interfaces/i-grouped-multi-select";
import { AuthService } from "../../../services/auth-service";
import { BranchesService } from "../../../services/branches-service";

@customElement("branch-default-filter")
@autoinject
export class BranchDefaultFilter {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public labelText: string = "Branches";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public defaultBranchName: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public searchText: string = "Branches";
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<BranchDefaultFilter>("lineOfServiceChanged")
    })
    public lineOfService: number = null;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedBranches: string[] = [];
    private _bindingEngine: BindingEngine;
    private _branchSubscription: Disposable;
    private _authService: AuthService;
    private _branchesService: BranchesService;
    public branches: IBranchResponse[] = [];
    public providerIds: string[] = [];
    public providerEnumMap: EnumMap;
    public branchEnumMap: EnumMap;
    public dropDownOptions: IMultiSelectDropDownOption;
    @observable({
        changeHandler: nameof<BranchDefaultFilter>("filterBranchesChanged")
    })
    public filterBranches: string[] = [];
    public isBranchLoading: boolean = false;

    public constructor(authService: AuthService, branchesService: BranchesService, bindingEngine: BindingEngine) {
        this._authService = authService;
        this._branchesService = branchesService;
        this._bindingEngine = bindingEngine;
    }

    public async attached() {
        await this.initFilters();
    }

    public async initFilters() {
        this.branches = await this._branchesService.getBranchesByLineOfService(this.lineOfService);
        this.providerIds = await this._authService.getProviders();
        let defaultBranches: IBranchResponse[] = await this.getBranchDefaults();
        this.filterBranches =
            this.selectedBranches?.length > 0
                ? Array.from(this.selectedBranches)
                : defaultBranches.map((branch) => branch.id);
        let providerEnum = await this.createProviderEnum();
        let branchEnum = await this.createBranchEnum();
        this.providerEnumMap = new EnumMap(providerEnum);
        this.branchEnumMap = new EnumMap(branchEnum);
        this.dropDownOptions = await this.getDropDownOptions();
        this.initSubscription();
    }

    private initSubscription() {
        this._branchSubscription = this._bindingEngine
            .collectionObserver(this.filterBranches)
            .subscribe(() => this.filterBranchesChanged());
    }

    public filterBranchesChanged() {
        // Using splice and push to modify selectedBranches.
        // CollectionObserver will not work if selectedBranches is reassigned.
        if (this.selectedBranches.length > 0) {
            let branchLength = this.selectedBranches.length;
            this.selectedBranches.splice(0, branchLength);
        }
        if (this.filterBranches?.length > 0) {
            this.filterBranches.forEach((branch) => this.selectedBranches.push(branch));
        } else {
            this.branches.forEach((branch) => this.selectedBranches.push(branch.id));
        }
    }

    public async lineOfServiceChanged() {
        this.isBranchLoading = true;
        await this.initFilters();
        if (this.selectedBranches?.length > 0) {
            let branchLength = this.selectedBranches.length;
            this.selectedBranches.splice(0, branchLength);
        }
        this.isBranchLoading = false;
    }

    public async getBranchDefaults() {
        return this.branches.filter((branch) => this.providerIds.includes(branch.providerId));
    }

    public getAllProviderBranchIds(provider: string) {
        let branchIds: string[] = [];
        this.branches.forEach((branch) => {
            if (branch.providerId === provider) {
                branchIds.push(branch.id);
            }
        });
        return branchIds;
    }

    public getDropDownOptions() {
        let branchOptions: IMultiSelectDropDownOption = {};
        let providers = [...new Set(this.branches.map((branch) => branch.providerId))];
        providers.forEach((providerId) => {
            branchOptions[providerId] = this.getAllProviderBranchIds(providerId);
        });
        return branchOptions;
    }

    public createProviderEnum() {
        let providers = [...new Set(this.branches.map((branch) => branch.providerName))];
        let providerEnum = providers.map((name, index) => {
            return {
                name: name,
                value: index + 1
            };
        });
        return providerEnum;
    }

    public createBranchEnum() {
        return this.branches.map((branch) => ({
            name: branch.name,
            value: branch.id
        }));
    }

    public detached() {
        if (this._branchSubscription) {
            this._branchSubscription.dispose();
        }
    }
}
