import { customAttribute, inject } from "aurelia-framework";

@customAttribute("height-auto")
@inject(Element)
export class HeightAuto {
    private _element: any;

    public constructor(el: Element) {
        this._element = $(el);
    }

    public attached() {
        this._element
            .on("keydown", () => {
                this.adjustHeight();
            })
            .on("focus", () => {
                this.adjustHeight();
            })
            .on("blur", () => {
                this.adjustHeight();
            });
        this.adjustHeight();
    }

    public detached() {
        this._element.off("keydown").off("focus").off("blur");
    }

    public adjustHeight() {
        let textArea = this._element[0];
        setTimeout(() => {
            let height = textArea.scrollHeight;
            textArea.style.height = `${height}px`;
        }, 0);
    }
}
