import "./multi-date-picker.scss";
import "bootstrap-datepicker";
import "custom-event-polyfill";

import { autoinject, bindable, bindingMode, customElement, inject } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import * as moment from "moment";

import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

// custom-event-polyfill is used for custom events to work in IE9/10/11.
// As of 03/23/2018, Axxess products support IE11
// https://www.npmjs.com/package/custom-event-polyfill

@customElement("multi-date-picker")
@inject(Element)
@autoinject
export class MultiDatePicker {
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable
    public debounce = 0;
    @bindable
    public result: string = "";
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public alignLeftStart: boolean = false;
    private _openListener: () => void;
    private _outsideClickListener: (evt: MouseEvent) => void;
    private _multiDatePickerInput: Element;
    private _multiDatePickerElements: JQuery;
    private _keyDownListener: (evt: KeyboardEvent) => void;
    private _self: Element;
    public showCalendar: boolean = false;

    public constructor(element: Element) {
        this._self = element;
        this._openListener = () => this.openCalendar();
        this._outsideClickListener = (evt: MouseEvent) => this.handleBlur(evt);
        this._keyDownListener = (evt: KeyboardEvent) => this.onKeyDown(evt);
    }

    public attached() {
        this._multiDatePickerInput.addEventListener("focus", this._openListener);
        this._multiDatePickerInput.addEventListener("click", this._openListener);
        this._multiDatePickerInput.addEventListener("keydown", this._keyDownListener);
        document.addEventListener("click", this._outsideClickListener);
        this._multiDatePickerElements = $(".multi-calendar-element");
        this._multiDatePickerElements.map((index, calendarElement) => {
            $(calendarElement).datepicker({
                defaultViewDate: {
                    year: new Date().getFullYear(),
                    month: new Date().getMonth() + index,
                    day: 1
                },
                multidate: true,
                updateViewDate: false
            });
        });

        // keep month in sync
        this._multiDatePickerElements.on("changeMonth", (e) => this.orderMonth(e));
        // keep dates in sync
        this._multiDatePickerElements.on("changeDate", (e) => this.changeDate(e));
        if (this.validation) {
            let displayName = this.validation.displayName;
            let message = this.validation.message;
            ValidationRules.ensure((x: MultiDatePicker) => x.result)
                .displayName(displayName)
                .required()
                .when((x: MultiDatePicker) => x.validation.required)
                .withMessage(message ? message : `${displayName} is required.`)
                .on(this);
        }
    }

    public detached() {
        this._multiDatePickerElements.datepicker("destroy");
        document.removeEventListener("click", this._outsideClickListener);
        this._multiDatePickerInput.removeEventListener("keydown", this._keyDownListener);
        this._multiDatePickerInput.removeEventListener("focus", this._openListener);
        this._multiDatePickerInput.removeEventListener("click", this._openListener);
    }

    public clearResult() {
        this.result = "";
        this._multiDatePickerElements.datepicker("clearDates");
        this.showCalendar = false;
    }

    private onKeyDown(evt: KeyboardEvent) {
        setTimeout(() => {
            if (this.showCalendar) {
                this.switchKeyCode(evt.keyCode);
                return;
            }
        }, 0);
    }

    private switchKeyCode(keyCode: number) {
        switch (keyCode) {
            case 27:
                return this.handleScape();
            default:
                return this.handleChange();
        }
    }

    private openCalendar() {
        if (this.showCalendar) {
            return;
        }
        this.showCalendar = true;
    }

    private handleChange() {
        this._multiDatePickerElements.datepicker(
            "setDates",
            this.result
                .split(", ")
                .filter((date) => this.isValidDate(date))
                .map((date) => moment(date).toDate())
        );
    }

    private handleBlur(evt: MouseEvent) {
        if (!this.showCalendar) {
            return;
        }

        setTimeout(() => {
            if (!this._self.contains(evt.target as Node)) {
                this.handleScape();
            }
        }, this.debounce);
    }

    private handleScape() {
        this.showCalendar = false;
    }

    private orderMonth(e: any) {
        let target = e.target;
        let date = e.date;
        let calendars = this._multiDatePickerElements;
        let positionOfTarget = calendars.index(target);
        calendars.each((index, calendarElement) => {
            if (calendarElement === target) {
                return;
            }
            let newDate = new Date(date);
            newDate.setUTCDate(1);
            newDate.setMonth(date.getMonth() + index - positionOfTarget);
            ($(calendarElement) as any).datepicker("_setDate", newDate, "view");
        });
    }

    private changeDate(e: Event) {
        let calendars = this._multiDatePickerElements;
        let target = e.target;
        let newDates = $(target).datepicker("getDates");
        calendars.each((index, calendarElement) => {
            if (calendarElement === e.target) {
                return;
            }
            // setUTCDates triggers changeDate event
            // could easily run into an infinite loop
            // therefore we check if currentDates equal newDates
            let currentDates = $(calendarElement).datepicker("getDates");
            if (
                currentDates &&
                currentDates.length === newDates.length &&
                currentDates.every((currentDate: Date) => {
                    return newDates.some((newDate: Date) => {
                        return currentDate.toISOString() === newDate.toISOString();
                    });
                })
            ) {
                return;
            }
            $(calendarElement).datepicker("setDates", newDates);
            let inputInProgress = this.result.split(", ").filter((date) => !this.isValidDate(date) && date != "");
            this.result = (newDates.map((date: Date) => moment(date).format("MM/DD/YYYY")) as string[])
                .concat(inputInProgress)
                .join(", ");
        });
    }

    private isValidDate(date: string) {
        return /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/.test(date);
    }
}
