import "./summer-editor.scss";
import "summernote/dist/summernote-bs4.js";

import { bindable, bindingMode, observable } from "aurelia-framework";
import * as $ from "jquery";

import nameof from "../../../common/nameof";

export class SummerEditor {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public options: any;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<SummerEditor>("initialValueChanged") })
    public initialValue: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public result: string = "";
    public editor: JQuery<HTMLElement>;
    public editorMarkupRef: HTMLElement;
    public editorLoaded: boolean = false;
    public onLoadData: string = "";

    // TODO: Using Summernote.options type gives an error
    // because buttons is missing in the definition
    public get defaultOptions(): any {
        let styleOptions: Summernote.toolbarStyleGroupOptions[] = ["bold", "italic", "style"];
        let paraOptions: Summernote.toolbarParaGroupOptions[] = ["ul", "ol"];
        let insertOptions: Summernote.toolbarInsertGroupOptions[] = ["link"];
        // TODO: Using Summernote.toolbarDef keeps throwing an error
        // even though the definition was followed
        let toolbarOptions: any = [
            ["style", styleOptions],
            ["para", paraOptions],
            ["insert", insertOptions]
        ];
        return {
            toolbar: toolbarOptions,
            styleTags: ["h1", "h2", "h3", "h4", "h5", "h6"],
            placeholder: "Start typing here...",
            height: 50,
            maxHeight: 50,
            focus: true
        };
    }

    public attached() {
        this.create();
        if (!!this.onLoadData && this.onLoadData.length > 0) {
            this.editor.summernote("code", this.onLoadData);
        }
        this.editorLoaded = true;
    }

    private create() {
        let $markupRef = $(this.editorMarkupRef);
        let editorOptions = Object.assign({}, this.options, this.defaultOptions);
        if (!!editorOptions.buttons && Object.keys(editorOptions.buttons).length > 0) {
            editorOptions.toolbar.push(["customButtons", Object.keys(editorOptions.buttons)]);
        }
        this.editor = $markupRef.summernote(editorOptions);
        $(this.editorMarkupRef).summernote("code", "");
        this.editor.on("summernote.change", () => {
            this.handleChange();
        });
    }

    public handleChange() {
        let content = $(this.editorMarkupRef).summernote("code") as unknown;
        if (this.result !== content) {
            this.result = content as string;
        }
    }

    public initialValueChanged(newValue: string) {
        // console.log("SUMMER EDITOR: Initial Value changed: ", { newValue, result: this.result });
        if (newValue !== this.result) {
            // console.log("Summer Editor: Initial Value Changed:", newValue);
            if (this.editorLoaded && !!this.editor) {
                this.onLoadData = "";
                if (!!newValue && newValue.length > 0) {
                    this.editor.summernote("code", newValue);
                } else {
                    this.editor.summernote("code", "");
                }
            } else {
                if (!!newValue && newValue.length > 0) {
                    this.onLoadData = newValue;
                }
            }
        }
    }

    public reset() {
        if (this.editorLoaded) {
            this.destroy();
            // summernote leaves the html message behind after destruction, this removes it
            this.editorMarkupRef.innerHTML = "";
            this.create();
        }
    }

    private destroy() {
        $(this.editorMarkupRef).summernote("destroy");
        this.editor = null;
    }

    public detached() {
        this.destroy();
    }
}
