import { NoteDataManager } from "../note-data-manager";
import { IRequiredAllValidation } from "../../../interfaces/form-builder/i-validation";
import { FluentRules } from "aurelia-validation";
import { FormModelType } from "../../../interfaces/form-builder/i-model-schema";

export class RequiredAllValidationHandler {
    public handle(
        validationRules: FluentRules<any, any>,
        validation: IRequiredAllValidation,
        noteDataManager: NoteDataManager,
        condition: () => boolean
    ) {
        let dependencyName = validation.dependencyName;
        // converts first character to lowercase
        dependencyName = dependencyName.charAt(0).toLowerCase() + dependencyName.slice(1);
        let dependencyValues = noteDataManager.getValue(dependencyName) as FormModelType[];
        if (!dependencyValues) {
            dependencyValues = noteDataManager.getOptionTypeFromProp(dependencyName);
        }
        validationRules
            .minItems(dependencyValues?.length)
            .when(condition)
            .withMessage(!validation.message ? "This item is required. Please select a response" : validation.message)
            .on(this);
    }
}
