import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { UserManager } from "oidc-client";

export const accountIdKey = "hospice-account-id";
export const userIdKey = "hospice-user-id";
export const selectedProviders = "hospice-provider-ids";
export const lineOfService = "hospice-line-of-service";

export const enum AccountEvent {
    Selected = "account:selected",
    ProvidersSelected = "account:providersSelected"
}
@autoinject
export class AuthService {
    private readonly _ea: EventAggregator;
    private readonly _userManager: UserManager;

    public constructor(userManager: UserManager, ea: EventAggregator) {
        this._userManager = userManager;
        this._ea = ea;
    }

    public async isLoggedIn(): Promise<boolean> {
        let user = await this._userManager.getUser();
        return user != null && !user.expired;
    }

    public async hasCachedUserConflict(): Promise<boolean> {
        let user = await this._userManager.getUser();
        if (!!user && !!user.profile) {
            let currentUserId = user.profile.sub;
            let cachedUserId = localStorage.getItem(userIdKey);
            return !cachedUserId || (!!cachedUserId && cachedUserId !== currentUserId);
        }
        return false;
    }

    public needsAccountSelection() {
        return this.getAccountId() == null;
    }

    public getAccountId() {
        let cachedAccountData = window.localStorage.getItem(accountIdKey);
        return cachedAccountData;
    }

    public async setAccountId(accountId: string) {
        window.localStorage.setItem(accountIdKey, accountId);
        this._ea.publish(AccountEvent.Selected, accountId);
    }

    public clearAccountId() {
        window.localStorage.removeItem(accountIdKey);
    }

    public async loadAccountAndProviders() {
        // Checking to see if user has an account and provider in local storage
        // Usage: To preload account and providers in new tabs
        let accountId = this.getAccountId();
        let providers = this.getProviders();
        if (accountId != null && providers?.length > 0) {
            this._ea.publish(AccountEvent.Selected, accountId);
            this._ea.publish(AccountEvent.ProvidersSelected, providers);
        }
    }

    public getProviders() {
        return JSON.parse(window.localStorage.getItem(selectedProviders));
    }

    public getLineOfService() {
        return Number(window.localStorage.getItem(lineOfService));
    }

    public async setProviderInformation(providers: string[], serviceType: number) {
        window.localStorage.setItem(selectedProviders, JSON.stringify(providers));
        window.localStorage.setItem(lineOfService, serviceType.toString());
        this._ea.publish(AccountEvent.ProvidersSelected, providers);
    }
}
