import { DialogService } from "aurelia-dialog";
import { autoinject, PLATFORM, useView } from "aurelia-framework";
import { Router } from "aurelia-router";
import { UserManager } from "oidc-client";

import { config } from "../../common/config";
import { ILocationSave } from "../../interfaces/i-location-save";
import { LoginPrompt } from "../../resources/dialogs/login-prompt/login-prompt";
import { AuthService, userIdKey } from "../../services/auth-service";

@autoinject
@useView(PLATFORM.moduleName("./sign-in-view.html"))
export class SignInStartPage {
    private readonly _userManager: UserManager;
    private readonly _dialogService: DialogService;
    private readonly _router: Router;
    private readonly _authService: AuthService;
    public isBeginning: boolean;
    public isCompleting: boolean;
    public fragment: string;
    public query: string;

    public constructor(userManager: UserManager, dialogService: DialogService, router: Router, authService: AuthService) {
        this._userManager = userManager;
        this._dialogService = dialogService;
        this._router = router;
        this._authService = authService;
    }

    public async activate(params: any) {
        console.log("User is not logged in, sign in start");
        params = params || {};
        this.fragment = params.fragment;
        this.query = params.query;
        let referrer: string = params.referrer;

        let save: ILocationSave = {
            fragment: this.fragment,
            query: this.query,
        };

        window.sessionStorage.setItem(config.locationSaveKey, JSON.stringify(save));
        console.log("Sign in start: ", { referrer });
        if (referrer) {
            // If the URL has accountId then we need to load that account.
            // In order to load the account in the URL we need to clear the account ID in the cache
            // By clearing, oidc-auth-step will redirect the user to select accounts page
            // and select accounts will take the account id from url and store it
            if (this.query.includes("accountId")) {
                console.log("URL query has account id:", this.query);
                this._authService.clearAccountId();
            }
            // this._userManager.signinRedirect();
        } else {
            this._authService.clearAccountId();
        }
        this._userManager.signinRedirect();
    }
}
