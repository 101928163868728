import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { IContactsOrder } from "../interfaces/i-get-patient";
import {
    IConvertReferralResult,
    ICreateInterimReferralIdResult,
    ICreateReferralResult,
    IExportReferral,
    IGetReferralQuery,
    IGetReferralResult
} from "../interfaces/i-get-referral";
import {
    IGetCurrentPatientPayerQuery,
    IGetPatientPayerQuery,
    IGetPatientPayerResult
} from "../interfaces/i-patient-payor";
import { IHospiceReferral } from "../interfaces/patients/i-hospice-referral";
import { AuthorizedContact } from "../models/authorized-contact";
import { HospiceReferral } from "../models/hospice-referral";
import { PatientPayor } from "../models/patient-payor";
import { LineOfService } from "../pages/create/create-interim-referral/model/line-of-service";
import { ICreateResponse } from "./../interfaces/i-response";
import {
    INonAdmitReferralQuery,
    IPalliativeReferral,
    IReferralDocument,
    IUpdateReferralPayorResponse,
    IUploadReferralDocumentQuery,
    IUploadReferralDocumentResult,
    IGetPreadmissionNotes,
    IGetPreadmissionNotesQuery,
    ICreatePreadmissionNoteResponse,
    ITransferReferral
} from "./i-referral-services";
import {
    IElectronicReferralDetails,
    IGetElectronicReferralQuery,
    IGetReferralsLean,
    IGetReferralsLeanQuery,
    IReReferDetails,
    IRejectReferralDetails
} from "../interfaces/i-electronic-referral";
import { ElectronicReferral } from "../models/electronic-referral";
import { PreadmissionNote } from "../pages/create/create-patient/referral-information/preadmission-notes/models/preadmission-note-model";
import { IPaginationNew } from "../interfaces/i-pagination";
import { IGetPatientContactQuery } from "../interfaces/i-patient-contact";

@autoinject()
export class ReferralService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/referrals";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getReferralByPage(query: IGetReferralQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetReferralResult>();
    }

    public async createInterimReferral(lineOfService: LineOfService) {
        return await this._httpClient
            .build(`${this._baseUrl}/interim`)
            .useMethod("POST")
            .useBody(lineOfService)
            .fetch<ICreateInterimReferralIdResult>();
    }

    public async getHospiceReferral(referralId: string) {
        return await this._httpClient.build(`${this._baseUrl}/hospice/${referralId}`).fetch<IHospiceReferral>();
    }

    public async createHospiceReferral(referral: HospiceReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/hospice`)
            .useMethod("POST")
            .useBody(referral)
            .fetch<ICreateReferralResult>();
    }

    public async editHospiceReferral(referral: HospiceReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/hospice/${referral.id}`)
            .useMethod("PUT")
            .useBody(referral)
            .fetchNoContent();
    }

    public async getPalliativeReferral(referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care/${referralId}`)
            .fetch<IPalliativeReferral>();
    }

    public async createPalliativeReferral(referral: IPalliativeReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care`)
            .useMethod("POST")
            .useBody(referral)
            .fetch<ICreateReferralResult>();
    }

    public async editPalliativeReferral(referral: IPalliativeReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care/${referral.id}`)
            .useMethod("PUT")
            .useBody(referral)
            .fetchNoContent();
    }

    public async updateReferralToNonAdmit(referralId: string, query: INonAdmitReferralQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/status/non-admit`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getElectronicReferralByPage(query: IGetElectronicReferralQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/electronic-referral`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IPaginationNew<IElectronicReferralDetails>>();
    }

    public async getReferralsLean(query: IGetReferralsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/electronic-referral/referrals/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IPaginationNew<IGetReferralsLean>>();
    }

    public async acceptReferral(electronicReferralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/electronic-referral/${electronicReferralId}/accept`)
            .useMethod("POST")
            .fetch();
    }

    public async rejectReferral(electronicReferralId: string, rejectReferralDetails: IRejectReferralDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/electronic-referral/${electronicReferralId}/reject`)
            .useBody(rejectReferralDetails)
            .useMethod("POST")
            .fetch();
    }

    public async reReferReferral(electronicReferralId: string, reReferDetails: IReReferDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/electronic-referral/${electronicReferralId}/re-refer`)
            .useBody(reReferDetails)
            .useMethod("POST")
            .fetch();
    }

    public async printReferral(electronicReferralId: string) {
        return await this._httpClient.build(`${this._baseUrl}/electronic-referral/${electronicReferralId}/print`).fetchBlob();
    }

    public async sendReferral(id: string, electronicReferral: ElectronicReferral | ITransferReferral) {
        return await this._httpClient
            .build(`${this._baseUrl}/electronic-referral/${id}`)
            .useMethod("POST")
            .useBody(electronicReferral)
            .fetch<ICreateResponse>();
    }

    public async addContact(referralId: string, contact: AuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/authorized-contact`)
            .useMethod("POST")
            .useBody(contact)
            .fetch<ICreateResponse>();
    }

    public async updateContact(contactInformation: AuthorizedContact) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${contactInformation.id}`)
            .useMethod("PUT")
            .useBody(contactInformation)
            .fetch<ICreateResponse>();
    }

    public async deleteContact(contactId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/authorized-contact/${contactId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async reorderContacts(contactsOrder: IContactsOrder[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/order-contacts`)
            .useMethod("PUT")
            .useBody({ contactsOrder: contactsOrder })
            .fetch<{}>();
    }

    public async getContacts(referralId: string, query?: IGetPatientContactQuery) {
        // TODO: Whats fe in the url? - Distinction from mobile and web endpoints.
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/contacts-fe`)
            .useQueryString(query)
            .fetch<AuthorizedContact[]>();
    }

    public async getReferralDocuments(referralId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${referralId}/documents`).fetch<IReferralDocument[]>();
    }

    public async uploadDocument(referralId: string, query: IUploadReferralDocumentQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/documents`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IUploadReferralDocumentResult>();
    }

    public async deleteDocument(referralId: string, documentId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/documents/${documentId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async createReferralPayors(referralId: string, patientPayer: PatientPayor) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/payers`)
            .useMethod("POST")
            .useBody(patientPayer)
            .fetch<ICreateResponse>();
    }

    public async updateReferralPayors(payerId: string, patientPayer: PatientPayor) {
        return await this._httpClient
            .build(`${this._baseUrl}/payers/${payerId}`)
            .useMethod("PUT")
            .useBody(patientPayer)
            .fetch<IUpdateReferralPayorResponse>();
    }

    public async getCurrentReferralPayors(referralId: string, query: IGetCurrentPatientPayerQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/current-payers`)
            .useQueryString(query)
            .fetch<IGetPatientPayerResult>();
    }

    public async getReferralPayors(referralId: string, query: IGetPatientPayerQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/payers`)
            .useQueryString(query)
            .fetch<IGetPatientPayerResult>();
    }

    public async deleteReferralPayors(payerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/payers/${payerId}`).useMethod("DELETE").fetchNoContent();
    }

    public async convertToHospicePatient(referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/hospice/${referralId}/convert`)
            .useMethod("PUT")
            .fetch<IConvertReferralResult>();
    }

    public async convertToPalliativePatient(referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/palliative-care/${referralId}/convert`)
            .useMethod("PUT")
            .fetch<IConvertReferralResult>();
    }

    public async deleteReferral(referralId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${referralId}`).useMethod("DELETE").fetchNoContent();
    }

    public async exportReferralExcel(query: IExportReferral) {
        return await this._httpClient.build(`${this._baseUrl}/xlsx`).useQueryString(query).fetchBlob();
    }

    public async getPreadmissionNotes(query: IGetPreadmissionNotesQuery, referralId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetPreadmissionNotes>>();
    }

    public async createPreadmissionNotes(referralId: string, note: PreadmissionNote) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes`)
            .useMethod("POST")
            .useBody(note)
            .fetch<ICreatePreadmissionNoteResponse>();
    }

    public async updatePreadmissionNotes(referralId: string, noteId: string, note: PreadmissionNote) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes/${noteId}`)
            .useMethod("PUT")
            .useBody(note)
            .fetch<ICreatePreadmissionNoteResponse>();
    }

    public async deletePreadmissionNotes(referralId: string, noteId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${referralId}/preadmission-notes/${noteId}`)
            .useMethod("Delete")
            .fetchNoContent();
    }
}
