import { observable, autoinject, bindable, bindingMode, containerless, customElement } from "aurelia-framework";
import { NavModel } from "aurelia-router";
import nameof from "../../../common/nameof";
import "./nav-menu-more-button.scss";

@autoinject
@containerless
@customElement("nav-menu-more-button")
export class NavMenuMoreButton {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public menuContainer: HTMLElement;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<NavMenuMoreButton>("activeItemChanged") })
    public activeItem: HTMLElement;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public routes: NavModel[];
    public activeSubMenuItem: number;
    public navMoreButton: HTMLElement;

    public attached() {
        if (this.menuContainer) {
            // INFO: The 250 ms delay is for Aurelia to render the UI
            // Else the width of each nav menu retrieved is not accurate
            setTimeout(() => this.overflowAdjust(), 250);
            this.handleOverflow();
        }
    }

    private handleOverflow() {
        let timeout: NodeJS.Timer = null;
        window.addEventListener("resize", () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() =>
                this.overflowAdjust(), 100);
        });
    }

    public activeItemChanged() {
        if (this.routes) {
            this.activeSubMenuItem = this.routes.findIndex((item) => item.isActive);
        }
        this.overflowAdjust();
    }

    private getElementProperty(item: HTMLElement, property: string) {
        if (!item) {
            return 0;
        }
        const style = document.defaultView.getComputedStyle(item);
        const left = style?.getPropertyValue(`${property}-left`);
        const right = style?.getPropertyValue(`${property}-right`);
        return Number(left.replace(/px$/, "")) + Number(right.replace(/px$/, ""));
    }

    private overflowAdjust() {
        const navBar = this.menuContainer;
        let navItems = Array.from(navBar.querySelectorAll("ul > li:not(#nav-more-button)"));
        let moreButtonItems = Array.from(navBar.getElementsByClassName("secondary-menu-item"));
        const moreButton = this.navMoreButton;
        const allHiddenItems = Array.from(navBar.getElementsByClassName("hidden"));
        allHiddenItems.forEach((item) => {
            item.classList.remove("hidden");
        });
        let stopWidth = moreButton?.offsetWidth + this.getElementProperty(moreButton, "margin");
        let hiddenItems: number[] = [];
        const navWidth = navBar.clientWidth - this.getElementProperty(navBar, "padding");
        if (!this.activeSubMenuItem && this.activeSubMenuItem !== 0) {
            this.activeSubMenuItem = navItems.findIndex((item) => item.classList.contains("active"));
        }
        // Find and filter out active menu item
        moreButtonItems[this.activeSubMenuItem]?.classList.add("hidden");
        const active = navItems[this.activeSubMenuItem] as HTMLElement;
        stopWidth += active?.offsetWidth + this.getElementProperty(active, "margin");
        moreButtonItems = moreButtonItems.filter((item, index) => index !== this.activeSubMenuItem);
        navItems = navItems.filter((item, index) => index !== this.activeSubMenuItem);
        // Hide nav items depending on width
        navItems.forEach((navItem, i) => {
            let item = navItem as HTMLElement;
            const itemWidth = item.offsetWidth + this.getElementProperty(item, "margin");
            if (navWidth >= stopWidth + itemWidth) {
                stopWidth += itemWidth;
            } else {
                item.classList.add("hidden");
                hiddenItems.push(i);
            }
        });
        if (!hiddenItems.length) {
            moreButton?.classList?.add("hidden");
        } else {
            moreButtonItems.forEach((item, i) => {
                if (!hiddenItems.includes(i)) {
                    item.classList.add("hidden");
                }
            });
        }
    }

}
