import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import {
    admissionReportTooltip,
    advanceDirectivesTooltip,
    authorizationReportTooltip,
    bereavementCensusReportTooltip,
    cahpsReportingTooltip,
    censusByLocTooltip,
    censusByStatusTooltip,
    nonAdmitReasonTooltip,
    patientByPhysicianListing,
    patientEmergencyPreparedness,
    patientsByCareTeamMemberTooltip,
    pendingPatientTooltip,
    referralReportTooltip,
    daysOnServiceReportTooltip,
    funeralHomeReportTooltip,
    qualisPatientImportReportTooltip,
    unVerifiedPayersTooltip
} from "./patient-reports-tooltips";

export const patientPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.Patient,
        action: PermissionActionEnum.View
    }
];

export const patientReportsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "census-by-loc"
    },
    {
        name: "days-on-service-report",
        route: "days-on-service-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./days-on-service-report/days-on-service-report", "days-on-service-report"),
        title: "Days on Service Report",
        href: "reports/days-on-service-report/days-on-service-report",
        settings: {
            tooltip: daysOnServiceReportTooltip,
            menuName: "Days on Service Report",
            permission: patientPermission,
            releaseDate: "5/17/2023"
        }
    },
    {
        name: "funeral-home-report",
        route: "funeral-home-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./funeral-home-report/funeral-home-report", "funeral-home-report"),
        title: "Funeral Home Report",
        href: "reports/funeral-home-report/funeral-home-report",
        settings: {
            tooltip: funeralHomeReportTooltip,
            menuName: "Funeral Home Report",
            permission: patientPermission,
            releaseDate: "05/04/2023",
            isPalliativeCare: false
        }
    },
    {
        name: "bereavement-census-report",
        route: "bereavement-census-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./bereavement-census-report/bereavement-census-report",
            "bereavement-census-report"
        ),
        title: "Bereavement Census Report",
        href: "reports/bereavement-census-report/bereavement-census-report",
        settings: {
            tooltip: bereavementCensusReportTooltip,
            menuName: "Bereavement Census Report",
            isPalliativeCare: false,
            permission: patientPermission
        }
    },
    {
        name: "authorization-report",
        route: "authorization-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./authorization-report/authorization-report", "authorization-report"),
        title: "Authorization Report",
        href: "reports/authorization-report/authorization-report",
        settings: {
            tooltip: authorizationReportTooltip,
            menuName: "Authorization Report",
            isPalliativeCare: false,
            permission: patientPermission
        }
    },
    {
        name: "patients-by-care-team-member",
        route: "patients-by-care-team-member",
        nav: true,
        moduleId: PLATFORM.moduleName("./patients-by-care-team/patients-by-care-team", "patients-by-care-team"),
        title: "Patients by Care Team Member",
        href: "reports/patients-by-care-team/patients-by-care-team",
        settings: {
            tooltip: patientsByCareTeamMemberTooltip,
            menuName: "Patients by Care Team Member",
            isPalliativeCare: false,
            permission: patientPermission
        }
    },
    {
        name: "admission-report",
        route: "admission-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./admission-report/admission-report", "admission-report"),
        title: "Admission Report",
        href: "reports/admission-report/admission-report",
        settings: {
            tooltip: admissionReportTooltip,
            menuName: "Admission Report",
            isPalliativeCare: false,
            permission: patientPermission
        }
    },
    {
        name: "referral-report",
        route: "referral-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./referral-report/referral-report", "pending-patient-report"),
        title: "Referral Report",
        href: "reports/patient-reports/referral-report",
        settings: {
            tooltip: referralReportTooltip,
            menuName: "Referral Report",
            permission: patientPermission
        }
    },
    {
        name: "pending-patient-report",
        route: "pending-patient-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./pending-patient-report/pending-patient-report", "pending-patient-report"),
        title: "Pending Patient Report",
        href: "reports/patient-reports/pending-patient-report",
        settings: {
            tooltip: pendingPatientTooltip,
            menuName: "Pending Patient Report",
            permission: patientPermission
        }
    },
    {
        name: "census-by-loc",
        route: "census-by-loc",
        nav: true,
        moduleId: PLATFORM.moduleName("./census-by-loc/census-by-loc", "census-by-loc"),
        title: "Census by Level of Care",
        href: "reports/patient-reports/census-by-loc",
        settings: {
            tooltip: censusByLocTooltip,
            menuName: "Census by Level of Care",
            isPalliativeCare: false,
            permission: patientPermission
        }
    },
    {
        name: "census-by-status",
        route: "census-by-status",
        nav: true,
        moduleId: PLATFORM.moduleName("./census-by-status/census-by-status", "census-by-status"),
        title: "Census by Status",
        href: "reports/patient-reports/census-by-status",
        settings: {
            tooltip: censusByStatusTooltip,
            menuName: "Census by Status",
            permission: patientPermission
        }
    },
    {
        name: "patient-by-physician",
        route: "patient-by-physician",
        nav: true,
        moduleId: PLATFORM.moduleName("./patient-by-physician/patient-by-physician", "patient-by-physician"),
        title: "Patients by Physician",
        href: "reports/patient-reports/patient-by-physician",
        settings: {
            tooltip: patientByPhysicianListing,
            menuName: "Patients by Physician",
            permission: patientPermission
        }
    },
    {
        name: "patient-emergency-preparedness",
        route: "patient-emergency-preparedness",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./patient-emergency-preparedness/patient-emergency-preparedness",
            "patient-emergency-preparedness"
        ),
        title: "Emergency Preparedness Patient List",
        href: "reports/patient-reports/patient-emergency-preparedness",
        settings: {
            tooltip: patientEmergencyPreparedness,
            menuName: "Emergency Preparedness Patient List",
            permission: patientPermission
        }
    },
    {
        name: "advance-directives",
        route: "advance-directives",
        nav: true,
        moduleId: PLATFORM.moduleName("./advance-directives/advance-directives", "advance-directives"),
        title: "Advance Directives",
        href: "reports/patient-reports/advance-directives",
        settings: {
            tooltip: advanceDirectivesTooltip,
            menuName: "Advance Directives",
            permission: patientPermission
        }
    },
    {
        name: "non-admit-reason",
        route: "non-admit-reason",
        nav: true,
        moduleId: PLATFORM.moduleName("./non-admit-reason/non-admit-reason", "non-admit-reason"),
        title: "Non-Admit by Reason",
        href: "reports/patient-reports/non-admit-reason",
        settings: {
            tooltip: nonAdmitReasonTooltip,
            menuName: "Non-Admit by Reason",
            permission: patientPermission
        }
    },
    {
        name: "qualis-patient-import",
        route: "qualis-patient-import",
        nav: true,
        moduleId: PLATFORM.moduleName("./qualis-patient-import/qualis-patient-import", "qualis-patient-import"),
        title: "Qualis Patient Import",
        href: "reports/patient-reports/qualis-patient-import",
        settings: {
            tooltip: qualisPatientImportReportTooltip,
            menuName: "Qualis Patient Import",
            permission: patientPermission
        }
    },
    {
        name: "cahps-reporting",
        route: "cahps-reporting",
        nav: true,
        moduleId: PLATFORM.moduleName("./cahps-reporting/cahps-reporting", "cahps-reporting"),
        title: "CAHPS Reporting Data",
        href: "reports/patient-reports/cahps-reporting",
        settings: {
            tooltip: cahpsReportingTooltip,
            menuName: "CAHPS Reporting Data",
            permission: patientPermission
        }
    },
    {
        name: "unverified-payers-report",
        route: "unverified-payers-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./unverified-payers-report/unverified-payers-report",
            "unverified-payers-report"
        ),
        title: "Unverified Payers Report",
        href: "reports/patient-reports/unverified-payers-report",
        settings: {
            tooltip: unVerifiedPayersTooltip,
            menuName: "Unverified Payers Data",
            permission: patientPermission
        }
    }
];
