import "./pay-rates-table.scss";

import { BindingEngine, Disposable } from "aurelia-binding";
import { bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { EnumMapType } from "../../../common/utilities/enum-map-type";
import { PayrollMenuEnum } from "../../../enums/payroll-menu-enum";
import { IGetCustomNonPatient } from "../../../interfaces/employee-schedule/i-non-patient-activity";
import { IBranchResponse } from "../../../interfaces/i-branch";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IAgencyTaskLeanResult } from "../../../interfaces/i-get-agency-task";
import { IPageCallbackResult } from "../../../interfaces/i-page-container";
import {
    IDeletePayRateParams,
    IGetPayRateParams,
    IGetPayRatesByPageResult,
    IUpdatePayRateParams
} from "../../../interfaces/i-pay-rate";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IGetCurrentPayType } from "../../../interfaces/i-user-payroll-settings";
import { PayRate } from "../../../models/pay-rate";
import { AgencyTasksService } from "../../../services/agency-task-service";
import { PayorService } from "../../../services/payor-service";
import { PAGE_FAIL_RESPONSE, Pagination } from "../pagination/pagination";
import { AccountsService } from "../../../services/accounts-service";

@customElement("pay-rates-table")
export class PayRatesTable {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<PayRatesTable>("providerIdChanged")
    })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public branchesList: IBranchResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public activePayrollTab: PayrollMenuEnum = PayrollMenuEnum.PayRates;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public payType: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public userSettings: IGetCurrentPayType;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public newPayRateId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public permission: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public fetchRates: (event: IGetPayRateParams) => Promise<IGetPayRatesByPageResult>;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateRate: (rate: IUpdatePayRateParams) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public deleteRate: (id: IDeletePayRateParams) => void;
    private readonly _accountsService: AccountsService;
    private readonly _payorService: PayorService;
    private readonly _agencyTasksService: AgencyTasksService;
    private _bindingEngine: BindingEngine;
    private _selectedFiltersSubscription: Disposable[] = [];
    @observable({
        changeHandler: nameof<PayRatesTable>("startDateChanged")
    })
    public startDate: string;
    public agencyTasksList: IAgencyTaskLeanResult[] = [];
    public isLoading: boolean = false;
    public isError: boolean = false;
    public payRatesPagination: Pagination;
    public page: IGetPayRatesByPageResult;
    public columns: number = 7;
    public selectedTasks: ITypeaheadOptions[] = [];
    public selectedBranches: ITypeaheadOptions[] = [];
    public selectedPayers: ITypeaheadOptions[] = [];
    public rateTypeEnumMap: EnumMapType;
    public rateTypes: ITypeaheadOptions[] = [];
    public nonPatientActivityList: IGetCustomNonPatient[] = [];

    @computedFrom(nameof<PayRatesTable>("page"))
    public get loadingCount() {
        return this.page?.items?.length > 0 ? this.page.items.length : 10;
    }

    @computedFrom(`${nameof<PayRatesTable>("page")}.${nameof<IGetPayRatesByPageResult>("itemCount")}`)
    public get hasData() {
        return this.page?.itemCount > 0;
    }

    public constructor(
        payorService: PayorService,
        accountsService: AccountsService,
        agencyTasksService: AgencyTasksService,
        bindingEngine: BindingEngine
    ) {
        this._payorService = payorService;
        this._accountsService = accountsService;
        this._agencyTasksService = agencyTasksService;
        this._bindingEngine = bindingEngine;
    }

    public async attached() {
        this.rateTypeEnumMap = new EnumMap(this.rateTypeEnum).getMap();
        let currentLineOfService = await this._accountsService.getLineOfService();
        this.agencyTasksList = await this._agencyTasksService.getTasksLean({
            lineOfService: currentLineOfService
        });
        this.nonPatientActivityList = await this._agencyTasksService.getCustomNonPatientActivities();
        this.initSubscription();
    }

    public initSubscription() {
        this._selectedFiltersSubscription.push(
            this._bindingEngine?.collectionObserver(this.selectedTasks).subscribe(() => this.filtersChanged())
        );
        this._selectedFiltersSubscription.push(
            this._bindingEngine?.collectionObserver(this.rateTypes).subscribe(() => this.filtersChanged())
        );
        this._selectedFiltersSubscription.push(
            this._bindingEngine?.collectionObserver(this.selectedBranches).subscribe(() => this.filtersChanged())
        );
        this._selectedFiltersSubscription.push(
            this._bindingEngine?.collectionObserver(this.selectedPayers).subscribe(() => this.filtersChanged())
        );
    }

    public filtersChanged() {
        this.pageReset();
    }

    public startDateChanged() {
        this.pageReset();
    }

    public tasksFetch(filter: string) {
        return this.agencyTasksList
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((task) => ({
                name: task.name,
                value: task.id
            }));
    }

    public rateTypeFetch(filter: string) {
        return this.rateTypeEnum
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((rate) => ({
                name: rate.name,
                value: rate.value
            }));
    }

    public getProviderIds() {
        if (this.selectedBranches.length > 0) {
            let selectedBranchIds = this.selectedBranches.map((branch) => branch.value);
            let selectedProviders = this.branchesList.filter((branch) => selectedBranchIds.includes(branch.id));
            return [...new Set(selectedProviders.map((branch) => branch.providerId))];
        }
        return [...new Set(this.branchesList.map((branch) => branch.providerId))];
    }

    public async providerIdChanged() {
        if (this.providerId) {
            this.selectedPayers = [];
            this.selectedBranches = [];
        }
    }

    public async payersFetch(filter: string) {
        let response = await this._payorService.getPayorsByName({
            name: filter,
            includeCharity: true,
            providerIds: this.providerId ? [this.providerId] : this.getProviderIds()
        });
        if (response) {
            return response.map((payor) => ({ name: payor.name, value: payor.id }));
        } else {
            console.error("Unable to Fetch");
            return [];
        }
    }

    public branchesFetch(filter: string) {
        return this.branchesList
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((branch) => ({
                name: branch.name,
                value: branch.id
            }));
    }

    public async pageChangedCallback(pageNumber: number, pageSize: number): Promise<IPageCallbackResult> {
        try {
            this.isError = false;
            let customTaskIds =
                this.selectedTasks.length > 0 ? this.selectedTasks.map((selected) => selected.value) : [];
            let locationIds =
                this.selectedBranches.length > 0 ? this.selectedBranches.map((selected) => selected.value) : [];
            let payorIds = this.selectedPayers.length > 0 ? this.selectedPayers.map((selected) => selected.value) : [];
            let rateTypes = this.rateTypes.length > 0 ? this.rateTypes.map((selected) => Number(selected.value)) : [];
            let data = await this.fetchRates({
                pageNumber,
                pageSize,
                customTaskIds,
                locationIds,
                payorIds,
                rateTypes,
                startDate: this.startDate
            });
            this.page = data;
            return {
                totalPageCount: data.pageCount,
                success: data.itemCount > 0,
                totalCount: data.itemCount
            };
        } catch (e) {
            console.error(e);
            this.isError = true;
            return PAGE_FAIL_RESPONSE;
        }
    }

    public async pageReset() {
        if (this.payRatesPagination) {
            this.isError = false;
            await this.payRatesPagination.reset();
        }
    }

    public updatePayRate(id: string, updatedPayRate: PayRate) {
        return this.updateRate({
            id,
            updatedPayRate
        });
    }

    public deletePayRate(id: string) {
        return this.deleteRate({
            id
        });
    }
}
