import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    ICreateCompanyPayrollSettings,
    IGetCompanyPayrollSettings,
    IGetCurrentCompanyPayrollSettings
} from "../interfaces/i-company-payroll-settings";
import { IGetMedicareWeekResult, IProviderMedicareWeek } from "../interfaces/i-medicare-week";
import { IPaginationNew, IPaginationRequest } from "../interfaces/i-pagination";
import {
    ICreatePayRate,
    IGetPayRatesByPageQuery,
    IGetPayRatesByPageResult,
    IUpdatePayRate
} from "../interfaces/i-pay-rate";
import { IPayrollCenterResult } from "../interfaces/i-payroll-report";
import {
    ICreateReimbursementRate,
    IGetReimbursementRate,
    IGetReimbursementRatesByPageQuery,
    IUpdateReimbursementRate
} from "../interfaces/i-reimbursement-rate";
import { ICreateResponse } from "../interfaces/i-response";
import { BillingSettings } from "../models/billing-settings";
import { OperationSettings } from "../models/operation-settings";
import { ClinicalSettings } from "./../models/clinical-settings";
import {
    IActivateTaskData,
    IBulkApprovePayroll,
    ICreateBranchVendorQuery,
    ICreateBranchVendorQueryResult,
    ICreateNpaPayRate,
    ICreatePatientTagQueryResult,
    ICreatePayrollCycleGroup,
    ICreatePayrollEarningCodeQuery,
    ICreatePayrollEarningCodeQueryResult,
    ICreateProviderMrn,
    IDeactivateTaskData,
    IExportLawsonReportQuery,
    IGetBranchVendor,
    IGetCurrentPayPeriod,
    IGetCustomBillingProvider,
    IGetEarningCodeCustomTasks,
    IGetEarningCodesQuery,
    IGetEarningCodesResult,
    IGetIntegrationStatus,
    IGetIntegrationStatusQuery,
    IGetIsPayrollCycleGroupsEnabled,
    IGetNpaPayRate,
    IGetNpaPayRatesByPageQuery,
    IGetPatientTag,
    IGetPatientTagsQuery,
    IGetPatientTagsResult,
    IGetPayrollBranchVendorQuery,
    IGetPayrollCenterInfo,
    IGetPayrollCenterReportQuery,
    IGetPayrollGroupsLean,
    IGetPayrollSettingsQuery,
    IGetProvider,
    IGetProviderAgencyLocations,
    IGetProviderBatchId,
    IGetProviderDefault,
    IGetProviderMrn,
    IGetProviderTask,
    IGetProviderTaskQuery,
    IGetShpCahpsVendorStatus,
    IGetTaskByDisciplineQuery,
    IGetVolunteerActivityTask,
    IPayrollCycleGroup,
    IProviderTaskByDiscipline,
    IUpdateBranchVendorQuery,
    IUpdatePayrollCenterInfo,
    IUpdatePayrollEarningCodeQuery,
    IUpdateProviderMrn,
    IPatientTagsQuery,
    IBulkMarkNonPayable,
    IGetFeatureEnabled,
    IGetProviderFeatureStatus,
    IUpdateClosedAccount,
    IUpdateNotificationSettings,
    IGetProviderByName,
    IGetProviderByNameQuery,
    IGetProviderPayrollGroups,
    IGetNotificationSettings,
    IGetProviderFeatureByLocation,
    IGetProviderFeatureByLocationQuery,
    IGetProviderCompanyRatesQuery,
    IProviderCompanyRates,
    IUpdateCompanyRates,
    ILoadCompanyRatesRequest,
    IGetPatientTagByLocation,
    IGetPriorPeriodsResult,
    IGetPriorPeriodsRequest,
    IUpdatePriorPeriodsRequest,
    IGetProviderByIdResult,
    ICheckDateInClosedPeriodQuery,
    ICheckDateInClosedPeriodResult,
    IPostProvidersLogo,
    IPutProvidersLogo,
    IProvidersLogo
} from "./i-providers-service";
import { IGetCustomVolunteerActivity } from "../interfaces/employee-schedule/i-volunteer-acitivty";
import { AuthService } from "./auth-service";
import { EnumsService } from "./enums-service";
import { EnumMap } from "../common/utilities/enum-map";
import { config } from "../common/config";
import Infrastructure from "../common/infrastructure";
import { UsersService } from "./users-service";

@autoinject()
export class ProvidersService {
    private _httpClient: HospiceClient;
    private readonly _authService: AuthService;
    private readonly _enumsService: EnumsService;
    private readonly _usersService: UsersService;
    private _baseUrl: string = "/api/v1/provider";

    public constructor(httpClient: HospiceClient, authService: AuthService, enumsService: EnumsService, usersService: UsersService) {
        this._httpClient = httpClient;
        this._authService = authService;
        this._enumsService = enumsService;
        this._usersService = usersService;
    }

    public async getProvider() {
        return await this._httpClient.build(`${this._baseUrl}`).fetch<IGetProvider[]>();
    }

    public async getProviderById(providerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${providerId}`).fetch<IGetProviderByIdResult>();
    }

    public async getBillingSettings(providerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${providerId}/billingsettings`).fetch<BillingSettings>();
    }

    public async updateBillingSettings(providerId: string, billingSettings: BillingSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/billingSettings`)
            .useMethod("PUT")
            .useBody(billingSettings)
            .fetchNoContent();
    }

    public async getClinicalSettings(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/clinicalsettings`)
            .fetch<ClinicalSettings>();
    }

    public async updateClinicalSettings(providerId: string, clinicalSettings: ClinicalSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/clinicalsettings`)
            .useMethod("PUT")
            .useBody(clinicalSettings)
            .fetchNoContent();
    }

    public async getOperationSettings(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/operationalsettings`)
            .fetch<OperationSettings>();
    }

    public async updateOperationSettings(providerId: string, operationalSettings: OperationSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/operationalsettings`)
            .useMethod("PUT")
            .useBody(operationalSettings)
            .fetchNoContent();
    }

    public async getMedicareWeekByPage(providerId: string, query: IPaginationRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/medicareweek`)
            .useQueryString(query)
            .fetch<IGetMedicareWeekResult>();
    }

    public async createMedicareWeek(providerId: string, medicareWeek: IProviderMedicareWeek) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/medicareweek`)
            .useMethod("POST")
            .useBody(medicareWeek)
            .fetchNoContent();
    }

    public async updateMedicareWeek(providerId: string, medicareWeekId: string, medicareWeek: IProviderMedicareWeek) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/medicareweek/${medicareWeekId}`)
            .useMethod("PUT")
            .useBody(medicareWeek)
            .fetchNoContent();
    }

    public async deleteMedicareWeek(providerId: string, medicareWeekId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/medicareweek/${medicareWeekId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getMedicareWeek(providerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${providerId}/currentmedicareweek`).fetch<number>();
    }

    public async getPayrollSettings(providerId: string, query: IGetPayrollSettingsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollsettings`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetCompanyPayrollSettings>>();
    }

    public async getCurrentPayrollSettings(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/currentpayrollsettings`)
            .fetch<IGetCurrentCompanyPayrollSettings>();
    }

    public async updatePayrollSettings(settingsId: string, payrollInfo: ICreateCompanyPayrollSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/payrollsettings/${settingsId}`)
            .useMethod("PUT")
            .useBody(payrollInfo)
            .fetch<ICreateResponse>();
    }

    public async createCompanyPayrollSettings(providerId: string, payrollInfo: ICreateCompanyPayrollSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollsettings`)
            .useMethod("POST")
            .useBody(payrollInfo)
            .fetch<ICreateResponse>();
    }

    public async deleteCompanyPayrollSettings(providerId: string, payrollSettingId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollsettings/${payrollSettingId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getOnCallPayRates(providerId: string, query: IGetPayRatesByPageQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrates`)
            .useQueryString(query)
            .fetch<IGetPayRatesByPageResult>();
    }

    public async createPayRate(providerId: string, payRate: ICreatePayRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrate`)
            .useMethod("POST")
            .useBody(payRate)
            .fetchNoContent();
    }

    public async editPayRate(rateId: string, providerId: string, rate: IUpdatePayRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrate/${rateId}`)
            .useMethod("PUT")
            .useBody(rate)
            .fetchNoContent();
    }

    public async deletePayRate(rateId: string, providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrate/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getNpaPayRates(providerId: string, query: IGetNpaPayRatesByPageQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrates`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetNpaPayRate>>();
    }

    public async createNpaPayRate(providerId: string, payRate: ICreateNpaPayRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/non-patient-task-pay-rate`)
            .useMethod("POST")
            .useBody(payRate)
            .fetchNoContent();
    }

    public async getReimbursementRates(providerId: string, query: IGetReimbursementRatesByPageQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollreimbursement`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetReimbursementRate>>();
    }

    public async editReimbursementRate(providerId: string, rateId: string, rate: IUpdateReimbursementRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollreimbursement/${rateId}`)
            .useMethod("PUT")
            .useBody(rate)
            .fetchNoContent();
    }

    public async deleteReimbursementRate(providerId: string, rateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollreimbursement/${rateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async createReimbursementRate(providerId: string, rate: ICreateReimbursementRate) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payrollreimbursement`)
            .useMethod("POST")
            .useBody(rate)
            .fetchNoContent();
    }

    public async getPayrollCenterInfo(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center-info`)
            .fetch<IGetPayrollCenterInfo>();
    }

    public async getFeatureStatus(data: IGetProviderFeatureStatus) {
        return await this._httpClient
            .build(`${this._baseUrl}/feature-status`)
            .useMethod("POST")
            .useBody(data)
            .fetch<IGetFeatureEnabled[]>();
    }

    public async getFeatureByLocation(request: IGetProviderFeatureByLocationQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/feature-by-locations`)
            .useQueryString(request)
            .fetch<IGetProviderFeatureByLocation>();
    }

    public async updatePayrollCenterInfo(providerId: string, payrollCenter: IUpdatePayrollCenterInfo) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center`)
            .useMethod("PUT")
            .useQueryString(payrollCenter)
            .fetchNoContent();
    }

    public async createBranchVendor(providerId: string, newBranch: ICreateBranchVendorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/branch-vendor`)
            .useMethod("POST")
            .useBody(newBranch)
            .fetch<ICreateBranchVendorQueryResult>();
    }

    public async updateBranchVendor(providerId: string, branchVendorId: string, newBranch: IUpdateBranchVendorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/branch-vendor/${branchVendorId}`)
            .useMethod("PUT")
            .useBody(newBranch)
            .fetchNoContent();
    }

    public async getBranchVendors(providerId: string, query: IGetPayrollBranchVendorQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/branch-vendors`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetBranchVendor>>();
    }

    public async deleteBranchVendor(branchId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/branch-vendor/${branchId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async deactivateBranchVendor(branchId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${branchId}/deactivate-branch-vendor`)
            .useMethod("PUT")
            .fetchNoContent();
    }

    public async getProviderBatchId(providerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${providerId}/batch-id`).fetch<IGetProviderBatchId>();
    }

    public async getProviderAgencyLocations(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/agency-locations`)
            .fetch<IGetProviderAgencyLocations[]>();
    }

    public async getVolunteerActivityTask(providerId: string, query: IGetVolunteerActivityTask) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/volunteer-activities`)
            .useQueryString(query)
            .fetch<IGetCustomVolunteerActivity[]>();
    }

    public async getPayrollCenterReport(providerId: string, query: IGetPayrollCenterReportQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center-report`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IPayrollCenterResult>();
    }

    public async downloadPayrollCenter(providerId: string, query: IGetPayrollCenterReportQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center-report/csv`)
            .useMethod("POST")
            .useBody(query)
            .fetchBlob();
    }

    public async exportCSVPayrollGeneralReport(providerId: string, payrollItemIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-general/csv`)
            .useMethod("PUT")
            .useBody({ payrollItemIds })
            .fetchBlob();
    }

    public async exportCSVPayrollUltiproReport(providerId: string, payrollItemIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-ultipro/csv`)
            .useMethod("PUT")
            .useBody({ payrollItemIds })
            .fetchBlob();
    }

    public async bulkApprovePayroll(providerId: string, approveItems: IBulkApprovePayroll) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center/approve`)
            .useMethod("PUT")
            .useBody(approveItems)
            .fetchNoContent();
    }

    public async bulkMarkAsNonPayable(providerId: string, markItems: IBulkMarkNonPayable) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center/mark-non-payable`)
            .useMethod("PUT")
            .useBody(markItems)
            .fetchNoContent();
    }

    public async bulkUnapprovePayroll(providerId: string, payrollItemIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-center/unapprove`)
            .useMethod("PUT")
            .useBody({
                payrollItemIds
            })
            .fetchNoContent();
    }

    public async exportCSVPayrollLawsonReport(providerId: string, query: IExportLawsonReportQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-lawson/csv`)
            .useMethod("PUT")
            .useBody(query)
            .fetchBlob();
    }

    public async createPayrollEarningCode(providerId: string, newEarningCode: ICreatePayrollEarningCodeQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-earning-code`)
            .useMethod("POST")
            .useBody(newEarningCode)
            .fetch<ICreatePayrollEarningCodeQueryResult>();
    }

    public async updatePayrollEarningCode(earningCodeId: string, newEarningCode: IUpdatePayrollEarningCodeQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/payroll-earning-code/${earningCodeId}`)
            .useMethod("PUT")
            .useBody(newEarningCode)
            .fetchNoContent();
    }

    public async getEarningCodeCustomTasks(providerId: string, lineOfService: number) {
        return await this._httpClient
            .build(`${this._baseUrl}/custom-tasks`)
            .useQueryString({ providerId, lineOfService })
            .fetch<IGetEarningCodeCustomTasks[]>();
    }

    public async getPayrollEarningCodes(providerId: string, query: IGetEarningCodesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-earning-codes`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetEarningCodesResult>>();
    }

    public async changeEarningCodeStatus(earningCodeId: string, isActivate: boolean) {
        return await this._httpClient
            .build(`${this._baseUrl}/earning-code/change-status/${earningCodeId}`)
            .useMethod("PUT")
            .useQueryString({ isActivate })
            .fetchNoContent();
    }

    public async getProvidersDefault(lineOfService?: number) {
        // NOTE: remove if condition if we no longer need to hide accounts for Liberty Agency
        // and clean up properties that wouldnt be required
        let accountId = this._authService.getAccountId();
        let res = await this._httpClient
            .build(`${this._baseUrl}/default`)
            .useQueryString({ lineOfService: lineOfService })
            .fetch<IGetProviderDefault[]>();
        // NOTE: giving access to split solution for only axxess users. if no longer needed, remove current user check below
        let currentUser = await this._usersService.getCurrentUser();
        if (currentUser?.email.endsWith("@axxess.com")) {
            return res;
        }
        let isProd = Infrastructure.isProdEnvironment;
        let libertyHidingStartDate = "2024-01-26T20:00:00";
        let todaysDate = new Date();
        let todaysDateEST = todaysDate.toLocaleString("en-US", { timeZone: "America/New_York" });
        if (
            accountId === config.libertyAccountId &&
            new Date(todaysDateEST) > new Date(libertyHidingStartDate) &&
            isProd
        ) {
            let lineOfServiceEnum = await this._enumsService.getLineOfService();
            let lineOfServiceEnumMap = new EnumMap(lineOfServiceEnum);
            res = res.filter((provider) => lineOfServiceEnumMap.checkEnumValue("Hospice", provider.lineOfService));
        }
        return res;
    }

    public async updateProviderDefaults(providers: IGetProviderDefault[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/default`)
            .useMethod("PUT")
            .useBody({ defaultProviders: providers })
            .fetchNoContent();
    }

    public async getTaskList(providerId: string, query: IGetProviderTaskQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/custom-task`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetProviderTask>>();
    }

    public async activateTask(providerId: string, taskId: string, data: IActivateTaskData) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/custom-task/${taskId}/activate`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async deactivateTask(providerId: string, taskId: string, data: IDeactivateTaskData) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/custom-task/${taskId}/deactivate`)
            .useMethod("PUT")
            .useBody(data)
            .fetchNoContent();
    }

    public async getMrnInformation(providerId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${providerId}/mrn-settings`).fetch<IGetProviderMrn[]>();
    }

    public async createProviderMrn(providerId: string, mrn: ICreateProviderMrn[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/mrn-settings`)
            .useMethod("POST")
            .useBody({ mrnDetails: mrn })
            .fetchNoContent();
    }

    public async updateProviderMrn(providerId: string, mrn: IUpdateProviderMrn[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/mrn-settings`)
            .useMethod("PUT")
            .useBody({ mrnDetails: mrn })
            .fetchNoContent();
    }

    public async getPatientTags(providerId: string, query: IGetPatientTagsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/tags`)
            .useQueryString(query)
            .fetch<IPaginationNew<IGetPatientTagsResult>>();
    }

    public async changePatientTagStatus(providerId: string, patientTagId: string, isActive: boolean) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/tags/change-status/${patientTagId}`)
            .useMethod("PUT")
            .useQueryString({ isActive })
            .fetchNoContent();
    }

    public async updatePatientTag(providerId: string, patientTagId: string, name: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/tags/${patientTagId}`)
            .useMethod("PUT")
            .useBody({ name })
            .fetchNoContent();
    }

    public async createPatientTag(providerId: string, name: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/tags`)
            .useMethod("POST")
            .useBody({ name })
            .fetch<ICreatePatientTagQueryResult>();
    }

    public async getActivePatientTags(providerIds?: string[], isActive?: boolean) {
        return await this._httpClient
            .build(`${this._baseUrl}/tags`)
            .useQueryString({ providerIds, isActive })
            .fetch<IGetPatientTag[]>();
    }

    public async getPatientTagsByLocation(locationIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/tags-by-location`)
            .useQueryString({ locationIds })
            .fetch<IGetPatientTagByLocation[]>();
    }

    public async getActivePatientTagsViaQuery(query?: IPatientTagsQuery) {
        return await this._httpClient.build(`${this._baseUrl}/tags`).useQueryString(query).fetch<IGetPatientTag[]>();
    }

    public async getProviderCustomTaskByDiscipline(query: IGetTaskByDisciplineQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/custom-task/by-discipline`)
            .useQueryString(query)
            .fetch<IProviderTaskByDiscipline[]>();
    }

    public async getCurrentPayPeriod(providerId: string, payrollGroupId: string = null) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/current-pay-periods`)
            .useQueryString({ payrollGroupId })
            .fetch<IGetCurrentPayPeriod[]>();
    }

    public async getIsPayrollCycleGroupsEnabled(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-cycle-status`)
            .fetch<IGetIsPayrollCycleGroupsEnabled>();
    }

    public async getPayrollCycleGroups(providerId: string, isActive: boolean = null) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-groups`)
            .useQueryString({ isActive })
            .fetch<IPayrollCycleGroup[]>();
    }

    public async createPayrollCycleGroup(providerId: string, name: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-groups`)
            .useMethod("POST")
            .useBody({ name })
            .fetch<ICreatePayrollCycleGroup>();
    }

    public async updatePayrollCycleGroup(providerId: string, groupId: string, name: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-groups/${groupId}`)
            .useMethod("PUT")
            .useBody({ name })
            .fetchNoContent();
    }

    public async deactivatePayrollCycleGroup(providerId: string, groupId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/payroll-groups/${groupId}/deactivate`)
            .useMethod("PUT")
            .fetchNoContent();
    }

    public async getShpCahpsStatus(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/is-provider-shp-cahps-vendor`)
            .fetch<IGetShpCahpsVendorStatus>();
    }

    public async getIntegrationStatus(query: IGetIntegrationStatusQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/integration-check`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetIntegrationStatus>();
    }

    public async getAllProviderPayrollGroups(query: IGetProviderPayrollGroups) {
        return await this._httpClient
            .build(`${this._baseUrl}/payroll-groups/lean`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetPayrollGroupsLean[]>();
    }

    public async getProvidersForCustomBilling() {
        return await this._httpClient.build(`${this._baseUrl}/for-custom-billing`).fetch<IGetCustomBillingProvider[]>();
    }

    public async closeAccounting(request: IUpdateClosedAccount) {
        return await this._httpClient
            .build(`${this._baseUrl}/closed-accounting`)
            .useMethod("PUT")
            .useBody(request)
            .fetchNoContent();
    }

    public async getProvidersByName(request: IGetProviderByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/by-name`)
            .useQueryString(request)
            .fetch<IGetProviderByName[]>();
    }

    public async getNotificationSettings(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/notification-settings`)
            .fetch<IGetNotificationSettings>();
    }

    public async updateNotificationsSettings(notificationSettings: IUpdateNotificationSettings) {
        return await this._httpClient
            .build(`${this._baseUrl}/notification-settings`)
            .useMethod("PUT")
            .useBody(notificationSettings)
            .fetchNoContent();
    }

    public async getProvidersLogo(providerId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/providers-logo`)
            .fetch<IPaginationNew<IProvidersLogo>>();
    }

    public async postProvidersLogo(query: IPostProvidersLogo) {
        return await this._httpClient.build(`${this._baseUrl}/providers-logo`).useMethod("POST").useBody(query).fetch();
    }

    public async putProvidersLogo(providerId: string, logoId: string, query: IPutProvidersLogo) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/providers-logo/${logoId}`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async deleteProvidersLogo(logoId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/providers-logo/${logoId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }
    public async getCompanyRates(providerId: string, query: IGetProviderCompanyRatesQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/agency-rates`)
            .useMethod("GET")
            .useQueryString(query)
            .fetch<IPaginationNew<IProviderCompanyRates>>();
    }

    public async addCompanyRates(providerId: string, request: IProviderCompanyRates) {
        return this._httpClient
            .build(`${this._baseUrl}/${providerId}/agency-rates`)
            .useMethod("POST")
            .useBody(request)
            .fetch<IPaginationNew<IProviderCompanyRates>>();
    }

    public async updateCompanyRates(providerId: string, request: IUpdateCompanyRates) {
        return this._httpClient
            .build(`${this._baseUrl}/${providerId}/agency-rates`)
            .useMethod("PUT")
            .useBody(request)
            .fetchNoContent();
    }

    public async getCompanyRatesHistory(providerId: string, query: IPaginationRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/agency-rates/history`)
            .useMethod("GET")
            .useQueryString(query)
            .fetch<IPaginationNew<IProviderCompanyRates>>();
    }

    public async loadCompanyRatesFromScheduling(providerId: string, request: ILoadCompanyRatesRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/agency-rates/copy`)
            .useMethod("POST")
            .useBody(request)
            .fetchNoContent();
    }

    public async getProviderAccountingPeriods(providerId: string, query: IGetPriorPeriodsRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/accounting-periods`)
            .useMethod("GET")
            .useQueryString(query)
            .fetch<IPaginationNew<IGetPriorPeriodsResult>>();
    }

    public async updateAccountingPeriods(providerId: string, periodId: string, request: IUpdatePriorPeriodsRequest) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/accounting-periods/${periodId}`)
            .useMethod("PUT")
            .useBody(request)
            .fetchNoContent();
    }

    public async checkDateInClosedPayPeriod(providerId: string, query: ICheckDateInClosedPeriodQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${providerId}/check-accounting-periods/`)
            .useMethod("GET")
            .useQueryString(query)
            .fetch<ICheckDateInClosedPeriodResult>();
    }
}
