import { ValidationRules } from "aurelia-validation";

import { ICreateVolunteerActivity } from "../../interfaces/employee-schedule/i-volunteer-acitivty";

export class VolunteerActivityModel implements ICreateVolunteerActivity {
    public id?: string;
    public taskId: string;
    public userId: string;
    public startDates: string[];
    public endDates: string[];
    public locationId: string = null;
    public status?: number;
    public addendumId?: string;

    public constructor(data?: ICreateVolunteerActivity) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules
            .ensure((x: VolunteerActivityModel) => x.locationId)
            .required()
            .withMessage("Branch is required.")
            .ensure((x: VolunteerActivityModel) => x.taskId)
            .required()
            .withMessage("Activity is required.")
            .ensure((x: VolunteerActivityModel) => x.startDates)
            .required()
            .withMessage("Date is required.")
            .on(this);
    }
}
