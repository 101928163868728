import "./npa-pay-rate-form.scss";

import { bindable, bindingMode, customElement } from "aurelia-framework";

import { getFilterOptions } from "../../../common/utilities/filter-manager";
import { IGetCustomNonPatient } from "../../../interfaces/employee-schedule/i-non-patient-activity";
import { IBranchResponse } from "../../../interfaces/i-branch";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { NpaPayRate } from "./npa-pay-rate";

@customElement("npa-pay-rate-form")
export class NpaPayRateForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public npaPayRate: NpaPayRate = new NpaPayRate();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public branchesList: IBranchResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isEdit: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public nonPatientActivityList: IGetCustomNonPatient[] = [];
    @bindable({ defaultBindingMore: bindingMode.toView })
    public isUserRate: boolean = false;
    @bindable({ defaultBindingMore: bindingMode.toView })
    public isBranchesLoading: boolean = false;

    public nonPatientActivityFetch(filter: string) {
        return this.nonPatientActivityList
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((activity) => ({
                name: `${activity.providerName || "Default"} - ${activity.name}`,
                value: activity.taskId,
                providerId: activity.providerId
            }));
    }

    public branchesFetch(filter: string) {
        return getFilterOptions(filter, this.branchesList);
    }
}
