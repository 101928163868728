import { customElement } from "aurelia-templating";
import mapsapi from "google-maps-api";

import Infrastructure from "../common/infrastructure";
import { IGeoCodeResponse } from "../interfaces/i-google-maps";

import GoogleMaps = google.maps;
@customElement("google-maps")
export class GoogleMapsService {
    private _activeMap: google.maps.Map;
    private _googleMapsApiKey: string;
    private _api: any;
    private _googleMaps: any;

    public constructor() {
        console.info("::Google Maps Service:: GOOGLE MAPS API: constructed");
        this._googleMapsApiKey = Infrastructure.isProdEnvironment
            ? "AIzaSyCXMBbTpJy-f8U5b7LimMqYQ2SUILFEk3M"
            : "AIzaSyDWlGgGmraEvpQl4woSVNpVTfR2cSqKDS0";
    }

    public async loadMapsApi(): Promise<void> {
        this._api = mapsapi(this._googleMapsApiKey, ["places"])();
        console.info("::Google Maps Service:: GOOGLE MAPS API: instantiated", this._api);
        this._googleMaps = await this._api;
    }

    public async getMapsApi() {
        if (!this._googleMaps) {
            await this.loadMapsApi();
        }
        return this._googleMaps;
    }

    public async getCoordsFromAddress(address: any, callBack: (info: IGeoCodeResponse) => void): Promise<void> {
        await this.loadMapsApi();
        let maps: any = this._googleMaps;
        if (maps && address && callBack && typeof callBack === "function") {
            let gc: GoogleMaps.Geocoder = new maps.Geocoder();
            gc.geocode({
                address: address
            }, (results: Array<GoogleMaps.GeocoderResult>, status: GoogleMaps.GeocoderStatus) => {
                let info = this.mapGeoCodeResponse(results, status);
                if (!info.hasError) {
                    callBack(info);
                }
            });
        }
    }

    public async getAddressFromCoords(location: any, callBack: (info: IGeoCodeResponse) => void): Promise<void> {
        await this.loadMapsApi();
        let maps = this._googleMaps;
        if (maps && location && callBack && typeof callBack === "function") {
            let gc: GoogleMaps.Geocoder = new maps.Geocoder();
            gc.geocode({
                location: location
            }, (results: Array<GoogleMaps.GeocoderResult>, status: GoogleMaps.GeocoderStatus) => {
                let info = this.mapGeoCodeResponse(results, status);
                callBack(info);
            });
        }
    }

    public mapGeoCodeResponse(results: Array<GoogleMaps.GeocoderResult>, status: GoogleMaps.GeocoderStatus): IGeoCodeResponse {
        let maps = this._googleMaps;
        if (status === maps.GeocoderStatus.OK) {
            let location: GoogleMaps.LatLng = results[0].geometry.location;
            let lat: string = location.lat().toFixed(3);
            let lng: string = location.lng().toFixed(3);
            let info: IGeoCodeResponse = {
                lat: lat,
                lng: lng,
                location: location
            };
            // Address Structure:
            // https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
            let address: Array<GoogleMaps.GeocoderAddressComponent> = results[0].address_components;
            for (let addressComponent of address) {
                let type: string = addressComponent.types[0];
                switch (type) {
                    case "street_number":
                    case "route":
                    case "country":
                    case "postal_code":
                    case "postal_code_suffix":
                        break;
                    case "locality":
                        info.city = addressComponent.short_name;
                        break;
                    case "administrative_area_level_1":
                        info.state = addressComponent.short_name;
                        break;
                    case "administrative_area_level_2":
                        info.county = addressComponent.short_name;
                        break;
                }
            }
            return info;
        } else {
            return {
                hasError: true,
                message: "ERROR: Could not get lat long from zip code."
            };
        }
    }
}
