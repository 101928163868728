import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { AdministrativePermissionEnum } from "../../enums/administrative-permission-enum";
import { ClinicalPermissionEnum } from "../../enums/clinical-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";
import {
    viewInquiryPermission,
    viewInsurancePayorsPermission,
    viewReferralPermission
} from "../list/list-route-permission";
import { IPermissionCheck } from "../../common/utilities/permission-manager";
import { DashboardPermissionEnum } from "../../enums/dashboard-permission-enum";

export const InquiryKeywords = ["inquiry", "lead"];

export const viewAllergyPermission: IPermissionCheck[] = [
    {
        resource: ClinicalPermissionEnum.Allergies,
        action: PermissionActionEnum.View
    }
];

export const PatientsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "patient-chart"
    },
    {
        name: "patients-dashboard",
        route: "dashboard",
        moduleId: PLATFORM.moduleName("./patients-dashboard/patients-dashboard", "patients-dashboard"),
        nav: true,
        title: "Patients Dashboard",
        settings: {
            menuName: "Patients Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.PatientDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "inquiry-list",
        route: "inquiry-list",
        moduleId: PLATFORM.moduleName("../list/inquiry-list/inquiry-list", "inquiry-list"),
        nav: true,
        title: "Inquiries",
        settings: {
            menuName: "Inquiries",
            keywords: InquiryKeywords,
            hideTitle: true,
            permission: viewInquiryPermission
        }
    },
    {
        name: "referral-list",
        route: "referral-list",
        moduleId: PLATFORM.moduleName("../list/referral-list/referrals", "referrals"),
        nav: true,
        title: "Referrals",
        settings: {
            menuName: "Referrals",
            hideTitle: true,
            permission: viewReferralPermission
        }
    },
    {
        name: "patient-chart",
        route: "patient-chart/:id?",
        moduleId: PLATFORM.moduleName("./patient-chart/patient-chart", "patient-chart"),
        href: "patients/patient-chart/load",
        nav: true,
        title: "Patient Charts",
        settings: {
            menuName: "Patient Charts",
            keywords: ["profile", "record", "client", "resident", "face", "facesheet"]
        }
    },
    {
        name: "medication-profile",
        route: "medication-profile/:id?",
        moduleId: PLATFORM.moduleName("./medication-profile/medication-profile", "medication-profile"),
        href: "patients/medication-profile/load",
        nav: true,
        title: "Medication Profile",
        settings: {
            menuName: "Medication",
            keywords: ["med", "list", "drug", "interaction", "check", "pos", "orders"]
        }
    },
    {
        name: "emar-profile",
        route: "emar-profile/:id?",
        moduleId: PLATFORM.moduleName("./emar-profile/emar-profile", "emar-profile"),
        href: "patients/emar-profile/load",
        title: "Electronic Medication Administration Record (eMAR)",
        nav: true,
        settings: {
            menuName: "eMAR",
            keywords: ["electronic", "medication", "administration", "record", "emar"],
            permission: [
                {
                    resource: ClinicalPermissionEnum.eMAR,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "allergy-profile",
        route: "allergy-profile/:id?",
        moduleId: PLATFORM.moduleName("./allergy-profile/allergy-profile", "allergy-profile"),
        href: "patients/allergy-profile/load",
        title: "Allergy Profile",
        nav: true,
        settings: {
            menuName: "Allergy",
            keywords: ["allergies", "list"],
            permission: viewAllergyPermission
        }
    },
    {
        name: "bereavement-plan-of-care",
        route: "bereavement-plan-of-care/:id?",
        moduleId: PLATFORM.moduleName(
            "./bereavement-plan-of-care/bereavement-plan-of-care",
            "bereavement-plan-of-care"
        ),
        href: "patients/bereavement-plan-of-care/load",
        title: "Bereavement Plan of Care Profile",
        nav: true,
        settings: {
            menuName: "Bereavement",
            isPalliativeCare: false,
            keywords: ["bereavement", "poc", "care"],
            permission: [
                {
                    resource: ClinicalPermissionEnum.Bereavement,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "plan-of-care",
        route: "plan-of-care/:id?",
        moduleId: PLATFORM.moduleName("./plan-of-care/plan-of-care", "plan-of-care"),
        href: "patients/plan-of-care/load",
        title: "Plan of Care Profile",
        nav: true,
        settings: {
            menuName: "Plan of Care",
            keywords: ["poc", "careplan", "care", "ipoc", "problem", "goal", "intervention"]
        }
    },
    {
        name: "diagnosis-history",
        route: "diagnosis-history/:id?",
        moduleId: PLATFORM.moduleName("./diagnosis-history/diagnosis-history", "diagnosis-history"),
        href: "patients/diagnosis-history/load",
        title: "Diagnosis History",
        nav: true,
        settings: {
            menuName: "Diagnosis",
            keywords: ["diagnosis", "diagnoses"]
        }
    },
    {
        name: "frequency-profile",
        route: "frequency-profile/:id?",
        moduleId: PLATFORM.moduleName("./frequency-profile/frequency-profile", "frequency-profile"),
        href: "patients/frequency-profile/load",
        title: "Frequency Profile",
        nav: true,
        settings: {
            menuName: "Frequency",
            isPalliativeCare: false,
            keywords: ["frequency", "frequencies"]
        }
    },
    {
        name: "infectious-disease-profile",
        route: "infectious-disease-profile/:id?",
        moduleId: PLATFORM.moduleName(
            "./infectious-disease-profile/infectious-disease-profile",
            "infectious-disease-profile"
        ),
        href: "patients/infectious-disease-profile/load",
        title: "Infectious Disease Profile",
        nav: true,
        settings: {
            menuName: "Infectious Disease",
            keywords: ["infectious", "disease"]
        }
    },
    // {
    //     name: "orders-dashboard",
    //     route: "orders-dashboard",
    //     moduleId: PLATFORM.moduleName("./orders-dashboard/orders-dashboard", "orders-dashboard"),
    //     nav: true,
    //     title: "Orders Dashboard",
    //     settings: {
    //         menuName: "Orders Dashboard",
    //         isPalliativeCare: false
    //     }
    // },
    {
        name: "level-of-care",
        route: "level-of-care/:id?",
        moduleId: PLATFORM.moduleName("./level-of-care/level-of-care", "level-of-care"),
        href: "patients/level-of-care/load",
        title: "Level of Care History",
        nav: true,
        settings: {
            isPalliativeCare: false,
            menuName: "Level of Care History",
            keywords: ["level of care"]
        }
    },
    {
        name: "orders-management",
        route: "orders-management",
        moduleId: PLATFORM.moduleName("./orders-management/orders-management", "orders-management"),
        nav: true,
        title: "Orders Management",
        settings: {
            menuName: "Orders Management",
            keywords: [
                "tracking",
                "processing",
                "send",
                "receive",
                "signature",
                "face",
                "cti",
                "document",
                "physician",
                "f2f",
                "ftf",
                "certification",
                "pa",
                "np",
                "practitioner"
            ],
            permission: [
                {
                    resource: ClinicalPermissionEnum.Orders,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "patient-edit",
        route: "patient-edit/:id?",
        moduleId: PLATFORM.moduleName("../create/create-patient/create-patient", "create-patient"),
        href: "patients/patient-edit/load",
        nav: false,
        title: "Edit Patient",
        settings: {
            permission: [
                {
                    resource: ClinicalPermissionEnum.PatientChart,
                    action: PermissionActionEnum.Edit
                }
            ]
        }
    },
    {
        name: "palliative-patient-edit",
        route: "palliative-patient-edit/:id?",
        moduleId: PLATFORM.moduleName(
            "../create/create-palliative-patient/create-palliative-patient",
            "create-palliative-patient"
        ),
        href: "patients/patient-palliative-edit/load",
        nav: false,
        title: "Edit Patient",
        settings: {
            permission: [
                {
                    resource: ClinicalPermissionEnum.PatientChart,
                    action: PermissionActionEnum.Edit
                }
            ]
        }
    },
    {
        name: "referral-edit",
        route: "referral-edit/:id?",
        moduleId: PLATFORM.moduleName("../create/create-referral/create-referral", "create-referral"),
        href: "patients/referral-edit/load",
        nav: false,
        title: "Edit Referral",
        settings: {
            permission: [
                {
                    resource: AdministrativePermissionEnum.Referrals,
                    action: PermissionActionEnum.Edit
                }
            ]
        }
    },
    {
        name: "palliative-referral-edit",
        route: "palliative-referral-edit/:id?",
        moduleId: PLATFORM.moduleName(
            "../create/create-palliative-referral/create-palliative-referral",
            "create-palliative-referral"
        ),
        href: "patients/palliative-referral-edit/load",
        nav: false,
        title: "Edit Referral",
        settings: {
            permission: [
                {
                    resource: AdministrativePermissionEnum.Referrals,
                    action: PermissionActionEnum.Edit
                }
            ]
        }
    },
    {
        name: "consents",
        route: "consents/:id?",
        moduleId: PLATFORM.moduleName("./consents/consents", "patient-consents"),
        href: "patients/consents/load",
        title: "Consents",
        nav: true,
        settings: {
            menuName: "Consents",
            permission: [
                {
                    resource: AdministrativePermissionEnum.Consents,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "benefit-period-manager",
        route: "benefit-period-manager/:id?",
        moduleId: PLATFORM.moduleName("./benefit-period-profile/benefit-period-profile", "benefit-period-profile"),
        href: "patients/benefit-period-manager/load",
        title: "Benefit Period Manager",
        nav: true,
        settings: {
            menuName: "Benefit Period Manager",
            isPalliativeCare: false,
            permission: [
                {
                    resource: ClinicalPermissionEnum.ManageBenefitPeriod,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "admission-period-manager",
        route: "admission-period-manager/:id?",
        moduleId: PLATFORM.moduleName(
            "./admission-period-manager/admission-period-manager",
            "admission-period-manager"
        ),
        href: "patients/admission-period-manager/load",
        title: "Admission Period Manager",
        nav: true,
        settings: {
            menuName: "Admission Period Manager",
            isPalliativeCare: true,
            permission: [
                {
                    resource: ClinicalPermissionEnum.ManageBenefitPeriod,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "authorizations",
        route: "authorizations/:id?",
        moduleId: PLATFORM.moduleName("./authorizations/authorizations", "authorizations"),
        href: "patients/authorizations/load",
        title: "Authorizations",
        nav: true,
        settings: {
            menuName: "Authorizations",
            permission: viewInsurancePayorsPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "non-covered-items",
        route: "non-covered-items/:id?",
        moduleId: PLATFORM.moduleName("./non-covered-items/non-covered-items", "non-covered-items"),
        href: "patients/non-covered-items/load",
        title: "Non-Covered-Items",
        nav: true,
        settings: {
            menuName: "Non-Covered Items",
            isPalliativeCare: false,
            permission: [
                {
                    resource: ClinicalPermissionEnum.PatientChart,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "export-patient-chart",
        route: "export-patient-chart",
        moduleId: PLATFORM.moduleName("./download-patient-chart/download-patient-chart", "download-patient-chart"),
        title: "Download Patient Chart",
        nav: true,
        settings: {
            menuName: "Download Patient Chart",
            permission: [
                {
                    resource: ClinicalPermissionEnum.DownloadPatientChart,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "vital-signs",
        route: "vital-signs/:id?",
        moduleId: PLATFORM.moduleName("./vital-signs/vital-signs", "vital-signs"),
        href: "patients/vital-signs/load",
        title: "Vital Signs",
        nav: true,
        settings: {
            menuName: "Vital Signs"
        }
    },
    {
        name: "symptom-ratings",
        route: "symptom-ratings/:id?",
        moduleId: PLATFORM.moduleName("./symptom-ratings/symptom-ratings", "symptom-ratings"),
        href: "patients/symptom-ratings/load",
        title: "Symptom Ratings",
        nav: true,
        settings: {
            menuName: "Symptom Ratings"
            // permission: [{
            //     resource: ClinicalPermissionEnum,
            //     action: PermissionActionEnum.View
            // }]
        }
    },
    {
        name: "deleted-tasks",
        route: "deleted-tasks/:id?",
        moduleId: PLATFORM.moduleName("./deleted-tasks/deleted-tasks", "deleted-tasks"),
        href: "patients/deleted-tasks/load",
        title: "Deleted Tasks",
        nav: true,
        settings: {
            menuName: "Deleted Tasks",
            permission: [
                {
                    resource: ClinicalPermissionEnum.Tasks,
                    action: PermissionActionEnum.Delete
                }
            ]
        }
    },
    {
        name: "wounds",
        route: "wounds/:id?",
        moduleId: PLATFORM.moduleName("./wounds/wounds", "wounds"),
        href: "patients/wounds/load",
        title: "Wounds",
        nav: true,
        settings: {
            menuName: "Wounds"
        }
    }
];
