import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const censusByStatusTooltip: IReportTooltip = {
    description:
        "Displays a list of patients by status with demographic information. This report is critical for internal administrative purposes and is used during state and regulatory surveys.",
    parameters: "Branch, Team, Patient Status, Unduplicated, Tags",
    reportIncludes:
        "Branch, Team, Patient Name, MRN, Date of Birth, Race/Ethnicity, Patient Status, Admission Date, Discharge Date, Days on Service, Primary Payer, Gender, Primary Phone, Address Line 1, Address Line 2, City, State, Zip, Tags"
};

export const censusByLocTooltip: IReportTooltip = {
    description:
        "Displays a list of patients by level of care with demographic information. This report is critical for internal administrative purposes and is used during state and regulatory surveys.",
    parameters:
        "Branch, Level of Care. Selecting different parameters will affect the results displayed in the report.",
    reportIncludes:
        "Name, Branch, Admission Date, Level of Care, Current Benefit Period, Days on Service, Primary Payer, Team, Gender, Phone, Address"
};

export const patientByPhysicianListing: IReportTooltip = {
    description: "Displays a list of patients assigned to each physician.",
    parameters: "Physician, Patient Status, Branch, Tags",
    reportIncludes:
        "Branch, Attending Physician, Attending Physician Phone Number, Hospice Physician, Hospice Physician Phone Number, Patient, Address, Primary Phone Number, Gender, Patient Status, Tags"
};

export const patientEmergencyPreparedness: IReportTooltip = {
    description:
        "Displays a list of all patients' emergency preparedness planning information and triage levels to allow quick identification of patients requiring assistance during an emergency or disaster. Evacuation zone information entered in patients’ charts also appears in this report.",
    parameters: "Branch, Team, Patient Status, Emergency Triage Level, Tags",
    reportIncludes:
        "Branch, Team, Patient Name, MRN, Date of Birth, Patient Mobile Phone, Terminal Diagnosis, Hospice Physician, Attending Physician, Patient Status, Triage Level, Primary Emergency Contact, Relationship, Contact Mobile Phone, Contact Alternate Phone, Contact Email, Evacuation Address, Evacuation Zone, Evacuation Mobile Phone, Evacuation Alternate Phone, Additional Emergency Preparedness Information, Tags."
};

export const advanceDirectivesTooltip: IReportTooltip = {
    description:
        "Displays a list of patients sorted by code status. Based on their documented advance directives, patients are identified as full code, DNR, or no advance directives. This report gives clinicians an at-a-glance view of code statuses for all patients to enable timely and appropriate preparations for emergent care.",
    parameters: "Branch, Advance Directive Status, Team",
    reportIncludes: "Patient Name, MRN, Branch, Team, Advance Directive"
};

export const nonAdmitReasonTooltip: IReportTooltip = {
    description:
        "Displays a list of patients not admitted to the agency and the reason they did not admit. This report is critical for internal operational purposes and is used during state and regulatory surveys.",
    parameters: "Date Range, Branch, Non-Admit Reason",
    reportIncludes: "Patient Name, Branch, Referral Date, Referral Source, Non-Admit Date, Non-Admit Reason"
};

export const cahpsReportingTooltip: IReportTooltip = {
    description:
        "Displays a list of deceased patients and their authorized contacts along with associated data based on CAHPS reporting and survey guidance based on the year and month selected. This report is critical for internal operational purposes and compliance with HQRP reporting requirements.",
    parameters: "Branch, Sample Year, Sample Month, Primary Payer, Column Details",
    reportIncludes:
        "Provider Name, Provider ID, NPI, Hospice Offices, Branch, Live Discharges, Total Patients, No Publicity Caregivers, Caregiver First Name, Caregiver MI, Caregiver Last Name, Caregiver Prefix, Caregiver Suffix, Patient First Name, Patient MI, Patient Last Name, Patient Prefix, Patient Suffix, Patient Date of Birth, Patient Date of Death, Patient Admission Date, Patient Gender, Patient Veteran, Patient Hispanic, Patient Race, Patient Last Location, Facility Name, Primary Payer, Secondary Payer, Other Payer, Primary Diagnosis, Primary Diagnosis Code, Caregiver Address Line 1, Caregiver Address Line 2, Caregiver City, Caregiver State, Caregiver ZIP, Caregiver Primary Phone, Caregiver Alternate Phone, Caregiver Email, Relationship to Patient,  Language, No Publicity"
};

export const unVerifiedPayersTooltip: IReportTooltip = {
    description:
        "Displays a list of active and pending patients that do not have verified payers in their patient charts.",
    parameters: "Branch, Patient, Patient Status, Payer, Payer Type",
    reportIncludes:
        "Branch, Patient, Patient Status, Date, Payer, Payer Type, Subscriber ID, Authorization Required"
};

export const pendingPatientTooltip: IReportTooltip = {
    description:
        "Displays a list of pending patients by date range. This report is a good reference for measuring the success and efficiency of the intake team.",
    parameters: "Line of Service, Provider/Branch, Date Range",
    reportIncludes:
        "Provider/Branch, Line of Service, Patient Name, MRN, Pending Patient Reason, Pending Patient Comments, Primary Payer, Referral Date, Referral Source, Referral Source Name, Community Liaison"
};

export const admissionReportTooltip: IReportTooltip = {
    description:
        "Displays a list of admissions by date range. This report is useful for assessing referral success, productivity and the success of marketing efforts.",
    parameters: "Branch, Patient Status, Date Range, Unduplicated",
    reportIncludes:
        "Branch, Team, Patient Last Name, Patient First Name, MRN, Gender, Race/Ethnicity, Patient Status, Admission Benefit Period, Primary Payer, Referral Date, Admission Date, Days to Admission, Referral Source, Referral Source Name, Community Liaison, Tags "
};

export const referralReportTooltip: IReportTooltip = {
    description:
        "Generates a list of all patients/referrals entered into the system. A management report to track patient conversion rates, when requested in surveys as well as a critical report for assessing marketing effectiveness.",
    parameters: "Date Range, Provider/Branch",
    reportIncludes:
        "Provider/Branch, Patient Name, Primary Payer, Patient Date of Birth, Age, Gender, Referral Date, Status, Admission Date, Days to Admission, Referral Source, Referral Source Name, Patient Address, Community Liaison"
};

export const patientsByCareTeamMemberTooltip: IReportTooltip = {
    description: "Displays a list of patients and their assigned care team members.",
    parameters: "Provider/Branch, Team, Status",
    reportIncludes: "Patient Name, MRN, Care Team, Assigned Care Team Members, Tags"
};

export const authorizationReportTooltip: IReportTooltip = {
    description:
        "Generates a list of patients with insurance/payer authorizations. A useful tool to track insurance/payer authorizations.",
    parameters: "Provider/Branch, Authorization Status, Authorization Type, Date Range",
    reportIncludes:
        "Provider/Branch, Patient Name, MRN, Patient Status, Payer, Authorization Status, Authorization Type, Services Requested, Procedure Code, Authorized Units, Unit Type, Start Date, End Date, Authorization Number"
};

export const bereavementCensusReportTooltip: IReportTooltip = {
    description: "Displays a list of all bereaved contacts on the census for a given date range.",
    parameters: "Branch, Risk Level, Status, Date Range",
    reportIncludes:
        " Branch, Bereaved Contact, Risk Level, Status, Address Line 1, Address Line 2, City, State, ZIP Code, County, Primary Phone, Relationship, Patient Last Name, Patient First Name, MRN, Date of Death"
};

export const daysOnServiceReportTooltip: IReportTooltip = {
    description: "Displays a list of patients with their current days on service and lengths of election.",
    parameters: "Branch, Team, Patient Status, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Payer, Patient Status, Admission Date, Discharge Date, Days on Service, Length of Election, ICD-10 Code, Terminal Diagnosis, Tags"
};

export const funeralHomeReportTooltip: IReportTooltip = {
    description: "Displays a list of patients and their funeral homes.",
    parameters: "Branch, Team, Patient Status, Date Range",
    reportIncludes:
        "Branch, Team, Last Name, First Name, MRN, Patient Status, Funeral Home, Funeral Home Phone Number, After-Hours Phone Number, Address, City, State, ZIP Code, Tags"
};

export const qualisPatientImportReportTooltip: IReportTooltip = {
    description: "Provides a list of active patients in the specified format required by Qualis for import.",
    parameters: "Branch, Team, Patient Status, Tags",
    reportIncludes:
        "MRN, Patient Name, Patient Date of Birth, Patient Address, Primary Phone, Patient Status, Admission Date, Branch"
};
