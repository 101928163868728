import { customElement, inlineView } from "aurelia-framework";

@customElement("feet-svg")
@inlineView(`<template>
    <svg width="320px" height="460px" viewBox="0 0 320 460" xmlns="http://www.w3.org/2000/svg">
        <style type="text/css">
            .st0 {
                fill: #FFFDFD;
            }

            .st1 {
                fill: none;
                stroke: #000000;
            }

            .st2 {
                fill: none;
                stroke: #000000;
                stroke-width: 0.5;
            }

            .st3 {
                fill: none;
                stroke: #000000;
                stroke-width: 0.25;
            }

            .st4 {
                fill: transparent;
                fill-opacity: 0.2721;
            }

            .st5 {
                fill: transparent;
                fill-opacity: 0.27;
            }

            .st6 {
                fill: #231F20;
            }

            .st7 {
                font-family: 'HelveticaNeue-Medium';
            }

            .st8 {
                font-size: 12.829px;
            }
        </style>
        <g id="Page-1">
            <g id="feetView">
                <rect id="boundingBox_320x460" class="st0" width="320" height="460"/>
                <g id="feet_Left" transform="translate(177.000000, 75.000000)">
                    <path id="Stroke-11" class="st1" d="M13.9,21.9c1-1.4,1.7-3.1,2.1-4.9c0.9-3.9,0.4-8.2-1.5-11.1c-1.4-2-3.5-3.3-6.4-3.1
\t\t\t\tC-4.6,3.7,1.2,21.2,2.8,23.6c1.6,2.5,2,5.1,1.6,14.6C3.9,47.8,11.1,59.7,14.6,67c3.5,7.4,2,29.2,2,35.9s-5.5,20.7,18.2,25.5
\t\t\t\tc23.8,4.8,20.5-38.2,21.9-48.8c1.4-10.6-3.2-50.1-3.2-50.1c-0.3-2-0.3-4.1,0-6.1c0.3-2.9-0.7-5.9-2.7-8.1c0,0-5.6-3.5-6.3,0.6
\t\t\t\tc-0.4,2.5-0.4,5.1,0,7.5"/>
                    <path id="Stroke-13" class="st1" d="M35,20.6l0.3-1.3c0.1-0.5-0.4-2-0.9-3.8c0.1-3.8,0.6-5.6,0-7.6c2.7-4.2,6.8-1,8.7,2.5
\t\t\t\tc1.9,3.5-0.6,13.3-0.6,13.3"/>
                    <path id="Stroke-15" class="st2" d="M27.4,37.4c0.4,4.3,0,8.8-1.4,13c-2.3,5.8-3.9,7.4-3.9,7.4"/>
                    <path id="Stroke-17" class="st1" d="M34.6,20.3c-0.3-3.2-0.3-6.5,0-9.7c0.6-4.1-2.5-8.8-4.8-8.8c-3.3,0-5.8,0.9-4.5,8.2
\t\t\t\tc1.3,7.4,0.9,10.3,0.9,10.3"/>
                    <path id="Stroke-19" class="st1"
                          d="M24.5,19.1c0,0,0-14.5-1-16.8c-1-2.3-9.2-2.7-9.1,2.6c2,4.6,2.3,6.8,1.7,12.2l0.3,5.1"/>
                    <path id="Stroke-21" class="st1" d="M49.9,26.7c0,0-15.3-7.8-22.9-6.5S7,28.3,7,28.3"/>
                    <path id="Stroke-1" class="st1" d="M116.1,11.6c0,0-4.3,15.3-4.6,21.7c-0.1,9.3,1.3,18.4,4.2,27.1c3.2,8.7,3.6,22.7,3.9,26.6
\t\t\t\tc0.3,3.9,1.9,10.3,1.3,12.4c-0.6,2.2,0.6,2.6-1.6,3s-2.9-0.1-2.9-0.1s1,6.1,0.3,7.5s-4.6,1-4.6,1s0.9,4.8-0.6,5.9
\t\t\t\tc-1.4,0.9-3,1.3-4.6,1.3c0,0-0.4,4.6-1.7,5.5c-3.2,2-7.4-0.9-7.4-0.9s0.4,4.2-2.5,4.8c-2.9,0.6-9.7,0.9-13-4.6
\t\t\t\ts-0.6-10.1-3.3-14.8s-5-12.3-3.3-19.4c1.7-7.1,5.2-9.7,3.9-21.3c-1.3-11.6-7.2-35.7-4.3-42.3c2.9-6.5,5.5-9.3,6.9-12.6
\t\t\t\tc1.4-3.3,0.7-6.4,0-10.9"/>
                    <path id="Stroke-3" class="st1" d="M113.5,91.4c0,0.4,2.2,3.8,2.3,6.1c0.1,1.9,0.6,3.6,1.2,5.2"/>
                    <path id="Stroke-5" class="st1" d="M105.6,95.5c0.3,0.6,3.9,5.5,4.5,9.3c0.3,2,0.9,4.1,1.7,5.9"/>
                    <path id="Stroke-7" class="st1" d="M91.9,100.6c0,0,1.2,10.4,2.9,14.2c1.7,3.8,2.9,7.8,2.9,7.8"/>
                    <path id="Stroke-9" class="st1" d="M102.1,99.5c0,0,5,15.9,4.6,18.5"/>
                    <path id="Stroke-23" class="st3" d="M92.4,114.9c-0.4,0-5.9-0.4-5.6,1.7c0.1,2.2,0.6,4.2,1.3,6.2c0.4,0.4,7.5-1.2,7.5-1.2
\t\t\t\tL92.4,114.9z"/>
                    <path id="Stroke-25" class="st3"
                          d="M104.6,116.9c-0.3,0-4-0.1-3.9,0.9c0.1,1,0.4,2,0.9,2.9c1.7,0,3.5-0.3,5.2-0.6L104.6,116.9z"
                    />
                    <path id="Stroke-27" class="st3"
                          d="M110.5,112.8c-0.1,0-2.3-0.1-2.3,0.6c0.1,0.7,0.3,1.3,0.6,1.9c1,0,2-0.1,3-0.3L110.5,112.8z"
                    />
                    <path id="Stroke-29" class="st3"
                          d="M115.9,107.7c-0.1,0-2-0.1-1.9,0.4c0,0.6,0.1,1,0.4,1.6c0.9,0,1.7-0.1,2.5-0.3L115.9,107.7z"
                    />
                    <path id="Stroke-31" class="st3"
                          d="M120,99.6c-0.1,0-2-0.1-1.9,0.4c0,0.6,0.1,1,0.4,1.6c0.9,0,1.7-0.1,2.5-0.3L120,99.6z"/>
                    <path id="Stroke-33" class="st2" d="M84.6,35.2c0,0-4.2-4.5-3.7-8.8S85,17.6,85.4,15"/>
                    <path id="Stroke-35" class="st1" d="M114,154.9c0.1,1-6.2,27.5-1.4,34.7c4.8,7.2,11.1,16.2,7.8,24.7s-4.9,11.6-20.8,11.6
\t\t\t\ts-27.4,0.3-35.9,4.3c-7.2,3-15.3,4.2-23.1,3.3c-3.9-0.7-10.7,1.4-12.8,1.3c-5.2-0.4-5.6-8.4,1.6-9.1c7.2-0.7,8.9-3.3,8.9-3.3"/>
                    <path id="Stroke-37" class="st1" d="M71.9,154c0,0.6-0.1,23.9,0,28.1s-23.6,11.3-29,15.2s-14.9,9.4-19.8,9.6s-16.3,1-18.6,1.4
\t\t\t\tC1,209-3.1,219.6,5.3,219.6"/>
                    <path id="Stroke-39" class="st1" d="M35.1,218.1c-2.2,1.6-4.5,2.7-7.1,3.5c-4.2,1.3-9.4,1-11,3.9c-1.6,2.9-2,4.3,0,6.2
\t\t\t\tc2,1.9,7.2,0.7,7.2,0.7"/>
                    <path id="Stroke-41" class="st1" d="M29.5,214.2c-2.7,1.9-5.9,3.2-9.2,3.8c-3.5,0.7-6.9,2.2-9.9,4.1c-1.7,0.9-2,5.5,1,6.9
\t\t\t\ts4.8,0.7,4.8,0.7"/>
                    <path id="Stroke-43" class="st1" d="M24,210.3c-1.9,1.7-4.3,2.9-6.9,3.2c-3.5,0-6.9,1.3-9.7,3.6c-2.3,2.3-4.9,2.9-4.3,4.9
\t\t\t\tc0.6,2.7,2.9,4.8,5.8,4.9"/>
                    <path id="Stroke-45" class="st2" d="M103.9,200c-0.6,0.1-4.3,5.5-9.9,3.3c-3.9-1.6-7.4-3.9-10.4-6.8"/>
                    <path id="Stroke-47" class="st3"
                          d="M12.6,207.4c0.4,0.9,0.7,1.9,1,2.9c0,0.6-5,1.7-7.1,1.6c-2-0.1-3.5-3.8-3.5-3.8L12.6,207.4z"
                    />
                    <polygon id="Stroke-49" class="st3"
                             points="27.2,227.1 28.2,228.4 26.3,230 24.6,228.6 \t\t\t"/>
                    <polygon id="Stroke-51" class="st3"
                             points="19.9,223.3 21.6,225.4 19.4,227.1 17.3,225.5 \t\t\t"/>
                    <polygon id="Stroke-53" class="st3"
                             points="13.7,220.3 15.1,222.2 12.4,224.4 10.2,222.2 \t\t\t"/>
                    <path id="Stroke-55" class="st3"
                          d="M7.6,217.3c0,0,3.3,1,2.6,1.4c-1,0.9-1.9,1.7-2.6,2.7l-2.3-1.9L7.6,217.3z"/>
                    <path id="Stroke-57" class="st1" d="M48.4,263.5c2.5,0.7-6.8,25.8,3,32.4s12.3,14,21.9,16.5c9.7,2.5,16.9,12.9,23.2,14
\t\t\t\ts19.6-1.9,21.6,2.3c2,4.1,3.6,8.4-2.9,11.1s-16,2.3-21.6,2.6c-8.4,0.4-19.2-0.6-24.1-2.9c-4.9-2.3-19.8-6.7-31.7-3
\t\t\t\ts-27,2.5-32-1.9c-7.8-6.7-5.6-15.2-1.4-27.1c5.6-15.9,9.9-30.8,6.1-42.7"/>
                    <path id="Stroke-59" class="st2" d="M37.7,296.1c0,0-2.3,6.9-8.7,7.4c-3.9,0.3-7.8-0.6-11.4-2.3"/>
                    <path id="Stroke-61" class="st2" d="M67.1,329c0,0-8.7-5.9-16.9-3.9c-8.2,2-10.7,2.7-10.7,2.7"/>
                    <path id="Stroke-63" class="st3"
                          d="M112.6,326.7c0,0,3,3.2,2.2,3.2s-4.9,1.7-7.4,0c-1.3-0.9-2.3-1.9-3.2-3.2H112.6z"/>
                    <path id="Stroke-65" class="st1" d="M109.8,326.7c0,0-6.2-1.9-6.9-2c-2-0.1-4-0.3-5.9-0.1c-4.3,0-5.5-0.9-5.5-0.9l7.8,0.6
\t\t\t\tc0,0-4.8-2.6-5.6-2.3c-0.9,0.3-7.1-1.3-7.1-1.3"/>
                </g>
                <g id="Feet_right" transform="translate(26.000000, 74.000000)">
                    <path id="Stroke-1_1_" class="st1" d="M5.9,11.6c0,0,4.3,15.3,4.6,21.7c0.1,9.3-1.3,18.4-4.2,27.1C3.2,69,2.7,83.1,2.4,87
\t\t\t\tc-0.3,3.9-1.9,10.3-1.3,12.4s-0.6,2.6,1.6,3s2.9-0.1,2.9-0.1s-1,6.1-0.3,7.5s4.6,1,4.6,1s-0.9,4.8,0.6,5.9c1.4,0.9,3,1.3,4.6,1.3
\t\t\t\tc0,0,0.4,4.6,1.7,5.5c3.2,2,7.4-0.9,7.4-0.9s-0.4,4.2,2.5,4.8s9.7,0.9,13-4.6c3.3-5.5,0.6-10.1,3.3-14.8c2.7-4.6,5-12.3,3.3-19.4
\t\t\t\tc-1.7-7.1-5.2-9.7-3.9-21.3s7.2-35.7,4.3-42.3c-2.9-6.5-5.5-9.3-6.9-12.6s-0.7-6.4,0-10.9"/>
                    <path id="Stroke-3_1_" class="st1" d="M8.5,91.4c0,0.4-2.2,3.8-2.3,6.1c-0.1,1.9-0.6,3.6-1.2,5.2"/>
                    <path id="Stroke-5_1_" class="st1" d="M16.4,95.5c-0.3,0.6-3.9,5.5-4.5,9.3c-0.3,2-0.9,4.1-1.7,5.9"/>
                    <path id="Stroke-7_1_" class="st1" d="M30.1,100.6c0,0-1.2,10.4-2.9,14.2c-1.7,3.8-2.9,7.8-2.9,7.8"/>
                    <path id="Stroke-9_1_" class="st1" d="M19.9,99.5c0,0-5,15.9-4.6,18.5"/>
                    <path id="Stroke-11_1_" class="st1" d="M108.1,21.9c-1-1.4-1.7-3.1-2.1-4.9c-0.9-3.9-0.4-8.2,1.5-11.1c1.4-2,3.5-3.3,6.4-3.1
\t\t\t\tc12.7,0.9,6.9,18.4,5.3,20.8s-2,5.1-1.6,14.6s-6.8,21.4-10.2,28.8s-2,29.2-2,35.9s5.5,20.7-18.2,25.5
\t\t\t\tc-23.8,4.8-20.5-38.2-21.9-48.8s3.2-50.1,3.2-50.1c0.3-2,0.3-4.1,0-6.1c-0.3-2.9,0.7-5.9,2.7-8.1c0,0,5.6-3.5,6.3,0.6
\t\t\t\tc0.4,2.5,0.4,5.1,0,7.5"/>
                    <path id="Stroke-13_1_" class="st1" d="M87,20.6l-0.3-1.3c-0.1-0.5,0.4-2,0.9-3.8c-0.1-3.8-0.6-5.6,0-7.6c-2.7-4.2-6.8-1-8.7,2.5
\t\t\t\ts0.6,13.3,0.6,13.3"/>
                    <path id="Stroke-15_1_" class="st2" d="M94.6,37.4c-0.4,4.3,0,8.8,1.4,13c2.3,5.8,3.9,7.4,3.9,7.4"/>
                    <path id="Stroke-17_1_" class="st1" d="M87.4,20.3c0.3-3.2,0.3-6.5,0-9.7c-0.6-4.1,2.5-8.8,4.8-8.8c3.3,0,5.8,0.9,4.5,8.2
\t\t\t\tc-1.3,7.4-0.9,10.3-0.9,10.3"/>
                    <path id="Stroke-19_1_" class="st1"
                          d="M97.5,19.1c0,0,0-14.5,1-16.8c1-2.3,9.2-2.7,9.1,2.6c-2,4.6-2.3,6.8-1.7,12.2l-0.3,5.1"/>
                    <path id="Stroke-21_1_" class="st1" d="M72.1,26.7c0,0,15.3-7.8,22.9-6.5s19.9,8.1,19.9,8.1"/>
                    <path id="Stroke-23_1_" class="st3" d="M29.6,114.9c0.4,0,5.9-0.4,5.6,1.7c-0.1,2.2-0.6,4.2-1.3,6.2c-0.4,0.4-7.5-1.2-7.5-1.2
\t\t\t\tL29.6,114.9z"/>
                    <path id="Stroke-25_1_" class="st3" d="M17.4,116.9c0.3,0,4-0.1,3.9,0.9c-0.1,1-0.4,2-0.9,2.9c-1.7,0-3.5-0.3-5.2-0.6L17.4,116.9
\t\t\t\tz"/>
                    <path id="Stroke-27_1_" class="st3" d="M11.5,112.8c0.1,0,2.3-0.1,2.3,0.6c-0.1,0.7-0.3,1.3-0.6,1.9c-1,0-2-0.1-3-0.3L11.5,112.8
\t\t\t\tz"/>
                    <path id="Stroke-29_1_" class="st3"
                          d="M6.1,107.7c0.1,0,2-0.1,1.9,0.4c0,0.6-0.1,1-0.4,1.6c-0.9,0-1.7-0.1-2.5-0.3L6.1,107.7z"
                    />
                    <path id="Stroke-31_1_" class="st3"
                          d="M2,99.6c0.1,0,2-0.1,1.9,0.4c0,0.6-0.1,1-0.4,1.6c-0.9,0-1.7-0.1-2.5-0.3L2,99.6z"/>
                    <path id="Stroke-33_1_" class="st2" d="M37.4,35.2c0,0,4.2-4.5,3.7-8.8c-0.4-4.3-4.2-8.8-4.6-11.4"/>
                    <path id="Stroke-35_1_" class="st1" d="M8,154.9c-0.1,1,6.2,27.5,1.4,34.7s-11.1,16.2-7.8,24.7s4.9,11.6,20.8,11.6
\t\t\t\ts27.4,0.3,35.9,4.3c7.2,3,15.3,4.2,23.1,3.3c3.9-0.7,10.7,1.4,12.8,1.3c5.2-0.4,5.6-8.4-1.6-9.1c-7.2-0.7-8.9-3.3-8.9-3.3"/>
                    <path id="Stroke-37_1_" class="st1" d="M50.1,154c0,0.6,0.1,23.9,0,28.1c-0.1,4.2,23.6,11.3,29,15.2s14.9,9.4,19.8,9.6
\t\t\t\ts16.3,1,18.6,1.4c3.6,0.7,7.6,11.3-0.7,11.3"/>
                    <path id="Stroke-39_1_" class="st1" d="M86.9,218.1c2.2,1.6,4.5,2.7,7.1,3.5c4.2,1.3,9.4,1,11,3.9c1.6,2.9,2,4.3,0,6.2
\t\t\t\tc-2,1.9-7.2,0.7-7.2,0.7"/>
                    <path id="Stroke-41_1_" class="st1" d="M92.5,214.2c2.7,1.9,5.9,3.2,9.2,3.8c3.5,0.7,6.9,2.2,9.9,4.1c1.7,0.9,2,5.5-1,6.9
\t\t\t\ts-4.8,0.7-4.8,0.7"/>
                    <path id="Stroke-43_1_" class="st1" d="M98,210.3c1.9,1.7,4.3,2.9,6.9,3.2c3.5,0,6.9,1.3,9.7,3.6c2.3,2.3,4.9,2.9,4.3,4.9
\t\t\t\tc-0.6,2.7-2.9,4.8-5.8,4.9"/>
                    <path id="Stroke-45_1_" class="st2" d="M18.1,200c0.6,0.1,4.3,5.5,9.9,3.3c3.9-1.6,7.4-3.9,10.4-6.8"/>
                    <path id="Stroke-47_1_" class="st3" d="M109.4,207.4c-0.4,0.9-0.7,1.9-1,2.9c0,0.6,5,1.7,7.1,1.6c2-0.1,3.5-3.8,3.5-3.8
\t\t\t\tL109.4,207.4z"/>
                    <polygon id="Stroke-49_1_" class="st3"
                             points="94.8,227.1 93.8,228.4 95.7,230 97.4,228.6 \t\t\t"/>
                    <polygon id="Stroke-51_1_" class="st3"
                             points="102.1,223.3 100.4,225.4 102.6,227.1 104.7,225.5 \t\t\t"/>
                    <polygon id="Stroke-53_1_" class="st3"
                             points="108.3,220.3 106.9,222.2 109.6,224.4 111.8,222.2 \t\t\t"/>
                    <path id="Stroke-55_1_" class="st3"
                          d="M114.4,217.3c0,0-3.3,1-2.6,1.4c1,0.9,1.9,1.7,2.6,2.7l2.3-1.9L114.4,217.3z"/>
                    <path id="Stroke-57_1_" class="st1" d="M73.6,263.5c-2.5,0.7,6.8,25.8-3,32.4s-12.3,14-21.9,16.5c-9.7,2.5-16.9,12.9-23.2,14
\t\t\t\ts-19.6-1.9-21.6,2.3c-2,4.1-3.6,8.4,2.9,11.1s16,2.3,21.6,2.6c8.4,0.4,19.2-0.6,24.1-2.9c4.9-2.3,19.8-6.7,31.7-3s27,2.5,32-1.9
\t\t\t\tc7.8-6.7,5.6-15.2,1.4-27.1c-5.6-15.9-9.9-30.8-6.1-42.7"/>
                    <path id="Stroke-59_1_" class="st2" d="M84.3,296.1c0,0,2.3,6.9,8.7,7.4c3.9,0.3,7.8-0.6,11.4-2.3"/>
                    <path id="Stroke-61_1_" class="st2" d="M54.9,329c0,0,8.7-5.9,16.9-3.9c8.2,2,10.7,2.7,10.7,2.7"/>
                    <path id="Stroke-63_1_" class="st3"
                          d="M9.4,326.7c0,0-3,3.2-2.2,3.2s4.9,1.7,7.4,0c1.3-0.9,2.3-1.9,3.2-3.2H9.4z"/>
                    <path id="Stroke-65_1_" class="st1" d="M12.2,326.7c0,0,6.2-1.9,6.9-2c2-0.1,4-0.3,5.9-0.1c4.3,0,5.5-0.9,5.5-0.9l-7.8,0.6
\t\t\t\tc0,0,4.8-2.6,5.6-2.3c0.9,0.3,7.1-1.3,7.1-1.3"/>
                </g>

                <g><title>Dorsal Right Foot</title>
                    <path class="st4" d="M72.1,163c-2.7-8.5-4-10.7-4.3-22c0-0.4,0.1-0.9,0.2-1.4c0.1-0.1,0.3-0.2,0.4-0.2c0.2-1.4,0.4-3,0.7-4.7
\t\t\tc0,0,0-0.1-0.1-0.1c2.1-12.4,5.5-29.3,3.2-34.5c-2.9-6.5-5.5-9.3-6.9-12.6c-0.5-1.1-0.7-2.2-0.8-3.4C57,84.5,48.8,87,41,91.5
\t\t\tc-2.5,1.4-4.8,3-7,4.7c1,4.3,2,9,2.1,11.9c0.1,3.9-0.2,7.7-0.6,11.5c0,0,0.1,0,0.1-0.1c-0.7,5-1.8,9.9-3.4,14.6
\t\t\tc-0.9,2.4-1.6,5.4-2.1,8.6c-0.1,0-0.3,0-0.4,0c-1.4,8.2-1.7,16.8-1.7,16.5c0.2,0,0.3,0,0.5,0c0,0.4,0,0.7-0.1,1
\t\t\tc-0.3,3.9-1.9,10.3-1.3,12.4c0.6,2.2-0.6,2.6,1.6,3c2.2,0.4,2.9-0.1,2.9-0.1s-1,6.1-0.3,7.5s4.6,1,4.6,1s-0.9,4.8,0.6,5.9
\t\t\tc1.4,0.9,3,1.3,4.6,1.3c0,0,0.4,4.6,1.7,5.5c3.2,2,7.4-0.9,7.4-0.9s-0.4,4.2,2.5,4.8s9.7,0.9,13-4.6c3.3-5.5,0.6-10.1,3.3-14.8
\t\t\tc0.4-0.7,0.8-1.4,1.1-2.1c-0.1-0.1-0.1-0.1-0.2-0.2C71,177.2,74.5,170.5,72.1,163z"/>
                </g>
                <g><title>Plantar Right Foot</title>
                    <path class="st4" d="M139.9,76.8c-2.9-0.2-5.1,1-6.4,3.1c-1.9,2.9-2.4,3.8-0.4-0.8c0.1-5.3-7.9-4.3-9.2-2.6
\t\t\tc-0.5,0.6-0.9,1.9-1.2,3c-0.3-0.7-2.1-3.7-5.1-3.7c-1.8,0-3.9,2.7-4.6,5.8c-2.8-3.9-6.7-0.8-8.6,2.6c-1.8,3.2-0.7,8.2-1.2,5.7
\t\t\tc-0.7-4-6.4-0.6-6.4-0.6c-2,2.2-3.1,5.2-2.8,8.1c0.2,1.6,0.3,3.2,0.1,4.8c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0
\t\t\tc0.1,0.4,0.1,0.9,0.2,1.3c0.5,4-2.5,14.8-2.6,19.5c0,0.9,0.1,1.9,0.2,2.9c-0.1,4.1-1.1,10.9-1.2,18c0,4.9,0.3,9.4,1,15.7
\t\t\tc0,0,0.1,0,0.1,0c0.4,19.6,1.6,47.2,22,43.1c23.9-4.8,18.3-19,18.3-25.7c0-3-0.3-9.2-0.3-15.7c-0.1-0.2-0.2-0.4-0.3-0.6
\t\t\tc0.4-9,0.9-14.5,1.4-16.2c1.4-4.6,4.9-11.3,7.7-17.7c-0.1,0-0.1-0.1-0.2-0.1c1.2-3.1,2.2-6.2,2.8-8.9c1-4.7-0.3-10.3,0.2-14.5
\t\t\tc0,0,0,0,0,0c0.2-2.5,0.7-4.3,1.5-5.6C146.9,95.1,152.7,77.6,139.9,76.8z"/>
                </g>
                <g><title>Dorsal Left Foot</title>
                    <path class="st4" d="M296.6,161.2c0-0.3,0-0.6-0.1-1c0.2,0,0.3,0,0.5,0c0,0.3-0.3-8.4-1.7-16.5c-0.1,0-0.3,0-0.4,0
\t\t\tc-0.5-3.1-1.2-6.2-2.1-8.6c-1.6-4.8-2.7-9.6-3.4-14.6c0,0,0.1,0,0.1,0.1c-0.5-3.8-0.7-7.6-0.6-11.5c0.1-2.9,1.1-7.6,2.1-11.9
\t\t\tc-2.2-1.7-4.5-3.3-7-4.7c-7.8-4.5-16-7-23.6-7.5c-0.1,1.1-0.3,2.2-0.8,3.4c-1.4,3.3-4,6.1-6.9,12.6c-2.3,5.3,1.1,22.2,3.2,34.5
\t\t\tc0,0,0,0.1-0.1,0.1c0.3,1.7,0.5,3.2,0.7,4.7c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0.5,0.2,0.9,0.2,1.4c-0.2,11.4-1.6,13.5-4.3,22
\t\t\tc-2.4,7.6,1.1,14.2,2.2,16c-0.1,0.1-0.1,0.1-0.2,0.2c0.4,0.8,0.7,1.5,1.1,2.1c2.7,4.6,0,9.3,3.3,14.8c3.3,5.5,10.1,5.2,13,4.6
\t\t\tc2.9-0.6,2.5-4.8,2.5-4.8s4.2,2.9,7.4,0.9c1.3-0.9,1.7-5.5,1.7-5.5c1.6,0,3.2-0.4,4.6-1.3c1.4-1.2,0.6-5.9,0.6-5.9s3.9,0.4,4.6-1
\t\t\tc0.7-1.4-0.3-7.5-0.3-7.5s0.7,0.6,2.9,0.1c2.2-0.4,1-0.9,1.6-3C298.5,171.5,296.9,165.1,296.6,161.2z"/>
                </g>
                <g><title>Plantar Left Foot</title>
                    <path class="st4" d="M234,144.8c0-7.2-1.1-14-1.2-18c0.1-1,0.2-2,0.2-2.9c-0.1-4.7-3.2-15.4-2.6-19.5c0.1-0.5,0.1-0.9,0.2-1.3
\t\t\tc0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c-0.1-1.6-0.1-3.2,0.1-4.8c0.3-2.9-0.7-5.9-2.8-8.1c0,0-5.7-3.5-6.4,0.6
\t\t\tc-0.4,2.5,0.6-2.5-1.2-5.7c-1.9-3.4-5.8-6.5-8.6-2.6c-0.7-3.1-2.9-5.8-4.6-5.8c-3,0-4.7,2.9-5.1,3.7c-0.3-1.1-0.7-2.4-1.2-3
\t\t\tc-1.2-1.7-9.3-2.7-9.2,2.6c2,4.6,1.5,3.7-0.4,0.8c-1.4-2-3.5-3.3-6.4-3.1c-12.8,0.9-7,18.3-5.4,20.8c0.9,1.3,1.3,3.1,1.5,5.6
\t\t\tc0,0,0,0,0,0c0.5,4.2-0.9,9.8,0.2,14.5c0.6,2.7,1.6,5.7,2.8,8.9c-0.1,0-0.1,0.1-0.2,0.1c2.9,6.4,6.3,13.1,7.7,17.7
\t\t\tc0.5,1.8,1,7.2,1.4,16.2c-0.1,0.2-0.2,0.4-0.3,0.6c0,6.5-0.3,12.7-0.3,15.7c0,6.7-5.5,20.9,18.3,25.7c20.3,4.1,21.6-23.4,22-43.1
\t\t\tc0,0,0.1,0,0.1,0C233.7,154.2,234,149.8,234,144.8z"/>
                </g>
                <g><title>Lateral Right Foot</title>
                    <path class="st5" d="M146.3,284.6c-1.1-2.9-4.2-2.7-7.6-2.8c-2.9-0.1-5.9-1.3-9.2-0.8c0.1-0.1,0.1-0.1,0.2-0.2c-0.1,0-2.6,0-2.7,0
\t\t\tc-2.3,0.5-4.8,0-8.7-1.7c-3.8-1.7-7-4-11.9-6.9c-2.1-1.2-5.7-3.3-8.1-4.2c-3.8-1.4-5.8-2.7-10.1-4.4c-4.2-1.7-9-4.6-12.5-7.5
\t\t\tc0.2-1.8,0.3-3.8,0.3-6c0-5.3,2.2-5.8-20.6,0c-18.5,4.7-18.3,1.2-18.3,2.8c-0.1,2.2,0,4.8,0,7.1c-0.3,0.4-0.7,1.1-0.8,1.4
\t\t\tc-2.7,5.3-11,17.4-9.6,23c1.8,7.3,3,18.4,26.2,15.6c9.9-1.2,20.8,1.1,29.7,3.8c6.7,2,13.8,4,17.5,4.4c0.1,0.3,3,0,4.2-0.2
\t\t\tc5.5-1.3,15.1,2.2,17.5,0.7c0.6-0.4,2.4-1.6,2.8-1.7c0.4-0.2,0.6-0.3,1.6-0.2c1.2,0.2,2.8,0.3,3.2,0.2c0.7-0.2,1.8-0.8,2.7-1.7
\t\t\tc0.2-0.2,0.5-1,0.7-1.2c0.3-0.3,1.5,0.2,2.2,0c2.1-0.5,4.6-1.6,4.9-2.7c0.1-0.4,0.4-0.2,0.8-0.3c0.6-0.1,1.4-0.2,1.9-0.5
\t\t\tc1.9-1.2,3.4-2.7,3.2-4.7c-0.1-1.5-1.4-1.4-0.5-2c0.6-0.4,0.8-0.4,1.5-1.2C148.6,290.4,147.7,288.1,146.3,284.6z"/>
                </g>
                <g><title>Lateral Left Foot</title>
                    <path class="st5" d="M288.8,262.5c-0.1-0.3-0.5-0.9-0.8-1.4c0-2.4,0.1-4.9,0-7.1c-0.1-1.6,0.2,1.9-18.3-2.8
\t\t\tc-22.8-5.8-20.6-5.3-20.6,0c0,2.2,0.1,4.2,0.3,6c-3.4,2.9-8.2,5.8-12.5,7.5c-4.4,1.8-6.3,3.1-10.1,4.4c-2.4,0.9-6,3-8.1,4.2
\t\t\tc-4.9,2.8-8.1,5.1-11.9,6.9c-3.9,1.7-6.3,2.3-8.7,1.7c-0.2,0-2.6,0-2.7,0c0.1,0.1,0.1,0.1,0.2,0.2c-3.3-0.5-6.3,0.7-9.2,0.8
\t\t\tc-3.4,0.1-6.5-0.1-7.6,2.8c-1.4,3.5-2.3,5.7-0.5,7.9c0.7,0.8,0.9,0.8,1.5,1.2c0.9,0.6-0.4,0.5-0.5,2c-0.2,2.1,1.4,3.5,3.2,4.7
\t\t\tc0.5,0.3,1.3,0.4,1.9,0.5c0.3,0.1,0.7-0.1,0.8,0.3c0.3,1.1,2.8,2.2,4.9,2.7c0.7,0.2,2-0.3,2.2,0c0.2,0.2,0.5,1,0.7,1.2
\t\t\tc0.9,0.9,2,1.5,2.7,1.7c0.4,0.1,1.9,0,3.2-0.2c1-0.2,1.2,0,1.6,0.2c0.3,0.1,2.2,1.3,2.8,1.7c2.4,1.5,12-2,17.5-0.7
\t\t\tc1.2,0.3,4.1,0.6,4.2,0.2c3.7-0.4,10.8-2.4,17.5-4.4c8.8-2.6,19.7-5,29.7-3.8c23.2,2.8,24.4-8.3,26.2-15.6
\t\t\tC299.7,279.9,291.5,267.8,288.8,262.5z"/>
                </g>
                <g><title>Medial Right Foot</title>
                    <path class="st5" d="M147.3,395.6c-0.7-6.2-2.5-13.1-5.4-20.7c-3.8-10.3-5.5-16.3-5.1-17.9c-9.4,1.5-15.5,3-18.3,4.3
\t\t\tc-0.8,0.4-1.6,0.8-2.3,1.3c-1.4,0-4.2,1.5-6,1.4c-3.2-0.2-5-2.6-9.2,0c-1.2,0.7-1,2.7-1.7,3.8c-1.1,1.7-3,2.7-4.9,4.1
\t\t\tc-5.6,4.3-10.8,11.2-14.9,12.9c-6,2.5-10.5,3.6-14.7,6.9c-1.6,1.3-2.9,2.3-4.1,3.3c-1.7-0.1-5,1.2-6.6,1c-1.6-0.2-4.1,2.7-7.7,2.7
\t\t\ts-4.9,1.7-6.8,1.6c-9.1-0.5-10.1,3.3-11.3,7.1c-1.2,3.8,1.8,8.6,18.1,9.3c1.6,0.1,4.1,0.2,6.6,0.3c3.2,0.8,8.1,0.3,11.3,0.2
\t\t\tc13.7-0.7,17.1-6.6,33.6-7.3c10.3-0.5,20,3.9,30,3c4.6-0.4,12.1-0.6,17.1-7.3C146.4,403.6,147.1,400.4,147.3,395.6
\t\t\tc-0.6,0.2-1,0.3-1.5,0.4C146.3,395.9,146.8,395.8,147.3,395.6z"/>
                </g>
                <g><title>Medial Left Foot</title>
                    <path class="st5" d="M296.8,408.4c-1.2-3.8-2.2-7.6-11.3-7.1c-1.8,0.1-3.2-1.6-6.8-1.6s-6.2-2.9-7.7-2.7c-1.5,0.2-4.8-1.1-6.6-1
\t\t\tc-1.1-0.9-2.5-2-4.1-3.3c-4.2-3.3-8.7-4.4-14.7-6.9c-4.2-1.7-9.3-8.6-14.9-12.9c-1.9-1.5-3.8-2.5-4.9-4.1
\t\t\tc-0.7-1.1-0.5-3.1-1.7-3.8c-4.2-2.6-6-0.2-9.2,0c-1.8,0.1-4.6-1.4-6-1.4c-0.7-0.5-1.5-0.9-2.3-1.3c-2.8-1.3-8.9-2.7-18.3-4.3
\t\t\tc0.4,1.6-1.3,7.6-5.1,17.9c-2.9,7.7-4.7,14.6-5.4,20.7c0.5,0.1,1,0.2,1.5,0.4c-0.5-0.1-0.9-0.2-1.4-0.3c0.1,4.7,0.8,7.9,2.2,9.7
\t\t\tc5.1,6.7,12.5,6.9,17.1,7.3c10,1,19.8-3.4,30-3c16.6,0.7,20,6.6,33.6,7.3c3.2,0.2,8.1,0.6,11.3-0.2c2.5-0.1,5.1-0.2,6.6-0.3
\t\t\tC295,417,297.9,412.2,296.8,408.4z"/>
                </g>
                <text transform="matrix(1 0 0 1 222.6658 47)" class="st6 st7 st8">Left</text>
                <text transform="matrix(1 0 0 1 71.6797 46.8226)" class="st6 st7 st8">Right</text>
            </g>
        </g>
    </svg>
</template>
`)
export class FeetSvg {}
