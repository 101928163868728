import "./messages-list.scss";

import { customElement, bindable, bindingMode, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";

import { IConversationNavOptions } from "../../../interfaces/i-message";

@autoinject
@customElement("messages-list")
export class MessagesList {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public conversations: IConversationNavOptions[] = [];
    private _router: Router;

    public constructor(router: Router) {
        this._router = router;
    }

    public handleMenuSelection(conversation: IConversationNavOptions) {
        this._router.navigate(`/message-center/conversation/${conversation.id}`);
    }
}
