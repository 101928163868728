import { ValidationRules } from "aurelia-validation";
import * as moment from "moment";
import { IVolunteerActivity } from "../services/i-volunteer-activity-service";

export class VolunteerActivity implements IVolunteerActivity {
    public id: string;
    public effectiveDate: string = moment().format("MM/DD/YYYY");
    public activityName: string = "";
    public costSavingsRate?: number;
    public includedIn5Percent: boolean = true;
    public providerIds: string[] = [];
    public discontinueDate?: string;

    public constructor(data?: IVolunteerActivity) {
        this.initValidation();
        if (data) {
            Object.assign(this, data);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: VolunteerActivity) => x.activityName)
            .required()
            .withMessage("Activity Name is required.")
            .ensure((x: VolunteerActivity) => x.effectiveDate)
            .required()
            .withMessage("Effective date is required.")
            .ensure((x: VolunteerActivity) => x.includedIn5Percent)
            .required()
            .withMessage("Include in 5% is required.")
            .ensure((x: VolunteerActivity) => x.costSavingsRate)
            .required()
            .when((x: VolunteerActivity) => x.includedIn5Percent)
            .withMessage("Cost Savings Rate is required.")
            .ensure((x: VolunteerActivity) => x.providerIds)
            .required()
            .withMessage("Associated Agency Providers are required.")
            .ensure((x: VolunteerActivity) => x.discontinueDate)
            .satisfies((val: string, x: VolunteerActivity) =>
                !!val ? !moment(val).isBefore(moment(x.effectiveDate)) : true
            )
            .withMessage("End Date must be on or after Start Date.")
            .on(this);
    }
}
