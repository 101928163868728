import {valueConverter} from "aurelia-binding";

@valueConverter("formatToUppercase")
export class FormatToUppercase {
    public fromView(value: string): string {
        if (!value) {
            return "";
        }
        return value?.toUpperCase();
    }
}
