import { PLATFORM } from "aurelia-framework";
import { RouteConfig } from "aurelia-router";

import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import {
    hospitalizedPatientsTooltip,
    levelOfCareTooltip,
    onCallStatsTooltip,
    ordersPendingTooltip,
    outstandingOrdersByTypeTooltip,
    outstandingOrdersTooltip,
    overlappingFrequencyTooltip,
    patientDiagReportTooltip,
    skilledNursingCareVisitTooltip,
    skilledNursingCareWeekendsTooltip,
    supervisoryVisitTooltip,
    topOutstandingOrdersTooltip,
    benefitPeriodHistoryTooltip,
    incidentLogTooltip,
    nonCoveredItemsTooltip
} from "./clinical-reports-tooltips";

export const clinicalPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.Clinical,
        action: PermissionActionEnum.View
    }
];
export const clinicalReportsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "patient-diag-report"
    },
    {
        name: "skilled-nursing-care-weekends",
        route: "skilled-nursing-care-weekends",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./skilled-nursing-care/skilled-nursing-care-weekends",
            "skilled-nursing-care-weekends"
        ),
        title: "Skilled Nursing Care Minutes on Weekends Report",
        href: "reports/clinical-reports/skilled-nursing-care-weekends",
        settings: {
            tooltip: skilledNursingCareWeekendsTooltip,
            menuName: "Skilled Nursing Care Minutes on Weekends Report",
            isPalliativeCare: false,
            permission: clinicalPermission,
            releaseDate: "02/07/2024"
        }
    },
    {
        name: "non-covered-items",
        route: "non-covered-items",
        nav: true,
        moduleId: PLATFORM.moduleName("./non-covered-items/non-covered-items", "non-covered-items"),
        title: "Non-Covered Items Report",
        href: "reports/clinical-reports/non-covered-items",
        settings: {
            tooltip: nonCoveredItemsTooltip,
            menuName: "Non-Covered Items Report",
            permission: clinicalPermission,
            releaseDate: "9/07/2023"
        }
    },
    {
        name: "incident-log",
        route: "incident-log",
        nav: true,
        moduleId: PLATFORM.moduleName("./incident-log/incident-log", "incident-log"),
        title: "Incident/Accident Log",
        href: "reports/clinical-reports/incident-log",
        settings: {
            tooltip: incidentLogTooltip,
            menuName: "Incident/Accident Log",
            isPalliativeCare: false,
            permission: clinicalPermission,
            releaseDate: "02/22/2023"
        }
    },
    {
        name: "benefit-period-history",
        route: "benefit-period-history",
        nav: true,
        moduleId: PLATFORM.moduleName("./benefit-period-history/benefit-period-history", "benefit-period-history"),
        title: "Benefit Period History Report",
        href: "reports/clinical-reports/benefit-period-history",
        settings: {
            tooltip: benefitPeriodHistoryTooltip,
            menuName: "Benefit Period History Report",
            isPalliativeCare: false,
            permission: clinicalPermission,
            releaseDate: "10/18/2022"
        }
    },
    {
        name: "level-of-care-report",
        route: "level-of-care-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./level-of-care-report/level-of-care-report", "level-of-care-report"),
        title: "Level of Care History Report",
        href: "reports/clinical-reports/level-of-care-report",
        settings: {
            tooltip: levelOfCareTooltip,
            menuName: "Level of Care History Report",
            isPalliativeCare: false,
            permission: clinicalPermission
        }
    },
    {
        name: "skilled-nursing-care",
        route: "skilled-nursing-care",
        nav: true,
        moduleId: PLATFORM.moduleName("./skilled-nursing-care/skilled-nursing-care", "skilled-nursing-care"),
        title: "Skilled Nursing Care Visits",
        href: "reports/clinical-reports/skilled-nursing-care",
        settings: {
            tooltip: skilledNursingCareVisitTooltip,
            menuName: "Skilled Nursing Care Visits",
            isPalliativeCare: false,
            permission: clinicalPermission
        }
    },
    {
        name: "patient-diag-report",
        route: "patient-diag-report",
        nav: true,
        moduleId: PLATFORM.moduleName("./patient-diag-report/patient-diag-report", "patient-diag-report"),
        title: "Patient Diagnosis Report",
        href: "reports/clinical-reports/patient-diag-report",
        settings: {
            tooltip: patientDiagReportTooltip,
            menuName: "Patient Diagnosis Report",
            permission: clinicalPermission
        }
    },
    {
        name: "hospitalized-patients",
        route: "hospitalized-patients",
        nav: true,
        moduleId: PLATFORM.moduleName("./hospitalized-patients/hospitalized-patients", "hospitalized-patients"),
        title: "Hospitalized Patients Report",
        href: "reports/clinical-reports/hospitalized-patients",
        settings: {
            tooltip: hospitalizedPatientsTooltip,
            menuName: "Hospitalized Patients Report",
            permission: clinicalPermission
        }
    },
    {
        name: "on-call-stats",
        route: "on-call-stats",
        nav: true,
        moduleId: PLATFORM.moduleName("./on-call-stats/on-call-stats", "on-call-stats"),
        title: "On-Call Stats",
        href: "reports/clinical-reports/on-call-stats",
        settings: {
            tooltip: onCallStatsTooltip,
            menuName: "On-Call Stats",
            permission: clinicalPermission
        }
    },
    {
        name: "outstanding-orders-by-age",
        route: "outstanding-orders-by-age",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./outstanding-orders-by-age/outstanding-orders-by-age",
            "outstanding-orders-by-age"
        ),
        title: "Outstanding Orders by Age",
        href: "reports/clinical-reports/outstanding-orders-by-age",
        settings: {
            menuName: "Outstanding Orders by Age",
            tooltip: outstandingOrdersTooltip,
            isPalliativeCare: false,
            permission: clinicalPermission
        }
    },
    {
        name: "orders-pending",
        route: "orders-pending",
        nav: true,
        moduleId: PLATFORM.moduleName("./orders-pending/orders-pending", "orders-pending"),
        title: "Orders Pending QA Approval",
        href: "reports/clinical-reports/orders-pending",
        settings: {
            tooltip: ordersPendingTooltip,
            menuName: "Orders Pending QA Approval",
            isPalliativeCare: false,
            permission: clinicalPermission
        }
    },
    {
        name: "top-outstanding-orders",
        route: "top-outstanding-orders",
        nav: true,
        moduleId: PLATFORM.moduleName("./top-outstanding-orders/top-outstanding-orders", "top-outstanding-orders"),
        title: "Top Physicians with Outstanding Orders",
        href: "reports/clinical-reports/top-outstanding-orders",
        settings: {
            tooltip: topOutstandingOrdersTooltip,
            menuName: "Top Physicians with Outstanding Orders",
            isPalliativeCare: false,
            permission: clinicalPermission
        }
    },
    {
        name: "outstanding-orders-by-type",
        route: "outstanding-orders-by-type",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./outstanding-orders-by-type/outstanding-orders-by-type",
            "outstanding-orders-by-type"
        ),
        title: "Outstanding Orders by Type",
        href: "reports/clinical-reports/outstanding-orders-by-type",
        settings: {
            menuName: "Outstanding Orders by Type",
            tooltip: outstandingOrdersByTypeTooltip,
            isPalliativeCare: false,
            permission: clinicalPermission
        }
    },
    {
        name: "overlapping-frequencies-report",
        route: "overlapping-frequencies-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./overlapping-frequency-report/overlapping-frequency-report",
            "overlapping-frequency-report"
        ),
        title: "Overlapping Frequencies Report",
        href: "reports/clinical-reports/overlapping-frequencies-report",
        settings: {
            tooltip: overlappingFrequencyTooltip,
            permission: clinicalPermission,
            menuName: "Overlapping Frequencies Report",
            isPalliativeCare: false
        }
    },
    {
        name: "supervisory-visit-report",
        route: "supervisory-visit-report",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./supervisory-visit-report/supervisory-visit-report",
            "supervisory-visit-report"
        ),
        title: "Supervisory Visit Report",
        href: "reports/clinical-reports/supervisory-visit-report",
        settings: {
            tooltip: supervisoryVisitTooltip,
            permission: clinicalPermission,
            menuName: "Supervisory Visit Report"
        }
    }
];
