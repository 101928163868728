import { autoinject } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

import { ILoadNarrativeBehavior } from "../../../interfaces/form-builder/i-behavior";
import { PatientsService } from "../../../services/patient-service";
import { NoteService } from "../../../services/note-service";
import { NoteDataManager } from "../note-data-manager";
import { FormButtonEvent } from "../form-input/form-button/form-button";

@autoinject
export class LoadNarrativeBehaviorHandler {
    private _patientsService: PatientsService;
    private _noteService: NoteService;
    private _ea: EventAggregator;

    public constructor(patientsService: PatientsService, noteService: NoteService, ea: EventAggregator) {
        this._patientsService = patientsService;
        this._noteService = noteService;
        this._ea = ea;
    }

    public async handle(behavior: ILoadNarrativeBehavior, noteDataManager: NoteDataManager) {
        let modifiedData = noteDataManager.getModifiedData();
        try {
            let taskId = noteDataManager.getTaskId();
            // Saving the note here to have the updated note data.
            // Ref: save() from note.ts
            if (Object.keys(modifiedData).length > 0) {
                await this._noteService.saveNote({
                    formId: taskId,
                    data: modifiedData
                });
                noteDataManager.updateNoteModel(modifiedData);
                noteDataManager.resetModifiedData(modifiedData);
            }
            let data = await this._patientsService.loadNarrativeByTask(taskId);
            this._ea.publish(FormButtonEvent.LoadData, {
                target: behavior.target,
                data: data.narrative
            });
        } catch (e) {
            console.error(e);
            noteDataManager.initModifiedData(modifiedData);
        }
    }
}
