import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { IPermissionCheck } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { ReportPermissionEnum } from "../../../enums/report-permission-enum";
import {
    averageDailyCensusTooltip,
    averageLengthOfStayTooltip,
    capStatisticsTooltip,
    censusByPrimaryPayerTooltip,
    daysSinceLastVisitTooltip,
    hisByStatusTooltip,
    locationOfCareProvidedTooltip,
    medianLengthOfStayTooltip,
    patientMixByDiagnosisTooltip,
    percentageOfCareDaysTooltip,
    referralConversionRateTooltip,
    visitsNearDeathTooltip,
    volunteerActivityTooltip,
    referralConversionRateByReferralSourceTooltip
} from "./statistical-reports-tooltips";

export const statisticalPermission: IPermissionCheck[] = [
    {
        resource: ReportPermissionEnum.Statistical,
        action: PermissionActionEnum.View
    }
];

export const statisticalReportsRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "census-by-primary-payer"
    },
    {
        name: "referral-conversion-rate-by-referral-source",
        route: "referral-conversion-rate-by-referral-source",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./referral-conversion-rate-by-referral-source/referral-conversion-rate-by-referral-source",
            "referral-conversion-rate-by-referral-source"
        ),
        title: "Referral Conversion Rate by Referral Source Report",
        href: "reports/statistical-reports/referral-conversion-rate-by-referral-source",
        settings: {
            tooltip: referralConversionRateByReferralSourceTooltip,
            menuName: "Referral Conversion Rate by Referral Source Report",
            permission: statisticalPermission,
            releaseDate: "08/30/2023"
        }
    },
    {
        name: "volunteer-activity",
        route: "volunteer-activity",
        nav: true,
        moduleId: PLATFORM.moduleName("./volunteer-activity/volunteer-activity", "volunteer-activity"),
        title: "Volunteer Activity",
        href: "reports/statistical-reports/volunteer-activity",
        settings: {
            tooltip: volunteerActivityTooltip,
            menuName: "Volunteer Activity",
            permission: statisticalPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "days-since-last-visit",
        route: "days-since-last-visit",
        nav: true,
        moduleId: PLATFORM.moduleName("./days-since-last-visit/days-since-last-visit", "days-since-last-visit"),
        title: "Days Without a Skilled Nursing Visit",
        href: "reports/statistical-reports/days-since-last-visit",
        settings: {
            tooltip: daysSinceLastVisitTooltip,
            menuName: "Days Without a Skilled Nursing Visit",
            permission: statisticalPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "visits-near-death",
        route: "visits-near-death",
        nav: true,
        moduleId: PLATFORM.moduleName("./visits-near-death/visits-near-death", "visits-near-death"),
        title: "Visits Near Death",
        href: "reports/statistical-reports/visits-near-death",
        settings: {
            tooltip: visitsNearDeathTooltip,
            menuName: "Visits Near Death",
            permission: statisticalPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "referral-conversion-rate",
        route: "referral-conversion-rate",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./referral-conversion-rate/referral-conversion-rate",
            "referral-conversion-rate"
        ),
        title: "Referral Conversion Rate by Admission Source",
        href: "reports/statistical-reports/referral-conversion-rate",
        settings: {
            tooltip: referralConversionRateTooltip,
            menuName: "Referral Conversion Rate by Admission Source",
            permission: statisticalPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "percentage-of-care-days",
        route: "percentage-of-care-days",
        nav: true,
        moduleId: PLATFORM.moduleName("./percentage-of-care-days/percentage-of-care-days", "percentage-of-care-days"),
        title: "Percentage of Care Days by Service Location",
        href: "reports/statistical-reports/percentage-of-care-days",
        settings: {
            tooltip: percentageOfCareDaysTooltip,
            menuName: "Percentage of Care Days by Service Location",
            permission: statisticalPermission
        }
    },
    {
        name: "patient-mix-by-diagnosis",
        route: "patient-mix-by-diagnosis",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./patient-mix-by-diagnosis/patient-mix-by-diagnosis",
            "patient-mix-by-diagnosis"
        ),
        title: "Patient Mix by Diagnosis",
        href: "reports/statistical-reports/patient-mix-by-diagnosis",
        settings: {
            tooltip: patientMixByDiagnosisTooltip,
            menuName: "Patient Mix by Diagnosis",
            permission: statisticalPermission
        }
    },
    {
        name: "location-of-care-provided",
        route: "location-of-care-provided",
        nav: true,
        moduleId: PLATFORM.moduleName(
            "./location-of-care-provided/location-of-care-provided",
            "location-of-care-provided"
        ),
        title: "Location of Care Provided",
        href: "reports/statistical-reports/location-of-care-provided",
        settings: {
            tooltip: locationOfCareProvidedTooltip,
            menuName: "Location of Care Provided",
            permission: statisticalPermission,
            isPalliativeCare: false
        }
    },
    {
        name: "average-daily-census",
        route: "average-daily-census",
        nav: true,
        moduleId: PLATFORM.moduleName("./average-daily-census/average-daily-census", "average-daily-census"),
        title: "Average Daily Census",
        href: "reports/statistical-reports/average-daily-census",
        settings: {
            tooltip: averageDailyCensusTooltip,
            menuName: "Average Daily Census",
            permission: statisticalPermission
        }
    },
    {
        name: "census-by-primary-payer",
        route: "census-by-primary-payer",
        nav: true,
        moduleId: PLATFORM.moduleName("./census-by-primary-payer/census-by-primary-payer", "census-by-primary-payer"),
        title: "Census by Primary Payer",
        href: "reports/statistical-reports/census-by-primary-payer",
        settings: {
            tooltip: censusByPrimaryPayerTooltip,
            menuName: "Census by Primary Payer",
            permission: statisticalPermission
        }
    },
    {
        name: "cap-statistics",
        route: "cap-statistics",
        nav: true,
        moduleId: PLATFORM.moduleName("./cap-statistics/cap-statistics", "cap-statistics"),
        title: "Cap Statistics",
        href: "reports/statistical-reports/cap-statistics",
        settings: {
            tooltip: capStatisticsTooltip,
            menuName: "Cap Statistics",
            permission: statisticalPermission
        }
    },
    {
        name: "median-length-of-stay",
        route: "median-length-of-stay",
        nav: true,
        moduleId: PLATFORM.moduleName("./median-length-of-stay/median-length-of-stay", "median-length-of-stay"),
        title: "Median Length of Stay",
        href: "reports/statistical-reports/median-length-of-stay",
        settings: {
            tooltip: medianLengthOfStayTooltip,
            menuName: "Median Length of Stay",
            permission: statisticalPermission
        }
    },
    {
        name: "his-by-status",
        route: "his-by-status",
        nav: true,
        moduleId: PLATFORM.moduleName("./his-by-status/his-by-status", "his-by-status"),
        title: "HIS by Status",
        href: "reports/statistical-reports/his-by-status",
        settings: {
            tooltip: hisByStatusTooltip,
            menuName: "HIS by Status",
            isPalliativeCare: false,
            permission: statisticalPermission
        }
    },
    {
        name: "average-length-of-stay",
        route: "average-length-of-stay",
        nav: true,
        moduleId: PLATFORM.moduleName("./average-length-of-stay/average-length-of-stay", "average-length-of-stay"),
        title: "Average Length of Stay",
        href: "reports/statistical-reports/average-length-of-stay",
        settings: {
            tooltip: averageLengthOfStayTooltip,
            menuName: "Average Length of Stay",
            permission: statisticalPermission
        }
    }
];
