import { bindingMode, computedFrom } from "aurelia-binding";
import { bindable, customElement } from "aurelia-templating";

import nameof from "../../../common/nameof";
import { IEnumResponse } from "../../../interfaces/i-enum";
import {
    IGoal,
    IIntervention,
    IInterventionToggleCallBack,
    IProblemStatement,
    IProblemToggleCallBack,
    IResolveGoalCallBack
} from "../../../interfaces/i-plan-of-care";
import { IResolveGoal } from "./../../../interfaces/i-plan-of-care";

@customElement("problem-statements-component")
export class ProblemStatementsComponent {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public problemStatements: IProblemStatement[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isUpdateEnabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public expandStatements: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public resolvedGoals: IResolveGoal[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public discontinuedInterventions: string[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public discontinuedProblemStatements: string[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public goalActions: IEnumResponse[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public interventionToggle: (intervention: IInterventionToggleCallBack) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public resolveGoal: (args: { goal: IResolveGoal; statement: IProblemStatement }) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public problemToggle: (problemStatement: IProblemToggleCallBack) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public goalCompletedByDateEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public interventionDisciplinesEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAction: boolean = false;
    public showStatements: string[] = [];

    @computedFrom(nameof<ProblemStatementsComponent>("resolvedGoals"))
    public get resolvedGoalIds() {
        return this.resolvedGoals.map((goal) => goal.id);
    }

    public attached() {
        if (this.expandStatements) {
            this.showStatements = this.problemStatements.map((statement) => statement.id);
        } else {
            this.showStatements = [];
        }
    }

    public expandStatementsChanged() {
        if (this.expandStatements) {
            this.showStatements = this.problemStatements.map((statement) => statement.id);
        }
    }

    // Display Toggle - Expand / Collapse
    public toggleProblemStatement(problemStatement: IProblemStatement) {
        if (this.showStatements.includes(problemStatement.id)) {
            this.showStatements = this.showStatements.filter((statementId) => statementId != problemStatement.id);
            if (this.expandStatements) {
                this.expandStatements = false;
            }
        } else {
            this.showStatements = this.showStatements.concat(problemStatement.id);
        }
    }

    // Marking Intervention
    public toggleInterventionCallback(
        intervention: IIntervention,
        addIntervention: boolean,
        problemStatement: IProblemStatement
    ) {
        this.interventionToggle({ id: intervention.id, addIntervention, statement: problemStatement });
    }

    // Marking Goal
    public resolveGoalCallback(goal: IGoal, problemStatement: IProblemStatement, action: string) {
        let resolvedGoalIds = this.resolvedGoalIds.filter((goalId) =>
            problemStatement.goals.map((psGoal) => psGoal.id).includes(goalId)
        );

        // Add Problem Statement if last Goal is Added
        if (this.isLastGoalAdded(goal.id, problemStatement, resolvedGoalIds, action)) {
            this.problemToggle({
                statement: problemStatement,
                addStatement: true
            });
        }

        // Remove Problem Statement if any goal is removed
        if (this.isGoalRemoved(problemStatement, resolvedGoalIds, action)) {
            this.problemToggle({
                statement: problemStatement,
                addStatement: false
            });
        }
        this.resolveGoal({ goal: { id: goal.id, action: action }, statement: problemStatement });
    }

    private isLastGoalAdded(
        goalId: string,
        problemStatement: IProblemStatement,
        resolvedGoalIds: string[],
        action: string
    ) {
        if (problemStatement && action !== null) {
            // Before and After Conditions to avoid the scenario if user is modifying already resolved goal action
            let goalsBeforeAdding = new Set(resolvedGoalIds);
            let beforeCondition = goalsBeforeAdding.size === problemStatement.goals.length - 1;
            let goalsAfterAdding = new Set(resolvedGoalIds).add(goalId);
            let afterCondition = problemStatement.goals.length === goalsAfterAdding.size;
            return beforeCondition && afterCondition;
        } else {
            return false;
        }
    }

    // goal removed from a problem statement which user resolved (all goals resolved)
    private isGoalRemoved(problemStatement: IProblemStatement, resolvedGoalIds: string[], action: string) {
        if (problemStatement && action === null) {
            return problemStatement.goals.length === resolvedGoalIds.length;
        } else {
            return false;
        }
    }
}
