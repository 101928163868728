import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { AccountsService } from "../../services/accounts-service";
import { ProvidersService } from "../../services/providers-service";
import { addUserPermission } from "../create/create-route-permissions";
import { AuthService } from "../../services/auth-service";

@autoinject
export class EmployeesRoutes {
    private readonly _providersService: ProvidersService;
    private readonly _accountsService: AccountsService;
    private _authService: AuthService;
    private _allowedRoutes: RouteConfig[] = [];
    private _employeesRoutes: RouteConfig[] = [
        {
            name: "root",
            route: "",
            redirect: "employee-center"
        },
        // {
        //     name: "employees-dashboard",
        //     route: "dashboard",
        //     moduleId: PLATFORM.moduleName("./employees-dashboard/employees-dashboard", "employees-dashboard"),
        //     nav: false,
        //     title: "People Dashboard",
        //     settings: {
        //         menuName: "People Dashboard",
        //         inProgressPage: true
        //     }
        // },
        {
            name: "employee-center",
            route: "employee-center/:id?",
            moduleId: PLATFORM.moduleName("./employee-center/employee-center", "employee-center"),
            href: "employees/employee-center/load",
            nav: true,
            title: "People Center",
            settings: {
                menuName: "People Center",
                keywords: [
                    "users",
                    "employee",
                    "staff",
                    "hr",
                    "human",
                    "resource",
                    "license",
                    "verification",
                    "training",
                    "inservice",
                    "service",
                    "time",
                    "mileage",
                    "permission",
                    "activities",
                    "activity",
                    "roles"
                ]
            }
        },
        {
            name: "payroll-center",
            route: "payroll-center",
            moduleId: PLATFORM.moduleName("./payroll-center/payroll-center", "payroll-center"),
            nav: true,
            title: "Payroll Center",
            settings: {
                menuName: "Payroll Center"
            }
        },
        {
            name: "volunteer-center",
            route: "volunteer-center/:id?",
            moduleId: PLATFORM.moduleName("./volunteer-center/volunteer-center", "volunteer-center"),
            href: "employees/volunteer-center/load",
            nav: true,
            title: "Volunteer Center",
            settings: {
                menuName: "Volunteer Center"
            }
        },
        {
            name: "bereavement",
            route: "bereavement/:id?",
            moduleId: PLATFORM.moduleName("../patients/bereavement/bereavement", "bereavement"),
            href: "employees/bereavement/load",
            nav: true,
            title: "Bereavement",
            settings: {
                menuName: "Bereavement",
                inProgressPage: true
            }
        },
        {
            name: "user-edit",
            route: "user-edit/:id?",
            moduleId: PLATFORM.moduleName("../create/create-user/create-user", "create-user"),
            href: "employees/user-edit/load",
            settings: {
                permission: addUserPermission,
                checkAll: true
            }
        },
        {
            name: "team-edit",
            route: "team-edit/:id?",
            moduleId: PLATFORM.moduleName("../create/create-team/create-team", "create-team"),
            href: "employees/team-edit/load"
        }
    ];

    public constructor(providersService: ProvidersService, accountsService: AccountsService, authService: AuthService) {
        this._providersService = providersService;
        this._accountsService = accountsService;
        this._authService = authService;
    }

    public async getRoutes() {
        this._allowedRoutes = [...this._employeesRoutes];
        await this.addPayrollCenterRouteOnEnabled();
        return this._allowedRoutes;
    }

    public async addPayrollCenterRouteOnEnabled() {
        let isEnabled = false;
        try {
            isEnabled = await this.hasPayrollCenterPermission();
            if (!isEnabled) {
                this._allowedRoutes = this._employeesRoutes.filter((route) => !(route.name === "payroll-center"));
            }
        } catch (e) {
            console.error(e);
        }
        return this._allowedRoutes;
    }

    public async hasPayrollCenterPermission() {
        let accountId = await this._authService.getAccountId();
        if (!accountId) {
            return false;
        }
        try {
            let account = await this._accountsService.getAccountInfo();
            let providerId = account.providers[0].id;
            let payrollCenterInfo = await this._providersService.getPayrollCenterInfo(providerId);
            return payrollCenterInfo.isEnabled;
        } catch (e) {
            throw e;
        }
    }
}
