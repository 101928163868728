import "./option-input.scss";

import { bindable, bindingMode, computedFrom, containerless, customElement } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { IOption } from "../../../../interfaces/form-builder/i-element";
import {getColumnClasses} from "../../../../common/utilities/column-classes";

@customElement("option-input")
@containerless
export class OptionInput {
    @bindable({ defaultBindingMode: bindingMode.toView})
    public viewOptions: IOption;
    @bindable({ defaultBindingMode: bindingMode.toView})
    public isReadOnly: boolean = false;

    @computedFrom(
        `${nameof<OptionInput>("viewOptions")}.${nameof<IOption>("columns")}`
    )
    public get columnClass(): string {
        let numColumns = this.viewOptions.columns;
        return getColumnClasses(numColumns);
    }
}
