import "./photo-cropper.scss";

import {observable} from "aurelia-binding";
import {DialogController} from "aurelia-dialog";
import {autoinject} from "aurelia-framework";
import Cropper from "cropperjs";

import nameof from "../../../common/nameof";
import {ChangePhotoEnum} from "../../../enums/change-photo-enum";
import {IGetPhotoResult, IPhotoDialogOptions} from "../../../interfaces/i-change-photo";
import {AssetsService} from "./../../../services/assets-service";
import {ToastrService} from "./../../../services/toastr-service";

@autoinject
export class PhotoCropper {
    private _toastrService: ToastrService;
    private _assetsService: AssetsService;
    @observable({
        changeHandler: nameof<PhotoCropper>("uploadImageChanged")
    })
    public uploadImage: FileList;
    public uploadedImg: HTMLImageElement;
    public cropper: Cropper;
    public isUploading: boolean = false;
    public isError: boolean = false;
    public options: IPhotoDialogOptions = {
        id: "",
        title: "",
        actions: []
    };
    public controller: DialogController;
    public currentPhoto: IGetPhotoResult;
    public photoCache: IGetPhotoResult;
    public fileInput: HTMLInputElement;
    public croppedImage: HTMLCanvasElement;
    public showNoImageIcon: boolean = false;
    public isImgCropInProgress: boolean = false;
    public isDeleteRequested: boolean = false;
    public isErrorInCrop: boolean = false;
    public newFileName: string = "";

    public constructor(controller: DialogController, toastrService: ToastrService, assetsService: AssetsService) {
        this.controller = controller;
        this._toastrService = toastrService;
        this._assetsService = assetsService;
    }

    public async activate(options: IPhotoDialogOptions) {
        Object.assign(this.options, options);
        let getAction = this.options?.actions?.find((action) => action.label === ChangePhotoEnum.Get);
        this.currentPhoto = await getAction.callBack();
        if (!this.currentPhoto?.photoUrl) {
            this.showNoImageIcon = true;
        }
        this.photoCache = Object.assign({}, this.currentPhoto);
    }

    public initPhotoCropper(file: File) {
        this.isImgCropInProgress = true;
        this.newFileName = file.name;
        // start file reader
        const reader = new FileReader();
        reader.onload = (e: any) => {
            // create new image
            this.uploadedImg.src = e.target.result;
            this.cropper = new Cropper(this.uploadedImg, {
                viewMode: 2,
                dragMode: "move",
                center: false,
                zoomOnWheel: true,
                cropBoxResizable: false,
                guides: true,
                minContainerHeight: 300,
                minContainerWidth: 300,
                aspectRatio: 1,
                autoCropArea: 1
            });
        };
        reader.readAsDataURL(file);
    }

    public cancelCropImage() {
        this.isImgCropInProgress = false;
        this.cropper?.destroy();
        this.newFileName = "";
        if (!this.photoCache?.photoUrl) {
            this.showNoImageIcon = true;
            return;
        }
        this.uploadedImg.src = this.photoCache.photoUrl;
    }

    public uploadImageChanged(newValue: FileList) {
        let files = Array.from(newValue);
        if (files?.length > 0) {
            if (files[0].type.includes("image")) {
                this.showNoImageIcon = false;
                this.initPhotoCropper(files[0]);
            }
        }
    }

    public async cropAndSave() {
        await this.cropImage();
        if (!this.isErrorInCrop) {
            await this.uploadPhoto();
        }
    }

    public async cropImage() {
        this.croppedImage = this.cropper.getCroppedCanvas({
            minWidth: 170,
            minHeight: 170
        });
        if (!!this.croppedImage) {
            this.currentPhoto.photoUrl = this.croppedImage.toDataURL("image/png");
            this.currentPhoto.photoName = this.newFileName;
            this.newFileName = "";
            this.cropper?.destroy();
        } else {
            this.isErrorInCrop = true;
        }
    }

    public openFileWindow() {
        $(this.fileInput).trigger("click");
    }

    public requestDeletePhoto() {
        this.isDeleteRequested = true;
    }

    public cancelDeletePhoto() {
        this.isDeleteRequested = false;
    }

    public async deletePhoto() {
        try {
            let deleteAction = this.options?.actions?.find((action) => action.label === ChangePhotoEnum.Delete);
            await deleteAction?.callBack();
            this.controller.ok(true);
        } catch (error) {
            console.error(error);
            this._toastrService.error({
                title: `Error - Delete Photo`,
                message: `There was an error while deleting the photo.`
            });
        } finally {
            this.isDeleteRequested = false;
        }
    }

    public async uploadPhoto() {
        try {
            this.isUploading = true;
            this.isError = false;
            this.croppedImage.toBlob(async (blob) => {
                let fileToUpload = this.blobToFile(blob, this.currentPhoto.photoName);
                let assetId = await this._assetsService.uploadFile(fileToUpload);
                let uploadAction = this.options?.actions?.find((action) => action.label === ChangePhotoEnum.Upload);
                uploadAction.callBack(assetId);
            }, "image/jpeg");
            this.controller.ok(true);
        } catch (e) {
            console.error(e);
            this.isError = true;
            this._toastrService.error({
                title: `Error - ${this.options.title}`,
                message: "There was an error while updating the photo."
            });
        } finally {
            this.isUploading = false;
        }
    }

    public tryAgain() {
        this.isErrorInCrop = false;
        this.cropAndSave();
    }

    public blobToFile(theBlob: Blob, fileName: string): File {
        return new File([theBlob], fileName);
    }
}
