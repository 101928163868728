import "./form-section.scss";

import { bindable, bindingMode, containerless, customElement } from "aurelia-framework";

@containerless
@customElement("form-section")
export class FormSection {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public size: string = "12";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string;
    // TODO: Need enums rather than string for types
    @bindable({ defaultBindingMode: bindingMode.toView })
    public type: string = "default-section";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public popoverContent: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public popoverContainer: string = "body";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public fullHeight: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public cssId: string = "";
}
