import "./upload.scss";

import { autoinject, observable } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import * as moment from "moment";

import { IGetPatientAdmissionPeriods, IPatientDialogOptions } from "../../../../interfaces/i-get-patient";
import { IUploadPatientDocumentQuery } from "./../../../../interfaces/i-get-patient";
import { PatientsService } from "./../../../../services/patient-service";
import { PatientDocuments } from "../patient-documents/patient-documents";
import nameof from "../../../../common/nameof";

@autoinject
export class Upload {
    private _patientsService: PatientsService;
    public controller: DialogController;
    public patientDocuments: PatientDocuments;
    public options: IPatientDialogOptions = {
        title: "",
        patientId: null
    };
    @observable({
        changeHandler: nameof<Upload>("selectedPatientVersionIdChanged")
    })
    public selectedPatientVersionId?: string = null;
    public admissionPeriods: IGetPatientAdmissionPeriods[] = [];

    public constructor(controller: DialogController, patientsService: PatientsService) {
        this.controller = controller;
        this._patientsService = patientsService;
    }

    public async activate(options: IPatientDialogOptions) {
        Object.assign(this.options, options);
        this.admissionPeriods = await this._patientsService.getPatientAdmissionPeriodsNoPermission(
            this.options.patientId
        );
    }

    public selectedPatientVersionIdChanged() {
        this.patientDocuments?.loadFilesList();
    }

    public getAdmissionPeriodLabel(period: IGetPatientAdmissionPeriods) {
        let startLabel = moment(period.admissionDate).format("MM/DD/YYYY");
        let end = period.isActive ? null : moment(period.dischargeDate).format("MM/DD/YYYY");
        let endLabel = end ? "- " + end : "";
        return `${startLabel} ${endLabel}${period.isActive ? "(Current Admission Period)" : ""}`;
    }

    public uploadDocument(patientId: string, newDocument: IUploadPatientDocumentQuery) {
        return this._patientsService.uploadDocument(patientId, newDocument);
    }

    public deleteDocument(patientId: string, documentId: string) {
        return this._patientsService.deleteDocument(patientId, documentId);
    }

    public getPatientDocuments(patientId: string) {
        return this._patientsService.getPatientDocuments(patientId, {
            patientVersionId: this.selectedPatientVersionId
        });
    }
}
