import { containerless, bindable, bindingMode } from "aurelia-framework";

import { config } from "../../../common/config";

@containerless
export class ReportErrorLink {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public classes: string = "";
    public ticketUrl: string = config.ticketUrl;
}
