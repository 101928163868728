import { autoinject, bindable, bindingMode, containerless, customElement } from "aurelia-framework";
import { IInput, IListInput, IOptionsInput, IScoreTableInput } from "../../../../interfaces/form-builder/i-input";
import { ILookupAndCopyBehavior } from "../../../../interfaces/form-builder/i-behavior";

export enum IExternalComponentType {
    LookupAndCopy = "lookupandcopy"
}

@customElement("external-component")
@containerless
@autoinject
export class DocumentPocProblemStatement {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public visitDate: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public element: IInput | IScoreTableInput | IListInput | IOptionsInput;
    public lookupAndCopyBehavior: ILookupAndCopyBehavior = null;

    public attached() {
        this.element.behaviors.forEach((behavior) => {
            switch (behavior.behaviorType) {
                case IExternalComponentType.LookupAndCopy:
                    this.lookupAndCopyBehavior = behavior as ILookupAndCopyBehavior;
                    return;
            }
        });
    }
}
