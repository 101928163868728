import { bindable } from "aurelia-framework";
import "./table-empty.scss";

export class TableEmpty {

    @bindable
    public colSpan: number;

    @bindable
    public primaryMessage: string;

    @bindable
    public secondaryMessage: string;
    @bindable
    public component: string;
}
