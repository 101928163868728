import { bindingMode } from "aurelia-binding";
import { bindable, containerless, customElement } from "aurelia-framework";
import { ISideNavOptions } from "../../../interfaces/i-side-nav-options";
import "./side-nav.scss";

@containerless
@customElement("side-nav")
export class SideNav {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public sideNavOptions: ISideNavOptions[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public emptyMessage: string = "No menu options found.";
    private _timeoutId: NodeJS.Timeout;
    public itemReferences: HTMLElement[] = [];

    public attached() {
        // console.info("Side Nav Options", this.sideNavOptions);
    }

    public handleMenuSelection(route: ISideNavOptions) {
        this.sideNavOptions.forEach((option) => {
            option.isActive = false;
            return option;
        });
        route.isActive = true;
    }

    public scrollIndexToView(index: number) {
        if (index >= 0 && index < this.sideNavOptions.length) {
            if (this._timeoutId) {
                clearTimeout(this._timeoutId);
            }
            this._timeoutId = setTimeout(() => {
                if (this.itemReferences[index]) {
                    this.itemReferences[index].scrollIntoView({ behavior: "smooth" });
                }
            }, 0);
        }
    }
}
