import { ValidationRules } from "aurelia-validation";
import { EnumMap } from "../common/utilities/enum-map";

import { IPhoneNumber } from "../interfaces/i-phone-number";
import { IAddress } from "./../interfaces/i-address";
import { Address } from "./common/address";

export class Physician {
    public id?: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public middleInitial: string = "";
    public taxonomyCode: string = "";
    public credentials: string = "";
    public npiNumber: string = "";
    public isPecosVerified: boolean = false;
    public medicaidProviderNumber: string = "";
    public address: IAddress = new Address();
    public phoneNumbers: IPhoneNumber[] = [];
    public faxNumber: string = "";
    public email: string = "";
    public isGrantedAccess: boolean = false;
    public isNotInExclusionList: boolean;
    public status: number;
    public deliveryMethod: number = null;
    public deliveryMethodEnumMap: EnumMap;
    public associatedAgencyProviderIds: string[] = [];
    public deliveryVendor: number = null;
    public licenseNumber: string = "";
    public expirationDate: string = "";
    public primaryState: string;
    public secondaryLicenseNumber: string = "";
    public secondaryExpirationDate: string = "";
    public secondaryState: string;
    public communityLiaisonId: string;
    public communityLiaisonName: string;

    public constructor(data?: Physician, deliveryMethodEnumMap?: EnumMap) {
        if (data) {
            Object.assign(this, data);
            this.address = new Address(this.address);
        }
        if (!!deliveryMethodEnumMap) {
            this.deliveryMethodEnumMap = deliveryMethodEnumMap;
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((x: Physician) => x.firstName)
            .required()
            .withMessage("First Name is required.")
            .ensure((x: Physician) => x.lastName)
            .required()
            .withMessage("Last Name is required.")
            .ensure((x: Physician) => x.npiNumber)
            .required()
            .withMessage("NPI is required.")
            .ensure((x: Physician) => x.email)
            .email()
            .withMessage("Invalid Email Address.")
            .ensure((x: Physician) => x.email)
            .required()
            .when((x: Physician) => this.isEmailRequired())
            .withMessage("Email is required.")
            .ensure((x: Physician) => x.faxNumber)
            .required()
            .when((x: Physician) => this.isFaxRequired())
            .withMessage("Fax Number is required.")
            .ensure((x: Physician) => x.deliveryMethod)
            .required()
            .withMessage("Preferred Delivery Method is required.")
            .ensure((x: Physician) => x.deliveryVendor)
            .required()
            .withMessage("Vendor is required.")
            .ensure((x: Physician) => x.licenseNumber)
            .required()
            .when((x: Physician) => (!!x.expirationDate || !!x.primaryState))
            .withMessage("Please enter Physician License Number")
            .ensure((x: Physician) => x.secondaryLicenseNumber)
            .required()
            .when((x: Physician) => (!!x.secondaryExpirationDate || !!x.secondaryState))
            .withMessage("Please enter Physician License Number")
            .on(this);
    }

    private isEmailRequired() {
        if (!this.deliveryMethod) {
            return false;
        }
        return this.deliveryMethodEnumMap?.checkEnumValue("Axxess Physician Portal", this.deliveryMethod);
    }

    private isFaxRequired() {
        if (!this.deliveryMethod) {
            return false;
        }
        return this.deliveryMethodEnumMap?.checkEnumValue("Fax", this.deliveryMethod);
    }
}
