import { DialogService } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { UserManager } from "oidc-client";

import { HttpStatusCodeEnum } from "../../enums/http-status-code-enum";
import { IResponseHandler } from "../../interfaces/i-handlers";
import { LoginPrompt } from "../../resources/dialogs/login-prompt/login-prompt";

@autoinject()
export class UnauthorizedErrorHandler implements IResponseHandler {
    private _dialogService: DialogService;
    private _userManager: UserManager;
    private _router: Router;

    public constructor(dialogService: DialogService, userManager: UserManager, router: Router) {
        this._dialogService = dialogService;
        this._userManager = userManager;
        this._router = router;
    }

    public async handle(response: Response): Promise<void> {
        let isOidcSignInPage: boolean = false;

        if (this._router?.currentInstruction?.config) {
            isOidcSignInPage = this._router.currentInstruction.config.name?.toLowerCase() === "oidc-sign-in";
        }
        // OIDC Sign in page shows a log in prompt as well
        if (response.status === HttpStatusCodeEnum.Unauthorized && !isOidcSignInPage) {
            this._dialogService
                .open({
                    viewModel: LoginPrompt
                })
                .whenClosed()
                .then(() => this._userManager.signinRedirect());
        }
    }
}
