import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";

import { InquiryKeywords } from "../patients/patients-routes";
import {
    addDmePermission,
    addInquiryPermission,
    addInsurancePayorPermission,
    addPharmacyPermission,
    addPhysicianPermission,
    addReferralPermission,
    addSupplyPermission,
    addUserPermission,
    addVendorPermission,
    addVolunteerActivityPermission,
    addNonPatientActivityPermission,
    addTeamsPermission
} from "./create-route-permissions";

const CommonKeywords = ["create", "enter", "add"];
export const PhysicianKeyWords = ["doctor", "np", "practitioner"];
export const PayorKeyWords = ["medicare", "medicaid"];
export const PharmacyKeyWords = ["drug", "store", "pbm"];
export const CustomMedicationKeyWords = ["drug", "medication", "pbm"];
export const DmeKeyWords = ["equipment", "hme", "durable", "medical"];
export const VendorKeyWords = [
    "contract",
    "contractors",
    "facility",
    "facilities",
    "nursing",
    "homes",
    "assisted",
    "living",
    "group",
    "hospital",
    "independent"
];
export const SuppliesKeyWords = ["medical", "wound", "supply"];
export const UserKeyWords = ["employee", "staff", "volunteer", "member"];
// TODO: Confirm team keywords
export const TeamKeyWords = ["team", "idg", "group", "care"];

export const CreateRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "inquiry"
    },
    {
        name: "create-inquiry",
        route: "inquiry",
        href: "create/inquiry",
        moduleId: PLATFORM.moduleName("./create-inquiry/create-inquiry", "create-inquiry"),
        nav: true,
        title: "Add Inquiry",
        settings: {
            menuName: "Inquiry",
            keywords: CommonKeywords.concat(InquiryKeywords),
            permission: addInquiryPermission
        }
    },
    {
        name: "create-interim-referral",
        route: "referral",
        moduleId: PLATFORM.moduleName("./create-interim-referral/create-interim-referral", "create-interim-referral"),
        href: "create/referral",
        nav: true,
        title: "Referral",
        settings: {
            menuName: "Referral",
            keywords: CommonKeywords,
            permission: addReferralPermission
        }
    },
    {
        name: "create-referral",
        route: "hospice-referral/:id?",
        moduleId: PLATFORM.moduleName("./create-referral/create-referral", "create-referral"),
        href: "create/hospice-referral",
        nav: false,
        settings: {
            permission: addReferralPermission
        }
    },
    {
        name: "create-palliative-referral",
        route: "palliative-care-referral/:id?",
        moduleId: PLATFORM.moduleName(
            "./create-palliative-referral/create-palliative-referral",
            "create-palliative-referral"
        ),
        href: "create/palliative-care-referral",
        nav: false,
        settings: {
            permission: addReferralPermission
        }
    },
    {
        name: "create-physician",
        route: "physician",
        href: "create/physician",
        moduleId: PLATFORM.moduleName("./create-physician/create-physician", "create-physician"),
        nav: true,
        title: "Add Physician",
        settings: {
            menuName: "Physician",
            keywords: CommonKeywords.concat(PhysicianKeyWords),
            permission: addPhysicianPermission
        }
    },
    {
        name: "create-payer",
        route: "payer",
        href: "create/payer",
        moduleId: PLATFORM.moduleName("./create-payor/create-payor", "create-payor"),
        nav: true,
        title: "Add Insurance/Payer",
        settings: {
            menuName: "Insurance/Payer",
            keywords: CommonKeywords.concat(PayorKeyWords),
            permission: addInsurancePayorPermission
        }
    },
    {
        name: "create-pharmacy",
        route: "pharmacy",
        href: "create/pharmacy",
        moduleId: PLATFORM.moduleName("./create-pharmacy/create-pharmacy", "create-pharmacy"),
        nav: true,
        title: "Add Pharmacy",
        settings: {
            menuName: "Pharmacy",
            keywords: CommonKeywords.concat(CustomMedicationKeyWords),
            permission: addPharmacyPermission
        }
    },
    {
        name: "create-dme",
        route: "dme",
        href: "create/dme",
        moduleId: PLATFORM.moduleName("./create-dme/create-dme", "create-dme"),
        nav: true,
        title: "Create DME",
        settings: {
            menuName: "DME",
            keywords: CommonKeywords.concat(DmeKeyWords),
            permission: addDmePermission
        }
    },
    {
        name: "create-vendor",
        route: "vendor",
        href: "create/vendor",
        moduleId: PLATFORM.moduleName("./create-vendor/create-vendor", "create-vendor"),
        nav: true,
        title: "Add Vendor",
        settings: {
            menuName: "Vendor",
            keywords: CommonKeywords.concat(VendorKeyWords),
            permission: addVendorPermission
        }
    },
    {
        name: "create-supplies",
        route: "supplies",
        href: "create/supplies",
        moduleId: PLATFORM.moduleName("./create-supplies/create-supplies", "create-supplies"),
        nav: true,
        title: "Add Supplies",
        settings: {
            menuName: "Supplies",
            keywords: CommonKeywords.concat(SuppliesKeyWords),
            permission: addSupplyPermission
        }
    },
    {
        name: "create-user",
        route: "user",
        href: "create/user",
        moduleId: PLATFORM.moduleName("./create-user/create-user", "create-user"),
        nav: true,
        title: "Add User",
        settings: {
            menuName: "User",
            keywords: CommonKeywords.concat(UserKeyWords),
            permission: addUserPermission,
            checkAll: true
        }
    },
    {
        name: "create-team",
        route: "team",
        href: "create/team",
        moduleId: PLATFORM.moduleName("./create-team/create-team", "create-team"),
        nav: true,
        title: "Add Team",
        settings: {
            menuName: "Team",
            keywords: CommonKeywords.concat(TeamKeyWords),
            permission: addTeamsPermission
        }
    },
    {
        name: "create-non-patient-activities",
        route: "non-patient-activities",
        href: "create/non-patient-activities",
        moduleId: PLATFORM.moduleName(
            "./create-non-patient-activity/create-non-patient-activity",
            "create-non-patient-activity"
        ),
        nav: true,
        title: "Add Non-Patient Activities",
        settings: {
            menuName: "Non-Patient Activities",
            keywords: CommonKeywords.concat(["non-patient activity", "non-patient activities"]),
            permission: addNonPatientActivityPermission
        }
    },
    {
        name: "create-volunteer-activity",
        route: "volunteer-activity",
        href: "create/volunteer-activity",
        moduleId: PLATFORM.moduleName(
            "./create-volunteer-activity/create-volunteer-activity",
            "create-volunteer-activity"
        ),
        nav: true,
        title: "Add Volunteer Activity",
        settings: {
            menuName: "Volunteer Activity",
            permission: addVolunteerActivityPermission,
            isPalliativeCare: false
        }
    }
];
