import { HttpClient } from "aurelia-fetch-client";
import { autoinject } from "aurelia-framework";

import { config } from "../common/config";
import { NotAuthenticatedException } from "../common/exceptions/not-authenticated-exception";
import { AccountHeaderHandler } from "../common/http-handlers/account-header-handler";
import { AuthTokenHandler } from "../common/http-handlers/auth-token-handler";
import { BadRequestHandler } from "../common/http-handlers/bad-request-handler";
import { ContentTypeHandler } from "../common/http-handlers/content-type-handler";
import { ServerErrorHandler } from "../common/http-handlers/server-error-handler";
import { TimeZoneHandler } from "../common/http-handlers/time-zone-handler";
import { UnauthorizedErrorHandler } from "../common/http-handlers/unauthorized-error-handler";
import { VerifyVersionHandler } from "../common/http-handlers/verify-version-handler";
import Infrastructure from "../common/infrastructure";
import { IRequestHandler, IResponseHandler } from "../interfaces/i-handlers";
import { AuthService } from "./auth-service";

@autoinject()
export class HttpService {
    private _http: HttpClient;
    private _authService: AuthService;
    private _requestHandlers: IRequestHandler[] = [];
    private _responseHandlers: IResponseHandler[] = [];

    public constructor(fetchClient: HttpClient,
        authService: AuthService,
        authHandler: AuthTokenHandler,
        timeZoneHandler: TimeZoneHandler,
        accountHeaderHandler: AccountHeaderHandler,
        contentTypeHandler: ContentTypeHandler,
        verifyVersionHandler: VerifyVersionHandler,
        serverErrorHandler: ServerErrorHandler,
        unauthorizedErrorHandler: UnauthorizedErrorHandler,
        badRequestHandler: BadRequestHandler) {

        this._http = fetchClient;
        this._authService = authService;

        // register request handlers
        this._requestHandlers.push(authHandler);
        this._requestHandlers.push(accountHeaderHandler);
        this._requestHandlers.push(timeZoneHandler);
        this._requestHandlers.push(contentTypeHandler);

        // register response handlers
        this._responseHandlers.push(verifyVersionHandler);
        this._responseHandlers.push(serverErrorHandler);
        this._responseHandlers.push(badRequestHandler);
        this._responseHandlers.push(unauthorizedErrorHandler);
    }

    public configureFetch() {
        this._http.configure(httpConfig => {
            httpConfig
                .withDefaults({
                    headers: {
                        "X-Hospice-Client-Version": Infrastructure.releaseVersion
                    }
                })
                .withInterceptor({
                    request: async (request: Request): Promise<Request> => {
                        let isLoggedIn = await this._authService.isLoggedIn();

                        if (isLoggedIn) {
                            for (let handler of this._requestHandlers) {
                                await handler.handle(request);
                            }
                            return request;
                        } else {
                            throw new NotAuthenticatedException();
                        }
                    },
                    response: async (response: Response) => {
                        if (!response.url.includes(config.nalaApiUrl) && !response.url.includes(config.mushuApiUrl)) {
                            for (let handler of this._responseHandlers) {
                                await handler.handle(response);
                            }
                        }
                        return response;
                    }
                });
        });
    }
}
