import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    observable,
} from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IOption } from "../../../../interfaces/form-builder/i-element";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IValidation } from "../../../../interfaces/form-builder/i-validation";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { NoteDataManager } from "../../note-data-manager";

@customElement("single-select-dropdown")
@containerless
@autoinject
export class SingleSelectDropDown {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    @observable({
        changeHandler: nameof<SingleSelectDropDown>("typeaheadResultChanged")
    })
    public typeaheadResult: ITypeaheadOptions;
    public noteDataManager: NoteDataManager;
    public isValidationLoading: boolean = true;
    public validation: IValidateCustomElement;

    @computedFrom(
        `${nameof<SingleSelectDropDown>("viewOptions")}.${nameof<IInputOptions>("options")}`
    )
    public get typeaheadOptions(): ITypeaheadOptions[] {
        let result: ITypeaheadOptions[] = [];
        if (this.viewOptions) {
            let options = this.viewOptions.options;
            if (options && options.length > 0) {
                result = options.map((option: IOption) => ({
                    name: option.label,
                    value: option.value
                }));
            }
        }
        return result;
    }
    public set typeaheadOptions(data: ITypeaheadOptions[]) {
        // Dummy setter for 2 way binding property
    }

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.initValidation();
    }

    public initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IValidation) => {
            if (rule.validationType === FormValidationEnum.Required) {
                this.validation = {
                    required: true,
                    displayName: this.viewOptions.name,
                    message: rule.message
                };
            }
        });
        this.isValidationLoading = false;
    }

    public typeaheadResultChanged(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions) {
        if (newValue || oldValue) {
            if (newValue) {
                this.result = newValue.value;
            } else {
                this.result = "";
            }
        }
    }

    public resultChanged(newValue: any) {
        if (!newValue) {
            return;
        }
        let resultOption = this.typeaheadOptions?.find((opt) => opt.value == this.result);
        if (resultOption) {
            this.typeaheadResult = resultOption;
        }
    }

}
