import "./form-image.scss";

import { autoinject, bindable, bindingMode, computedFrom } from "aurelia-framework";

import nameof from "../../common/nameof";
import { IText } from "../../interfaces/form-builder/i-element";
import {getColumnClasses} from "../../common/utilities/column-classes";

@autoinject
export class FormText {
    @bindable({ defaultBindingMode: bindingMode.toView})
    public element: IText;

    @computedFrom(nameof<FormText>("element"))
    public get columnsClasses(): string {
        let columns = this.element.columns;
        return getColumnClasses(columns);
    }

    public async activate(params: IText) {
        Object.assign(this, params);
    }
}
