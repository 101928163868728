import { autoinject } from "aurelia-framework";
import { IReadOnlyIfBehavior } from "./../../../interfaces/form-builder/i-behavior";
import { NoteDataManager } from "../note-data-manager";
import { CurrentUserService } from "../../../services/current-user-service";
import { FormModelType } from "../../../interfaces/form-builder/i-model-schema";

@autoinject
export class ReadOnlyIfBehaviorHandler {
    private _currentUserService: CurrentUserService;

    public constructor(currentUserService: CurrentUserService, noteDataManager: NoteDataManager) {
        this._currentUserService = currentUserService;
    }

    public async handle(
        behavior: IReadOnlyIfBehavior,
        noteDataManager: NoteDataManager,
        dependencyInfo?: { elementName: string; value: FormModelType }
    ) {
        if (!behavior) {
            return false;
        }
        let isReadOnly = false;
        if (!behavior.component) {
            // No Component indicates simple check of if value in Target name is same as value passed in
            let value = noteDataManager.getValue(behavior.targetName) || false;
            if (dependencyInfo) {
                value = dependencyInfo.value;
            }
            isReadOnly = behavior.values.includes(value.toString());
            // If value in Target name is empty then set isReadOnly to true;
            if (!value) {
                isReadOnly = true;
            }
        }
        // TODO: Restructure and define Enums for the Component and Sub-component when more cases arise
        if (behavior.component === "user" && behavior.subComponent === "id") {
            let currentUser = await this._currentUserService.getUser();
            isReadOnly = noteDataManager.getValue(behavior?.targetName) === currentUser.id;
        }
        // Presence of component indicates check of the value in target name to be equal to the value associated with an entity
        return behavior.isInverted ? !isReadOnly : isReadOnly;
    }
}
