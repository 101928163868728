import { DialogOpenResult, DialogService, DialogSettings } from "aurelia-dialog";
import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";

import { IResponseHandler } from "../../interfaces/i-handlers";
import { IPromptOptions, Prompt } from "../../resources/dialogs/prompt/prompt";
import Infrastructure from "../infrastructure";

@autoinject()
export class VerifyVersionHandler implements IResponseHandler {
    private static _lastReloadPrompt: Date;
    private _dialogService: DialogService;
    private _ea: EventAggregator;

    public constructor(dialogService: DialogService, ea: EventAggregator) {
        this._dialogService = dialogService;
        this._ea = ea;
    }

    public async handle(response: Response): Promise<void> {
        let releaseVersionHeader = response.headers.get("x-hospice-releaseversion");
        let cacheControlHeader = response.headers.get("Cache-Control");
        let lastTimeAsked = VerifyVersionHandler._lastReloadPrompt ? (new Date().getTime() - VerifyVersionHandler._lastReloadPrompt.getTime()) / 1000 : 3600;

        if (!releaseVersionHeader
            || releaseVersionHeader == Infrastructure.releaseVersion
            || cacheControlHeader // let the client/browser handle cache invalidation
            || Infrastructure.releaseEnvironment == "Local"
            || lastTimeAsked < 60) {
            return;
        }

        VerifyVersionHandler._lastReloadPrompt = new Date();

        let dialogOptions: DialogSettings = {
            viewModel: Prompt,
            model: {
                okText: "Reload! (might lose any unsaved work)",
                cancelText: "Don't reload",
                message: "There is a new version of this software available. We recommend reloading the software."
            } as IPromptOptions
        };

        let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
        let closeResult = await dialog.closeResult;

        if (!closeResult.wasCancelled) {
            location.reload();
        }
    }
}
