import {bindable, customAttribute, inject} from "aurelia-framework";

import {NumberOnly} from "../number-only/number-only";

@customAttribute("currency-format")
@inject(Element)
export class CurrencyFormat {
    @bindable
    public allowNegative: boolean = false;
    @bindable
    public decimals: number = 2;
    private _element: JQuery;
    private _numberOnlyAttribute: NumberOnly;

    public constructor(el: Element) {
        this._element = $(el) as JQuery;
        this._numberOnlyAttribute = new NumberOnly(el);
    }

    public attached(): void {
        this.formatInput();
        this._element
            .on("blur", (evt: JQueryEventObject) => {
                this.formatInput();
            })
            .on("focus", (evt: JQueryEventObject) => {
                this.removeFormatting();
            })
            .on("keydown", (evt: JQueryEventObject) => {
                if (this.decimals > 0 && this.allowNegative) {
                    return this._numberOnlyAttribute.checkNumberOrHyphen(evt);
                } else if (this.decimals > 0) {
                    return this._numberOnlyAttribute.checkIfEnteredKeyIsDecimal(evt);
                } else {
                    return this._numberOnlyAttribute.checkIfEnteredKeyIsNumber(evt);
                }
            });
    }

    public formatInput() {
        let value = parseFloat(this._element.val() as string);
        let isNegative = value < 0;
        value = Math.abs(value);
        if (!isNaN(value)) {
            let formattedString = value.toLocaleString([], {
                maximumFractionDigits: this.decimals,
                minimumFractionDigits: this.decimals
            });
            this._element.val(`${isNegative ? "-" : ""}$${formattedString}`);
        }
    }

    public removeFormatting() {
        let value = this._element.val() as string;
        let newVal = value.replace(/\,|\$/g, "");
        let isValid = newVal && !isNaN(parseFloat(newVal)) && parseFloat(newVal) !== 0;
        this._element.val(isValid ? parseFloat(newVal) : null);
    }
}
