/***************************************************************
 * *
 * Custom Built Phone Number Component for Hospice, Axxess
 * *
 ***************************************************************/
import "./phone-number.scss";

import { autoinject, bindable, bindingMode, computedFrom, observable } from "aurelia-framework";
import { customElement } from "aurelia-templating";
import { ValidationRules } from "aurelia-validation";

import nameof from "../../../common/nameof";
import { PhoneTypeEnum } from "../../../enums/phone-type-enum";
import { IPhoneNumberOption } from "../../../interfaces/i-phone-number-option";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { IGetCountryPhoneCode } from "../../../interfaces/lookup/i-country-phone-code";
import { LookupService } from "../../../services/lookup-service";

@autoinject
@customElement("phone-number")
export class PhoneNumber {
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public enableCountry: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public phoneTypeOptions: PhoneTypeEnum[] = [PhoneTypeEnum.Home, PhoneTypeEnum.Mobile, PhoneTypeEnum.Facility];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public options: IPhoneNumberOption[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<PhoneNumber>("countryChanged") })
    public country: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public value: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<PhoneNumber>("typeChanged") })
    public type: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public extension: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public disableExtension: boolean;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public validation: IValidateCustomElement = {
        required: false
    };
    @bindable({ defaultBindingMode: bindingMode.toView })
    public placeholder: string = "Enter Phone Number";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideExtension: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hidePhoneType: boolean = false;
    private readonly _lookupService: LookupService;
    private _extensionCache: string;
    private _isCountryListLoadInProgress: boolean = false;
    public defaultValidationMessage: string = "Invalid Phone Number";
    public unitedStatesCountryCode: string;
    public countryList: IGetCountryPhoneCode[] = [];
    public selectedCountry: IGetCountryPhoneCode;

    @computedFrom(
        nameof<PhoneNumber>("enableCountry"),
        nameof<PhoneNumber>("country"),
        nameof<PhoneNumber>("unitedStatesCountryCode")
    )
    public get isUnitedStates() {
        return this.enableCountry && this.country && this.country === this.unitedStatesCountryCode;
    }

    @computedFrom(nameof<PhoneNumber>("options"))
    public get singleOption() {
        return this.options?.length === 1;
    }

    @computedFrom(nameof<PhoneNumber>("options"))
    public get showExtension() {
        if (!this.singleOption) {
            return true;
        }
        return this.options[0]?.hasExtension;
    }

    public constructor(lookupService: LookupService) {
        this._lookupService = lookupService;
    }

    public async bind() {
        this.initValidation();
        this.unitedStatesCountryCode = await this._lookupService.getUnitedStatesCode();
        let phoneTypes = await this._lookupService.getPhoneTypeOptions();
        if (!!this.phoneTypeOptions?.length && phoneTypes?.length) {
            this.options = phoneTypes.filter((op) => {
                return this.phoneTypeOptions.includes(op.name);
            });
        }

        if (!!this.type) {
            this.typeChanged();
        }

        if (this.countryList?.length <= 0 && !this._isCountryListLoadInProgress) {
            await this.getCountryList();
        }

        if (!this.country) {
            this.country = this.unitedStatesCountryCode;
        }

        this.selectedCountry = this.countryList.find((country) => {
            return country.value === this.country;
        });
    }

    public async countryChanged() {
        if (!!this.country) {
            if (this.countryList?.length <= 0 && !this._isCountryListLoadInProgress) {
                await this.getCountryList();
            }

            this.selectedCountry = this.countryList.find((country) => {
                return country.value === this.country;
            });
        } else {
            this.country = this.unitedStatesCountryCode;
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: PhoneNumber) => x.value)
            .displayName(this.validation.displayName)
            .required()
            .when((phoneNumber: PhoneNumber) => phoneNumber.validation.required)
            .withMessage(`\${$displayName} is required.`)
            .matches(/^\d{10}$/)
            .when((phoneNumber: PhoneNumber) => phoneNumber.validation.matches)
            .withMessage("Invalid Phone Number")
            .on(this);
    }

    public async getCountryList() {
        try {
            this._isCountryListLoadInProgress = true;
            this.countryList = await this._lookupService.getCountryPhoneCodes();
        } catch (e) {
            console.error(e);
        } finally {
            this._isCountryListLoadInProgress = false;
        }
    }

    public typeChanged() {
        if (this.options?.length > 0) {
            let selectedOption = this.getOption(this.type);
            this.disableExtension = selectedOption ? !selectedOption.hasExtension : true;
            let shouldLoadToCache = !this.disableExtension && this.extension != "";
            this._extensionCache = shouldLoadToCache ? this.extension : this._extensionCache;
            this.extension = this.disableExtension ? "" : this._extensionCache;
        }
    }

    private getOption(val: number) {
        return this.options.find((option: IPhoneNumberOption) => option.value === val);
    }

    public handleCountrySelection(selectedCountry: IGetCountryPhoneCode) {
        this.selectedCountry = Object.assign({}, selectedCountry);
        this.country = selectedCountry?.value;
    }
}
