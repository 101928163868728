import { autoinject } from "aurelia-framework";
import { HospiceClient } from "../common/hospice-client";
import { IEditTemplateQuery, IGetTemplateQuery, IGetTemplateResult } from "../interfaces/i-get-template";
import { ICreateResponse } from "../interfaces/i-response";
import { Template } from "../models/template";

@autoinject()
export class TemplateService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/templates";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createTemplate(NewTemplate: Template) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(NewTemplate)
            .fetch<ICreateResponse>();
    }

    public async getTemplateByPage(query: IGetTemplateQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useQueryString(query)
            .fetch<IGetTemplateResult>();
    }

    public async getTemplateById(templateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${templateId}`)
            .fetch<Template>();
    }

    public async editTemplate(query: IEditTemplateQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async deleteTemplate(templateId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${templateId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

}
