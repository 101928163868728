export enum ReportPermissionEnum {
    Patient = "patient reports",
    Survey = "survey reports",
    People = "people reports",
    BillingFinancial = "billing and financial reports",
    Statistical = "statistical reports",
    Clinical = "clinical reports",
    Payroll = "payroll report",
    Schedule = "schedule reports"
}
