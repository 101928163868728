export enum FormValidationEnum {
    Required = "required",
    RequiredArray = "required-array",
    Regex = "regex",
    MaxLength = "maxtextlength",
    // current input required if target input has certain values
    RequiredIf = "requiredif",
    RequiredAll = "requiredall",
    // can be any of the above validations on current input,
    // but it will be applied based on a condition on a target input (conditions are defined in form-condition-validation-enum)
    // if condition true for target, apply validation to current input
    // example: if target input is not empty, make current input required
    Conditional = "conditional"
}
