import { autoinject, bindable, customElement, inject } from "aurelia-framework";
import "custom-event-polyfill";
import "daterangepicker";
import { IDateRangePickerOptions } from "../../../interfaces/i-date-range-picker";
import "./../../../../node_modules/daterangepicker/daterangepicker.css";

// custom-event-polyfill is used for custom events to work in IE9/10/11.
// As of 03/23/2018, Axxess products support IE11
// https://www.npmjs.com/package/custom-event-polyfill

@inject(Element)
@customElement("date-range-picker")
@autoinject
export class DateRangePicker {
    public pickerFields: JQuery;
    public dateLimit: number = null;
    private _dateRangePicker: JQuery;
    private _self: HTMLElement;
    @bindable
    public options: IDateRangePickerOptions;

    public constructor(element: Element) {
        this._self = element as HTMLElement;
        this.pickerFields = $(this._self).find("input,div.input-group.date,div.input-group.input-daterange");
    }

    public attached() {
        if (this.pickerFields == null || this.pickerFields.length != 1) {
            console.warn(
                "The date-range-picker custom element must contain one child of type input[type=text]. See http://www.daterangepicker.com/."
            );
            console.info("length", this.pickerFields.length);
            console.info("array", this.pickerFields);
        }
        this.initDateRangePicker();
    }

    public optionsChanged() {
        this.initDateRangePicker();
    }

    public initDateRangePicker() {
        let options = {
            autoUpdateInput: false,
            locale: {
                cancelLabel: "Clear"
            }
        };
        if (this.options != null) {
            options = Object.assign(options, this.options);
        }
        this._dateRangePicker = (jQuery(this.pickerFields) as any).daterangepicker(options);
        this._dateRangePicker.on("apply.daterangepicker", (event, picker) => {
            jQuery(this.pickerFields).val(
                picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY")
            );
            let changeEvent = new CustomEvent("change", {
                detail: {},
                bubbles: true
            });

            // Target = input(s), input(s) was modified outside of dom events, need to notify the binder.
            // Unfortunately, we must find the inputs here since the target is not always the input.
            let inputs = jQuery("input", event.target.parentElement);
            for (let input of inputs.toArray()) {
                input.dispatchEvent(changeEvent);
            }

            // Anyone listening on the custom element.
            this._self.dispatchEvent(changeEvent);
        });
        this._dateRangePicker.on("cancel.daterangepicker", (ev, picker) => {
            jQuery(this.pickerFields).val("");
            let changeEvent = new CustomEvent("change", {
                detail: {},
                bubbles: true
            });
            let inputs = jQuery("input", ev.target.parentElement);
            for (let input of inputs.toArray()) {
                input.dispatchEvent(changeEvent);
            }
            this._self.dispatchEvent(changeEvent);

            // Calling this method resets the calender ui used for date range picker
            this.initDateRangePicker();
        });
    }
}
