import { valueConverter } from "aurelia-binding";

@valueConverter("permissionAction")
export class PermissionAction {
    public toView(value: string) {
        switch (value.toLocaleLowerCase()) {
            case "access":
                return 1;
            case "add":
                return 2;
            case "edit":
                return 3;
            case "delete":
                return 4;
            case "reassign":
                return 5;
            case "export":
                return 6;
            case "submission":
                return 7;
            default:
                return 0;
        }
    }
}