import { autoinject } from "aurelia-framework";
import * as moment from "moment";

import { formatName } from "../../../common/utilities/format-name";
import { IPrefillBehavior } from "../../../interfaces/form-builder/i-behavior";
import { CurrentUserService } from "../../../services/current-user-service";

export enum PrefillBehaviorResourceEnum {
    LoggedInUserId = "loggedinuserid",
    LoggedInUserName = "loggedinusername",
    Today = "today",
    ListIndex = "listIndex"
}

@autoinject
export class PrefillBehaviorHandler {
    private _currentUserService: CurrentUserService;

    public constructor(currentUserService: CurrentUserService) {
        this._currentUserService = currentUserService;
    }

    public async handle(behavior: IPrefillBehavior, inputName: string) {
        if (!!behavior.value) {
            return behavior.value;
        }

        if (!!behavior.resource) {
            switch (behavior.resource) {
                case PrefillBehaviorResourceEnum.LoggedInUserId:
                    return await this.getLoggedInUserId();
                case PrefillBehaviorResourceEnum.LoggedInUserName:
                    return await this.getLoggedInUserName();
                case PrefillBehaviorResourceEnum.Today:
                    return Promise.resolve(moment().format("MM/DD/YYYY"));
                case PrefillBehaviorResourceEnum.ListIndex:
                    let propSplit = inputName.split(".");
                    if (propSplit.length > 2) {
                        return Promise.resolve(String(parseInt(propSplit[1], 10) + 1));
                    }
            }
        }

        return Promise.resolve("");
    }

    private async getLoggedInUserName() {
        let userInfo = await this._currentUserService.getUser();
        return formatName(userInfo.firstName, userInfo.lastName);
    }

    private async getLoggedInUserId() {
        let userInfo = await this._currentUserService.getUser();
        return userInfo.id;
    }
}
