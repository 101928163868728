/******************************************************************************
 * *
 * Permission Toggle
 * Usage: Wrapper enclosing the feature should have if.bind to hasPermission
 * checkRoute: Default - True
 * Usage: True to check for Routes, False to check for permissions
 * *
 ********************************************************************************/
import { autoinject, bindable, bindingMode, containerless, customElement, inlineView } from "aurelia-framework";
import { RouteConfig } from "aurelia-router";

import { isPageProdReady } from "../../../common/utilities/is-route-allowed";
import { IPermissionCheck, PermissionManager } from "../../../common/utilities/permission-manager";

@customElement("permission-toggle")
@inlineView("<template><slot></slot></template>")
@containerless
@autoinject
export class PermissionToggle {
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public hasPermission: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public checkRoute: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public routes: RouteConfig[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public routeName: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public permission: IPermissionCheck[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public checkAll: boolean = false;
    private _permissionManager: PermissionManager;
    public element: Element;

    public constructor(permissionManager: PermissionManager) {
        this._permissionManager = permissionManager;
    }

    public attached() {
        if (this.checkRoute) {
            let selectedRoute = this.routes.find(route => route.name === this.routeName);
            let isProdReady = isPageProdReady(selectedRoute?.settings);
            this.hasPermission = isProdReady
                && this._permissionManager.checkRoutePermission(selectedRoute);
        } else {
            this.hasPermission = this._permissionManager.checkPermission(this.permission, this.checkAll);
        }
    }

}
