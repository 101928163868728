import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    IGetNonAdmitReasonsQuery,
    IGetNonAdmitReasons,
    ISaveNonAdmitReason,
    IGetNonAdmitReasonsSlim
} from "./i-non-admit-reasons-service";

@autoinject()
export class NonAdmitReasonsService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/non-admit-reasons";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getNonAdmitReasons(query: IGetNonAdmitReasonsQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetNonAdmitReasons>();
    }

    public async deleteNonAdmitReason(reasonId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${reasonId}`).useMethod("DELETE").fetchNoContent();
    }

    public async updateNonAdmitReason(query: ISaveNonAdmitReason, reasonId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${reasonId}`).useMethod("PUT").useBody(query).fetch();
    }

    public async createNonAdmitReason(query: ISaveNonAdmitReason) {
        return await this._httpClient.build(`${this._baseUrl}`).useMethod("POST").useBody(query).fetch();
    }

    public async getNonAdmitReasonsSlim(providerIds: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim`)
            .useMethod("POST")
            .useBody({ providerIds })
            .fetch<IGetNonAdmitReasonsSlim[]>();
    }
}
