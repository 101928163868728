import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, singleton } from "aurelia-framework";
import { IIdgNoteSignalrResponse } from "../interfaces/i-idg-note-signalr";
import { IJoinMeetingSignalrResponse } from "../interfaces/i-meeting-signalr";
import { IReportSignalrResponse } from "../interfaces/reports/i-report-notification";

export interface INotificationResponse {
    message: string;
}

export const enum SignalrEvent {
    NewMessage = "message:new",
    MeetingStarted = "meeting:started",
    MeetingEnded = "meeting:ended",
    JoinMeeting = "meeting:userJoined",
    IdgNoteReviewed = "idgnote:reviewed",
    IdgNoteUnreviewed = "idgnote:unreviewed",
    ReportNotification = "report:notification",
    TaskDetailsUpdated = "taskdetails:updated",
}

const enum SignalrServerEvent {
    Notify = "Notify",
    StartMeeting = "StartMeeting",
    JoinMeeting = "JoinMeeting",
    EndMeeting = "EndMeeting",
    IdgNoteReviewed = "IdgNoteReviewed",
    IdgNoteUnreviewed = "IdgNoteUnreviewed",
    ReportNotification = "NotifyExportGeneratedAsync",
    TaskDetailsUpdated = "TaskDetailsUpdated"
}

@autoinject()
@singleton()
export class HubsContainer {
    private _eventAggregator: EventAggregator;
    public hubs: Map<string, Map<string, () => void>> = new Map<string, Map<string, () => void>>();

    public constructor(ea: EventAggregator) {
        this._eventAggregator = ea;
        this.hubs.set("NotificationHub", this.createNotificationHub());
        // TODO: Decide on Hubs Architecture
        // this.hubs.set("MessageHub", this.createMessageHub());
    }

    /* public createMessageHub() {

        let messageHub = new Map<string, (...args: string[]) => void>([
            ["messageReceived", (conversationId: string): void => {
                this._EventAggregator.publish("message:received", { conversationId });
            }]
        ]);

        return messageHub;
    } */

    public createNotificationHub() {

        // TODO: Hubs separation will get rid of the any usage here
        let notificationHub = new Map<string, (...args: any) => void>([
            [
                SignalrServerEvent.Notify,
                (message: INotificationResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.NewMessage, { message });
                }
            ],
            [
                SignalrServerEvent.StartMeeting,
                (message: INotificationResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.MeetingStarted, { message });
                }
            ],
            [
                SignalrServerEvent.JoinMeeting,
                (message: IJoinMeetingSignalrResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.JoinMeeting, message);
                }
            ],
            [
                SignalrServerEvent.EndMeeting,
                (message: INotificationResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.MeetingEnded, { meetingId: message });
                }
            ],
            [
                SignalrServerEvent.IdgNoteReviewed,
                (message: IIdgNoteSignalrResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.IdgNoteReviewed, message);
                }
            ],
            [
                SignalrServerEvent.IdgNoteUnreviewed,
                (message: IIdgNoteSignalrResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.IdgNoteUnreviewed, message);
                }
            ],
            [
                SignalrServerEvent.ReportNotification,
                (message: IReportSignalrResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.ReportNotification, message);
                }
            ],
            [
                SignalrServerEvent.TaskDetailsUpdated, (message: INotificationResponse): void => {
                    this._eventAggregator.publish(SignalrEvent.TaskDetailsUpdated, { message });
                }
            ]
        ]);
        return notificationHub;
    }
}
