import { EventAggregator } from "aurelia-event-aggregator";
import { PLATFORM, useView } from "aurelia-framework";
import { Router } from "aurelia-router";

import { OidcSilentRenewCallback } from "../../common/oidc/oidc-event-handler";

@useView(PLATFORM.moduleName("./sign-in-view.html"))
export class SignInSilentPage {
    public _ea: EventAggregator;
    private _router: Router;

    public constructor(ea: EventAggregator, router: Router) {
        this._ea = ea;
        this._router = router;
    }

    public async activate() {
        let location = window.location.toString();
        console.log(`Publishing silent sign-in using location:`, location);
        this._ea.publish(OidcSilentRenewCallback, location);
    }
}
