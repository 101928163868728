import "./patient-info-header.scss";

import { icon } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { computedFrom } from "aurelia-binding";
import { DialogOpenResult, DialogService, DialogSettings } from "aurelia-dialog";
import { autoinject, bindable, bindingMode, containerless, observable, PLATFORM } from "aurelia-framework";
import { Router } from "aurelia-router";
import * as moment from "moment";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IPermissionCheck, PermissionManager } from "../../../common/utilities/permission-manager";
import { AdministrativePermissionEnum } from "../../../enums/administrative-permission-enum";
import { ChangePhotoEnum } from "../../../enums/change-photo-enum";
import { ClinicalPermissionEnum } from "../../../enums/clinical-permission-enum";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { IPhotoDialogOptions } from "../../../interfaces/i-change-photo";
import { IDisplayDiagnosis } from "../../../interfaces/i-diagnosis";
import { IEnumResponse } from "../../../interfaces/i-enum";
import {
    IGetPatientPhotoResult,
    IGetPatientsSlimInfoResult,
    IPatientDialogOptions
} from "../../../interfaces/i-get-patient";
import { addReferralPermission } from "../../../pages/create/create-route-permissions";
import { Upload } from "../../../pages/patients/patient-chart/upload/upload";
import { AccountsService } from "../../../services/accounts-service";
import { DeviceService } from "../../../services/device-service";
import { DownloadService } from "../../../services/download-service";
import { EnumsService } from "../../../services/enums-service";
import { PatientsService } from "../../../services/patient-service";
import { PrintService } from "../../../services/print-service";
import { ToastrService } from "../../../services/toastr-service";
import { PhotoCropper } from "./../photo-cropper/photo-cropper";
import { ProvidersService } from "../../../services/providers-service";
import { BranchesService } from "../../../services/branches-service";
import { IBranchResponse } from "../../../interfaces/i-branch";

PLATFORM.moduleName("../../../pages/patients/patient-chart/upload/upload");
PLATFORM.moduleName("../photo-cropper/photo-cropper");

export enum PatientHeaderSizeEnum {
    Large = "large",
    Medium = "medium",
    Small = "small"
}

@autoinject
@containerless
export class PatientInfoHeader {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<PatientInfoHeader>("patientInfoChanged") })
    public patientInfo: IGetPatientsSlimInfoResult;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<PatientInfoHeader>("openDocumentsChanged") })
    public openDocuments: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public reloadPatientData: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientModule: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public openChangeStatus: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isStatusChangeRequested: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showEditPatientLink: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isReadOnly: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public quickLinkClasses: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideButtons: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientStatusEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showCustomButton: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isCustomInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public customButtonLabel: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public customInProgressLabel: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public customButtonCallback: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<PatientInfoHeader>("displaySizeChanged") })
    public displaySize: string = PatientHeaderSizeEnum.Medium;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showPatientChart: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public openTransferForm: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isTransferRequested: boolean = false;
    private readonly _router: Router;
    private readonly _deviceService: DeviceService;
    private readonly _downloadService: DownloadService;
    private readonly _enumsService: EnumsService;
    private readonly _patientsService: PatientsService;
    private readonly _accountsService: AccountsService;
    private readonly _permissionManager: PermissionManager;
    private readonly _dialogService: DialogService;
    private readonly _toastrService: ToastrService;
    private readonly _printService: PrintService;
    private _genderEnums: IEnumResponse[] = [];
    private _levelsOfCareEnums: IEnumResponse[] = [];
    private _veteranEnum: IEnumResponse[] = [];
    private _levelOfCareEnumMap: EnumMap;
    private _veteranEnumMap: EnumMap;
    private _genderEnumMap: EnumMap;
    private _patientStatusEnumMap: EnumMap;
    private _providersService: ProvidersService;
    private _branchesService: BranchesService;
    private _advancedCarePlanDirectivesEnumMap: EnumMap = new EnumMap([]);
    private _codeStatusEnumMap: EnumMap = new EnumMap([]);
    @observable({ changeHandler: nameof<PatientInfoHeader>("userPicElementChanged") })
    public userPicElement: HTMLElement;
    public patientPhoto: IGetPatientPhotoResult;
    public terminalDiagnosis: IDisplayDiagnosis = null;
    public patientAddressLink: string = "";
    public patientFilterParams: string = "";
    public patientStatusLabel: string = "";
    public levelOfCareLabel: string = "";
    public veteranLabel: string = "";
    public genderLabel: string = "";
    public displayAddress: string = "";
    public terminalDiagTooltip: string = "";
    public medicaidLabel: string = null;
    public noPicIcon: string = icon(faUser).html[0];
    public isPrintInProgress: boolean = false;
    public isDownloadInProgress: boolean = false;
    public isConvertInProgress: boolean = false;
    public canShowEditPatient: boolean = false;
    public hasAddress: boolean = false;
    public showStatusChangeLink: boolean = false;
    public isPendingPatient: boolean = false;
    public canShowAddReferralButton: boolean = false;
    public canShowManageDocuments: boolean = false;
    public showMoreData: boolean = false;
    public isLargeDisplay: boolean = false;
    public isMediumDisplay: boolean = false;
    public isSmallDisplay: boolean = false;
    public hasAddReferralPermission: boolean = false;
    public addReferralPermission: IPermissionCheck[] = addReferralPermission;
    public age: number = 0;
    public canShowBenefitPeriodManager: boolean = false;
    public canChangeStatus: boolean = false;
    public isPalliativeCare: boolean = false;
    public isMobileTablet: boolean = false;
    public patientTags: string = "";
    public isTagsEnabled: boolean = false;
    public isDischarged: boolean = false;
    public patientBranch: IBranchResponse;
    public allergyCount: number = 0;
    public isDoNotResuscitate: boolean = false;
    public isDoNotIntubate: boolean = false;
    public isDoNotHospitalize: boolean = false;

    @computedFrom(nameof<PatientInfoHeader>("patientInfo"), nameof<PatientInfoHeader>("isPalliativeCare"))
    public get editPatientRoute() {
        if (this.isPalliativeCare) {
            return `/patients/palliative-patient-edit/${this.patientInfo.id}`;
        }
        return `/patients/patient-edit/${this.patientInfo.id}`;
    }

    public constructor(
        enumsService: EnumsService,
        router: Router,
        accountsService: AccountsService,
        permissionManager: PermissionManager,
        patientsService: PatientsService,
        dialogService: DialogService,
        toastrService: ToastrService,
        printService: PrintService,
        deviceService: DeviceService,
        downloadService: DownloadService,
        providersService: ProvidersService,
        branchesService: BranchesService
    ) {
        this._router = router;
        this._accountsService = accountsService;
        this._enumsService = enumsService;
        this._permissionManager = permissionManager;
        this._patientsService = patientsService;
        this._dialogService = dialogService;
        this._toastrService = toastrService;
        this._printService = printService;
        this._deviceService = deviceService;
        this._downloadService = downloadService;
        this._providersService = providersService;
        this._branchesService = branchesService;
    }

    public async bind() {
        this.isMobileTablet = this._deviceService.isMobileTablet() || this._deviceService.isSafariOnIOS();
        this.isPalliativeCare = await this._accountsService.getIsPalliativeAccount();
        const codeStatuses = await this._enumsService.codeStatus();
        this._codeStatusEnumMap = new EnumMap(codeStatuses);
        await this.initOptions();
        this.initPermissions();
        await this.patientInfoChanged();
        this.openDocumentsChanged();
        this.displaySizeChanged();
        this.initAdvancedCarePlanDirectives();
    }

    public attached() {
        this.setUserPic();
    }

    public async initAdvancedCarePlanDirectives() {
        [this.isDoNotResuscitate, this.isDoNotIntubate, this.isDoNotHospitalize] = [false, false, false];
        for (const directiveNumber of this.patientInfo.codeStatus) {
            const directiveName = (this._codeStatusEnumMap.getEnumName(directiveNumber) as string).toLocaleLowerCase();
            switch (directiveName) {
                case "do not resuscitate":
                    this.isDoNotResuscitate = true;
                    break;
                case "do not intubate":
                    this.isDoNotIntubate = true;
                    break;
                case "do not hospitalize":
                    this.isDoNotHospitalize = true;
                    break;
                default:
                    break;
            }
        }
    }

    private async initOptions() {
        if (!this.patientStatusEnum || this.patientStatusEnum?.length == 0) {
            this.patientStatusEnum = await this._enumsService.getPatientStatus();
        }
        this._patientStatusEnumMap = new EnumMap(this.patientStatusEnum);

        this._levelsOfCareEnums = await this._enumsService.getLevelOfCareFilter();
        this._levelOfCareEnumMap = new EnumMap(this._levelsOfCareEnums);

        this._veteranEnum = await this._enumsService.getVeteranOptions();
        this._veteranEnumMap = new EnumMap(this._veteranEnum);

        this._genderEnums = await this._enumsService.getGender();
        this._genderEnumMap = new EnumMap(this._genderEnums);
    }

    private initPermissions() {
        if (!this.isReadOnly) {
            this.canShowEditPatient = this._permissionManager.checkPermission([
                {
                    resource: ClinicalPermissionEnum.PatientChart,
                    action: PermissionActionEnum.Edit
                }
            ]);
            this.canShowManageDocuments = this._permissionManager.checkPermission([
                {
                    resource: ClinicalPermissionEnum.ManageDocuments,
                    action: PermissionActionEnum.View
                }
            ]);
            this.canShowBenefitPeriodManager = this._permissionManager.checkPermission([
                {
                    resource: ClinicalPermissionEnum.ManageBenefitPeriod,
                    action: PermissionActionEnum.View
                }
            ]);
            this.canChangeStatus = this._permissionManager.checkPermission([
                {
                    resource: AdministrativePermissionEnum.ChangePatientStatus,
                    action: PermissionActionEnum.Edit
                }
            ]);
        }
    }

    public async patientInfoChanged() {
        if (!!this.patientInfo) {
            this.initAdvancedCarePlanDirectives();
            this.hasAddress = !!this.patientInfo.addressLine1 && !!this.patientInfo.city && !!this.patientInfo.state;
            let zipCode = this.patientInfo.zipCode?.slice(0, 5);
            if (this.hasAddress) {
                if (this.patientInfo.addressLine2) {
                    this.patientAddressLink = `https://www.google.com/maps/place/${
                        this.patientInfo.addressLine1
                    },${encodeURIComponent(this.patientInfo.addressLine2)},${this.patientInfo.city},${
                        this.patientInfo.state
                    }, ${zipCode}`;
                    this.displayAddress = `${this.patientInfo.addressLine1}, ${this.patientInfo.addressLine2}, ${this.patientInfo.city}, ${this.patientInfo.state}, ${zipCode}`;
                } else {
                    this.patientAddressLink = `https://www.google.com/maps/place/${this.patientInfo.addressLine1},${this.patientInfo.city},${this.patientInfo.state}, ${zipCode}`;
                    this.displayAddress = `${this.patientInfo.addressLine1}, ${this.patientInfo.city}, ${this.patientInfo.state}, ${zipCode}`;
                }
            }

            if (this.patientInfo.status) {
                this.patientFilterParams = `status=${this.patientInfo.status}`;
            }

            this.patientStatusLabel = this._patientStatusEnumMap?.getEnumName(this.patientInfo.status).toString();
            this.levelOfCareLabel = this._levelOfCareEnumMap?.getEnumName(this.patientInfo.levelOfCare).toString();
            this.veteranLabel = this.patientInfo.veterans?.length > 0 ? this.getVeteranLabel() : "";
            this.genderLabel = this._genderEnumMap?.getEnumName(this.patientInfo.gender).toString();
            this.isPendingPatient = this.patientStatusLabel.toLocaleLowerCase() === "pending";
            this.canShowAddReferralButton =
                this.patientStatusLabel.toLocaleLowerCase() === "discharged" ||
                this.patientStatusLabel.toLocaleLowerCase() === "non-admitted";
            this.patientTags = this.patientInfo.patientTags?.map((tag) => tag.name).join(", ");
            this.calculateAge();
            this.initShowStatusChangeLink();
            this.initMedicaidLabel();
            this.initTerminalDiagnosis();
            await this.loadPatientPhoto();
            this.setUserPic();
            this.isDischarged =
                this.patientStatusLabel.toLocaleLowerCase() === "discharged" ||
                this.patientStatusLabel.toLocaleLowerCase() === "deceased";
            let allBranches = await this._branchesService.getAllBranches();
            this.patientBranch = allBranches.find((branch) => branch.id === this.patientInfo?.branchId);
            await this.getIsTagsEnabled();
            this.allergyCount = this.patientInfo?.allergies?.filter((allergy) => !allergy.endDate).length;
        }
    }

    public getVeteranLabel() {
        let veteranValue = this.patientInfo.veterans?.map((item) => this._veteranEnumMap?.getEnumName(item));
        return veteranValue.join(", ");
    }

    public openDocumentsChanged() {
        if (this.openDocuments) {
            this.uploadDocument();
        }
    }

    public displaySizeChanged() {
        this.isLargeDisplay = this.displaySize === PatientHeaderSizeEnum.Large;
        this.isMediumDisplay = this.displaySize === PatientHeaderSizeEnum.Medium;
        this.isSmallDisplay = this.displaySize === PatientHeaderSizeEnum.Small;
    }

    public userPicElementChanged() {
        if (!!this.userPicElement) {
            this.setUserPic();
        }
    }

    private calculateAge() {
        if (!!this.patientInfo?.dateOfBirth) {
            let today = moment();
            let mDob = moment(this.patientInfo.dateOfBirth);
            this.age = today.diff(mDob, "years");
        }
    }

    private initShowStatusChangeLink() {
        if (!!this.patientStatusLabel) {
            let notAllowedStatuses = ["non-admitted", "discharged", "deceased"];
            this.showStatusChangeLink = !notAllowedStatuses.some(
                (status) => this.patientStatusLabel.toLocaleLowerCase() === status
            );
        }
    }

    private initMedicaidLabel() {
        if (!!this.patientInfo.medicaidNumber) {
            if (this.patientInfo.medicaidNumber == "+") {
                this.medicaidLabel = "Pending";
            } else if (this.patientInfo.medicaidNumber == "N") {
                this.medicaidLabel = "Not a Medicaid Recipient";
            } else {
                this.medicaidLabel = this.patientInfo.medicaidNumber;
            }
        } else {
            this.medicaidLabel = null;
        }
    }

    private initTerminalDiagnosis() {
        if (this.patientInfo?.diagnoses?.length > 0) {
            this.terminalDiagnosis = this.patientInfo.diagnoses.find((diagnosis) => {
                return diagnosis.order === 1 && !!diagnosis.code && !!diagnosis.description;
            });
            if (!!this.terminalDiagnosis) {
                this.terminalDiagTooltip = `${this.terminalDiagnosis.code} ${this.terminalDiagnosis.description}`;
            }
        } else {
            this.terminalDiagnosis = null;
        }
    }

    private async loadPatientPhoto() {
        this.patientPhoto = await this._patientsService.getPatientPhoto(this.patientInfo.id);
    }

    public async getPatientPhoto() {
        return await this._patientsService.getPatientPhoto(this.patientInfo.id);
    }

    private setUserPic() {
        if (!!this.patientPhoto?.photoUrl && !!this.userPicElement) {
            this.userPicElement.style.backgroundImage = `url(${this.patientPhoto.photoUrl})`;
        }
    }

    public async changePhoto() {
        let isNewPhoto = !this.patientPhoto?.photoUrl;
        let title = "Change Photo";
        if (isNewPhoto) {
            title = "Upload Photo";
        }
        let photoOptions: IPhotoDialogOptions = {
            title,
            id: this.patientInfo.id,
            actions: [
                { label: ChangePhotoEnum.Get, callBack: this.getPatientPhoto.bind(this) },
                { label: ChangePhotoEnum.Delete, callBack: this.deletePatientPhoto.bind(this) },
                { label: ChangePhotoEnum.Upload, callBack: (args: any) => this.uploadPatientPhoto(args) }
            ]
        };
        let dialogOptions: DialogSettings = {
            viewModel: PhotoCropper,
            model: photoOptions
        };

        let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
        let closeResult = await dialog.closeResult;
        if (!closeResult.wasCancelled && closeResult.output) {
            await this.loadPatientPhoto();
            let messageTitle = "Changed";
            if (isNewPhoto) {
                messageTitle = "Uploaded";
            }
            this._toastrService.success({
                title: `Photo ${messageTitle}`,
                message: `The photo for has been successfully ${messageTitle.toLocaleLowerCase()}.`
            });
        }
    }

    public async deletePatientPhoto() {
        await this._patientsService.deletePatientPhoto(this.patientInfo.id);
    }

    public async uploadPatientPhoto(assetId: string) {
        await this._patientsService.uploadPatientPhoto(this.patientInfo.id, assetId);
        await this.loadPatientPhoto();
        this.setUserPic();
    }

    public async printFacesheet() {
        try {
            if (!!this.patientInfo) {
                this.isPrintInProgress = true;
                let response = await this._patientsService.printFacesheet(this.patientInfo.id);
                this._printService.print(response);
            } else {
                throw new Error();
            }
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Print Failed",
                message: `There was a problem while printing the facesheet. Please try again.`
            });
        } finally {
            this.isPrintInProgress = false;
        }
    }

    public async downloadFacesheet() {
        try {
            if (!!this.patientInfo) {
                this.isDownloadInProgress = true;
                let response = await this._patientsService.printFacesheet(this.patientInfo.id);
                this._downloadService.downloadFile(response);
            } else {
                throw new Error();
            }
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Download Failed",
                message: `There was a problem while downloading the facesheet. Please try again.`
            });
        } finally {
            this.isDownloadInProgress = false;
        }
    }

    public async uploadDocument() {
        let uploadOptions: IPatientDialogOptions = {
            title: "Documents",
            patientId: this.patientInfo?.id
        };
        let dialogOptions: DialogSettings = {
            viewModel: Upload,
            model: uploadOptions
        };

        let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
        let closeResult = await dialog.closeResult;
        if (closeResult.wasCancelled) {
            this.openDocuments = false;
        }
    }

    public async convertToReferral() {
        try {
            if (!!this.patientInfo) {
                this.isConvertInProgress = true;
                this._router.navigate(`/create/referral?patientId=${this.patientInfo.id}`);
            } else {
                throw new Error();
            }
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Convert to Referral Failed",
                message: `There was a problem while converting the patient to referral. Please try again.`
            });
        } finally {
            this.isConvertInProgress = false;
        }
    }

    public showMoreDetails() {
        this.showMoreData = true;
    }

    public hideMoreDetails() {
        this.showMoreData = false;
    }

    private async getIsTagsEnabled() {
        let patientTagsSettings = await this._providersService.getOperationSettings(this.patientBranch?.providerId);
        this.isTagsEnabled = patientTagsSettings.hasTagEnabled;
    }
}
