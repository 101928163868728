import { bindingMode } from "aurelia-binding";
import { bindable, customElement } from "aurelia-framework";

import { IProblemStatement } from "../../../interfaces/i-plan-of-care";

@customElement("problem-statement-group")
export class ProblemStatementGroup {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public problemStatement: IProblemStatement;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showStatement: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public toggleProblemStatement: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isUpdateEnabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAction: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isExpandAll: boolean = false;

    public get colspan() {
        if (this.isAction) {
            return this.columns - 2;
        } else {
            return this.columns - 1;
        }
    }
}
