import { autoinject, bindable, bindingMode, containerless, customElement } from "aurelia-framework";

import {
    IAddItemBehavior,
    ICreateDocumentBehavior,
    IDeleteItemBehavior,
    ILoadNarrativeBehavior,
    INavigationBehavior,
} from "../../../../interfaces/form-builder/i-behavior";
import { IButtonOptions } from "../../../../interfaces/form-builder/i-button-options";
import { NoteDataManager } from "../../note-data-manager";
import { AddItemBehaviorHandler } from "../../behavior-handlers/add-item-behavior-handler";
import { BehaviorTypesEnum } from "../../behavior-handlers/behavior-types-enum";
import { CreateDocumentBehaviorHandler } from "../../behavior-handlers/create-document-behavior-handler";
import { CreateInfectionReportBehaviorHandler } from "../../behavior-handlers/create-infection-report-behavior-handler";
import { DeleteItemBehaviorHandler } from "../../behavior-handlers/delete-item-behavior-handler";
import { DisplayToggleBehaviorHandler } from "../../behavior-handlers/display-toggle-behavior-handler";
import { NavigationBehaviorHandler } from "../../behavior-handlers/navigation-behavior-handler";
import { LoadNarrativeBehaviorHandler } from "../../behavior-handlers/load-narrative-behavior-handler";

export const enum FormButtonEvent {
    ListAddItem = "list:add:item",
    ListDeleteItem = "list:delete:item",
    ToggleDisplay = "dependency:modified",
    LoadData = "load:data"
}

@customElement("form-button")
@autoinject
@containerless
export class FormButton {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public viewOptions: IButtonOptions;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: boolean;
    private _navigationBehaviorHandler: NavigationBehaviorHandler;
    private _displayToggleBehaviorHandler: DisplayToggleBehaviorHandler;
    private _createDocumentBehaviorHandler: CreateDocumentBehaviorHandler;
    private _createInfectionReportBehaviorHandler: CreateInfectionReportBehaviorHandler;
    private _addItemBehaviorHandler: AddItemBehaviorHandler;
    private _deleteItemBehaviorHandler: DeleteItemBehaviorHandler;
    private _loadNarrativeBehaviorHandler: LoadNarrativeBehaviorHandler;
    private _noteDataManager: NoteDataManager;

    public constructor(navigationBehavior: NavigationBehaviorHandler, displayToggleBehavior: DisplayToggleBehaviorHandler,
        createDocumentBehavior: CreateDocumentBehaviorHandler,
        createInfectionReportBehavior: CreateInfectionReportBehaviorHandler,
        addItemBehavior: AddItemBehaviorHandler, deleteItemBehavior: DeleteItemBehaviorHandler,
        loadNarrativeBehaviorHandler: LoadNarrativeBehaviorHandler,
        noteDataManager: NoteDataManager) {
        this._navigationBehaviorHandler = navigationBehavior;
        this._displayToggleBehaviorHandler = displayToggleBehavior;
        this._createDocumentBehaviorHandler = createDocumentBehavior;
        this._createInfectionReportBehaviorHandler = createInfectionReportBehavior;
        this._addItemBehaviorHandler = addItemBehavior;
        this._deleteItemBehaviorHandler = deleteItemBehavior;
        this._loadNarrativeBehaviorHandler = loadNarrativeBehaviorHandler;
        this._noteDataManager = noteDataManager;
    }

    public onButtonClick() {
        let behaviors = this.viewOptions.behaviors;
        let numOfBehaviors = behaviors.length;

        for (let i = 0; i < numOfBehaviors; i++) {
            let buttonBehavior = behaviors[i];
            switch (buttonBehavior.behaviorType) {
                case BehaviorTypesEnum.Navigation:
                    // INFO: Reason for passing NoteDataManager instead of using autoinject in the behavior handler?
                    // Containers only work with classes that are custom elements or custom attributes
                    // Classes that are not either of the above will always get the instance from the root container
                    this._navigationBehaviorHandler.handle(
                        buttonBehavior as INavigationBehavior,
                        this._noteDataManager
                    );
                    break;
                case BehaviorTypesEnum.ToggleDisplay:
                    if (!!this.viewOptions.modelTarget) {
                        // TODO: Unable to get it from modifiedData. Find a way to retrieved modified value. For now, only closes.
                        // let currentValue = !!this._noteDataManager.getValue(dependency.targetName);
                        let value = this._displayToggleBehaviorHandler.handle(
                            true,
                            this.viewOptions.modelTarget.targetName);
                        // TODO: When value issue is fixed, uncomment to modify in modified data.
                        // this._noteDataManager.updatePropInModifiedData(dependency.targetName, value, false)
                        break;
                    }
                    this.result = this._displayToggleBehaviorHandler.handle(
                        this.result,
                        this.viewOptions.name
                    );
                    break;
                case BehaviorTypesEnum.CreateDocument:
                    this._createDocumentBehaviorHandler.handle(
                        buttonBehavior as ICreateDocumentBehavior,
                        this._noteDataManager
                    );
                    break;
                case BehaviorTypesEnum.CreateInfectionReport:
                    this._createInfectionReportBehaviorHandler.handle(this._noteDataManager);
                    break;
                case BehaviorTypesEnum.AddItem:
                    this._addItemBehaviorHandler.handle(buttonBehavior as IAddItemBehavior);
                    break;
                case BehaviorTypesEnum.DeleteItem:
                    this._deleteItemBehaviorHandler.handle(
                        buttonBehavior as IDeleteItemBehavior,
                        this.viewOptions.name
                    );
                    break;
                case BehaviorTypesEnum.LoadNarrative:
                    this._loadNarrativeBehaviorHandler.handle(
                        buttonBehavior as ILoadNarrativeBehavior,
                        this._noteDataManager
                    );
                    break;
            }
        }
    }
}
