import "./not-found.scss";

import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";

@autoinject
export class NotFound {
    public router: Router;

    public constructor(router: Router) {
        this.router = router;
    }
}
