import { valueConverter } from "aurelia-binding";
import { autoinject } from "aurelia-framework";

import { PermissionManager } from "../../common/utilities/permission-manager";

@autoinject
@valueConverter("isActionAllowed")
export class IsActionAllowed {
    private _permissionManager: PermissionManager;

    public constructor (permissionManager: PermissionManager) {
        this._permissionManager = permissionManager;
    }

    public toView(action: number, permission: number): boolean {
        return this._permissionManager.isActionAllowed(action, permission);
    }
}
