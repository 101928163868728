import {NavModel, RouteConfig, Router} from "aurelia-router";

import Infrastructure from "../infrastructure";
import {PermissionManager} from "./permission-manager";

export function isRouteAllowed(route: RouteConfig) {
    let settings = route.settings || {};
    return !!route.nav && isPageProdReady(settings);
}

export function isNavAllowed(navModel: NavModel) {
    let settings = navModel.settings || {};
    return !!navModel.config.nav && isPageProdReady(settings);
}

export function isPageProdReady(settings: any) {
    let isProd = Infrastructure.isProdEnvironment;
    return !isProd || !(settings?.inProgressPage);
}

export function getNavigationIndexes(router: Router, permissionManager: PermissionManager) {
    let routes = router.routes;
    let currentRoute = router.currentInstruction.config.name;
    let currentRouteIndex = routes.map(route => route.name).indexOf(currentRoute);
    let previousRouteIndex = -1;
    let nextRouteIndex = -1;

    for (let i = 0; i < routes.length; i++) {
        if (!routes[i].settings) {
            continue;
        }
        let isVisibleRoute = isPageProdReady(routes[i].settings);
        let hasPermission = !routes[i].settings.permission || permissionManager.checkRoutePermission(routes[i]);
        if (isVisibleRoute && hasPermission) {
            if (i < currentRouteIndex) {
                previousRouteIndex = i;
            }
            if (i > currentRouteIndex && nextRouteIndex == -1 && i > nextRouteIndex) {
                nextRouteIndex = i;
            }
        }
    }
    return {
        previousRouteIndex, nextRouteIndex
    };
}
