import {bindable, bindingMode, customElement} from "aurelia-framework";

import {IDiagnosis, IDisplayDiagnosis} from "../../../interfaces/i-diagnosis";

@customElement("diagnosis-component")
export class DiagnosisComponent {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public disabled: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public diagnosis: IDisplayDiagnosis[] = [{
        code: "",
        description: "",
        id: "00000000-0000-0000-0000-000000000000",
        order: 1
    }];

    public attached() {
        if (this.diagnosis && this.diagnosis[0] && this.diagnosis[0].order != 1) {
            this.diagnosis = Array(this.diagnosis[0].order - 1).fill(null).concat(this.diagnosis);
        }
    }

    public removeDiagnosis(index: number) {
        if (this.diagnosis.length > 1) {
            this.diagnosis.splice(index, 1);
            this.diagnosis = this.diagnosis.map((diagnosis, diagnosisIndex) => {
                diagnosis.order = diagnosisIndex + 1;
                return diagnosis;
            });
        }
    }

    public addDiagnosis() {
        this.diagnosis.push({
            code: "",
            description: "",
            id: "00000000-0000-0000-0000-000000000000",
            order: this.diagnosis.length + 1
        });
    }

    public moveDiagnosis(oldIndex: number, newIndex: number) {
        let arr = this.diagnosis;
        if (oldIndex >= arr.length || oldIndex < 0) {
            return;
        }
        if (newIndex >= arr.length || newIndex < 0) {
            return;
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        arr = arr.map((diagnosis, index) => {
            diagnosis.order = index + 1;
            return diagnosis;
        });
        this.diagnosis = arr;
    }

    // https://github.com/aurelia/templating/blob/master/doc/article/en-US/templating-basics.md#repeaters
    public diagnosisChanged(index: number, diag: IDiagnosis) {
        if (index < this.diagnosis.length) {
            let displayDiag = {
                code: diag.code,
                description: diag.description,
                order: index + 1
            };
            this.diagnosis[index] = displayDiag;
            console.log(this.diagnosis);
        }
    }
}
