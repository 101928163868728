import { Appender, Logger } from "aurelia-logging";
import * as Sentry from "@sentry/browser";

export class SentryLogger implements Appender {
    // TODO: Check Information in rest and logger ID

    public debug(logger: Logger, ...rest: any[]): void {
        // Empty
    }
    public info(logger: Logger, ...rest: any[]): void {
        // Empty
    }
    public warn(logger: Logger, ...rest: any[]): void {
        let message: string = "";
        for (let arg of rest) {
            message += arg;
        }
        Sentry.withScope(scope => {
            scope.setExtra("extra", rest);
            scope.setExtra("logger", logger.id);
            Sentry.captureMessage(message);
        });
    }
    public error(logger: Logger, ...rest: any[]): void {
        let error = rest[0] as Error;

        if (error != null) {
            Sentry.withScope(scope => {
                scope.setExtra("extra", rest);
                scope.setExtra("logger", logger.id);
                Sentry.captureException(error);
            });
        } else {
            let message: string = "";
            for (let arg of rest) {
                message += arg;
            }
            Sentry.withScope(scope => {
                scope.setExtra("extra", rest);
                scope.setExtra("logger", logger.id);
                Sentry.captureMessage(message);
            });
        }
    }
}
