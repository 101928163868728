import { autoinject, bindable, bindingMode, containerless, customElement, observable } from "aurelia-framework";
import { ValidationRules } from "aurelia-validation";
import moment from "moment";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IDateRangePickerOptions } from "../../../interfaces/i-date-range-picker";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IGetPatientsSlimInfoResult } from "../../../interfaces/i-get-patient";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { NewTask } from "../../../models/new-task";
import { EnumsService } from "../../../services/enums-service";

@autoinject
@containerless
@customElement("add-task-dates")
export class AddTaskDates {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<AddTaskDates>("patientChanged") })
    public patient: IGetPatientsSlimInfoResult;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<AddTaskDates>("newTaskChanged") })
    public newTask: NewTask = new NewTask();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPalliativeCareView: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public taskDateChanged: () => void;
    @observable({ changeHandler: nameof<AddTaskDates>("newTaskSingleDateChanged") })
    public newTaskSingleDate: string = "";
    @observable({ changeHandler: nameof<AddTaskDates>("newTaskDateRangeChanged") })
    public newTaskDateRange: string = "";
    @observable({ changeHandler: nameof<AddTaskDates>("newTaskFlexDatesChanged") })
    public newTaskFlexDates: string = "";
    @observable({ changeHandler: nameof<AddTaskDates>("newTaskRepeatChanged") })
    public newTaskRepeat: number = 0;
    private readonly _enumsService: EnumsService;
    public recurrencesEnum: IEnumResponse[] = [];
    public recurrencesEnumMap: EnumMap = new EnumMap([]);
    public newTaskRangePickerOptions: IDateRangePickerOptions = {
        dateLimit: {
            days: 90
        }
    };
    public dateValidation: IValidateCustomElement = {
        required: false,
        displayName: "Date",
        matches: true
    };
    public showSingleDateValidation: boolean = true;
    public showDateRangeValidation: boolean = false;
    public isPatientPending: boolean = false;

    public constructor(enumsService: EnumsService) {
        this._enumsService = enumsService;
    }

    public async attached() {
        this.initValidation();
        try {
            this.recurrencesEnum = await this._enumsService.getRecurrences();
            this.recurrencesEnumMap = new EnumMap(this.recurrencesEnum);
        } catch (e) {
            console.error(e);
        }
    }

    public newTaskChanged(newTask: NewTask, oldTask: NewTask) {
        if (newTask.taskId === oldTask.taskId) {
            return;
        }
        this.newTaskRepeat = 0;
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
    }

    public newTaskRepeatChanged(newValue: number, oldValue: number) {
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
        this.newTask.startDates = [];
        this.newTask.endDates = [];
        this.changeDateValidationStatus();
    }

    public newTaskSingleDateChanged(newValue: string) {
        this.newTask.startDates = [];
        if (!!newValue) {
            this.newTask.startDates.push(newValue);
        }
        if (this.taskDateChanged) {
            this.taskDateChanged();
        }
    }

    public newTaskDateRangeChanged(newValue: string) {
        this.newTask.startDates = [];
        let newTaskDates = newValue.split(" - ");
        let startDate = moment(newTaskDates[0]);
        let endDate = moment(newTaskDates[1]);
        let dateToAdd = startDate;
        while (dateToAdd <= endDate) {
            this.newTask.startDates.push(dateToAdd.format("MM/DD/YYYY"));
            if (this.newTaskRepeat == 4) {
                dateToAdd = dateToAdd.add(1, "month");
            } else {
                dateToAdd = dateToAdd.add(this.newTaskRepeat, "week");
            }
        }
        if (this.taskDateChanged) {
            this.taskDateChanged();
        }
    }

    public newTaskFlexDatesChanged(newValue: string) {
        this.newTask.startDates = [];
        if (!!newValue) {
            this.newTask.startDates = newValue.split(", ");
        }
        if (this.taskDateChanged) {
            this.taskDateChanged();
        }
    }

    public changeDateValidationStatus() {
        if (!this.dateValidation || !this.recurrencesEnumMap) {
            return;
        }
        let recurrenceName = this.recurrencesEnumMap.getEnumName(this.newTaskRepeat) as string;
        if (recurrenceName.toLowerCase() === "flexible") {
            this.showDateRangeValidation = false;
            this.showSingleDateValidation = false;
            this.dateValidation.required = true;
        } else if (recurrenceName.toLowerCase() === "does not repeat") {
            this.showDateRangeValidation = false;
            this.showSingleDateValidation = true;
            this.dateValidation.required = false;
        } else {
            this.showDateRangeValidation = true;
            this.showSingleDateValidation = false;
            this.dateValidation.required = false;
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: AddTaskDates) => x.newTaskSingleDate)
            .required()
            .when((x: AddTaskDates) => !!x.showSingleDateValidation)
            .withMessage("Date is required.")
            .ensure((x: AddTaskDates) => x.newTaskDateRange)
            .required()
            .when((x: AddTaskDates) => !!x.showDateRangeValidation)
            .withMessage("Date is required.")
            .on(this);
    }

    private async checkIsPatientPending() {
        let patientStatuses = await this._enumsService.getPatientStatus();
        let patientStatusEnumMap = new EnumMap(patientStatuses);
        this.isPatientPending = patientStatusEnumMap.getEnumValue("pending") === this.patient.status;
    }

    public patientChanged() {
        if (!this.patient) {
            return;
        }
        this.checkIsPatientPending();
    }
}
