import { autoinject, bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";
import { Router } from "aurelia-router";
import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";
import nameof from "../../../common/nameof";
import moment from "moment";
import { IOpenOrder, IPlanOfCare } from "../../../interfaces/i-plan-of-care";
import { PatientsService } from "../../../services/patient-service";
import { ToastrService } from "../../../services/toastr-service";
import { IProblemAreas } from "./../../../interfaces/i-plan-of-care";
import { ITypeaheadOptions } from "./../../../interfaces/i-typeahead";
import { PlanOfCareService } from "./../../../services/poc-service";
import { ProblemStatementsRenderer } from "./problem-statements-renderer";

@autoinject
@customElement("poc-problem-statements")
export class POCProblemStatements {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public visitDate: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public urlNoteId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAssessment: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAction: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<POCProblemStatements>("noteIdChanged")
    })
    public noteId: string;
    public openOrders: IOpenOrder[] = [];
    private _planOfCareService: PlanOfCareService;
    private _patientsService: PatientsService;
    private _toastrService: ToastrService;
    public isCurrentStatus: boolean = true;
    public planOfCare: IPlanOfCare[] = [];
    public router: Router;
    public newProblemArea: ITypeaheadOptions;
    public problemTypes: ITypeaheadOptions[] = [];
    public isAddItemRequested: boolean = false;
    public validationController: ValidationController;
    public isError: boolean = false;
    public isLoading: boolean = false;
    public isNoteIdRequested: boolean = false;
    public expandStatements: boolean;
    public isUpdateEnabled: boolean = false;
    public isUpdateProgress: boolean = false;
    public updateItemsCount: number = 0;
    public problemStatementsRenderer: ProblemStatementsRenderer;

    @computedFrom(nameof<POCProblemStatements>("noteId"), `${nameof<POCProblemStatements>("openOrders")}.length`)
    public get isValidOrderLinked() {
        return !!this.noteId && !!this.openOrders.find((order) => order.orderId == this.noteId);
    }

    @computedFrom(nameof<POCProblemStatements>("planOfCare"))
    public get hasData() {
        return this.planOfCare && this.planOfCare.length > 0;
    }

    public constructor(
        router: Router,
        controllerFactory: ValidationControllerFactory,
        patientsService: PatientsService,
        planOfCareService: PlanOfCareService,
        toastrService: ToastrService
    ) {
        this.router = router;
        this._toastrService = toastrService;
        this._patientsService = patientsService;
        this._planOfCareService = planOfCareService;
        this.validationController = controllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
    }

    public async attached() {
        await this.loadPatientProblems();
    }

    public async patientIdChanged(newValue: string) {
        if (this.patientId) {
            this.openOrders = await this._patientsService.getOpenOrders(this.patientId);
            if (this.urlNoteId) {
                this.noteId = this.urlNoteId;
            }
            await this.loadPatientProblems();
            let problemAreas = await this._planOfCareService.getProblemAreas(this.patientId);
            this.problemTypes = this.getDisplayProblemArea(problemAreas);
        }
    }

    public async loadPatientProblems() {
        if (this.patientId) {
            try {
                this.isError = false;
                this.isLoading = true;
                this.planOfCare = [];
                this.planOfCare = await this._planOfCareService.getPlanOfCare(this.patientId);
                // Filter out problem areas by date for formbuilder
                if (this.isAction && this.visitDate) {
                    this.planOfCare.forEach((plan) => {
                        plan.statements = plan.statements.filter((statement) => {
                            let effectiveDate = moment(statement.effectiveDate)?.format("MM/DD/YYYY");
                            return !effectiveDate || !moment(effectiveDate).isAfter(this.visitDate);
                        });
                    });
                    this.planOfCare = this.planOfCare.filter((plan) => plan.statements.length);
                }
            } catch (e) {
                this.isError = true;
                throw e;
            } finally {
                this.isLoading = false;
            }
        }
    }

    public openAddProblem() {
        this.isAddItemRequested = true;
    }

    public closeAddProblem() {
        this.validationController.reset();
        this.isAddItemRequested = false;
    }

    public async addNewProblem() {
        if (this.newProblemArea.value && this.patientId && this.noteId) {
            let url = `/notes/problem-area/${this.newProblemArea.value}?patientId=${this.patientId}&noteId=${this.noteId}`;
            if (this.isAssessment) {
                url += `&isAssessment=${this.isAssessment}`;
            }
            this.router.navigate(url);
        } else {
            this._toastrService.success({
                title: `Error - Add Problem(s)`,
                message: `There was a problem while adding the Problem(s). Please try again.`
            });
        }
    }

    public setCurrentStatus(status: boolean) {
        this.isCurrentStatus = status;
    }

    public async refreshList() {
        this.planOfCare = [].concat(this.planOfCare);
    }

    public expandAll() {
        this.problemStatementsRenderer.expandAll();
    }

    public collapseAll() {
        this.problemStatementsRenderer.collapseAll();
    }

    public getDisplayProblemArea(problemAreas: IProblemAreas[]) {
        if (problemAreas && problemAreas.length > 0) {
            return problemAreas.map((area) => ({ name: area.name, value: area.id }));
        } else {
            return [];
        }
    }

    public updatePlanOfCare() {
        this.isNoteIdRequested = true;
    }

    public closeUpdatePlanOfCare() {
        this.undoChanges();
    }

    public updateProblemStatements() {
        this.isUpdateEnabled = true;
    }

    public updateItemsUpdated(count: number) {
        this.updateItemsCount = count;
    }

    public async savePlanOfCare() {
        let resolvedGoals = this.problemStatementsRenderer.resolvedGoals;
        let discontinuedInterventions = this.problemStatementsRenderer.discontinuedInterventions;
        let discontinuedProblemStatements = this.problemStatementsRenderer.discontinuedProblemStatements;
        if (
            resolvedGoals.length > 0 ||
            discontinuedInterventions.length > 0 ||
            discontinuedProblemStatements.length > 0
        ) {
            try {
                this.isUpdateProgress = true;
                await this._planOfCareService.updatePlanOfCare(this.patientId, {
                    noteId: this.noteId,
                    goals: resolvedGoals,
                    interventions: discontinuedInterventions,
                    problemStatements: discontinuedProblemStatements,
                    areaIds: this.problemStatementsRenderer?.discontinuedAreaIds
                });
                this.problemStatementsRenderer.resetSelections();
                await this.loadPatientProblems();
                this.problemStatementsRenderer.collapseAll();
                this._toastrService.success({
                    title: `Plan of Care Updated`,
                    message: `Plan of Care has been successfully updated.`
                });
            } catch (e) {
                this._toastrService.error({
                    title: `Error - Updating Plan of Care`,
                    message: `There was a problem while updating the Plan of Care. Please try again.`
                });
            } finally {
                this.isUpdateProgress = false;
                this.cancelUpdate();
            }
        } else {
            this._toastrService.info({
                title: `Plan of Care up to date`,
                message: `There were no changes made in Plan of Care`
            });
        }
    }

    public cancelUpdate() {
        this.isUpdateEnabled = false;
    }

    public undoChanges() {
        if (this.urlNoteId) {
            this.router.navigate(`/note/${this.noteId}`);
        } else {
            this.cancelUpdate();
            this.noteId = "";
            this.urlNoteId = "";
            this.isNoteIdRequested = false;
            if (this.router.currentInstruction) {
                let currentInstruction = this.router.currentInstruction;
                delete currentInstruction.queryParams.noteId;
                this.router.navigateToRoute(
                    currentInstruction.config.name,
                    {
                        ...currentInstruction.queryParams,
                        id: this.patientId
                    },
                    { trigger: false, replace: true }
                );
            }
        }
    }

    public noteIdChanged() {
        if (!this.isAssessment) {
            if (this.isValidOrderLinked) {
                this.isNoteIdRequested = true;
            } else if (!!this.noteId) {
                // When there is no valid order linked but there is a note id set, throw an error
                this._toastrService.error({
                    title: `Error - Order not found`,
                    message: `There was a problem while loading the Selected Physician Order. Please try again.`
                });
                return;
            }
        }
        if (this.noteId && this.router.currentInstruction) {
            let currentInstruction = this.router.currentInstruction;
            this.router.navigateToRoute(
                currentInstruction.config.name,
                {
                    ...currentInstruction.queryParams,
                    noteId: this.noteId,
                    id: this.patientId
                },
                {
                    trigger: false,
                    replace: true
                }
            );
        }
    }
}
