export function formatName(firstName: string, lastName: string, format: string = "LN, FN", missingName: string = "",
    middleInitial: string = "", suffix: string = "") {
    let fullName = missingName;
    if (!!firstName) {
        fullName = firstName;
    }
    if (!!lastName) {
        if (format === "LN, FN") {
            fullName = `${lastName}, ${firstName}`;
        } else {
            fullName = `${firstName} ${lastName}`;
        }
    }
    if (!!suffix && !!middleInitial) {
        fullName = `${lastName} ${suffix}., ${firstName} ${middleInitial}.`;
    } else if (!!middleInitial) {
        fullName = `${lastName}, ${firstName} ${middleInitial}.`;
    } else if (!!suffix) {
        fullName = `${lastName} ${suffix}., ${firstName}`;
    }
    return fullName;
}
