import { INavigatorGroup, INavigatorItem, INavigatorQuestion } from "../../../interfaces/form-builder/i-navigator";
import { IScrubberResult, IScrubberResults } from "../../../interfaces/form-builder/i-scrubber";
import { NoteDataManager } from "../note-data-manager";

export class NavigatorDataHelper {
    private _noteDataManager: NoteDataManager;
    private _navigatorData: Map<string, INavigatorGroup>;

    public constructor(noteDataManager: NoteDataManager) {
        this._noteDataManager = noteDataManager;
        // TODO: Move the GenerateNavigatorData to respective Form Builder Pages
        // When backend can send tab name and question name
        let scrubberData = this._noteDataManager.getScrubberResults();
        this.generateNavigatorData(scrubberData);
    }

    public hasData() {
        return !!this._navigatorData;
    }

    public getNavigatorData() {
        return this._navigatorData;
    }

    public generateNavigatorData(scrubberData: IScrubberResults) {
        let navigatorDataChain: NavigatorDataChain = new NavigatorDataChain();
        if (!scrubberData) {
            this._navigatorData = null;
        }
        if (scrubberData.errors?.length > 0) {
            let errors = this.scrubberResultToNavigatorItem(scrubberData.errors);
            navigatorDataChain.addNavigatorGroup("Errors", NavigatorTheme.Error, NavigatorIcon.Error, errors);
        }
        if (scrubberData.inconsistencies?.length > 0) {
            let inconsistencies = this.scrubberResultToNavigatorItem(scrubberData.inconsistencies);
            navigatorDataChain.addNavigatorGroup("Inconsistencies", NavigatorTheme.Info, NavigatorIcon.Inconsistency, inconsistencies);
        }
        if (scrubberData.warnings?.length > 0) {
            let warnings = this.scrubberResultToNavigatorItem(scrubberData.warnings);
            navigatorDataChain.addNavigatorGroup("Warnings", NavigatorTheme.Warning, NavigatorIcon.Warning, warnings);
        }
        let navigatorData = navigatorDataChain.getData();
        if (navigatorData.size > 0) {
            this._navigatorData = navigatorData;
        }
    }

    private scrubberResultToNavigatorItem(scrubberResults: IScrubberResult[]): INavigatorItem[] {
        return scrubberResults.map((result) => ({
            message: result.message,
            questions: this.scrubberToNavigationQuestion(result.questions)
        }));
    }

    private scrubberToNavigationQuestion(codes: string[]): INavigatorQuestion[] {
        return codes.map((code) => ({
            code,
            tab: this.findTab(code)
        }));
    }

    private findTab(code: string): string {
        let tabs = this._noteDataManager.getTabsWithCodes();
        let elementsTab = tabs.find((tab) => tab.codes.indexOf(code) > -1);
        if (!elementsTab) {
            return null;
        }
        return elementsTab.name;
    }
}

export class NavigatorDataChain {
    private _navigatorData: Map<string, INavigatorGroup> = new Map();

    public addNavigatorGroup(label: string, theme: NavigatorTheme, icon: NavigatorIcon, navigatorItems: INavigatorItem[]) {
        let groupData = {
            settings: {
                label,
                theme,
                icon
            },
            navigatorItems
        };
        let groupName = label.replace(/ /g, "_");
        if (!!this._navigatorData.get(groupName)) {
            throw new Error("Duplicate Groups for Navigator");
        }
        if (navigatorItems?.length > 0) {
            this._navigatorData.set(groupName, groupData);
        }
        return this;
    }

    public getData() {
        return this._navigatorData;
    }
}

export enum NavigatorTheme {
    Error = "danger",
    Info = "info",
    Warning = "warning",
    Primary = "app-primary"
}

export enum NavigatorIcon {
    Error = "fas fa-times-circle",
    Inconsistency = "fas fa-minus-circle",
    Warning = "fas fa-exclamation-triangle"
}
