import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    Disposable,
    observable,
    BindingEngine
} from "aurelia-framework";

import nameof from "../../../common/nameof";
import { getSelectedEnumFilter } from "../../../common/utilities/filter-manager";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { AdministrativePermissionEnum } from "../../../enums/administrative-permission-enum";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IGetDrug } from "../../../interfaces/i-medication";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { Medication } from "../../../models/medication";
import { EnumsService } from "../../../services/enums-service";
import { IGetFrequencyOption } from "../../../services/i-medication-service";
import { MedicationService } from "../../../services/medication-service";
import { ToastrService } from "../../../services/toastr-service";

@autoinject
@containerless
@customElement("medication-form")
export class MedicationForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<MedicationForm>("medicationChanged")
    })
    public medication: Medication;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showSendToPatient: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPalliativeCareView: boolean = false;
    @observable({
        changeHandler: nameof<MedicationForm>("selectedMedicationChanged")
    })
    public selectedMedication: ITypeaheadOptions;
    @observable({
        changeHandler: nameof<MedicationForm>("selectedFrequencyChanged")
    })
    public selectedFrequency: ITypeaheadOptions;
    @observable({
        changeHandler: nameof<MedicationForm>("selectedDaysChanged")
    })
    public selectedDays: ITypeaheadOptions[] = [];
    public hasAddCustomMedPermission: boolean = false;
    public medicationValidation: IValidateCustomElement = {
        required: true,
        displayName: "Medication Name"
    };
    public frequencyValidation: IValidateCustomElement = {
        required: true,
        displayName: "Frequency"
    };
    private readonly _medicationService: MedicationService;
    private readonly _permissionManager: PermissionManager;
    private readonly _enumService: EnumsService;
    private _toastrService: ToastrService;
    private _bindingEngine: BindingEngine;
    private _observerSubscriptions: Disposable[] = [];
    public administeredByEnum: IEnumResponse[] = [];
    public weekDaysEnum: IEnumResponse[] = [];
    public classification: string;
    public isAddCustomMedRequested: boolean = false;
    public isAddCustomMedInProgress: boolean = false;
    public classificationsList: string[] = [];
    public newCustomMedication: string = "";
    public isClassificationsLoading: boolean = false;
    // This is required for custom check box
    // Bootstrap custom check box requires id
    // Multiple forms of same type being open breaks the check box behavior
    public checkBoxUniqueId: string = Date.now().toString();
    public medicationList: IGetDrug[] = [];
    public frequencyList: IGetFrequencyOption[] = [];
    public commentValidation: IValidateCustomElement = {
        required: true,
        message: "Reason is required"
    };
    public timeValidation: IValidateCustomElement = {
        required: false,
        message: "Time is required"
    };
    public maxTimesPerDay: number = Number.MAX_VALUE;
    public hasUnlimitedTimes: boolean = false;
    public isMedispan: boolean = false;

    @computedFrom(`${nameof<MedicationForm>("medication")}.${nameof<Medication>("id")}`)
    public get isEditMedication(): boolean {
        return !!this.medication && !!this.medication.id;
    }

    @computedFrom(
        `${nameof<MedicationForm>("medication")}.${nameof<Medication>("id")}`,
        `${nameof<MedicationForm>("medication")}.${nameof<Medication>("medicationName")}`
    )
    public get isCopyMedication(): boolean {
        return !!this.medication && !this.medication.id && !!this.medication.medicationName;
    }

    public constructor(
        medicationService: MedicationService,
        toastrService: ToastrService,
        enumService: EnumsService,
        bindingEngine: BindingEngine,
        permissionManager: PermissionManager
    ) {
        this._enumService = enumService;
        this._toastrService = toastrService;
        this._medicationService = medicationService;
        this._bindingEngine = bindingEngine;
        this._permissionManager = permissionManager;
    }

    public async attached() {
        if (this.medication?.isCovered != null) {
            this.medication.isCoveredOther = !this.medication.isCovered;
        }
        await this.getFormOptions();
        this.hasAddCustomMedPermission = this._permissionManager.checkPermission([
            {
                resource: AdministrativePermissionEnum.CustomMedications,
                action: PermissionActionEnum.Add
            }
        ]);
        if (this.isEditMedication) {
            this.getSelectedDays();
            this.maxTimesPerDay = this.medication?.maximumTimesPerDay;
            this.hasUnlimitedTimes = this.medication?.hasUnlimitedTimesPerDay;
        }
        this.initSubscriptions();
    }

    public initSubscriptions() {
        this._observerSubscriptions.push(
            this._bindingEngine
                .propertyObserver(this.medication, nameof<Medication>("isCovered"))
                .subscribe((newValue: boolean, oldValue: boolean) => {
                    this.isCoveredChanged(newValue);
                })
        );
        this._observerSubscriptions.push(
            this._bindingEngine.propertyObserver(this.medication, nameof<Medication>("days")).subscribe(() => {
                this.checkTimeValidation();
            })
        );
        this._observerSubscriptions.push(
            this._bindingEngine.propertyObserver(this.medication, nameof<Medication>("times")).subscribe(() => {
                this.checkTimeValidation();
            })
        );
    }

    public isCoveredChanged(newValue: boolean) {
        this.medication.isCoveredOther = !newValue;
    }

    public checkTimeValidation() {
        this.timeValidation.required = !!this.medication?.days?.length;
    }

    public async getFormOptions() {
        try {
            this.administeredByEnum = await this._enumService.getAdministeredBy();
            this.weekDaysEnum = await this._enumService.getWeekDays();
            if (this.isPalliativeCareView) {
                this.administeredByEnum = this.administeredByEnum.filter(
                    (item) => item.name.toLowerCase() !== "hospice"
                );
            }
        } catch (e) {
            console.error(e);
        }
    }

    public async fetchMedications(term: string) {
        try {
            if (term?.length <= 0) {
                return [];
            }
            this.medicationList = await this._medicationService.getDrugs({
                term,
                providerIds: [this.providerId],
                patientId: this.patientId
            });
            // TODO: Once every patient starts using only medispan database for medications, we would need to cleanup the code related to lexicom.
            let medispanMedication = this.medicationList.find((med) => med?.isMedispan);
            this.isMedispan = medispanMedication?.isMedispan;
            let medicationOptions;
            if (this.isMedispan) {
                medicationOptions = this.medicationList.map((med: IGetDrug) => ({
                    name: med.medicationName,
                    value: !med.customMedicationId ? med.mediSpanDrugDescriptorId.toString() : med.customMedicationId
                }));
            } else {
                medicationOptions = this.medicationList.map((med: IGetDrug) => ({
                    name: med.medicationName,
                    value: med.lexiDrugId ? med.lexiDrugId : med.customMedicationId
                }));
            }
            return medicationOptions;
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Error",
                message: "There was a problem while fetching the medication list. Please try again."
            });
            throw e;
        }
    }

    public async fetchFrequencies(term: string) {
        try {
            if (term?.length <= 0) {
                return [];
            }
            this.frequencyList = await this._medicationService.getFrequencyList({ term });
            let medicationOptions = this.frequencyList.map((option: IGetFrequencyOption) => ({
                name: option.frequency,
                value: option.id
            }));
            return medicationOptions;
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Error",
                message: "There was a problem while fetching the frequency list. Please try again."
            });
            throw e;
        }
    }

    public async addCustomMedication(term: string) {
        this.isAddCustomMedRequested = true;
        this.newCustomMedication = term;
    }

    public async createCustomMedication() {
        try {
            this.isAddCustomMedInProgress = true;
            let createCustomMedicationResponse = await this._medicationService.createCustomMedication({
                medicationName: this.newCustomMedication,
                associatedAgencyProviderIds: [this.providerId]
            });
            this.selectedMedication = {
                name: this.newCustomMedication,
                value: createCustomMedicationResponse.id
            };
            this.medication.medicationName = this.newCustomMedication;
            this.medication.customMedicationId = createCustomMedicationResponse.id;
            this.isAddCustomMedRequested = false;
            this._toastrService.success({
                title: "Custom Medication Created",
                message: `Custom medication <b>${this.newCustomMedication}</b> has been successfully created.`
            });
        } catch (err) {
            let message = "";
            console.error(err);
            if (err.statusMessage === "Conflict") {
                message = `Custom medication <b>${this.newCustomMedication}</b> is already present.`;
            } else {
                message = `There was a problem while creating custom medication, <b>${this.newCustomMedication}</b>. Please try again.`;
            }
            this._toastrService.error({
                title: "Error",
                message: message
            });
        } finally {
            this.isAddCustomMedInProgress = false;
        }
    }

    public cancelUpdateMedication() {
        this.isAddCustomMedRequested = false;
    }

    public medicationChanged(newValue: Medication) {
        if (this.isEditMedication || this.isCopyMedication) {
            this.selectedMedication = {
                name: this.medication.medicationName,
                value: this.medication.lexiDrugId ? this.medication.lexiDrugId : this.medication.customMedicationId
            };
            this.selectedFrequency = {
                name: this.medication.frequency,
                value: this.medication.medicationFrequencyId
            };
        } else {
            this.selectedMedication = undefined;
            this.selectedFrequency = undefined;
            this.selectedDays = [];
        }
    }

    public async getSelectedDays() {
        const days = this.medication?.days?.map((day) => day.toString());
        this.selectedDays = getSelectedEnumFilter(days, this.weekDaysEnum);
    }

    public async selectedMedicationChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            let selectedMedication: IGetDrug;
            if (this.isMedispan) {
                selectedMedication = this.medicationList.find((medication) => {
                    let medId = !medication.customMedicationId
                        ? medication.mediSpanDrugDescriptorId.toString()
                        : medication.customMedicationId;
                    return medication.medicationName === newValue.name && medId === newValue.value;
                });
            } else {
                selectedMedication = this.medicationList.find((medication) => {
                    return (
                        medication.medicationName === newValue.name &&
                        (medication.lexiDrugId === newValue.value || medication.customMedicationId === newValue.value)
                    );
                });
            }
            if (selectedMedication) {
                this.medication.medicationName = selectedMedication.medicationName;
                this.medication.lexiDrugId = selectedMedication.lexiDrugId;
                this.medication.synonymId = selectedMedication.synonymId;
                this.medication.customMedicationId = selectedMedication.customMedicationId;
                this.medication.classification = selectedMedication.classification;
                this.medication.mediSpanDrugDescriptorId = selectedMedication.mediSpanDrugDescriptorId;
            }
            if (this.medication.lexiDrugId) {
                await this.loadClassifications();
            } else {
                this.classificationsList = [];
            }
        } else {
            this.classificationsList = [];
        }
    }

    public async selectedFrequencyChanged(newValue: ITypeaheadOptions) {
        if (newValue) {
            let selectedFrequency = this.frequencyList.find((option) => {
                return option.frequency === newValue.name && option.id === newValue.value;
            });
            if (selectedFrequency) {
                this.medication.frequency = selectedFrequency.frequency;
                this.medication.medicationFrequencyId = selectedFrequency.id;
                this.medication.isPrn = selectedFrequency.isPrn;
                this.maxTimesPerDay = selectedFrequency.maximumTimesPerDay;
                this.hasUnlimitedTimes = selectedFrequency.hasUnlimitedTimesPerDay;
                this.medication.times = this.medication.times.slice(0, this.maxTimesPerDay);
            }
        }
    }

    public async selectedDaysChanged(newValue: ITypeaheadOptions[]) {
        if (!this.medication) {
            return;
        }
        this.medication.days = this.selectedDays.map((day) => day.value);
    }

    private async loadClassifications() {
        try {
            this.isClassificationsLoading = true;
            this.classificationsList = await this._medicationService.getClassifications(this.medication.lexiDrugId);
            this.isClassificationsLoading = false;
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: "Error",
                message: "There was a problem while fetching the classification list. Please try again."
            });
        }
    }

    public isCustomMed(medicationValue: string) {
        return !!this.medicationList.find((medication) => medication.customMedicationId == medicationValue);
    }

    public resetForm() {
        this.selectedMedication = null;
        this.selectedFrequency = null;
        this.selectedDays = [];
        this.medication = new Medication();
        this.maxTimesPerDay = Number.MAX_VALUE;
    }

    public detached() {
        this._observerSubscriptions.forEach((sub) => sub.dispose());
    }
}
