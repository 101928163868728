import { DialogService, DialogSettings } from "aurelia-dialog";
import { DialogCloseResult } from "aurelia-dialog/src/dialog-result";
import { autoinject, PLATFORM, useView } from "aurelia-framework";
import { Router } from "aurelia-router";

import { EventAggregator } from "../../../node_modules/aurelia-event-aggregator";
import { OidcSignOut } from "../../common/oidc/oidc-event-handler";
import { IGetAccountsModel } from "../../interfaces/i-get-accounts";
import { Alert, IAlertOptions } from "../../resources/dialogs/alert/alert";
import { SwitchAccountDialog, SwitchAccountDialogParams } from "../../resources/dialogs/switch-account/switch-account";
import { AccountsService } from "../../services/accounts-service";
import { AuthService } from "../../services/auth-service";
import { ProvidersService } from "../../services/providers-service";
import { IGetProviderDefault } from "../../services/i-providers-service";
import { BranchesService } from "../../services/branches-service";
import { PalliativePatientWarning } from "../home/my-dashboard/palliative-patient-warning";
import Infrastructure from "../../common/infrastructure";
import { config } from "../../common/config";

// import { getLogger } from "../../../node_modules/aurelia-logging";
@autoinject()
@useView(PLATFORM.moduleName("./select-account-view.html"))
export class SelectAccounts {
    // private readonly _logger = getLogger("select-accounts-page");
    private readonly _router: Router;
    private readonly _authService: AuthService;
    private readonly _accountService: AccountsService;
    private readonly _dialogService: DialogService;
    private readonly _ea: EventAggregator;
    private _providersService: ProvidersService;
    private _branchesService: BranchesService;
    public fragment: string;
    public query: string;
    public accounts: IGetAccountsModel[];
    public warningStartDate: string = "2024-01-28T08:00:00";
    public warningEndDate: string = "2024-01-30T08:00:00";
    public libertyAgency: string = config.libertyAccountId;

    public constructor(
        authService: AuthService,
        router: Router,
        accountService: AccountsService,
        dialogService: DialogService,
        ea: EventAggregator,
        providersService: ProvidersService,
        branchesService: BranchesService
    ) {
        this._authService = authService;
        this._router = router;
        this._accountService = accountService;
        this._dialogService = dialogService;
        this._ea = ea;
        this._providersService = providersService;
        this._branchesService = branchesService;
    }

    public async activate(params: any) {
        params = params || {};
        this.fragment = params.fragment;
        this.query = params.query;
    }

    public async attached() {
        console.log("Select accounts attached: Calling get All accounts");
        this.accounts = await this._accountService.getAllAccounts();
        await this.switchAccounts();
        console.debug(`Restoring saved location:`, this.fragment);
        this._router.navigate(this.fragment);
    }

    public async switchAccounts() {
        let accounts = this.accounts;
        if (accounts?.length === 0) {
            this.handleNoAccounts();
        } else if (accounts?.length === 1) {
            await this._authService.setAccountId(accounts[0].id);
            let providers = await this._providersService.getProvidersDefault();
            if (providers?.length > 1) {
                await this.handleMultipleAccounts();
            } else {
                let providerId = [providers[0].id];
                let lineOfService = providers[0].lineOfService;
                this._authService.setProviderInformation(providerId, lineOfService);
            }
        } else {
            let accountId = await this.getAccountIdFromQuery();
            if (!accountId) {
                await this.handleMultipleAccounts();
            }
        }
    }

    private async handleNoAccounts() {
        let model: IAlertOptions = {
            title: `Authorization Failed`,
            message:
                "You do not have any agencies linked to your account. If you believe this is an error, please contact support."
        };

        let options: DialogSettings = {
            viewModel: Alert,
            model: model
        };
        await this._dialogService.open(options).whenClosed(async () => {
            this._ea.publish(OidcSignOut);
        });
    }

    private async handleMultipleAccounts() {
        let params: SwitchAccountDialogParams = {
            accounts: this.accounts,
            currentAccountId: await this._authService.getAccountId()
        };

        let options: DialogSettings = {
            viewModel: SwitchAccountDialog,
            model: params
        };

        await this._dialogService.open(options).whenClosed(async (selectedInfo: DialogCloseResult) => {
            // This means user switched from one account to another within AxxessHospice
            // As per Product and the team's consensus, when user switches to an agency internally
            // Take the user to dashboard
            this.fragment = "/home/my-dashboard";
            let providers = selectedInfo?.output?.providers;
            let rememberedSelection = selectedInfo?.output?.rememberSelection;
            let lineOfService = selectedInfo.output?.lineOfService;
            this.handleClosedAccountDialog(providers, rememberedSelection, lineOfService);
        });
    }

    private async handleClosedAccountDialog(
        providers: IGetProviderDefault[],
        rememberSelection: boolean,
        lineOfService: number
    ) {
        if (rememberSelection) {
            await this._providersService.updateProviderDefaults(providers);
        }
        let defaultProviders = providers.filter((provider: IGetProviderDefault) => provider.isDefault);
        let selectedProviders =
            defaultProviders?.length > 0
                ? defaultProviders
                : providers.filter((provider: IGetProviderDefault) => provider.lineOfService === lineOfService);
        let selectedProviderIds = selectedProviders.map((provider: IGetProviderDefault) => provider.id);
        this._authService.setProviderInformation(selectedProviderIds, lineOfService);
        this._branchesService.clearBranchCache();
        this._accountService.clearLineOfService();
    }

    private async getAccountIdFromQuery() {
        // TODO: Update method names to reflect new provider changes
        if (!this.query) {
            return null;
        }
        let queryParams = this.query.split("&");
        let accountIdQuery = queryParams.filter((param) => param.includes("accountId"))[0];
        if (!accountIdQuery) {
            return null;
        }

        let accountId = accountIdQuery.split("=")[1];
        console.log("Select accounts: AccountId from URL: ", accountId);
        this.query = queryParams.filter((param) => !param.includes(accountIdQuery)).join("&");
        console.log("Query after removing accountId: ", this.query);
        if (!accountId) {
            return null;
        }
        this._authService.setAccountId(accountId);
        let providers = await this._providersService.getProvidersDefault();
        let rememberedSelection = providers?.filter((provider) => provider.isDefault);
        if (providers?.length > 1) {
            if (rememberedSelection?.length === 0) {
                await this.handleMultipleAccounts();
            } else {
                // If user has rememberedSelection do not not show dialog and set provider information.
                let providerIds = rememberedSelection.map((provider) => provider.id);
                let lineOfService = rememberedSelection[0].lineOfService;
                this._authService.setProviderInformation(providerIds, lineOfService);
            }
        } else if (providers?.length === 1) {
            let providerId = providers[0].id;
            let lineOfService = providers[0].lineOfService;
            this._authService.setProviderInformation([providerId], lineOfService);
        }
        return accountId;
    }

    private async openPalliativePatientWarning() {
        // ToDo: Remove this function when no longer necessary.
        let isProd = Infrastructure.isProdEnvironment;
        if (!isProd) {
            this.warningStartDate = "2024-01-24T08:00:00";
            this.warningEndDate = "2024-01-28T08:00:00";
        }
        let todaysDate = new Date();
        let todaysDateEST = todaysDate.toLocaleString("en-US", { timeZone: "EST" });
        if (
            new Date(todaysDateEST) > new Date(this.warningEndDate) ||
            new Date(todaysDateEST) < new Date(this.warningStartDate)
        ) {
            return;
        }
        let dialogOptions: DialogSettings = {
            viewModel: PalliativePatientWarning
        };
        await this._dialogService.open(dialogOptions);
    }

    public detached() {
        let accoundId = this._authService.getAccountId();
        if (accoundId == this.libertyAgency) {
            this.openPalliativePatientWarning();
        }
    }
}
