/***************************************************************
 * *
 * Default Sanitizer does NOT provide security against a wide variety of sophisticated XSS attacks,
 *  and should not be relied upon for sanitizing input from unknown sources.
 * https://aurelia.io/docs/binding/basics#element-content
 * *
 ***************************************************************/
import { valueConverter } from "aurelia-binding";
import DOMPurify from "dompurify";

@valueConverter("sanitizeMarkup")
export class SanitizeMarkup {
    public toView(dom: string): string {
        // Add a hook to make all links open a new window
        DOMPurify.addHook("afterSanitizeAttributes", (node: any) => {
            // set all elements owning target to target=_blank
            if ("target" in node) {
                let link = node.getAttribute("href");
                node.setAttribute("target", "_blank");
                // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
                if (link?.charAt(0) !== "/") {
                    node.setAttribute("rel", "noopener noreferrer");
                }
            }
            // set non-HTML/MathML links to xlink:show=new
            if (!node.hasAttribute("target") && (node.hasAttribute("xlink:href") || node.hasAttribute("href"))) {
                node.setAttribute("xlink:show", "new");
            }
        });
        return DOMPurify.sanitize(dom);
    }
}
