import { DialogService } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";

import { HttpStatusCodeEnum } from "../../enums/http-status-code-enum";
import { IResponseHandler } from "../../interfaces/i-handlers";
import { ToastrService } from "../../services/toastr-service";

@autoinject()
export class BadRequestHandler implements IResponseHandler {
    private _dialogService: DialogService;
    private _toastrService: ToastrService;

    public constructor(dialogService: DialogService, toastrService: ToastrService) {
        this._dialogService = dialogService;
        this._toastrService = toastrService;
    }

    public async handle(response: Response): Promise<void> {
        // let actionId = response.headers.get("x-mushu-actionid");

        if (response.status === HttpStatusCodeEnum.BadRequest) {
            let json = await response.json();
            let result = json as BadRequestResult;
            let messageTitle;
            let messages: string[] = [];
            let warnings: string[] = [];
            if (Array.isArray(result.errors) || Array.isArray(result.warnings)) {
                messageTitle = result.message;
                if (Array.isArray(result.errors)) {
                    for (let error of result.errors) {
                        if (error.message != "") {
                            messages.push(error.message);
                        }
                    }
                }
                if (Array.isArray(result.warnings)) {
                    for (let warning of result.warnings) {
                        if (warning.message != "") {
                            warnings.push(warning.message);
                        }
                    }
                }
            } else {
                messageTitle = "Validation Failed";
                messages.push(result.message);
            }

            // I will be punished for this
            /* for (let key of Object.keys(result.modelState)) {
                for (let message of result.modelState[key]) {
                    if (message != "") {
                        messages.push(message);
                    }
                }
            } */
            if (messages.length > 0) {
                this._toastrService.error({
                    title: messageTitle,
                    message: `${
                        messages.length === 1
                            ? messages.toString()
                            : "<ol>" + messages.map((message) => "<li>" + message + "</li>").join("") + "</ol>"
                    }`,
                    options: {
                        iconClass: "bg-danger"
                    }
                });
            }
            if (warnings.length > 0) {
                this._toastrService.warning({
                    title: messageTitle,
                    message: `${
                        warnings.length === 1
                            ? warnings.toString()
                            : "<ol>" + warnings.map((warning) => "<li>" + warning + "</li>").join("") + "</ol>"
                    }`,
                    options: {
                        iconClass: "bg-warning"
                    }
                });
            }
        }
    }
}

export class BadRequestResult {
    public errors: IError[];
    public warnings: IWarning[];
    public message: string;

    // This is ugly. I can't get typescript to properly deserialize to a Map<string, string[]>
    // public modelState: any;
}

export interface IError {
    field: string;
    message: string;
}

export interface IWarning {
    propertyName: string;
    message: string;
}
