import {
    autoinject,
    bindable,
    BindingEngine,
    bindingMode,
    customElement,
    Disposable,
    observable
} from "aurelia-framework";
import { ValidationController, ValidationRules } from "aurelia-validation";
import * as moment from "moment";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IGetCustomVolunteerActivity } from "../../../interfaces/employee-schedule/i-volunteer-acitivty";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { VolunteerActivityModel } from "../../../models/employee-schedule/volunteer-activity";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { EnumsService } from "../../../services/enums-service";
import { ProvidersService } from "../../../services/providers-service";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IBranchResponse } from "../../../interfaces/i-branch";
import { BranchesService } from "../../../services/branches-service";

@autoinject
@customElement("add-volunteer-activity-form")
export class AddVolunteerActivityForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("volunteerActivityChanged") })
    public volunteerActivity: VolunteerActivityModel = new VolunteerActivityModel();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public validationController: ValidationController;
    private readonly _enumsService: EnumsService;
    private readonly _providersService: ProvidersService;
    private readonly _subscription: Disposable[] = [];
    private readonly _bindingEngine: BindingEngine;
    private readonly _branchesService: BranchesService;
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("newTaskRepeatChanged") })
    public newTaskRepeat: number = 0;
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("newTaskSingleDateChanged") })
    public newTaskSingleDate: string = "";
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("newTaskDateRangeChanged") })
    public newTaskDateRange: string = "";
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("newTaskFlexDatesChanged") })
    public newTaskFlexDates: string = "";
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("shiftStartTimeChanged") })
    public shiftStartTime: string;
    @observable({ changeHandler: nameof<AddVolunteerActivityForm>("selectedVolunteerActivityChanged") })
    public selectedVolunteerActivity: IGetCustomVolunteerActivity = null;
    @observable({
        changeHandler: nameof<AddVolunteerActivityForm>("selectedShiftLengthChanged")
    })
    public shiftLength: number = 0;
    @observable({
        changeHandler: nameof<AddVolunteerActivityForm>("selectedBranchChanged")
    })
    public selectedBranch: IBranchResponse = null;
    public recurrencesEnum: IEnumResponse[] = [];
    public shiftEndTime: string;
    public volunteerActivityLists: IGetCustomVolunteerActivity[] = [];
    public recurrencesEnumMap: EnumMap;
    public showSingleDateValidation: boolean = true;
    public showDateRangeValidation: boolean = false;
    public activityValidation: IValidateCustomElement = {
        required: true,
        displayName: "Activity",
        matches: true
    };
    public dateValidation: IValidateCustomElement = {
        required: false,
        displayName: "Date",
        matches: true
    };
    public providerOptions: ITypeaheadOptions[] = [];
    public branches: IBranchResponse[];

    public constructor(
        enumsService: EnumsService,
        providersService: ProvidersService,
        bindingEngine: BindingEngine,
        branchesService: BranchesService
    ) {
        this._enumsService = enumsService;
        this._providersService = providersService;
        this._bindingEngine = bindingEngine;
        this._branchesService = branchesService;
    }

    public async attached() {
        this.initValidation();
        try {
            this.recurrencesEnum = await this._enumsService.getRecurrences();
            this.recurrencesEnumMap = new EnumMap(this.recurrencesEnum);
            this.branches = await this._branchesService.getBranchesByLineOfService();
            if (!!this.volunteerActivity?.taskId) {
                let selectedVolunteerActivity = this.volunteerActivityLists.find((activity) => {
                    return activity.taskId === this.volunteerActivity.taskId;
                });
                if (!!selectedVolunteerActivity) {
                    this.selectedVolunteerActivity = Object.assign({}, selectedVolunteerActivity);
                }
            }
        } catch (e) {
            console.error("Unable to Fetch Agency Tasks", e);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: AddVolunteerActivityForm) => x.newTaskSingleDate)
            .required()
            .when((x: AddVolunteerActivityForm) => !!x.showSingleDateValidation)
            .withMessage("Date is required.")
            .ensure((x: AddVolunteerActivityForm) => x.newTaskDateRange)
            .required()
            .when((x: AddVolunteerActivityForm) => !!x.showDateRangeValidation)
            .withMessage("Date is required.")
            .ensure((x: AddVolunteerActivityForm) => x.selectedBranch)
            .required()
            .withMessage("Branch is required.")
            .on(this);
    }

    public initSubscription() {
        this._subscription.push(
            this._bindingEngine
                .propertyObserver(this.volunteerActivity, nameof<VolunteerActivityModel>("startDates"))
                .subscribe(async (newValue: number) => {
                    this.updateActivityList();
                })
        );
        this._subscription.push(
            this._bindingEngine
                .propertyObserver(this.volunteerActivity, nameof<VolunteerActivityModel>("locationId"))
                .subscribe(async (newValue: number) => {
                    this.updateActivityList();
                })
        );
    }

    public async updateActivityList() {
        this.selectedVolunteerActivity = null;
        this.volunteerActivityLists = [];
        if (!!this.volunteerActivity?.startDates && !!this.volunteerActivity?.locationId) {
            this.volunteerActivityLists = await this._providersService.getVolunteerActivityTask(
                this.selectedBranch?.providerId,
                { startDates: this.volunteerActivity?.startDates }
            );
        }
    }

    public volunteerActivityChanged() {
        // Here every value is reset since newVolunteerActivity only changes when the form is closed
        this.selectedVolunteerActivity = null;
        this.newTaskRepeat = 0;
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
        this.shiftLength = 0;
        this.shiftStartTime = "";
        this.selectedBranch = null;
        // to reset subscriptions to volunteerActivity properties
        this.disposeSubscriptions();
        this.initSubscription();
    }

    public newTaskRepeatChanged() {
        this.newTaskSingleDate = "";
        this.newTaskDateRange = "";
        this.newTaskFlexDates = "";
        this.volunteerActivity.startDates = [];
        this.volunteerActivity.endDates = [];
        this.validationController?.reset();
        this.changeDateValidationStatus();
    }

    public changeDateValidationStatus() {
        if (!!this.dateValidation && !!this.recurrencesEnumMap) {
            let recurrenceName = this.recurrencesEnumMap.getEnumName(this.newTaskRepeat) as string;
            if (recurrenceName.toLowerCase() === "flexible") {
                this.showDateRangeValidation = false;
                this.showSingleDateValidation = false;
                this.dateValidation.required = true;
            } else if (recurrenceName.toLowerCase() === "does not repeat") {
                this.showDateRangeValidation = false;
                this.showSingleDateValidation = true;
                this.dateValidation.required = false;
            } else {
                this.showDateRangeValidation = true;
                this.showSingleDateValidation = false;
                this.dateValidation.required = false;
            }
        }
    }

    public async selectedVolunteerActivityChanged(selectedCustomTask: IGetCustomVolunteerActivity) {
        if (selectedCustomTask) {
            this.volunteerActivity.taskId = selectedCustomTask.taskId;
        }
    }

    public newTaskSingleDateChanged(newValue: string) {
        if (newValue) {
            this.volunteerActivity.startDates = [];
            this.volunteerActivity.startDates.push(newValue);
            this.updateShiftStartAndEndTime();
        }
    }

    public newTaskDateRangeChanged(newValue: string) {
        this.volunteerActivity.startDates = [];
        let newTaskDates = newValue.split(" - ");
        let startDate = moment(newTaskDates[0]);
        let endDate = moment(newTaskDates[1]);
        let dateToAdd = startDate;
        while (dateToAdd <= endDate) {
            this.volunteerActivity.startDates.push(dateToAdd.format("MM/DD/YYYY"));
            if (this.newTaskRepeat == 4) {
                dateToAdd = dateToAdd.add(1, "month");
            } else {
                dateToAdd = dateToAdd.add(this.newTaskRepeat, "week");
            }
        }
        this.updateShiftStartAndEndTime();
    }

    public newTaskFlexDatesChanged(newValue: string) {
        if (newValue) {
            this.volunteerActivity.startDates = newValue.split(", ");
            this.updateShiftStartAndEndTime();
        }
    }

    public shiftStartTimeChanged() {
        if (this.shiftLength > 0 && this.volunteerActivity?.startDates?.length > 0) {
            this.updateShiftStartAndEndTime();
        }
    }

    public selectedShiftLengthChanged(newValue: string, oldValue: string) {
        if (!newValue || newValue === "0") {
            this.shiftStartTime = null;
            this.shiftEndTime = null;
        }
        if (
            !!newValue &&
            newValue !== oldValue &&
            !!this.shiftStartTime &&
            this.volunteerActivity?.startDates?.length > 0
        ) {
            this.updateShiftStartAndEndTime();
        }
    }

    public selectedBranchChanged() {
        this.volunteerActivity.locationId = this.selectedBranch?.id;
    }

    public updateShiftStartAndEndTime() {
        let startDates = this.volunteerActivity.startDates;
        this.volunteerActivity.startDates = startDates.map((item) => {
            let startDate = moment(item).format("MM/DD/YYYY");
            let updatedStartDate;
            if (!!this.shiftStartTime) {
                updatedStartDate = new Date(`${startDate} ${this.shiftStartTime}`).toISOString();
                return moment(updatedStartDate).format("MM/DD/YYYY hh:mm A");
            }
            return startDate;
        });
        this.volunteerActivity.endDates = [];
        this.shiftEndTime = moment(this.shiftStartTime, "hh:mm A").add(this.shiftLength, "hours").format("hh:mm A");
        this.volunteerActivity.startDates.forEach((item) => {
            let endDate;
            if (!!this.shiftStartTime) {
                endDate = moment(item).add(this.shiftLength, "hours").format("MM/DD/YYYY hh:mm A");
            } else {
                endDate = moment(item).format("MM/DD/YYYY");
            }
            this.volunteerActivity.endDates.push(endDate);
        });
    }

    public disposeSubscriptions() {
        this._subscription?.forEach((subscription) => subscription.dispose());
    }

    public detached() {
        this.disposeSubscriptions();
    }
}
