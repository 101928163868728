import { HospiceClient } from "../common/hospice-client";
import { autoinject } from "aurelia-framework";

@autoinject()
export class NluService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/nlu";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async sendMessage(message: string) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useQueryString({
                chatMessage: message
            })
            .fetch<any>();
    }
}
