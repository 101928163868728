import "./dashboard-ring.scss";

import { bindable, bindingMode, containerless, customElement } from "aurelia-framework";

@customElement("dashboard-ring")
@containerless
export class DashboardRing {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public type: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public text: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public clickHandler: () => void;

    public ringClick() {
        if (this.clickHandler) {
            this.clickHandler();
        }
    }
}
