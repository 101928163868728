export enum BillingPermissionEnum {
    NOE = "NOE",
    NOTR = "NOTR",
    Claims = "Claims",
    History = "History",
    Remittance = "Remittance",
    OverrideClosedAccounting = "Override Closed Accounting",
    Collections = "Collections",
    ClaimNotes = "Claim Notes"
}
