import { bindingMode, computedFrom } from "aurelia-binding";
import { autoinject, bindable } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { IElement } from "../../../interfaces/form-builder/i-element";
import { NoteDataManager } from "../note-data-manager";
import {getColumnClasses} from "../../../common/utilities/column-classes";

@autoinject
export class SubSectionGroup {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public element: IElement;
    private _noteDataManager: NoteDataManager;
    public tooltip: string;

    @computedFrom(nameof<SubSectionGroup>("element"))
    public get columnsClasses(): string {
        let columns = this.element.columns;
        return getColumnClasses(columns);
    }

    public constructor(noteDataManager: NoteDataManager) {
        this._noteDataManager = noteDataManager;
    }

    public async attached() {
        this.tooltip = await this._noteDataManager.getTooltip(this.element.name);
    }
}
