import { valueConverter } from "aurelia-binding";

@valueConverter("formatZipCode")
export class FormatZipCode {
    public toView(zip: string) {
        if (!!zip) {
            let unformattedZip = zip?.split("-").join("");
            if (unformattedZip?.length > 5) {
                let zipLength = unformattedZip.length > 9 ? 9 : zip.length;
                return `${unformattedZip.substr(0, 5)}-${unformattedZip.substr(5, 4)}`;
            } else {
                return unformattedZip;
            }
        } else {
            return zip;
        }
    }

    public fromView(formattedZip: string) {
        if (formattedZip?.length > 5) {
            return formattedZip.split("-").join("").substr(0, 9);
        } else {
            return formattedZip;
        }
    }
}
