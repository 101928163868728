import { IAddress } from "../../interfaces/i-address";
import { ValidationRules } from "aurelia-validation";

export class Address implements IAddress {
    public country?: string;
    public addressLine1: string = "";
    public addressLine2: string = "";
    public city: string = "";
    public state: string = "";
    public zipCode: string = "";
    public county: string = "";
    public latitude: number;
    public longitude: number;
    public isValidated: boolean = false;
    public isBypassed: boolean = false;

    public constructor(data?: IAddress) {
        if (!!data) {
            Object.assign(this, data);
        }
    }

    public initValidations() {
        // TODO: Find out why initializing validation rules for individual field fails
        ValidationRules.ensure((address: IAddress) => address.addressLine1)
            .required()
            .withMessage("Address Line 1 is required.")
            .ensure((address: IAddress) => address.city)
            .required()
            .withMessage("City is required.")
            .ensure((address: IAddress) => address.state)
            .required()
            .withMessage("State is required.")
            .ensure((address: IAddress) => address.zipCode)
            .required()
            .withMessage("ZIP is required.")
            .ensure((address: IAddress) => address.county)
            .required()
            .withMessage("County is required.")
            .on(this);
    }

    public initInternationalValidation() {
        ValidationRules.ensure((address: IAddress) => address.addressLine1)
            .required()
            .withMessage("Address Line 1 is required.")
            .ensure((address: IAddress) => address.city)
            .required()
            .withMessage("City is required.")
            .ensure((address: IAddress) => address.state)
            .required()
            .withMessage("State / Province / Territory is required.")
            .ensure((address: IAddress) => address.zipCode)
            .required()
            .withMessage("Postal Code is required.")
            .on(this);
    }
}
