import { computedFrom } from "aurelia-binding";
import { autoinject, containerless } from "aurelia-framework";

import { config } from "../../common/config";
import nameof from "../../common/nameof";
import { MushuService } from "../../services/mushu-service";
import { NalaService } from "../../services/nala-service";

@containerless
@autoinject
export class SwitchApp {
    private _mushuService: MushuService;
    private _nalaService: NalaService;
    public hasNala: boolean = false;
    public hasMushu: boolean = false;
    public nalaUrl: string = config.nalaApiUrl;
    public mushuUrl: string = config.mushuApiUrl;

    @computedFrom(
        nameof<SwitchApp>("hasNala"),
        nameof<SwitchApp>("hasMushu")
    )
    public get showSwitchApp() {
        return this.hasNala
            || this.hasMushu;
    }

    public constructor(nalaService: NalaService, mushuService: MushuService) {
        this._nalaService = nalaService;
        this._mushuService = mushuService;
    }

    public attached() {
        this.checkOtherProducts();
    }

    private checkOtherProducts() {
        this.checkMushu();
        this.checkNala();
    }

    private async checkMushu() {
        let mushuAccounts = await this._mushuService.getAccounts();
        this.hasMushu = mushuAccounts.length > 0;
    }

    private async checkNala() {
        let nalaAccounts = await this._nalaService.getAccounts();
        this.hasNala = nalaAccounts.length > 0;
    }
}
