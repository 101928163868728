import {IEnumResponse} from "../../interfaces/i-enum";

export function getEnumValue(name: string, enumArr: IEnumResponse[], fuzzySearch: boolean = false): string | number {
    if (enumArr.length > 0) {
        let status;
        if (fuzzySearch) {
            status = enumArr.find(item => item.name.includes(name));
        } else {
            status = enumArr.find(item => item.name.toLowerCase() === name.toLowerCase());
        }
        if (!!status) {
            return status.value;
        }
    }
    return null;
}
