import "./npa-pay-rates-table.scss";

import {
    autoinject,
    bindable,
    BindingEngine,
    bindingMode,
    computedFrom,
    Disposable,
    observable
} from "aurelia-framework";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { getEnumFilterOptions, getFilterOptions } from "../../../common/utilities/filter-manager";
import { IGetCustomNonPatient } from "../../../interfaces/employee-schedule/i-non-patient-activity";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IPageCallbackResult } from "../../../interfaces/i-page-container";
import { IPagination, IPaginationNew } from "../../../interfaces/i-pagination";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IGetNpaPayRate, IGetNpaPayRateParams, IUpdateNpaPayRateParams } from "../../../services/i-providers-service";
import { NpaPayRate } from "../../forms/npa-pay-rate-form/npa-pay-rate";
import { PAGE_FAIL_RESPONSE, Pagination } from "../pagination/pagination";
import { IBranchResponse } from "./../../../interfaces/i-branch";
import { IDeleteNpaPayRateParams } from "./../../../services/i-providers-service";

@autoinject
export class NpaPayRatesTable {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public branches: IBranchResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public nonPatientActivityList: IGetCustomNonPatient[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public payType: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public permission: string;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public fetchRates: (args: IGetNpaPayRateParams) => Promise<IPaginationNew<IGetNpaPayRate>>;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateNpaRate: (rate: IUpdateNpaPayRateParams) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public deleteNpaRate: (id: IDeleteNpaPayRateParams) => void;
    private readonly _bindingEngine: BindingEngine;
    private _eaSubscriptions: Disposable[] = [];
    @observable({
        changeHandler: nameof<NpaPayRatesTable>("startDateChanged")
    })
    public startDate: string;
    public selectedNpaActivities: ITypeaheadOptions[] = [];
    public selectedBranches: ITypeaheadOptions[] = [];
    public selectedRateTypes: ITypeaheadOptions[] = [];
    public paginationInstance: Pagination;
    public isContentLoading: boolean = false;
    public isError: boolean = false;
    public columns: number = 7;
    public page: IPaginationNew<IGetNpaPayRate>;
    public rateTypeEnumMap: EnumMap = new EnumMap([]);

    @computedFrom(nameof<NpaPayRatesTable>("page"))
    public get loadingCount() {
        return this.page?.items?.length > 0 ? this.page.items.length : 10;
    }

    @computedFrom(`${nameof<NpaPayRatesTable>("page")}.${nameof<IPagination>("itemCount")}`)
    public get hasData() {
        return this.page?.itemCount > 0;
    }

    public constructor(bindingEngine: BindingEngine) {
        this._bindingEngine = bindingEngine;
    }

    public bind() {
        this.rateTypeEnumMap = new EnumMap(this.rateTypeEnum);
    }

    public async attached() {
        this.initSubscription();
    }

    public initSubscription() {
        [this.selectedBranches, this.selectedNpaActivities, this.selectedRateTypes].forEach((property) =>
            this._eaSubscriptions.push(
                this._bindingEngine.collectionObserver(property).subscribe((splices: any) => {
                    this.filtersChanged();
                })
            )
        );
    }

    public filtersChanged() {
        this.paginationInstance?.reset();
    }

    public async pageChangedCallback(pageNumber: number, pageSize: number): Promise<IPageCallbackResult> {
        try {
            this.isError = false;
            let nonPatientActivities =
                this.selectedNpaActivities.length > 0
                    ? this.selectedNpaActivities.map((selected) => selected.value)
                    : [];
            let locationIds =
                this.selectedBranches.length > 0 ? this.selectedBranches.map((selected) => selected.value) : [];
            let rateTypes =
                this.selectedRateTypes.length > 0
                    ? this.selectedRateTypes.map((selected) => Number(selected.value))
                    : [];
            let data = await this.fetchRates({
                pageNumber,
                pageSize,
                customTaskIds: nonPatientActivities,
                locationIds,
                rateTypes,
                startDate: this.startDate
            });
            this.page = data;
            return {
                totalPageCount: data.pageCount,
                success: data.itemCount > 0,
                totalCount: data.itemCount
            };
        } catch (e) {
            console.error(e);
            this.isError = true;
            return PAGE_FAIL_RESPONSE;
        }
    }

    public updateNpaPayRate(id: string, updatedPayRate: NpaPayRate) {
        return this.updateNpaRate({
            id,
            updatedPayRate
        });
    }

    public deleteNpaPayRate(id: string) {
        return this.deleteNpaRate({
            id
        });
    }

    public nonPatientActivityFetch(filter: string) {
        return this.nonPatientActivityList
            .filter((x) => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .map((activity) => ({
                name: `${activity.providerName || "Default"} - ${activity.name}`,
                value: activity.taskId
            }));
    }

    public branchFetch(filter: string): ITypeaheadOptions[] {
        return getFilterOptions(filter, this.branches);
    }

    public rateTypeFetch(filter: string): ITypeaheadOptions[] {
        return getEnumFilterOptions(filter, this.rateTypeEnum);
    }

    public startDateChanged() {
        this.paginationInstance?.reset();
    }

    public detached() {
        if (this._eaSubscriptions.length > 0) {
            this._eaSubscriptions.forEach((sub) => sub.dispose());
        }
    }
}
