import { bindingMode, computedFrom } from "aurelia-binding";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable } from "aurelia-framework";
import { cloneDeep } from "lodash";

import nameof from "../../../common/nameof";
import {
    IAddItemBehavior,
    IDeleteItemBehavior,
    IGetBehavior,
    IGetWoundGroupBehavior
} from "../../../interfaces/form-builder/i-behavior";
import { IElement, IOption, IElementWithBehaviors } from "../../../interfaces/form-builder/i-element";
import { IInput } from "../../../interfaces/form-builder/i-input";
import { IFormModel } from "../../../interfaces/form-builder/i-model-schema";
import { FormBuilderEvent, NoteDataManager } from "../note-data-manager";
import { BehaviorTypesEnum } from "../behavior-handlers/behavior-types-enum";
import { ElementTypesEnum } from "../element-types-enum";
import { GroupingTypesEnum } from "../form-grouping/grouping-types-enum";
import { FormButtonEvent } from "./form-button/form-button";
import { InputTypesEnum } from "./input-types-enum";
import { IReadOnlyIfBehavior } from "../../../interfaces/form-builder/i-behavior";
import { getColumnClasses } from "../../../common/utilities/column-classes";

@autoinject
export class ListGroup {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public element: IInput;
    private _ea: EventAggregator;
    private _subscriptions: Subscription[] = [];
    private _inputs: IInput[] = [];
    private _formTemplate: IElement;
    private _modelTemplate: IFormModel = {};
    public listModel: IFormModel[] = [];
    public tooltip: string;
    public elementsList: IElement[] = [];
    public haveEmptyRows: boolean = true;
    public _noteDataManager: NoteDataManager;
    public woundBodyBehavior: IGetWoundGroupBehavior = null;

    @computedFrom(nameof<ListGroup>("element"))
    public get columnsClasses(): string {
        let columns = this.element.columns;
        return getColumnClasses(columns);
    }

    @computedFrom(`${nameof<ListGroup>("element")}.${nameof<IInput>("options")}`)
    public get minimum() {
        let behaviorData = this.getBehaviorData();
        return behaviorData?.minimum || 0;
    }

    @computedFrom(`${nameof<ListGroup>("element")}.${nameof<IInput>("options")}`)
    public get maximum() {
        let behaviorData = this.getBehaviorData();
        return behaviorData?.maximum || Number.MAX_VALUE;
    }

    @computedFrom(`${nameof<ListGroup>("highestRowIndex")}`, nameof<ListGroup>("maximum"))
    public get maxLimitReached() {
        return this.highestRowIndex === this.maximum;
    }

    @computedFrom(`${nameof<ListGroup>("listModel")}.length`)
    public get highestRowIndex() {
        return this.listModel.length;
    }

    public constructor(ea: EventAggregator, noteDataManager: NoteDataManager) {
        this._ea = ea;
        this._noteDataManager = noteDataManager;
    }

    private getBehaviorData() {
        let inputOption: any = this.element.options.filter(
            (item: IElement) => item.elementType === ElementTypesEnum.Input
        );
        let addButton: IInput = (inputOption as IInput[]).find(
            (item: IInput) => item.inputType === InputTypesEnum.Button
        );
        let behaviorData = addButton?.behaviors.find(
            (item) => item.behaviorType === BehaviorTypesEnum.AddItem
        ) as IAddItemBehavior;
        // TODO: Store this in a private variable and reuse to reduce the lopping checks
        return behaviorData;
    }

    public attached() {
        this.getFormTemplate();
        this._inputs = this.getInputs(this._formTemplate as IOption);
        this.getModelTemplate();
        this.refreshListModel();
        // This is to ensure add button shows up if the minimum is set to 0 and there is a add button in the options
        if (this.listModel.length === 0 && this.minimum === 0) {
            this.haveEmptyRows = false;
        }

        this.renderList();
        this.manageDeleteButtonVisibility();
        this.initSubscriptions();
        this.tooltip = this._noteDataManager.getTooltip(this.element.name);
        // console.log("List Group FORM TEMPLATE: ", this._formTemplate);
        // console.log("List Group Inputs: ", this._inputs);
        // console.log("List Group MODEL TEMPLATE: ", this._modelTemplate);
        // console.log("List Group LIST MODEL: ", this.listModel);
        // console.log("Before List Generation: highestRowIndex ", this.highestRowIndex);
        if (this.listModel.length > 0) {
            this.listModel.forEach((list, index) => {
                this._noteDataManager.updateListGroupValidations(
                    Object.keys(this._modelTemplate),
                    this.element.name,
                    index
                );
            });
        }
        this.extractBehaviors();
    }

    private extractBehaviors() {
        if (this.element?.behaviors?.length > 0) {
            this.woundBodyBehavior = this.element.behaviors.find(
                (ele) => ele.behaviorType == BehaviorTypesEnum.GetWoundGroup
            ) as IGetWoundGroupBehavior;
        }
    }

    private getFormTemplate() {
        // List group only accepts subsection to be repeated, which is the template
        let template = this.element.options.find((item) => item.groupingType === GroupingTypesEnum.Subsection);
        this._formTemplate = template;
    }

    private getInputs(template: IOption) {
        let nonInputs = [InputTypesEnum.Button, InputTypesEnum.Link, InputTypesEnum.ButtonLink];
        let inputs: IInput[] = [];
        template?.elements?.forEach((element) => {
            if (
                element.elementType === ElementTypesEnum.Input &&
                !nonInputs.includes((element as IInput).inputType as InputTypesEnum)
            ) {
                inputs.push(element as IInput);
                // Handle subsections inside options
                if (!!(element as IElementWithBehaviors).options) {
                    (element as IElementWithBehaviors).options.forEach((option: IOption) => {
                        if (
                            !!option.subElement &&
                            option.subElement.elementType === ElementTypesEnum.Input &&
                            !nonInputs.includes((option.subElement as IInput).inputType as InputTypesEnum)
                        ) {
                            inputs.push(option.subElement as IInput);
                            if (!!option.subElement.elements) {
                                this.getInputs(option.subElement as IOption);
                            }
                        }
                    });
                }
            } else if (
                element.elementType === ElementTypesEnum.Grouping &&
                element.groupingType === GroupingTypesEnum.Subsection
            ) {
                inputs = inputs.concat(...this.getInputs(element as IOption));
            }
        });
        return inputs;
    }

    private getModelTemplate() {
        this._inputs.forEach((input) => {
            this._modelTemplate[input.name] = "";
        });
    }

    public refreshListModel() {
        let noteModel = this._noteDataManager.getNoteModel();
        let noteModelKeys = Object.keys(noteModel);
        let listNoteModelKeys = noteModelKeys.filter((key) => key.includes(`${this.element.name}.`));
        let modifiedData = this._noteDataManager.getModifiedData();
        let modifiedDataKeys = Object.keys(modifiedData);
        let listModifiedDataKeys = modifiedDataKeys.filter((key) => key.includes(`${this.element.name}.`));
        let allListKeys = new Set([...listNoteModelKeys, ...listModifiedDataKeys]);
        allListKeys.forEach((key) => {
            let { index, propertyName } = this.getIndexAndPropName(key);
            let isInput = !!this._inputs.find((element) => {
                if (element.inputType === InputTypesEnum.ListGroup) {
                    return propertyName.includes(element.name);
                }
                return element.name === propertyName;
            });
            if (isInput) {
                this.updateItemInListModel(key, index, propertyName);
            }
        });

        // Add dummy entries to list model to satisfy minimum requirement
        if (this.listModel.length < this.minimum) {
            // TODO: use i = this.listModel.length and test
            for (let i = 0; i < this.minimum; i++) {
                let model = Object.assign({}, this._modelTemplate);
                this.listModel.push(model);
            }
        }
        // We could have saved empty rows in the model, it should be removed while rendering if those rows are beyond the min level
        this.removeEmptyRowsInListModel();
        this.checkEmptyRows();
        console.log("After REFRESH LIST MODEL: listModel", this.listModel);
        console.log("After REFRESH LIST MODEL: highestRowIndex", this.highestRowIndex);
    }

    private removeEmptyRowsInListModel() {
        // Include rows that have one or more values
        // Going in reverse order so that we don't remove any rows with empty values in the middle
        for (let i = this.highestRowIndex - 1; i >= this.minimum; i--) {
            let isRowEmpty = this.isRowEmpty(i);
            if (!isRowEmpty) {
                break;
            }

            this.listModel.splice(i, 1);
        }
    }

    private isRowEmpty(index: number) {
        let row = this.listModel[index];
        // let numberOfFieldsInModel = Object.keys(this._modelTemplate).length;
        // let numberOfFieldsInRow = Object.keys(row).length;
        let hasSomeValue = true;
        // TODO: Find a way for numberOfFieldsInModel === numberOfFieldsInRow to work in nested list groups to make this more efficient.
        if (!!row) {
            hasSomeValue = Object.values(row).some((value) => {
                return value !== null && value !== undefined && value.toString().length > 0;
            });
        }

        return !hasSomeValue;
    }

    private renderList() {
        let itemsToLoad: number = this.listModel.length || this.minimum;
        this.elementsList = [];
        for (let i = 0; i < itemsToLoad; i++) {
            let newRow = this.getNewFormRow(i);
            this.elementsList.push(newRow);
        }
    }

    private updateItemInListModel(key: string, index?: number, propertyName?: string) {
        let value = this._noteDataManager.getValueFromModel(key);

        if (index === null || isNaN(index)) {
            let indexAndProp = this.getIndexAndPropName(key);
            index = indexAndProp.index;
            propertyName = indexAndProp.propertyName;
        }

        if (this._noteDataManager.hasElementModified(key)) {
            value = this._noteDataManager.getValueFromModifiedData(key);
        }

        // Add row to listModel if it doesn't exist
        if (!this.listModel[index]) {
            this.listModel[index] = {};
        }

        // store empty value if there is nothing stored in the listValue yet
        this.listModel[index][propertyName] = value;

        /*
        Sample List Model:
        [{
            inputName1: "",
            inputName2: ""
        }, {
            inputName1: "",
            inputName2: ""
        }]
        */
    }

    private checkEmptyRows() {
        // This is to hide add button if there are any empty rows available
        // User should fill in the empty rows available before adding more
        if (this.listModel.length > 0) {
            this.haveEmptyRows = this.listModel.some((row: IFormModel, index: number) => {
                return this.isRowEmpty(index);
            });
        } else if (this.minimum === 0) {
            this.haveEmptyRows = false;
        }
    }

    private getIndexAndPropName(fieldName: string) {
        // Key name would have the structure of `${listGroupName}.${index}.${elementsValues}`
        // Remove name of list group and for from key, it could have dot inside the name
        let itemWithoutName = fieldName.replace(`${this.element.name}.`, "");
        // Split by first occurrence, there could be nested levels
        let modelKeySplit = itemWithoutName.match(/(\d*).(.*)/);
        if (modelKeySplit) {
            let propertyName = modelKeySplit[2];
            let index = Number(modelKeySplit[1]);
            return { index, propertyName };
        }
        return null;
    }

    private getNewFormRow(index: number) {
        // Deep copying the object.
        let newRow: IElement = cloneDeep(this._formTemplate);
        newRow = this.changeElementsName(newRow, index);
        // this.highestRowIndex = index;
        return newRow;
    }

    private changeSubElementNames(option: IOption, index: number) {
        if (!!option.subElement) {
            option.subElement.name = `${this.element.name}.${index}.${option.subElement.name}`;
            if (!!(option.subElement as IElementWithBehaviors).options) {
                (option.subElement as IElementWithBehaviors).options.forEach((subOption) => {
                    this.changeSubElementNames(subOption, index);
                });
            }
            if (!!(option.subElement as IElementWithBehaviors).elements) {
                this.changeElementsName(option.subElement, index);
            }
        }
    }

    private changeElementsName(element: IElement, index: number) {
        element?.elements?.forEach((item: IElementWithBehaviors) => {
            let propertyName = item.name;
            item.name = `${this.element.name}.${index}.${propertyName}`;
            // TODO: Changing dependency names will break if dependency is external and not in list-group.
            if (!!item.dependencies) {
                item.dependencies.forEach((dependency, dependencyIndex) => {
                    item.dependencies[dependencyIndex] = `${this.element.name}.${index}.${dependency}`;
                });
            }
            if (!!item.valueDependencies) {
                item.valueDependencies.forEach((dependency) => {
                    dependency.targetName = `${this.element.name}.${index}.${dependency.targetName}`;
                });
            }
            if (!!item.behaviors) {
                item.behaviors.forEach((behavior: IReadOnlyIfBehavior) => {
                    if (behavior.targetName) {
                        behavior.targetName = `${this.element.name}.${index}.${behavior.targetName}`;
                    }
                    if (behavior?.secondaryData) {
                        behavior.secondaryData = `${this.element.name}.${index}.${behavior.secondaryData}`;
                    }
                });
            }
            if (!!item.options) {
                item.options.forEach((option: IOption) => {
                    this.changeSubElementNames(option, index);
                });
            }
            if (item.elementType === ElementTypesEnum.Grouping) {
                this.changeElementsName(item, index);
            }
            if (!!item.questions) {
                this.changeQuestionsName(item, index);
            }
            return item;
        });
        return element;
    }

    private changeQuestionsName(item: IElementWithBehaviors, index: number) {
        item.questions.forEach((question) => {
            let newName = `${this.element.name}.${index}.${question.name}`;
            question.name = newName;
        });
    }

    private manageDeleteButtonVisibility() {
        if (this.elementsList.length <= this.minimum) {
            // Remove Button if length of list items is less than minimum possible
            this.elementsList.forEach((listItem) => {
                listItem.elements = listItem.elements.filter((elem: IInput) => {
                    let behaviors = elem.behaviors;
                    // If buttons with delete item behavior, filter the element from listItems
                    let notAllowedButton =
                        elem.inputType !== InputTypesEnum.ButtonLink ||
                        !behaviors ||
                        (!!behaviors &&
                            !behaviors.find((behavior) => {
                                return behavior.behaviorType === BehaviorTypesEnum.DeleteItem;
                            }));
                    return notAllowedButton;
                });
            });
        } else if (this.elementsList.length === this.minimum + 1) {
            // To add delete button to all the items which do not have a delete button after minimum criteria is met
            for (let i = 0; i < this.minimum; i++) {
                let newRow = this.getNewFormRow(i);
                this.elementsList.splice(i, 1, newRow);
            }
        }
    }

    private initSubscriptions() {
        this._subscriptions.push(
            this._ea.subscribe(FormButtonEvent.ListAddItem, (buttonBehavior: IAddItemBehavior) => {
                if (
                    buttonBehavior.listName.toLowerCase() === this.element.name.toLowerCase() ||
                    (this.element.inputType === InputTypesEnum.ListGroup &&
                        this.element.name.toLowerCase().includes(buttonBehavior.listName.toLowerCase()))
                ) {
                    console.log("Calling Handle for Adding a Row", this.element.name);
                    this.handleAddItem();
                }
            })
        );
        this._subscriptions.push(
            this._ea.subscribe(FormButtonEvent.ListDeleteItem, (buttonBehavior: IDeleteItemBehavior) => {
                if (
                    buttonBehavior.listName.toLowerCase() === this.element.name.toLowerCase() ||
                    (this.element.inputType === InputTypesEnum.ListGroup &&
                        this.element.name.toLowerCase().includes(buttonBehavior.listName.toLowerCase()))
                ) {
                    this.handleDeleteItem(buttonBehavior);
                }
            })
        );
        this._subscriptions.push(
            this._ea.subscribe(FormBuilderEvent.ModifiedDataUpdated, (prop: string) => {
                if (prop.includes(`${this.element.name}.`)) {
                    this.updateItemInListModel(prop);
                    this.checkEmptyRows();
                }
            })
        );
        this._subscriptions.push(
            this._ea.subscribe(FormBuilderEvent.ModifiedDataPropDeleted, (prop: string) => {
                if (prop.includes(`${this.element.name}.`) && this.listModel.length > 0) {
                    // this.handleModifiedDataChange(prop);
                    this.updateItemInListModel(prop);
                    let { index } = this.getIndexAndPropName(prop);
                    if (index + 1 > this.minimum) {
                        let isRowEmpty = this.isRowEmpty(index);
                        if (isRowEmpty) {
                            this.listModel.splice(index, 1);
                        }
                    }
                    this.checkEmptyRows();
                }
            })
        );
    }

    // private handleModifiedDataChange(prop: string) {
    // }

    private handleAddItem() {
        console.log("List Model before ADD ITEM", JSON.stringify(this.listModel));
        let newRow = this.getNewFormRow(this.listModel.length);
        this.elementsList.push(newRow);
        this.listModel.push(Object.assign({}, this._modelTemplate));
        this.checkEmptyRows();
        this.manageDeleteButtonVisibility();
        this._noteDataManager.updateListGroupValidations(
            Object.keys(this._modelTemplate),
            this.element.name,
            this.listModel.length - 1
        );
        console.log("List Model after ADD ITEM", JSON.stringify(this.listModel));
    }

    // TODO: If the delete is causing issues and the fix is dirty
    // Please change the implementation to use HomeCare's list-item delete
    // For any questions in HomeCare delete implementation, reach out to Chris Henry
    private handleDeleteItem(buttonBehavior: IDeleteItemBehavior) {
        let { index: deletedItemIndex } = this.getIndexAndPropName(buttonBehavior.itemName);
        let listModifiedData = this._noteDataManager.getModifiedData();
        console.log("Before Delete Modified Data: ", listModifiedData);
        let modifiedDataKeys = Object.keys(listModifiedData);
        let modifiedListInputKeys = modifiedDataKeys.filter((key) => key.includes(`${this.element.name}.`));

        // Get rows that are addition in progress
        let addInProgressRows = this.getAddInProgressRows(deletedItemIndex);

        this.listModel.splice(deletedItemIndex, 1);

        // UPDATE MODIFIED DATA WITH NEW listModel
        listModifiedData = Object.assign({}, this.getValuesFromListModel());
        listModifiedData = Object.assign(listModifiedData, this.fillDeletedRowsInModel());

        // UPDATE LIST INPUT KEYS IN MODIFIED DATA
        console.log("Before deleting all list input values in modified data", this._noteDataManager.getModifiedData());
        this.listModel = [];
        modifiedListInputKeys.forEach((key) => {
            this._noteDataManager.deletePropFromModifiedData(key, false);
        });
        console.log("Before Modified Data update: ", listModifiedData);
        Object.keys(listModifiedData).forEach((key) => {
            this._noteDataManager.updatePropInModifiedData(key, listModifiedData[key], true);
        });
        console.log("After Modified Data update: ", this._noteDataManager.getModifiedData());

        // INFO: updatePropInModifiedData is not guaranteed to update the prop, since the prop might not be updated
        // if it has the same value in note model
        this.refreshListModel();
        // INFO: If deleted index was within the minimum number of rows,
        // then the last item will be an empty row by default since refresh will bring the minimum number of rows
        if (deletedItemIndex <= this.minimum - 1) {
            addInProgressRows.pop();
        }
        if (addInProgressRows.length > 0) {
            this.listModel = [...this.listModel, ...addInProgressRows];
        }
        console.log("After Delete Item: List Model ", this.listModel);
        this.checkEmptyRows();

        // REGENERATE THE LIST
        this.renderList();
        this.manageDeleteButtonVisibility();
    }

    private getAddInProgressRows(deletedItemIndex: number) {
        let rows: IFormModel[] = [];
        for (let i = this.listModel.length - 1; i > this.minimum - 1; i--) {
            let isRowEmpty = this.isRowEmpty(i);
            if (i !== deletedItemIndex && isRowEmpty) {
                rows.push(this.listModel[i]);
            } else if (!isRowEmpty) {
                // INFO: empty row in the middle and delete an entry with data
                break;
            }
        }
        return rows;
    }

    private getValuesFromListModel() {
        let localModifiedData: IFormModel = {};
        this.listModel.forEach((item, index) => {
            for (let [prop, value] of Object.entries(item)) {
                let key = `${this.element.name}.${index}.${prop}`;
                localModifiedData[key] = value;
            }
        });
        return localModifiedData;
    }

    private fillDeletedRowsInModel() {
        let countInModel: number = -1;
        let localModifiedData: IFormModel = {};
        let localNoteModel = this._noteDataManager.getNoteModel();
        let maxRowsInListModel = this.highestRowIndex - 1;
        Object.keys(localNoteModel).forEach((key) => {
            if (key.includes(this.element.name)) {
                let { index } = this.getIndexAndPropName(key);
                countInModel = Math.max(countInModel, index);
            }
        });
        if (countInModel > maxRowsInListModel) {
            // IF MODEL HAS MORE DATA THAN MODIFIED DATA THEN FILL THE REST WITH "" IN MODIFIED DATA
            for (let i = maxRowsInListModel; i < countInModel; i++) {
                this._inputs.forEach((input) => {
                    let modelKey = `${this.element.name}.${i + 1}.${input.name}`;
                    localModifiedData[modelKey] = "";

                    if (input.behaviors) {
                        let getBehavior = input.behaviors.find(
                            (item) => item.behaviorType === BehaviorTypesEnum.Get
                        ) as IGetBehavior;

                        if (getBehavior?.secondaryData) {
                            localModifiedData[`${this.element.name}.${i + 1}.${getBehavior.secondaryData}`] = "";
                        }
                    }

                    if (input.inputType === InputTypesEnum.ListGroup) {
                        Object.keys(localNoteModel).forEach((key) => {
                            if (key.includes(modelKey)) {
                                localModifiedData[key] = "";
                            }
                        });
                    }
                });
            }
        }
        return localModifiedData;
    }

    public detached() {
        this._subscriptions?.forEach((sub) => sub?.dispose());
    }
}
