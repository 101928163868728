import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";

import { EnumMap } from "../../../common/utilities/enum-map";
import { PermissionManager } from "../../../common/utilities/permission-manager";
import { PermissionActionEnum } from "../../../enums/permission-action-enum";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IGetNpaPayRate, IUpdateNpaPayRateParams } from "../../../services/i-providers-service";
import { ToastrService } from "../../../services/toastr-service";
import { NpaPayRate } from "../../forms/npa-pay-rate-form/npa-pay-rate";
import { IDeleteNpaPayRateParams } from "./../../../services/i-providers-service";
import { ActionButtonTooltipEnum } from "../../../enums/action-button-tooltip-enum";

@autoinject
export class NpaPayRateRow {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnumMap: EnumMap;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public permission: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public npaPayRate: IGetNpaPayRate;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public rateTypeEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public updateNpaRate: (rate: IUpdateNpaPayRateParams) => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public deleteNpaRate: (id: IDeleteNpaPayRateParams) => void;
    private readonly _toastrService: ToastrService;
    private readonly _permissionManager: PermissionManager;
    public actionButtonTooltipEnum = ActionButtonTooltipEnum;
    public validationController: ValidationController;
    public cachedData: NpaPayRate;
    public isEditRequested: boolean = false;
    public isDeleteRequested: boolean = false;
    public isEditInProgress: boolean = false;
    public isDeleteInProgress: boolean = false;
    public hasEditPermission: boolean = false;
    public hasDeletePermission: boolean = false;
    public maxEndDate: string = "9999-12-31T23:59:59";

    public constructor(
        controllerFactory: ValidationControllerFactory,
        toastrService: ToastrService,
        permissionManager: PermissionManager
    ) {
        this._toastrService = toastrService;
        this._permissionManager = permissionManager;
        this.validationController = controllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
    }

    public attached() {
        this.hasEditPermission = this._permissionManager.checkPermission([
            {
                resource: this.permission,
                action: PermissionActionEnum.Edit
            }
        ]);
        this.hasDeletePermission = this._permissionManager.checkPermission([
            {
                resource: this.permission,
                action: PermissionActionEnum.Delete
            }
        ]);
    }

    public openEditPayRate() {
        if (!this.hasEditPermission) {
            return;
        }
        this.isEditRequested = true;
        this.cachedData = new NpaPayRate();
        this.cachedData.rate = this.npaPayRate.rate;

        this.cachedData.activities = [
            {
                name: this.npaPayRate.customTaskName,
                value: this.npaPayRate.customTaskId
            }
        ];
        this.cachedData.branches = [
            {
                name: this.npaPayRate.locationName,
                value: this.npaPayRate.locationId
            }
        ];
        this.cachedData.effectiveDate = this.npaPayRate.effectiveDate;
        this.cachedData.rateType = this.npaPayRate.rateType;
    }

    public async updatePayRate() {
        if (
            this.cachedData.rate == this.npaPayRate.rate &&
            this.cachedData.rateType == this.npaPayRate.rateType &&
            this.cachedData.effectiveDate == this.npaPayRate.effectiveDate
        ) {
            this._toastrService.info({
                title: `The non-patient activity rate is up to date`,
                message: `There were no changes made in the pay rate.`
            });
        } else {
            let res = await this.validationController.validate();
            if (!res.valid) {
                return;
            }
            try {
                this.isEditInProgress = true;
                await this.updateNpaRate({ id: this.npaPayRate.payRateId, updatedPayRate: this.cachedData });
                this._toastrService.success({
                    title: `Non-Patient Activity Rate Updated`,
                    message: `The Non-Patient Activity rate has been successfully updated.`
                });
                this.closeEditPayRate();
            } catch (err) {
                console.error(err);
                this._toastrService.error({
                    title: `Error - Update Non-Patient Activity Rate`,
                    message: `There was a problem while updating the Non-Patient Activity Rate. Please try again.`
                });
            } finally {
                this.isEditInProgress = false;
            }
        }
    }

    public closeEditPayRate() {
        this.isEditRequested = false;
        this.validationController.reset();
        this.cachedData = undefined;
    }

    public confirmDeletePayRate() {
        if (!this.hasDeletePermission) {
            return;
        }
        this.isDeleteRequested = true;
    }

    public async deletePayRate() {
        try {
            this.isDeleteInProgress = true;
            await this.deleteNpaRate({ id: this.npaPayRate.payRateId });
            this._toastrService.success({
                title: `Non-Patient Activity Rate Deleted`,
                message: `The Non-Patient Activity rate has been successfully deleted.`
            });
        } catch (e) {
            console.error(e);
            this._toastrService.error({
                title: `Error - Delete Non-Patient Activity Rate`,
                message: `There was a problem while deleting the Non-Patient Activity Rate. Please try again.`
            });
        } finally {
            this.isDeleteInProgress = false;
        }
    }

    public cancelDeletePayRate() {
        this.isDeleteRequested = false;
    }

    public getRateTypeLabel(type: number) {
        return this.rateTypeEnumMap?.getEnumName(type);
    }
}
