import {autoinject, bindable, bindingMode, computedFrom, customElement, observable} from "aurelia-framework";

import nameof from "../../../../common/nameof";
import {IInputOptions} from "../../../../interfaces/form-builder/i-input-options";
import {AssetsService} from "../../../../services/assets-service";
import {IGetAssetResponse} from "../../../../services/i-assets-service";
import {NoteDataManager} from "../../note-data-manager";

@customElement("file-input")
@autoinject
export class FileInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryData: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public acceptableFileType: string = "";
    private _assetsService: AssetsService;
    public isUploadInProgress: boolean = false;
    public isFetchingFileDetails: boolean = false;
    public isFetchingError: boolean = false;
    public isUploadError: boolean = false;
    public fileDetails: IGetAssetResponse;
    public noteDataManager: NoteDataManager;
    @observable({
        changeHandler: nameof<FileInput>("selectedFileChanged")
    })
    public selectedFile: File;

    @computedFrom(`${nameof<FileInput>("fileDetails")}.${nameof<IGetAssetResponse>("mimeType")}`)
    public get fileIcon() {
        if (!this.fileDetails) {
            return "fa-file";
        }
        let type = this.fileDetails.mimeType;
        if (type.match("image.*")) {
            return "fa-file-image";
        } else if (type.includes("wordprocessing")) {
            return "fa-file-word";
        } else if (type.includes("spreadsheet")) {
            return "fa-file-excel";
        } else if (type.includes("pdf")) {
            return "fa-file-pdf";
        } else {
            return "fa-file";
        }
    }

    public constructor(assetsService: AssetsService, noteDataManager: NoteDataManager) {
        this._assetsService = assetsService;
        // Using file input for as a custom Element in Task Details
        // TODO: Transition to form builder element
        // this.noteDataManager = noteDataManager;
    }

    public attached() {
        // this.noteDataManager.assignValidationRules.call(
        //     this,
        //     this.viewOptions.name,
        //     this.noteDataManager
        // );
    }

    public removeFile() {
        this.result = null;
        this.secondaryData = null;
    }

    public async selectedFileChanged(newValue: File) {
        if (!newValue) {
            return;
        }
        try {
            this.isUploadInProgress = true;
            this.isUploadError = false;
            this.result = await this._assetsService.uploadFile(newValue);
            this.secondaryData = newValue.name;
            this.selectedFile = null;
        } catch (e) {
            this.isUploadError = true;
            console.error(e);
        } finally {
            this.isUploadInProgress = false;
        }
    }

    public async resultChanged(newValue: any) {
        if (!newValue) {
            this.fileDetails = null;
            this.selectedFile = null;
            return;
        }
        try {
            this.isFetchingError = false;
            this.isFetchingFileDetails = true;
            this.fileDetails = await this._assetsService.getAsset(newValue);
        } catch (e) {
            console.error(e);
            this.isFetchingError = true;
        } finally {
            this.isFetchingFileDetails = false;
        }
    }

    public retryFetching() {
        this.resultChanged(this.result);
    }
}
