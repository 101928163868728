import "./attach-physician-order.scss";

import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    observable
} from "aurelia-framework";
import { Router } from "aurelia-router";
import {
    validateTrigger,
    ValidationController,
    ValidationControllerFactory,
    ValidationRules
} from "aurelia-validation";
import moment from "moment";

import nameof from "../../../common/nameof";
import { formatName } from "../../../common/utilities/format-name";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IGetUserSlimInfoResult } from "../../../interfaces/i-user";
import { PhysiciansService } from "../../../services/physician-service";
import { TaskService } from "../../../services/task-service";
import { ToastrService } from "../../../services/toastr-service";
import { IOpenOrder } from "./../../../interfaces/i-plan-of-care";
import { CurrentUserService } from "./../../../services/current-user-service";
import { PatientsService } from "./../../../services/patient-service";
import { AccountsService } from "../../../services/accounts-service";

@autoinject
@containerless
@customElement("attach-physician-order")
export class AttachPhysicianOrder {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerId: string = "";
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public closeAttachOrder: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public openOrders: IOpenOrder[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<AttachPhysicianOrder>("orderIdChanged")
    })
    public orderId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public urlNoteId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public entity: string;
    private _router: Router;
    private _currentUserService: CurrentUserService;
    private _patientsService: PatientsService;
    private _physicianService: PhysiciansService;
    private _tasksService: TaskService;
    private _toastrService: ToastrService;
    private _accountsService: AccountsService;
    @observable({
        changeHandler: nameof<AttachPhysicianOrder>("orderSelectedPhysicianChanged")
    })
    public orderSelectedPhysician: ITypeaheadOptions;
    public currentUser: IGetUserSlimInfoResult;
    public orderDate: string = moment().format("MM/DD/YYYY");
    public isNewOrderRequested: boolean = false;
    public isNewOrderInProgress: boolean = false;
    public validationController: ValidationController;
    public isChooseOrderRequested: boolean = true;
    public isPalliativeCareView: boolean = false;

    @computedFrom(nameof<AttachPhysicianOrder>("orderId"), nameof<AttachPhysicianOrder>("urlNoteId"))
    public get isUrlOrderSelected(): boolean {
        return this.orderId === this.urlNoteId;
    }

    @computedFrom(nameof<AttachPhysicianOrder>("orderId"), `${nameof<AttachPhysicianOrder>("openOrders")}.length`)
    public get currentOrder() {
        return this.openOrders.find((order) => order.orderId == this.orderId);
    }

    @computedFrom(
        `${nameof<AttachPhysicianOrder>("currentUser")}.${nameof<IGetUserSlimInfoResult>("firstName")}`,
        `${nameof<AttachPhysicianOrder>("currentUser")}.${nameof<IGetUserSlimInfoResult>("lastName")}`
    )
    public get userFullName() {
        if (!!this.currentUser) {
            return formatName(this.currentUser.firstName, this.currentUser.lastName, "FN LN");
        }
        return "";
    }

    public constructor(
        router: Router,
        currentUserService: CurrentUserService,
        patientsService: PatientsService,
        physicianService: PhysiciansService,
        toastrService: ToastrService,
        tasksService: TaskService,
        controllerFactory: ValidationControllerFactory,
        accountsService: AccountsService
    ) {
        this._router = router;
        this._currentUserService = currentUserService;
        this._patientsService = patientsService;
        this._physicianService = physicianService;
        this._tasksService = tasksService;
        this._toastrService = toastrService;
        this._accountsService = accountsService;
        this.validationController = controllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.changeOrBlur;
        this.initValidation();
    }

    public orderIdChanged() {
        this.isChooseOrderRequested = !this.orderId;
    }

    public async attached() {
        this.isPalliativeCareView = await this._accountsService.getIsPalliativeAccount();
        this.currentUser = await this._currentUserService.getUser();
    }

    public selectOrder(order: IOpenOrder) {
        this.orderId = order.orderId;
    }

    public openOrder(order: IOpenOrder) {
        window.open(`/note/${order.orderId}`);
    }

    public closeOrderSelection() {
        if (this.orderId) {
            this.isChooseOrderRequested = false;
        } else {
            this.closeAttachOrder();
        }
    }

    public openNewOrder() {
        this.isNewOrderRequested = true;
    }

    public async getPhysicians(filter: string, limit: number) {
        try {
            let physicians = await this._physicianService.getPhysiciansByName({
                name: filter,
                page: 1,
                pageLength: limit,
                providerIds: [this.providerId]
            });
            return physicians.items?.map((physician) => ({
                name: this.getPhysicianName(physician.name) + ` - NPI: ${physician.npi}`,
                value: physician.id
            }));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public getPhysicianName(fullName: string) {
        let names = fullName.split(" ");
        return formatName(names[0], names[1]);
    }

    public async createPhysicianOrder() {
        let res = await this.validationController.validate();
        if (res.valid) {
            try {
                this.isNewOrderInProgress = true;
                let createOrderResponse;
                let physicianNameSplit = this.orderSelectedPhysician.name.split(", ");
                let physicianId = this.orderSelectedPhysician.value;
                let physicianFirstName = physicianNameSplit[1];
                let physicianLastName = physicianNameSplit[0];
                let query = {
                    patientId: this.patientId,
                    scheduleDate: this.orderDate,
                    physicianId: this.orderSelectedPhysician.value,
                    physicianFirstName,
                    physicianLastName
                };
                if (!this.isPalliativeCareView) {
                    createOrderResponse = await this._tasksService.createOrder(query);
                } else {
                    createOrderResponse = await this._tasksService.createPalliativeCareOrder(query);
                }
                this._toastrService.success({
                    title: `Physician Order Created and Selected`,
                    message: `Updates on ${this.entity} for the patient would be available on the new Order created.`
                });
                this.openOrders.push({
                    physicianFirstName,
                    physicianLastName,
                    physicianId,
                    orderId: createOrderResponse.id,
                    orderDate: this.orderDate,
                    orderStatus: "Saved"
                });
                this.orderId = createOrderResponse.id;
                this.isChooseOrderRequested = false;
                this.isNewOrderRequested = false;
            } catch (e) {
                this._toastrService.error({
                    title: `Error`,
                    message: `There was a problem while creating a Physician Order. Please try again.`
                });
            } finally {
                this.isNewOrderInProgress = false;
            }
        }
    }

    public async orderSelectedPhysicianChanged(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions) {
        let npiIndex = newValue?.name?.indexOf(" - NPI");
        if (npiIndex > 0 && newValue?.name) {
            let name = newValue?.name?.slice(0, npiIndex + 1);
            this.orderSelectedPhysician.name = name;
        }
    }

    public closeNewOrder() {
        this.isNewOrderRequested = false;
        this.orderDate = null;
    }

    public changeOrder() {
        this.isChooseOrderRequested = true;
    }

    public initValidation() {
        ValidationRules.ensure((x: AttachPhysicianOrder) => x.orderDate)
            .required()
            .withMessage("Order Date is required.")
            .on(this);
    }

    public handleExit() {
        if (this.urlNoteId) {
            this._router.navigate(`/note/${this.urlNoteId}`);
        } else {
            this.closeAttachOrder();
        }
    }
}
