import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { bindable, bindingMode, customElement, observable } from "aurelia-framework";
import * as moment from "moment";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IAgencyTaskLeanResult } from "../../../interfaces/i-get-agency-task";
import { IGetAvailablePatientPayer, IGetPatientsSlimInfoResult } from "../../../interfaces/i-get-patient";
import { NewTask } from "../../../models/new-task";
import { EnumsService } from "../../../services/enums-service";
import { PatientsService } from "../../../services/patient-service";
import { AddTaskEntity } from "./add-task-entity";

@autoinject
@customElement("add-task-form")
export class AddTaskForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<AddTaskForm>("patientChanged")
    })
    public patient: IGetPatientsSlimInfoResult;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isOutsideFrequency: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public employeeForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<AddTaskForm>("newTaskChanged") })
    public newTask: NewTask = new NewTask();
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPalliativeCareView: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public currentLineOfService: number = 0;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public selectedCustomTask: IAgencyTaskLeanResult = null;
    private readonly _enumsService: EnumsService;
    private readonly _patientsService: PatientsService;
    @observable({ changeHandler: nameof<AddTaskForm>("shiftStartTimeChanged") })
    public shiftStartTime: string;
    public addTaskEntity: AddTaskEntity;
    public shiftLength: number = 0;
    public shiftEndTime: string;
    public isDateSelected: boolean = false;
    public isPatientSelected: boolean = false;
    public patientPayers: IGetAvailablePatientPayer[] = [];
    public payerTypes: IEnumResponse[] = [];
    public payerTypeEnum: IEnumResponse[] = [];
    public payerTypeEnumMap: EnumMap = new EnumMap([]);

    @computedFrom(`${nameof<AddTaskForm>("newTask")}.${nameof<NewTask>("startDates")}`)
    public get newTaskStartDates() {
        return this.newTask?.startDates?.filter((startDate) => moment(startDate, "MM/DD/YYYY", true).isValid());
    }

    @computedFrom(
        nameof<AddTaskForm>("newTask"),
        `${nameof<AddTaskForm>("newTask")}.${nameof<NewTask>("patientPayerId")}`,
        `${nameof<AddTaskForm>("newTask")}.${nameof<NewTask>("startDates")}`,
        nameof<AddTaskForm>("patientPayers")
    )
    public get payerWarningDates() {
        if (!this.newTask?.patientPayerId || this.newTask?.startDates?.length === 0) {
            return [];
        }
        let selectedPayer = this.patientPayers.find((payer) => payer.id === this.newTask.patientPayerId);
        if (!selectedPayer?.endDate) {
            return [];
        }
        return this.newTask.startDates.filter((startDate) => moment(startDate).isAfter(moment(selectedPayer.endDate)));
    }

    public constructor(enumsService: EnumsService, patientsService: PatientsService) {
        this._enumsService = enumsService;
        this._patientsService = patientsService;
    }

    public async attached() {
        this.payerTypeEnum = await this._enumsService.getPayorType();
        this.payerTypeEnumMap = new EnumMap(this.payerTypeEnum);
        if (!this.employeeForm) {
            this.isPatientSelected = true;
        }
        this.taskDateChanged();
    }

    public taskDateChanged() {
        this.addTaskEntity?.taskDateChanged();
        this.resetShiftStartTime();
        if (this.newTask.startDates?.length > 0) {
            this.isDateSelected = true;
        } else {
            this.isDateSelected = false;
            this.resetShiftOnCall();
        }
        this.updateAvailablePayors();
    }

    public async selectedPatientChanged() {
        this.isPatientSelected = !!this.newTask.patientId;
        if (!this.isPatientSelected) {
            this.patientPayers = [];
        }
        await this.updateAvailablePayors();
    }

    public patientChanged() {
        this.selectedPatientChanged();
    }

    public async updateAvailablePayors() {
        if (!this.newTask.startDates.length || !this.patient) {
            this.patientPayers = [];
            return;
        }
        let startDates = this.newTask.startDates.map((date) => moment(date));
        let startDate = moment.min(startDates).format("MM/DD/YYYY");
        let endDate = moment.max(startDates).format("MM/DD/YYYY");
        this.patientPayers = await this._patientsService.getAvailablePayorTypesByDate(this.patient.id, {
            startDate,
            endDate,
            includeCharityPayer: true
        });
        this.payerTypes = this.payerTypeEnum.filter((type) =>
            this.patientPayers.some((patientPayor) => patientPayor.payerType === type.value)
        );
        let primaryPayer = this.patientPayers?.find((payer) =>
            this.payerTypeEnumMap.checkEnumValue("Primary", payer.payerType)
        );
        this.newTask.patientPayerId = primaryPayer?.id ?? null;
    }

    private resetShiftOnCall() {
        this.newTask.onCall = false;
        this.shiftStartTime = "";
        this.shiftLength = 0;
    }

    private resetShiftStartTime() {
        this.shiftStartTime = "";
        this.shiftLength = 0;
        this.shiftStartTimeChanged();
    }

    public shiftStartTimeChanged() {
        let startDates = this.newTask.startDates;
        if (this.shiftLength > 0 && startDates && startDates.length > 0) {
            this.newTask.startDates = startDates.map((item) => {
                item = moment(item).format("MM/DD/YYYY");
                let startDate = new Date(`${item} ${this.shiftStartTime}`).toISOString();
                return moment(startDate).format("MM/DD/YYYY HH:mm");
            });
            this.newTask.endDates = [];
            this.shiftEndTime = moment(this.shiftStartTime, "hh:mm A").add(this.shiftLength, "hours").format("hh:mm A");
            this.newTask.startDates.forEach((item) => {
                let endDate = moment(item).add(this.shiftLength, "hours").format("MM/DD/YYYY HH:mm");
                this.newTask.endDates.push(endDate);
            });
        }

        if (this.shiftLength == 0 && startDates.length > 0) {
            this.newTask.startDates = startDates.map((item) => {
                item = moment(item).format("MM/DD/YYYY");
                let startDate = new Date(`${item}`).toISOString();
                return moment(startDate).format("MM/DD/YYYY");
            });
            this.newTask.endDates = [];
        }
    }

    public newTaskChanged(newTask: NewTask, oldTask: NewTask) {
        if (newTask.taskId !== oldTask.taskId) {
            this.shiftLength = 0;
            this.shiftStartTime = "";
        }
    }

    public getSelectedPayerName(patientPayerId: string) {
        if (!this.patientPayers) {
            return "";
        }
        let selectedPayer = this.patientPayers.find((payer) => payer.id === patientPayerId);
        return selectedPayer?.name ?? "";
    }
}
