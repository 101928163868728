import "../../../pages/note/note.scss";
import "./page-note.scss";

import { EventAggregator } from "aurelia-event-aggregator";
import {
    autoinject,
    bindable,
    bindingMode,
    computedFrom,
    containerless,
    customElement,
    observable
} from "aurelia-framework";
import { validateTrigger, ValidationController, ValidationControllerFactory } from "aurelia-validation";

import nameof from "../../../common/nameof";
import { IElement } from "../../../interfaces/form-builder/i-element";
import { IFormSchema } from "../../../interfaces/form-builder/i-form-schema";
import { IFormModel, IModelSchema } from "../../../interfaces/form-builder/i-model-schema";
import { IHighlightQuestion } from "../../../interfaces/form-builder/i-navigator";
import { ITooltip } from "../../../interfaces/form-builder/i-tooltips";
import { ElementTypesEnum } from "../element-types-enum";
import { GroupingTypesEnum } from "../form-grouping/grouping-types-enum";
import { NavigatorTheme } from "../form-navigator/navigator-data-helper";
import { NoteDataManager } from "../note-data-manager";
import { IPatientVisitInfoModel } from "../../../services/i-task-service";
import { TaskService } from "../../../services/task-service";
import { ToastrService } from "../../../services/toastr-service";

export interface IFormInfo {
    formSchema: IFormSchema;
    formModel: IFormModel;
    modelSchema: IModelSchema;
    // metaSchema?: IMetaSchema; O(1)
    // skeletonSchema?: ISkeletonSchema;
    tooltips?: ITooltip[];
}

@customElement("page-note")
@autoinject
@containerless
export class PageNote {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public refreshPatientVisitInfo: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public taskId: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<PageNote>("patientIdChanged") })
    public patientId: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({ changeHandler: nameof<PageNote>("currentTabChanged") })
    public currentTab: string = "";
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public isLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public previousUrl: string = "/";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public prefillLoadingText: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isPrefillInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isSaveInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hideForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isCheckErrorInProgress: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public patientVisitInfo: IPatientVisitInfoModel = null;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showDefaultStateLoader: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({ changeHandler: nameof<PageNote>("readOnlyFormChanged") })
    public readOnlyForm: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public getFormInfo: () => Promise<IFormInfo>;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public checkNoteErrors: () => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public save: () => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public tryAgain: () => void;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public updateTabInRoute: (params: { tab: string }) => void;
    private _ea: EventAggregator;
    private _toastrService: ToastrService;
    private _taskService: TaskService;
    public formSchema: IFormSchema;
    public noteModel: IFormModel;
    public modelSchema: IModelSchema;
    public tooltips: ITooltip[] = [];
    public noteDataManager: NoteDataManager;
    public elements: IElement[];
    public tabs: IElement[];
    public controller: ValidationController;
    public selectedTabContent: IElement;
    public isNavigatorExpanded: boolean = false;
    public isNoteUnlockLoading: boolean = false;

    @computedFrom(nameof<PageNote>("tabs"))
    public get hasTabs(): boolean {
        return this.tabs?.length > 1;
    }

    @computedFrom(
        nameof<PageNote>("hideForm"),
        nameof<PageNote>("isLoading"),
        nameof<PageNote>("isError"),
        nameof<PageNote>("isSaveInProgress"),
        nameof<PageNote>("isCheckErrorInProgress"),
        nameof<PageNote>("isPrefillInProgress"),
        nameof<PageNote>("selectedTabContent")
    )
    public get showForm(): boolean {
        return (
            !this.hideForm &&
            !this.isError &&
            !this.isLoading &&
            !this.isSaveInProgress &&
            !this.isCheckErrorInProgress &&
            !this.isPrefillInProgress &&
            !!this.selectedTabContent
        );
    }

    public constructor(
        toastrService: ToastrService,
        taskService: TaskService,
        ea: EventAggregator,
        noteDataManager: NoteDataManager,
        controllerFactory: ValidationControllerFactory
    ) {
        this._toastrService = toastrService;
        this._taskService = taskService;
        this._ea = ea;
        this.noteDataManager = noteDataManager;
        this.noteDataManager.setIsReadOnly(false);
        this.controller = controllerFactory.createForCurrentScope();
        this.controller.validateTrigger = validateTrigger.changeOrBlur;
    }

    public attached() {
        this.initNote();
    }

    public patientIdChanged() {
        if (!!this.patientId) {
            this.initNote();
        }
    }

    public readOnlyFormChanged() {
        this.noteDataManager.setIsReadOnly(this.readOnlyForm);
    }

    public async currentTabChanged(newTab: string, oldTab?: string) {
        if (this.hasTabs) {
            if (!!this.save) {
                // TODO: The implementation her take from as of 08/26/2020
                // does not handle this save fail properly
                // When a tab is switched and the save fails, the user stays on the previous tab without any message
                // More details: Check TODO comment in save() in note.ts
                // INFO: This try..catch needs to be uncommented
                // try {
                await this.save();
                // } catch (e) {
                //     console.error(e);
                //     // Silent Fail
                // }
            }

            this.getContent();

            if (this.updateTabInRoute && !!this.selectedTabContent) {
                this.updateTabInRoute({
                    tab: this.selectedTabContent.name
                });
            }
        } else {
            this.getContent();
        }
    }

    public async initNote() {
        if (!this.getFormInfo) {
            console.warn("Get method not loaded yet.");
            return;
        }

        if (this.isLoading) {
            console.log("Initializing already in progress.");
            return;
        }

        try {
            this.isError = false;
            this.isLoading = true;
            let response = await this.getFormInfo();
            if (!!response) {
                this.formSchema = response.formSchema;
                this.noteModel = response.formModel;
                this.modelSchema = response.modelSchema;
                // this.metaSchema = response.metaSchema;
                // this.skeletonSchema = response.skeletonSchema;
                this.tooltips = response.tooltips;
                // if (this.skeletonSchema) {
                //     this.elements = this.skeletonSchema?.elements;
                // } else {
                this.elements = this.formSchema?.elements;
                // }

                this.initMetaData();
                this.generateForm();
                this.tabs = this.elements.filter(
                    (elem) =>
                        // let elementMetaInfo = this.metaSchema[elem.name];
                        // elementMetaInfo.elementType === ElementTypesEnum.Grouping
                        // && elementMetaInfo.groupingType === GroupingTypesEnum.Tab
                        elem.elementType === ElementTypesEnum.Grouping &&
                        elem.groupingType === GroupingTypesEnum.Tab &&
                        elem.elements &&
                        elem.elements.length > 0
                );

                if (!this.currentTab && this.hasTabs) {
                    this.currentTab = this.tabs[0].name;
                    // console.log("No current tab found but has tabs set current tab to", this.currentTab);
                } else {
                    this.currentTabChanged(this.currentTab);
                }
            }
        } catch (e) {
            console.error(e);
            this.isError = true;
        } finally {
            this.isLoading = false;
        }
    }

    private initMetaData() {
        if (this.patientId) {
            this.noteDataManager.setPatientId(this.patientId);
        }
    }

    private generateForm() {
        this.noteDataManager.setFormSchema(this.formSchema);
        this.noteDataManager.setNoteModel(this.noteModel);
        this.noteDataManager.setModelSchema(this.modelSchema);
        this.noteDataManager.setToolTips(this.tooltips);
    }

    private getContent() {
        if (this.hasTabs && !!this.currentTab) {
            console.log("Setting content: ", this.currentTab, this.hasTabs);
            this.selectedTabContent = this.tabs.find((elem) => elem.name === this.currentTab);
        } else if (!!this.formSchema) {
            console.log("Set content: Has no tab", this.hasTabs);
            this.selectedTabContent = this.formSchema.elements[0];
        } else {
            console.log("No Tabs loaded & No Current Tab & No Form Schema; That means nothing to see yet.");
        }
    }

    public async validate() {
        // TODO: It might be better to validate on the parent
        // Since the parent could use one validation controller to validate multiple forms?
        // Not sure.
        return await this.controller.validate();
    }

    public focusElement(question: IHighlightQuestion) {
        if (this.hasTabs) {
            this.currentTab = question.tab;
            setTimeout(() => {
                this.highlightElement(question.code, question.theme);
            }, 0);
        } else {
            this.highlightElement(question.code, question.theme);
        }
    }

    private highlightElement(code: string, theme: NavigatorTheme) {
        let activeElement = document.getElementById(code);
        if (activeElement) {
            activeElement.scrollIntoView();

            this._ea.publish("Reset :: Highlight Form Input");
            this._ea.publish("Highlight Form Input", {
                code,
                type: this.themeToType(theme)
            });
        }
    }

    private themeToType(theme: NavigatorTheme) {
        switch (theme) {
            case NavigatorTheme.Error:
                return "errors";
            case NavigatorTheme.Info:
                return "inconsistencies";
            case NavigatorTheme.Warning:
                return "warnings";
        }
        return null;
    }

    public async unlockVisitNote() {
        try {
            this.isNoteUnlockLoading = true;
            let visitData = {
                taskId: this.taskId,
                deviceName: this.patientVisitInfo.deviceName,
                deviceOs: this.patientVisitInfo.deviceOs,
                toggleLock: false
            };
            await this._taskService.unlockVisit(this.patientId, visitData);
            this.refreshPatientVisitInfo();
            this._toastrService.success({
                title: "Success",
                message: `Visit Note Unlocked.`
            });
        } catch (err) {
            console.error(err);
            this._toastrService.error({
                title: "Error",
                message: `There was a problem while unlocking the visit note. Please try again.`
            });
        } finally {
            this.isNoteUnlockLoading = false;
        }
    }
}
