// import "./reports-layout.scss";
import {autoinject, bindable, bindingMode, containerless} from "aurelia-framework";
import {Router} from "aurelia-router";

@autoinject
@containerless
export class ReportsLayout {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageTitle: string;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public exportReport: () => void;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public exportCsvReport: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public showExportButton: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public includeCsvExport: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isExportLoading: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isCsvExportLoading: boolean = false;
    public router: Router;

    public constructor(router: Router) {
        this.router = router;
    }

    public goToReportCenter() {
        this.router.navigate("/reports/report-center");
    }
}
