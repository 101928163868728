import "./message-input-handler.scss";

import { bindable, bindingMode, computedFrom, containerless, customElement, observable } from "aurelia-framework";
import * as $ from "jquery";
import turndownService from "turndown";

import nameof from "../../../common/nameof";
import { IToolbarCustomButtons } from "../../../interfaces/i-summer-editor";
import { SummerEditor } from "../summer-editor/summer-editor";

interface IFileOption {
    fileName: string;
    fileUrl: string;
    iconClass: string;
}

@containerless
@customElement("message-input-handler")
export class MessageInputHandler {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public initialMessage: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isImportant: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public expand: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public showAttachmentLimitError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public files: File[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public send: (sendParams: { message?: string }) => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public toggleImportant: () => void;
    private _turndownService: turndownService = new turndownService();
    @observable({
        changeHandler: nameof<MessageInputHandler>("uploadFileChanged")
    })
    public uploadFile: FileList;
    public result: string;
    public summerEditorRef: SummerEditor;
    public uploadMarkupRef: HTMLInputElement;

    public markAsImportantButton = () => {
        let ui = ($ as any).summernote.ui;

        // create button
        let button = ui.button({
            contents: `<i class="fas fa-exclamation"/> Mark as Important`,
            tooltip: "Mark as Important",
            click: () => {
                this.toggleImportant();
            }
        });

        return button.render(); // return button as jquery object
    }

    public sendMessageButton = () => {
        let ui = ($ as any).summernote.ui;

        // create button
        let button = ui.button({
            contents: `<i class="far fa-paper-plane" id="Send__Message""/>`,
            tooltip: "Click to Send Message",
            click: () => {
                this.sendMessage();
            }
        });

        return button.render(); // return button as jquery object
    }

    public attachFileButton = () => {
        let ui = ($ as any).summernote.ui;

        // create button
        let button = ui.button({
            contents: `<i class="fas fa-paperclip" />`,
            tooltip: "Click to Attach a File",
            click: () => {
                this.triggerFileUpload();
            }
        });

        return button.render(); // return button as jquery object
    }

    public customButtons: IToolbarCustomButtons = {
        important: this.markAsImportantButton,
        attach: this.attachFileButton,
        send: this.sendMessageButton
    };
    public editorOptions: any = {
        buttons: this.customButtons
    };

    @computedFrom(nameof<MessageInputHandler>("files"))
    public get filesUI() {
        let filesUI: IFileOption[] = [];
        this.files.forEach((file) => {
            let fileOption: IFileOption = {
                fileName: "",
                fileUrl: "",
                iconClass: "fa-file"
            };
            if (!file.type.match("image.*")) {
                fileOption.iconClass = this.getFileIcon(file.type);
                fileOption.fileName = file.name;
                filesUI.push(fileOption);
                return;
            }
            let reader = new FileReader();
            reader.onload = (e: any) => {
                fileOption.fileUrl = e.target.result;
                fileOption.fileName = file.name;
                filesUI.push(fileOption);
            };
            reader.readAsDataURL(file);
        });
        return filesUI;
    }

    public removeFile(fileName: string) {
        this.files = this.files.filter((file) => file.name !== fileName);
        this.expand = this.files.length > 0;
    }

    private getFileIcon(type: string) {
        if (type.includes("wordprocessing")) {
            return "fa-file-word";
        } else if (type.includes("spreadsheet")) {
            return "fa-file-excel";
        } else if (type.includes("pdf")) {
            return "fa-file-pdf";
        } else {
            return "fa-file";
        }
    }

    public uploadFileChanged(newValue: FileList) {
        this.showAttachmentLimitError = newValue.length > 25;
        this.files = [...this.files, ...Array.from(newValue)];
        this.expand = this.files.length > 0;
    }

    public async sendMessage() {
        let params: {
            message?: string;
        } = {};
        if (!!this.result && this.result.length > 0) {
            let message = this.getMarkdownFromHtml(this.result);
            this.summerEditorRef.reset();
            if (!!message && message.length > 0) {
                params.message = message;
            }
        }
        if (Object.keys(params).length > 0 || this.files.length > 0) {
            await this.send(params);
        }
    }

    public triggerFileUpload() {
        $(this.uploadMarkupRef).trigger("click");
    }

    public getMarkdownFromHtml(html: string) {
        return this._turndownService.turndown(html);
    }
}
