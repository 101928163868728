import "./claim-errors.scss";

import {bindable, bindingMode, computedFrom, containerless} from "aurelia-framework";

import nameof from "../../../common/nameof";
import {IClaimErrorMessage} from "../../../interfaces/billing/i-claim";

@containerless
export class ClaimErrors {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public errorMessages: IClaimErrorMessage[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isExpanded: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isFullHeight: boolean = false;
    public minErrorsCount: number = 2;

    @computedFrom(
        `${nameof<ClaimErrors>("isExpanded")}`,
        `${nameof<ClaimErrors>("errorMessages")}`
    )
    public get displayMessages() {
        if (this.isExpanded) {
            return this.errorMessages;
        } else {
            return this.errorMessages.filter((message, index) => index < this.minErrorsCount);
        }
    }

    public expandErrors() {
        this.isExpanded = true;
    }

    public collapseErrors() {
        this.isExpanded = false;
    }
}
