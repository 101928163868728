import toastr from "toastr";

import {INotifyOptions} from "../interfaces/i-notify-options";
import {SanitizeMarkup} from "./../resources/value-converters/sanitize-markup";

// https://codeseven.github.io/toastr/demo.html
export class ToastrService {
    private sanitizeMarkup: SanitizeMarkup;
    public options: ToastrOptions = {
        closeButton: true,
        newestOnTop: true,
        progressBar: true,
        positionClass: "toast-top-center",
        showDuration: 300,
        hideDuration: 300,
        timeOut: 5000,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        iconClass: "",
    };

    public constructor() {
        toastr.options = this.options;
        this.sanitizeMarkup = new SanitizeMarkup();
    }

    public success(params: INotifyOptions) {
        let message = params.message;
        if (Array.isArray(params.message)) {
            message = this.convertMessageListToString(params.message);
        }
        let sanitizedMessage = this.sanitize(message as string);
        toastr.success(sanitizedMessage, params.title, params.options);
    }

    public error(params: INotifyOptions) {
        let message = params.message;
        if (Array.isArray(params.message)) {
            message = this.convertMessageListToString(params.message);
        }
        let sanitizedMessage = this.sanitize(message as string);
        toastr.error(sanitizedMessage, params.title, params.options);
    }

    public info(params: INotifyOptions) {
        let message = params.message;
        if (Array.isArray(params.message)) {
            message = this.convertMessageListToString(params.message);
        }
        let sanitizedMessage = this.sanitize(message as string);
        toastr.info(sanitizedMessage, params.title, params.options);
    }

    public warning(params: INotifyOptions) {
        let message = params.message;
        if (Array.isArray(params.message)) {
            message = this.convertMessageListToString(params.message);
        }
        let sanitizedMessage = this.sanitize(message as string);
        toastr.warning(sanitizedMessage, params.title, params.options);
    }
    public clear() {
        toastr.clear();
    }

    private sanitize(message: string) {
        message = this.sanitizeMarkup.toView(message);
        return message
            .replace(/<script>/gi, "&lt;script&gt;")
            .replace(/<\/script>/gi, "&lt;/script&gt;");
    }

    private convertMessageListToString(messageList: string[]) {
        return messageList.length === 1 ? messageList[0]
            : ("<ol>"
                + messageList.map(message => "<li>" + message + "</li>").join("")
                + "</ol>");
    }
}
