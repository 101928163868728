import { autoinject, customElement, bindable, bindingMode } from "aurelia-framework";
import { BranchesService } from "../../../services/branches-service";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";

@customElement("page-filters")
@autoinject
export class PageFilters {
    public async attached() {
        let branches = await this._branchesService.getAllBranches();
        this.branches = branches.map(branch => ({
            name: branch.name,
            value: branch.id
        }));
    }
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public hasFilters: boolean;
    private _branchesService: BranchesService;
    public constructor(branchesService: BranchesService) {
        this._branchesService = branchesService;
    }
    public branches: ITypeaheadOptions[] = [];
    public selectedBranches: ITypeaheadOptions[] = [];

    public async branchesFetch(filter: string): Promise<ITypeaheadOptions[]> {
        return this.branches.filter(
            x => !filter || x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        );
    }
}
