import { autoinject } from "aurelia-framework";
import printJS, { PrintTypes } from "print-js";

import { IBlobResponse } from "../interfaces/i-hospice-client";
import { IGetAssetResponse } from "./i-assets-service";
import { DownloadService } from "./download-service";
import { DeviceService } from "./device-service";

// DOCUMENTATION: https://printjs.crabbly.com/
// https://stackoverflow.com/questions/16239513/print-pdf-directly-from-javascript
@autoinject()
export class PrintService {
    private _downloadService: DownloadService;
    private _deviceService: DeviceService;

    public constructor(downloadService: DownloadService, deviceService: DeviceService) {
        this._downloadService = downloadService;
        this._deviceService = deviceService;
    }

    public print(file: IBlobResponse) {
        let isMobileTablet = this._deviceService.isMobileTablet();
        if (isMobileTablet) {
            this._downloadService.downloadFile(file);
            return;
        }
        let fileName = this.extractFileNameFromResponseHeader(file);
        let pdfFile = new Blob([file.body], {
            type: "application/pdf"
        });
        let pdfUrl = URL.createObjectURL(pdfFile);
        printJS({
            printable: pdfUrl,
            documentTitle: fileName
        });
    }

    // print from url only works for pdf, html, image, json and raw-html
    public printFileFromUrl(assetFile: IGetAssetResponse) {
        // setting default type as pdf
        // TODO: handle cases for html, json and raw-html
        let urlPrintOptions = {
            printable: assetFile.url,
            documentTitle: assetFile.name,
            type: "pdf" as PrintTypes
        };
        if (assetFile.mimeType.includes("image")) {
            urlPrintOptions.type = "image" as PrintTypes;
        }
        printJS(urlPrintOptions);
    }

    private extractFileNameFromResponseHeader(response: IBlobResponse): string {
        let responseHeader: string = response.headers.get("content-disposition");
        return responseHeader.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
    }
}
