import { autoinject } from "aurelia-framework";
import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { AdminRoutes } from "../admin/admin-routes";
import { BillingRoutes } from "../billing/billing-routes";
import { CreateRoutes } from "../create/create-routes";
import { HomeRoutes } from "../home/home-routes";
import { EmployeesRoutes } from "../employees/employees-routes";
import { CentersRoutes } from "../idg/centers-routes";
import { ListRoutes } from "../list/list-routes";
import { PatientsRoutes } from "../patients/patients-routes";
import { ReportRoutes } from "../reports/report-routes";
import { ScheduleRoutes } from "../schedule/schedule-routes";

export enum RouteName {
    Home = "home",
    Patients = "patients",
    Schedule = "schedule",
    Centers = "centers",
    Employees = "employees",
    Billing = "billing",
    Admin = "admin",
    Reports = "reports",
    List = "list",
    Create = "create",
    MessageCenter = "message-center",
    Notes = "notes",
    Note = "note",
    CreateNote = "create-note",
    OidcSignIn = "oidc-sign-in",
    OidcSignInRedirect = "oidc-sign-in-redirect",
    OidcSignInSilent = "oidc-sign-in-silent",
    OidcSelectAccounts = "oidc-select-accounts",
    NotAuthorized = "not-authorized"
}

@autoinject
export class AppRoutes {
    private _appRoutes: RouteConfig[] = [
        {
            name: "root",
            route: "",
            redirect: "home"
        },
        {
            name: RouteName.Home,
            route: RouteName.Home,
            moduleId: PLATFORM.moduleName("../home/home", "home"),
            nav: true,
            title: "Home",
            settings: {
                iconClass: "fas fa-home",
                subRoutes: HomeRoutes,
                menuName: "Home"
            }
        },
        {
            name: RouteName.Patients,
            route: RouteName.Patients,
            moduleId: PLATFORM.moduleName("../patients/patients", "patients"),
            nav: true,
            settings: {
                iconClass: "far fa-user",
                subRoutes: PatientsRoutes,
                menuName: "Patients"
            }
        },
        {
            name: RouteName.Schedule,
            route: RouteName.Schedule,
            moduleId: PLATFORM.moduleName("../schedule/schedule", "schedule"),
            nav: true,
            settings: {
                iconClass: "far fa-calendar-alt",
                subRoutes: ScheduleRoutes,
                menuName: "Schedule"
            }
        },
        {
            name: RouteName.Centers,
            route: RouteName.Centers,
            moduleId: PLATFORM.moduleName("../idg/idg", "idg"),
            nav: true,
            settings: {
                iconClass: "fas fa-users",
                subRoutes: CentersRoutes,
                menuName: "Centers"
            }
        },
        {
            name: RouteName.Employees,
            route: RouteName.Employees,
            moduleId: PLATFORM.moduleName("../employees/employees", "employees"),
            nav: true,
            settings: {
                iconClass: "far fa-address-card",
                subRoutes: [],
                menuName: "People"
            }
        },
        {
            name: RouteName.Billing,
            route: RouteName.Billing,
            moduleId: PLATFORM.moduleName("../billing/billing", "billing"),
            nav: true,
            settings: {
                iconClass: "far fa-money-bill-alt",
                subRoutes: [],
                menuName: "Billing"
            }
        },
        {
            name: RouteName.Admin,
            route: RouteName.Admin,
            moduleId: PLATFORM.moduleName("../admin/admin", "admin"),
            nav: true,
            settings: {
                iconClass: "fas fa-wrench",
                subRoutes: AdminRoutes,
                menuName: "Admin"
            }
        },
        {
            name: RouteName.Reports,
            route: RouteName.Reports,
            moduleId: PLATFORM.moduleName("../reports/reports", "reports"),
            nav: true,
            settings: {
                iconClass: "far fa-chart-bar",
                subRoutes: ReportRoutes,
                menuName: "Reports"
            }
        },
        {
            name: RouteName.List,
            route: RouteName.List,
            moduleId: PLATFORM.moduleName("../list/list", "list"),
            nav: false,
            settings: {
                subRoutes: ListRoutes,
                menuName: "List"
            }
        },
        {
            name: RouteName.Create,
            route: RouteName.Create,
            moduleId: PLATFORM.moduleName("../create/create", "create"),
            nav: false,
            settings: {
                subRoutes: CreateRoutes,
                menuName: "Add"
            }
        },
        {
            name: RouteName.MessageCenter,
            route: RouteName.MessageCenter,
            moduleId: PLATFORM.moduleName("../message-center/message-center", "message-center"),
            settings: {
                menuName: "Message Center",
                keywords: ["message", "send", "receive", "mc"]
            }
        },
        {
            name: RouteName.Notes,
            route: RouteName.Notes,
            moduleId: PLATFORM.moduleName("../notes/notes", "notes"),
            nav: false
        },
        {
            name: RouteName.Note,
            route: `${RouteName.Note}/:id`,
            moduleId: PLATFORM.moduleName("../note/note", "note")
        },
        {
            name: RouteName.CreateNote,
            route: RouteName.CreateNote,
            moduleId: PLATFORM.moduleName("../create-note/create-note", "create-note")
        },
        {
            name: RouteName.OidcSignIn,
            route: "oidc/sign-in",
            moduleId: PLATFORM.moduleName("../oidc/sign-in-start"), // This cannot be in a nested folder for some reason...
            title: "Oidc - Sign-In",
            settings: {
                anonymous: true
            }
        },
        {
            name: RouteName.OidcSignInRedirect,
            route: "oidc/sign-in-redirect",
            moduleId: PLATFORM.moduleName("../oidc/sign-in-redirect"), // This cannot be in a nested folder for some reason...
            title: "Oidc - Sign-In-Redirect",
            settings: {
                anonymous: true
            }
        },
        {
            name: RouteName.OidcSignInSilent,
            route: "oidc/sign-in-silent",
            moduleId: PLATFORM.moduleName("../oidc/sign-in-silent"), // This cannot be in a nested folder for some reason...
            title: "Oidc - Sign-In-Silent",
            settings: {
                anonymous: true
            }
        },
        {
            name: RouteName.OidcSelectAccounts,
            route: "oidc/select-accounts",
            moduleId: PLATFORM.moduleName("../oidc/select-accounts"),
            settings: {
                accountLess: true
            }
        },
        {
            name: RouteName.NotAuthorized,
            route: RouteName.NotAuthorized,
            moduleId: PLATFORM.moduleName("./not-authorized", "not-authorized"),
            settings: {
                accountLess: true
            }
        }
    ];
    private _billingRoutes: BillingRoutes;
    private _employeesRoutes: EmployeesRoutes;

    public constructor(billingRoutes: BillingRoutes, employeesRoutes: EmployeesRoutes) {
        this._billingRoutes = billingRoutes;
        this._employeesRoutes = employeesRoutes;
    }

    public async getRoutes() {
        await this.addBillingRoutes();
        await this.addEmployeesRoutes();
        return this._appRoutes;
    }

    public async addEmployeesRoutes() {
        let adminRoutes = await this._employeesRoutes.getRoutes();
        if (!!adminRoutes && adminRoutes.length > 0) {
            let AdminPosition = this._appRoutes.findIndex((route) => route.name === RouteName.Employees);
            if (AdminPosition > -1) {
                this._appRoutes[AdminPosition].settings.subRoutes = adminRoutes;
            }
        }
    }

    public async addBillingRoutes() {
        let billingRoutes = await this._billingRoutes.getRoutes();
        if (!!billingRoutes && billingRoutes.length > 0) {
            let billingPosition = this._appRoutes.findIndex((route) => route.name === RouteName.Billing);
            if (billingPosition > -1) {
                this._appRoutes[billingPosition].settings.subRoutes = billingRoutes;
            }
        }
    }
}
