import "./page-layout.scss";

import { bindingMode, computedFrom } from "aurelia-binding";
import { autoinject, bindable, containerless, customElement } from "aurelia-framework";
import { Router } from "aurelia-router";

import nameof from "../../../common/nameof";

@customElement("page-layout")
@containerless
@autoinject
export class PageLayout {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public hasSideNav: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public hasButtons: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasTitle: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public router: Router;
    @bindable({ defaultBindingMode: bindingMode.oneTime })
    public pageTitle: string;
    public element: Element;

    @computedFrom(nameof<PageLayout>("router"))
    public get isFullPage() {
        if (this.router) {
            return this.router.parent == null;
        } else {
            return true;
        }
    }

    @computedFrom(
        `${nameof<PageLayout>("router")}.${nameof<Router>("navigation")}`
    )
    public get activeRouteName() {
        if (this.router && this.router.navigation.filter((nav) => nav.isActive).length > 0) {
            return this.router.navigation.filter((nav) => nav.isActive)[0].config.settings.menuName;
        } else {
            return "Menu";
        }
    }
}
