import {ValidationRules} from "aurelia-validation";
import * as moment from "moment";

import {IPhoneNumber} from "../interfaces/i-phone-number";

export class Inquiry {
    public id: string = "";
    public date: string;
    public followUpDate: string;
    public firstName: string;
    public middleInitial: string;
    public lastName: string;
    public phoneNumbers: IPhoneNumber[];
    public contactFirstName: string;
    public contactMiddleInitial: string;
    public contactLastName: string;
    public contactPhoneNumbers: IPhoneNumber[];
    public locationId: string = null;
    public assignedUserId: string;
    public assignedUser: string;
    public createdBy: string;
    public createdOn: string;
    public comment: string;
    public lastUpdated: string;
    public contactRelationshipToPatient: string;
    public currentLocation: string;
    public servicesRequested: number[] = [];

    public constructor(data?: Inquiry) {
        this.initValidation();
        this.date = moment().format("MM/DD/YYYY");
        this.phoneNumbers = [
            {
                number: "",
                extension: "",
                type: 1,
                country: "",
                isPrimary: true
            }
        ];
        this.contactPhoneNumbers = [
            {
                number: "",
                extension: "",
                type: 1,
                country: "",
                isPrimary: true
            }
        ];
        if (data) {
            Object.assign(this, data);
        }
    }

    private initValidation() {
        ValidationRules.ensure((x: Inquiry) => x.firstName)
            .required()
            .withMessage("First Name is required.")
            .ensure((x: Inquiry) => x.middleInitial)
            .required()
            .withMessage("Middle Initial is required.")
            .ensure((x: Inquiry) => x.lastName)
            .required()
            .withMessage("Last Name is required.")
            .ensure((x: Inquiry) => x.locationId)
            .required()
            .withMessage("Agency Branch is required.")
            .on(this);
    }
}
