import { valueConverter } from "aurelia-binding";

@valueConverter("formatStringToFloat")
export class FormatStringToFloat {
    public fromView(value: string): number {
        if (!value) {
            return null;
        }
        return parseFloat(value);
    }

    public toView(value: number): string {
        if (value == null) {
            return "";
        }
        return String(value);
    }
}
