export enum AdministrativePermissionEnum {
    Users = "Users",
    Inquiries = "Inquiries",
    Referrals = "Referrals",
    Physicians = "Physicians",
    InsurancePayers = "Insurance/Payers and Authorizations",
    Pharmacies = "Pharmacies",
    Dme = "DME",
    Vendors = "Vendors",
    Supplies = "Supplies",
    DocumentTypes = "Document Types",
    Templates = "Templates",
    CompanySetup = "Company Setup",
    MessageCenter = "Message Center",
    QaCenter = "QA Center",
    StartIdgMeeting = "Start IDG Meeting",
    UserPayroll = "User Payroll",
    CompanyPayroll = "Company Payroll",
    InfectionLog = "Infection Log/Reports",
    ChangePatientStatus = "Change Patient Status",
    TaskDetails = "Task Details",
    CustomMedications = "Custom Medications",
    Consents = "Consents",
    ProviderInformation = "Provider Information",
    GeneratePayrollExports = "Generate Payroll Exports",
    NonAdmitReasons = "Non-Admit Reasons",
    VolunteerActivities = "Volunteer Activities",
    BereavementCenter = "Bereavement Center",
    PartnerConnections = "Partner Connections",
    ActivityLog = "Activity Log",
    NonPatientActivities = "Non-Patient Activities",
    Teams = "Teams"
}
