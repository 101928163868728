import { autoinject } from "aurelia-dependency-injection";

import { IGetUserSlimInfoResult } from "../interfaces/i-user";
import { AuthService } from "./auth-service";
import { UsersService } from "./users-service";

@autoinject
export class CurrentUserService {
    private readonly _userService: UsersService;
    private readonly _authService: AuthService;
    private _currentUser: IGetUserSlimInfoResult;
    private _accountId: string;

    public constructor(userService: UsersService, authService: AuthService) {
        this._userService = userService;
        this._authService = authService;
    }

    public async getUser() {
        let isLoggedIn = await this._authService.isLoggedIn();
        if (!isLoggedIn || this._authService.needsAccountSelection()) {
            return null;
        }

        let currentAccountId = this._authService.getAccountId();
        // console.log("Get Current User: ", { currentAccountId, currentUser: this._currentUser });
        if (this._currentUser == null || currentAccountId !== this._accountId) {
            this._accountId = currentAccountId;
            this._currentUser = await this._userService.getCurrentUser();
        }

        return this._currentUser;
    }
}
