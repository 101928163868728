import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { NavModel, RouteConfig } from "aurelia-router";

import { IEnumResponse } from "../../interfaces/i-enum";
import { Permission } from "../../models/permission";
import { EnumsService } from "../../services/enums-service";
import { UsersService } from "../../services/users-service";
import { EnumMap } from "./enum-map";
import { PermissionActionEnum } from "../../enums/permission-action-enum";

export interface IPermissionCheck {
    action: PermissionActionEnum;
    resource: string;
}

export enum PermissionEvent {
    UserPermissionLoaded = "user:permission:loaded"
}

@autoinject
export class PermissionManager {
    private readonly _enumsService: EnumsService;
    private readonly _usersService: UsersService;
    private readonly _ea: EventAggregator;
    private _actions: IEnumResponse[] = [];
    private _actionsEnumMap: EnumMap = new EnumMap([]);
    private _resource: IEnumResponse[] = [];
    private _resourceEnumMap: EnumMap = new EnumMap([]);
    private _permissions: Permission[] = [];

    public constructor(enumsService: EnumsService, usersService: UsersService, ea: EventAggregator) {
        this._enumsService = enumsService;
        this._usersService = usersService;
        this._ea = ea;
    }

    private async getEnums() {
        this._resource = await this._enumsService.getParentPermission();
        this._resourceEnumMap = new EnumMap(this._resource);
        this._actions = await this._enumsService.getPermissionActions();
        this._actionsEnumMap = new EnumMap(this._actions);
    }

    public isPermissionManagerReady() {
        return !!this._resource && !!this._actions && this._permissions?.length > 0;
    }

    public checkPermission(permission: IPermissionCheck[], checkAll: boolean = false) {
        if (permission?.length > 0) {
            if (checkAll) {
                return this.hasAllPermission(permission);
            } else {
                return this.hasSomePermission(permission);
            }
        } else {
            return false;
        }
    }

    public checkRoutePermission(route: RouteConfig) {
        if (route?.settings?.permission?.length > 0) {
            return this.checkPermission(route.settings.permission, !!route.settings.checkAll);
        } else {
            return false;
        }
    }

    public checkNavModelPermission(navModel: NavModel) {
        if (navModel?.settings?.permission?.length > 0) {
            let checkAll = navModel.settings.checkAll ? navModel.settings.checkAll : false;
            return this.checkPermission(navModel.settings.permission, checkAll);
        } else {
            return false;
        }
    }

    public async refreshUserPermissionData() {
        try {
            if (this._resource?.length <= 0 || this._actions?.length <= 0) {
                await this.getEnums();
            }
            this._permissions = await this._usersService.getCurrentUserPermissions();
            this._ea.publish(PermissionEvent.UserPermissionLoaded, this._permissions);
            return this._permissions;
        } catch (e) {
            throw e;
        }
    }

    public isActionAllowed(action: number, permission: number): boolean {
        if (this._permissions?.length > 0) {
            let per = this._permissions.find((el: Permission) => {
                return parseInt(el.resource, 10) === permission && el.action === action;
            });
            return per?.value;
        }
        return false;
    }

    private hasSomePermission(permission: IPermissionCheck[]) {
        if (!!permission) {
            return permission.some((group: IPermissionCheck) => this.hasPermission(group.resource, group.action));
        } else {
            return false;
        }
    }

    private hasAllPermission(permission: IPermissionCheck[]) {
        if (!!permission) {
            return permission.every((group: IPermissionCheck) => this.hasPermission(group.resource, group.action));
        } else {
            return false;
        }
    }

    private hasPermission(resource: string, actionDesc: string) {
        if (!!resource && !!actionDesc) {
            let parentPermission = this._resourceEnumMap.getEnumValue(resource) as number;
            let action = this._actionsEnumMap.getEnumValue(actionDesc) as number;
            return this.isActionAllowed(action, parentPermission);
        } else {
            return false;
        }
    }
}
