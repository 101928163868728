import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";

import { INavigationBehavior } from "../../../interfaces/form-builder/i-behavior";
import { AccountsService } from "../../../services/accounts-service";
import { NoteDataManager } from "../note-data-manager";
import { NavigationComponentEnum } from "./navigation-component-enum";
import { IPromptOptions, Prompt } from "../../dialogs/prompt/prompt";
import { DialogOpenResult, DialogService, DialogSettings } from "aurelia-dialog";

const NavigationSubComponentValues = [
    "demographics",
    "payor-information",
    "clinical-information",
    "physician",
    "pharmacy-dme",
    "authorized-contacts",
    "emergency-preparedness",
    "advanced-directives",
    "referral-information"
];

export enum NavigationTarget {
    Self = "self",
    Target = "target"
}

enum ParamResourceType {
    FormId = "formId",
    IsAssessment = "isAssessment"
}

export enum NavigationBehaviorEvent {
    Triggered = "navigation:behavior:triggered"
}

@autoinject
export class NavigationBehaviorHandler {
    private readonly _dialogService: DialogService;
    private _taskId: string;
    private _patientId: string;
    private _isAssessment: boolean = false;
    private _ea: EventAggregator;
    private _accountsService: AccountsService;

    public constructor(ea: EventAggregator, accountsService: AccountsService, dialogService: DialogService) {
        this._ea = ea;
        this._accountsService = accountsService;
        this._dialogService = dialogService;
    }

    public async handle(buttonBehavior: INavigationBehavior, noteDataManager: NoteDataManager) {
        if (!!noteDataManager) {
            this._taskId = noteDataManager.getTaskId();
            this._patientId = noteDataManager.getPatientId();
            this._isAssessment = noteDataManager.getIsAssessment();
        }

        let url = "";
        if (buttonBehavior) {
            switch (buttonBehavior.component) {
                case NavigationComponentEnum.Patient:
                case NavigationComponentEnum.Wound:
                case NavigationComponentEnum.Infection:
                case NavigationComponentEnum.Incident:
                    url += await this.getPatientUrl(buttonBehavior.action);
                    break;
                case NavigationComponentEnum.InfectiousDiseaseScreening:
                    url += "patients/infectious-disease-profile/";
                    break;
                case NavigationComponentEnum.Medication:
                    url += "patients/medication-profile/";
                    break;
                case NavigationComponentEnum.Allergy:
                    url += "patients/allergy-profile/";
                    break;
                case NavigationComponentEnum.Frequency:
                    url += "patients/frequency-profile/";
                    break;
                case NavigationComponentEnum.PlanOfCareProfile:
                    url += "patients/plan-of-care/";
                    break;
                case NavigationComponentEnum.BereavementPlanOfCareProfile:
                    url += "patients/bereavement-plan-of-care/";
                    break;
                case NavigationComponentEnum.ConsentTracking:
                    url += "patients/consents/";
                    break;
                case NavigationComponentEnum.SymptomRatings:
                    url += "patients/symptom-ratings/";
                    break;
                case NavigationComponentEnum.VitalSigns:
                    url += "patients/vital-signs/";
                    break;
                case NavigationComponentEnum.EMar:
                    url += "patients/emar-profile/";
                    break;
                case NavigationComponentEnum.HospiceAidePlanOfCare:
                case NavigationComponentEnum.HomemakerPlanOfCare:
                    url += "note";
                    break;
                case NavigationComponentEnum.AddContact:
                    url += "patients/patient-edit/";
                    break;
            }

            switch (buttonBehavior.component) {
                case NavigationComponentEnum.HospiceAidePlanOfCare:
                case NavigationComponentEnum.HomemakerPlanOfCare:
                    url = await this.appendNoteId(url, noteDataManager);
                    break;
                default:
                    url = this.handleSubComponent(url, buttonBehavior.subComponent);
                    break;
            }

            switch (buttonBehavior.component) {
                case NavigationComponentEnum.AddContact:
                    url += "/authorized-contacts";
                    break;
            }

            url = this.constructQueryString(url, buttonBehavior.parameters);
            if (url.length > 0) {
                this._ea.publish(NavigationBehaviorEvent.Triggered, {
                    url,
                    target: buttonBehavior.target,
                    handler: this.navigate
                });
                console.log("Published nav trigger: ", { url, target: buttonBehavior.target });
            }
        }
    }

    private async getPatientUrl(action: string) {
        let url = "patients/";
        let isPalliativeCareView = await this._accountsService.getIsPalliativeAccount();
        let patientEditRoute = isPalliativeCareView ? `palliative-patient-edit/` : `patient-edit/`;
        switch (action) {
            case "view":
                url += `patient-chart/`;
                break;
            case "edit":
                url += patientEditRoute;
                break;
        }
        return url;
    }

    private async appendNoteId(url: string, noteDataManager: NoteDataManager) {
        let noteId = noteDataManager.getNoteModel().associatedNoteId;
        if (!noteId) {
            let promptOptions: IPromptOptions = {
                message: "There is no plan of care created. Please contact your administrator.",
                cancelText: null
            };
            let dialogOptions: DialogSettings = {
                viewModel: Prompt,
                model: promptOptions
            };
            let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
            await dialog.closeResult;
            // return empty url, will be handled by calling function and navigation will not proceed
            return "";
        }
        return `/${url}/${noteId}`;
    }

    private handleSubComponent(url: string, subComponent: string) {
        if (subComponent && NavigationSubComponentValues.find((item) => item === subComponent) && this._patientId) {
            url = `/${url}${this._patientId}/${subComponent}`;
        } else if (this._patientId) {
            url = `/${url}${this._patientId}`;
        }
        return url;
    }

    private constructQueryString(url: string, parameters: string[]) {
        if (!!parameters && parameters.length > 0) {
            let queryString = this.getParameters(parameters);
            if (queryString.length > 0) {
                url = `${url}?${queryString}`;
            }
        }
        return url;
    }

    private getParameters(params: string[]) {
        let queryString: string[] = [];
        params.forEach((param) => {
            switch (param) {
                case ParamResourceType.FormId:
                    queryString.push(`noteId=${this._taskId}`);
                    break;
                case ParamResourceType.IsAssessment:
                    queryString.push(`isAssessment=${this._isAssessment}`);
                    break;
            }
        });
        return queryString.join("&");
    }

    /*
     * INFO:
     * This method is part of the NavigationBehaviorEvent.Triggered event
     * Sent as a callback in the above event's publish
     * The subscriber will call this method when it is appropriate
     * The subscriber is mostly the button components in different notes
     */
    public navigate(url: string, target: string, router: Router) {
        console.log("In Navigate(): ", { url, target });
        if (target === NavigationTarget.Self) {
            router.navigate(url);
        } else {
            window.open(url);
        }
    }
}
