import {
    autoinject,
    bindable,
    bindingMode,

    containerless,
    customElement,
    observable
} from "aurelia-framework";
import nameof from "../../../../common/nameof";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IFetchDataInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IValidation } from "../../../../interfaces/form-builder/i-validation";
import { ITypeaheadOptions } from "../../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { BehaviorTypesEnum } from "../../behavior-handlers/behavior-types-enum";
import { FetchDataBehaviorHandler } from "../../behavior-handlers/fetch-data-behavior-handler";
import { NoteDataManager } from "../../note-data-manager";

@customElement("single-select-get-all-dropdown")
@containerless
@autoinject
export class SingleSelectGetAllDropDown {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryData: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IFetchDataInputOptions;
    private _fetchDataBehaviorHandler: FetchDataBehaviorHandler;
    private _taskId: string = "";
    private _patientId: string = "";
    @observable({
        changeHandler: nameof<SingleSelectGetAllDropDown>("typeaheadResultChanged")
    })
    public typeaheadResult: ITypeaheadOptions;
    public resultsLimit: number = 100;
    public noteDataManager: NoteDataManager;
    public isLoading: boolean = true;
    public isValidationLoading: boolean = true;
    public validation: IValidateCustomElement;
    public typeaheadOptions: ITypeaheadOptions[] = [];

    public constructor(fetchDataBehavior: FetchDataBehaviorHandler, noteDataManager: NoteDataManager) {
        this._fetchDataBehaviorHandler = fetchDataBehavior;
        this.noteDataManager = noteDataManager;
    }

    public async bind() {
        this._patientId = this.noteDataManager.getPatientId();
        this._taskId = this.noteDataManager.getTaskId();
        await this.getList();
        this.initValidation();
    }

    private async getList() {
        try {
            this.isLoading = true;
            let getBehavior = this.viewOptions.behaviors.find(behavior => behavior.behaviorType === BehaviorTypesEnum.Get);
            if (!getBehavior) {
                console.error("Get Behavior not found for single-select-get-all-dropdown");
                return;
            }
            let optionValues = await this._fetchDataBehaviorHandler.getList(getBehavior, this._patientId, this._taskId);
            this.typeaheadOptions = optionValues.map((option) => ({
                name: option.name as string,
                value: option.value as string
            }));
        } catch (e) {
            console.error(e);
        } finally {
            this.isLoading = false;
        }
    }

    public initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IValidation) => {
            if (rule.validationType === FormValidationEnum.Required) {
                this.validation = {
                    required: true,
                    displayName: this.viewOptions.name,
                    message: rule.message
                };
            }
        });
        this.isValidationLoading = false;
    }

    public typeaheadResultChanged(newValue: ITypeaheadOptions, oldValue: ITypeaheadOptions) {
        if (newValue || oldValue) {
            if (newValue) {
                this.result = newValue.value;
                this.secondaryData = newValue.name;
            } else {
                this.result = "";
                this.secondaryData = "";
            }
        }
    }

    public async resultChanged(newValue: any) {
        if (this.typeaheadOptions?.length === 0) {
            await this.getList();
        }
        if (!newValue) {
            return;
        }
        let resultOption = this.typeaheadOptions?.find((opt) => opt.value == this.result);
        if (resultOption) {
            this.typeaheadResult = resultOption;
        }
    }

}
