import { autoinject } from "aurelia-framework";
import { HospiceClient } from "../common/hospice-client";
import {
    IAcceptServiceUserInviteQuery,
    IAcceptServiceUserInviteResponse,
    ICreateServiceUserInviteQuery,
    ICreateServiceUserInviteResponse,
    IGetServiceUserProviders,
    IPartnerProvider
} from "./i-service-user-service";

@autoinject
export class ServiceUserService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/service-user";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getServiceUserProviders(id: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${id}/service-user-providers`)
            .fetch<IGetServiceUserProviders[]>();
    }

    public async createServiceUserInvite(query: ICreateServiceUserInviteQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/create-invite`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateServiceUserInviteResponse>();
    }

    public async acceptServiceUserInvite(query: IAcceptServiceUserInviteQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/accept-invite`)
            .useMethod("PUT")
            .useBody(query)
            .fetch<IAcceptServiceUserInviteResponse>();
    }

    public async getPartnerProviders() {
        return await this._httpClient.build(`${this._baseUrl}/partner-providers`).fetch<IPartnerProvider[]>();
    }

    public async getPartnerProvidersByProvider(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/partner-providers/${id}`).fetch<IPartnerProvider[]>();
    }
}
