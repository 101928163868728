import { bindable, bindingMode, containerless } from "aurelia-framework";
@containerless
export class CurrencyTextHandler {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public value: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public options: Intl.NumberFormatOptions;

    public getTextClass(value: number) {
        return value < 0 ? "text-danger" : "";
    }
}
