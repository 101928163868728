import "./chart.scss";

import {customElement} from "aurelia-framework";
import {bindable} from "aurelia-templating";
import Highcharts, {ChartObject, Options} from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsNoDataToDisplay from "highcharts/modules/no-data-to-display";

HighchartsMore(Highcharts);
// tslint:disable-next-line:no-var-requires
require("highcharts/modules/variable-pie")(Highcharts);
@customElement("chart")
export class Chart {
    @bindable
    public ready: () => Promise<Options>;
    private _highchart: ChartObject;
    public element: HTMLElement;
    public isLoading: boolean = false;

    public constructor() {
        HighchartsNoDataToDisplay(Highcharts);

        Highcharts.setOptions({
            chart: {
                style: {
                    fontFamily: "Open Sans, Helvetica, sans-serif"
                }
            },
            tooltip: {
                enabled: false
            },
            title: {
                text: ""
            },
            colors: [
                "#87CEEB",
                "#32CD32",
                "#BA55D3",
                "#F08080",
                "#4682B4",
                "#9ACD32",
                "#40E0D0",
                "#FF69B4",
                "#F0E68C",
                "#D2B48C",
                "#8FBC8B",
                "#6495ED",
                "#DDA0DD",
                "#5F9EA0",
                "#FFDAB9",
                "#FFA07A"
            ],
            xAxis: {
                dateTimeLabelFormats: {
                    millisecond: "%b %e", // "%A, %b %e, %H:%M:%S.%L",
                    second: "%b %e", // "%A, %b %e, %H:%M:%S",
                    minute: "%b %e", // "%A, %b %e, %H:%M",
                    hour: "%b %e", // "%A, %b %e, %H:%M",
                    day: "%b %e",
                    week: "Week from %A, %b %e, %Y",
                    month: "%B %Y",
                    year: "%Y"
                }
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            lang: {
                noData: "No data in range",
                thousandsSep: ","
            }
        });

    }

    public async requestUpdate() {
        console.log("Chart Update Request");
        let options = await this.ready();
        if (!!this._highchart) {
            this._highchart.update(options);
        }
    }

    public async attached() {
        console.log("Chart Attached");
        let options = await this.ready();
        try {
            this._highchart = Highcharts.chart(this.element, options);
            setTimeout(() => this._highchart.reflow(), 100);
        } catch (e) {
            if (e.message == null || e.message != "Cannot read property 'nodeName' of null") {
                throw e;
            }
        }
    }

    public detached() {
        if (!!this._highchart) {
            this._highchart.destroy();
        }
    }
}
