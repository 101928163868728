export class DeviceService {
    // NOTE: Migrate to https://github.com/duskload/react-device-detect, if more than 2 methods are in this service

    // Helper method to check if the device is touch-capable
    private isTouchDevice(): boolean {
        return "ontouchstart" in window || navigator.maxTouchPoints > 0;
    }

    public isMobileTablet(): boolean {
        const isMobileUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );

        // Check if the browser is in "Desktop Mode" on mobile devices
        const isDesktopMode =
            (this.isTouchDevice() && /Linux/.test(navigator.platform) && (window as any).chrome !== undefined) ||
            (window.Intl && (Intl as any).v8BreakIterator && window.innerWidth <= 1024 && window.innerHeight <= 768);

        return isMobileUserAgent || isDesktopMode;
    }

    // NOTE: Refer to https://stackoverflow.com/a/58064481 for this method
    public isSafariOnIOS() {
        return navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
    }
}
