import "./form-navigator.scss";

import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, containerless, customElement, observable } from "aurelia-framework";

import nameof from "../../../common/nameof";
import {
    IDisplayNavigatorState,
    IHighlightQuestion,
    INavigatorGroup,
    INavigatorGroupInfo,
    INavigatorQuestion,
} from "../../../interfaces/form-builder/i-navigator";
import { NoteDataManager } from "../note-data-manager";
import { NavigatorDataHelper } from "./navigator-data-helper";
import { NavigatorStateManager } from "./navigator-state-manager";

export const ScrubberResultPublishKey = "scrubber:result:received";

@autoinject
@containerless
@customElement("form-navigator")
export class FormNavigator {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isNavigatorExpanded: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public focusElement: (element: { question: IHighlightQuestion }) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public checkNoteErrors: () => Promise<void>;
    private _ea: EventAggregator;
    private _noteDataManager: NoteDataManager;
    private _subscription: Subscription;
    private _defaultQuestionIndex: number = 0;
    @observable({
        changeHandler: nameof<FormNavigator>("displayStateChanged")
    })
    public displayState: IDisplayNavigatorState = null;
    public isDescriptionExpanded: boolean = false;
    public navigatorGroups: INavigatorGroupInfo[] = [];
    public navigatorStateManager: NavigatorStateManager;
    public navigatorData: Map<string, INavigatorGroup>;

    public constructor(ea: EventAggregator, noteDataManager: NoteDataManager) {
        this._ea = ea;
        this._noteDataManager = noteDataManager;
    }

    public attached() {
        this.initNavigatorData();
        this._subscription = this._ea.subscribe(ScrubberResultPublishKey, () => {
            this.initNavigatorData();
        });
    }

    private initNavigatorData() {
        let navigatorDataHelper = new NavigatorDataHelper(this._noteDataManager);
        this.navigatorData = navigatorDataHelper.getNavigatorData();
        this.navigatorStateManager = new NavigatorStateManager(this.navigatorData);
        this.navigatorGroups = this.navigatorStateManager.getGroups();
        this.displayState = this.navigatorStateManager.getDisplayNavigatorState();
    }

    public displayStateChanged() {
        if (!this.displayState) {
            return;
        }

        let firstQuestion = this.displayState.activeState?.navigatorItem?.questions[this._defaultQuestionIndex];
        setTimeout(() => {
            this.navigateToQuestion(firstQuestion);
            this._defaultQuestionIndex = 0;
        }, 0);
    }

    public setGroup(group: INavigatorGroupInfo, navigatorItemIndex: number = 0) {
        let activeState = this.navigatorStateManager.setGroup(group.groupName, navigatorItemIndex);
        this.displayState = activeState;
    }

    public previousState() {
        let activeState = this.navigatorStateManager.previousState();
        this.displayState = activeState;
    }

    public nextState() {
        let activeState = this.navigatorStateManager.nextState();
        this.displayState = activeState;
    }

    public expandDescription() {
        this.isDescriptionExpanded = true;
    }

    public collapseDescription() {
        this.isDescriptionExpanded = false;
    }

    public expandNavigator() {
        this.isNavigatorExpanded = true;
    }

    public collapseNavigator() {
        this.isNavigatorExpanded = false;
    }

    public navigateAndCollapse(question: INavigatorQuestion, group: INavigatorGroupInfo,
        questionIndex: number, navigatorItemIndex: number) {
        if (!question.tab) {
            return;
        }
        this._defaultQuestionIndex = questionIndex;
        this.collapseNavigator();
        this.setGroup(group, navigatorItemIndex);
        this.navigateToQuestion(question);
    }

    public getGroupItems(groupName: string) {
        return this.navigatorData.get(groupName).navigatorItems;
    }

    public navigateToQuestion(question: INavigatorQuestion) {
        if (!question?.tab || !this.focusElement) {
            return;
        }
        this.focusElement({
            question: {
                code: question.code,
                tab: question.tab,
                theme: this.displayState.activeState.theme
            }
        });
    }

    public detached() {
        this._subscription?.dispose();
    }
}
