import "./dashboard-card.scss";
import { bindable, bindingMode, containerless } from "aurelia-framework";

@containerless
export class DashboardCard {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public title: string;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public tryAgain: () => any;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAuthorized: boolean = true;
}
