import { autoinject } from "aurelia-framework";

import { config } from "../common/config";
import { HospiceClient } from "../common/hospice-client";
import Infrastructure from "../common/infrastructure";
import { IAssetUrlResponse, ICreateAssetItem, ICreateAssetResult, IFileDetails } from "../interfaces/i-asset";
import { IGetAssetResponse } from "./i-assets-service";

@autoinject()
export class AssetsService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/assets";
    private _baseUrlV2: string = "/api/v2/assets";
    private _baseAssetUrl: string = `${config.assetServerURL}api/v1/assets`;

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
        if (Infrastructure.assetServerUrl && !Infrastructure.assetServerUrl.includes("#")) {
            this._baseAssetUrl = `${Infrastructure.assetServerUrl}api/v1/assets`;
        }
    }

    public async uploadFile(file: File) {
        let asset: ICreateAssetItem = await this.createAsset({
            fileName: file.name,
            fileSize: file.size
        });
        if (!asset) {
            throw new Error("Asset Creation Unsuccessful.");
        }
        await this.upload(asset.token, file);
        return asset.assetId;
    }

    private async createAsset(fileDetails: IFileDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(fileDetails)
            .fetch<ICreateAssetItem>();
    }

    public async uploadMessageFiles(files: File[]) {
        if (!files?.length) {
            return [];
        }
        let fileDetails = files.map((file, index) => ({
            fileName: file.name,
            fileSize: file.size,
            attachmentNumber: index
        }));
        let assets: ICreateAssetResult = await this.createMessageAssets(fileDetails);
        if (!assets?.assetDetails?.length) {
            throw new Error("Asset Creation Unsuccessful.");
        }
        let successfulAssetIds: string[] = [];
        assets.assetDetails?.forEach((asset) => {
            if (asset.isSuccessful) {
                let file = files[asset.attachmentNumber];
                this.upload(asset.token, file);
                successfulAssetIds.push(asset.assetId);
            }
        });
        return successfulAssetIds;
    }

    private async createMessageAssets(fileDetails: IFileDetails[]) {
        return await this._httpClient
            .build(`${this._baseUrlV2}`)
            .useMethod("POST")
            .useBody({ assets: fileDetails })
            .fetch<ICreateAssetResult>();
    }

    public async upload(token: string, file: File) {
        let formData = new FormData();
        formData.append("File", file);
        return await this._httpClient
            .build(`${this._baseAssetUrl}`)
            .useMode("cors")
            .useMethod("POST")
            .useAuthorization("Bearer " + token)
            .useFormData(formData)
            .fetchNoContent();
    }

    public async getUrlById(assetId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${assetId}/url`).fetch<IAssetUrlResponse>();
    }

    public async getAsset(assetId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${assetId}`).fetch<IGetAssetResponse>();
    }

    public async getAssetBlob(assetId: string) {
        let asset = await this.getAsset(assetId);
        let fileBlob = await fetch(asset.url);
        let blobFile = await fileBlob.blob();
        return {
            assetBlob: new Blob([blobFile], { type: asset.mimeType }),
            assetName: asset.name,
            type: asset.mimeType
        };
    }
}
