import { computedFrom, Disposable } from "aurelia-binding";
import { autoinject } from "aurelia-dependency-injection";
import { EventAggregator } from "aurelia-event-aggregator";
import { PLATFORM } from "aurelia-pal";
import { Router, RouterConfiguration, RouteConfig } from "aurelia-router";
import * as moment from "moment";
import { Moment } from "moment";
import { Key } from "ts-keycode-enum";
import { config } from "../../common/config";
import Infrastructure from "../../common/infrastructure";
import nameof from "../../common/nameof";
import { OidcConfiguration } from "../../common/oidc/oidc-configuration";
import { KeyListener, KeyPressPublish } from "../../common/utilities/key-listener";
import { IKeyEventPayload } from "../../interfaces/i-keyevent-payload";
import { AccountEvent, accountIdKey, AuthService } from "../../services/auth-service";
import { HttpService } from "../../services/http-service";
import { SignalrReactor } from "../../signalr/signalr-reactor";
import { UsersService } from "./../../services/users-service";
import { AppCache } from "./app-cache";
import { AppRoutes } from "./app-routes";
import "./app.scss";
import { AuthorizeStep } from "./authorize-step";
import { Permission } from "../../models/permission";
import { User } from "../../models/user";
import { IPromptOptions, Prompt } from "../../resources/dialogs/prompt/prompt";
import { DialogSettings, DialogService, DialogOpenResult } from "aurelia-dialog";

@autoinject
export class App {
    private _usersService: UsersService;
    private _eaSubscriptions: Disposable[] = [];
    private _httpService: HttpService;
    private _signalR: SignalrReactor;
    private _ea: EventAggregator;
    private _authService: AuthService;
    private _oidcConfiguration: OidcConfiguration;
    private _appCache: AppCache;
    private _keyListener: KeyListener;
    private _appRoutes: AppRoutes;
    private _versionChanged: boolean = false;
    private _dialogService: DialogService;
    public router: Router;
    public currentYear: string = moment().format("YYYY");
    public releaseVersion: string = Infrastructure.releaseVersion;
    public releaseTimestamp: Moment = moment(new Date(Infrastructure.releaseTimestamp));
    public releaseEnvironment: string = Infrastructure.releaseEnvironment;
    public currentUserInfo: User;
    public axxessCentralUrl: string = config.axxessCentralUrl;

    @computedFrom(nameof<App>("releaseTimestamp"))
    public get releasedOn() {
        if (this.releaseTimestamp.isValid()) {
            return `Released on ${this.releaseTimestamp.format("MM/DD/YYYY hh:mm:ss a")}`;
        } else {
            return "";
        }
    }

    public constructor(
        signalR: SignalrReactor,
        httpService: HttpService,
        ea: EventAggregator,
        authService: AuthService,
        oidcConfiguration: OidcConfiguration,
        appCache: AppCache,
        keyListener: KeyListener,
        appRoutes: AppRoutes,
        usersService: UsersService,
        dialogService: DialogService
    ) {
        this._httpService = httpService;
        this._signalR = signalR;
        this._ea = ea;
        this._authService = authService;
        this.initSubscriptions();
        this._httpService.configureFetch();
        this._oidcConfiguration = oidcConfiguration;
        this._appCache = appCache;
        this._keyListener = keyListener;
        this._appRoutes = appRoutes;
        this._usersService = usersService;
        this._dialogService = dialogService;
    }

    private initSubscriptions() {
        this._eaSubscriptions.push(
            this._ea.subscribe(AccountEvent.Selected, async (accountId: string) => {
                console.log("Account Selected Sub in App", accountId);
                await this.initPlugins(accountId);
            })
        );
        window.addEventListener("storage", async (event: StorageEvent) => {
            console.log("Storage event triggered", event);
            let isUserLoggedIn = await this._authService.isLoggedIn();
            if (isUserLoggedIn && event.key === accountIdKey && event.newValue !== event.oldValue) {
                console.log("Account has been changed in another tab, Reloading");
                window.location.reload();
            }
        });
    }

    public async attached(): Promise<void> {
        let accountId = this._authService.getAccountId();
        await this.initPlugins(accountId);
        this._keyListener.attach();
        this._eaSubscriptions.push(this._ea.subscribe(KeyPressPublish, this.keyListenHandler.bind(this)));
    }

    private async showPromptMessage() {
        let promptOptions: IPromptOptions = {
            message: "Account access is restricted based on your user settings.",
            additionalMessage:
                "If you need access to this account, contact your administrator to update your login restrictions.",
            okText: "Ok",
            cancelText: ""
        };

        let dialogOptions: DialogSettings = {
            viewModel: Prompt,
            model: promptOptions
        };

        let dialog = (await this._dialogService.open(dialogOptions)) as DialogOpenResult;
        let closeResult = await dialog.closeResult;

        if (!closeResult.wasCancelled) {
            this.router.navigate(this.axxessCentralUrl);
        }
    }

    public async configureRouter(routerConfig: RouterConfiguration, router: Router) {
        this.router = router;
        routerConfig.title = "AxxessHospice";
        routerConfig.options.pushState = true;
        let appRoutes = await this._appRoutes.getRoutes();
        routerConfig.map(appRoutes);
        routerConfig.mapUnknownRoutes(PLATFORM.moduleName("./not-found"));
        routerConfig.fallbackRoute("/");
        this._oidcConfiguration.configureRouter(routerConfig, router);
        routerConfig.addAuthorizeStep(AuthorizeStep);
    }

    private async initPlugins(accountId: string) {
        if (!!accountId) {
            try {
                let hasVersionChanged = this.checkIfVersionChanged(this.releaseVersion);
                if (hasVersionChanged) {
                    await this.handleVersionChange();
                } else {
                    // This is to prevent not caching data when the app versions are same
                    await this._appCache.restoreMissingCache();
                }
                await this._signalR.start(accountId);
                let hasWeekendAccess = await this.isUserAllowedAccess();
                if (!hasWeekendAccess) {
                    await this.showPromptMessage();
                    return;
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    private checkIfVersionChanged(version: string) {
        let cachedAppVersion = localStorage.getItem(config.versionCacheKey);
        this._versionChanged = !cachedAppVersion || (version && cachedAppVersion !== version);
        return this._versionChanged;
    }

    private async handleVersionChange() {
        if (this._versionChanged) {
            localStorage.setItem(config.versionCacheKey, this.releaseVersion);
            await this._appCache.refreshCache();
        }
    }

    private async isUserAllowedAccess() {
        let currentUser = await this._usersService.getCurrentUser();
        if (!currentUser) {
            return false;
        }
        this.currentUserInfo = await this._usersService.getUser(currentUser.id);
        return this.isUserAllowedDuringTime() && this.isUserAllowedWeekend();
    }

    private isUserAllowedWeekend() {
        if (!this.currentUserInfo) {
            return false;
        }
        let currentWeekDay = moment().format("dddd");
        let isWeekend = currentWeekDay.toLowerCase() === "sunday" || currentWeekDay === "saturday";
        if (isWeekend && !this.currentUserInfo?.allowWeekendAccess) {
            return false;
        }
        return true;
    }

    private isUserAllowedDuringTime() {
        if (!this.currentUserInfo) {
            return false;
        }
        if (!this.currentUserInfo.earliestLoginTime && !this.currentUserInfo.automaticLogoutTime) {
            return true;
        }
        let timeToCompare = moment(moment(), "HH:mm");
        let isBeforeAllowedLogIn = timeToCompare.isBefore(moment(this.currentUserInfo.earliestLoginTime, "HH:mm"));
        let isAfterAllowedLogOut = timeToCompare.isAfter(moment(this.currentUserInfo.automaticLogoutTime, "HH:mm"));
        if (isBeforeAllowedLogIn || isAfterAllowedLogOut) {
            return false;
        }
        return true;
    }

    private keyListenHandler(payload: IKeyEventPayload) {
        let event = payload.KeyboardEvent;
        let isCtrl = event.ctrlKey;
        let isAlt = event.altKey;
        let isKeyDPressed = event.keyCode === Key.D;
        if (isCtrl && isAlt && isKeyDPressed) {
            this.initiateNavigationToDataLoad();
        }
    }

    private async initiateNavigationToDataLoad() {
        let isProdEnvironment = Infrastructure.isProdEnvironment;
        let isUserAuthorized = true;
        if (isProdEnvironment) {
            isUserAuthorized = await this._usersService.isTemporaryUser();
        }
        if (isUserAuthorized) {
            this.router.navigate("/admin/data-load");
        }
    }

    public detached() {
        if (this._eaSubscriptions.length > 0) {
            this._eaSubscriptions.forEach((sub) => sub.dispose());
        }
        this._keyListener.detach();
    }
}
