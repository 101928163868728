import { IReportTooltip } from "../../../interfaces/form-builder/i-tooltips";

export const employeeRosterTooltip: IReportTooltip = {
    description:
        "Displays a list of people for your agency by status and type. This report is critical for internal administrative purposes and is used during state and regulatory surveys.",
    parameters: "Branch, Employee Status, Employee Type",
    reportIncludes:
        "First Name, Last Name, Branch, Type, Title, Credentials, Mobile Phone, Home Phone, Payroll Group, Status, Hire Date, Separation Date"
};

export const expiringDocumentsTooltip: IReportTooltip = {
    description:
        "Generates a list of employees expiring documents. Regulations require that licensed staff maintain licensure that is active and in good standing in order to work in their scope of practice.",
    parameters: "Service Line, Provider/Branch, User Type, User Status, Expiration Date Range",
    reportIncludes: "Employee Name, Employee Title, Document Name, Effective Date and Expiration Date"
};

export const payrollExportReportTooltip: IReportTooltip = {
    description: "Allows users to generate a payroll export for multiple providers in one file.",
    parameters: "Pay Group Cycle Dates, Providers",
    reportIncludes: "Specific format for Payroll Vendor"
};

export const expiringPhysiciansLicenseReportTooltip: IReportTooltip = {
    description:
        "Displays a list of expiring physician licenses. Licensed staff must maintain active licensure to work in their scope of practice.",
    parameters: "Provider, Status, Expiration Date Range",
    reportIncludes:
        "Provider, NPI, Physician First Name, Physician Last Name, Physician Credentials, Status, License Type, License Number, Expiration Date and State"
};
