import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject } from "aurelia-framework";

import { IKeyEventPayload } from "../../interfaces/i-keyevent-payload";

@autoinject
export class KeyListener {
    private _ea: EventAggregator;
    private keypressCallbackFactory = (that: KeyListener) => this.keypressInput.bind(that);

    public constructor(ea: EventAggregator) {
        this._ea = ea;
    }

    public attach() {
        window.addEventListener("keydown", this.keypressCallbackFactory(this), false);
    }

    public detach() {
        window.removeEventListener("keydown", this.keypressCallbackFactory(this));
    }

    private keypressInput(e: KeyboardEvent) {
        let payload: IKeyEventPayload = {
            KeyboardEvent: e
        };
        this._ea.publish(KeyPressPublish, payload);
    }
}

export const KeyPressPublish: string = "hospice:key:pressed";
