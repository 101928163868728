import { DialogController } from "aurelia-dialog";
import { autoinject, bindable} from "aurelia-framework";

import "./alert.scss";

export interface IAlertOptions {
    title?: string;
    message: string;
    actionId?: string;
    okText?: string;
}

@autoinject()
export class Alert {
    public dialogController: DialogController;

    public options: IAlertOptions = {
        title: "Alert",
        message: "This is a default message.",
        okText: "Ok"
    };

    public constructor(element: Element, dialogController: DialogController) {
        this.dialogController = dialogController;
    }

    public activate(model: IAlertOptions) {
        Object.assign(this.options, model);
    }
}
