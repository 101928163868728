import { valueConverter } from "aurelia-binding";

@valueConverter("taskStatusNameColor")
export class TaskStatusNameColor {
    public toView(status: string) {
        switch (status?.toLowerCase()) {
            case "not yet started":
            case "not yet due":
            case "saved":
                return "info";
            case "submitted pending co-signature":
            case "to be sent":
            case "returned for review":
            case "pending follow up":
            case "reopened":
            case "pending addendum":
                return "warning";
            case "submitted with signature":
            case "export ready":
            case "exported":
            case "sent to physician":
            case "returned with physician signature":
            case "order received":
            case "completed":
                return "success";
            default:
                return "";
        }
    }
}

@valueConverter("taskStatusIdColor")
export class TaskStatusIdColor {
    public toView(id: string) {
        switch (parseInt(id, 10)) {
            case 1:
            case 2:
            case 3:
                return "info";
            case 4:
            case 8:
            case 12:
            case 13:
            case 16:
                return "warning";
            case 5:
            case 6:
            case 7:
            case 9:
            case 10:
            case 11:
            case 18:
                return "success";
            default:
                return "";
        }
    }
}
