
import * as moment from "moment";
import { Moment } from "moment";
import { valueConverter } from "aurelia-framework";
import { MomentValueConverter } from "../../services/date-format-service";

@valueConverter("formatDate")
export class FormatDate {
    public fromView(value: string, dateFormat: string = "MM/DD/YYYY"): Moment {
        if (value) {
            return moment(value, dateFormat);
        } else {
            return null;
        }
    }

    public toView(value: Moment, dateFormat: string = "MM/DD/YYYY"): string {
        if (value && (moment.isMoment(value) || moment(value).isValid())) {
            return moment(value).format(dateFormat);
        } else {
            return "";
        }
    }
}

@valueConverter("formatDateFromDate") // TODO : I know the name is terrible
export class FormatDateFromDate {

    public toView(value: Date, dateFormat: string = "MM/DD/YYYY"): string {
        if (value) {
            let theMoment = MomentValueConverter.dateOrStringToMoment(value);
            return theMoment.format(dateFormat);
        }

        return ".";
    }
}

@valueConverter("formatDateTime")
export class FormatDateTime {

    public toView(value: string, dateTimeFormat: string = "MM/DD/YYYY hh:mm A") {
        if (value) {
            return moment(value).format(dateTimeFormat);
        } else {
            return null;
        }
    }
}

@valueConverter("formatTime")
export class FormatTime {

    public toView(value: Moment, timeFormat: string = "hh:mm a") {
        if (value) {
            return value.format(timeFormat);
        } else {
            return null;
        }
    }
}

@valueConverter("formatAgoFromDate")
export class FormatAgoFromDate {

    public toView(value: Date, postfix: boolean = false) {
        if (value) {
            let theMoment = MomentValueConverter.dateOrStringToMoment(value);
            return theMoment.fromNow(postfix);
        } else {
            return null;
        }
    }
}

@valueConverter("formatDateInput")

export class FormatDateInput {
    public toView(value: string): string {
        let result: string = value;
        if (result) {
            result = value.replace(/^(\d{2})(\d+)+$/, "$1/$2");
            result = result.replace(/^(\d{2})\/(\d{2})(\d+)+$/, "$1/$2/$3");
            if (/^(\d{4})\-(\d{2})\-(\d{2})/.test(result)) {
                result = moment(value).format("MM/DD/YYYY");
            }
        }
        return result;
    }
}

@valueConverter("formatDaysRemaining")

export class FormatDaysRemaining {
    public toView(value: number): string {
        let positiveDaysRemaining = Math.abs(value);
        let result = String(positiveDaysRemaining);
        result += " day";
        result += `${positiveDaysRemaining == 1 ? "" : "s"}`;
        result += `${value < 0 ? " overdue" : ""}`;
        return result;
    }
}
