import { bindable, bindingMode, computedFrom, customElement, autoinject } from "aurelia-framework";

import nameof from "../../../../common/nameof";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { NoteDataManager } from "../../note-data-manager";

@customElement("single-select")
@autoinject
export class SingleSelect {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public hasHighlight: string = "";
    public noteDataManager: NoteDataManager;

    @computedFrom(nameof<SingleSelect>("hasHighlight"))
    public get highlightClass() {
        switch (this.hasHighlight) {
            case "errors":
                return "is-invalid";
            case "warnings":
                return "is-warning";
            case "inconsistencies":
                return "is-inconsistency";
        }
        return "";
    }

    @computedFrom(nameof<SingleSelect>("hasHighlight"))
    public get highlightFocus() {
        return this.hasHighlight.length > 0;
    }

    public set highlightFocus(focus: boolean) {
        // focus would try to set property
    }

    @computedFrom(nameof<SingleSelect>("viewOptions"), nameof<SingleSelect>("result"))
    public get showSubInput() {
        let result = this.result;
        return this.viewOptions.options.map((opt) => result === opt.value);
    }

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.noteDataManager.assignValidationRules.call(this, this.viewOptions.name, this.noteDataManager);
    }

    public optionClick(value: string) {
        if (this.result == value) {
            this.result = null;
        }
        return true;
    }

    public getCheckBoxId(viewOptionName: string, optionName: string) {
        return `${viewOptionName}-${optionName}`;
    }
}
