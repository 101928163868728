import { PLATFORM } from "aurelia-pal";
import { RouteConfig } from "aurelia-router";
import { DashboardPermissionEnum } from "../../enums/dashboard-permission-enum";
import { PermissionActionEnum } from "../../enums/permission-action-enum";

const scheduleKeyWords = ["task", "add", "visit", "note", "calendar", "daily", "weekly", "f2f", "ftf"];

export const ScheduleRoutes: RouteConfig[] = [
    {
        name: "root",
        route: "",
        redirect: "schedule-dashboard"
    },
    {
        name: "schedule-dashboard",
        route: "schedule-dashboard",
        moduleId: PLATFORM.moduleName("./schedule-dashboard/schedule-dashboard", "schedule-dashboard"),
        nav: true,
        title: "Schedule Dashboard",
        settings: {
            menuName: "Schedule Dashboard",
            permission: [
                {
                    resource: DashboardPermissionEnum.ScheduleDashboard,
                    action: PermissionActionEnum.View
                }
            ]
        }
    },
    {
        name: "employee-schedule",
        route: "employee-schedule/:id?",
        moduleId: PLATFORM.moduleName("./employee-schedule/employee-schedule", "employee-schedule"),
        href: "schedule/employee-schedule/load",
        nav: true,
        title: "Employee Schedule",
        settings: {
            menuName: "Employee Schedule",
            keywords: scheduleKeyWords
        }
    },
    {
        name: "patient-schedule",
        route: "patient-schedule/:id?",
        moduleId: PLATFORM.moduleName("./patient-schedule/patient-schedule", "patient-schedule"),
        href: "schedule/patient-schedule/load",
        nav: true,
        title: "Patient Schedule",
        settings: {
            menuName: "Patient Schedule",
            keywords: scheduleKeyWords
        }
    },
    {
        name: "volunteer-schedule",
        route: "volunteer-schedule/:id?",
        moduleId: PLATFORM.moduleName("./volunteer-schedule/volunteer-schedule", "volunteer-schedule"),
        href: "schedule/volunteer-schedule/load",
        nav: true,
        title: "Volunteer Schedule",
        settings: {
            menuName: "Volunteer Schedule",
            keywords: scheduleKeyWords
        }
    }
];
