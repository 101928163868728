import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    IBulkRefillRequest,
    ICreateCustomMedication,
    ICreateCustomMedicationResponse,
    ICreateSnapshotQuery,
    IDeleteCustomMedicationResponse,
    IDiscontinueParams,
    IDispenseInformation,
    IDispenseInformationQuery,
    IEditCustomMedicationResponse,
    IGetCustomMedicationQuery,
    IGetCustomMedicationResult,
    IGetDrug,
    IGetDrugQuery,
    IGetMedicationsParams,
    IGetSnapshotQuery,
    IGetSnapshotResult
} from "../interfaces/i-medication";
import { IMedicationOrder } from "../interfaces/i-medication-order";
import { IPaginationNew } from "../interfaces/i-pagination";
import { ICreateResponse } from "../interfaces/i-response";
import { Medication } from "../models/medication";
import {
    ICreateMedicationActionResponse,
    IEmarMedicationCheckResponse,
    IGetEmar,
    IGetEmarMedicationCheckQuery,
    IGetFrequencyOption,
    IGetFrequencyQuery,
    IGetMedicationActionsRequest,
    IGetOrderSetQuery,
    IMedication,
    IOrderSet,
    IOrderSetSlim,
    IPostMedicationAction,
    IPostOrderSetQuery,
    IPostPatientOrderSetQuery,
    IPutMedicationAction,
    IUpdateOrderSetQuery
} from "./i-medication-service";

@autoinject()
export class MedicationService {
    private _httpClient: HospiceClient;
    private _lookupURL: string = "/api/v1/lookup";
    private _medicationURL: string = "/api/v1/medications";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getDrugs(query: IGetDrugQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/search`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetDrug[]>();
    }

    public async getClassifications(genericDrugId: string) {
        return await this._httpClient
            .build(`${this._lookupURL}/classifications`)
            .useQueryString({ genericDrugId: genericDrugId })
            .fetch<string[]>();
    }

    public async createMedication(newMedication: Medication) {
        return await this._httpClient
            .build(`${this._medicationURL}`)
            .useMethod("POST")
            .useBody(newMedication)
            .fetch<ICreateResponse>();
    }

    public async editMedication(medicationId: string, newMedication: Medication) {
        return await this._httpClient
            .build(`${this._medicationURL}/${medicationId}`)
            .useMethod("PUT")
            .useBody(newMedication)
            .fetch();
    }

    public async discontinueMedications(discontinueParams: IDiscontinueParams) {
        return await this._httpClient
            .build(`${this._medicationURL}/discontinue`)
            .useMethod("PUT")
            .useBody(discontinueParams)
            .fetchNoContent();
    }

    public async getAllMedications(medicationParams: IGetMedicationsParams) {
        return await this._httpClient
            .build(`${this._medicationURL}`)
            .useQueryString(medicationParams)
            .fetch<Medication[]>();
    }

    public async getMedicationOrders(patientId: string) {
        return await this._httpClient.build(`${this._medicationURL}/${patientId}/orders`).fetch<IMedicationOrder[]>();
    }

    public async deleteMedication(medicationId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/${medicationId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async printMedicationProfile(patientId: string) {
        return await this._httpClient.build(`${this._medicationURL}/${patientId}/print`).fetchBlob();
    }

    public async createSnapshot(patientId: string, query: ICreateSnapshotQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/${patientId}/snapshot`)
            .useMethod("POST")
            .useBody(query)
            .fetch<ICreateResponse>();
    }

    public async getSnapshotByPage(patientId: string, query: IGetSnapshotQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/${patientId}/snapshot`)
            .useQueryString(query)
            .fetch<IGetSnapshotResult>();
    }

    public async printSnapshot(patientId: string, snapshotId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/${patientId}/snapshot/${snapshotId}/print`)
            .fetchBlob();
    }

    public async getCustomMedicationByPage(query: IGetCustomMedicationQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/custom/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetCustomMedicationResult>();
    }

    public async exportCustomMedicationExcel(query: IGetCustomMedicationQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/custom/xlsx`)
            .useMethod("POST")
            .useBody(query)
            .fetchBlob();
    }

    public async createCustomMedication(data: ICreateCustomMedication) {
        return await this._httpClient
            .build(`${this._medicationURL}/custom`)
            .useBody(data)
            .useMethod("POST")
            .fetch<ICreateCustomMedicationResponse>();
    }

    public async editCustomMedication(customMedicationId: string, data: ICreateCustomMedication) {
        return await this._httpClient
            .build(`${this._medicationURL}/custom/${customMedicationId}`)
            .useBody(data)
            .useMethod("PUT")
            .fetch<IEditCustomMedicationResponse>();
    }

    public async deleteCustomMedication(customMedicationId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/custom/${customMedicationId}`)
            .useMethod("DELETE")
            .fetch<IDeleteCustomMedicationResponse>();
    }

    public async getDispenseInformation(medicationId: string, query: IDispenseInformationQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/${medicationId}/dispense-information`)
            .useQueryString(query)
            .fetch<IPaginationNew<IDispenseInformation>>();
    }

    public async cancelDelivery(medicationId: string, dispenseId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/${medicationId}/dispense-information/${dispenseId}/cancel`)
            .useMethod("POST")
            .fetchNoContent();
    }

    public async requestRefill(refillRequest: IBulkRefillRequest) {
        return await this._httpClient
            .build(`${this._medicationURL}/refill`)
            .useBody(refillRequest)
            .useMethod("POST")
            .fetchNoContent();
    }

    public async downloadEmar(patientId: string, query: IGetMedicationActionsRequest) {
        return await this._httpClient
            .build(`${this._medicationURL}/${patientId}/medication-actions/xlsx`)
            .useQueryString(query)
            .fetchBlob();
    }

    public async getPatientEmar(patientId: string, query: IGetMedicationActionsRequest) {
        return await this._httpClient
            .build(`${this._medicationURL}/${patientId}/medication-actions`)
            .useQueryString(query)
            .fetch<IGetEmar[]>();
    }

    public async createMedicationAction(patientId: string, medicationAction: IPostMedicationAction) {
        return await this._httpClient
            .build(`${this._medicationURL}/${patientId}/medication-action`)
            .useMethod("POST")
            .useBody(medicationAction)
            .fetch<ICreateMedicationActionResponse>();
    }

    public async editMedicationAction(medicationActionId: string, medicationAction: IPutMedicationAction) {
        return await this._httpClient
            .build(`${this._medicationURL}/medication-action/${medicationActionId}`)
            .useMethod("PUT")
            .useBody(medicationAction)
            .fetchNoContent();
    }

    public async deleteMedicationAction(medicationActionId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/medication-action/${medicationActionId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getOrderSetSlim(query: string[]) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/slim`)
            .useQueryString({ providerIds: query })
            .fetch<IOrderSetSlim[]>();
    }

    public async getOrderSetMedicationsById(orderSetId: string, patientId?: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/${orderSetId}/medication`)
            .useQueryString({ patientId })
            .fetch<IMedication[]>();
    }

    public async createOrderSet(orderSet: IPostOrderSetQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set`)
            .useMethod("POST")
            .useBody(orderSet)
            .fetch<ICreateResponse>();
    }

    public async getOrderSets(query: IGetOrderSetQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IPaginationNew<IOrderSet[]>>();
    }

    public async editOrderSet(orderSetId: string, orderSet: IUpdateOrderSetQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/${orderSetId}`)
            .useMethod("PUT")
            .useBody(orderSet)
            .fetchNoContent();
    }

    public async deleteOrderSet(orderSetId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/${orderSetId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async addOrderSetToPatient(orderSet: IPostPatientOrderSetQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/patient`)
            .useMethod("POST")
            .useBody(orderSet)
            .fetchNoContent();
    }

    public async addPalliativeOrderSetToPatient(orderSet: IPostPatientOrderSetQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/palliative-care/order-set/patient`)
            .useMethod("POST")
            .useBody(orderSet)
            .fetchNoContent();
    }

    public async addMedicationToOrderSet(orderSetId: string, medication: IMedication) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/${orderSetId}/medication`)
            .useMethod("POST")
            .useBody(medication)
            .fetch<ICreateResponse>();
    }

    public async updateOrderSetMedication(medicationId: string, medication: IMedication) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/medication/${medicationId}`)
            .useMethod("PUT")
            .useBody(medication)
            .fetchNoContent();
    }

    public async deleteOrderSetMedication(medicationId: string) {
        return await this._httpClient
            .build(`${this._medicationURL}/order-set/medication/${medicationId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async getFrequencyList(query: IGetFrequencyQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/frequencies/search`)
            .useQueryString(query)
            .fetch<IGetFrequencyOption[]>();
    }

    public async createPalliativeMedication(newMedication: Medication) {
        return await this._httpClient
            .build(`${this._medicationURL}/palliative-care`)
            .useMethod("POST")
            .useBody(newMedication)
            .fetch<ICreateResponse>();
    }

    public async editPalliativeMedication(medicationId: string, medication: Medication) {
        return await this._httpClient
            .build(`${this._medicationURL}/palliative-care/${medicationId}`)
            .useMethod("PUT")
            .useBody(medication)
            .fetch();
    }

    public async getEmarMedicationCheck(query: IGetEmarMedicationCheckQuery) {
        return await this._httpClient
            .build(`${this._medicationURL}/emar-medication-check`)
            .useQueryString(query)
            .fetch<IEmarMedicationCheckResponse>();
    }
}
