import { autoinject, bindable, bindingMode, customElement } from "aurelia-framework";
import { DME } from "../../../models/dme";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";

@autoinject
@customElement("dme-form")
export class DmeForm {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerOptions: ITypeaheadOptions[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public dme: DME;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    public isError: boolean = false;
    public providerValidation: IValidateCustomElement = {
        required: true,
        message: "Associated Agency Provider is required"
    };

    public isInfusionPumpChanged(value: boolean) {
        if (this.dme.isInfusionPump === value) {
            this.dme.isInfusionPump = null;
        } else {
            this.dme.isInfusionPump = value;
        }
    }
}
