import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import {
    ICreatePhysicianResult,
    IExportPhysician,
    IGetPhysicianByNameQuery,
    IGetPhysicianByNameResult,
    IGetPhysicianOigRequest,
    IGetPhysicianOigResponse,
    IGetPhysicianQuery,
    IGetPhysicianResult,
    IGetPhysiciansByNPI,
    IGetPhysiciansByNPIResult,
    IGetPhysiciansLeanQuery,
    IGetPhysiciansLeanResult,
    IGetPhysicianUser
} from "../interfaces/i-get-physician";
import { Physician } from "../models/physician";
import { IGetPhysiciansLean } from "./../interfaces/i-get-physician";

@autoinject()
export class PhysiciansService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/physicians";
    private _lookupURL: string = "/api/v1/lookup";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createPhysician(newPhysician: Physician) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(newPhysician)
            .fetch<ICreatePhysicianResult>();
    }

    public async getPhysicianByPage(query: IGetPhysicianQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/list`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetPhysicianResult>();
    }

    public async getPhysiciansByName(query: IGetPhysicianByNameQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/searchByName`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetPhysicianByNameResult>();
    }

    public async getPhysiciansByNPI(query: IGetPhysiciansByNPI) {
        return await this._httpClient
            .build(`${this._lookupURL}/npis`)
            .useQueryString(query)
            .fetch<IGetPhysiciansByNPIResult>();
    }

    public async getPhysicianById(physicianId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${physicianId}`).fetch<Physician>();
    }

    public async editPhysician(physicianId: string, physician: Physician) {
        return await this._httpClient
            .build(`${this._baseUrl}/${physicianId}`)
            .useMethod("PUT")
            .useBody(physician)
            .fetch();
    }

    public async deletePhysician(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}`).useMethod("DELETE").fetchNoContent();
    }

    public async exportPhysicianExcel(query: IExportPhysician) {
        return await this._httpClient.build(`${this._baseUrl}/xlsx`).useMethod("POST").useBody(query).fetchBlob();
    }

    public async verifyPecos(npi: string) {
        return await this._httpClient
            .build(`${this._lookupURL}/ispecosverified`)
            .useQueryString({ npi })
            .fetch<boolean>();
    }

    public async checkPhysicianOig(query: IGetPhysicianOigRequest) {
        return await this._httpClient
            .build(`${this._lookupURL}/oigstatus`)
            .useQueryString(query)
            .fetch<IGetPhysicianOigResponse>();
    }

    public async getPhysiciansLean(query: IGetPhysiciansLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/lean`)
            .useQueryString(query)
            .fetch<IGetPhysiciansLeanResult>();
    }

    public async getPhysiciansLeanByIds(ids: string[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/leanbyids`)
            .useQueryString({ ids })
            .fetch<IGetPhysiciansLean[]>();
    }

    public async activatePhysician(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}/activate`).useMethod("PUT").fetchNoContent();
    }

    public async deactivatePhysician(id: string) {
        return await this._httpClient.build(`${this._baseUrl}/${id}/deactivate`).useMethod("PUT").fetchNoContent();
    }

    public async getIsPhysicianUser(npi: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/is-user`)
            .useQueryString({ npi })
            .fetch<IGetPhysicianUser>();
    }
}
