import "./single-select-scale.scss";

import { bindable, bindingMode, containerless, customElement, autoinject } from "aurelia-framework";

import { ISingleSelectScaleOptions } from "../../../../interfaces/form-builder/i-single-select-scale";
import { NoteDataManager } from "../../note-data-manager";

@customElement("single-select-scale")
@containerless
@autoinject
export class SingleSelectScale {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: any;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: ISingleSelectScaleOptions;
    public noteDataManager: NoteDataManager;
    public isBehaviorDriven: any = false;
    public colorPalette = [
        "red",
        "scarlet",
        "sunset-orange",
        "orange-red",
        "outrageous-orange",
        "coral",
        "safety-orange",
        "orange",
        "supernova",
        "golden-tainoi",
        "madang",
        "feijoa",
        "fern",
        "san-felix",
        "myrtle"
    ];
    public colors: string[] = [];

    public constructor(noteDataManager: NoteDataManager) {
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        let minValue = Math.min.apply(
            null,
            this.viewOptions.options.map((opt) => Number(opt.value))
        );
        this.isBehaviorDriven = this.viewOptions.behaviors && this.viewOptions.behaviors.length > 0;
        if (this.isBehaviorDriven) {
            if (this.viewOptions.behaviors[0].goodValue === minValue) {
                this.colorPalette = this.colorPalette.reverse();
            }
        }
        if (this.viewOptions.isReadOnly) {
            this.colors = Array(this.viewOptions.options.length).fill("grey");
        } else {
            this.colors = this.viewOptions.options.map((option, index) => this.getColorName(index));
        }
        this.noteDataManager.assignValidationRules.call(this, this.viewOptions.name, this.noteDataManager);
    }

    private getColorName(index: number) {
        if (this.isBehaviorDriven) {
            let colorIndex = Math.floor((index / this.viewOptions.options.length) * this.colorPalette.length);
            return this.colorPalette[colorIndex];
        }
        return "primary";
    }

    public selectChange(newValue: any) {
        if (newValue && !this.viewOptions.isReadOnly) {
            this.result = this.result == newValue ? null : newValue;
        }
    }

    public detached() {
        if (this.colorPalette[0] !== "red") {
            // reverse it again, so bad options are at the top for symptom-rating-input
            this.colorPalette = this.colorPalette.reverse();
        }
    }
}
