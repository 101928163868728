import { bindingMode } from "aurelia-binding";
import { bindable, containerless, customElement, autoinject } from "aurelia-framework";
import { ValidationController } from "aurelia-validation";

import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IValidation } from "../../../../interfaces/form-builder/i-validation";
import { IDiagnosis, IDiagnosisDetails } from "../../../../interfaces/i-diagnosis";
import { IValidateCustomElement } from "../../../../interfaces/i-validate-custom-element";
import { DiagnosisQueryTypeEnum, LookupService } from "../../../../services/lookup-service";
import { NoteDataManager } from "../../note-data-manager";

@customElement("diagnosis-input")
@containerless
@autoinject
export class DiagnosisInput {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IInputOptions;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public secondaryData: string;
    public diagnosis: IDiagnosis;
    public controller: ValidationController;
    public isLoading = false;
    private readonly _lookUpService: LookupService;
    public validation: IValidateCustomElement;
    public noteDataManager: NoteDataManager;

    public constructor(lookUpService: LookupService, noteDataManager: NoteDataManager) {
        this._lookUpService = lookUpService;
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        this.initValidation();
    }

    public initValidation() {
        let validation = this.noteDataManager.getValidationFromProp(this.viewOptions.name);
        validation?.forEach((rule: IValidation) => {
            if (rule.validationType === FormValidationEnum.Required) {
                this.validation = {
                    required: true,
                    displayName: this.viewOptions.name,
                    message: rule.message
                };
            }
        });
    }

    public diagnosisUpdated(diag: IDiagnosis) {
        if (diag) {
            this.result = diag.code;
            this.secondaryData = diag.description;
        } else {
            this.result = "";
            this.secondaryData = "";
        }
    }

    public async resultChanged(newValue: string) {
        if (newValue) {
            this.isLoading = true;
            let diagnosisResult: IDiagnosisDetails[] = await this._lookUpService.getIcd10Codes({
                term: newValue,
                type: DiagnosisQueryTypeEnum.code
            });
            if (diagnosisResult.length > 0) {
                let diag = diagnosisResult[0];
                this.diagnosis = {
                    code: this.result,
                    description: diag.shortDescription,
                    billable: diag.billable
                };
            }
            this.isLoading = false;
        }
    }
}
