import { bindingMode, computedFrom } from "aurelia-binding";
import { autoinject, bindable, customElement } from "aurelia-framework";

import nameof from "../../../common/nameof";
import { EnumMap } from "../../../common/utilities/enum-map";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { IIntervention, IInterventionComponentCallBack } from "../../../interfaces/i-plan-of-care";
import { EventAggregator } from "aurelia-event-aggregator";
import { ProblemStatementEventEnum } from "../../../enums/problem-statement-event-enum";

@customElement("intervention-row")
@autoinject
export class InterventionRow {
    @bindable({ defaultBindingMode: bindingMode.toView })
    public intervention: IIntervention;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public statementInterventions: IIntervention[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public discontinuedInterventions: string[];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public columns: number;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isUpdateEnabled: boolean;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isStatementDiscontinued: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public toggleIntervention: (addIntervention: IInterventionComponentCallBack) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public goalActions: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public interventionDisciplinesEnum: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isAction: boolean = false;
    private readonly _eventAggregator: EventAggregator;
    public isLoading: boolean = true;

    @computedFrom(nameof<InterventionRow>("discontinuedInterventions"), nameof<InterventionRow>("intervention"))
    public get isMarkedDiscontinue() {
        return this.discontinuedInterventions?.includes(this.intervention?.id);
    }

    @computedFrom(
        nameof<InterventionRow>("discontinuedInterventions"),
        nameof<InterventionRow>("statementInterventions"),
        nameof<InterventionRow>("intervention")
    )
    public get isLastIntervention() {
        // Last Intervention of the parent statement
        let statementInterventionsIds = this.statementInterventions.map((intervention) => intervention.id);
        let discontinuedInterventions = this.discontinuedInterventions.filter((intervention) =>
            statementInterventionsIds.includes(intervention)
        );
        let interventionsAfterAdding = new Set(discontinuedInterventions).add(this.intervention.id);

        return this.statementInterventions.length === interventionsAfterAdding.size;
    }

    @computedFrom(nameof<InterventionRow>("interventionDisciplinesEnum"), nameof<InterventionRow>("intervention"))
    public get disciplineLabels() {
        if (!!this.interventionDisciplinesEnum && !!this.intervention?.disciplines) {
            let interventionDisEnum = this.interventionDisciplinesEnum.map((x) => ({
                name: x.name.toString(),
                value: x.value.toString()
            }));
            let interventionDisEnumMap = new EnumMap(interventionDisEnum);
            let labels = this.intervention.disciplines.map((discipline) => {
                return (interventionDisEnumMap.getEnumName(discipline.toString()) as string) ?? "";
            });
            return labels;
        }
        return [];
    }

    public constructor(eventAggregator: EventAggregator) {
        this._eventAggregator = eventAggregator;
    }

    public markDiscontinue() {
        this.toggleIntervention({
            addIntervention: true
        });
    }

    public cancelDiscontinue() {
        this.toggleIntervention({
            addIntervention: false
        });
    }

    public document() {
        this._eventAggregator.publish(ProblemStatementEventEnum.intervention, this.intervention.intervention);
    }
}
