import { ValidationRules } from "aurelia-validation";

import { ICreateNonPatientActivity } from "../../interfaces/employee-schedule/i-non-patient-activity";

export class NonPatientActivity implements ICreateNonPatientActivity {
    public taskId: string;
    public userId: string;
    public startDates: string[];
    public endDates: string[];
    public onCall: boolean = false;
    public locationId: string = null;
    public status: number;
    public addendumId?: string;

    public constructor(data?: ICreateNonPatientActivity) {
        if (data) {
            Object.assign(this, data);
        }
        this.initValidation();
    }

    private initValidation() {
        ValidationRules.ensure((x: NonPatientActivity) => x.locationId)
            .required()
            .withMessage("Agency Branch is required.")
            .on(this);
    }
}
