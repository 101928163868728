import { autoinject, bindable, bindingMode } from "aurelia-framework";

import { Template } from "../../../models/template";

@autoinject
export class TemplateForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public template: Template;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = false;
}
