import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { IMeetingSlim } from "../interfaces/i-meeting";
import { ICreateResponse } from "../interfaces/i-response";
import {
    ICreateMeetingScheduleResult,
    IGetGroupLean,
    IGetGroupResult,
    IGetMeetingScheduleQuery,
    IGetTeamLean,
    IGetTeamPatientsFilterParams,
    IGetTeamQuery,
    IGetTeamResult,
    IGetTeamsLeanQuery,
    IReassignTeam,
    ITeamPatient,
    ITeamUser
} from "../interfaces/i-team";
import { Group } from "../models/group";
import { MeetingSchedule } from "../models/meeting-schedule";
import { Team } from "../models/team";
import { IGetInterimTeam, IRecertificationDueDate, IUpdateMeetingScheduleResult } from "./../interfaces/i-team";
import { ICreatePalliativeMeetingScheduleResult, IUpdatePalliativeMeetingScheduleResult } from "./i-team-service";

@autoinject()
export class TeamsService {
    private _httpClient: HospiceClient;
    private _baseUrl: string = "/api/v1/teams";
    private _groupUrl: string = "/api/v1/groups";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async createTeam(newTeam: Team) {
        return await this._httpClient
            .build(`${this._baseUrl}`)
            .useMethod("POST")
            .useBody(newTeam)
            .fetch<ICreateResponse>();
    }

    public async updateTeam(teamId: string, team: Team) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}`)
            .useMethod("PUT")
            .useBody(team)
            .fetchNoContent();
    }

    public async deleteTeam(teamId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${teamId}`).useMethod("DELETE").fetchNoContent();
    }

    public async getTeamsLean(query: IGetTeamsLeanQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/slim`)
            .useMethod("POST")
            .useBody(query)
            .fetch<IGetTeamLean[]>();
    }

    public async getTeamPatients(teamId: string, filterParams?: IGetTeamPatientsFilterParams) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/patients`)
            .useQueryString(filterParams)
            .fetch<ITeamPatient[]>();
    }

    public async getGroupsLean(teamId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${teamId}/groups`).fetch<IGetGroupLean[]>();
    }

    public async getGroups(teamId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${teamId}/groups`).fetch<Group[]>();
    }

    public async getTeam(teamId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${teamId}`).fetch<Team>();
    }

    public async getInterimTeamId() {
        return await this._httpClient.build(`${this._baseUrl}/interim/id`).fetch<IGetInterimTeam>();
    }

    public async getMeetingSchedule(teamId: string, query: IGetMeetingScheduleQuery) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/meetingschedules`)
            .useQueryString(query)
            .fetch<MeetingSchedule[]>();
    }

    public async createMeetingSchedule(teamId: string, meetingSchedule: MeetingSchedule) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/meetingschedule`)
            .useMethod("POST")
            .useBody(meetingSchedule)
            .fetch<ICreateMeetingScheduleResult>();
    }

    public async createPalliativeMeetingSchedule(teamId: string, meetingSchedule: MeetingSchedule) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/palliative-meeting-schedule`)
            .useMethod("POST")
            .useBody(meetingSchedule)
            .fetch<ICreatePalliativeMeetingScheduleResult>();
    }

    public async deleteMeetingSchedule(teamId: string, meetingScheduleId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/meetingschedule/${meetingScheduleId}`)
            .useMethod("DELETE")
            .fetchNoContent();
    }

    public async deactivateMeetingSchedule(teamId: string, meetingScheduleId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/meetingschedule/${meetingScheduleId}/deactivate`)
            .useMethod("PUT")
            .fetchNoContent();
    }

    public async updateMeetingSchedule(teamId: string, meetingScheduleId: string, meetingSchedule: MeetingSchedule) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/meetingschedule/${meetingScheduleId}`)
            .useMethod("PUT")
            .useBody(meetingSchedule)
            .fetch<IUpdateMeetingScheduleResult>();
    }

    public async updatePalliativeMeetingSchedule(
        teamId: string,
        meetingScheduleId: string,
        meetingSchedule: MeetingSchedule
    ) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/palliative-meeting-schedule/${meetingScheduleId}`)
            .useMethod("PUT")
            .useBody(meetingSchedule)
            .fetch<IUpdatePalliativeMeetingScheduleResult>();
    }

    public async getTeamByPage(query: IGetTeamQuery) {
        return await this._httpClient.build(`${this._baseUrl}`).useQueryString(query).fetch<IGetTeamResult>();
    }

    public async reassignTeams(query: IReassignTeam[]) {
        return await this._httpClient
            .build(`${this._baseUrl}/reassign`)
            .useMethod("PUT")
            .useBody(query)
            .fetchNoContent();
    }

    public async getRecertificationDueDates(teamId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/recertificationduedates`)
            .fetch<IRecertificationDueDate[]>();
    }

    public async createGroup(teamId: string, newGroup: Group) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/groups`)
            .useMethod("POST")
            .useBody(newGroup)
            .fetch<ICreateResponse>();
    }

    public async updateGroup(teamId: string, groupId: string, group: Group) {
        return await this._httpClient
            .build(`${this._baseUrl}/${teamId}/groups/${groupId}`)
            .useMethod("PUT")
            .useBody(group)
            .fetchNoContent();
    }

    public async getGroup(groupId: string) {
        return await this._httpClient.build(`${this._groupUrl}/${groupId}`).fetch<IGetGroupResult>();
    }

    public async getUpcomingMeeting(teamId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${teamId}/upcomingmeeting`).fetch<IMeetingSlim>();
    }

    public async deleteGroup(groupId: string) {
        return await this._httpClient.build(`${this._groupUrl}/${groupId}`).useMethod("DELETE").fetchNoContent();
    }

    public async getTeamUsers(teamId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${teamId}/users`).fetch<ITeamUser[]>();
    }
}
