import "./messages-renderer.scss";

import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, bindable, bindingMode, computedFrom, customElement, observable } from "aurelia-framework";
import * as moment from "moment";

import nameof from "../../../common/nameof";
import {
    IAsset,
    IConversationNavOptions,
    IGetMessageQuery,
    IMessageItem,
    ISendMessageQuery,
    ISendMessageResult
} from "../../../interfaces/i-message";
import { AssetsService } from "../../../services/assets-service";
import { MessageService } from "../../../services/message-service";
import { MessageErrorEnum } from "../../../enums/message-error-enum";

export enum MessageListEvent {
    HasUnreadMessage = "message:unreadChange"
}

@customElement("messages-renderer")
@autoinject
export class MessagesRenderer {
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public addSelectedMessage: (event: { messageId: string }) => void;
    @bindable({ defaultBindingMode: bindingMode.fromView })
    public removeSelectedMessage: (event: { messageId: string }) => void;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public allMessagesSelected: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    @observable({
        changeHandler: nameof<MessagesRenderer>("isViewDeletedMessagesRequestedChanged")
    })
    public isViewDeletedMessagesRequested: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isBulkDeleteRequested: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectedMessageIds: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public newItemsLength: number = 0;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public pageCount: number = 0;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public selectedConversation: IConversationNavOptions;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public messages: IMessageItem[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public pageChanged: (event: IGetMessageQuery) => Promise<void>;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public isLoading: boolean = true;
    @bindable({ defaultBindingMode: bindingMode.toView })
    @observable({
        changeHandler: nameof<MessagesRenderer>("isErrorChanged")
    })
    public isError: boolean = false;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public selectAll: boolean = false;
    private _ea: EventAggregator;
    private readonly _messageService: MessageService;
    private readonly _assetsService: AssetsService;
    private _timeoutId: NodeJS.Timeout;
    @observable({
        changeHandler: nameof<MessagesRenderer>("pageNumberChanged")
    })
    public pageNumber: number = 1;
    public pageSize: number = 50;
    public messagesWrapper: HTMLElement;
    public unReadMessagesBanner: HTMLElement;
    public loadingCount: number = 2;
    public messageReferences: HTMLElement[] = [];

    @computedFrom(nameof<MessagesRenderer>("pageNumber"), nameof<MessagesRenderer>("pageCount"))
    public get allPagesLoaded() {
        return !!this.messages?.length && this.pageNumber > this.pageCount;
    }

    @computedFrom("messages.length")
    public get readMessages(): IMessageItem[] {
        return this.messages.filter((message) => message.isRead);
    }

    @computedFrom("messages.length")
    public get unReadMessages(): IMessageItem[] {
        return this.messages.filter((message) => !message.isRead);
    }

    public constructor(ea: EventAggregator, messageService: MessageService, assetsService: AssetsService) {
        this._ea = ea;
        this._messageService = messageService;
        this._assetsService = assetsService;
    }

    public async attached() {
        await this.render();
        this.handleScrolling();
    }

    public handleScrolling() {
        let scrollTimer: NodeJS.Timer = null;
        let $scrollableElement: JQuery<Element> = $(this.messagesWrapper);
        let self: this = this;
        $scrollableElement.off("scroll").on("scroll", (e: any) => {
            if (scrollTimer) {
                clearTimeout(scrollTimer); // clear any previous pending timer
            }
            scrollTimer = setTimeout(() => {
                let st: number = $scrollableElement.scrollTop();
                if (st === 0 && !self.isLoading && !self.allPagesLoaded) {
                    self.pageNumber++;
                }
            }, 100); // set new timer
        });
    }

    public isErrorChanged(newValue: boolean) {
        if (typeof newValue == "boolean" && newValue) {
            this.reset();
        }
    }

    public async render() {
        await this.pageChanged({ page: this.pageNumber, pageLength: this.pageSize });
        if (this.unReadMessages.length > 0) {
            this.unReadMessagesBanner.scrollIntoView();
            this._ea.publish(MessageListEvent.HasUnreadMessage);
        } else if (!this.isViewDeletedMessagesRequested) {
            this.scrollToBottom();
        }
    }

    public scrollToBottom() {
        // The delay is set for rendering the messages in the UI
        // Attempts and info:
        // Aurelia does not have any life cycle hook to know when a view updated
        // ref's don't update in repeat.for. Ref's binding is one-time
        // scroll bindings aurelia have on an element does not have scrollHeight
        setTimeout(() => {
            if (this.messagesWrapper) {
                this.messagesWrapper.scrollTop = this.messagesWrapper.scrollHeight;
                console.log("Scroll top set to:", this.messagesWrapper.scrollTop);
            }
        }, 300);
    }

    public async pageNumberChanged(newValue: number, oldValue: number) {
        if (newValue <= 1 || newValue > this.pageCount || !this.messages || this.allPagesLoaded) {
            return;
        }
        await this.pageChanged({ page: this.pageNumber, pageLength: this.pageSize });
        if (this.messageReferences?.length > 0 && this.newItemsLength > 0) {
            let index = this.newItemsLength - 1;
            this.scrollIndexToView(index);
        }
    }

    public scrollIndexToView(index: number) {
        if (this._timeoutId) {
            clearTimeout(this._timeoutId);
        }
        this._timeoutId = setTimeout(() => {
            if (this.messageReferences[index]) {
                this.messageReferences[index].scrollIntoView();
            }
        }, 0);
    }

    public async reset() {
        if (this.isError) {
            this.isError = false;
        }
        this.pageNumber = 1;
        this.pageSize = 50;
        await this.render();
    }

    public renderAsset(asset: IAsset) {
        // TODO: Move this to HTML. Try not to have markup in TS files
        if (asset) {
            if (asset.mimeType.indexOf("image") > -1) {
                return `<img src=${asset.url} />`;
            } else {
                return (
                    `<a href=${asset.url} target="_blank" class="m-1 py-2 px-2 file-render text-light d-block">` +
                    `<i class="fas ${this.getFileIcon(asset.mimeType)} file-type"></i> ${asset.fileName}` +
                    "</a>"
                );
            }
        } else {
            return "";
        }
    }

    public async retryMessage(message: IMessageItem) {
        let messageQuery: ISendMessageQuery = {
            message: message.text ? message.text : "",
            isImportant: message.isImportant,
            assetIds: []
        };
        let createdDateTime = new Date();
        this.updateErrorMessageAsLoading(message);
        let isError: boolean = false;
        for (const asset of message.assets) {
            if (asset.isError) {
                try {
                    asset.assetId = await this._assetsService.uploadFile(asset.file);
                    asset.isError = false;
                } catch (e) {
                    console.error(e);
                    isError = true;
                }
            }
        }
        if (isError) {
            this.markMessageError(message.createdOn, MessageErrorEnum.FileUploadError);
        } else {
            try {
                messageQuery.assetIds = message.assets.map((asset) => asset.assetId);
                let messagesResult = await this._messageService.sendMessage(this.selectedConversation.id, messageQuery);
                this.updateSentMessage(messagesResult, message.createdOn, createdDateTime);
                if (message.assets.length > 0) {
                    await this.reset();
                }
            } catch (e) {
                console.error(e);
                this.markMessageError(message.createdOn, MessageErrorEnum.MessageSendingError);
            }
        }
    }

    private getFileIcon(type: string) {
        if (type.includes("wordprocessing")) {
            return "fa-file-word";
        } else if (type.includes("spreadsheet")) {
            return "fa-file-excel";
        } else if (type.includes("pdf")) {
            return "fa-file-pdf";
        } else {
            return "fa-file";
        }
    }

    private updateErrorMessageAsLoading(errorMessage: IMessageItem) {
        let messages = this.messages.slice(0);
        messages.forEach((message) => {
            if (message.isError && message.createdOn == errorMessage.createdOn) {
                message.isLoading = true;
            }
        });
        this.messages = messages;
    }

    private updateSentMessage(messagesResult: ISendMessageResult, oldDateTime: Date, newDateTime: Date) {
        let messages = this.messages.slice(0);
        this.moveMessageToTop(oldDateTime);
        messages.forEach((message) => {
            if (message.isLoading && message.isError && message.createdOn == oldDateTime) {
                message.isLoading = false;
                message.isError = false;
                message.createdOn = newDateTime;
                message.id = messagesResult.id;
            }
        });
        this.messages = messages;
    }

    private moveMessageToTop(dateTime: Date) {
        let messages = this.messages.splice(0);
        let index = messages.findIndex((message) => message.createdOn == dateTime);
        messages.push(messages.splice(index, 1)[0]);
        this.messages = messages;
    }

    private markMessageError(createdDateTime: Date, messageErrorType: MessageErrorEnum) {
        let messages = this.messages.slice(0);
        messages.forEach((message) => {
            if (message.isLoading && message.isError && message.createdOn == createdDateTime) {
                message.isLoading = false;
                message.isError = true;
                message.errorMessage = messageErrorType;
            }
        });
        this.messages = messages;
    }

    public showDateBanner(index: number) {
        let readMessages = this.readMessages;
        if (index === 0) {
            return true;
        } else if (readMessages.length > 1) {
            let dateFormat = "MM/DD/YYYY";
            let createdOn = moment.utc(readMessages[index].createdOn).local().format(dateFormat);
            let prevCreatedOn = moment
                .utc(readMessages[index - 1].createdOn)
                .local()
                .format(dateFormat);

            return createdOn !== prevCreatedOn;
        } else {
            return false;
        }
    }

    public updateSelectedIds(isSelected: boolean, messageId: string) {
        if (isSelected) {
            this.addSelectedMessage({ messageId });
        } else {
            this.removeSelectedMessage({ messageId });
        }
    }

    public isViewDeletedMessagesRequestedChanged() {
        this.pageNumber = 1;
        this.pageSize = 50;
    }

    public resetTable() {
        // TODO: Enable page reset
    }
}
