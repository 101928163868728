import {valueConverter} from "aurelia-binding";

@valueConverter("isReadOnlyTaskByName")
export class IsReadOnlyTaskByName {
    public toView(status: string) {
        switch (status.toLowerCase()) {
            case "submitted pending co-signature":
            case "to be sent":
            case "submitted with signature":
            case "export ready":
            case "exported":
            case "sent to physician":
            case "returned with physician signature":
            case "order received":
            case "completed":
                return true;
            default:
                return false;
        }
    }
}

@valueConverter("isReadOnlyTaskById")
export class IsReadOnlyTaskById {
    public toView(id: string | number) {
        if (typeof id === "string") {
            id = parseInt(id, 10);
        }
        switch (id) {
            // case 1:
            // case 2:
            // case 3:
            case 4:
            case 8:
            // case 12:
            // case 13:
            case 5:
            case 6:
            case 7:
            case 9:
            case 10:
            case 11:
            case 18:
                return true;
            default:
                return false;
        }
    }
}
