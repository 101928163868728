import { autoinject } from "aurelia-framework";

import { HospiceClient } from "../common/hospice-client";
import { IGrouping } from "../interfaces/form-builder/i-element";
import { IFormSchema } from "../interfaces/form-builder/i-form-schema";
import { IFormModel, IModelSchema } from "../interfaces/form-builder/i-model-schema";
import { ICompleteDocumentNote, ISaveNote } from "../interfaces/form-builder/i-save-note";
import { ITab } from "../interfaces/form-builder/i-tab";
import { ITooltip } from "../interfaces/form-builder/i-tooltips";
import { IGetSchema } from "../interfaces/i-get-schema";
import { ISaveTaskDetails, IValidateTaskDetailsQuery, IValidationTaskDetails } from "./i-note-service";

@autoinject()
export class NoteService {
    private _httpClient: HospiceClient;
    private _tooltips: Map<string, ITooltip[]> = new Map();
    private _baseUrl: string = "/api/v1/form";
    private _documentUrl: string = "/api/v1/form/document";

    public constructor(httpClient: HospiceClient) {
        this._httpClient = httpClient;
    }

    public async getFormSchema(query: IGetSchema) {
        return await this._httpClient.build(`${this._documentUrl}/${query.formId}/formschema`).fetch<IFormSchema>();
    }

    public async getModelSchema(query: IGetSchema) {
        return await this._httpClient.build(`${this._documentUrl}/${query.formId}/modelschema`).fetch<IModelSchema>();
    }

    public async getModel(query: IGetSchema) {
        return await this._httpClient.build(`${this._documentUrl}/${query.formId}/model`).fetch<IFormModel>();
    }

    public async getTooltips(schemaId: string) {
        let tooltips = [];
        if (!this._tooltips.has(schemaId)) {
            tooltips = await this._httpClient.build(`${this._documentUrl}/${schemaId}/tooltips`).fetch<ITooltip[]>();
            this._tooltips.set(schemaId, tooltips);
        } else {
            tooltips = this._tooltips.get(schemaId);
        }
        return tooltips;
    }

    public async getOneTooltip(schemaId: string, name: string) {
        let tooltip;
        if (!this._tooltips.has(schemaId)) {
            tooltip = await this._httpClient
                .build(`${this._documentUrl}/${schemaId}/tooltips/${name}`)
                .fetch<ITooltip>();
        } else {
            let tooltips = this._tooltips.get(schemaId);
            tooltip = tooltips.filter((item) => name === item.name)[0];
        }
        return tooltip;
    }

    public async saveNote(command: ISaveNote) {
        return await this._httpClient
            .build(`${this._documentUrl}/${command.formId}`)
            .useMethod("PUT")
            .useBody(command.data)
            .fetchNoContent();
    }

    public async printNote(taskId: string) {
        return await this._httpClient.build(`${this._documentUrl}/${taskId}/print`).fetchBlob();
    }

    public async validateNote(taskId: string) {
        return await this._httpClient
            .build(`${this._documentUrl}/${taskId}/validate`)
            .useMethod("POST")
            .fetch<IValidationTaskDetails>();
    }

    public async completeNote(command: ICompleteDocumentNote) {
        return await this._httpClient
            .build(`${this._documentUrl}/${command.formId}/complete`)
            .useMethod("POST")
            .useBody(command.data)
            .fetchNoContent();
    }

    public async completePalliativeNote(command: ICompleteDocumentNote) {
        return await this._httpClient
            .build(`${this._documentUrl}/palliative-care/${command.formId}/complete`)
            .useMethod("POST")
            .useBody(command.data)
            .fetchNoContent();
    }

    public async getTabs(query: IGetSchema) {
        return await this._httpClient.build(`${this._baseUrl}/${query.formId}/tabs`).fetch<ITab[]>();
    }

    public async getSchemaByTab(query: IGetSchema) {
        return await this._httpClient
            .build(`${this._baseUrl}/${query.formId}/tabs/${query.tabName}`)
            .fetch<IGrouping>();
    }

    public async getTaskDetailsFormSchema(taskId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${taskId}/task-details/formschema`).fetch<IFormSchema>();
    }

    public async getTaskDetailsModelSchema(taskId: string) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/task-details/modelschema`)
            .fetch<IModelSchema>();
    }

    public async getTaskDetailsModel(taskId: string) {
        return await this._httpClient.build(`${this._baseUrl}/${taskId}/task-details/model`).fetch<IFormModel>();
    }

    public async saveTaskDetails(taskId: string, command: ISaveTaskDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/task-details`)
            .useMethod("PUT")
            .useBody(command)
            .fetchNoContent();
    }

    public async savePalliativeTaskDetails(taskId: string, command: ISaveTaskDetails) {
        return await this._httpClient
            .build(`${this._baseUrl}/${taskId}/palliative-task-details`)
            .useMethod("PUT")
            .useBody(command)
            .fetchNoContent();
    }

    public async validateTaskDetails(taskId: string, command: IValidateTaskDetailsQuery) {
        return await this._httpClient
            .build(`${this._documentUrl}/${taskId}/validate-details`)
            .useMethod("POST")
            .useBody(command)
            .fetch<IValidationTaskDetails>();
    }
}
