import { autoinject, bindable, bindingMode, customElement, observable } from "aurelia-framework";
import moment, { Moment } from "moment";

import nameof from "../../../common/nameof";
import { IEnumResponse } from "../../../interfaces/i-enum";
import { ITypeaheadOptions } from "../../../interfaces/i-typeahead";
import { IUserResult } from "../../../interfaces/i-user";
import { IValidateCustomElement } from "../../../interfaces/i-validate-custom-element";
import { Inquiry } from "../../../models/inquiry";
import { EnumsService } from "../../../services/enums-service";
import { IGetProvider } from "../../../services/i-providers-service";
import { UsersService } from "../../../services/users-service";

@autoinject
@customElement("inquiry-form")
export class InquiryForm {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public inquiry: Inquiry;
    @bindable({ defaultBindingMode: bindingMode.toView })
    public serviceTypeEnums: IEnumResponse[] = [];
    @bindable({ defaultBindingMode: bindingMode.toView })
    public providerList: IGetProvider[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public isLoading: boolean = false;
    private _enumsService: EnumsService;
    private _usersService: UsersService;

    @observable({
        changeHandler: nameof<InquiryForm>("teamMemberChanged")
    })
    public teamMember: ITypeaheadOptions;
    public today: Moment = moment();
    public datePickerOptions: DatepickerOptions = {
        endDate: this.today.format("MM/DD/YYYY"),
        autoclose: true
    };
    public isError: boolean = false;
    public dateInputOption = {
        isReadOnly: false,
        name: "date",
        placeholderText: "MM/DD/YYYY"
    };
    public patientPhoneValidation: IValidateCustomElement = {
        required: true,
        displayName: "Phone Number",
        matches: true
    };
    public contactPhoneValidation: IValidateCustomElement = {
        matches: true
    };

    public constructor(enumsService: EnumsService, usersService: UsersService) {
        this._enumsService = enumsService;
        this._usersService = usersService;
    }

    public async attached() {
        if (this.inquiry.assignedUser && this.inquiry.assignedUserId) {
            this.teamMember = {
                name: this.inquiry.assignedUser,
                value: this.inquiry.assignedUserId
            };
        }
    }

    public async getTeamList(filter: string, limit: number) {
        return this.userToTypeahead(
            await this._usersService.getUsersByRole({
                role: [0],
                include: true,
                term: filter
            })
        );
    }

    public teamMemberChanged(teamMember: IUserResult): void {
        if (teamMember) {
            this.inquiry.assignedUserId = teamMember.value as string;
            this.inquiry.assignedUser = teamMember.name as string;
        }
    }

    private userToTypeahead(users: IUserResult[]): ITypeaheadOptions[] {
        return users.map((user) => ({
            name: `${user.lastName}, ${user.firstName}`,
            value: user.id
        }));
    }
}
