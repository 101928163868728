import {
    autoinject,
    bindable,
    BindingEngine,
    bindingMode,
    computedFrom,
    customElement,
    Disposable
} from "aurelia-framework";
import nameof from "../../../../common/nameof";
import { FormConditionValidationEnum } from "../../../../enums/form-condition-validation-enum";
import { FormValidationEnum } from "../../../../enums/form-validation-enum";
import { IInputOptions } from "../../../../interfaces/form-builder/i-input-options";
import { IMultiSelectOptions } from "../../../../interfaces/form-builder/i-multi-select-options";
import { IConditionalValidation, IRequiredAllValidation } from "../../../../interfaces/form-builder/i-validation";
import { NoteDataManager } from "../../note-data-manager";

@customElement("multi-select")
@autoinject
export class MultiSelect {
    // Input fields
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public result: string[] = [];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public viewOptions: IMultiSelectOptions;
    private _resultCache: string[] = [];
    private _bindingEngine: BindingEngine;
    private _resultSubscription: Disposable[] = [];
    public noteDataManager: NoteDataManager;
    public masterOptionValue: string = null;
    public showOption: boolean[] = [];
    public showRequiredIcon: boolean = false;

    @computedFrom(nameof<MultiSelect>("viewOptions"))
    public get hasSubInputs(): boolean {
        return this.viewOptions.options.filter((e) => e.subElement).length > 0;
    }

    @computedFrom(`${nameof<MultiSelect>("viewOptions")}.${nameof<IInputOptions>("options")}`, "result.length")
    public get showSubInput() {
        let result = this.result;
        return this.viewOptions.options.map((opt) => result.indexOf(opt.value) > -1);
    }

    public constructor(bindingEngine: BindingEngine, noteDataManager: NoteDataManager) {
        this._bindingEngine = bindingEngine;
        this.noteDataManager = noteDataManager;
    }

    public attached() {
        // https://ilikekillnerds.com/2015/10/observing-objects-and-arrays-in-aurelia/
        // https://dustindavis.me/observing-arrays-aurelia/
        this.showOption = new Array(this.viewOptions.options.length).fill(true);
        let masterOptionBehavior = null;
        if (this.viewOptions.behaviors) {
            masterOptionBehavior = this.viewOptions.behaviors.filter(
                (behave) => behave.behaviorType === "masteroption"
            );
            if (masterOptionBehavior.length > 0) {
                let masterOptionName = masterOptionBehavior[0].sourceName;
                let masterOption = this.viewOptions.options.filter((option) => option.name === masterOptionName);
                if (masterOption.length > 0) {
                    this.masterOptionValue = masterOption[0].value;
                }
            }
        }
        this.noteDataManager.assignValidationRules.call(this, this.viewOptions.name, this.noteDataManager);
        this.initResultSubscription();
        this.initRequiredIndicator();
    }

    public resultChanged(newValue: string) {
        this.resultCallBack(newValue);
        this.initResultSubscription();
    }

    public initResultSubscription() {
        this._resultSubscription.push(
            this._bindingEngine.collectionObserver(this.result).subscribe((data) => {
                this.resultCallBack(data);
            })
        );
    }

    public resultCallBack(data: any) {
        if (this.masterOptionValue) {
            this.showOption = this.viewOptions.options.map((option) => {
                if (option.value == this.masterOptionValue) {
                    return true;
                } else {
                    return !this.result.includes(this.masterOptionValue);
                }
            });
            if (this.result.includes(this.masterOptionValue) && this.result.length !== 1) {
                this._resultCache = this.result.filter((result) => result != this.masterOptionValue);
                this.result.splice(0, this.result.length);
                this.result.push(this.masterOptionValue);
            }
            if (this.result.length == 0 && this._resultCache.length != 0) {
                this.result = this.result.concat(this._resultCache);
                this._resultCache = [];
                this.initResultSubscription();
            }
        }
    }

    public getCheckBoxId(viewOptionName: string, optionName: string) {
        return `${viewOptionName}-${optionName}`;
    }

    private initRequiredIndicator() {
        let validations = this.noteDataManager.getValidationFromProp(this.viewOptions?.name);
        validations?.forEach((rule: any) => {
            if (rule.validationType === FormValidationEnum.Conditional) {
                let conditionalValidation = rule as IConditionalValidation;
                let conditionMethod: () => boolean = null;
                if (conditionalValidation.conditionType === FormConditionValidationEnum.IsNotEmpty) {
                    conditionMethod = () => !!this.noteDataManager.getValue(conditionalValidation.targetName);
                } else if (conditionalValidation.conditionType === FormConditionValidationEnum.IsEmpty) {
                    conditionMethod = () => !this.noteDataManager.getValue(conditionalValidation.targetName);
                }
                if (!!conditionMethod) {
                    this.checkIfRequired(conditionalValidation.conditionValidation, conditionMethod);
                }
            } else {
                this.checkIfRequired(rule);
            }
        });
    }

    public checkIfRequired(rule: any, condition: () => boolean = () => true) {
        if (rule.validationType === FormValidationEnum.RequiredAll) {
            let requiredAllRule = rule as IRequiredAllValidation;
            this.showRequiredIcon = requiredAllRule.showIcon && condition();
        }
    }

    public detached() {
        if (this._resultSubscription.length > 0) {
            this._resultSubscription.forEach((sub) => sub.dispose());
        }
    }
}
